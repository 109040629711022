import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// import pt_BR from '../public/locale/pt-BR/translation.json';
// import en from '../public/locale/en/translation.json';

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
    fallbackLng: 'pt-BR',
    debug: true,
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;