export default class CurrencyHelper {

    static centsToMoney(cents, withUnit = true) {

        let money = Math.abs(cents / 100);
        // return money.toLocaleString("pt-BR", {style:"currency", currency:"BRL"});
        return (withUnit ? "R$ " : "") + money.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
    }

    static moneyToCents(money) {
        let cents = Math.abs(money * 100);

        return cents;
    }
}