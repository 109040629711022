import SessionHelper from "./SessionHelper";
import Firestore from "../api/firebase/Firestore";

export default class ReplacementLessonLogHelper {

    static async insert(add, user, admin, system, from_manual_lesson, lesson) {

        let data = {
            add,
            app: false,
            from_canceled_lesson: system,
            from_manual_lesson: from_manual_lesson || false,
            id_lesson: lesson || null,
            date: new Date(),
            id_admin: admin,
            id_user: user,
            id_company: SessionHelper.getData().id_company
        };

        await Firestore.insert(data, 'replacement_lesson_log');
    }
}