import AttachMoney from "@material-ui/icons/AttachMoney";
import Brightness4 from "@material-ui/icons/Brightness4";
import SportsTennis from "@material-ui/icons/SportsTennis";

export default class EventTypeHelper {
    static getEventNameByType(type) {
        let title = `Sem Título`;

        if (type === 'court_rent') {

            title = `Aluguel de Quadra`;

        } else if (type === 'experimental_lesson') {

            title = `Aula Experimental`;

        } else if (type === 'private_lesson') {

            title = `Partida de Torneio`;

        } else if (type === 'day_use') {

            title = `Day Use`;

        } else if (type === 'tournament') {

            title = `Partida de Torneio`;

        } else {

            title = `Aula`;
        }

        return title;
    }

    static getEventIconByType(type) {
        let icon = null;

        if (type === 'court_rent') {

            icon = <AttachMoney style={{ marginRight: 10 }} />;

        } else if (type === 'experimental_lesson') {

            icon = <img src={process.env.PUBLIC_URL + '/icons/flask-grey.png'} style={{ height: 15, width: 18, marginRight: 8 }} />;

        } else if (type === 'private_lesson') {

            icon = <SportsTennis style={{ marginRight: 10 }} />;

        } else if (type === 'day_use') {

            icon = <Brightness4 style={{ marginRight: 10 }} />;

        } else {

            icon = <SportsTennis style={{ marginRight: 10 }} />;
        }

        return icon;
    }
}