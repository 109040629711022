import APIKeys from '../../constants/PagarMe';

export default class Pagarme {

    static getEndpoint() { return 'https://api.pagar.me/1' }

    static getAPIKey() { return APIKeys.getKeys().api_key }

    static getEncriptionKey() { return APIKeys.getKeys().encription_key }

    static async post(url, data) {

        data.api_key = this.getAPIKey();

        let response = await fetch(this.getEndpoint() + url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data) });
        return response.json();
    }

    static async put(url, data) {
        
        data.api_key = this.getAPIKey();

        let response = await fetch(this.getEndpoint() + url, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data) });
        return response.json();
    }

    static async get(url, data) {

        let response = await fetch(this.getEndpoint() + url + '?api_key=' + this.getAPIKey() + '&' + data, { method: 'GET' });
        return response.json();
    }
}