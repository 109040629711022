import React, { Component } from 'react';
import Colors from '../constants/Colors';
import moment from "moment-timezone";
import 'moment/locale/pt-br';
import Firestore from '../api/firebase/Firestore';
import { Tooltip } from '@material-ui/core';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import Brightness4 from '@material-ui/icons/Brightness4';


export default class KanbanCard extends Component {
    state = {
        students: []
    }

    componentDidMount = async () => {
        await this.getStudents();
    }

    renderTitle() {
        return this.props.event.type === 'tournament' ? 'Partida de Torneio' :
            this.props.event.type === 'court_rent' ? 'Aluguel de Quadra' :
                this.props.event.type === 'experimental_lesson' ? 'Aula Experimental' :
                    this.props.event.type === 'private_lesson' ? 'Aula Avulsa' :
                        this.props.event.type === 'day_use' ? 'Day Use' :
                            `Aula com ${this.props.event.teacher}`;
    }

    renderIcon() {
        return this.props.event.type === 'tournament' ? <EmojiEventsIcon style={styles.titleIcon} /> :
            this.props.event.type === 'court_rent' ? <AttachMoneyIcon style={styles.titleIcon} /> :
                this.props.event.type === 'experimental_lesson' ? <img src={process.env.PUBLIC_URL + '/icons/flask-grey.png'} style={{ ...styles.titleIcon, height: 16, width: 16, filter: `invert(100%)` }} /> :
                    this.props.event.type === 'private_lesson' ? <SportsTennisIcon style={styles.titleIcon} /> :
                        this.props.event.type === 'day_use' ? <Brightness4 style={styles.titleIcon} /> :
                            <SportsTennisIcon style={styles.titleIcon} />
    }

    getCourt() {
        if (this.props.parent && this.props.parent.state.courtDocs && this.props.event.id_court) {
            return this.props.parent.state.courtDocs.find(court => court.id == this.props.event.id_court);
        } else {
            return {};
        }
    }

    getTeacher() {
        if (this.props.parent && this.props.parent.state.teacherDocs && this.props.event.id_teacher) {
            return this.props.parent.state.teacherDocs.find(court => court.id == this.props.event.id_teacher);
        } else {
            return {};
        }
    }

    getStudents = async () => {
        if (this.props.event.id_student && this.props.event.id_student[0]) {
            let query = await Firestore.customQuery('user').where(Firestore.idField(), 'in', this.props.event.id_student).get();
            let docs = [];

            query.forEach((doc, key) => {
                let data = { ...doc.data(), id: doc.id };
                this.props.parent.setStudents(data);
                docs.push(data);
            });

            this.setState({ students: docs });
        }
    }

    renderImages = () => {
        let content = null;

        if (this.getTeacher()) {
            content = (
                <div style={styles.teacherImageContainer}>
                    <div style={{ height: 24 }}>
                        {this.renderDates()}
                    </div>
                    {this.props.event.id_teacher ?
                        <Tooltip title={(this.getTeacher()).name}>
                            <img style={styles.teacherImage} src={(this.getTeacher()).photo || process.env.PUBLIC_URL + '/empty_avatar.png'} />
                        </Tooltip>
                        : null}
                </div>
            );
        }

        return content;
    }

    treatName(string) {
        string = string.toLowerCase();
        string = string.charAt(0).toUpperCase() + string.slice(1);
        string = string.split(/\s/);

        return `${string[0]}`;
    }

    renderStudentsNames() {
        let students = this.state.students.length > 0 ? this.state.students.map((student, key) => {
            let user = this.state.students.find((item => item.id === student.id));
            return this.treatName(user.name);
        }).join(', ') : null;

        return students ? <div style={{ ...styles.text, fontSize: 12, marginTop: 2 }}>{students}</div> : null;
    }

    renderDates() {
        if (this.props.event.start && this.props.event.end) {
            return <div style={{ ...styles.text, fontSize: 12, fontWeight: 'bold', marginTop: 5, color: `rgba(0, 0, 0, 0.54)` }}>{moment(this.props.event.start).format('DD/MM/YYYY HH:mm')} ~ {moment(this.props.event.end).format('HH:mm')}</div>
        } else {
            return null;
        }
    }

    render() {
        return (
            <div style={{ ...styles.wrapper, borderRight: `4px solid ${(this.getCourt()).color || Colors.primary}` }} onClick={() => { this.props.parent.handleEventSelect(this.props.event) }}>
                <div style={styles.titleContainer}>{this.renderIcon()} <div style={styles.title}>{this.renderTitle()}</div></div>
                <div style={styles.text}>{(this.getCourt()).name || (this.props.event.type === 'day_use' ? `${this.props.event.id_courts.length || 0} quadras` : `Quadra Desconhecida`)}</div>
                {this.renderStudentsNames()}

                {this.props.event.type === 'day_use' ? <div style={styles.text}>{this.props.event.participants.length || 0} inscritos</div> : null}

                {this.renderImages()}
            </div>
        );
    }
}

const styles = {
    wrapper: {
        cursor: `pointer`,
        width: `100%`,
        heigth: 150,
        backgroundColor: `#fff`,
        marginTop: 5,
        borderRadius: 5,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
        boxShadow: `0px 0px 2px 0px rgba(34,34,34,0.46)`
    },
    title: {
        fontWeight: 'bold',
        fontSize: 16
    },
    titleIcon: {
        fontSize: 16,
        marginRight: 3
    },
    titleContainer: {
        display: `flex`,
        flexDirection: `row`,
        justifyContent: `flex-start`,
        alignItems: `center`
    },
    text: {
        fontSize: 14
    },
    teacherImage: {
        borderRadius: 12,
        heigth: 24,
        width: 24
    },
    teacherImageContainer: {
        marginTop: 10,
        width: `100%`,
        cursor: `pointer`,
        display: `flex`,
        flexDirection: `row`,
        justifyContent: `space-between`,
    }
};