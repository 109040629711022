import React from "react";
import Colors from '../constants/Colors';
import DefaultLoader from "./DefaultLoader";

export default class CustomSuspense extends React.Component {
  state = {
    title: ``
  };

  getQuote() {
    return [
      'Carregando a melhor jogada...',
      'Recolhendo Bolinhas...',
      'Escolhendo a Raquete...',
      'Encontrando Treinador...',
      'Vendo se a bola foi dentro...',
      'Trocando o Grip da raquete...',
      'Abrindo tubo novo de bolinhas...',
      'Aquecendo o saque...',
      'Alongando-se...',
      'Aquecendo o Back-Hand...',
      'Treinando o Fore-Hand...',
      'Sacando a 100km/h...',
      'Testando Raquete Nova...',
      'Varrendo a quadra...',
    ]
  }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  componentDidMount() {
    this.setTitle();

    this.titleInterval = setInterval(() => {
      this.setTitle();
    }, 2500);
  }

  setTitle() {
    let tPos = this.getRandomInt(0, this.getQuote().length - 1);
    this.setState({ title: this.getQuote()[tPos] });
  }

  componentWillUnmount() {
    clearInterval(this.titleInterval);
  }

  render() {
    return (
      <div style={{ height: this.props.height ? this.props.height : '100vh', backgroundColor: Colors.primary, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: Colors.primary, fontWeight: 'bold' }}>
        <img style={{ height: window.screen.width < 1366 ? 100 : 120, marginLeft: 'auto', marginRight: 'auto', marginBottom: 8, marginTop: 20 }} src={process.env.PUBLIC_URL + '/bg-wide-white.png'} />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
          <div style={{ height: 6, width: 25, backgroundColor: Colors.aceYellow, marginRight: 15 }} />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
            <DefaultLoader css={{ marginTop: window.screen.width < 1366 ? 6 : 3, marginRight: 10 }} size={24} color={'#fff'} />
            <h4 style={{ color: `#fff`, fontSize: window.screen.width < 1366 ? 14 : 16, maxWidth: window.screen.width < 1366 ? 130 : 320, textAlign: 'left' }}>{this.state.title}</h4>
          </div>
          <div style={{ height: 6, width: 25, backgroundColor: Colors.aceYellow, marginLeft: 15 }} />
        </div>
      </div>
    );
  }
}