import React, { Component } from 'react';
import IosSwitch from '../IosSwitch';
import DefaultButton from '../DefaultButton';
import DefaultLoader from '../DefaultLoader';
import SessionHelper from '../../helper/SessionHelper';
import ReportHelper from '../../helper/ReportHelper';
import moment from "moment-timezone";
import ReportDefaultModalOptions from './ReportDefaultModalOptions';

export default class StudentListReport extends Component {

    state = {
        name: `Lista de Alunos - ${SessionHelper.getData().company.name}`,
        loadingModal: true,
        paramDoc: {},
        studentDocs: [],
        users: [],
        allUsers: true,
        output: 'download',
        format: 'pdf',
    }

    async componentDidMount() {

        if (this.props.getDocs && this.props.getDocs.length) {

            for (let index = 0; index < this.props.getDocs.length; index++) {
                
                const getDoc = this.props.getDocs[index];
                await getDoc(this);
            }
        }

        this.setState({ loadingModal: false });
    }

    getReportDocs() {

        let users = this.state.allUsers ? this.state.studentDocs : this.state.users;
        let docs = [];

        users.forEach((user, key) => {

            let userDoc = user;

            if (!this.state.allUsers) {

                userDoc = this.state.studentDocs.find(item => item.id === user);
            }

            let address = [];
            if (userDoc.city) { address.push(userDoc.city) };
            if (userDoc.state) { address.push(userDoc.state) };
            if (userDoc.street) { address.push(userDoc.street) };
            if (userDoc.number) { address.push(userDoc.number) };
            if (userDoc.neighbourhood) { address.push(userDoc.neighbourhood) };

            userDoc.address = address.join(', ');

            docs.push(userDoc);
        });

        docs.sort((a, b) => {

            return a.name.toLowerCase() > b.name.toLowerCase();
        });

        return docs;
    }

    async print() {

        this.setState({ loadingModal: true });
        
        let docs = this.getReportDocs();
        let report = await ReportHelper.createReport(this.state.name, 5, '', this.state.format, 'l');

        report = await ReportHelper.createColumns(report, docs, [
            { name: 'Nome', width: 13, getText: (doc) => doc.name ? this.state.format === 'pdf' ? ReportHelper.cutName(ReportHelper.getShortName(doc.name), 22) : ReportHelper.getShortName(doc.name) : '' },
            { name: 'E-mail', width: 55, getText: (doc) => doc.email ? this.state.format === 'pdf' ? ReportHelper.cutName(doc.email, 20) : doc.email : '' },
            { name: 'Contato', width: 100, getText: (doc) => doc.phone || '' },
            { name: 'Cpf', width: 132, getText: (doc) => doc.cpf ? doc.cpf.toString().replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : '' },
            { name: 'Sexo', width: 160, getText: (doc) => doc.sex === 'M' ? 'Masc.' : doc.sex === 'F' ? 'Fem.' : 'Outro' },
            { name: 'Nascimento', width: 174, getText: (doc) => doc.birth_date ? moment(doc.birth_date.toDate()).format('DD/MM/YYYY') : '' },
            { name: 'Endereço', width: 198, getText: (doc) => doc.address ? this.state.format === 'pdf' ? ReportHelper.cutName(doc.address, 25) : doc.address : '' },
            { name: 'Categoria', width: 250, getText: (doc) => this.state.format === 'pdf' ? ReportHelper.cutName(this.getStudentCategory(doc.companies[SessionHelper.getData().id_company].category), 18) : this.getStudentCategory(doc.companies[SessionHelper.getData().id_company].category) },
        ]);

        report = await ReportHelper.createColumns(report, [{ count: docs.length }], [
            { name: 'Total', width: 270, getText: (doc) => doc.count.toString() },
        ], null, false, 6);

        ReportHelper.print(report, this.state.output, this.state.format);

        this.setState({ loadingModal: false });
    }

    getStudentCategory(studentCategory) {

        let result = '';
        let category = this.state.paramDoc.categories.find(item => item.value == studentCategory);

        if (category) {

            result = category.label;
        }

        return result;
    }

    renderLoading() {

        return (

            <div style={{ height: 200 }}>
                <DefaultLoader/>
            </div>
        )
    }

    renderModal() {

        return (

            <div>
                <IosSwitch fullWidth label={'Todos os Alunos'} checked={this.state.allUsers} onChange={(e) => { this.setState({ allUsers: e, users: [] }) }} />
                { !this.state.allUsers ? ReportHelper.renderUserSelection(this, this.state.studentDocs, 'users') : null }
                <ReportDefaultModalOptions format={this.state.format} output={this.state.output} onOutputChange={(value) => { this.setState({ output: value }) }} onFormatChange={(value) => { this.setState({ format: value }) }}/>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <DefaultButton width={'100%'} title={'Imprimir'} onClick={() => { this.print() }} />
                </div>
            </div>
        )
    }

    render() {

        return (

            this.state.loadingModal ? this.renderLoading() : this.renderModal()
        )
    }
}