import firebase from 'firebase/app'
import 'firebase/firestore';
import { toast } from 'react-toastify';
import Maps from '../constants/Maps';

export default class GeographicHelper {
	static async getCurrentPosition(callback) {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				async ({ coords: { latitude, longitude } }) => {
					callback({
						lat: latitude,
						lng: longitude
					});
				},
				() => {
					toast.warn("Não foi possível encontrar sua localização", {
						position: toast.POSITION.TOP_RIGHT
					});
					callback(null);
				},
				{
					timeout: 3000,
					enableHighAccuracy: true,
					maximumAge: 1000
				}
			);
		} else {
			toast.error("Localização do navegador não Habilitada", {
				position: toast.POSITION.TOP_RIGHT
			});
		}
	}

	static getDistanceBetweenPoints(start, end) {
		const R = 6371;

		let dLat = (end.lat - start.lat) * Math.PI / 180;
		let dLng = (end.lng - start.lng) * Math.PI / 180;

		let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(start.lat * Math.PI / 180) * Math.cos(end.lat * Math.PI / 180) *
			Math.sin(dLng / 2) * Math.sin(dLng / 2);
		let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		let d = R * c;

		let distanceObject = {};

		if (d < 1) {
			distanceObject = {
				value: parseInt(this.kmToMeters(d)),
				unit: 'm'
			}
		} else {
			distanceObject = {
				value: this.epsilonRound(d),
				unit: 'km'
			}
		}

		return distanceObject;
	}

	static epsilonRound(value) {
		return Math.round(((parseFloat(value)) + Number.EPSILON) * 100) / 100;
	}

	static kmToMeters(km) {
		return this.epsilonRound(parseFloat(km * 1000))
	}

	static metersToKm(meters) {
		return this.epsilonRound(parseFloat(meters / 1000))
	}

	static setFirebaseGeopoint(lat, lng) {
		return new firebase.firestore.GeoPoint(lat, lng);
	}

	static getLatLngFromGeopoint(geoPoint) {
		return {
			lat: geoPoint.latitude,
			lng: geoPoint.longitude
		}
	}

	static async reverseGeocode(coords, callback) {
		fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + coords.lat + ',' + coords.lng + '&key=' + Maps.key)
			.then((response) => response.json())
			.then(async (responseJson) => {
				const response = responseJson.results[0].address_components;

				if (response) {

					const address = {};

					await response.forEach((element) => {
						switch (element.types[0]) {
							case 'route':
								address.street = element.long_name;
								break;
							case 'street_number':
								address.number = element.long_name;
								break;
							case 'political':
								address.neighborhood = element.long_name;
								break;
							case 'postal_code':
								address.zipcode = element.long_name;
								break;
							case 'administrative_area_level_2':
								address.city = element.long_name;
								break;
							case 'administrative_area_level_1':
								address.state = element.short_name;
								break;
							case 'country':
								address.country = element.long_name;
								break;
							default:
								break;
						}
					});

					callback(address);
				}
			});
	}
}