import React, { Component } from 'react';
import { TextField } from '@material-ui/core';

export default class components extends Component {

    state = {
        error: false,
        errorText: '',
    }

    onBlur(text) {
        
        text = text.toString();

        let error = false;
        let helperText = '';

        if (this.props.hasMask) { text = text.replace(/[^\d]/g, '') }

        if (this.props.required && text.length === 0) {

            error = true;
            helperText = 'Esse campo é obrigatório';

        } else if (this.props.minLength >= 0 && text.length < this.props.minLength) {

            error = true;
            helperText = `Esse campo deve ter ao mínimo ${this.props.minLength} caractéres`;

        } else if (this.props.maxLength >= 0 && text.length > this.props.maxLength) {

            error = true;
            helperText = `Esse campo deve ter ao máximo ${this.props.maxLength} caractéres`;

        } else if (this.props.email) {

            let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            
            if (!regex.test(text.toLowerCase())) {

                error = true;
                helperText = 'O E-mail informado é inválido';
            }

        } else if (this.props.number) {

            let regex = /^\d+$/;

            if (!regex.test(text)) {

                error = true;
                helperText = 'Esse campo deve conter apenas números';
            }

        } else {

            error = false;
            helperText = '';
        }

        if (this.props.onError) { this.props.onError(error, helperText) }

        this.setState({ error: error, errorText: helperText });
    }

    render() {

        return (
            <TextField
                onKeyPress={(evt) => { this.props.onKeyPress && this.props.onKeyPress(evt) }}
                onKeyDown={(evt) => { this.props.onKeyDown && this.props.onKeyDown(evt) }}
                type={this.props.type ? this.props.type : "text"}
                defaultValue={this.props.defaultValue}
                multiline={this.props.multiline ? this.props.multiline : false}
                disabled={this.props.disabled ? this.props.disabled : false}
                helperText={this.props.helperText || this.state.errorText}
                error={this.props.error || this.state.error}
                value={this.props.value}
                onBlur={(evt) => { this.props.onBlur && this.props.onBlur(evt.target.value); this.onBlur(evt.target.value); }}
                onChange={(evt) => { this.props.onChange && this.props.onChange(evt.target.value) }}
                required={this.props.required}
                fullWidth={true}
                type={this.props.type}
                style={this.props.style ? this.props.style : { paddingTop: 10, paddingBottom: 10 }}
                id={this.props.id}
                label={this.props.label}
                placeholder={this.props.placeholder ? this.props.placeholder : this.props.label}
            />
        )

    }
}
