import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { TextField } from '@material-ui/core';
import Colors from '../constants/Colors';

export default class CurrencyInput extends Component {

    state = {
        error: false,
        errorText: '',
    }

    onBlur(text) {
        
        text = text.toString();

        let error = false;
        let helperText = '';

        if (this.props.hasMask) { text = text.replace(/[^\d]/g, '') }

        if (this.props.required && text.length === 0) {

            error = true;
            helperText = 'Esse campo é obrigatório';

        } else {

            error = false;
            helperText = '';
        }

        if (this.props.onError) { this.props.onError(error, helperText) }

        this.setState({ error: error, errorText: helperText });
    }

    render() {
        return (
            <NumberFormat
                style={{ paddingTop: 10, paddingBottom: this.props.paddingBottom >= 0 ? this.props.paddingBottom : 10, color: Colors.primary }}
                id={this.props.id}
                type={this.props.type}
                required={this.props.required}
                fullWidth={true}
                label={this.props.label}
                customInput={TextField}
                value={this.props.value}
                prefix={'R$'}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalSeparator={','}
                thousandSeparator={'.'}
                onValueChange={(e) => { this.props.onChange(e) }}
                onBlur={(evt) => { this.onBlur(evt.target.value) }}
                helperText={this.props.helperText || this.state.errorText}
                error={this.props.error || this.state.error}
                disabled={this.props.disabled ? this.props.disabled : false}
            />
        )
    }
}
