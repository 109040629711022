import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import Colors from '../constants/Colors';
import Firestore from '../api/firebase/Firestore';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import moment from "moment-timezone";
import DoneAllIcon from '@material-ui/icons/Check';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import SyncIcon from '@material-ui/icons/Sync';
import SchoolIcon from '@material-ui/icons/School';

export default class StudentSubscriptionRequest extends Component {

    state = {
        docs: [],
        userDocs: [],
        idUsers: [],
        loading: false,
        loadingModal: false,
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('student_subscription_request').where('id_company', '==', SessionHelper.getData().id_company).orderBy('date', 'desc').orderBy('answered', 'asc').get();
        let docs = [];
        let idUsers = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            if (!idUsers.includes(data.id_user)) {

                idUsers.push(data.id_user);
            }

            docs.push(data);
        });

        this.setState({ docs: docs, idUsers: idUsers });
    }

    async getUsers() {

        let idUsers = this.state.idUsers;
        let users = [];

        if (idUsers && idUsers.length) {

            for (let index = 0; index < idUsers.length; index++) {

                const element = idUsers[index];

                let user = await Firestore.getDoc('user', element);

                if (user.exists) {

                    let data = { ...user.data(), id: user.id };

                    users.push(data);
                }
            }
        }

        this.setState({ userDocs: users });
    }

    async componentDidMount() {

        await this.getDocs();
        await this.getUsers();

        this.setState({ loading: false });
    }

    renderWeekDays(weekDays) {
        console.log(weekDays);

        let days = ``;

        weekDays.forEach((day) => {
            let periods = ``;

            if (day.checked) {
                day.periods.forEach(period => {
                    if (period.checked) {
                        periods += `${period.label}, `;
                    }
                });

                if (periods && periods.length > 0) {
                    periods = periods.slice(0, periods.length - 2);
                }
            }

            if (day.checked) {
                days += `${day.label} (${periods}) ⦁ `;
            }
        });

        if (days && days.length > 0) {
            days = days.slice(0, days.length - 2);
        }

        return (
            <div>
                {days}
            </div>
        )
    }

    renderAnswered(answered) {

        if (answered) {

            return <CheckCircleIcon style={{ color: Colors.success }} />

        } else {

            return <ClearIcon style={{ color: Colors.error }} />
        }
    }

    openWhatsApp(idUser) {

        let user = this.state.userDocs.find(item => item.id === idUser);

        window.open(`https://wa.me/55${user.phone.replace(/[^\d]/g, '')}`)
    }

    async handleAnswered(answered, id) {

        if (answered) {

            toast.warn('Essa solicitação já foi marcada como respondida');

        } else {

            try {

                this.setState({ loading: true });

                await Firestore.update({ answered: true }, 'student_subscription_request', id);
                await this.getDocs();

                this.setState({ loading: false });
                toast.success('Marcado como respondido');

            } catch (error) {

                toast.error('Houve um problema ao marcar como respondido');
                this.setState({ loading: false });
            }
        }
    }

    renderRequestType(student, cancel) {

        let label = '';
        let color = '';
        let icon = '';

        if (cancel) {
            label = 'Cancelamento de Matrícula';
            color = Colors.error;
            icon = <ClearIcon style={{ fontSize: 16 }}/>;

        } else if (student) {
            label = 'Troca de Horários';
            color = Colors.primary;
            icon = <SyncIcon style={{ fontSize: 16 }}/>;

        } else {

            label = 'Solicitação de Matrícula';
            color = Colors.success;
            icon = <SchoolIcon style={{ fontSize: 16 }}/>;
        }

        return (
            <div style={{ backgroundColor: color, color: 'white', paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8, borderRadius: 5, fontWeight: 'bold', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                {icon}
                <div style={{ marginLeft: 5 }}>{label}</div>
            </div>
        );
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>

                <DefaultTable
                    title={'Solicitações'}
                    marginTop={10}
                    
                    actions={[
                        {
                            icon: WhatsAppIcon,
                            tooltip: 'Chamar no WhatsApp',
                            onClick: (event, rowData) => { this.openWhatsApp(rowData.id_user) }
                        },
                        {
                            icon: DoneAllIcon,
                            tooltip: 'Marcar como Respondido',
                            onClick: (event, rowData) => { this.handleAnswered(rowData.answered, rowData.id) }
                        },
                    ]}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Nome', field: 'id_user', editable: false, render: rowData => <div>{this.state.userDocs.find(item => item.id === rowData.id_user).name}</div> },
                        { title: 'Aluno', field: 'student', editable: false, render: rowData => <div style={{ color: rowData.student ? `green` : Colors.primary, fontWeight: 'bold' }}>{rowData.student ? `Sim` : `Não`}</div> },
                        { title: 'Tipo de Solicitação', field: 'solicitation', editable: false, render: rowData => this.renderRequestType(rowData.student, rowData.cancel) },
                        { title: 'E-mail', field: 'id_user', editable: false, render: rowData => <div>{this.state.userDocs.find(item => item.id === rowData.id_user).email}</div> },
                        { title: 'Telefone', field: 'id_user', editable: false, render: rowData => <div>{this.state.userDocs.find(item => item.id === rowData.id_user).phone}</div> },
                        { title: 'Quantidade de Aulas', field: 'lesson_amount', editable: false },
                        { title: 'Dias (Períodos) disponíveis', field: 'week_days', editable: false, render: rowData => this.renderWeekDays(rowData.week_days) },
                        { title: 'Respondido', field: 'answered', editable: false, render: rowData => this.renderAnswered(rowData.answered) },
                        { title: 'Data', editable: false, field: 'date', render: rowData => <div>{rowData.date ? moment(rowData.date.toDate ? rowData.date.toDate() : rowData.date).format('DD/MM/YYYY HH:mm') : ''}</div> },
                    ]}
                    data={this.state.docs}
                    onRowDelete={async (oldData) => {

                        let prev = this.state.docs;
                        prev.splice(prev.indexOf(oldData), 1);

                        this.setState({ docs: prev });

                        if (oldData.id) {
                            await Firestore.delete('student_subscription_request', oldData.id);
                        }

                        toast.success("Removido com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        return prev;
                    }}
                />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
