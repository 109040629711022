import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import { Card, CardContent, Grid } from '@material-ui/core';
import DefaultModal from '../components/DefaultModal';
import Colors from '../constants/Colors';
import Firestore from '../api/firebase/Firestore';
import SessionHelper from '../helper/SessionHelper';
import PrintIcon from '@material-ui/icons/Print'
import DefaultButton from '../components/DefaultButton';
import SearchBar from '../components/SearchBar';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import WidgetsIcon from '@material-ui/icons/Widgets';
import PeopleIcon from '@material-ui/icons/People';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import EmojiEvents from '@material-ui/icons/EmojiEvents';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import StudentListReport from '../components/report/StudentListReport';
import TeacherLessonReport from '../components/report/TeacherLessonReport';
import TournamentEnrollReport from '../components/report/TournamentEnrollReport';
import AvailableInventoryReport from '../components/report/AvailableInventoryReport';
import InventoryMovementReport from '../components/report/InventoryMovementReport';
import SalesInPeriodReport from '../components/report/SalesInPeriodReport';
import SalesByProductReport from '../components/report/SalesByProductReport';
import TicketsInPeriodReport from '../components/report/TicketsInPeriodReport';
import EventIcon from "@material-ui/icons/Event";
import CalendarEventReport from '../components/report/CalendarEventReport';
import LessonReport from '../components/report/LessonReport';
import CourtRentReport from '../components/report/CourtRentReport';
import TournamentMatchReport from '../components/report/TournamentMatchReport';
import ExpenseInPeriodReport from '../components/report/ExpenseInPeriodReport';
import ClientListReport from '../components/report/ClientListReport';
import SalesHelper from '../helper/SalesHelper';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import RankEnrollReport from '../components/report/RankEnrollReport';
import RankingMatchReport from '../components/report/RankingMatchReport';
import CashierFlowReport from '../components/report/CashierFlowReport';
import StudentPlanReport from '../components/report/StudentPlanReport';
import PaidStudentSubscriptionReport from '../components/report/PaidStudentSubscriptionReport';
import FutureTransactionsReport from '../components/report/FutureTransactionsReport';
import AttachMoney from '@material-ui/icons/AttachMoney';

const iconStyle = { color: 'grey', fontSize: 60 };

export default class ReportPage extends Component {

    state = {
        reports: [
            { name: 'Lista de Alunos', description: 'Lista de alunos cadastrados na plataforma.', modalState: 'studentListModal', hover: false, icon: <PeopleIcon style={iconStyle}/>, component: <StudentListReport getDocs={[this.getStudents, this.getParams]}/> },
            { name: 'Matrículas por Aluno', description: 'Lista de planos vinculado em alunos.', modalState: 'studentPlanModal', hover: false, icon: <PeopleIcon style={iconStyle}/>, component: <StudentPlanReport getDocs={[this.getStudents, this.getStudentPlans, this.getParams]}/> },
            { name: 'Matrículas Pagas', description: 'Lista de pagamentos recebidos por aluno.', modalState: 'paidStudentSubscriptionModal', hover: false, icon: <PeopleIcon style={iconStyle}/>, component: <PaidStudentSubscriptionReport getDocs={[this.getStudents, this.getParams, this.getStudentPlans]}/> },
            { name: 'Títulos a Receber', description: 'Lista de recebíveis futuros.', modalState: 'futureTransactionsModal', hover: false, icon: <AttachMoney style={iconStyle}/>, component: <FutureTransactionsReport getDocs={[this.getStudents, this.getStudentPlans]}/> },
            { name: 'Aulas por Professor', description: 'Relação de aulas lecionadas por professor x alunos.', modalState: 'teacherLessonModal', hover: false, icon: <RecordVoiceOverIcon style={iconStyle}/>, component: <TeacherLessonReport getDocs={[this.getStudents, this.getTeachers, this.getStudentPlans]}/> },
            { name: 'Aulas', description: 'Lista de aulas agendadas em um período.', modalState: 'lessonModal', hover: false, icon: <EventIcon style={iconStyle}/>, component: <LessonReport getDocs={[this.getStudents, this.getTeachers, this.getCourts, this.getParams]}/> },
            { name: 'Aluguéis de Quadra', description: 'Lista de aluguéis agendados em um período.', modalState: 'courtRentModal', hover: false, icon: <EventIcon style={iconStyle}/>, component: <CourtRentReport getDocs={[this.getStudents, this.getCourts]}/> },
            { name: 'Eventos da Agenda', description: 'Imprime uma lista de eventos em um determinado período.', modalState: 'agendaEventModal', hover: false, icon: <EventIcon style={iconStyle}/>, component: <CalendarEventReport getDocs={[this.getStudents, this.getTeachers, this.getCourts]}/> },
            { name: 'Inscrições de Torneio', description: 'Lista de inscritos em um torneio selecionado.', modalState: 'tournamentSubscriptionsModal', hover: false, icon: <EmojiEvents style={iconStyle}/>, component: <TournamentEnrollReport getDocs={[this.getTournaments]}/> },
            { name: 'Partidas de Torneio', description: 'Lista de partidas de torneio agendados em um período', modalState: 'tournamentMatchModal', hover: false, icon: <EmojiEvents style={iconStyle}/>, component: <TournamentMatchReport getDocs={[this.getStudents, this.getTournaments, this.getCourts]}/> },
            { name: 'Inscrições de Rank', description: 'Lista de inscritos em um ranking selecionado.', modalState: 'rankSubscriptionsModal', hover: false, icon: <ImportExportIcon style={iconStyle}/>, component: <RankEnrollReport getDocs={[this.getRanks]}/> },
            { name: 'Partidas de Rank', description: 'Lista de partidas de ranking agendados em um período', modalState: 'rankMatchModal', hover: false, icon: <ImportExportIcon style={iconStyle}/>, component: <RankingMatchReport getDocs={[this.getStudents, this.getRanks, this.getCourts]}/> },
            { name: 'Estoque Disponível', description: 'Lista de produtos com o seu estoque atual.', modalState: 'availableInventoryModal', hover: false, icon: <WidgetsIcon style={iconStyle}/>, component: <AvailableInventoryReport getDocs={[this.getProducts]}/> },
            { name: 'Entradas e Saídas do Estoque', description: 'Listagem de todas as entradas e saídas em um período.', modalState: 'inventoryMovementModal', hover: false, icon: <WidgetsIcon style={iconStyle}/>, component: <InventoryMovementReport getDocs={[this.getProducts, this.getAdmins]}/> },
            { name: 'Comandas por Cliente', description: 'Lista de comandas criadas por cliente.', modalState: 'ticketsInPeriodModal', hover: false, icon: <ShoppingCartIcon style={iconStyle}/>, component: <TicketsInPeriodReport getDocs={[this.getProducts, this.getServices, this.getSalesUsers, this.getAdmins, this.getLocations, this.getCourts]}/> },
            { name: 'Vendas da Loja', description: 'Vendas realizadas em um determinado período.', modalState: 'salesInPeriodModal', hover: false, icon: <ShoppingCartIcon style={iconStyle}/>, component: <SalesInPeriodReport getDocs={[this.getProducts, this.getServices, this.getSalesUsers, this.getAdmins, this.getLocations]}/> },
            { name: 'Fluxo de Caixa', description: 'Entradas e saídas do caixa no período.', modalState: 'cashierFlowModal', hover: false, icon: <ShoppingCartIcon style={iconStyle}/>, component: <CashierFlowReport getDocs={[this.getSalesUsers, this.getAdmins]}/> },
            { name: 'Vendas por Produto/Serviço', description: 'Quantidade de itens vendidos por produto ou serviço no período.', modalState: 'salesByProductModal', hover: false, icon: <ShoppingCartIcon style={iconStyle}/>, component: <SalesByProductReport getDocs={[this.getProducts, this.getServices, this.getSalesUsers, this.getLocations]}/> },
            { name: 'Lista de Clientes', description: 'Lista de clientes cadastrados na plataforma.', modalState: 'clientListModal', hover: false, icon: <ShoppingCartIcon style={iconStyle}/>, component: <ClientListReport getDocs={[SalesHelper.getUsers]}/> },
            { name: 'Despesas no Período', description: 'Lançamento de despesas em um determinado período.', modalState: 'expensesInPeriodModal', hover: false, icon: <TrendingDownIcon style={iconStyle}/>, component: <ExpenseInPeriodReport getDocs={[this.getProviders, this.getAdmins]}/> },
        ],
        selectedReport: '',
    }

    async getParams(instance = this) {

        let query = await Firestore.customQuery('settings').where('id_company', '==', SessionHelper.getData().id_company).get();
        let data = instance.state.config;

        if (query.size === 1 && query.docs[0]) {
            data = query.docs[0].data();
            data.id = query.docs[0].id;
        }

        instance.setState({ paramDoc: data });
    }

    async getStudents(instance = this) {

        let query = await Firestore.customQuery('user').where('id_companies', 'array-contains', SessionHelper.getData().id_company).where('type', '==', 'student').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        instance.setState({ studentDocs: docs });
    }

    async getSalesUsers(instance = this) {

        let query = await Firestore.customQuery('user').where('id_companies', 'array-contains', SessionHelper.getData().id_company).where('type', 'in', ['student', 'sales_user']).orderBy('name', 'asc').get();

        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            docs.push({ ...data, id: doc.id });
        });

        instance.setState({ salesUserDocs: docs });
    }

    async getAdmins(instance = this) {

        let query = await Firestore.customQuery('user').where('id_company', '==', SessionHelper.getData().id_company).where('type', '==', 'admin').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        instance.setState({ adminDocs: docs });
    }

    async getProducts(instance = this) {

        let query = await Firestore.customQuery('product').where('id_company', '==', SessionHelper.getData().id_company).where('type', '==', 'product').orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        instance.setState({ productDocs: docs });
    }

    async getServices(instance = this) {

        let query = await Firestore.customQuery('product').where('id_company', '==', SessionHelper.getData().id_company).where('type', '==', 'service').orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        instance.setState({ serviceDocs: docs });
    }

    async getTeachers(instance = this) {

        let query = await Firestore.customQuery('user').where('id_company', '==', SessionHelper.getData().id_company).where('type', '==', 'teacher').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        instance.setState({ teacherDocs: docs });
    }

    async getStudentPlans(instance = this) {

        let query = await Firestore.customQuery('student_plan').where('id_company', '==', SessionHelper.getData().id_company).get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        instance.setState({ studentPlanDocs: docs });
    }

    async getCourts(instance = this) {

        let query = await Firestore.customQuery('court').where('id_company', '==', SessionHelper.getData().id_company).get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        instance.setState({ courtDocs: docs });
    }

    async getTournaments(instance = this) {

        let query = await Firestore.customQuery('tournament').where('id_company', '==', SessionHelper.getData().id_company).get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        instance.setState({ tournamentDocs: docs });
    }

    async getRanks(instance = this) {

        let query = await Firestore.customQuery('ranking').where('id_company', '==', SessionHelper.getData().id_company).get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        instance.setState({ rankingDocs: docs });
    }

    async getProviders(instance = this) {

        let query = await Firestore.customQuery('provider').where('id_company', '==', SessionHelper.getData().id_company).get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        instance.setState({ providerDocs: docs });
    }

    async getLocations(instance = this) {

        let query = await Firestore.customQuery('product_location').where('id_company', '==', SessionHelper.getData().id_company).get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        instance.setState({ locationDocs: docs });
    }

    hasPermission(report) {

        if (SessionHelper.getData().type === 'super_admin') {

            return true;
            
        } else {

            let permission = SessionHelper.getData().permission.routes[report];

            if (permission === undefined) {
    
                return true;
            
            } else if (permission === false) {
    
                return false;
                
            } else {
    
                return true;
            }
        }
    }

    toggleHover(key, flag) {

        let cards = this.state.reports;
        cards[key].hover = flag;

        this.setState({ cards: cards });
    }

    async openModal(key) {

        this.setState({ selectedReport: key });
    }

    renderCards() {

        return this.state.reports.map((report, key) => {

            let render = [];
            let hasPermission = this.hasPermission(report.modalState);

            if (hasPermission) {

                render.push(

                    <Grid item xs={4}>
                        <Card onMouseEnter={() => { this.toggleHover(key, true) }} onMouseLeave={() => { this.toggleHover(key, false) }} variant="outlined" style={{ width: 'auto', minHeight: 210, marginRight: 5, marginTop: 5, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 10 }}>
                            <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', height: 30 }}>
                                    <div style={{ fontWeight: 'bold', fontSize: 18, color: Colors.primary }}>{report.name}</div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'space-between', height: '100%', alignItems: 'flex-start', paddingTop: 25 }}>
                                    <div style={{ width: '80%', color: 'grey', textAlign: 'justify', paddingRight: 40 }}>
                                        <div>{report.description}</div>
                                    </div>
                                    {report.icon || <PrintIcon style={{ color: 'grey', fontSize: 60 }}/>}
                                </div>
                                { report.hover ? <div style={{ marginTop: 15, width: '100%' }}><DefaultButton width={'100%'} onClick={() => { this.openModal(report.modalState) }} title={'Configurar e Imprimir'}/></div> : null }
                            </CardContent>
                        </Card>
                    </Grid>
                );
    
                render.push(<DefaultModal loading={this.state.loadingModal} content={report.component} title={'Configurar Relatório'} onClose={() => { this.setState({ selectedReport: '' }) }} open={this.state.selectedReport === report.modalState}/>);    
            }

            return render;
        });
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (

            <div style={{ width: '100%', padding: 25 }}>
                <Grid container>
                    <Grid item xs={12}>
                        <SearchBar docs={this.state.reports} search={'name'} onEmpty={(docs) => { this.setState({ reports: docs }) }} onSearch={(result) => { this.setState({ reports: result }) }} placeholder={'Busque por um relatório...'} />       
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            { this.renderCards() }
                        </div>
                    </Grid>
                </Grid>
            </div>
            
        );
    }
}
