import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Tooltip, IconButton, FormLabel } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import SessionHelper from '../helper/SessionHelper';
import moment from "moment-timezone";
import ReceiptIcon from '@material-ui/icons/Receipt';
import AddIcon from '@material-ui/icons/Add';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CheckIcon from '@material-ui/icons/CheckCircle';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SaleCard from '../components/SaleCard';
import TicketCard from '../components/TicketCard';
import CurrencyHelper from '../helper/CurrencyHelper';
import CurrencyInput from '../components/CurrencyInput';
import { toast } from 'react-toastify';
import SearchIcon from '@material-ui/icons/Search';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import HeaderActionsWrapper from '../components/HeaderActionsWrapper';
import SalesHelper from '../helper/SalesHelper';
import AddSale from '../components/AddSale';
import GavelIcon from '@material-ui/icons/Gavel';
import SettleTickets from '../components/SettleTickets';
import SettingsIcon from '@material-ui/icons/Settings';
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import LayoutHelper from '../helper/LayoutHelper';
import CashierHelper from '../helper/CashierHelper';

export default class SalesPage extends Component {
    state = {
        loadingModal: false,
        addSaleModal: false,
        search: '',
        statuses: SalesHelper.getStatuses(),
        selectedStatuses: [],
        paginationStart: moment().startOf('month'),
        paginationEnd: moment().endOf('month'),
        ticketsDocs: [],
        productsDocs: [],
        salesDocs: [],
        usersDocs: [],
        adminUsers: [],
        cashierDocs: [],
        cashierLastOperation: null,
        cashierAvailableMoney: null,
        rawCashierAvailableMoney: null,
        cashierWithdraw: null,
        rawCashierWithdraw: null,
        reloading: false,
        loading: true,
        isEditing: false,
        settleTickets: false,
        dateFilterTypes: SalesHelper.getDateFilterTypes(),
        selectedDateFilter: SalesHelper.getDateFilterTypes()[1].type,
        timeUnitFilters: SalesHelper.getTimeUnitFilterTypes(),
        selectedTimeUnitFilter: SalesHelper.getTimeUnitFilterTypes()[0],
        dateTypeTypes: SalesHelper.getDateTypeTypes(),
        selectedDateType: SalesHelper.getDateTypeTypes()[0],
    }

    componentDidMount = async () => {
        await this.setState({ loading: true });

        await this.getDocs();

        this.interval = setInterval(() => { if (!this.state.addSaleModal && !this.state.isEditing) this.getDocs() }, 600000);

        await this.setState({ loading: false });
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getDocs = async () => {
        this.setState({ reloading: true });

        await SalesHelper.getUsers(this);
        await SalesHelper.getSystemUsers(this);
        await SalesHelper.getProducts(this);
        await SalesHelper.getTicketsDocs(this);
        await this.getSalesDocs();
        await CashierHelper.getLastOperation(this);

        this.setState({ reloading: false });
    }

    getCashierMoneyFromOperation = async () => {

        await SalesHelper.getTicketsDocs(this);
        await this.getSalesDocs();

        let lastOperation = this.state.cashierLastOperation;
        let storeDocs = [...this.state.salesDocs, ...this.state.ticketsDocs];
        let cashierAvailableMoney = 0;


        if (lastOperation.operation === 'close') {

            cashierAvailableMoney += lastOperation.amount;

        } else {

            cashierAvailableMoney += lastOperation.amount;

            let referenceDate = moment(lastOperation.date.toDate());

            storeDocs.forEach((sale, key) => {

                if (moment(sale.date.toDate()).isSameOrAfter(referenceDate) && sale.payment_method === 'money') {

                    cashierAvailableMoney += sale.amount;
                }
            });
        }

        this.setState({ rawCashierAvailableMoney: cashierAvailableMoney / 100, cashierAvailableMoney: cashierAvailableMoney / 100 });
    }

    searchArray(array, searchField, keyword) {
        let search = [];

        for (var j = 0; j < array.length; j++) {
            var regex = new RegExp(keyword, "gm");

            if (array[j][searchField].match(regex)) search.push(array[j]);
        }

        return search;
    }

    getSalesDocs = async () => {
        let query = await Firestore.customQuery('order').where('id_company', '==', SessionHelper.getData().id_company).where('type', '==', 'product').where('date', '>=', this.state.paginationStart.toDate()).where('date', '<=', this.state.paginationEnd.toDate()).orderBy('date', 'desc').get();
        let docs = [];

        query.forEach((document, key) => {
            let doc = { ...document.data(), id: document.id };

            let canPush = true;

            if (this.state.search.length > 0 && canPush) {
                let users = this.searchArray(this.state.usersDocs, `name`, this.state.search);

                if (doc.id_user) {
                    canPush = users.some(user => doc.id_user === user.id);
                } else {
                    canPush = false;
                }
            }

            if (doc.canceled) canPush = false;

            if (canPush) docs.push(doc);
        });

        this.setState({ salesDocs: docs });
    }

    renderTickets = () => {
        return (
            <div>
                <div style={{ ...styles.kanbanTitleContainer, height: 86 }}>
                    <FormLabel style={styles.kanbanTitle}>COMANDAS · {this.state.reloading ? <DefaultLoader size={13} css={{ marginLeft: 6 }} color={`rgba(0, 0, 0, 0.54)`} /> : this.state.ticketsDocs.length}</FormLabel>
                    <div style={{ flexDirection: 'row', display: 'flex', height: 20, marginTop: -4, marginRight: -10 }}>
                        {this.renderStatuses()}
                    </div>
                </div>
                <div style={styles.kanbanCardsContainer}>
                    <ul style={styles.ticketCardsContainer}>
                        {this.state.ticketsDocs.map(doc => <TicketCard onIsEditing={(flag) => { this.setState({ isEditing: flag }) }} fromSalesPage={true} getTicketsDocs={() => { SalesHelper.getTicketsDocs(this) }} getSalesDocs={() => { this.getSalesDocs() }} productsDocs={this.state.productsDocs} doc={doc} usersDocs={this.state.usersDocs} adminUsers={this.state.adminUsers} />)}
                    </ul>
                </div>
            </div>
        );
    }

    renderTodaysSales = () => {
        return (
            <div>
                <div style={styles.kanbanTitleContainer}>
                    <FormLabel style={styles.kanbanTitle}>VENDAS · {this.state.reloading ? <DefaultLoader size={13} css={{ marginLeft: 6 }} color={`rgba(0, 0, 0, 0.54)`} /> : this.state.salesDocs.length}</FormLabel>
                </div>
                <div style={styles.kanbanCardsContainer}>
                    {this.state.salesDocs.map(doc => <SaleCard doc={doc} usersDocs={this.state.usersDocs} adminUsers={this.state.adminUsers} />)}
                </div>
            </div>

        );
    }

    saveCashierOperation = async () => {

        let operation = this.state.cashierLastOperation.operation === 'open' ? 'close' : 'open';

        try {

            this.setState({ loadingModal: true });

            let data = {
                operation,
                amount: parseInt(this.state.rawCashierAvailableMoney * 100),
                date: new Date(),
                id_user: SessionHelper.getFirebaseAuth().uid,
                id_company: SessionHelper.getData().id_company,
            };

            await Firestore.insert(data, 'store_cashier');
            await CashierHelper.getLastOperation(this);

            toast.success(`Caixa ${operation === 'open' ? 'aberto' : 'fechado'} com sucesso`)
            this.setState({ loadingModal: false, cashierModal: false });

        } catch (error) {

            this.setState({ loadingModal: false });
            toast.error(`Houve um problema ao ${operation === 'open' ? 'abrir' : 'fechar'} o caixa`);
        }
    }

    withdrawFromCashier = async () => {

        let available = parseInt(this.state.rawCashierAvailableMoney * 100);
        let amount = parseInt(this.state.rawCashierWithdraw * 100);

        if ((available - amount) < 0) {

            toast.warn('Saldo insuficiente');

        } else {

            let lastCashierOperation = this.state.cashierLastOperation;

            try {

                this.setState({ loadingModal: true });

                let withdraws = lastCashierOperation.withdraws || [];
                withdraws.push({ id_user: SessionHelper.getFirebaseAuth().uid, date: new Date(), amount: amount, before_amount: lastCashierOperation.amount, available: parseInt(lastCashierOperation.amount - amount) });

                let update = {
                    amount: parseInt(lastCashierOperation.amount - amount),
                    withdraws,
                };

                await Firestore.update(update, 'store_cashier', lastCashierOperation.id);
                await CashierHelper.getLastOperation(this);

                toast.success(`Valor retirado com sucesso`);
                this.setState({ loadingModal: false, cashierWithdrawModal: false, rawCashierWithdraw: null, cashierWithdraw: null });

            } catch (error) {

                this.setState({ loadingModal: false });
                toast.error(`Houve um problema ao retirar o valor do caixa`);
            }
        }
    }

    cashierModal() {
        if (this.state.cashierModal) {
            return (
                <div>
                    <CurrencyInput label={'Valor em Caixa'} onChange={(values) => { const { formattedValue, value } = values; this.setState({ cashierAvailableMoney: formattedValue, rawCashierAvailableMoney: value }) }} value={this.state.cashierAvailableMoney} />
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton leftIcon={<CheckIcon />} onClick={() => { this.saveCashierOperation() }} color={Colors.primary} loading={this.state.loadingModal} width={'100%'} title={`Confirmar Operação`} />
                    </div>
                </div>
            )
        }
    }

    cashierWithdrawModal() {
        if (this.state.cashierWithdrawModal) {
            return (
                <div>
                    <p style={{ color: Colors.success, fontWeight: 'bold', fontSize: 20 }}>{`Valor em Caixa: ${CurrencyHelper.centsToMoney(this.state.rawCashierAvailableMoney * 100)}`}</p>
                    <CurrencyInput label={'Valor'} onChange={(values) => { const { formattedValue, value } = values; this.setState({ cashierWithdraw: formattedValue, rawCashierWithdraw: value }) }} value={this.state.cashierWithdraw} />
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton leftIcon={<CheckIcon />} onClick={() => { this.withdrawFromCashier() }} color={Colors.primary} loading={this.state.loadingModal} width={'100%'} title={`Confirmar Operação`} />
                    </div>
                </div>
            )
        }
    }

    dynamicSearch = async (text) => {
        this.setState({ search: text });

        const term = text;
        const minChars = 3;

        if (term == ``) {
            this.setState({ search: term });
            this.getDocs();
        }

        if (term.length < minChars) {
            this.setState({ isTyping: false });
            return;
        }

        this.setState({ isTyping: true });

        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        const that = this;

        this.setState({
            typingTimeout: setTimeout(async () => {
                that.setState({ isTyping: false });
                that.search();
            }, 1000)
        });
    }

    search = () => {
        this.getDocs();
    }

    async handleMonthChange(advance) {
        let unit = this.state.selectedTimeUnitFilter.type;
        let paginationStart = this.state.paginationStart, paginationEnd = this.state.paginationEnd;

        if (advance) {
            paginationStart = moment(paginationStart).add(1, unit).startOf(unit);
            paginationEnd = moment(paginationEnd).add(1, unit).endOf(unit);
        } else {
            paginationStart = moment(paginationStart).subtract(1, unit).startOf(unit);
            paginationEnd = moment(paginationEnd).subtract(1, unit).endOf(unit);
        }

        await this.setState({ paginationStart, paginationEnd });
        await this.getDocs();
    }

    renderBasicTimePicker() {
        return (
            <div>
                <div style={{ width: 200, justifyContent: 'space-evenly', display: 'flex', flexDirection: 'row', }}>
                    <IconButton style={{ height: 64, width: 64, color: Colors.primary }} onClick={() => { this.handleMonthChange(false) }} ><NavigateBeforeIcon /></IconButton>
                    {this.state.reloading ? <DefaultLoader size={20} color={Colors.formLabel} css={{ display: 'flex', marginTop: 0, marginRight: 10, justifyContent: 'center', alignItems: 'center' }} /> : null}
                    <div style={{ fontSize: 20, fontWeight: 'bold', color: Colors.formLabel, display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: this.state.selectedTimeUnitFilter.type === 'week' ? 124 : 'auto' }}><div>{moment(this.state.paginationStart).format(this.state.selectedTimeUnitFilter.format)}</div>{this.state.selectedTimeUnitFilter.type === 'week' ? <div style={{ marginLeft: 4 }}>~ {moment(this.state.paginationEnd).format(this.state.selectedTimeUnitFilter.format)}</div> : null}</div>
                    <IconButton style={{ height: 64, width: 64, color: Colors.primary }} onClick={() => { this.handleMonthChange(true) }} ><NavigateNextIcon /></IconButton>
                </div>

                <div style={{ width: 200, justifyContent: 'space-evenly', display: 'flex', flexDirection: 'row', }}>
                    {
                        this.state.timeUnitFilters.map(type => (
                            <Tooltip title={`Filtrar vendas e comandas por ${type.title}`}>
                                <div
                                    style={{ ...styles.typeSelector, border: `2px solid ${type.color}`, color: this.state.selectedTimeUnitFilter.type == type.type ? `#fff` : Colors.formLabel, backgroundColor: this.state.selectedTimeUnitFilter.type === type.type ? type.color : `#fafafa` }}
                                    onClick={async () => { await this.setState({ selectedTimeUnitFilter: type, paginationStart: moment().startOf(type.type), paginationEnd: moment().endOf(type.type) }); this.getDocs(); }}>
                                    {type.title.toUpperCase()}
                                </div>
                            </Tooltip>
                        ))
                    }
                </div>
            </div>
        );
    }

    renderAdvancedTimePicker() {
        return (
            <div>
                <div style={{ display: 'flex', flexDirection: LayoutHelper.isSmallScreen() ? 'column' : 'row', width: '100%', alignItems: 'center' }}>
                    <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            style={LayoutHelper.isSmallScreen() ? { width: '100%', marginTop: 10, marginBottom: 15 } : { width: 142, marginRight: 5, marginTop: 15, marginBottom: 15 }}
                            invalidDateMessage={false}
                            format={'dd/MM/yyyy'}
                            autoOk={true}
                            label="De"
                            cancelLabel={'Cancelar'}
                            okLabel={'Confirmar'}
                            onChange={async (v) => {
                                await this.setState({ paginationStart: moment(v) });
                                if (moment(v).isValid()) {
                                    this.getDocs();
                                }
                            }}
                            value={this.state.paginationStart}
                        />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            style={LayoutHelper.isSmallScreen() ? { width: '100%', marginBottom: 15 } : { width: 142, marginLeft: 5, marginTop: 15, marginBottom: 15 }}
                            invalidDateMessage={false}
                            format={'dd/MM/yyyy'}
                            autoOk={true}
                            label="Até"
                            cancelLabel={'Cancelar'}
                            okLabel={'Confirmar'}
                            onChange={async (v) => {
                                await this.setState({ paginationEnd: moment(v) });
                                if (moment(v).isValid()) {
                                    this.getDocs();
                                }
                            }}
                            value={this.state.paginationEnd}
                        />
                    </MuiPickersUtilsProvider>
                </div>
            </div>
        );
    }

    renderMonthPicker() {
        return (
            <div style={{ backgroundColor: '#fff', borderBottomWidth: .5, borderBottomColor: Colors.contrastColor, justifyContent: 'center', display: 'flex', flexDirection: 'column', minWidth: LayoutHelper.isSmallScreen() ? '100%' : 200, alignContent: 'center', alignItems: 'center', marginTop: LayoutHelper.isSmallScreen() ? 20 : 0, marginBottom: LayoutHelper.isSmallScreen() ? 20 : 0 }}>
                <div style={{ justifyContent: 'space-evenly', display: 'flex', flexDirection: 'row', }}>
                    {
                        this.state.dateTypeTypes.map(type => (
                            <Tooltip title={`Filtro de data ${type.title}`}>
                                <div
                                    style={{ ...styles.typeSelector, border: `2px solid ${type.color}`, color: this.state.selectedDateType.type == type.type ? `#fff` : Colors.formLabel, backgroundColor: this.state.selectedDateType.type === type.type ? type.color : `#fafafa` }}
                                    onClick={async () => { await this.setState({ selectedDateType: type, paginationStart: moment().startOf(this.state.selectedTimeUnitFilter.type), paginationEnd: moment().endOf(this.state.selectedTimeUnitFilter.type) }); this.getDocs() }}>
                                    {type.title.toUpperCase()}
                                </div>
                            </Tooltip>
                        ))
                    }
                </div>

                {this.state.selectedDateType.type == 'basic' ? this.renderBasicTimePicker() : this.renderAdvancedTimePicker()}
            </div>

        )
    }

    renderSearch() {
        return (
            <div style={styles.searchContainer}>
                {this.state.reloading ? <DefaultLoader size={28} color={Colors.formLabel} css={{ display: 'flex', marginTop: 5, marginRight: 10, justifyContent: 'center', alignItems: 'center' }} /> : <div style={{ display: 'flex', height: '100%', justifyContent: 'center', marginRight: 5 }}><SearchIcon style={{ marginTop: 25, fontSize: 28, color: Colors.formLabel }} /></div>}
                <DefaultInput label={'Busque por usuários...'} type={'text'} value={this.state.search} onChange={(text) => { this.dynamicSearch(text) }} />
            </div>
        );
    }

    selectElement = (element, stateField) => {
        let state = this.state;

        if (state[stateField].includes(element)) {
            const index = state[stateField].indexOf(element);
            if (index > -1) state[stateField].splice(index, 1);
        } else {
            state[stateField].push(element);
        }

        this.setState(state);

        this.getDocs();
    }

    renderStatuses = () => {
        return (
            <div>
                <FormLabel style={styles.kanbanTitle}>{this.state.reloading ? <DefaultLoader size={13} css={{ marginRight: 6 }} color={`rgba(0, 0, 0, 0.54)`} /> : <SettingsIcon style={{ fontSize: 13, marginRight: 8 }} />} FILTROS</FormLabel>

                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 6, marginTop: 8 }}>
                    {
                        this.state.dateFilterTypes.map(type => (
                            <Tooltip title={`Mostrar comandas baseado em ${type.title}`}>
                                <div
                                    style={{ ...styles.typeSelector, border: `2px solid ${type.color}`, color: this.state.selectedDateFilter == type.type ? `#fff` : Colors.formLabel, backgroundColor: this.state.selectedDateFilter === type.type ? type.color : `#fafafa` }}
                                    onClick={async () => { await this.setState({ selectedDateFilter: type.type }); this.getDocs(); }}>
                                    {type.title.toUpperCase()}
                                </div>
                            </Tooltip>
                        ))
                    }
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', }}>
                    {
                        this.state.statuses.map(type => (
                            <Tooltip title={`Filtrar comandas por ${type.title}`}>
                                <div style={{ ...styles.typeSelector, border: `2px solid ${type.color}`, color: this.state.selectedStatuses.includes(type.type) ? `#fff` : Colors.formLabel, backgroundColor: this.state.selectedStatuses.includes(type.type) ? type.color : `#fafafa` }} onClick={() => { this.selectElement(type.type, `selectedStatuses`) }}>
                                    {type.title.toUpperCase()}
                                </div>
                            </Tooltip>
                        ))
                    }
                </div>
            </div>
        );
    }

    async handleCashierModal() {

        this.setState({ loadingModal: true, cashierModal: true });

        await CashierHelper.getLastOperation(this);
        await this.getCashierMoneyFromOperation();

        this.setState({ loadingModal: false });
    }

    async handleCashierWithdrawModal() {

        this.setState({ loadingModal: true, cashierWithdrawModal: true });

        await CashierHelper.getLastOperation(this);
        await this.getCashierMoneyFromOperation();

        this.setState({ loadingModal: false });
    }

    async handleSettleTickets() {

        this.setState({ settleTickets: true, isEditing: true })
        await this.settleTickets.getDocs();
    }

    renderCashier() {

        let lastOperation = this.state.cashierLastOperation;

        return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <DefaultButton loading={this.state.reloading} color={lastOperation.operation === 'close' ? Colors.success : Colors.error} leftIcon={lastOperation.operation === 'close' ? <AttachMoneyIcon /> : <MoneyOffIcon />} onClick={() => { this.handleCashierModal() }} title={lastOperation.operation === 'close' ? 'Abrir Caixa' : 'Fechar Caixa'} />
                <div style={{ marginRight: 8 }} />
                {lastOperation.operation === 'open' ? <DefaultButton loading={this.state.reloading} color={Colors.danger} leftIcon={<CallReceivedIcon />} onClick={() => { this.handleCashierWithdrawModal() }} title={'Retirar Valor'} /> : null}
                <div style={{ marginRight: 8 }} />
            </div>
        )
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <HeaderActionsWrapper style={styles.headerButtons}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginBottom: 10, maxWidth: '100%' }} className={'header-actions-buttons'}>
                            {this.renderCashier()}
                            <DefaultButton loading={this.state.reloading} color={Colors.contrast} leftIcon={<GavelIcon />} onClick={() => { this.handleSettleTickets() }} title={'Baixar Comandas'} />
                        </div>

                        <DefaultButton loading={this.state.reloading} leftIcon={<div style={{ display: 'flex', flexDirection: 'row' }}><ReceiptIcon /> <AddIcon style={{ fontSize: 16 }} /></div>} onClick={() => { this.setState({ addSaleModal: true, isEditing: true }) }} title={'Nova Venda'} />
                    </div>
                    {this.renderMonthPicker()}
                    {this.renderSearch()}
                </HeaderActionsWrapper>

                <div style={styles.viewWrapper}>
                    <div style={styles.ticketWrapper}>
                        {this.renderTickets()}
                    </div>
                    <div style={styles.kanbanColumn}>
                        {this.renderTodaysSales()}
                    </div>
                </div>

                <AddSale
                    onClose={() => { this.setState({ addSaleModal: false, isEditing: false }) }}
                    open={this.state.addSaleModal}
                    usersDocs={this.state.usersDocs}
                    adminUsers={this.state.adminUsers}
                    productsDocs={this.state.productsDocs}
                    fromSalesPage={true}
                    getDocs={this.getDocs}
                />
                <SettleTickets
                    onClose={() => { this.setState({ settleTickets: false, isEditing: false }); SalesHelper.getTicketsDocs(this) }}
                    open={this.state.settleTickets}
                    usersDocs={this.state.usersDocs}
                    ref={(ref) => this.settleTickets = ref}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.cashierModal()} title={this.state.cashierLastOperation.operation === 'close' ? 'Abrir Caixa' : 'Fechar Caixa'} onClose={() => { this.setState({ cashierModal: false }) }} open={this.state.cashierModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.cashierWithdrawModal()} title={'Retirar Valor'} onClose={() => { this.setState({ cashierWithdrawModal: false }) }} open={this.state.cashierWithdrawModal} />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
        maxHeight: LayoutHelper.isSmallScreen() ? window.screen.height : window.innerHeight,
        heigth: `100%`,
        display: `flex`,
        flexDirection: `column`,
    },
    headerButtons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: window.screen.width < 1360 ? 'left' : 'center',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        padding: 15,
        boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px',
        borderRadius: 5
    },
    viewWrapper: {
        display: 'flex',
        paddingTop: 20,
        flexDirection: LayoutHelper.isSmallScreen() ? 'column' : 'row',
        justifyContent: 'space-between',
        maxHeight: LayoutHelper.isSmallScreen() ? '50%' : window.innerHeight - (245),
        maxHeight: LayoutHelper.isSmallScreen() ? '50%' : window.innerHeight - (245),
    },
    ticketWrapper: {
        width: LayoutHelper.isSmallScreen() ? '100%' : 'calc(100% - 290px)',
        borderRadius: 10,
        minHeight: LayoutHelper.isSmallScreen() ? '50%' : window.innerHeight - (245),
        maxHeight: LayoutHelper.isSmallScreen() ? '50%' : window.innerHeight - (245),
        backgroundColor: 'blue',
        backgroundColor: `rgb(244, 245, 247)`
    },
    kanbanColumn: {
        marginTop: LayoutHelper.isSmallScreen() ? 20 : 0,
        width: LayoutHelper.isSmallScreen() ? '100%' : '280px',
        minHeight: LayoutHelper.isSmallScreen() ? '50%' : window.innerHeight - (245),
        maxHeight: LayoutHelper.isSmallScreen() ? '50%' : window.innerHeight - (245),
        borderRadius: 10,
        marginBottom: 10,
        flexDirection: `column`,
        backgroundColor: `rgb(244, 245, 247)`
    },
    kanbanTitleContainer: {
        display: `flex`,
        flexDirection: `row`,
        justifyContent: 'space-between',
        height: 32,
        padding: 13,
        width: `100%`
    },
    kanbanTitle: {
        display: `flex`,
        flexDirection: `row`,
        textAlign: `left`,
        fontWeight: `bold`,
        fontSize: 13
    },
    kanbanCardsContainer: {
        overflowY: `auto`,
        width: `100%`,
        maxHeight: LayoutHelper.isSmallScreen() ? window.screen.height - (440 + 86) : window.innerHeight - (245 + 86),
        paddingLeft: 10,
        paddingBottom: 10,
        paddingRight: 10
    },
    ticketCardsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 0,
        listStyleType: 'none',
        paddingInlineStart: 0
    },
    searchContainer: {
        display: `flex`,
        justifyContent: 'flex-end',
        width: LayoutHelper.isSmallScreen() ? '95%' : '20%',
        marginTop: 5,
        marginBottom: 5,
        flexDirection: 'row'
    },
    typeSelector: {
        cursor: `pointer`,
        marginRight: 6,
        minHeight: `auto`,
        border: `2px solid ${Colors.primary}`,
        maxHeight: 18,
        textAlign: `center`,
        justifyContent: `center`,
        paddingLeft: 8,
        paddingRight: 8,
        fontSize: 12,
        fontWeight: 'bold',
        borderRadius: 15
    },
}