import React, { Component } from 'react';
import { Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

export default class CompanyBadge extends Component {

    render() {

        return (
            <Tooltip title={this.props.tooltip}>
                <div onClick={() => { window.location.href = this.props.href; }} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: 'orange', padding: 8, borderRadius: 8, cursor: 'pointer', marginLeft: 8, marginBottom: 8, marginTop: 8 }}>
                    <InfoIcon style={{ marginRight: 5 }}/>
                    {this.props.title}
                </div>
            </Tooltip>
        )

    }
}
