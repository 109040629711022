import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Colors from '../constants/Colors';
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";

export default class DeleteModal extends Component {

    render() {
        return (
            <Modal
            style={{ display: 'flex',alignItems: 'center', justifyContent: 'center' }}
            open={this.props.open}
            onClose={(evt) => { this.props.onClose(evt) }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }} >
                <Fade in={this.props.open}>
                <div style={{backgroundColor: '#fff', borderRadius: 5, paddingLeft: 20, paddingRight: 20, paddingBottom: 20, maxHeight: '90vh', width: this.props.width ? this.props.width : 600, overflowY: 'scroll' }}>
                    <h2>{this.props.title}</h2>
                    <div style={{filter: this.props.loading ? 'blur(2px)' : ''}}>{this.props.content}</div>
                    {this.props.loading && this.renderLoading()}
                </div>
                </Fade>
            </Modal>
        )
    }
    
    renderLoading() {
        return (
            <ClipLoader
            css={css`position: absolute;top:50%;left:48.5%;`}
            size={50}
            color={Colors.primary}
            loading={true}
            />
        );
    }
}
