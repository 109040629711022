import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import { Tabs, Tab, Box, FormLabel, TextareaAutosize, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import DefaultInput from '../components/DefaultInput';
import CurrencyInput from '../components/CurrencyInput';
import SessionHelper from '../helper/SessionHelper';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DescriptionIcon from '@material-ui/icons/Description';
import Firestore from '../api/firebase/Firestore';
import Storage from '../api/firebase/Storage';
import { toast } from 'react-toastify';
import Colors from '../constants/Colors';
import DefaultButton from '../components/DefaultButton';
import ImagePicker from '../components/ImagePicker';
import IosSwitch from '../components/IosSwitch';
import LocationPicker from '../components/LocationPicker';
import GeographicHelper from '../helper/GeographicHelper';
import moment from "moment-timezone";
import 'moment/locale/pt-br';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import brLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from '@date-io/date-fns';
import InputMask from 'react-input-mask';
import CompanyTaskHelper from '../helper/CompanyTaskHelper';
import PagarmeConstants from '../constants/PagarMe';
import CurrencyHelper from '../helper/CurrencyHelper';
import PaymentMethodHelper from '../helper/PaymentMethodHelper';
import DefaultSelect from '../components/DefaultSelect';
import DataHelper from '../helper/DataHelper';

const timezones = Intl.supportedValuesOf('timeZone').map((item, key) => {
    return {
        value: item,
        label: item.replace('_', ' '),
    }
});
export default class CompanyPage extends Component {

    state = {
        tab: 0,
        loading: false,
        logoHover: false,
        images: SessionHelper.getData().company.images || [],
        name: SessionHelper.getData().company.name,
        email: SessionHelper.getData().company.email,
        phone: SessionHelper.getData().company.phone,
        cnpj: SessionHelper.getData().company.cnpj ? SessionHelper.getData().company.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1 $2 $3/$4-$5") : '',
        cpf: SessionHelper.getData().company.cpf ? SessionHelper.getData().company.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : '',
        zipcode: SessionHelper.getData().company.zipcode,
        country: SessionHelper.getData().company.country,
        state: SessionHelper.getData().company.state,
        city: SessionHelper.getData().company.city,
        street: SessionHelper.getData().company.street,
        ddd: SessionHelper.getData().company.ddd,
        number: SessionHelper.getData().company.number,
        complemento: SessionHelper.getData().company.complemento,
        neighborhood: SessionHelper.getData().company.neighborhood,
        description: SessionHelper.getData().company.description ? SessionHelper.getData().company.description : '',
        logo: SessionHelper.getData().company.logo,
        coordinates: SessionHelper.getData().company.coordinates ? SessionHelper.getData().company.coordinates : GeographicHelper.setFirebaseGeopoint(-27, -52),
        services: SessionHelper.getData().company.services || [
            { label: 'Matrícula', name: 'sign_up', checked: false },
            { label: 'Aula Avulsa', name: 'private_lesson', checked: false },
            { label: 'Aula Experimental', name: 'test_lesson', checked: false },
        ],
        sport: SessionHelper.getData().company.sport || '',
        sports: SessionHelper.getData().company.sports ? [ ...SessionHelper.getData().company.sports ] : [],
        // type: SessionHelper.getData().company.type || '',
        company_hours: SessionHelper.getData().company.company_hours || {
            monday: {
                checked: true,
                label: 'Segunda-feira',
                morning: { checked: true, hours: ['08:00', '12:00'] },
                afternoon: { checked: true, hours: ['13:30', '20:00'] }
            },
            tuesday: {
                checked: true,
                label: 'Terça-feira',
                morning: { checked: true, hours: ['08:00', '12:00'] },
                afternoon: { checked: true, hours: ['13:30', '20:00'] }
            },
            wednesday: {
                checked: true,
                label: 'Quarta-feira',
                morning: { checked: true, hours: ['08:00', '12:00'] },
                afternoon: { checked: true, hours: ['13:30', '20:00'] }
            },
            thursday: {
                checked: true,
                label: 'Quinta-feira',
                morning: { checked: true, hours: ['08:00', '12:00'] },
                afternoon: { checked: true, hours: ['13:30', '20:00'] }
            },
            friday: {
                checked: true,
                label: 'Sexta-feira',
                morning: { checked: true, hours: ['08:00', '12:00'] },
                afternoon: { checked: true, hours: ['13:30', '20:00'] }
            },
            saturday: {
                checked: true,
                label: 'Sábado',
                morning: { checked: true, hours: ['09:00', '12:00'] },
                afternoon: { checked: true, hours: ['13:30', '20:00'] }
            },
            sunday: {
                checked: true,
                label: 'Domingo',
                morning: { checked: false, hours: ['09:00', '12:00'] },
                afternoon: { checked: true, hours: ['13:30', '17:00'] }
            }
        },
        visible: SessionHelper.getData().company.visible ? true : false,
        experimentalLessonPrice: SessionHelper.getData().company.experimental_lesson_price / 100 || 0,
        experimentalLessonRawPrice: SessionHelper.getData().company.experimental_lesson_price / 100 || 0,
        privateLessonPrice: SessionHelper.getData().company.private_lesson_price / 100 || 0,
        privateLessonRawPrice: SessionHelper.getData().company.private_lesson_price / 100 || 0,
        inputError: false,
        paymentMethods: SessionHelper.getData().company.payment_methods ? SessionHelper.getData().company.payment_methods : {
            'court_rent_packs': ['credit_card', 'pix'],
            'private_lesson_packs': ['credit_card', 'pix'],
            'court_rent': ['credit_card', 'money', 'pix'],
            'private_lesson': ['credit_card', 'money', 'pix'],
            'experimental_lesson': ['credit_card', 'money', 'pix'],
            'tournament': ['credit_card', 'boleto', 'pix'],
            'day_use': ['credit_card', 'money', 'pix'],
        },
        lesson_confirmation_minutes: SessionHelper.getData().company.lesson_confirmation_minutes ? SessionHelper.getData().company.lesson_confirmation_minutes.toString() : '120',
        timezone: SessionHelper.getData().company.timezone || 'America/Sao_Paulo',
    }

    handleChange(index) {
        this.setState({ tab: index });
    }

    TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <div>{children}</div>
                    </Box>
                )}
            </div>
        );
    }

    async upload(file) {

        if (file) {

            try {

                this.setState({ loading: true });

                let ref = await Storage.uploadFile('logo' || '', file);
                let downloadPath = await Storage.getFileFromRef(ref);

                if (!downloadPath) {
                    throw new Error('upload error');
                }

                await Firestore.update({ logo: downloadPath }, 'company', SessionHelper.getData().id_company);

                if (!SessionHelper.getData().company.logo && downloadPath) {
                    CompanyTaskHelper.completeTask('company_logo', this.props.tasks);
                }

                let data = SessionHelper.getData();
                data.company.logo = downloadPath;

                this.setState({ logo: downloadPath, loading: false });

                toast.success("Imagem adicionada com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

            } catch (error) {

                toast.error("Houve um problema ao salvar a imagem", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loading: false });
            }
        }
    }

    async updateCourts() {
        const courtsQuery = await Firestore.customQuery('court').where('id_company', '==', SessionHelper.getData().id_company).get();

        if (courtsQuery.docs.length > 0) {
            let courts = [];

            courtsQuery.forEach(doc => {
                let court = {
                    data: doc.data(),
                    id: doc.id
                };

                courts.push(court);
            });

            for (let i = 0; i < courts.length; i++) {
                await Firestore.geoUpdate(courts[i].data, 'court', courts[i].id,
                    GeographicHelper.getLatLngFromGeopoint(this.state.coordinates).lat,
                    GeographicHelper.getLatLngFromGeopoint(this.state.coordinates).lng
                );
            }
        }
    }

    async save() {

        if (!this.state.visible || !this.state.services[1].checked || (this.state.services[1].checked && parseFloat(this.state.privateLessonRawPrice) > 0)) {

            if (this.state.lesson_confirmation_minutes && parseInt(this.state.lesson_confirmation_minutes) > 0) {

                if (this.state.name && this.state.email && this.state.phone && this.state.street && this.state.neighborhood && this.state.number) {

                    if (PaymentMethodHelper.validateCompanyPaymentMethods(this.state.paymentMethods)) {

                        try {

                            this.setState({ loading: true });

                            let data = {
                                name: this.state.name,
                                email: this.state.email,
                                phone: this.state.phone ? `${this.state.phone.replace(/[^\d]/g, '')}` : null,
                                street: this.state.street,
                                neighborhood: this.state.neighborhood,
                                number: this.state.number,
                                complemento: this.state.complemento,
                                description: this.state.description,
                                zipcode: this.state.zipcode.replace(/[^\d]/g, ''),
                                country: this.state.country,
                                coordinates: this.state.coordinates,
                                state: this.state.state,
                                ddd: this.state.ddd.replace(/[^\d]/g, ''),
                                visible: this.state.visible,
                                company_hours: this.state.company_hours,
                                services: this.state.services,
                                sports: this.state.sports,
                                experimental_lesson_price: this.state.experimentalLessonRawPrice * 100,
                                private_lesson_price: this.state.privateLessonRawPrice * 100,
                                payment_methods: this.state.paymentMethods,
                                lesson_confirmation_minutes: parseInt(this.state.lesson_confirmation_minutes),
                                timezone: this.state.timezone,
                            };

                            await Firestore.geoUpdate(data, 'company', SessionHelper.getData().id_company,
                                GeographicHelper.getLatLngFromGeopoint(this.state.coordinates).lat,
                                GeographicHelper.getLatLngFromGeopoint(this.state.coordinates).lng
                            );

                            await this.updateCourts();

                            toast.success('Dados atualizados com sucesso');
                            this.setState({ loading: false });

                            if (SessionHelper.getData().company.timezone !== data.timezone) {
                                toast.info('Atualize a página para aplicar o novo Fuso Horário', { autoClose: false });
                            }

                            if (!SessionHelper.getData().company.description && this.state.description) {
                                CompanyTaskHelper.completeTask('company_description', this.props.tasks);
                            }

                            if (!SessionHelper.getData().company.visible && this.state.visible) {
                                CompanyTaskHelper.completeTask('app_visible', this.props.tasks);
                            }

                            if (!SessionHelper.getData().company.images && this.state.images) {
                                CompanyTaskHelper.completeTask('company_photo', this.props.tasks);
                            }

                            if (!SessionHelper.getData().company.sports.length && this.state.sports.length) {
                                CompanyTaskHelper.completeTask('company_sport', this.props.tasks);
                            }

                            if ((this.state.services[0].checked || this.state.services[1].checked || this.state.services[2].checked)) {
                                CompanyTaskHelper.completeTask('company_services', this.props.tasks);
                            }

                            SessionHelper.setData({ ...SessionHelper.getData(), company: { ...SessionHelper.getData().company, ...data } });

                            this.handleInvisibleWarn(data.visible);
                        } catch (error) {
                            console.log(error);

                            toast.error('Erro ao atualizar dados');
                            this.setState({ loading: false });
                        }
                    }

                } else {

                    toast.warn('Preencha todos os campos');
                }

            } else {

                toast.warn('O tempo de confirmação das aulas não pode ser vazio e deve ser maior que zero')
            }

        } else {

            if (this.state.privateLessonRawPrice <= 0) {
                toast.warn('O valor da aula avulsa não pode ser zero');
            }
        }
    }

    handleInvisibleWarn(visible) {

        if (visible === true) {

            let session = SessionHelper.getData();
            session.company.visible = true;

            SessionHelper.setData(session);

            this.props.navigation.forceUpdate();

        } else {

            let session = SessionHelper.getData();
            session.company.visible = false;

            SessionHelper.setData(session);

            this.props.navigation.forceUpdate();
        }
    }

    async saveImages(files) {

        if (files.length) {

            await Firestore.update({ images: files }, 'company', SessionHelper.getData().id_company);
        }
    }

    renderLogo() {
        return (
            <div style={{ paddingBottom: 28, display: 'flex', overflowY: 'auto' }}>
                <input
                    multiple={false}
                    accept=".png"
                    style={{ display: 'none' }}
                    id="contained-button-file"
                    type="file"
                    onChange={(v) => { this.upload(v.target.files[0]) }}
                />
                <label htmlFor="contained-button-file">

                    {!this.state.logo ?

                        <div style={{ height: 150, width: 350, backgroundColor: '#ededed', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', marginTop: 10 }}>
                            <AddIcon style={{ fontSize: 40, color: 'grey' }} />
                        </div>

                        :

                        <div onMouseOver={() => { this.setState({ logoHover: true }) }} onMouseLeave={() => { this.setState({ logoHover: false }) }} style={{ position: 'relative', height: 150, width: 350, border: '1px solid lightgrey', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', marginTop: 10 }}>
                            {this.state.logoHover && <EditIcon style={{ color: Colors.primary, cursor: 'pointer', width: 50, height: 50, padding: 10, borderRadius: '100%', backgroundColor: 'white', boxShadow: Colors.boxShadow, position: 'absolute', marginBottom: 'auto', marginLeft: 'auto', margin: 5, marginTop: 15, fontSize: 30 }} />}
                            <img src={this.state.logo} style={{ height: 150, width: 350, padding: 25, }} />
                        </div>

                    }

                </label>
            </div>
        )
    }

    renderCompanyData() {
        return (
            <div>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 25 }} className={'mobile-dashboard'}>
                    <div style={{ width: window.screen.width < 1080 ? '100%' : '60%' }}>
                        <FormLabel style={{ paddingBottom: 18 }} component="legend">Dados da Empresa</FormLabel>
                        <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} value={this.state.name} onChange={(text) => { this.setState({ name: text }) }} label={'Nome'} />
                        <DefaultInput required email onError={(error, msg) => { this.setState({ inputError: error }) }} value={this.state.email} onChange={(text) => { this.setState({ email: text }) }} label={'E-mail'} />

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <DefaultInput required number onError={(error, msg) => { this.setState({ inputError: error }) }} value={this.state.ddd} onChange={(text) => { this.setState({ ddd: text }); }} label={'DDD'} />
                            <div style={{ marginLeft: 3, marginRight: 3 }} />
                            <InputMask onChange={(v) => { this.setState({ phone: DataHelper.treatPhone(v) }); console.log(this.state.phone) }} mask="9 9999-99999999" maskPlaceholder={''} value={this.state.phone}>
                                <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={8} label={'Telefone'} />
                            </InputMask>
                        </div>

                        {SessionHelper.getData().company.type === 'pj' ? <DefaultInput value={this.state.cnpj} disabled={true} label={'Cnpj'} /> : <DefaultInput value={this.state.cpf} disabled={true} label={'Cpf'} />}

                        <FormLabel style={{ paddingBottom: 18, paddingTop: 18, fontSize: 13 }} component="legend">Descrição</FormLabel>
                        <TextareaAutosize style={{ width: '100%', borderRadius: 5, borderColor: 'lightgrey', padding: 15, fontSize: '1rem' }} rowsMax={8} rowsMin={8} value={this.state.description} onChange={(v) => { this.setState({ description: v.target.value }) }} placeholder="Escreva uma descrição..." />
                    </div>
                    <div style={{ marginLeft: window.screen.width < 1080 ? 0 : 50 }}>
                        <FormLabel style={{ paddingBottom: 18 }} component="legend">Logo</FormLabel>
                        {this.renderLogo()}
                        <FormLabel style={{ fontSize: 12 }} component="legend">*Para melhor visualização, utilize de preferência uma imagem com tamanho de 350x150 pixels.</FormLabel>
                        <FormLabel style={{ fontSize: 12, marginTop: 15, }} component="legend">*Utilize uma imagem no formato png com fundo transparente.</FormLabel>
                    </div>
                </div>

                <div style={{ paddingTop: 10 }}/>
                <DefaultSelect multiple={false} search={true} searchField={'label'} valueField={'value'} displayField={'label'} key={'timezone_select'} value={this.state.timezone} onChange={(v) => { this.setState({ timezone: v.target.value }) }} docs={timezones} label={'Fuso Horário'} />

                <FormLabel style={{ marginTop: 18 }} component="legend">Localização da Empresa</FormLabel>
                <LocationPicker callback={this.handleMapChange} initialCenter={GeographicHelper.getLatLngFromGeopoint(this.state.coordinates)} />

                <FormLabel style={{ paddingBottom: 18, paddingTop: 18 }} component="legend">Endereço</FormLabel>
                <InputMask disabled={true} onChange={(v) => { this.setState({ zipcode: v }) }} mask="99999-999" value={this.state.zipcode}>
                    <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={8} label={'Cep'} />
                </InputMask>

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <DefaultInput value={this.state.country} disabled={true} onChange={(text) => { this.setState({ country: text }) }} label={'País'} />
                    <div style={{ paddingRight: 5, paddingLeft: 5 }} />
                    <DefaultInput value={this.state.state} disabled label={'Estado'} />
                </div>

                <DefaultInput value={this.state.city} disabled={true} label={'Cidade'} />

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} required value={this.state.street} onChange={(text) => { this.setState({ street: text }) }} label={'Rua'} />
                    <div style={{ paddingRight: 5, paddingLeft: 5 }} />
                    <div style={{ paddingRight: 5, paddingLeft: 5, width: '50%' }}>
                        <DefaultInput value={this.state.number} onChange={(text) => { this.setState({ number: text }) }} label={'Número'} />
                    </div>
                    <div style={{ paddingRight: 5, paddingLeft: 5, width: '50%' }}>
                        <DefaultInput value={this.state.complemento} onChange={(text) => { this.setState({ complemento: text }) }} label={'Complemento'} />
                    </div>
                </div>

                <DefaultInput value={this.state.neighborhood} disabled={true} onChange={(text) => { this.setState({ neighborhood: text }) }} label={'Bairro'} />

                <div style={{ paddingTop: 18 }}>
                    <DefaultButton width={'100%'} onClick={() => { this.save() }} title={'Salvar'} />
                </div>
            </div>
        )
    }

    handleMapChange = (geodata) => {
        let state = this.state;

        let keys = ['zipcode', 'country', 'state', 'city', 'street', 'number', 'complemento', 'neighborhood'];

        keys.forEach(key => { state[key] = '' });

        if (geodata.address) {
            Object.keys(geodata.address).forEach(key => {
                if (key == 'number') {
                    state[key] = geodata.address[key].replace(/\D*/, '');
                    state['complemento'] = geodata.address[key].replace(/\d*/, '');
                } else {
                    state[key] = geodata.address[key];
                }
            });
        }
        if (geodata.coords) {
            state.coordinates = GeographicHelper.setFirebaseGeopoint(geodata.coords.lat, geodata.coords.lng);
        }

        this.setState(state);
    }

    renderPhotos() {
        return (
            <div>
                <FormLabel style={{ marginTop: 18, fontWeight: '800' }} component="legend">Imagens da acadêmia</FormLabel>
                <FormLabel style={{ fontSize: 14, marginTop: 5 }} component="legend">Estas imagens serão exibidas ao usuário na página de acadêmias. A primeira imagem é a principal.</FormLabel>
                <ImagePicker imageWidth={300} imageHeight={300} onUploadStart={() => { this.setState({ loading: true }) }} onUploadEnd={() => { this.setState({ loading: false }) }} onUpload={(files) => { this.setState({ images: files }); this.saveImages(files); }} images={this.state.images} folder={'company_images'} multiple={true} />
            </div>
        )
    }

    renderPaymentMethods() {

        return (
            <div style={{ marginBottom: 20 }}>
                <FormLabel style={{ marginTop: 18, fontWeight: '800' }} component="legend">Métodos de Pagamento</FormLabel>
                <FormLabel style={{ fontSize: 14, marginTop: 5 }} component="legend">Defina os métodos de pagamento aceitos para cada tipo de serviço oferecido.</FormLabel>
                <div style={{ paddingTop: 25 }}>
                    <div style={{ width: 500 }}><DefaultSelect multiple={true} valueField={'value'} displayField={'label'} key={'court_rent_packs_select'} value={this.state.paymentMethods['court_rent_packs'] || []} onChange={(v) => { let paymentMethods = this.state.paymentMethods; paymentMethods['court_rent_packs'] = v.target.value; this.setState({ paymentMethods }) }} docs={PaymentMethodHelper.getPaymentMethodsByService('court_rent_packs')} label={'Pacote de Aluguéis de Quadra'} /></div>
                </div>
                <div style={{ paddingTop: 25 }}>
                    <div style={{ width: 500 }}><DefaultSelect multiple={true} valueField={'value'} displayField={'label'} key={'private_lesson_packs_select'} value={this.state.paymentMethods['private_lesson_packs'] || []} onChange={(v) => { let paymentMethods = this.state.paymentMethods; paymentMethods['private_lesson_packs'] = v.target.value; this.setState({ paymentMethods }) }} docs={PaymentMethodHelper.getPaymentMethodsByService('private_lesson_packs')} label={'Pacote de Aulas Avulsas'} /></div>
                </div>
                <div style={{ paddingTop: 25 }}>
                    <div style={{ width: 500 }}><DefaultSelect multiple={true} valueField={'value'} displayField={'label'} key={'court_rent_select'} value={this.state.paymentMethods['court_rent'] || []} onChange={(v) => { let paymentMethods = this.state.paymentMethods; paymentMethods['court_rent'] = v.target.value; this.setState({ paymentMethods }) }} docs={PaymentMethodHelper.getPaymentMethodsByService('court_rent')} label={'Aluguel de Quadra'} /></div>
                </div>
                <div style={{ paddingTop: 25 }}>
                    <div style={{ width: 500 }}><DefaultSelect multiple={true} valueField={'value'} displayField={'label'} key={'private_lesson_select'} value={this.state.paymentMethods['private_lesson'] || []} onChange={(v) => { let paymentMethods = this.state.paymentMethods; paymentMethods['private_lesson'] = v.target.value; this.setState({ paymentMethods }) }} docs={PaymentMethodHelper.getPaymentMethodsByService('private_lesson')} label={'Aulas Avulsas'} /></div>
                </div>
                <div style={{ paddingTop: 25 }}>
                    <div style={{ width: 500 }}><DefaultSelect multiple={true} valueField={'value'} displayField={'label'} key={'experimental_lesson_select'} value={this.state.paymentMethods['experimental_lesson'] || []} onChange={(v) => { let paymentMethods = this.state.paymentMethods; paymentMethods['experimental_lesson'] = v.target.value; this.setState({ paymentMethods }) }} docs={PaymentMethodHelper.getPaymentMethodsByService('experimental_lesson')} label={'Aulas Experimentais'} /></div>
                </div>
                <div style={{ paddingTop: 25 }}>
                    <div style={{ width: 500 }}><DefaultSelect multiple={true} valueField={'value'} displayField={'label'} key={'tournament_select'} value={this.state.paymentMethods['tournament'] || []} onChange={(v) => { let paymentMethods = this.state.paymentMethods; paymentMethods['tournament'] = v.target.value; this.setState({ paymentMethods }) }} docs={PaymentMethodHelper.getPaymentMethodsByService('tournament')} label={'Inscrição de Torneio'} /></div>
                </div>
                <div style={{ paddingTop: 25 }}>
                    <div style={{ width: 500 }}><DefaultSelect multiple={true} valueField={'value'} displayField={'label'} key={'ranking_select'} value={this.state.paymentMethods['ranking'] || []} onChange={(v) => { let paymentMethods = this.state.paymentMethods; paymentMethods['ranking'] = v.target.value; this.setState({ paymentMethods }) }} docs={PaymentMethodHelper.getPaymentMethodsByService('ranking')} label={'Inscrição de Ranking'} /></div>
                </div>
                <div style={{ paddingTop: 25 }}>
                    <div style={{ width: 500 }}><DefaultSelect multiple={true} valueField={'value'} displayField={'label'} key={'day_use_select'} value={this.state.paymentMethods['day_use'] || []} onChange={(v) => { let paymentMethods = this.state.paymentMethods; paymentMethods['day_use'] = v.target.value; this.setState({ paymentMethods }) }} docs={PaymentMethodHelper.getPaymentMethodsByService('day_use')} label={'Day Use'} /></div>
                </div>
            </div>
        )
    }

    renderLessonParameters() {

        return (
            <div style={{ marginBottom: 20 }}>
                <FormLabel style={{ marginTop: 18, fontWeight: '800' }} component="legend">Confirmação de Presença em Aulas</FormLabel>
                <FormLabel style={{ fontSize: 14, marginTop: 5 }} component="legend">Defina quanto tempo antes (minutos) da aula os seus alunos podem aceitar ou recusar a presença.</FormLabel>
                <div style={{ paddingTop: 10 }}>
                    <div style={{ width: 500 }}>
                        <InputMask onChange={(v) => { this.setState({ lesson_confirmation_minutes: v }) }} mask="999" maskPlaceholder={''} value={this.state.lesson_confirmation_minutes}>
                            <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={1} label={'Tempo em Minutos'} />
                        </InputMask>
                    </div>
                </div>
            </div>
        )
    }

    getDaysIndex() {
        return [
            { key: 'monday' },
            { key: 'tuesday' },
            { key: 'wednesday' },
            { key: 'thursday' },
            { key: 'friday' },
            { key: 'saturday' },
            { key: 'sunday' },
        ];
    }

    renderCompanyHours() {
        return (
            <div>
                <FormLabel style={{ marginTop: 18, fontWeight: '800' }} component="legend">Horários de Funcionamento</FormLabel>
                <FormLabel style={{ fontSize: 14, marginTop: 5 }} component="legend">Aqui você configura os dias e horários que sua acadêmia fica aberta. Que serão visíveis aos usuários do App.</FormLabel>
                <div style={{ paddingTop: 10 }}>
                    <FormLabel style={{ fontSize: 14, marginTop: 10, marginBottom: 3, fontWeight: '600' }} component="legend">Dias de semana (selecionado significa aberto)</FormLabel>

                    {this.getDaysIndex().map(dayIndex => {
                        let key = dayIndex.key;
                        let day = this.state.company_hours[key];

                        if (day.label) {
                            return (
                                <div style={{ paddingTop: 10, flexDirection: 'column', display: 'flex', maxWidth: 500 }}>
                                    <IosSwitch label={day.label} onChange={(evt) => { this.setState({ company_hours: { ...this.state.company_hours, [key]: { ...this.state.company_hours[key], checked: evt } } }); }} checked={day.checked || false} />
                                    {day.checked ?
                                        <div style={{ paddingLeft: 25, paddingTop: 10, flexDirection: 'column', display: 'flex' }}>
                                            <IosSwitch label={"Manhã"} onChange={(evt) => { this.setState({ company_hours: { ...this.state.company_hours, [key]: { ...this.state.company_hours[key], morning: { ...this.state.company_hours[key].morning, checked: evt } } } }); }} checked={day.morning.checked || false} />

                                            {day.morning.checked ?
                                                <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: 15 }}>
                                                    <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                                        <KeyboardTimePicker
                                                            style={{ width: '100%', marginBottom: 30, paddingRight: 5 }}
                                                            invalidDateMessage={false}
                                                            format={'HH:mm'}
                                                            autoOk={true}
                                                            label="Início Manhã"
                                                            cancelLabel={'Cancelar'}
                                                            okLabel={'Confirmar'}
                                                            onChange={(v) => {
                                                                let hours = day.morning.hours;
                                                                hours[0] = moment(v).format('HH:mm');

                                                                this.setState({ company_hours: { ...this.state.company_hours, [key]: { ...this.state.company_hours[key], morning: { ...this.state.company_hours[key].morning, hours } } } });
                                                            }}
                                                            value={moment(day.morning.hours[0], 'HH:mm')}
                                                        />
                                                        <KeyboardTimePicker
                                                            style={{ width: '100%', marginBottom: 30, paddingLeft: 5 }}
                                                            invalidDateMessage={false}
                                                            format={'HH:mm'}
                                                            autoOk={true}
                                                            label="Fim Manhã"
                                                            cancelLabel={'Cancelar'}
                                                            okLabel={'Confirmar'}
                                                            onChange={(v) => {
                                                                let hours = day.morning.hours;
                                                                hours[1] = moment(v).format('HH:mm');

                                                                this.setState({ company_hours: { ...this.state.company_hours, [key]: { ...this.state.company_hours[key], morning: { ...this.state.company_hours[key].morning, hours } } } });
                                                            }}
                                                            value={moment(day.morning.hours[1], 'HH:mm')}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                                : null}

                                            <IosSwitch label={"Tarde/Noite"} onChange={(evt) => { this.setState({ company_hours: { ...this.state.company_hours, [key]: { ...this.state.company_hours[key], afternoon: { ...this.state.company_hours[key].morning, checked: evt } } } }); }} checked={day.afternoon.checked || false} />

                                            {day.afternoon.checked ?
                                                <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: 15 }}>
                                                    <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                                        <KeyboardTimePicker
                                                            style={{ width: '100%', marginBottom: 30, paddingRight: 5 }}
                                                            invalidDateMessage={false}
                                                            format={'HH:mm'}
                                                            autoOk={true}
                                                            label="Início Tarde/Noite"
                                                            cancelLabel={'Cancelar'}
                                                            okLabel={'Confirmar'}
                                                            onChange={(v) => {
                                                                let hours = day.afternoon.hours;
                                                                hours[0] = moment(v).format('HH:mm');

                                                                this.setState({ company_hours: { ...this.state.company_hours, [key]: { ...this.state.company_hours[key], afternoon: { ...this.state.company_hours[key].afternoon, hours } } } });
                                                            }}
                                                            value={moment(day.afternoon.hours[0], 'HH:mm')}
                                                        />
                                                        <KeyboardTimePicker
                                                            style={{ width: '100%', marginBottom: 30, paddingLeft: 5 }}
                                                            invalidDateMessage={false}
                                                            format={'HH:mm'}
                                                            autoOk={true}
                                                            label="Fim Tarde/Noite"
                                                            cancelLabel={'Cancelar'}
                                                            okLabel={'Confirmar'}
                                                            onChange={(v) => {
                                                                let hours = day.afternoon.hours;
                                                                hours[1] = moment(v).format('HH:mm');

                                                                this.setState({ company_hours: { ...this.state.company_hours, [key]: { ...this.state.company_hours[key], afternoon: { ...this.state.company_hours[key].afternoon, hours } } } });
                                                            }}
                                                            value={moment(day.afternoon.hours[1], 'HH:mm')}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                                : null}
                                        </div>
                                        : null
                                    }
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        );
    }

    renderRecipientInfo() {
        return (
            <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', alignItems: 'center', fontWeight: 'bold', marginTop: 10 }}>
                <InfoRoundedIcon style={{ fontSize: 30, marginRight: 8 }} />
                <div>Antes de aparecer no App, você precisa cadastrar uma <a style={{ color: 'orange' }} href={'/payment_account'}>conta de pagamento</a>.</div>
            </div>
        )
    }

    renderVisible() {
        return (
            <div>
                <FormLabel style={{ marginTop: 18, fontWeight: '800' }} component="legend">Aparecer no App</FormLabel>
                <FormLabel style={{ fontSize: 14, marginTop: 5 }} component="legend">Marcando esta opção sua acadêmia e serviços ficam ou não visível para os usuários do App.</FormLabel>
                <div style={{ flexDirection: 'row', paddingTop: 10 }}>
                    <IosSwitch disabled={!SessionHelper.getData().company.id_recipient_pagarme} label="Visível" checked={this.state.visible} onChange={(v) => { this.setState({ visible: v }) }} />
                </div>
                {!SessionHelper.getData().company.id_recipient_pagarme && !this.state.visible ? this.renderRecipientInfo() : null}
            </div>
        );
    }

    checkServices(index, checked) {
        let services = this.state.services;

        services[index].checked = checked;

        this.setState({ services });
    }

    handleSportSelection(value) {
        if (value) {
            let sports = this.state.sports || [];

            if (sports.includes(value)) {
                let index = sports.indexOf(value);

                if (index > -1) sports.splice(index, 1);
            } else {
                sports.push(value);
            }

            this.setState({ sports });
        }
    }

    getSportLookup() {
        return {
            'tennis': 'Tennis',
            'padel': 'Padel',
            'beach_tennis': 'Beach Tennis',
            'squash': 'Squash',
            'badminton': 'Badminton',
            'beach_volley': 'Vôlei de Praia',
            'foot_volley': 'Futevôlei',
            'others': 'Outros'
        }
    }

    renderServices() {
        return (
            <div>
                <FormLabel style={{ marginTop: 18, fontWeight: '800' }} component="legend">Serviços Oferecidos</FormLabel>
                <FormLabel style={{ fontSize: 14, marginTop: 5 }} component="legend">Aqui você seleciona quais destes serviços você oferece, que ficarão visíveis para os usuários do App.</FormLabel>
                <div style={{ flexDirection: 'row', paddingTop: 10 }}>
                    <IosSwitch label={this.state.services[0].label} checked={this.state.services[0].checked} onChange={(v) => { this.checkServices(0, v) }} />
                    <IosSwitch label={this.state.services[1].label} checked={this.state.services[1].checked} onChange={(v) => { this.checkServices(1, v) }} />
                    <IosSwitch label={this.state.services[2].label} checked={this.state.services[2].checked} onChange={(v) => { this.checkServices(2, v) }} />
                </div>
                <div style={{ width: '30%', paddingTop: 15 }}>
                    {this.state.services[1].checked ? <CurrencyInput label={'Valor por Hora (Aula Avulsa)'} onChange={(values) => { const { formattedValue, value } = values; this.setState({ privateLessonPrice: formattedValue, privateLessonRawPrice: value }) }} value={this.state.privateLessonPrice} /> : null}
                    {this.state.services[2].checked ? <CurrencyInput label={'Valor por Hora (Aula Experimental)'} onChange={(values) => { const { formattedValue, value } = values; this.setState({ experimentalLessonPrice: formattedValue, experimentalLessonRawPrice: value }) }} value={this.state.experimentalLessonPrice} /> : null}
                    {this.state.services[1].checked || this.state.services[2].checked ? <div style={{ paddingBottom: 10, display: 'flex', flexDirection: 'row', color: 'orange', alignItems: 'center' }}><InfoRoundedIcon style={{ paddingRight: 5 }} />{'Vazio ou R$ 0,00 significa gratuíto'}</div> : null}
                </div>
                <FormLabel style={{ marginTop: 18, fontWeight: '800' }} component="legend">Esportes</FormLabel>
                <FormLabel style={{ fontSize: 14, marginTop: 5 }} component="legend">Aqui você seleciona quais esportes a sua acadêmia oferece.</FormLabel>
                <div style={{ flexDirection: 'row', paddingTop: 10, maxWidth: '95%' }}>
                    {Object.keys(this.getSportLookup()).map(sportKey => (
                        <IosSwitch label={this.getSportLookup()[sportKey]} checked={this.state.sports.includes(sportKey)} onChange={(v) => { this.handleSportSelection(sportKey) }} />
                    ))}
                </div>
            </div>
        );
    }

    renderTaxInfo() {
        return (
            <div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10, fontWeight: 'bold' }}>
                        <CreditCardIcon style={{ marginRight: 5, color: '#1a1f71' }} />
                        {`Cartão de Crédito:`}
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div>
                            <div>Taxa à vista</div>
                            <div style={{ fontWeight: 'bold' }}>{PagarmeConstants.getKeys().credit_card_tax.toFixed(2)}% + {CurrencyHelper.centsToMoney(PagarmeConstants.getKeys().easydata_transaction_amount)}</div>
                        </div>
                        <div style={{ marginLeft: 25 }}>
                            <div>2 a 6 parcelas</div>
                            <div style={{ fontWeight: 'bold' }}>{PagarmeConstants.getKeys().credit_card_tax_2_6.toFixed(2)}% + {CurrencyHelper.centsToMoney(PagarmeConstants.getKeys().easydata_transaction_amount)}</div>
                        </div>
                        <div style={{ marginLeft: 25 }}>
                            <div>7 parcelas ou mais</div>
                            <div style={{ fontWeight: 'bold' }}>{PagarmeConstants.getKeys().credit_card_tax_7.toFixed(2)}% + {CurrencyHelper.centsToMoney(PagarmeConstants.getKeys().easydata_transaction_amount)}</div>
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10, marginTop: 25, fontWeight: 'bold' }}>
                        <DescriptionIcon style={{ marginRight: 5, color: 'orange' }} />
                        {`Boleto:`}
                    </div>
                    <div>Compensado</div>
                    <div style={{ fontWeight: 'bold' }}>{CurrencyHelper.centsToMoney(PagarmeConstants.getKeys().boleto_tax)} + {CurrencyHelper.centsToMoney(PagarmeConstants.getKeys().easydata_transaction_amount_boleto)}</div>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10, marginTop: 25, fontWeight: 'bold' }}>
                        <img style={{ width: 22, heigth: 22, marginLeft: 3, marginRight: 5 }} src={process.env.PUBLIC_URL + '/icons/pix.png'} />
                        {`Pix:`}
                    </div>
                    <div>Pago</div>
                    <div style={{ fontWeight: 'bold' }}>{PagarmeConstants.getKeys().pix_tax.toFixed(2)}% + {CurrencyHelper.centsToMoney(PagarmeConstants.getKeys().easydata_transaction_amount_pix)}</div>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10, marginTop: 25, fontWeight: 'bold' }}>
                        <AttachMoneyIcon style={{ marginRight: 5, color: Colors.success }} />
                        {`Transferências:`}
                    </div>
                    <div>DOC/TED</div>
                    <div style={{ fontWeight: 'bold' }}>{CurrencyHelper.centsToMoney(PagarmeConstants.getKeys().transfer_tax)}</div>
                </div>
            </div>
        )
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <Box boxShadow={3}>
                    <Tabs
                        style={{ backgroundColor: '#fff' }}
                        value={this.state.tab}
                        variant="fullWidth"
                        TabIndicatorProps={{ style: { background: Colors.primary } }}
                        onChange={(event, index) => { this.handleChange(index) }}>
                        <Tab label={<span style={{ fontWeight: this.state.tab === 0 ? 'bold' : '', color: Colors.primary }}>Dados Cadastrais</span>} />
                        <Tab label={<span style={{ fontWeight: this.state.tab === 1 ? 'bold' : '', color: Colors.primary }}>Configurações do App</span>} />
                        <Tab label={<span style={{ fontWeight: this.state.tab === 2 ? 'bold' : '', color: Colors.primary }}>Taxas e Serviços</span>} />
                    </Tabs>
                    <this.TabPanel value={this.state.tab} index={0}>
                        {this.renderCompanyData()}
                    </this.TabPanel>
                    <this.TabPanel value={this.state.tab} index={1}>
                        {this.renderVisible()}
                        {this.state.visible ? this.renderPhotos() : null}
                        {this.state.visible ? this.renderServices() : null}
                        {this.state.visible ? this.renderPaymentMethods() : null}
                        {this.state.visible ? this.renderLessonParameters() : null}
                        {this.state.visible ? this.renderCompanyHours() : null}
                        <div style={{ paddingTop: 18 }}>
                            <DefaultButton width={'100%'} onClick={() => { this.save() }} title={'Salvar'} />
                        </div>
                    </this.TabPanel>
                    <this.TabPanel value={this.state.tab} index={2}>
                        {this.renderTaxInfo()}
                    </this.TabPanel>
                </Box>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
