import React from 'react';
import { Button, Stepper, Step, StepLabel, RadioGroup, FormControlLabel, Radio, TextareaAutosize, InputLabel, Select, MenuItem, Accordion, AccordionSummary, AccordionDetails, Tooltip, FormLabel } from '@material-ui/core';
import PaymentIcon from '@material-ui/icons/Payment';
import SchoolIcon from '@material-ui/icons/School';
import CheckIcon from '@material-ui/icons/Check';
import DescriptionIcon from '@material-ui/icons/Description';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SyncIcon from '@material-ui/icons/Sync';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CancelIcon from '@material-ui/icons/Cancel';
import EmailIcon from '@material-ui/icons/Email';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import DefaultButton from '../components/DefaultButton';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import DefaultSelect from '../components/DefaultSelect';
import IosSwitch from '../components/IosSwitch';
import CurrencyInput from '../components/CurrencyInput';
import Colors from '../constants/Colors';
import Firestore from '../api/firebase/Firestore';
import ClipLoader from "react-spinners/ClipLoader";
import SessionHelper from '../helper/SessionHelper';
import { toast } from 'react-toastify';
import CurrencyHelper from '../helper/CurrencyHelper';
import InputMask from 'react-input-mask';
import Pagarme from '../api/pagarme/Pagarme';
import NotificationHelper from '../helper/NotificationHelper';
import PagarmeConstants from '../constants/PagarMe';
import moment from "moment-timezone";
import RelationField from './grid/RelationField';
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import PaymentMethodHelper from '../helper/PaymentMethodHelper';
import MembershipTermsHelper from '../helper/MembershipTermsHelper';
import Functions from '../api/firebase/Functions';
import StudentSubscriptionTermStatus from './StudentSubscriptionTermStatus';
import ReportHelper from '../helper/ReportHelper';

export default class StudentDetailPanel extends React.Component {

    state = {
        activeStep: 0,
        steps: ['Dados do Aluno', 'Plano', 'Configuração de Cobrança', 'Contrato', 'Finalização'],
        loading: true,
        loadingModal: false,
        subscriptionActivateModal: false,
        user: null,
        studentSubscriptions: [],
        planDocs: [],
        activePlans: [],
        planType: 'existing_plan',
        selectedPlan: null,
        selectedPlanDoc: null,
        selectedPaymentMethod: 'boleto',
        cancelSubscriptionModal: false,
        planSwitchModal: false,
        selectedSubscription: null,
        inactiveSubscription: false,
        //boleto fine
        applyBoletoFine: 'not_apply_fine',
        boletoFineDays: '',
        boletoFineAmount: null,
        boletoFineAmountRaw: null,
        boletoFinePercentage: '',
        boletoFineType: 'value',
        //boleto interest
        applyBoletoInterest: 'not_apply_interest',
        boletoInterestDays: '',
        boletoInterestAmount: null,
        boletoInterestAmountRaw: null,
        boletoInterestPercentage: '',
        boletoInterestType: 'value',
        // plan creation
        name: '',
        description: '',
        paymentType: ['boleto', 'credit_card'],
        price: null,
        rawPrice: null,
        days: '',
        charges: null,
        installments: '',
        invoiceReminder: '',
        active: true,
        inifinite: true,
        student_specific_plan: false,
        lessons: '',
        amountPaid: null,
        rawAmountPaid: null,
        paymentOptions: [
            { label: 'Pix', key: 'pix' },
            { label: 'Dinheiro', key: 'money' },
            { label: 'Cartão de Crédito', key: 'credit-card' },
            { label: 'Cartão de Débito', key: 'debit-card' },
        ],
        payment_method: '',
        obs: '',
        boletoExpirationDate: moment().add(3, 'days').toDate(),
        card_number: '',
        card_expiration_date: '',
        card_cvv: '',
        credit_card_name: '',
        planMembershipTerms: [],
        selectedMembershipTerm: null,
        parsedTerm: '',
        subscriptionTermModal: false,
        subscriptionTerm: '',
        addSubscriptionTermModal: false,
        kids: [],
        sendTermEmailModal: false,
        sendTermEmail: '',
        sendBoletoEmailModal: false,
        sendBoletoEmail: '',
    }

    async componentDidMount() {

        await this.getUser();
        await this.getPlans();
        await this.getUserPlans();

        this.setState({ loading: false });
    }

    async getUser() {

        let query = await Firestore.getDoc('user', this.props.user);

        if (query.exists) {

            let user = { ...query.data(), id: query.id };

            let kids = [];

            let kidsQuery = await Firestore.customQuery('user').where('parent_id', '==', user.id).get();

            kidsQuery.forEach((doc, key) => {
                let data = doc.data();
                data.id = doc.id;

                kids.push(data);
            });

            this.setState({ user, kids });
        }
    }

    async getUserPlans(loading) {

        if (loading) { await this.setState({ loading: true }) };

        let query = await Firestore.customQuery('student_subscription').where('id_company', '==', SessionHelper.getData().id_company).where('id_user', '==', this.state.user.id).orderBy('created_at', 'desc').get();
        let docs = [];
        let inactiveSubscription = false;

        for await (let doc of query.docs) {

            let data = { ...doc.data(), id: doc.id, expanded: false };

            if (data.id_plan) {

                data.plan = this.state.planDocs.find(item => item.id === data.id_plan);
            }

            if (data.id_subscription_pagarme) {

                let response = await Pagarme.get(`/subscriptions/${data.id_subscription_pagarme}`);

                if (response.id) {

                    data.subscription_pagarme = response;

                    if (doc.id === query.docs[0].id) {

                        if (data.subscription_pagarme.status === 'canceled' || data.subscription_pagarme.status === 'ended') {

                            inactiveSubscription = true;
                        }
                    }
                }
            }

            docs.push(data);
        }

        this.setState({ studentSubscriptions: docs, inactiveSubscription: inactiveSubscription });

        if (loading) { await this.setState({ loading: false }) };
    }

    async getPlans() {
        let query = await Firestore.customQuery('student_plan').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc').get();
        let docs = [];
        let activePlans = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            if (data.active) {
                activePlans.push(data);
            }

            docs.push(data);
        });

        this.setState({ planDocs: docs, activePlans: activePlans });
    }

    async getPlanMembershipTerms(id = null) {
        let query = await Firestore.customQuery('membership_terms').where('id_company', '==', SessionHelper.getData().id_company).where('plans', 'array-contains', id ? id : this.state.selectedPlan).orderBy('name', 'asc').get();
        let docs = [];
        let activePlans = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            if (data.active) {
                activePlans.push(data);
            }

            docs.push(data);
        });

        this.setState({ planMembershipTerms: docs });
    }

    async handleNext() {

        if (this.state.loadingModal == true) {
            toast.warn('Aguarde, realizando ação...');
            return;
        }

        let isValid = true;
        let step = this.state.activeStep;

        this.setState({ loadingModal: true });

        if (step === 0) {

            isValid = await this.validateUserData();

        } else if (step === 1) {

            isValid = await this.validatePlan();

            if (isValid && this.state.selectedPlan) {

                await this.getPlanMembershipTerms();
            }

        } else if (step === 2) {

            isValid = await this.validatePaymentConfiguration();

        } else if (step === 3) {

            isValid = await this.finish();

            if (isValid) {

                await this.getPlans();
                await this.getUserPlans();
            }
        }

        if (isValid) { step++ }

        this.setState({ activeStep: step, loadingModal: false });
    };

    async validateUserData() {

        let flag = true;

        if (!this.state.user.name || !this.state.user.email || !this.state.user.cpf || !this.state.user.phone || !this.state.user.street || !this.state.user.number || !this.state.user.neighbourhood || !this.state.user.zipcode) {

            flag = false;
            toast.warn('Esse aluno possuí dados com pendência')
        }

        return flag;
    }

    async validatePaymentConfiguration() {

        let flag = true;

        if (this.state.selectedPaymentMethod === 'boleto') {

            if (this.state.applyBoletoFine === 'apply_fine') {

                let days = parseInt(this.state.boletoFineDays.replace(/[^\d]/g, ''));
                let percentage = parseFloat(this.state.boletoFinePercentage.replace(/[^\d\.]/g, ''));
                let value = parseFloat(this.state.boletoFineAmountRaw);

                if (this.state.boletoFineType === 'value' && (!value > 0 || !days > 0)) {

                    flag = false;
                    toast.warn('Preencha corretamente os dados da multa');
                }

                if (this.state.boletoFineType === 'percentage' && (!percentage > 0 || !days > 0)) {

                    flag = false;
                    toast.warn('Preencha corretamente os dados da multa');
                }
            }

            if (this.state.applyBoletoInterest === 'apply_interest') {

                let days = parseInt(this.state.boletoInterestDays.replace(/[^\d]/g, ''));
                let percentage = parseFloat(this.state.boletoInterestPercentage.replace(/[^\d\.]/g, ''));
                let value = parseFloat(this.state.boletoInterestAmountRaw);

                if (this.state.boletoInterestType === 'value' && (!value > 0 || !days > 0)) {

                    flag = false;
                    toast.warn('Preencha corretamente os dados de juros');
                }

                if (this.state.boletoInterestType === 'percentage' && (!percentage > 0 || !days > 0)) {

                    flag = false;
                    toast.warn('Preencha corretamente os dados de juros');
                }
            }

        } else if (this.state.selectedPaymentMethod === 'credit_card') {

            if (!this.state.card_number || !this.state.card_expiration_date || !this.state.card_cvv || !this.state.credit_card_name) {

                flag = false;
                toast.warn('Preencha todos os campos do cartão');
            }
        }

        return flag;
    }

    async validatePlan() {

        let flag = true;

        if (this.state.planType === 'existing_plan' && !this.state.selectedPlan) {

            flag = false;
            toast.warn('Selecione um plano para prosseguir');
        }

        if (this.state.planType === 'new_plan' && (!this.state.name || !this.state.rawPrice || !this.state.paymentType.length || !this.state.invoiceReminder || !this.state.lessons || !this.state.days)) {

            flag = false;
            toast.warn('Preencha todos os campos corretamente');
        }

        return flag;
    }

    async notifyUser() {

        let userDoc = this.state.user;

        if (userDoc && userDoc.notification_token && userDoc.notification_token.data) {

            let token = userDoc.notification_token.data;
            let notification = {
                icon: 'paperclip',
                color: 'green',
                navigate: 'StudentSubscription',
            };

            NotificationHelper.send('Matrícula Ativa', `${SessionHelper.getData().company.name} ativou a sua matrícula, abra o app e confira.`, notification, token, userDoc.id);
        }
    }

    async finish() {

        try {

            let planId = null;
            let planDoc = null;
            let planPrice = null;

            if (this.state.planType === 'existing_plan') {

                planId = this.state.selectedPlanDoc.id_plan_pagarme;
                planPrice = this.state.selectedPlanDoc.price;
                planDoc = this.state.selectedPlan;

            } else {

                planDoc = await Firestore.getId('student_plan');

                let data = {
                    name: this.state.name,
                    description: this.state.description,
                    price: this.state.rawPrice * 100,
                    payment_type: this.state.paymentType,
                    lessons: this.state.lessons,
                    days: this.state.days,
                    charges: this.state.charges,
                    inifinite: this.state.inifinite,
                    installments: 1,
                    invoice_reminder: this.state.invoiceReminder,
                    active: this.state.student_specific_plan,
                    id_company: SessionHelper.getData().id_company,
                    date: new Date(),
                };

                let body = {
                    amount: data.price,
                    days: this.state.days,
                    charges: this.state.charges,
                    installments: 1,
                    name: data.name,
                    payment_methods: data.payment_type,
                };

                if (data.invoice_reminder > 0) {
                    body.invoice_reminder = data.invoice_reminder;
                }

                let response = await Pagarme.post('/plans', body);

                if (response.errors && response.errors.length) {
                    throw new Error('Pagarme Error');
                }

                data.id_plan_pagarme = response.id;
                planId = response.id;
                planPrice = data.price;

                await Firestore.insert(data, 'student_plan', planDoc);
            }

            let studentSubscriptionDoc = await Firestore.getId('student_subscription');
            let tax = this.state.selectedPaymentMethod === 'boleto' ? PagarmeConstants.getKeys().easydata_transaction_amount_boleto : PagarmeConstants.getKeys().easydata_transaction_amount;

            let subscription = {
                plan_id: planId,
                payment_method: this.state.selectedPaymentMethod,
                postback_url: PagarmeConstants.getKeys().student_subscription_postback,
                customer: {
                    email: this.state.user.email,
                    name: this.state.user.name,
                    document_number: this.state.user.cpf.replace(/[^\d]/g, ''),
                    address: {
                        street: this.state.user.street,
                        street_number: this.state.user.number.toString().replace(/[^\d]/g, ''),
                        complementary: this.state.user.complement,
                        neighborhood: this.state.user.neighbourhood,
                        zipcode: this.state.user.zipcode.replace(/[^\d]/g, ''),
                    },
                    phone: {
                        ddd: this.state.user.phone.toString().substring(2, 4),
                        number: this.state.user.phone.toString().substring(4, this.state.user.phone.toString().length - 1),
                    },
                },
                split_rules: [
                    {
                        recipient_id: PagarmeConstants.getKeys().easydata_recipient_id,
                        liable: false,
                        charge_processing_fee: false,
                        amount: tax,
                        charge_remainder: true,
                    },
                    {
                        recipient_id: SessionHelper.getData().company.id_recipient_pagarme,
                        liable: true,
                        charge_processing_fee: true,
                        amount: planPrice - tax,
                        charge_remainder: false,
                    }
                ],
                metadata: {
                    id_plan: planDoc,
                    id_user: this.state.user.id,
                    id_company: SessionHelper.getData().id_company,
                    type: 'student_plan',
                    id_student_subscription: studentSubscriptionDoc,
                }
            };

            if (this.state.selectedPaymentMethod === 'boleto') {

                subscription.boleto_expiration_date = moment(this.state.boletoExpirationDate).format('YYYY-MM-DD');

                if (this.state.applyBoletoFine === 'apply_fine') {

                    subscription.boleto_fine = {
                        days: this.state.boletoFineDays,
                    };

                    if (this.state.boletoFineType === 'value') {

                        subscription.boleto_fine.amount = this.state.boletoFineAmountRaw * 100;

                    } else {

                        subscription.boleto_fine.percentage = this.state.boletoFinePercentage.replace(/[^\d\.]/g, '');
                    }
                }

                if (this.state.applyBoletoInterest === 'apply_interest') {

                    subscription.boleto_interest = {
                        days: this.state.boletoInterestDays,
                    };

                    if (this.state.boletoInterestType === 'value') {

                        subscription.boleto_interest.amount = this.state.boletoInterestAmountRaw * 100;

                    } else {

                        subscription.boleto_interest.percentage = this.state.boletoInterestPercentage.replace(/[^\d\.]/g, '');
                    }
                }

            } else if (this.state.selectedPaymentMethod === 'credit_card') {

                subscription.card_number = this.state.card_number;
                subscription.card_expiration_date = this.state.card_expiration_date;
                subscription.card_cvv = this.state.card_cvv;
                subscription.card_holder_name = this.state.credit_card_name;
            }

            let subscriptionResponse = await Pagarme.post('/subscriptions', subscription);

            if (subscriptionResponse.errors && subscriptionResponse.errors.length) {

                throw new Error('Subscription Error');
            }

            let studentSubscription = {
                id_company: SessionHelper.getData().id_company,
                id_user: this.state.user.id,
                id_plan: planDoc,
                id_subscription_pagarme: subscriptionResponse.id,
                status: subscriptionResponse.status,
                created_at: new Date(),
            };

            if (this.state.selectedMembershipTerm) {

                let membershipTerm = this.state.planMembershipTerms.find(item => item.id === this.state.selectedMembershipTerm);

                let planDataQuery = await Firestore.getDoc('student_plan', planDoc);
                let planData = planDataQuery.data();

                if (membershipTerm) {

                    let studentSubscriptionTermDoc = await Firestore.getId('student_subscription_term');

                    let subscriptionMembershipTerm = {
                        id_student_subscription: studentSubscriptionDoc,
                        id_membership_term: membershipTerm.id,
                        content: MembershipTermsHelper.parseTermVariables(membershipTerm.content, membershipTerm, SessionHelper.getData().company, this.state.user, planData, moment(this.state.boletoExpirationDate).format('DD'), this.state.kids),
                        signed: false,
                        signature: '',
                        id_company: SessionHelper.getData().id_company,
                        created_at: new Date(),
                    };

                    await Firestore.insert(subscriptionMembershipTerm, 'student_subscription_term', studentSubscriptionTermDoc);
                    studentSubscription.id_student_subscription_term = studentSubscriptionTermDoc;

                    await Functions.request('POST', `sendContractEmail`,
                    {
                        email: this.state.user.email,
                        hashUrl: `${window.location.protocol}//${window.location.host}/sign_contract?hash=${studentSubscriptionDoc}`,
                        companyName: SessionHelper.getData().company.name,
                        userName: this.state.user.name,
                    }
                );
                }
            }

            await Firestore.insert(studentSubscription, 'student_subscription', studentSubscriptionDoc);

            this.notifyUser();

            return true;

        } catch (error) {

            toast.error('Houve um problema ao matrícular esse aluno, entre em contato conosco');
            return false;
        }
    }

    handleBack() {

        let step = this.state.activeStep;
        step--;

        this.setState({ activeStep: step, inputError: false });
    };

    renderIcon(step) {

        return (
            <div style={{ backgroundColor: this.state.activeStep < step ? 'lightgrey' : Colors.primary, width: 30, height: 30, padding: 5, fontSize: 14, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ color: '#FFFFFF' }}>{this.state.activeStep < step + 1 ? (step + 1) : <CheckIcon style={{ fontSize: 20, paddingTop: 3 }} />}</div>
            </div>
        )
    }

    renderLoading() {

        return (
            <div style={{ display: 'flex', justifyContent: 'center', width: '50%', padding: 25 }}>
                <ClipLoader
                    size={25}
                    color={Colors.primary}
                    loading={true}
                />
            </div>
        )
    }

    async toggleSubscription(key) {

        let studentSubscriptions = this.state.studentSubscriptions;
        studentSubscriptions[key].expanded = !studentSubscriptions[key].expanded;
        studentSubscriptions[key].loading_transactions = true;

        await this.setState({ studentSubscriptions });
        await this.fetchData(key);
    }

    async fetchData(key) {

        let studentSubscriptions = this.state.studentSubscriptions;

        studentSubscriptions[key].loading_transactions = false;

        if (studentSubscriptions[key].id_subscription_pagarme) {

            let response = await Pagarme.get(`/subscriptions/${studentSubscriptions[key].id_subscription_pagarme}/transactions`, 'page=1&count=1000');

            if (response.length) {

                studentSubscriptions[key].transactions = response;

            } else {

                studentSubscriptions[key].transactions = [];
            }
        }

        if (studentSubscriptions[key].id_student_subscription_term) {

            let termQuery = await Firestore.getDoc('student_subscription_term', studentSubscriptions[key].id_student_subscription_term);
            let term = termQuery.data();

            studentSubscriptions[key].subscription_term = term;
        }

        this.setState({ studentSubscriptions });
    }

    renderSubscriptionStatus(subscription) {

        let status = subscription.status;
        let text;
        let color;

        if (subscription.payment_method === 'boleto') {
                        
            let expirationDate = subscription.current_transaction.boleto_expiration_date;
            
            if (expirationDate) {

                expirationDate = moment(expirationDate).startOf('day');

                if (moment().startOf('day').isSameOrBefore(expirationDate)) {

                    status = 'pending_payment';
                }
            }
        }

        if (status === 'paid') {

            text = 'Pagamento em dia';
            color = Colors.success;

        } else if (status === 'trialing') {

            text = 'Período Gratuíto';
            color = Colors.danger;

        } else if (status === 'canceled') {

            text = 'Cancelado';
            color = Colors.error;

        } else if (status === 'ended') {

            text = 'Encerrado';
            color = Colors.success;

        } else if (status === 'pending_payment') {

            text = 'Aguardando Pagamento';
            color = Colors.danger;

        } else if (status === 'unpaid') {

            text = 'Inadimplente';
            color = Colors.error;
        }

        return <b style={{ backgroundColor: color, color: 'white', paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8, borderRadius: 5, width: 200, textAlign: 'center' }}>{text}</b>
    }

    renderTransactionStatus(status, skippedInstallments, installment, subscription) {

        let text;
        let color;
        let tooltip = null;

        if (status === 'paid' || (skippedInstallments && skippedInstallments.length && skippedInstallments.includes(installment))) {

            if (skippedInstallments && skippedInstallments.length && skippedInstallments.includes(installment)) {

                text = 'Baixa Manual';

                if (subscription.settled_charges) {

                    let skipInstallmentData = subscription.settled_charges[installment];

                    if (skipInstallmentData) {

                        let paymentMethod = this.state.paymentOptions.find(item => item.key === skipInstallmentData.payment_method);

                        let html = (
                            <div>
                                Valor Recebido: {CurrencyHelper.centsToMoney(skipInstallmentData.amount_paid)}
                                <br />Método de Pagamento: {paymentMethod ? paymentMethod.label : 'Não Informado'}
                                <br />Observação: {skipInstallmentData.obs ? skipInstallmentData.obs : '--'}
                                <br /><div style={{ display: 'flex', flexDirection: 'row' }}>Usuário: <RelationField collection={'user'} id={skipInstallmentData.id_user} field={'name'} loading={false} /></div>
                                Data: {moment(skipInstallmentData.date.toDate()).format('DD/MM/YYYY HH:mm')}
                            </div>
                        )
                        tooltip = <Tooltip title={html}><InfoRoundedIcon style={{ color: 'white', fontSize: 15, marginLeft: 8, cursor: 'pointer' }} /></Tooltip>
                    }
                }

            } else {

                text = 'Pagamento Confirmado';
            }

            color = Colors.success;

        } else if (status === 'processing') {

            text = 'Em processamento';
            color = Colors.danger;

        } else if (status === 'authorized') {

            text = 'Autorizado';
            color = Colors.success;

        } else if (status === 'refunded') {

            text = 'Estornado';
            color = Colors.success;

        } else if (status === 'waiting_payment') {

            text = 'Aguardando pagamento';
            color = Colors.danger;

        } else if (status === 'pending_refund') {

            text = 'Processando estorno';
            color = Colors.danger;

        } else if (status === 'refused') {

            text = 'Pagamento recusado';
            color = Colors.error;
        }

        return <b style={{ backgroundColor: color, color: 'white', paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8, borderRadius: 5, width: 200, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>{text} {tooltip}</b>
    }

    renderInstallmentSkip(subscription) {

        if (SessionHelper.getData().type === 'super_admin' || SessionHelper.getData().permission.routes['student_subscription_installment_skip'] !== false) {
            
            return <b onClick={() => { subscription.status === 'unpaid' || subscription.status === 'pending_payment' ? this.setState({ installmentSkipModal: true, selectedSubscription: subscription, amountPaid: subscription.plan.price / 100, rawAmountPaid: subscription.plan.price / 100, payment_method: '', obs: '' }) : toast.warn('Não é possível realizar a baixa em um título pago') }} style={{ backgroundColor: Colors.primary, color: 'white', paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8, borderRadius: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5, cursor: 'pointer' }}><DescriptionIcon style={{ fontSize: 15, marginRight: 5 }} />{'Baixa Manual'}</b>
        }

        return null;
    }

    renderSendEmail(subscription) {

        if (subscription.status === 'unpaid' || subscription.status === 'pending_payment' || subscription.status === 'paid') {
            
            return <b onClick={() => { this.setState({ selectedSubscription: subscription, sendBoletoEmailModal: true, sendBoletoEmail: this.state.user.email }) }} style={{ backgroundColor: Colors.primary, color: 'white', paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8, borderRadius: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5, cursor: 'pointer' }}><EmailIcon style={{ fontSize: 15, marginRight: 5 }} />{'Enviar por E-mail'}</b>;
        }

        return null;
    }

    renderTransactions(transactions, subscription) {

        if (transactions && transactions.length) {

            return transactions.map((transaction, key) => {

                let charge = transactions.length - (key);
                let isSettled = subscription.subscription_pagarme.settled_charges && subscription.subscription_pagarme.settled_charges.length && subscription.subscription_pagarme.settled_charges.includes(charge) ? true : false;

                return (
                    <div style={{ borderTopLeftRadius: key === 0 ? 5 : 0, borderTopRightRadius: key === 0 ? 5 : 0, borderBottomLeftRadius: key === (transactions.length - 1) ? 5 : 0, borderBottomRightRadius: key === (transactions.length - 1) ? 5 : 0, borderTop: '1px solid lightgrey', borderLeft: '1px solid lightgrey', borderRight: '1px solid lightgrey', borderBottom: key === (transactions.length - 1) ? '1px solid lightgrey' : '', padding: 15, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <b style={{ color: Colors.primary }}>{`Parcela ${charge} • ${CurrencyHelper.centsToMoney(transaction.amount)} ${transaction.boleto_expiration_date ? `• Vencimento ${moment(transaction.boleto_expiration_date).format('DD/MM/YYYY')}` : moment(transaction.date_created).format('DD/MM/YYYY')}`} {transaction.status === 'paid' ? `• Pago em ${moment(transaction.date_updated).format('DD/MM/YYYY')}` : isSettled ? `• Baixado em ${moment(subscription.settled_charges[charge].date.toDate()).format('DD/MM/YYYY')}` : null}</b>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {key === 0 && transaction.payment_method === 'boleto' ? this.renderSendEmail(subscription) : null}
                            {key === 0 && transaction.payment_method === 'boleto' ? this.renderInstallmentSkip(subscription) : null}
                            {subscription.subscription_pagarme.payment_method === 'boleto' ? <b onClick={() => { window.open(transaction.boleto_url, '_blank') }} style={{ backgroundColor: Colors.primary, color: 'white', paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8, borderRadius: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5, cursor: 'pointer' }}><DescriptionIcon style={{ fontSize: 15, marginRight: 5 }} /> {transaction.payment_method === 'boleto' ? 'Abrir Boleto' : 'Cartão'}</b> : null}
                            {this.renderTransactionStatus(transaction.status, subscription.subscription_pagarme.settled_charges, charge, subscription)}
                        </div>
                    </div>
                )
            });
        }
    }

    renderContent() {

        return (
            <div style={{ padding: 25 }}>
                <div style={{ marginBottom: 10 }}>
                    <FormLabel>Histórico de Matrículas</FormLabel>
                </div>

                <div style={{ marginBottom: 20 }}><DefaultButton leftIcon={<SchoolIcon />} onClick={() => { this.handleSubscriptionModal() }} title={'Nova Matrícula'} /></div>

                {
                    this.state.studentSubscriptions.map((subscription, key) => {

                        let canCancel = subscription.subscription_pagarme && subscription.subscription_pagarme.status !== 'ended' && subscription.subscription_pagarme.status !== 'canceled';

                        return (
                            <Accordion expanded={subscription.expanded} style={{ borderTop: '1px solid lightgrey', borderLeft: '1px solid lightgrey', borderRight: '1px solid lightgrey', borderBottom: key === (this.state.studentSubscriptions.length - 1) ? '1px solid lightgrey' : '', boxShadow: Colors.boxShadow, margin: 0, width: window.screen.width - (this.props.menuOpen ? 350 : 175) }}>
                                <AccordionSummary style={{ position: 'relative' }} onClick={() => { this.toggleSubscription(key) }} expandIcon={!subscription.expanded ? <ChevronRightIcon /> : <ExpandLessIcon />}>
                                    <StudentSubscriptionTermStatus id_student_subscription_term={subscription.id_student_subscription_term}/>
                                    <h3 style={{ color: Colors.primary, margin: 8, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', gap: 5 }}>
                                        <Tooltip title={subscription.plan.name}>
                                            <div>{ReportHelper.cutName(subscription.plan.name, 15)}</div>
                                        </Tooltip>
                                        <div>{'•'}</div>
                                        <div>{CurrencyHelper.centsToMoney(subscription.plan.price)}/{subscription.plan.days} dias • {subscription.subscription_pagarme ? PaymentMethodHelper.getPaymentMethodLabel(subscription.subscription_pagarme.payment_method) : ''}</div>
                                    </h3>
                                    <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', alignItems: 'center', top: 0, bottom: 0, right: 50 }}>
                                        <div style={{ color: 'grey' }}>{`Ativado em ${moment(subscription.created_at.toDate()).format('DD/MM/YYYY')}`}</div>
                                        <div style={{ marginRight: 8, marginLeft: 8 }} />
                                        {subscription.subscription_pagarme ? this.renderSubscriptionStatus(subscription.subscription_pagarme) : ''}
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
                                        {this.renderSubscriptionTermButton(subscription, canCancel)}
                                        {this.renderSubscriptionTermSendButton(subscription, canCancel)}
                                        <b onClick={() => { canCancel ? this.setState({ paymentSwitchModal: true, selectedSubscription: subscription, selectedPaymentMethod: subscription.subscription_pagarme.payment_method }) : toast.warn('Essa matrícula já foi encerrada') }} style={{ backgroundColor: canCancel ? Colors.primary : 'lightgrey', color: 'white', paddingTop: 8, paddingBottom: 8, paddingLeft: 8, paddingRight: 8, borderRadius: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5, cursor: 'pointer', width: 250, justifyContent: 'center', boxShadow: Colors.boxShadow }}><PaymentIcon style={{ fontSize: 20, marginRight: 5 }} />Alterar Método de Pagamento</b>
                                        <b onClick={() => { canCancel ? this.setState({ planSwitchModal: true, selectedSubscription: subscription, planType: 'existing_plan', selectedPlan: null, selectedPlanDoc: null }) : toast.warn('Essa matrícula já foi encerrada') }} style={{ backgroundColor: canCancel ? Colors.primary : 'lightgrey', color: 'white', paddingTop: 8, paddingBottom: 8, paddingLeft: 8, paddingRight: 8, borderRadius: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5, cursor: 'pointer', width: 175, justifyContent: 'center', boxShadow: Colors.boxShadow }}><SyncIcon style={{ fontSize: 20, marginRight: 5 }} />Alterar Plano</b>
                                        <b onClick={() => { canCancel ? this.setState({ cancelSubscriptionModal: true, selectedSubscription: subscription, }) : toast.warn('Essa matrícula já foi encerrada') }} style={{ backgroundColor: canCancel ? Colors.primary : 'lightgrey', color: 'white', paddingTop: 8, paddingBottom: 8, paddingLeft: 8, paddingRight: 8, borderRadius: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5, cursor: 'pointer', width: 175, justifyContent: 'center', boxShadow: Colors.boxShadow }}><CancelIcon style={{ fontSize: 20, marginRight: 5 }} />Cancelar Matrícula</b>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                        {subscription.loading_transactions ? this.renderLoading() : this.renderTransactions(subscription.transactions, subscription)}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })
                }
            </div>
        )
    }

    renderSubscriptionTermButton(subscription, canCancel) {

        let text = '';
        let state = {};
        let disabled = false;

        if (subscription.subscription_term) {

            text = 'Ver Contrato';
            state.subscriptionTermModal = true;
            state.subscriptionTerm = subscription.subscription_term.content;
            if(subscription.subscription_term.signature?.date) {
                state.subscriptionTerm += `<br/><br/><b>Assinado em ${moment(subscription.subscription_term.signature.date.toDate()).format('DD/MM/YYYY HH:mm:ss')}</b><br/><b>IP: ${subscription.subscription_term.signature.ip}</b><br/><b>Dispositivo: ${subscription.subscription_term.signature.userAgent}}</b>`; 
            }

        } else if (canCancel && !subscription.subscription_term) {

            text = 'Vincular Contrato';
            state.addSubscriptionTermModal = true;
            state.selectedPlan = subscription.plan.id;
            state.selectedSubscription = subscription;

        } else if (!canCancel && !subscription.subscription_term) {

            text = 'Vincular Contrato';
            disabled = true;
        }

        return <b onClick={async () => { disabled ? toast.warn('Essa matrícula já foi encerrada') : await this.getPlanMembershipTerms(subscription.plan.id); this.setState({ ...this.state, ...state }); }} style={{ color: 'white', backgroundColor: disabled ? 'lightgrey' : Colors.primary, paddingTop: 8, paddingBottom: 8, paddingLeft: 8, paddingRight: 8, borderRadius: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5, cursor: 'pointer', justifyContent: 'center', boxShadow: Colors.boxShadow }}><AssignmentTurnedInIcon style={{ fontSize: 20, marginRight: 5 }} />{text}</b>;
    }

    renderSubscriptionTermSendButton(subscription, canCancel) {

        let text = '';
        let state = {};
        let disabled = false;

        if (!canCancel && !subscription.subscription_term) {

            disabled = true;
        }

        if (subscription.subscription_term && !subscription.subscription_term.signature?.date) {

            state.sendTermEmailModal = true;
            state.selectedSubscription = subscription;
            state.sendTermEmail = this.state.user.email;
            text = 'Reenviar Contrato';
            return <b onClick={async () => { disabled ? toast.warn('Essa matrícula já foi encerrada') : this.setState({ ...this.state, ...state }); }} style={{ color: 'white', backgroundColor: disabled ? 'lightgrey' : Colors.primary, paddingTop: 8, paddingBottom: 8, paddingLeft: 8, paddingRight: 8, borderRadius: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5, cursor: 'pointer', justifyContent: 'center', boxShadow: Colors.boxShadow }}><AssignmentTurnedInIcon style={{ fontSize: 20, marginRight: 5 }} />{text}</b>;
        }
    }

    handleSubscriptionModal() {

        const recipient = SessionHelper.getData().company.id_recipient_pagarme;

        if (recipient) {

            let state = {
                subscriptionActivateModal: true,
                activeStep: 0,
                planType: 'existing_plan',
                selectedPlan: null,
                selectedPlanDoc: null,
                selectedPaymentMethod: 'boleto',
                applyBoletoFine: 'not_apply_fine',
                boletoFineDays: '',
                boletoFineAmount: null,
                boletoFineAmountRaw: null,
                boletoFinePercentage: '',
                boletoFineType: 'value',
                applyBoletoInterest: 'not_apply_interest',
                boletoInterestDays: '',
                boletoInterestAmount: null,
                boletoInterestAmountRaw: null,
                boletoInterestPercentage: '',
                boletoInterestType: 'value',
                name: '',
                description: '',
                paymentType: ['boleto', 'credit_card'],
                price: null,
                rawPrice: null,
                days: '',
                charges: null,
                installments: '',
                invoiceReminder: '',
                active: true,
                inifinite: true,
                lessons: '',
                boletoExpirationDate: moment().add(3, 'days').toDate(),
                planMembershipTerms: [],
                selectedMembershipTerm: null,
                parsedTerm: '',
                subscriptionTerm: '',
            };

            this.setState(state);

        } else {

            toast.warn('Para ativar uma matrícula, é necessário ter uma conta de pagamentos cadastrada.', { autoClose: false, onClick: () => { window.location.href = '/payment_account' } })
        }
    }

    renderSubscriptionStart() {

        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '50%', height: 150, padding: 25 }}>
                <DefaultButton leftIcon={<SchoolIcon />} onClick={() => { this.handleSubscriptionModal() }} title={'Ativar Matrícula'} />
                <p style={{ color: 'grey', marginTop: 25 }}>{`${this.state.user.name} não possuí nenhum histórico de matrícula. Clique no botão acima para começar.`}</p>
            </div>
        )
    }

    renderUserData() {

        return (

            <div style={{ display: 'flex', flexDirection: 'column', padding: 10, marginBottom: 25 }}>
                <div style={{ color: 'orange', width: 500, display: 'flex', flexDirection: 'row', fontWeight: 'bold', alignItems: 'center', marginBottom: 25, padding: 15, boxShadow: Colors.boxShadow, borderRadius: 5, border: '0.5px solid lightgrey' }}>
                    <InfoRoundedIcon style={{ fontSize: 25, marginRight: 8 }} />
                    <div>{'Certifique-se que os dados do aluno estão corretos.'}</div>
                </div>
                <div>
                    <div style={{ fontWeight: 'bold', fontSize: 17, color: Colors.primary }}>Nome</div>
                    {this.state.user.name}
                </div>
                <div style={{ marginTop: 8 }}>
                    <div style={{ fontWeight: 'bold', fontSize: 17, color: Colors.primary }}>E-mail</div>
                    {this.state.user.email}
                </div>
                <div style={{ marginTop: 8 }}>
                    <div style={{ fontWeight: 'bold', fontSize: 17, color: Colors.primary }}>Cpf</div>
                    {this.state.user.cpf ? this.state.user.cpf.toString().replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : <div style={{ color: Colors.error }}>{'*Cpf pendente'}</div>}
                </div>
                <div style={{ marginTop: 8 }}>
                    <div style={{ fontWeight: 'bold', fontSize: 17, color: Colors.primary }}>Endereço</div>
                    {this.state.user.street ? this.state.user.street : <div style={{ color: Colors.error }}>{'*Rua pendente'}</div>}
                    {this.state.user.number ? `, ${this.state.user.number}` : <div style={{ color: Colors.error }}>{'*Número pendente'}</div>}
                    {this.state.user.complement ? `, ${this.state.user.complement}` : ''}
                    {this.state.user.neighbourhood ? `, Bairro ${this.state.user.neighbourhood}` : <div style={{ color: Colors.error }}>{'*Bairro pendente'}</div>}
                    {this.state.user.zipcode ? `, ${this.state.user.zipcode}` : <div style={{ color: Colors.error }}>{'*CEP pendente'}</div>}
                </div>
                <div style={{ marginTop: 8 }}>
                    <div style={{ fontWeight: 'bold', fontSize: 17, color: Colors.primary }}>Telefone</div>
                    {this.state.user.phone ? `+${this.state.user.phone}` : <div style={{ color: Colors.error }}>{'*pendente'}</div>}
                </div>
            </div>
        )
    }

    renderNewPlan() {

        return (
            <div>
                <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ name: v }) }} label={'Nome'} />
                <TextareaAutosize style={{ width: '100%', borderRadius: 5, borderColor: 'lightgrey', padding: 15, marginTop: 15, fontSize: '1rem' }} rowsMax={8} rowsMin={8} value={this.state.description} onChange={(v) => { this.setState({ description: v.target.value }) }} placeholder="Descrição" />
                <DefaultInput number required onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ lessons: v }) }} label={'Aulas por Semana'} />

                <CurrencyInput required onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Valor'} onChange={(values) => { const { formattedValue, value } = values; this.setState({ price: formattedValue, rawPrice: value }) }} value={this.state.price} />

                <div style={{ marginBottom: 15, marginTop: 15 }}>
                    <InputLabel id="type">Formas de Pagamento</InputLabel>
                    <Select
                        style={{ width: '100%' }}
                        labelId="tipo"
                        value={this.state.paymentType}
                        multiple={true}
                        onChange={(v) => { this.setState({ paymentType: v.target.value }) }}>
                        <MenuItem value={'boleto'}>{'Boleto'}</MenuItem>
                        <MenuItem value={'credit_card'}>{'Cartão de Crédito'}</MenuItem>
                    </Select>
                </div>

                {this.state.paymentType.includes('boleto') && <DefaultInput onBlur={(v) => { this.setState({ invoiceReminder: v }) }} label={'Lembrete de Pagamento (dias antes do vencimento)'} />}

                <DefaultInput required number onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ days: v }) }} label={'Intervalo de Cobrança (Ex: 30 = uma cobrança a cada 30 dias)'} />

                <div style={{ marginTop: 15 }}>
                    <IosSwitch fullWidth label="Cobrar até o cancelamento do plano" checked={this.state.inifinite} onChange={(v) => { this.setState({ inifinite: v, charges: null }) }} />
                </div>

                <div style={{ marginTop: 15 }}>
                    <IosSwitch fullWidth label="Salvar na lista de planos de matrícula" checked={this.state.student_specific_plan} onChange={(v) => { this.setState({ student_specific_plan: v }) }} />
                </div>

                {!this.state.inifinite ? <DefaultInput required number onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ charges: v }) }} label={'Número de Cobranças'} /> : null}
            </div>
        )
    }

    handlePlanSelection(v) {

        let doc = this.state.planDocs.find(item => item.id === v.target.value);

        this.setState({ selectedPlan: v.target.value, selectedPlanDoc: doc });
    }

    handleMembershipTermChange(v) {

        let term = this.state.planMembershipTerms.find((item => item.id === v));
        let plan = this.state.planDocs.find((item => item.id === this.state.selectedPlan));

        let content = MembershipTermsHelper.parseTermVariables(term.content, term, SessionHelper.getData().company, this.state.user, plan, moment(this.state.boletoExpirationDate).format('DD'), this.state.kids);

        this.setState({ selectedMembershipTerm: v, parsedTerm: content });
    }

    renderExistingPlan() {

        return (
            <div style={{ paddingTop: 25 }}>
                <DefaultSelect search={true} searchField={'name'} valueField={'id'} displayField={'name'} onChange={(v) => { this.handlePlanSelection(v) }} value={this.state.selectedPlan} docs={this.state.activePlans} label={'Selecionar Plano'} />

                {!this.state.selectedPlan ? <p style={{ color: 'grey' }}>Selecione um plano para prosseguir.</p> : null}

                {this.state.selectedPlan ?

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginTop: 25 }}>
                            <div style={{ fontWeight: 'bold', fontSize: 17, color: Colors.primary }}>Nome</div>
                            {this.state.selectedPlanDoc.name}
                        </div>
                        <div style={{ marginTop: 8 }}>
                            <div style={{ fontWeight: 'bold', fontSize: 17, color: Colors.primary }}>Descrição</div>
                            {this.state.selectedPlanDoc.description}
                        </div>
                        <div style={{ marginTop: 8 }}>
                            <div style={{ fontWeight: 'bold', fontSize: 17, color: Colors.primary }}>Aulas por Semana</div>
                            {this.state.selectedPlanDoc.lessons}
                        </div>
                        <div style={{ marginTop: 8 }}>
                            <div style={{ fontWeight: 'bold', fontSize: 17, color: Colors.primary }}>Valor</div>
                            {CurrencyHelper.centsToMoney(this.state.selectedPlanDoc.price)}
                        </div>
                        <div style={{ marginTop: 8 }}>
                            <div style={{ fontWeight: 'bold', fontSize: 17, color: Colors.primary }}>Métodos de Pagamento</div>
                            {this.renderPlanPaymentMethods()}
                        </div>
                        <div style={{ marginTop: 8 }}>
                            <div style={{ fontWeight: 'bold', fontSize: 17, color: Colors.primary }}>Lembrete de Pagamento</div>
                            {this.state.selectedPlanDoc.invoice_reminder ? <div>{`${this.state.selectedPlanDoc.invoice_reminder} dias`}</div> : ''}
                        </div>
                        <div style={{ marginTop: 8 }}>
                            <div style={{ fontWeight: 'bold', fontSize: 17, color: Colors.primary }}>Intervalo de Cobrança</div>
                            {this.state.selectedPlanDoc.days ? <div>{`${this.state.selectedPlanDoc.days} dias`}</div> : ''}
                        </div>
                        <div style={{ marginTop: 8 }}>
                            <div style={{ fontWeight: 'bold', fontSize: 17, color: Colors.primary }}>Número de Cobranças</div>
                            {this.state.selectedPlanDoc.charges ? <div>{`${this.state.selectedPlanDoc.charges}`}</div> : 'Indefinidamente'}
                        </div>
                        <div style={{ marginTop: 8 }}>
                            <div style={{ fontWeight: 'bold', fontSize: 17, color: Colors.primary }}>Cobrar até o Cancelamento</div>
                            {this.state.selectedPlanDoc.inifinite ? 'Sim' : 'Não'}
                        </div>
                    </div>

                    : null}
            </div>
        )
    }

    renderPlanPaymentMethods() {

        let paymentMethods = [];

        this.state.selectedPlanDoc.payment_type.map((paymentMethod, key) => {

            if (paymentMethod === 'boleto') { paymentMethods.push('Boleto') };
            if (paymentMethod === 'credit_card') { paymentMethods.push('Cartão de Crédito') };
        });

        return paymentMethods.join(', ');
    }

    renderPlanSelection() {

        return (
            <div style={{ padding: 10, marginBottom: 25 }}>
                <div>
                    <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} aria-label="type" name="type" value={this.state.planType} onChange={(evt) => { this.setState({ planType: evt.target.value, selectedPlan: null, selectedPlanDoc: null }) }}>
                        <FormControlLabel value="existing_plan" control={<Radio style={{ color: Colors.primary }} />} label="Plano Existente" />
                        <FormControlLabel value="new_plan" control={<Radio style={{ color: Colors.primary }} />} label="Criar Plano" />
                    </RadioGroup>
                </div>
                {this.state.planType === 'existing_plan' ? this.renderExistingPlan() : this.renderNewPlan()}
            </div>
        )
    }

    renderPlanMembershipTermSelection() {

        return (
            <div style={{ padding: 10, marginBottom: 25 }}>
                <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', fontWeight: 'bold', alignItems: 'center', marginBottom: 25, padding: 15, boxShadow: Colors.boxShadow, borderRadius: 5, border: '0.5px solid lightgrey' }}>
                    <InfoRoundedIcon style={{ fontSize: 35, marginRight: 8 }} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {`${this.state.planMembershipTerms.length} contratos disponíveis para esse plano.`}
                        <div style={{ fontWeight: 'normal' }}>{`A vinculação do contrato é opcional, selecione um da lista ou prossiga.`}</div>
                    </div>
                </div>
                <DefaultSelect
                    id={'membership_term_selection'}
                    valueField={'id'}
                    displayField={'name'}
                    value={this.state.selectedMembershipTerm}
                    onChange={(v) => { this.handleMembershipTermChange(v.target.value) }}
                    docs={this.state.planMembershipTerms}
                    label={'Vincular Contrato'}
                />
                {this.state.parsedTerm ? this.renderMembershipTermPreview() : null}
            </div>
        )
    }

    renderMembershipTermPreview() {

        return (

            <div>
                <h2 style={{ marginTop: 20 }}>Pré-visualização do Contrato</h2>
                <div dangerouslySetInnerHTML={{ __html: this.state.parsedTerm }} />
            </div>
        )
    }

    renderCreditCardForm() {
        return (
            <div>
                <InputMask onBlur={(v) => { this.setState({ card_number: v.replace(/[^\d]/g, '') }) }} mask="9999 9999 9999 9999">
                    <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} hasMask={true} number={true} minLength={16} label={'Número do Cartão'} />
                </InputMask>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <InputMask onBlur={(v) => { this.setState({ card_expiration_date: v.replace(/[^\d]/g, '') }) }} mask="99/99">
                        <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} hasMask={true} number={true} minLength={4} label={'Data de Vencimento'} />
                    </InputMask>
                    <div style={{ marginLeft: 5, marginRight: 5 }} />
                    <InputMask onBlur={(v) => { this.setState({ card_cvv: v.replace(/[^\d]/g, '') }) }} maskPlaceholder={''} number={true} mask="9999">
                        <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} number={true} label={'Código Verificador'} />
                    </InputMask>
                </div>
                <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Nome no Cartão'} onBlur={(v) => { this.setState({ credit_card_name: v }) }} />
            </div>
        )
    }

    renderPaymentMethodSelection() {

        if (this.state.planType === 'existing_plan') {

            return (

                <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} aria-label="type" name="type" value={this.state.selectedPaymentMethod} onChange={(evt) => { this.setState({ selectedPaymentMethod: evt.target.value }) }}>
                    {this.state.selectedPlanDoc.payment_type.includes('boleto') ? <FormControlLabel value="boleto" control={<Radio style={{ color: Colors.primary }} />} label="Boleto" /> : null}
                    {this.state.selectedPlanDoc.payment_type.includes('credit_card') ? <FormControlLabel value="credit_card" control={<Radio style={{ color: Colors.primary }} />} label="Cartão de Crédito" /> : null}
                </RadioGroup>
            )

        } else {

            return (

                <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} aria-label="type" name="type" value={this.state.selectedPaymentMethod} onChange={(evt) => { this.setState({ selectedPaymentMethod: evt.target.value }) }}>
                    {this.state.paymentType.includes('boleto') ? <FormControlLabel value="boleto" control={<Radio style={{ color: Colors.primary }} />} label="Boleto" /> : null}
                    {this.state.paymentType.includes('credit_card') ? <FormControlLabel value="credit_card" control={<Radio style={{ color: Colors.primary }} />} label="Cartão de Crédito" /> : null}
                </RadioGroup>
            )
        }
    }

    renderPaymentConfiguration() {

        return (
            <div style={{ padding: 10, marginBottom: 25 }}>
                <div style={{ marginBottom: 20 }}>
                    <InputLabel>Método de Pagamento</InputLabel>
                    {this.renderPaymentMethodSelection()}
                </div>

                {this.state.selectedPaymentMethod === 'boleto' ?

                    <div>
                        <InputLabel style={{ marginBottom: 8 }}>Configurações do Boleto</InputLabel>

                        <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                style={{ width: 400, marginTop: 15 }}
                                invalidDateMessage={false}
                                format={'dd/MM/yyyy'}
                                autoOk={true}
                                label="Data de Vencimento"
                                cancelLabel={'Cancelar'}
                                okLabel={'Confirmar'}
                                onChange={(v) => { this.setState({ boletoExpirationDate: v }) }}
                                minDate={new Date()}
                                value={this.state.boletoExpirationDate}
                            />
                        </MuiPickersUtilsProvider>

                        <RadioGroup style={{ display: 'flex', flexDirection: 'row', marginTop: 15 }} aria-label="type" name="type" value={this.state.applyBoletoFine} onChange={(evt) => { this.setState({ applyBoletoFine: evt.target.value, boletoFineDays: '', boletoFineAmount: null, boletoFineAmountRaw: null, boletoFinePercentage: '', boletoFineType: 'value' }) }}>
                            <FormControlLabel value={'not_apply_fine'} control={<Radio style={{ color: Colors.primary }} />} label="Não aplicar multa" />
                            <FormControlLabel value={'apply_fine'} control={<Radio style={{ color: Colors.primary }} />} label="Aplicar multa" />
                        </RadioGroup>

                        {this.state.applyBoletoFine === 'apply_fine' ?

                            <div style={{ marginLeft: 25 }}>
                                <InputMask onChange={(v) => { this.setState({ boletoFineDays: v }) }} maskPlaceholder={''} mask="99" value={this.state.boletoFineDays}>
                                    <DefaultInput hasMask={true} number={true} required={true} minLength={1} label={'Dias (após o vencimento)'} />
                                </InputMask>

                                <InputLabel style={{ marginTop: 25 }}>Tipo da Multa</InputLabel>
                                <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} aria-label="type" name="type" value={this.state.boletoFineType} onChange={(evt) => { this.setState({ boletoFineType: evt.target.value, boletoFineAmount: null, boletoFineAmountRaw: null, boletoFinePercentage: '' }) }}>
                                    <FormControlLabel value={'value'} control={<Radio style={{ color: Colors.primary }} />} label="Valor" />
                                    <FormControlLabel value={'percentage'} control={<Radio style={{ color: Colors.primary }} />} label="Porcentagem" />
                                </RadioGroup>

                                {this.state.boletoFineType === 'value' ?

                                    <CurrencyInput required onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Valor da Multa'} onChange={(values) => { const { formattedValue, value } = values; this.setState({ boletoFineAmount: formattedValue, boletoFineAmountRaw: value }) }} value={this.state.boletoFineAmount} />

                                    :

                                    <InputMask onChange={(v) => { this.setState({ boletoFinePercentage: v }) }} alwaysShowMask={true} mask="9.99%" value={this.state.boletoFinePercentage}>
                                        <DefaultInput hasMask={true} number={true} required={true} minLength={1} label={'Porcentagem da Multa'} />
                                    </InputMask>

                                }
                            </div>

                            : null}

                        <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} aria-label="type" name="type" value={this.state.applyBoletoInterest} onChange={(evt) => { this.setState({ applyBoletoInterest: evt.target.value, boletoInterestDays: '', boletoInterestAmount: null, boletoInterestAmountRaw: null, boletoInterestPercentage: '', boletoInterestType: 'value' }) }}>
                            <FormControlLabel value={'not_apply_interest'} control={<Radio style={{ color: Colors.primary }} />} label="Não aplicar juros" />
                            <FormControlLabel value={'apply_interest'} control={<Radio style={{ color: Colors.primary }} />} label="Aplicar juros" />
                        </RadioGroup>

                        {this.state.applyBoletoInterest === 'apply_interest' ?

                            <div style={{ marginLeft: 25 }}>
                                <InputMask onChange={(v) => { this.setState({ boletoInterestDays: v }) }} maskPlaceholder={''} mask="99" value={this.state.boletoInterestDays}>
                                    <DefaultInput hasMask={true} number={true} required={true} minLength={1} label={'Dias (após o vencimento)'} />
                                </InputMask>

                                <InputLabel style={{ marginTop: 25 }}>Tipo de Juros</InputLabel>
                                <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} aria-label="type" name="type" value={this.state.boletoInterestType} onChange={(evt) => { this.setState({ boletoInterestType: evt.target.value, boletoInterestAmount: null, boletoInterestAmountRaw: null, boletoInterestPercentage: '' }) }}>
                                    <FormControlLabel value={'value'} control={<Radio style={{ color: Colors.primary }} />} label="Valor" />
                                    <FormControlLabel value={'percentage'} control={<Radio style={{ color: Colors.primary }} />} label="Porcentagem" />
                                </RadioGroup>

                                {this.state.boletoInterestType === 'value' ?

                                    <CurrencyInput required onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Valor do Juros'} onChange={(values) => { const { formattedValue, value } = values; this.setState({ boletoInterestAmount: formattedValue, boletoInterestAmountRaw: value }) }} value={this.state.boletoInterestAmount} />

                                    :

                                    <InputMask onChange={(v) => { this.setState({ boletoInterestPercentage: v }) }} alwaysShowMask={true} mask="9.99%" value={this.state.boletoInterestPercentage}>
                                        <DefaultInput hasMask={true} number={true} required={true} minLength={1} label={'Porcentagem de Juros (Ao mês)'} />
                                    </InputMask>

                                }
                            </div>

                            : null}

                    </div>

                    : this.renderCreditCardForm()}
            </div>
        )
    }

    renderFinish() {

        return (
            <div style={{ marginBottom: 50, width: '100%', height: 400, overflowY: 'scroll', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 50 }}>
                <img style={{ height: 225 }} src={process.env.PUBLIC_URL + '/ilustrations/confirm_link_page.png'} />
                <h1 style={{ textAlign: 'center' }}>Sucesso!<br />O seu aluno foi matrículado.</h1>
                <p style={{ textAlign: 'center' }}>Feche essa janela para visualizar as novas informações.</p>
            </div>
        )
    }

    subscriptionActivateModal() {

        if (this.state.subscriptionActivateModal) {

            return (
                <div>
                    <Stepper style={{ width: '100%' }} activeStep={this.state.activeStep} alternativeLabel>
                        {this.state.steps.map((label, key) => (
                            <Step key={label}>
                                <StepLabel icon={this.renderIcon(key)}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    {this.state.activeStep === 0 && this.renderUserData()}
                    {this.state.activeStep === 1 && this.renderPlanSelection()}
                    {this.state.activeStep === 2 && this.renderPaymentConfiguration()}
                    {this.state.activeStep === 3 && this.renderPlanMembershipTermSelection()}
                    {this.state.activeStep === 4 && this.renderFinish()}

                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <Button style={{ width: '50%', marginRight: 5, fontWeight: 'bold' }} variant="contained" disabled={this.state.activeStep === 0 || this.state.activeStep === this.state.steps.length - 1} onClick={() => { this.handleBack() }}>{'Voltar'}</Button>
                        <Button style={{ width: '50%', marginLeft: 5, backgroundColor: Colors.primary, fontWeight: 'bold' }} variant="contained" color="primary" onClick={() => { this.state.activeStep === this.state.steps.length - 1 ? this.setState({ activeStep: 0, subscriptionActivateModal: false }) : this.handleNext() }}>{this.state.activeStep === this.state.steps.length - 1 ? 'Fechar' : 'Avançar'}</Button>
                    </div>
                </div>
            )
        }
    }

    async cancelSubscription() {

        if (this.state.loadingModal == true) {
            toast.warn('Aguarde, realizando ação...');
            return;
        }

        try {

            this.setState({ loadingModal: true });

            let actualSubscription = this.state.selectedSubscription;
            let response = await Pagarme.post(`/subscriptions/${actualSubscription.id_subscription_pagarme}/cancel`, {});

            if (response.errors && response.errors.length) {
                throw new Error('Pagarme Error');
            }

            this.setState({ loadingModal: false, cancelSubscriptionModal: false, selectedSubscription: null });

            toast.success('Plano cancelado com sucesso');
            await this.getUserPlans(true);

        } catch (error) {

            this.setState({ loadingModal: false });
            toast.error('Houve um problema ao alterar o plano');
        }
    }

    async switchPlan() {

        if (this.state.loadingModal == true) {
            toast.warn('Aguarde, realizando ação...');
            return;
        }

        let isValid = await this.validatePlan();

        if (isValid) {

            let actualSubscription = this.state.selectedSubscription;

            if ((actualSubscription && actualSubscription.plan.id !== this.state.selectedPlan) || this.state.planType === 'new_plan') {

                try {

                    this.setState({ loadingModal: true });

                    let planId = null;
                    let planDoc = null;

                    if (this.state.planType === 'existing_plan') {

                        planId = this.state.selectedPlanDoc.id_plan_pagarme;
                        planDoc = this.state.selectedPlan;

                    } else {

                        planDoc = await Firestore.getId('student_plan');

                        let data = {
                            name: this.state.name,
                            description: this.state.description,
                            price: this.state.rawPrice * 100,
                            payment_type: this.state.paymentType,
                            lessons: this.state.lessons,
                            days: this.state.days,
                            charges: this.state.charges,
                            inifinite: this.state.inifinite,
                            installments: 1,
                            invoice_reminder: this.state.invoiceReminder,
                            active: this.state.student_specific_plan,
                            id_company: SessionHelper.getData().id_company,
                            date: new Date(),
                        };

                        let body = {
                            amount: data.price,
                            days: this.state.days,
                            charges: this.state.charges,
                            installments: 1,
                            name: data.name,
                            payment_methods: data.payment_type,
                        };

                        if (data.invoice_reminder > 0) {
                            body.invoice_reminder = data.invoice_reminder;
                        }

                        let response = await Pagarme.post('/plans', body);

                        if (response.errors && response.errors.length) {
                            throw new Error('Pagarme Error');
                        }

                        data.id_plan_pagarme = response.id;
                        planId = response.id;

                        await Firestore.insert(data, 'student_plan', planDoc);
                    }

                    let data = {
                        plan_id: planId,
                    };

                    let response = await Pagarme.put(`/subscriptions/${actualSubscription.id_subscription_pagarme}`, data);

                    if (response.errors && response.errors.length) {

                        throw new Error('Pagarme Error');
                    }

                    if (response.object === 'subscription') {

                        let update = { id_plan: planDoc };
                        await Firestore.update(update, 'student_subscription', actualSubscription.id);
                    }

                    this.setState({ loadingModal: false, planSwitchModal: false, selectedSubscription: null });

                    toast.success('Plano alterado com sucesso');
                    await this.getUserPlans(true);

                } catch (error) {

                    this.setState({ loadingModal: false });
                    toast.error('Houve um problema ao alterar o plano');
                }

            } else {

                toast.warn('Selecione um plano diferente do atual');
            }
        }
    }

    async addSubscriptionTerm() {

        if (this.state.loadingModal == true) {
            toast.warn('Aguarde, realizando ação...');
            return;
        }

        let actualSubscription = this.state.selectedSubscription;

        if (actualSubscription) {

            try {

                this.setState({ loadingModal: true });

                if (this.state.selectedMembershipTerm) {

                    let membershipTerm = this.state.planMembershipTerms.find(item => item.id === this.state.selectedMembershipTerm);

                    let planDataQuery = await Firestore.getDoc('student_plan', actualSubscription.plan.id);
                    let planData = planDataQuery.data();

                    if (membershipTerm) {

                        let studentSubscriptionTermDoc = await Firestore.getId('student_subscription_term');

                        let subscriptionMembershipTerm = {
                            id_student_subscription: actualSubscription.id,
                            id_membership_term: membershipTerm.id,
                            content: MembershipTermsHelper.parseTermVariables(membershipTerm.content, membershipTerm, SessionHelper.getData().company, this.state.user, planData, moment(this.state.boletoExpirationDate).format('DD'), this.state.kids),
                            signed: false,
                            signature: '',
                            id_company: SessionHelper.getData().id_company,
                            created_at: new Date(),
                        };

                        await Firestore.insert(subscriptionMembershipTerm, 'student_subscription_term', studentSubscriptionTermDoc);
                        await Firestore.update({ id_student_subscription_term: studentSubscriptionTermDoc }, 'student_subscription', actualSubscription.id);

                        await Functions.request('POST', `sendContractEmail`,
                            {
                                email: this.state.user.email,
                                hashUrl: `${window.location.protocol}//${window.location.host}/sign_contract?hash=${actualSubscription.id}`,
                                companyName: SessionHelper.getData().company.name,
                                userName: this.state.user.name,
                            }
                        );
                    }
                }

                this.setState({ loadingModal: false, addSubscriptionTermModal: false, selectedSubscription: null, selectedMembershipTerm: null, parsedTerm: '' });

                toast.success('Contrato vinculado com sucesso');
                await this.getUserPlans(true);

            } catch (error) {

                this.setState({ loadingModal: false });
                toast.error('Houve um problema ao vincular o contrato');
            }

        }
    }

    async switchPaymentMethod() {

        if (this.state.loadingModal == true) {
            toast.warn('Aguarde, realizando ação...');
            return;
        }

        if (this.state.selectedPaymentMethod === this.state.selectedSubscription.subscription_pagarme.payment_method) {
            toast.warn('Selecione um método de pagamento diferente do atual');
            return;
        }

        let isValid = this.validatePaymentConfiguration();

        if (isValid) {

            try {

                this.setState({ loadingModal: true });

                let subscription = {
                    payment_method: this.state.selectedPaymentMethod
                };

                if (this.state.selectedPaymentMethod === 'boleto') {

                    if (this.state.applyBoletoFine === 'apply_fine') {

                        subscription.boleto_fine = {
                            days: this.state.boletoFineDays,
                        };

                        if (this.state.boletoFineType === 'value') {

                            subscription.boleto_fine.amount = this.state.boletoFineAmountRaw * 100;

                        } else {

                            subscription.boleto_fine.percentage = this.state.boletoFinePercentage.replace(/[^\d\.]/g, '');
                        }
                    }

                    if (this.state.applyBoletoInterest === 'apply_interest') {

                        subscription.boleto_interest = {
                            days: this.state.boletoInterestDays,
                        };

                        if (this.state.boletoInterestType === 'value') {

                            subscription.boleto_interest.amount = this.state.boletoInterestAmountRaw * 100;

                        } else {

                            subscription.boleto_interest.percentage = this.state.boletoInterestPercentage.replace(/[^\d\.]/g, '');
                        }
                    }

                } else if (this.state.selectedPaymentMethod === 'credit_card') {

                    subscription.card_number = this.state.card_number;
                    subscription.card_expiration_date = this.state.card_expiration_date;
                    subscription.card_cvv = this.state.card_cvv;
                    subscription.card_holder_name = this.state.credit_card_name;
                }

                let response = await Pagarme.put(`/subscriptions/${this.state.selectedSubscription.subscription_pagarme.id}`, subscription);

                if (response.errors && response.errors.length) {

                    throw new Error('Pagarme Error');
                }

                this.setState({ loadingModal: false, paymentSwitchModal: false, selectedPaymentMethod: '', selectedSubscription: null });

                toast.success('Método de pagamento alterado com sucesso');
                await this.getUserPlans(true);

            } catch (error) {

                this.setState({ loadingModal: false });
                toast.error('Houve um problema ao alterar o método de pagamento');
            }
        }
    }

    async skipInstallment() {

        if (this.state.loadingModal == true) {
            toast.warn('Aguarde, realizando ação...');
            return;
        }

        try {

            this.setState({ loadingModal: true });

            let actualSubscription = this.state.selectedSubscription;

            let data = {
                charges: 1,
            }

            let response = await Pagarme.post(`/subscriptions/${actualSubscription.id_subscription_pagarme}/settle_charge`, data);

            if (response.errors && response.errors.length) {

                throw new Error('Pagarme Error');
            }

            let settledCharge = response.settled_charges[response.settled_charges.length - 1];
            let query = await Firestore.getDoc('student_subscription', this.state.selectedSubscription.id);

            if (query.exists) {

                let studentSubscription = { ...query.data(), id: query.id };
                let settledCharges = studentSubscription.settled_charges || {};

                settledCharges[settledCharge] = {
                    amount_paid: this.state.rawAmountPaid * 100,
                    payment_method: this.state.payment_method,
                    obs: this.state.obs,
                    index: settledCharge,
                    date: new Date(),
                    id_user: SessionHelper.getFirebaseAuth().uid,
                }

                await Firestore.update({ settled_charges: settledCharges }, 'student_subscription', studentSubscription.id);
            }

            this.setState({ loadingModal: false, installmentSkipModal: false, selectedSubscription: null, rawAmountPaid: null, amountPaid: null, payment_method: '', obs: '' });

            toast.success('Baixa manual realizada com sucesso, a próxima parcela do aluno será gerada em breve', { autoClose: false });
            await this.getUserPlans(true);

        } catch (error) {

            this.setState({ loadingModal: false });
            toast.error('Houve um problema ao realizar a baixa manual');
        }
    }

    async sendSubscriptionTerm() {

        if (this.state.loadingModal == true) {
            toast.warn('Aguarde, realizando ação...');
            return;
        }

        if (this.state.sendTermEmail.length) {

            try {

                if (!this.state.selectedSubscription.id || !this.state.user.name) {

                    throw new Error('Missing required data');
                }

                this.setState({ loadingModal: true });
    
                await Functions.request('POST', `sendContractEmail`,
                {
                    email: this.state.sendTermEmail,
                    hashUrl: `${window.location.protocol}//${window.location.host}/sign_contract?hash=${this.state.selectedSubscription.id}`,
                    companyName: SessionHelper.getData().company.name,
                    userName: this.state.user.name,
                });
    
                this.setState({ loadingModal: false, sendTermEmailModal: false, selectedSubscription: null, sendTermEmail: '' });
                toast.success('Contrato enviado com sucesso!');
    
            } catch (error) {
    
                this.setState({ loadingModal: false });
                toast.error('Houve um problema ao enviar o contrato');
            }
        
        } else {

            toast.warn('Informe o e-mail para enviar o contrato');
        }
    }

    async sendBoletoEmail() {

        if (this.state.loadingModal == true) {
            toast.warn('Aguarde, realizando ação...');
            return;
        }

        if (this.state.sendBoletoEmail.length) {

            try {

                if (!this.state.selectedSubscription.id || !this.state.user.name) {

                    throw new Error('Missing required data');
                }

                this.setState({ loadingModal: true });

                await Functions.request('POST', `sendBoletoEmail`,
                {
                    email: this.state.sendBoletoEmail,
                    boleto: this.state.selectedSubscription.subscription_pagarme.current_transaction.boleto_url,
                    expirationDate: moment(this.state.selectedSubscription.subscription_pagarme.current_transaction.boleto_expiration_date).format('DD/MM/YYYY'),
                    companyName: SessionHelper.getData().company.name,
                    userName: this.state.user.name,
                });
    
                this.setState({ loadingModal: false, sendBoletoEmailModal: false, selectedSubscription: null, sendBoletoEmail: '' });
                toast.success('Boleto enviado com sucesso!');
    
            } catch (error) {
    
                this.setState({ loadingModal: false });
                toast.error('Houve um problema ao enviar o boleto');
            }
        
        } else {

            toast.warn('Informe o e-mail para enviar o boleto');
        }
    }

    planSwitchModal() {

        if (this.state.planSwitchModal) {

            return (
                <div>
                    {this.renderPlanSelection()}
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.switchPlan() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                        <DefaultButton onClick={() => { this.setState({ planSwitchModal: false, selectedSubscription: null }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    paymentSwitchModal() {

        if (this.state.paymentSwitchModal && this.state.selectedSubscription) {

            return (
                <div>
                    <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} aria-label="type" name="type" value={this.state.selectedPaymentMethod} onChange={(evt) => { this.setState({ selectedPaymentMethod: evt.target.value }) }}>
                        {this.state.selectedSubscription.plan.payment_type.includes('boleto') ? <FormControlLabel value="boleto" control={<Radio style={{ color: Colors.primary }} />} label="Boleto" /> : null}
                        {this.state.selectedSubscription.plan.payment_type.includes('credit_card') ? <FormControlLabel value="credit_card" control={<Radio style={{ color: Colors.primary }} />} label="Cartão de Crédito" /> : null}
                    </RadioGroup>
                    {this.state.selectedPaymentMethod === 'boleto' ?

                        <div>
                            <InputLabel style={{ marginBottom: 8 }}>Configurações do Boleto</InputLabel>
                            <RadioGroup style={{ display: 'flex', flexDirection: 'row', marginTop: 15 }} aria-label="type" name="type" value={this.state.applyBoletoFine} onChange={(evt) => { this.setState({ applyBoletoFine: evt.target.value, boletoFineDays: '', boletoFineAmount: null, boletoFineAmountRaw: null, boletoFinePercentage: '', boletoFineType: 'value' }) }}>
                                <FormControlLabel value={'not_apply_fine'} control={<Radio style={{ color: Colors.primary }} />} label="Não aplicar multa" />
                                <FormControlLabel value={'apply_fine'} control={<Radio style={{ color: Colors.primary }} />} label="Aplicar multa" />
                            </RadioGroup>

                            {this.state.applyBoletoFine === 'apply_fine' ?

                                <div style={{ marginLeft: 25 }}>
                                    <InputMask onChange={(v) => { this.setState({ boletoFineDays: v }) }} maskPlaceholder={''} mask="99" value={this.state.boletoFineDays}>
                                        <DefaultInput hasMask={true} number={true} required={true} minLength={1} label={'Dias (após o vencimento)'} />
                                    </InputMask>

                                    <InputLabel style={{ marginTop: 25 }}>Tipo da Multa</InputLabel>
                                    <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} aria-label="type" name="type" value={this.state.boletoFineType} onChange={(evt) => { this.setState({ boletoFineType: evt.target.value, boletoFineAmount: null, boletoFineAmountRaw: null, boletoFinePercentage: '' }) }}>
                                        <FormControlLabel value={'value'} control={<Radio style={{ color: Colors.primary }} />} label="Valor" />
                                        <FormControlLabel value={'percentage'} control={<Radio style={{ color: Colors.primary }} />} label="Porcentagem" />
                                    </RadioGroup>

                                    {this.state.boletoFineType === 'value' ?

                                        <CurrencyInput required onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Valor da Multa'} onChange={(values) => { const { formattedValue, value } = values; this.setState({ boletoFineAmount: formattedValue, boletoFineAmountRaw: value }) }} value={this.state.boletoFineAmount} />

                                        :

                                        <InputMask onChange={(v) => { this.setState({ boletoFinePercentage: v }) }} alwaysShowMask={true} mask="9.99%" value={this.state.boletoFinePercentage}>
                                            <DefaultInput hasMask={true} number={true} required={true} minLength={1} label={'Porcentagem da Multa'} />
                                        </InputMask>

                                    }
                                </div>

                                : null}

                            <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} aria-label="type" name="type" value={this.state.applyBoletoInterest} onChange={(evt) => { this.setState({ applyBoletoInterest: evt.target.value, boletoInterestDays: '', boletoInterestAmount: null, boletoInterestAmountRaw: null, boletoInterestPercentage: '', boletoInterestType: 'value' }) }}>
                                <FormControlLabel value={'not_apply_interest'} control={<Radio style={{ color: Colors.primary }} />} label="Não aplicar juros" />
                                <FormControlLabel value={'apply_interest'} control={<Radio style={{ color: Colors.primary }} />} label="Aplicar juros" />
                            </RadioGroup>

                            {this.state.applyBoletoInterest === 'apply_interest' ?

                                <div style={{ marginLeft: 25 }}>
                                    <InputMask onChange={(v) => { this.setState({ boletoInterestDays: v }) }} maskPlaceholder={''} mask="99" value={this.state.boletoInterestDays}>
                                        <DefaultInput hasMask={true} number={true} required={true} minLength={1} label={'Dias (após o vencimento)'} />
                                    </InputMask>

                                    <InputLabel style={{ marginTop: 25 }}>Tipo de Juros</InputLabel>
                                    <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} aria-label="type" name="type" value={this.state.boletoInterestType} onChange={(evt) => { this.setState({ boletoInterestType: evt.target.value, boletoInterestAmount: null, boletoInterestAmountRaw: null, boletoInterestPercentage: '' }) }}>
                                        <FormControlLabel value={'value'} control={<Radio style={{ color: Colors.primary }} />} label="Valor" />
                                        <FormControlLabel value={'percentage'} control={<Radio style={{ color: Colors.primary }} />} label="Porcentagem" />
                                    </RadioGroup>

                                    {this.state.boletoInterestType === 'value' ?

                                        <CurrencyInput required onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Valor do Juros'} onChange={(values) => { const { formattedValue, value } = values; this.setState({ boletoInterestAmount: formattedValue, boletoInterestAmountRaw: value }) }} value={this.state.boletoInterestAmount} />

                                        :

                                        <InputMask onChange={(v) => { this.setState({ boletoInterestPercentage: v }) }} alwaysShowMask={true} mask="9.99%" value={this.state.boletoInterestPercentage}>
                                            <DefaultInput hasMask={true} number={true} required={true} minLength={1} label={'Porcentagem de Juros (Ao mês)'} />
                                        </InputMask>

                                    }
                                </div>

                                : null}

                        </div>

                        : this.renderCreditCardForm()}

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.switchPaymentMethod() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                        <DefaultButton onClick={() => { this.setState({ paymentSwitchModal: false, selectedSubscription: null, selectedPaymentMethod: '' }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    cancelSubscriptionModal() {

        if (this.state.cancelSubscriptionModal) {

            return (
                <div>
                    <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', fontWeight: 'bold', alignItems: 'center', marginBottom: 25, padding: 15, boxShadow: Colors.boxShadow, borderRadius: 5, border: '0.5px solid lightgrey' }}>
                        <InfoRoundedIcon style={{ fontSize: 35, marginRight: 8 }} />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>{'Ao cancelar essa matrícula, o aluno continuará vinculado à sua academia.'}
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8, alignItems: 'flex-end' }}>
                                Para removê-lo completamente clique na ação
                                <DeleteIcon style={{ color: 'red', marginLeft: 8, marginBottom: 3 }} />
                                .
                            </div>
                        </div>
                    </div>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.cancelSubscription() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                        <DefaultButton onClick={() => { this.setState({ cancelSubscriptionModal: false, selectedSubscription: null }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    installmentSkipModal() {

        if (this.state.installmentSkipModal) {

            return (
                <div>
                    <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', fontWeight: 'bold', alignItems: 'center', marginBottom: 25, padding: 15, boxShadow: Colors.boxShadow, borderRadius: 5, border: '0.5px solid lightgrey' }}>
                        <InfoRoundedIcon style={{ fontSize: 35, marginRight: 8 }} />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>{'Ao baixar manual, o título selecionado será marcado como pago, e a matrícula em questão também.'}</div>
                    </div>
                    <CurrencyInput label={'Valor Recebido'} onChange={(values) => { const { formattedValue, value } = values; this.setState({ amountPaid: formattedValue, rawAmountPaid: value }) }} value={this.state.amountPaid} />
                    <div style={{ marginTop: 10 }} />
                    <DefaultSelect
                        id={'payment_selection'}
                        valueField={'key'}
                        displayField={'label'}
                        value={this.state.payment_method}
                        onChange={(v) => {
                            this.setState({ payment_method: v.target.value });
                        }}
                        docs={this.state.paymentOptions}
                        label={'Método de pagamento'}
                    />
                    <TextareaAutosize style={{ width: '100%', borderRadius: 5, borderColor: 'lightgrey', padding: 15, fontSize: '1rem', marginTop: 20 }} rowsMax={8} rowsMin={8} onBlur={(v) => { this.setState({ obs: v.target.value }) }} placeholder="Observação" />
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.skipInstallment() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                        <DefaultButton onClick={() => { this.setState({ installmentSkipModal: false, selectedSubscription: null }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    subscriptionTermModal() {

        if (this.state.subscriptionTermModal) {

            return (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: this.state.subscriptionTerm }} />
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.setState({ subscriptionTerm: '', subscriptionTermModal: false }) }} color={Colors.primary} loading={this.state.loadingModal} width={'100%'} title={'FECHAR'} />
                    </div>
                </div>
            )
        }
    }

    addSubscriptionTermModal() {

        if (this.state.addSubscriptionTermModal) {

            return (
                <div>
                    {this.renderPlanMembershipTermSelection()}
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.addSubscriptionTerm() }} color={Colors.primary} loading={this.state.loadingModal} width={'100%'} title={'VINCULAR'} />
                        <div style={{ marginLeft: 10, marginRight: 10 }} />
                        <DefaultButton onClick={() => { this.setState({ addSubscriptionTermModal: false }) }} color={Colors.secondaryButton} loading={this.state.loadingModal} width={'100%'} title={'FECHAR'} />
                    </div>
                </div>
            )
        }
    }

    sendTermEmailModal() {

        if (this.state.sendTermEmailModal) {

            return (
                <div>
                    <DefaultInput required value={this.state.sendTermEmail} onChange={(v) => { this.setState({ sendTermEmail: v }) }} label={'Enviar para'} />
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.sendSubscriptionTerm() }} color={Colors.primary} loading={this.state.loadingModal} width={'100%'} title={'ENVIAR'} />
                        <div style={{ marginLeft: 10, marginRight: 10 }} />
                        <DefaultButton onClick={() => { this.setState({ sendTermEmailModal: false, sendTermEmail: '' }) }} color={Colors.secondaryButton} loading={this.state.loadingModal} width={'100%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    sendBoletoEmailModal() {

        if (this.state.sendBoletoEmailModal) {

            return (
                <div>
                    <DefaultInput required value={this.state.sendBoletoEmail} onChange={(v) => { this.setState({ sendBoletoEmail: v }) }} label={'Enviar para'} />
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.sendBoletoEmail() }} color={Colors.primary} loading={this.state.loadingModal} width={'100%'} title={'ENVIAR'} />
                        <div style={{ marginLeft: 10, marginRight: 10 }} />
                        <DefaultButton onClick={() => { this.setState({ sendBoletoEmailModal: false, sendBoletoEmail: '' }) }} color={Colors.secondaryButton} loading={this.state.loadingModal} width={'100%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    render() {

        return this.state.loading ? this.renderLoading() : (
            <div>
                {this.state.studentSubscriptions.length ? this.renderContent() : this.renderSubscriptionStart()}
                <DefaultModal width={window.screen.width - (window.screen.width * 0.15)} loading={this.state.loadingModal} content={this.subscriptionActivateModal()} title={'Ativar Matrícula'} onClose={() => { this.setState({ subscriptionActivateModal: false }) }} open={this.state.subscriptionActivateModal} />
                <DefaultModal width={window.screen.width - (window.screen.width * 0.15)} loading={this.state.loadingModal} content={this.planSwitchModal()} title={'Alterar Plano'} onClose={() => { this.setState({ planSwitchModal: false }) }} open={this.state.planSwitchModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.paymentSwitchModal()} title={'Alterar Método de Pagamento'} onClose={() => { this.setState({ paymentSwitchModal: false }) }} open={this.state.paymentSwitchModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.cancelSubscriptionModal()} title={'Cancelar Matrícula'} onClose={() => { this.setState({ cancelSubscriptionModal: false }) }} open={this.state.cancelSubscriptionModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.installmentSkipModal()} title={'Baixa Manual'} onClose={() => { this.setState({ installmentSkipModal: false }) }} open={this.state.installmentSkipModal} />
                <DefaultModal width={window.screen.width - (window.screen.width * 0.15)} loading={this.state.loadingModal} content={this.subscriptionTermModal()} title={'Contrato'} onClose={() => { this.setState({ subscriptionTermModal: false }) }} open={this.state.subscriptionTermModal} />
                <DefaultModal width={window.screen.width - (window.screen.width * 0.15)} loading={this.state.loadingModal} content={this.addSubscriptionTermModal()} title={'Vincular Contrato'} onClose={() => { this.setState({ addSubscriptionTermModal: false }) }} open={this.state.addSubscriptionTermModal} />
                <DefaultModal width={window.screen.width - (window.screen.width * 0.15)} loading={this.state.loadingModal} content={this.sendTermEmailModal()} title={'Reenviar Contrato'} onClose={() => { this.setState({ sendTermEmailModal: false }) }} open={this.state.sendTermEmailModal} />
                <DefaultModal width={window.screen.width - (window.screen.width * 0.15)} loading={this.state.loadingModal} content={this.sendBoletoEmailModal()} title={'Enviar por E-mail'} onClose={() => { this.setState({ sendBoletoEmailModal: false }) }} open={this.state.sendBoletoEmailModal} />
            </div>
        )
    }
}