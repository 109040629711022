export default class ZipCodeHelper {

    static async getAddress(zipCode) {

        try {

            if (!zipCode) { throw new Error('CEP não informado') }

            if (!this.validateZipCode(zipCode)) { throw new Error('CEP inválido') }

            let request = await fetch(`https://viacep.com.br/ws/${zipCode}/json/`)
            let json = await request.json();

            return json;

        } catch (error) {

            return false;
        }
    }

    static validateZipCode(zipCode, emptyReturn = false) {

        let regex = /^[0-9]{8}$/;
 
        zipCode = zipCode.toString().replace(/\D/g, '');

        if(zipCode.length > 0) {

            if(regex.test(zipCode))
                return true;
            else
                return false;

        } else {
            return emptyReturn;
        }
    }
}