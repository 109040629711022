import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import Colors from '../constants/Colors';
import Firestore from '../api/firebase/Firestore';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import moment from "moment-timezone";
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import CallMadeIcon from '@material-ui/icons/CallMade';
import RestoreIcon from '@material-ui/icons/Restore';
import DefaultButton from '../components/DefaultButton';
import DefaultModal from '../components/DefaultModal';
import InputMask from "react-input-mask";
import DefaultSelect from '../components/DefaultSelect';
import DefaultInput from '../components/DefaultInput';
import InventoryHelper from '../helper/InventoryHelper';
import { TextareaAutosize } from '@material-ui/core';
import RelationField from '../components/grid/RelationField';
import HeaderActionsWrapper from '../components/HeaderActionsWrapper';

export default class InventoryMovementPage extends Component {

    state = {
        docs: [],
        productDocs: [],
        type: null,
        quantity: 0,
        products: null,
        obs: '',
        loading: true,
        loadingModal: false,
        addModal: false,
    }

    async getDocs() {

        let query = await Firestore.customQuery('inventory_movement').where('id_company', '==', SessionHelper.getData().id_company).orderBy('date', 'desc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = { ...doc.data(), id: doc.id };
            docs.push(data);
        });

        this.setState({ docs: docs });
    }

    async getProducts() {

        let query = await Firestore.customQuery('product').where('id_company', '==', SessionHelper.getData().id_company).where('type', '==', 'product').orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = { ...doc.data(), id: doc.id };
            docs.push(data);
        });

        this.setState({ productDocs: docs });
    }

    async componentDidMount() {

        await this.getDocs();
        await this.getProducts();

        this.setState({ loading: false });
    }

    async addMovement() {

        if (this.state.products) {

            if (this.state.quantity > 0) {

                try {

                    this.setState({ loadingModal: true });

                    await InventoryHelper.addProductMovement(this.state.products, this.state.quantity, this.state.type, this.state.obs, false);
                    await this.getDocs();
                    await this.getProducts();

                    this.setState({ loadingModal: false, addModal: false, quantity: 0, type: null, products: null });
                    toast.success('Movimentação adicionada com sucesso');

                } catch (error) {

                    console.log(error);
                    this.setState({ loadingModal: false });
                }

            } else {

                toast.warn('A quantidade deve ser maior que zero');
            }

        } else {

            toast.warn('Selecione ao menos um produto');
        }
    }

    async revert(id) {

        let confirm = window.confirm('Tem certeza que deseja reverter essa movimentação?');

        if (confirm) {

            this.setState({ loading: true });

            await InventoryHelper.deleteProductMovement(id, true);
            await this.getDocs();
            await this.getProducts();
    
            toast.success("Removido com sucesso", {
                position: toast.POSITION.TOP_RIGHT
            });
    
            this.setState({ loading: false });
        }
    }

    renderType(type) {

        let label = '';
        let color = '';
        let icon = '';

        if (type === 'entry') {

            label = 'Entrada';
            color = Colors.success;
            icon = <CallMadeIcon style={{ fontSize: 16 }}/>;

        } else if (type === 'exit') {

            label = 'Saída';
            color = Colors.error;
            icon = <CallReceivedIcon style={{ fontSize: 16 }}/>;
        }

        return (
            <div style={{ backgroundColor: color, color: 'white', width: 100, paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8, borderRadius: 5, fontWeight: 'bold', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                {icon}
                <div style={{ marginLeft: 5 }}>{label}</div>
            </div>
        );
    }

    addModal() {

        if (this.state.addModal) {

            return (
                <div>
                    <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                        <DefaultSelect search={true} searchField={'name'} value={this.state.products} id={'products_selection'} valueField={'id'} secondaryDisplay={'quantity_available'} displayField={'name'} onChange={(v) => { this.setState({ products: v.target.value }) }} docs={this.state.productDocs} label={'Produto'} />
                    </div>
                    <InputMask onChange={(v) => { this.setState({ quantity: v }) }} mask="999999" maskPlaceholder={''} value={this.state.quantity}>
                        <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={1} label={'Quantidade'} />
                    </InputMask>
                    <TextareaAutosize style={{ width: '100%', borderRadius: 5, borderColor: 'lightgrey', padding: 15, fontSize: '1rem', marginTop: 20 }} rowsMax={8} rowsMin={8} onBlur={(v) => { this.setState({ obs: v.target.value }) }} placeholder="Digite uma observação..." />
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.addMovement() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                        <DefaultButton onClick={() => { this.setState({ addModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <HeaderActionsWrapper style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<CallMadeIcon/>} onClick={() => { this.setState({ addModal: true, type: 'entry' }) }} title={'Nova Entrada'}/>
                        <div style={{ marginLeft: 8 }}/>
                        <DefaultButton leftIcon={<CallReceivedIcon/>} onClick={() => { this.setState({ addModal: true, type: 'exit' }) }} title={'Nova Saída'}/>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        
                    </div>
                </HeaderActionsWrapper> 
                <DefaultTable
                    title={'Estoque'}
                    marginTop={10}
                    
                    actions={[
                        {
                            icon: RestoreIcon,
                            tooltip: 'Reverter Movimentação',
                            onClick: (event, rowData) => { this.revert(rowData.id) }
                        },
                    ]}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Produto', field: 'id_product', render: rowData => <div>{this.state.productDocs.find(item => item.id === rowData.id_product) ? this.state.productDocs.find(item => item.id === rowData.id_product).name : `Produto desconhecido`}</div> },
                        { title: 'Tipo da Movimentação', field: 'type', render: rowData => this.renderType(rowData.type) },
                        { title: 'Quantidade', field: 'quantity' },
                        { title: 'Movimentação', field: 'quantity_available', render: rowData => <div>{`${rowData.quantity_before} → ${rowData.quantity_available}`}</div> },
                        { title: 'Observação', field: 'obs' },
                        { title: 'Usuário', field: 'id_user', render: rowData => <div>{!rowData.system ? <RelationField id={rowData.id_user} collection={'user'} field={'name'}/> : 'Sistema'}</div> },
                        { title: 'Data', editable: false, field: 'date', render: rowData => <div>{rowData.date ? moment(rowData.date.toDate ? rowData.date.toDate() : rowData.date).format('DD/MM/YYYY HH:mm') : ''}</div> },
                    ]}
                    data={this.state.docs}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={`Nova ${this.state.type === 'entry' ? 'Entrada' : 'Saída'}`} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
