import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { FormLabel, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import IosSwitch from '../components/IosSwitch';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import moment from "moment-timezone";
import AddIcon from '@material-ui/icons/Add';
import InputMask from "react-input-mask";
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import HeaderActionsWrapper from '../components/HeaderActionsWrapper';
import ZipCodeHelper from '../helper/ZipCodeHelper';
import DataHelper from '../helper/DataHelper';

export default class ProviderPage extends Component {

    state = {
        docs: [],
        name: '',
        email: '',
        phone: '',
        document: '',
        documentType: 'pj',
        zipcode: '',
        street: '',
        number: '',
        neighbourhood: '',
        complement: '',
        state: '',
        city: '',
        loading: true,
        loadingModal: false,
        addModal: false,
        active: true,
        inputError: false,
    }

    async componentDidMount() {

        await this.getDocs();

        this.setState({ loading: false });
    }

    async getDocs() {

        let query = await Firestore.customQuery('provider').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs });
    }

    async addProvider() {

        if (this.state.name) {

            try {

                this.setState({ loadingModal: true });

                let data = {
                    name: this.state.name,
                    email: this.state.email,
                    phone: this.state.phone ? `${this.state.phone.replace(/[^\d]/g, '')}` : '',
                    document: this.state.document ? this.state.document.replace(/[^\d]/g, '') : '',
                    document_type: this.state.documentType,
                    state: this.state.state,
                    city: this.state.city,
                    zipcode: this.state.zipcode ? this.state.zipcode.replace(/[^\d]/g, '') : '',
                    street: this.state.street,
                    number: this.state.number,
                    neighbourhood: this.state.neighbourhood,
                    complement: this.state.complement,
                    active: this.state.active,
                    id_company: SessionHelper.getData().id_company,
                    date: new Date(),
                };

                await Firestore.insert(data, 'provider');
                toast.success("Cadastrado com sucesso");

                await this.setState({ loadingModal: false, addModal: false, name: '', email: '', phone: '', documentType: 'pj', document: '', zipcode: '', state: '', city: '', street: '', number: '', neighbourhood: '', complement: '', active: true });
                await this.getDocs();

            } catch (error) {

                toast.error("Houve um problema ao cadastrar");
                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn("Preencha ao menos o Nome", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    async zipCodeFetch(zipCode) {

        if (zipCode.length) {

            let response = await ZipCodeHelper.getAddress(zipCode.replace(/[^\d]/g, ''));

            if (response !== false) {

                this.setState({ street: response.logradouro, city: response.localidade, state: response.uf, neighbourhood: response.bairro, complement: response.complemento })

            } else {

                this.setState({ street: '', city: '', state: '', neighbourhood: '', complement: '' })
            }
        }
    }

    renderDocument() {
        
        if (this.state.documentType === 'pj') {

            return (
                <InputMask onChange={(v) => { this.setState({ document: v }) }} mask="99.999.999/9999-99" value={this.state.document}>
                    <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={14} label={'Cnpj'} />
                </InputMask>
            )

        } else if (this.state.documentType === 'pf') {

            return (
                <InputMask onChange={(v) => { this.setState({ document: v }) }} mask="999.999.999-99" value={this.state.document}>
                    <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={11} label={'Cpf'} />
                </InputMask>
            )
        }
    }

    addModal() {
        return (
            <div>
                <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ name: v }) }} label={'Nome'}/>
                <DefaultInput required email onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ email: v }) }} label={'E-mail'} />
                <InputMask onBlur={(v) => { this.setState({ phone: DataHelper.treatPhone(v) }) }} mask="+55 (99) 9 9999-99999999" maskPlaceholder={''}>
                    <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} minLength={11} label={'Telefone'} />
                </InputMask>

                <div style={{ paddingTop: 8 }}>
                    <FormLabel component="legend">Tipo</FormLabel>
                    <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} aria-label="type" name="type" value={this.state.documentType} onChange={(evt) => { this.setState({ documentType: evt.target.value, document: '' }) }}>
                        <FormControlLabel value="pf" control={<Radio style={{ color: Colors.primary }} />} label="Pessoa Física" />
                        <FormControlLabel value="pj" control={<Radio style={{ color: Colors.primary }} />} label="Pessoa Jurídica" />
                    </RadioGroup>
                </div>

                { this.renderDocument() }

                <FormLabel style={{ paddingTop: 28, paddingBottom: 18, fontWeight: 'bold' }} component="legend">Endereço</FormLabel>
                <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', fontWeight: 'bold', marginBottom: 10 }}>
                    <InfoRoundedIcon style={{ fontSize: 20, marginRight: 8 }} />
                    <div>{'Informe o CEP para auto-completar o endereço.'}</div>
                </div>
                <InputMask onBlur={(v) => { this.zipCodeFetch(v); this.setState({ zipcode: v }) }} mask="99999-999">
                    <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} minLength={8} label={'Cep'} />
                </InputMask>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: '50%', paddingRight: 5 }}><DefaultInput value={this.state.state} disabled={true} label={'Estado'} /></div>
                    <div style={{ width: '50%', paddingLeft: 5 }}><DefaultInput value={this.state.city} disabled={true} label={'Cidade'} /></div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: '70%', paddingRight: 5 }}><DefaultInput value={this.state.street} onChange={(v) => { this.setState({ street: v }) }} label={'Rua'} /></div>
                    <div style={{ width: '30%', paddingLeft: 5 }}><DefaultInput value={this.state.number} onChange={(v) => { this.setState({ number: v }) }} label={'Número'} /></div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: '50%', paddingRight: 5 }}><DefaultInput value={this.state.neighbourhood} onChange={(v) => { this.setState({ neighbourhood: v }) }} label={'Bairro'} /></div>
                    <div style={{ width: '50%', paddingLeft: 5 }}><DefaultInput value={this.state.complement} onChange={(v) => { this.setState({ complement: v }) }} label={'Complemento'} /></div>
                </div>
                <div style={{ marginTop: 8 }} />

                <div style={{ paddingTop: 15 }}>
                    <IosSwitch label="Ativo" value="active" checked={this.state.active} onChange={(v) => { this.setState({ active: v }) }}/>
                </div>
                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                    <DefaultButton onClick={() => { this.addProvider() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                    <DefaultButton onClick={() => { this.setState({ addModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                </div>
            </div>
        )
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <HeaderActionsWrapper style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon/>} onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'}/>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        
                    </div>
                </HeaderActionsWrapper>  

                <DefaultTable
                title={'Fornecedores'}
                marginTop={10}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Tipo de Pessoa', field: 'document_type', hidden: true },
                    { title: 'Nome', field: 'name' },
                    { title: 'E-mail', field: 'email' },
                    { title: 'Telefone', field: 'phone' },
                    { title: 'Cpf/Cnpj', field: 'document', render: rowData => <div>{rowData.document ? rowData.document_type === 'pf' ? rowData.document.toString().replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : rowData.document.toString().replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") : ''}</div> },
                    { title: 'Cep', field: 'zipcode' },
                    { title: 'Cidade', field: 'city' },
                    { title: 'Estado', field: 'state' },
                    { title: 'Rua', field: 'street' },
                    { title: 'Número', field: 'number' },
                    { title: 'Bairro', field: 'neighbourhood' },
                    { title: 'Complemento', field: 'complement' },
                    { title: 'Ativo', field: 'active', type: 'boolean', render: rowData => <IosSwitch disabled={true} checked={rowData.active}/>, editComponent: props => (<IosSwitch checked={props.value} onChange={(e) => { props.onChange(e) }} /> ) },
                    { title: 'Data Cadastro', field: 'date', render: rowData => <div>{moment(rowData.date.toDate()).format('DD/MM/YYYY HH:mm')}</div>, editable: false },
                ]}
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                    newData.date = oldData.date;

                    if (newData.name) {

                        let prev = this.state.docs;
                        prev[prev.indexOf(oldData)] = newData;
    
                        this.setState({ docs: prev });
    
                        if (oldData.id) {
                            
                            let update = {
                                name: newData.name,
                                email: newData.email,
                                phone: newData.phone,
                                document: newData.document ? newData.document.replace(/[^\d]/g, '') : '',
                                zipcode: newData.zipcode ? newData.zipcode.replace(/[^\d]/g, '') : '',
                                state: newData.state,
                                city: newData.city,
                                street: newData.street,
                                number: newData.number,
                                neighbourhood: newData.neighbourhood,
                                complement: newData.complement,
                                active: newData.active,
                            };
    
                            await Firestore.update(update, 'provider', oldData.id);
                        }
    
                        toast.success("Editado com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });
    
                        return prev;

                    } else {

                        toast.warn('Preencha todos os campos');
                    }
                }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Fornecedor'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
