import React, { Component } from 'react';
import DefaultButton from '../DefaultButton';
import DefaultLoader from '../DefaultLoader';
import SessionHelper from '../../helper/SessionHelper';
import ReportHelper from '../../helper/ReportHelper';
import moment from "moment-timezone";
import CurrencyHelper from '../../helper/CurrencyHelper';
import { toast } from 'react-toastify';
import ReportDefaultModalOptions from './ReportDefaultModalOptions';
import Pagarme from '../../api/pagarme/Pagarme';
import Firestore from '../../api/firebase/Firestore';
import PaymentMethodHelper from '../../helper/PaymentMethodHelper';

export default class FutureTransactionsReport extends Component {

    state = {
        name: `Títulos a Receber - ${SessionHelper.getData().company.name}`,
        loadingModal: true,
        studentDocs: [],
        studentPlanDocs: [],
        recipientHistory: [],
        start: moment().startOf('month').startOf('day').toDate(),
        end: moment().endOf('month').endOf('day').toDate(),
        output: 'download',
        format: 'pdf',
    }

    async componentDidMount() {

        if (this.props.getDocs && this.props.getDocs) {

            for (let index = 0; index < this.props.getDocs.length; index++) {
                
                const getDoc = this.props.getDocs[index];
                await getDoc(this);
            }
        }

        this.setState({ loadingModal: false });
    }

    async getRecipientHistory() {

        let recipient = SessionHelper.getData().company.id_recipient_pagarme;

        if (recipient) {

            try {

                let response = await Pagarme.get(`/recipients/${recipient}/balance/operations`, `count=1000&page=${this.state.recipientHistoryPage}`);

                if (response && response.length) {
                    this.setState({ recipientHistory: response, fetchedHistory: true });
                }

            } catch (error) {
                toast.error('Erro ao buscar o seu histórico');
            }
        }
    }

    async getReportDocs() {

        let students = this.state.studentDocs;
        let docs = [];
        let total = 0;

        let query = await Firestore.customQuery('student_subscription').where('id_company', '==', SessionHelper.getData().id_company).orderBy('created_at', 'desc').get();

        for (let doc of query.docs) {
         
            let studentSubscription = { ...doc.data(), id: doc.id };
            let userDoc = students.find(item => item.id === studentSubscription.id_user);
            let planDoc = this.state.studentPlanDocs.find(item => item.id === studentSubscription.id_plan);
                
            let response = await Pagarme.get(`/subscriptions/${studentSubscription.id_subscription_pagarme}/transactions`, {});

            if (!response.errors) {

                let count = 1;

                for (let transaction of response) {
                    
                    if (transaction.status === 'paid' || transaction.status === 'waiting_payment' || (studentSubscription.settled_charges && studentSubscription.settled_charges[count])) {

                        let reportDoc = {};
                        reportDoc.customer = userDoc ? userDoc.name : '';
                        reportDoc.status = transaction.status;
                        reportDoc.payment_method = transaction.payment_method;
                        reportDoc.installment = transaction.installment;
                        reportDoc.installments = transaction.installment;

                        let paymentDate = transaction.status === 'paid' || transaction.payment_method === 'credit_card' ? moment(transaction.date_updated) : moment(transaction.boleto_expiration_date);
                        let paymentDateSettled = null;

                        reportDoc.payment_date = paymentDate;
                        reportDoc.date_created = moment(transaction.payment_method === 'boleto' ? transaction.boleto_expiration_date : transaction.date_created);

                        if (studentSubscription.settled_charges && studentSubscription.settled_charges[count]) {

                            paymentDateSettled = moment(studentSubscription.settled_charges[count].date.toDate());
                        }

                        if ((paymentDate.isSameOrAfter(moment(this.state.start)) && paymentDate.isSameOrBefore(this.state.end)) || (studentSubscription.settled_charges && studentSubscription.settled_charges[count] && paymentDateSettled.isSameOrAfter(moment(this.state.start)) && paymentDateSettled.isSameOrBefore(this.state.end))) {
                            
                            reportDoc.amount = transaction.amount;
                            reportDoc.plan = planDoc.name;

                            if (studentSubscription.settled_charges && studentSubscription.settled_charges[count]) {
                                
                                reportDoc.amount = studentSubscription.settled_charges[count].amount_paid;
                                reportDoc.payment_date = studentSubscription.settled_charges[count].date.toDate()
                            }

                            docs.push(reportDoc);
                            total += reportDoc.amount;
                        }
                    }

                    count++;
                }
            }
        }

        docs.sort((a, b) => {

            return a.name ? a.name.toLowerCase() : 0 > b.name ? b.name.toLowerCase() : 1;
        });

        return { docs: docs, totalizer: total };
    }

    addThree(report, column) {

        report.doc.setLineWidth(0.6);
        report.doc.line(column.width - 0.3, report.offset + 4, 18, report.offset + 4);
        report.doc.line(column.width, report.offset + 4, column.width, report.offset + 2);
        report.doc.setLineWidth(0.2);
    }

    async print() {

        try {

            this.setState({ loadingModal: true });

            let docs = await this.getReportDocs();
            let report = await ReportHelper.createReport(this.state.name, 5, `De ${moment(this.state.start).format('DD/MM/YYYY')} até ${moment(this.state.end).format('DD/MM/YYYY')}`, this.state.format, 'l');

            report = await ReportHelper.createColumns(report, docs.docs, [
                { name: 'Cliente', width: 13, getText: (doc) => doc.customer ? this.state.format === 'pdf' ? ReportHelper.cutName(doc.customer, 18) : doc.customer : '' },
                { name: 'Valor', width: 95, getText: (doc) => `${doc.status === 'refunded' ? '-' : ' '}${CurrencyHelper.centsToMoney(doc.amount)}` },
                { name: 'Método de Pagamento', width: 127, getText: (doc) => PaymentMethodHelper.getPaymentMethodLabel(doc.payment_method) },
                { name: 'Parcela', width: 170, getText: (doc) => doc.installment ? `Parcela ${doc.installment} de ${doc.installments}` : '' },
                { name: 'Data de Recebimento (Prevista)', width: 200, getText: (doc) => moment(doc.payment_date).format('DD/MM/YYYY') },
                { name: 'Data', width: 256, getText: (doc) => moment(doc.date_created).format('DD/MM/YYYY') },
            ]);

            report = await ReportHelper.createColumns(report, [{ count: docs.docs.length, total: docs.totalizer }], [
                { name: 'Total', width: 13, getText: (doc) => doc.count.toString() },
                { name: 'Total a Receber', width: 230, getText: (doc) => CurrencyHelper.centsToMoney(doc.total) },
            ], null, false, 12);
    
            ReportHelper.print(report, this.state.output, this.state.format);

            this.setState({ loadingModal: false });    

        } catch (error) {
console.log(error);
            this.setState({ loadingModal: false });
            toast.error('Houve um problema ao imprimir o relatório')
        }

    }

    renderLoading() {

        return (

            <div style={{ height: 200 }}>
                <DefaultLoader/>
            </div>
        )
    }

    renderModal() {

        return (
            <div>
                { ReportHelper.renderPeriodSelection(this, 'start', 'end', 'dd/MM/yyyy') }
                <ReportDefaultModalOptions format={this.state.format} output={this.state.output} onOutputChange={(value) => { this.setState({ output: value }) }} onFormatChange={(value) => { this.setState({ format: value }) }}/>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <DefaultButton width={'100%'} title={'Imprimir'} onClick={() => { this.print() }} />
                </div>
            </div>
        )
    }

    render() {

        return (

            this.state.loadingModal ? this.renderLoading() : this.renderModal()
        )
    }
}