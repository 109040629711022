import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import Calendar from '../components/Calendar';

export default class AgendaPage extends Component {
    state = {
        loading: false,
        width: window.screen.width,
        height: window.screen.width < 1360 ? window.screen.height - 255 : window.innerHeight - 184,
        resized: false
    };

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight - 184, resized: true });
    };

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.addEventListener('resize', this.updateDimensions);
    }


    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <Calendar
                className={'agenda-page'}
                showExperimentalLessons={true}
                showTournamentMatches={true}
                showCourtRents={true}
                showPrivateLesson={true}
                showDayUses={true}
                height={this.state.height}
                export={true}
                />
            </div>
        );
    }
}

const styles = {
    container: {
        paddingTop: 15,
        paddingBottom: 0,
        paddingLeft: 10,
        paddingRight: 10,
        height: `100%`
    }
}
