import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button, FormControlLabel, FormLabel, Select, MenuItem, RadioGroup, Radio, InputLabel, Tooltip, IconButton, TableContainer, Table, TableHead, TableRow, TableBody, TableCell } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import brLocale from "date-fns/locale/pt-BR";
import DateRangeIcon from '@material-ui/icons/DateRange';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import PersonAdd from '@material-ui/icons/PersonAdd';
import DescriptionIcon from '@material-ui/icons/Description';
import AddIcon from '@material-ui/icons/Add';
import ChildFriendlyIcon from '@material-ui/icons/ChildFriendly';
import RemoveIcon from '@material-ui/icons/Remove';
import LoopIcon from '@material-ui/icons/Loop';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import Firestore from '../api/firebase/Firestore';
import moment from "moment-timezone";
import IosSwitch from '../components/IosSwitch';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import StudentSubscriptionStatus from '../components/StudentSubscriptionStatus';
import SessionHelper from '../helper/SessionHelper';
import DefaultSelect from '../components/DefaultSelect';
import Calendar from '../components/Calendar';
import Functions from '../api/firebase/Functions';
import ZipCodeHelper from '../helper/ZipCodeHelper';
import InputMask from 'react-input-mask';
import NotificationHelper from '../helper/NotificationHelper';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import CloseIcon from '@material-ui/icons/Close';
import StudentDetailPanel from '../components/StudentDetailPanel';
import ExcelModal from '../components/ExcelModal';
import { MTableToolbar } from 'material-table';
import TermHelper from '../helper/TermHelper';
import StudentReplacementHistory from '../components/StudentReplacementHistory';
import ReplacementLessonLogHelper from '../helper/ReplacementLessonLogHelper';
import Pagarme from '../api/pagarme/Pagarme';
import HeaderActionsWrapper from '../components/HeaderActionsWrapper';
import UserHelper from '../helper/UserHelper';
import UserTicketStatus from '../components/UserTicketsStatus';
import TicketsDetailPanel from '../components/TicketsDetailPanel';
import DataHelper from '../helper/DataHelper';
import CropFree from '@material-ui/icons/CropFree';
import FileCopy from '@material-ui/icons/FileCopy';
import Storage from '../api/firebase/Storage';

export default class StudentPage extends Component {

    state = {
        docs: [],
        userChildren: [],
        allChildren: [],
        teacherDocs: [],
        courtDocs: [],
        paramDoc: {},
        userCount: null,
        name: '',
        email: '',
        phone: '',
        sex: 'F',
        cpf: '',
        category: '',
        lesson_type: 'group',
        zipcode: '',
        street: '',
        number: '',
        neighbourhood: '',
        complement: '',
        birth_date: null,
        state: SessionHelper.getData().company.state,
        city: SessionHelper.getData().company.city,
        active: true,
        loading: true,
        loadingModal: false,
        addModal: false,
        addChildrenModal: false,
        replacementModal: false,
        agendaModal: false,
        editId: null,
        loginEmail: '',
        password: '',
        passwordConfirmation: '',
        repeat: false,
        repeat_number: null,
        planTransactions: [],
        replacementLesson: null,
        inputError: false,
        emailExistsModal: false,
        cpfExistsModal: false,
        linkModal: false,
        linkEmail: '',
        linkType: 'email',
        linkCpf: '',
        duplicates: [],
        duplicateModal: false,
        insertedCounter: 0,
        removeFutureLessons: false,
        filterStatus: 'all',
        filterTags: [],
        statusTypes: [
            { value: 'all', label: 'Todos' },
            { value: 'paid', label: 'Pago' },
            { value: 'canceled', label: 'Cancelado' },
            { value: 'ended', label: 'Encerrado' },
            { value: 'pending_payment', label: 'Aguardando Pagamento' },
            { value: 'unpaid', label: 'Inadimplente' },
            { value: 'none', label: 'Sem Matrícula' },
        ],
        companyLabel: TermHelper.getestablishmentTypeLabel(SessionHelper.getData().company.establishment_type),
        qrCodeModal: false
    }

    async getDocs() {
        let query = await Firestore.customQuery('user').where('id_companies', 'array-contains', SessionHelper.getData().id_company).where('type', '==', 'student').orderBy('name', 'asc').get();
        let docs = [];
        let allChildren = [];
        let userCount = 0;

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            if (data.companies && data.companies[SessionHelper.getData().id_company]) {
                data.category = data.companies[SessionHelper.getData().id_company].category || null;
                data.lesson_type = data.companies[SessionHelper.getData().id_company].lesson_type || null;
            }

            if (!data.kid) {
                docs.push(data);
            } else {
                allChildren.push(data);
            }
            if(!data.tags) {
                data.tags = [];
            }

            userCount++;
        });

        this.setState({ docs: docs, allChildren, userCount: userCount, filterStatus: 'all' });
    }

    async getParams() {

        let query = await Firestore.customQuery('settings').where('id_company', '==', SessionHelper.getData().id_company).get();
        let data = this.state.config;

        if (query.size === 1 && query.docs[0]) {
            data = query.docs[0].data();
            data.id = query.docs[0].id;

            if (!data.tags) {
                data.tags = [];
            }
        }

        this.setState({ paramDoc: data });
    }

    async getCourts() {

        let query = await Firestore.customQuery('court').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ courtDocs: docs });
    }

    async getTeachers() {

        let query = await Firestore.customQuery('user').where('id_company', '==', SessionHelper.getData().id_company).where('type', '==', 'teacher').orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ teacherDocs: docs });
    }

    async filterStudents(status, tags) {

        this.setState({ loading: true })

        let query = await Firestore.customQuery('user').where('id_companies', 'array-contains', SessionHelper.getData().id_company).where('type', '==', 'student').orderBy('name', 'asc').get();
        let docs = [];
        let users = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        if (status === 'all') {

            users = docs;

        } else {

            if (status === 'none') {

                let subscriptions = await Firestore.customQuery('student_subscription').where('id_company', '==', SessionHelper.getData().id_company).get();
                users = docs;

                if (subscriptions.size > 0) {

                    subscriptions.forEach((doc, key) => {

                        let id = doc.data().id_user;
                        let index = users.findIndex(item => item.id === id);

                        if (index >= 0) {

                            users.splice(index, 1);
                        }
                    });
                }

            } else {

                users = [];
                let ids = [];
                let subscriptions = await Firestore.customQuery('student_subscription').where('id_company', '==', SessionHelper.getData().id_company).orderBy('created_at', 'desc').get();

                if (subscriptions.size > 0) {

                    subscriptions.forEach((doc, key) => {

                        const id = doc.data().id_user;
                        const data = doc.data();

                        if (data.status === status) {

                            if (!ids.includes(id)) {

                                let user = docs.find(item => item.id === id);
    
                                if (user) {
                                    
                                    users.push(user);
                                    ids.push(id);
                                }
                            }

                        } else {

                            ids.push(id);
                        }
                    });
                }
            }
        }

        if (tags && tags.length) {

            users = users.filter((value, key) => {

                let hasTag = false;

                if (value.tags && value.tags.length) {

                    value.tags.forEach((tag, key) => {

                        if (tags.includes(tag)) {

                            hasTag = true;
                            return;
                        }
                    });
                }

                return hasTag;
            });
        }

        this.setState({ loading: false, docs: users });
    }

    async componentDidMount() {

        await this.getDocs();
        await this.getTeachers();
        await this.getCourts();
        await this.getParams();

        this.setState({ loading: false });
    }

    async checkEmailExists(email) {
        const query = await Firestore.customQuery('user').where('email', '==', email).where('type', '==', 'student').get();

        if (query.docs.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    async checkCpfExists(cpf) {
        const query = await Firestore.customQuery('user').where('cpf', '==', cpf).where('type', '==', 'student').get();

        if (query.docs.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    async addStudent() {

        if (this.state.name && this.state.email && this.state.cpf) {

            if (!this.state.inputError) {

                if ((SessionHelper.getData().plan && SessionHelper.getData().plan.max_user - this.state.userCount > 0) || SessionHelper.getData().company.trial || SessionHelper.getData().type === 'super_admin') {

                    try {

                        this.setState({ loadingModal: true });

                        let zipCodeValid = true;

                        if (this.state.zipcode) {

                            let zipCodeResponse = await Pagarme.get(`/zipcodes/${this.state.zipcode.replace(/[^\d]/g, '')}`, '');

                            if (zipCodeResponse.errors && zipCodeResponse.errors.length) {

                                // zipCodeValid = false
                            }
                        }

                        if (zipCodeValid) {

                            let id_companies = [SessionHelper.getData().id_company];

                            let companies = {};
                            companies[SessionHelper.getData().id_company] = {
                                category: this.state.category,
                                lesson_type: this.state.lesson_type,
                                replacement_lesson: 0,
                            }

                            let data = {
                                name: this.state.name,
                                email: this.state.email,
                                phone: this.state.phone ? `${this.state.phone.replace(/[^\d]/g, '')}` : '',
                                cpf: this.state.cpf.replace(/[^\d]/g, ''),
                                state: this.state.state,
                                city: this.state.city,
                                zipcode: this.state.zipcode.replace(/[^\d]/g, ''),
                                street: this.state.street,
                                number: this.state.number,
                                neighbourhood: this.state.neighbourhood,
                                complement: this.state.complement,
                                sex: this.state.sex,
                                companies: companies,
                                id_companies: id_companies,
                                date: new Date(),
                                active: this.state.active,
                                type: 'student',
                                app_login: false,
                            };

                            let emailExists = await this.checkEmailExists(data.email);
                            let cpfExists = await this.checkCpfExists(data.cpf);

                            if (emailExists == false && cpfExists == false) {
                                await Firestore.insert(data, 'user');

                                toast.success("Cadastrado com sucesso", {
                                    position: toast.POSITION.TOP_RIGHT
                                });

                                await this.setState({ addModal: false, name: '', email: '', phone: '', cpf: '', zipcode: '', street: '', number: '', neighbourhood: '', complement: '', active: true, loading: true });
                                await this.getDocs();

                                await this.setState({ loading: false });

                            } else {
                                if (emailExists == true) {
                                    this.setState({ addModal: false, emailExistsModal: true });
                                } else if (cpfExists == true) {
                                    this.setState({ addModal: false, cpfExistsModal: true });
                                }
                            }

                            this.setState({ loadingModal: false });

                        } else {

                            toast.warn('O CEP informado não existe');
                            this.setState({ loadingModal: false });
                        }

                    } catch (error) {
                        toast.error("Houve um problema ao cadastrar", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        this.setState({ loadingModal: false });
                    }

                } else {

                    toast.warn(`Você atingiu o limite de ${this.state.companyLabel.plural.toLowerCase()} do seu plano atual. Faça um upgrade para aumentar.`);
                }

            } else {

                toast.warn("Preencha todos os campos corretamente", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }

        } else {

            toast.warn(`Preencha ao menos o Nome, E-mail e Cpf do ${this.state.companyLabel.singular.toLowerCase()}`);
        }
    }

    async activateLogin() {

        if (this.state.password && this.state.passwordConfirmation && this.state.loginEmail) {

            if (this.state.password === this.state.passwordConfirmation) {

                if (this.state.password.toString().length >= 6) {

                    this.setState({ loadingModal: true });

                    let data = {
                        email: this.state.loginEmail,
                        password: this.state.password,
                        id: this.state.editId,
                    };

                    try {

                        let response = await Functions.request('POST', 'registerStudent', data);

                        console.log(response);

                    } catch (error) {

                        console.log(error);
                    }

                    toast.success('Login ativado');

                    this.setState({ loginModal: false, loginEmail: '', password: '', passwordConfirmation: '', editId: null, loadingModal: false });
                    this.getDocs();


                } else {

                    toast.warn('A senha deve conter ao menos 6 caracteres');
                }

            } else {

                toast.warn('As senhas são diferentes');
            }

        } else {

            toast.warn('Preencha todos os campos');
        }
    }

    async studentHasSubscription(user) {

        let query = await Firestore.customQuery('student_subscription').where('id_user', '==', user).where('id_company', '==', SessionHelper.getData().id_company).orderBy('created_at', 'desc').limit(1).get();

        if (query.size === 1) {

            let doc = query.docs[0].data();

            if (doc.status === 'canceled' || doc.status === 'ended') {

                return false;

            } else {

                return true;
            }
        }
    }

    emailExistsModal() {
        return (
            <div>
                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10, flexDirection: 'column' }}>
                    <p style={{ fontSize: 16 }}>{`Esse E-mail já existe no ACE, caso seja seu ${this.state.companyLabel.singular.toLowerCase()}, vincule-o usando o botão abaixo! ;)`}</p>
                    <img style={{ height: 209, marginTop: 20, marginBottom: 40 }} src={process.env.PUBLIC_URL + '/ilustrations/confirm_link_page.png'} />
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', width: '100%' }}>
                        <DefaultButton leftIcon={<PersonAdd />} width={'100%'} onClick={() => {
                            const linkEmail = `${this.state.email}`;
                            this.setState({ emailExistsModal: false, linkEmail, linkType: 'email', linkModal: true, name: '', email: '', phone: '', cpf: '', zipcode: '', street: '', number: '', neighbourhood: '', complement: '', active: true });
                        }} title={`Vincular ${this.state.companyLabel.singular.toLowerCase()} existente`} />
                    </div>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 10 }}>
                        <DefaultButton leftIcon={<CloseIcon />} width={'100%'} color={'red'} title={'Cancelar'} onClick={() => { this.setState({ emailExistsModal: false, name: '', email: '', phone: '', cpf: '', zipcode: '', street: '', number: '', neighbourhood: '', complement: '', active: true }) }} />
                    </div>
                </div>
            </div>
        );
    }

    cpfExistsModal() {
        return (
            <div>
                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10, flexDirection: 'column' }}>
                    <p style={{ fontSize: 16 }}>{`Esse CPF já existe no ACE, caso seja seu ${this.state.companyLabel.singular.toLowerCase()}, vincule-o usando o botão abaixo! ;)`}</p>
                    <img style={{ height: 209, marginTop: 20, marginBottom: 40 }} src={process.env.PUBLIC_URL + '/ilustrations/confirm_link_page.png'} />
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', width: '100%' }}>
                        <DefaultButton leftIcon={<PersonAdd />} width={'100%'} onClick={() => {
                            const linkCpf = `${this.state.cpf}`;
                            this.setState({ cpfExistsModal: false, linkCpf, linkType: 'cpf', linkModal: true, name: '', email: '', phone: '', cpf: '', zipcode: '', street: '', number: '', neighbourhood: '', complement: '', active: true });
                        }} title={`Vincular ${this.state.companyLabel.singular.toLowerCase()} existente`} />
                    </div>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 10 }}>
                        <DefaultButton leftIcon={<CloseIcon />} width={'100%'} color={'red'} title={'Cancelar'} onClick={() => { this.setState({ cpfExistsModal: false, name: '', email: '', phone: '', cpf: '', zipcode: '', street: '', number: '', neighbourhood: '', complement: '', active: true }) }} />
                    </div>
                </div>
            </div>
        );
    }

    renderUserLimitInfo() {

        return (

            <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', fontWeight: 'bold', alignItems: 'center' }}>
                <InfoRoundedIcon style={{ fontSize: 40, marginRight: 8 }} />
                <div>
                    {`Você ainda pode ter ${SessionHelper.getData().plan.max_user - this.state.userCount > 0 ? SessionHelper.getData().plan.max_user - this.state.userCount : 0} ${this.state.companyLabel.plural.toLowerCase()} ativos no seu plano atual. Considere fazer um `}
                    <a target={'_blank'} href={'/subscription'} style={{ color: 'orange' }}>upgrade</a>.
                </div>
            </div>
        )
    }

    async zipCodeFetch(zipCode) {

        if (zipCode.length) {

            let response = await ZipCodeHelper.getAddress(zipCode.replace(/[^\d]/g, ''));

            if (response !== false) {

                this.setState({ street: response.logradouro, city: response.localidade, state: response.uf, neighbourhood: response.bairro, complement: response.complemento })

            } else {

                this.setState({ street: '', city: '', state: '', neighbourhood: '', complement: '' })
            }
        }
    }

    async sendLinkEmail() {

        if ((SessionHelper.getData().plan && SessionHelper.getData().plan.max_user - this.state.userCount > 0) || SessionHelper.getData().company.trial || SessionHelper.getData().type === 'super_admin') {

            try {

                this.setState({ loadingModal: true });

                let query = await Firestore.customQuery('user').where('email', '==', this.state.linkEmail).where('type', '==', 'student').where('app_login', '==', true).get();
                let user = '';

                if (query.docs[0]) {
                    user = query.docs[0].id;
                }

                let response = await Functions.request('POST', 'auth/sendUserLink', { id_user: user, id_company: SessionHelper.getData().id_company });
                let message = response.msg || '';

                if (response.status === 200) {

                    toast.success(message);

                } else {

                    toast.error(message);

                    throw new Error('E-mail error');
                }

                this.setState({ loadingModal: false, linkModal: false, linkEmail: `` });

            } catch (error) {

                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn(`Você atingiu o limite de ${this.state.companyLabel.plural.toLowerCase()} do seu plano atual. Faça um upgrade para aumentar.`);
        }
    }

    async cpfLink() {

        if ((SessionHelper.getData().plan && SessionHelper.getData().plan.max_user - this.state.userCount > 0) || SessionHelper.getData().company.trial || SessionHelper.getData().type === 'super_admin') {

            if (this.state.loadingModal == true) {
                toast.warn('Aguarde, realizando ação...');
                return;
            }

            try {
                this.setState({ loadingModal: true });

                const cpf = this.state.linkCpf.replace(/[^\d]/g, '');

                let query = await Firestore.customQuery('user').where('cpf', '==', cpf).where('type', '==', 'student').where('app_login', '==', true).limit(1).get();
                let userId = '';
                let user = {};

                if (query.docs[0] && query.docs[0].id) {
                    userId = query.docs[0].id;
                    user = query.docs[0].data();

                    if (!user.id_companies) user.id_companies = [];
                    if (!user.companies) user.companies = {};

                    if (!user.id_companies.includes(SessionHelper.getData().id_company)) {
                        user.id_companies.push(SessionHelper.getData().id_company);
                        user.companies[SessionHelper.getData().id_company] = {
                            category: null,
                            replacement_lesson: 0,
                        }

                        await Firestore.update({ id_companies: user.id_companies, companies: user.companies }, 'user', userId);
                        toast.success('Usuário vinculado com sucesso! Peça a ele para fechar e abrir o aplicativo para aplicar as mudanças!');

                        this.setState({ loadingModal: false, linkModal: false, linkCpf: `` });
                        this.getDocs();
                    } else {
                        toast.info('Usúario já vinculado a sua empresa');
                        this.setState({ loadingModal: false, linkModal: false, linkCpf: `` });
                    }
                } else {
                    toast.error('Usuário inexistente');
                    throw new Error('CPF error');
                }

            } catch (error) {

                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn(`Você atingiu o limite de ${this.state.companyLabel.plural.toLowerCase()} do seu plano atual. Faça um upgrade para aumentar.`);
        }
    }

    linkModal() {

        if (this.state.linkModal) {

            return (
                <div>
                    {SessionHelper.getData().plan ? this.renderUserLimitInfo() : null}
                    <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', fontWeight: 'bold', alignItems: 'center', marginBottom: 20, marginTop: 20, }}>
                        <InfoRoundedIcon style={{ fontSize: 40, marginRight: 8 }} />
                        <div>
                            Caso o vinculo por e-mail não funcione e o usuário existir, o e-mail pode ser privado (Apple), peça o CPF do usuário e efetue o vínculo por CPF clicando em "CPF" abaixo.
                        </div>
                    </div>

                    <div style={{ paddingTop: 8, paddingBottom: 10 }}>
                        <FormLabel component="legend">Informação para realizar o vínculo</FormLabel>
                        <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} aria-label="type" name="type" value={this.state.linkType} onChange={(evt) => { this.setState({ linkType: evt.target.value }) }}>
                            <FormControlLabel value="email" control={<Radio style={{ color: Colors.primary }} />} label="E-mail" />
                            <FormControlLabel value="cpf" control={<Radio style={{ color: Colors.primary }} />} label="CPF" />
                        </RadioGroup>
                    </div>

                    {this.state.linkType == 'email' ?
                        <div style={{ width: '100%' }}>
                            <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', fontWeight: 'bold', marginBottom: 20 }}>
                                <InfoRoundedIcon style={{ fontSize: 40, marginRight: 8 }} />
                                <div>
                                    {`Ao confirmar, o ${this.state.companyLabel.singular.toLowerCase()} receberá um E-mail de confirmação, solicite que o mesmo abra o link enviado para realizar o vínculo.`}
                                </div>
                            </div>
                            <DefaultInput defaultValue={this.state.linkEmail || ``} required email onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ linkEmail: v }) }} label={'E-mail'} />

                            <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                                <DefaultButton onClick={() => { this.sendLinkEmail() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                                <DefaultButton onClick={() => { this.setState({ linkModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                            </div>
                        </div>
                        : null
                    }

                    {this.state.linkType == 'cpf' ?
                        <div style={{ width: '100%' }}>
                            <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', fontWeight: 'bold', marginBottom: 20 }}>
                                <InfoRoundedIcon style={{ fontSize: 40, marginRight: 8 }} />
                                <div>
                                    O vínculo por CPF não requere confirmação do usuário.
                                </div>
                            </div>

                            <InputMask defaultValue={this.state.linkCpf || ``} onBlur={(v) => { this.setState({ linkCpf: v }) }} mask="999.999.999-99" maskPlaceholder={''}>
                                <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={11} label={'CPF'} />
                            </InputMask>

                            <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                                <DefaultButton onClick={() => { this.cpfLink() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                                <DefaultButton onClick={() => { this.setState({ linkModal: false, linkEmail: ``, linkCpf: `` }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                            </div>
                        </div>
                        : null
                    }



                </div>
            )
        }
    }

    addModal() {

        if (this.state.addModal) {

            return (
                <div>
                    {SessionHelper.getData().plan ? this.renderUserLimitInfo() : null}
                    <FormLabel style={{ paddingTop: 28, paddingBottom: 18 }} component="legend">{`Dados do ${this.state.companyLabel.singular}`}</FormLabel>
                    <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ name: v }) }} label={'Nome'} />
                    <DefaultInput required email onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ email: v }) }} label={'E-mail'} />

                    <InputMask onBlur={(v) => { this.setState({ phone: DataHelper.treatPhone(v) }) }} mask="+55 (99) 9 9999-99999999" maskPlaceholder={''}>
                        <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} minLength={11} label={'Telefone'} />
                    </InputMask>

                    <InputMask onBlur={(v) => { this.setState({ cpf: v }) }} mask="999.999.999-99" maskPlaceholder={''}>
                        <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={11} label={'Cpf'} />
                    </InputMask>

                    <div style={{ marginBottom: 15, marginTop: 15 }}>
                        <InputLabel id="type">Categoria</InputLabel>
                        <Select
                            style={{ width: '100%' }}
                            labelId="tipo"
                            value={this.state.category}
                            onChange={(v) => { this.setState({ category: v.target.value }) }}>

                            {

                                this.state.paramDoc && this.state.paramDoc.categories ? this.state.paramDoc.categories.map((category, key) => {
                                    return <MenuItem value={category.value}>{category.label}</MenuItem>
                                }) : null

                            }

                        </Select>
                    </div>

                    <div style={{ marginBottom: 20, marginTop: 15 }}>
                        <InputLabel id="type">Tipo de Aula</InputLabel>
                        <Select
                            style={{ width: '100%' }}
                            labelId="tipo"
                            value={this.state.lesson_type}
                            onChange={(v) => { this.setState({ lesson_type: v.target.value }) }}>

                            {
                                Object.keys(UserHelper.getLessonTypeLookup()).map((key) => {
                                    return <MenuItem value={key}>{UserHelper.getLessonTypeLookup()[key]}</MenuItem>
                                })
                            }

                        </Select>
                    </div>

                    <div style={{ paddingTop: 8 }}>
                        <FormLabel component="legend">Sexo</FormLabel>
                        <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} aria-label="sex" name="sex" value={this.state.sex} onChange={(evt) => { this.setState({ sex: evt.target.value }) }}>
                            <FormControlLabel value="F" control={<Radio style={{ color: Colors.primary }} />} label="Feminino" />
                            <FormControlLabel value="M" control={<Radio style={{ color: Colors.primary }} />} label="Masculino" />
                            <FormControlLabel value="O" control={<Radio style={{ color: Colors.primary }} />} label="Outro" />
                        </RadioGroup>
                    </div>

                    <FormLabel style={{ paddingTop: 28, paddingBottom: 18 }} component="legend">Endereço</FormLabel>
                    <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', fontWeight: 'bold', marginBottom: 10 }}>
                        <InfoRoundedIcon style={{ fontSize: 20, marginRight: 8 }} />
                        <div>{'Informe o CEP para auto-completar o endereço.'}</div>
                    </div>
                    <InputMask onBlur={(v) => { this.zipCodeFetch(v); this.setState({ zipcode: v }) }} mask="99999-999">
                        <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} minLength={8} label={'Cep'} />
                    </InputMask>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '50%', paddingRight: 5 }}><DefaultInput value={this.state.state} disabled={true} label={'Estado'} /></div>
                        <div style={{ width: '50%', paddingLeft: 5 }}><DefaultInput value={this.state.city} disabled={true} label={'Cidade'} /></div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '70%', paddingRight: 5 }}><DefaultInput value={this.state.street} onChange={(v) => { this.setState({ street: v }) }} label={'Rua'} /></div>
                        <div style={{ width: '30%', paddingLeft: 5 }}><DefaultInput value={this.state.number} onChange={(v) => { this.setState({ number: v }) }} label={'Número'} /></div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '50%', paddingRight: 5 }}><DefaultInput value={this.state.neighbourhood} onChange={(v) => { this.setState({ neighbourhood: v }) }} label={'Bairro'} /></div>
                        <div style={{ width: '50%', paddingLeft: 5 }}><DefaultInput value={this.state.complement} onChange={(v) => { this.setState({ complement: v }) }} label={'Complemento'} /></div>
                    </div>
                    <div style={{ marginTop: 8 }} />
                    <IosSwitch label="Ativo" value="active" checked={this.state.active} onChange={(v) => { this.setState({ active: v }) }} />
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.addStudent() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                        <DefaultButton onClick={() => { this.setState({ addModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    agendaModal() {

        if (this.state.agendaModal) {

            return (
                <div>
                    <Calendar readOnly={true} noFilter={true} hiddenButton={true} filterStudent={this.state.editId} />
                </div>
            )
        }
    }

    async addReplacementLesson() {

        try {

            this.setState({ loadingModal: true });

            let userData = this.state.docs.find(item => item.id === this.state.editId);
            if(!userData) userData = this.state.allChildren.find(item => item.id === this.state.editId);

            let replacementLessons = this.state.replacementLesson;
            replacementLessons++;

            if (userData.companies[SessionHelper.getData().id_company]) {

                userData.companies[SessionHelper.getData().id_company].replacement_lesson = replacementLessons;
            }

            await Firestore.update({ companies: userData.companies }, 'user', this.state.editId);

            if (userData.notification_token && userData.notification_token.data) {

                let token = userData.notification_token.data;
                let notification = {
                    icon: 'plus',
                    color: 'green',
                    navigate: 'ReplacementLesson'
                };

                NotificationHelper.send('Aula de Reposição', `Você ganhou uma aula de reposição em ${SessionHelper.getData().company.name}`, notification, token, this.state.editId);
            }

            await ReplacementLessonLogHelper.insert(true, this.state.editId, SessionHelper.getFirebaseAuth().uid, false);
            if (this.studentReplacementHistory) { this.studentReplacementHistory.update() };

            this.getDocs();
            this.setState({ loadingModal: false, replacementLesson: replacementLessons });

            toast.success('Aula de reposição adicionada com sucesso');

        } catch (error) {

            this.setState({ loadingModal: false });
            toast.error('Houve um problema ao adicionar a aula');
        }
    }

    async removeReplacementLesson() {

        let replacementLessons = this.state.replacementLesson;

        if (replacementLessons > 0) {

            try {

                this.setState({ loadingModal: true });

                let userData = this.state.docs.find(item => item.id === this.state.editId);
                if(!userData) userData = this.state.allChildren.find(item => item.id === this.state.editId);

                replacementLessons--;

                if (userData.companies[SessionHelper.getData().id_company]) {

                    userData.companies[SessionHelper.getData().id_company].replacement_lesson = replacementLessons;
                }

                await Firestore.update({ companies: userData.companies }, 'user', this.state.editId);

                await ReplacementLessonLogHelper.insert(false, this.state.editId, SessionHelper.getFirebaseAuth().uid, false);
                if (this.studentReplacementHistory) { this.studentReplacementHistory.update() };

                this.getDocs();
                this.setState({ loadingModal: false, replacementLesson: replacementLessons });

                toast.success('Aula de reposição removida com sucesso');

            } catch (error) {
                console.log(error);

                this.setState({ loadingModal: false });
                toast.error('Houve um problema ao remover a aula');
            }

        } else {

            toast.warn(`Esse ${this.state.companyLabel.singular.toLowerCase()} já possuí zero aulas`);
        }
    }

    replacementModal() {

        if (this.state.replacementModal) {

            return (
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <Tooltip title={'Reduzir Aula'}>
                            <IconButton onClick={() => { this.removeReplacementLesson() }}>
                                <RemoveIcon />
                            </IconButton>
                        </Tooltip>
                        <div style={{ fontSize: 50, margin: 15 }}>{this.state.replacementLesson}</div>
                        <Tooltip title={'Adicionar Aula'}>
                            <IconButton onClick={() => { this.addReplacementLesson() }}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', fontWeight: 'bold', marginTop: 20, marginBottom: 20 }}>
                        <InfoRoundedIcon style={{ fontSize: 40, marginRight: 8 }} />
                        <div>{`As aulas de reposição são usadas pelo ${this.state.companyLabel.singular.toLowerCase()} no App para reagendar aulas perdidas por ausência.`}</div>
                    </div>
                    <h2>Histórico</h2>
                    <StudentReplacementHistory style={{ maxHeight: 350, overflowY: 'scroll' }} ref={ref => this.studentReplacementHistory = ref} id={this.state.editId} />
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { this.setState({ replacementModal: false }) }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '100%' }} variant={'contained'}>{'FECHAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    getSexLookup() {

        return {
            'M': 'Masculino',
            'F': 'Feminino',
            'O': 'Outro',
        }
    }

    renderLogin(bool) {

        return bool ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon style={{ color: 'red' }} />
    }

    technicianLogin(data) {

        if (data.app_login) {

            toast.warn(`${this.state.companyLabel.singular} já possui login no app`);

        } else {

            this.setState({ loginModal: true, loginEmail: data.email, editId: data.id });
        }
    }

    loginModal() {
        return (
            <div>
                <DefaultInput required email onError={(error, msg) => { this.setState({ inputError: error }) }} value={this.state.loginEmail} onChange={(v) => { this.setState({ loginEmail: v }) }} label={'E-mail'} />
                <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} type={'password'} onBlur={(v) => { this.setState({ password: v }) }} label={'Senha'} />
                <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} type={'password'} onBlur={(v) => { this.setState({ passwordConfirmation: v }) }} label={'Confirme a senha'} />
                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                    <DefaultButton onClick={() => { this.activateLogin() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                    <DefaultButton onClick={() => { this.setState({ loginModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                </div>
            </div>
        )
    }

    removeModal() {
        return (
            <div>
                <IosSwitch label={`Remover ${this.state.companyLabel.singular} de aulas futuras`} value="removeFutureLessons" checked={this.state.removeFutureLessons} onChange={(v) => { this.setState({ removeFutureLessons: v }) }} />
                {this.state.removeFutureLessons ?

                    <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', fontWeight: 'bold', marginTop: 10 }}>
                        <InfoRoundedIcon style={{ fontSize: 40, marginRight: 8 }} />
                        <div>{`Ao confirmar, esse ${this.state.companyLabel.singular} será removido de aulas em grupo, e as aulas em que ele joga sozinho serão apagadas.`}</div>
                    </div>

                    : null}
                {this.getDependentsIds(this.state.editId).length ?

                    <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', fontWeight: 'bold', marginTop: 10 }}>
                        <InfoRoundedIcon style={{ fontSize: 40, marginRight: 8 }} />
                        <div>{`Esse ${this.state.companyLabel.singular} possuí ${this.getDependentsIds(this.state.editId).length} dependentes, eles também serão removidos.`}</div>
                    </div>

                    : null}
                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                    <DefaultButton onClick={() => { this.removeStudent(this.state.editId) }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                    <DefaultButton onClick={() => { this.setState({ removeModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                </div>
            </div>
        )
    }

    renderStatus(status) {

        let text = '';
        let color = '';

        if (status === 'pending_transfer') {
            text = 'Transferência pendente';
            color = 'orange';
        } else if (status === 'transferred') {
            text = 'Transferido com sucesso';
            color = 'green';
        } else if (status === 'failed') {
            text = 'Transferência falhou';
            color = 'red';
        } else if (status === 'processing') {
            text = 'Processando';
            color = 'orange';
        } else if (status === 'canceled') {
            text = 'Cancelado';
            color = 'grey'
        } else if (status === 'paid') {
            color = 'green';
            text = 'Pago';
        } else if (status === 'waiting_payment') {
            color = 'orange';
            text = 'Aguardando Pagamento';
        }

        return <div style={{ color: color, fontWeight: 'bold' }}>{text}</div>
    }

    getCategoryLookup() {
        let lookup = {};

        if (this.state.paramDoc && this.state.paramDoc.categories) {

            this.state.paramDoc.categories.map((category, key) => {

                lookup[category.value] = category.label;
            });
        }

        return lookup;
    }

    duplicateModal() {
        const totalColSpan = 100;
        let unitColSpan;

        unitColSpan = parseInt(totalColSpan / this.getColsSub().length);

        return (
            <div>
                <div style={{ marginBottom: 20, marginTop: 30 }}>
                    <FormLabel>Total de inseridos {this.state.insertedCounter}</FormLabel>
                    <br />
                    <FormLabel error={true}>Total de não inseridos: {this.state.duplicates.length}</FormLabel>
                    <br />
                    <br />
                    <FormLabel>Lista de dados não inseridos por estarem duplicados ou fora do padrão:</FormLabel>
                </div>
                <div style={{ maxHeight: 300, minHeight: 200, overflowY: 'auto' }}>
                    <TableContainer>
                        <Table aria-label="spanning table">
                            <TableHead>
                                <TableRow colspan={unitColSpan}>
                                    {this.getColsSub().map((col, key) => <TableCell align="center">{col.alias}</TableCell>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.duplicates.map(row => <TableRow colspan={unitColSpan}>{this.getColsSub().map((col) => row[col.name] ? <TableCell align="center">{row[col.name]}</TableCell> : <TableCell align="center"></TableCell>)}</TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row-reverse', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                    <Button onClick={() => { this.setState({ duplicates: [], duplicateModal: false, insertedCounter: 0 }) }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '100%' }} variant={'contained'}>{'Fechar'}</Button>
                </div>
            </div>
        )
    }

    async removeStudent(id, closeModal = true) {

        if (this.state.loadingModal && closeModal === true) {
            toast.warn('Aguarde, realizando ação...');
            return;
        }

        if (id) {

            try {

                this.setState({ loadingModal: true });

                if (await this.studentHasSubscription(id)) {

                    toast.warn(`Esse ${this.state.companyLabel.singular} possuí uma matrícula ativa, faça o cancelamento antes de desvincular.`, { autoClose: false });

                } else {

                    let kids = this.getDependentsIds(id);

                    if (kids.length) {
                        for (let kid of kids) {
                            await this.removeStudent(kid, false);
                        }
                    }

                    if (this.state.removeFutureLessons) {

                        let referenceDate = moment().toDate();
                        let lessonQuery = await Firestore.customQuery('lesson').where('start', '>=', referenceDate).where('id_students', 'array-contains', id).where('id_company', '==', SessionHelper.getData().id_company).get();

                        if (lessonQuery.size > 0) {

                            for (let doc of lessonQuery.docs) {

                                let lesson = { ...doc.data(), id: doc.id };

                                if (lesson.id_students.length === 1) {

                                    await Firestore.delete('lesson', lesson.id);

                                } else {

                                    let students = lesson.id_students;
                                    let index = students.findIndex(item => item === id);

                                    if (index > -1) {

                                        students.splice(index, 1);
                                        await Firestore.update({ id_students: students }, 'lesson', lesson.id);
                                    }
                                }
                            }
                        }
                    }

                    let userQuery = await Firestore.getDoc('user', id);

                    if (userQuery.exists) {

                        let doc = { ...userQuery.data(), id: userQuery.id };

                        let idCompanies = doc.id_companies;
                        let companies = doc.companies;

                        let companyIndex = idCompanies.findIndex(item => item === SessionHelper.getData().id_company);
                        if (companyIndex >= 0) { idCompanies.splice(companyIndex, 1) };

                        delete companies[SessionHelper.getData().id_company];

                        await Firestore.update({ id_companies: idCompanies, companies: companies }, 'user', id);
                    }

                    await this.getDocs();
                    if(this.state.addChildrenModal && this.state.parentId) this.getUsersChildren(this.state.parentId);
                    if(closeModal) toast.success(`${this.state.companyLabel.singular} desvinculado com sucesso`);
                    this.setState({ removeModal: !closeModal, editId: null })
                }

                this.setState({ loadingModal: false });

            } catch (error) {

                this.setState({ loadingModal: false });
                toast.error(`Houve um problema ao desvincular esse ${this.state.companyLabel.singular}`);
            }
        }
    }

    async validateUserExcel(doc, userCount) {

        let response = true;
        let duplicates = this.state.duplicates;

        let email = await this.checkEmailExists(doc.email);
        let cpf = await this.checkCpfExists(doc.cpf.toString().replace(/[^\d]/g, ''));

        if (email || cpf) {

            response = false;
        }

        if (doc.state && doc.state.length > 2) {

            response = false;
        }

        if (doc.sex && doc.sex.length > 1) {

            response = false;
        }

        if ((SessionHelper.getData().plan && SessionHelper.getData().plan.max_user - userCount <= 0) && !SessionHelper.getData().company.trial) {

            response = false;
        }

        if (!response) {

            duplicates.push(doc);
            this.setState({ duplicates });
        }

        return response;
    }

    handleExcelImport = async (data, fileName) => {

        if (data.length > 0) {

            this.setState({ loading: true });

            let inserted = 0;

            try {

                let userCount = this.state.userCount;

                for (let i = 0; i < data.length; i++) {

                    if ((await this.validateUserExcel(data[i], userCount))) {

                        let id_companies = [SessionHelper.getData().id_company];

                        let companies = {};
                        companies[SessionHelper.getData().id_company] = {
                            category: null,
                            replacement_lesson: 0,
                        }

                        if (data[i].cpf) {

                            data[i].cpf.toString().replace(/[^\d]/g, '');
                        }

                        if (data[i].zipcode) {

                            data[i].zipcode.toString().replace(/[^\d]/g, '');
                        }

                        if (data[i].phone) {

                            data[i].phone = `55${data[i].phone.toString().replace(/[^\d]/g, '')}`
                        }

                        data[i].type = 'student';
                        data[i].id_companies = id_companies;
                        data[i].companies = companies;
                        data[i].active = true;
                        data[i].date = new Date();

                        await Firestore.insert(data[i], 'user');

                        inserted++;
                        userCount++;
                    }
                }

                this.setState({ insertedCounter: inserted, duplicateModal: this.state.duplicates.length > 0 ? true : false });

                if (data.length - this.state.duplicates.length) {

                    toast.success(`${data.length - this.state.duplicates.length} registros importados com sucesso!`, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }

            } catch (error) {

                toast.error("Erro ao importar .xlsx", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }

            await this.getDocs();

            this.setState({ loading: false });
        }
    }

    getColsSub() {
        return [
            { name: 'name', alias: 'Nome', required: true, example: 'João da Silva' },
            { name: 'email', alias: 'E-mail', required: true, example: 'joao_silva@gmail.com' },
            { name: 'cpf', alias: 'Cpf', example: '387.196.700-95' },
            { name: 'zipcode', alias: 'Cep', example: '89840-000' },
            { name: 'city', alias: 'Cidade', example: 'Chapecó' },
            { name: 'state', alias: 'Estado', example: 'SC' },
            { name: 'street', alias: 'Rua', example: 'Rua Amazonas' },
            { name: 'number', alias: 'Número', example: '1009' },
            { name: 'neighbourhood', alias: 'Bairro', example: 'Três Palmeiras' },
            { name: 'complement', alias: 'Complemento', example: 'D' },
            { name: 'phone', alias: 'Telefone', example: '49988694871' },
            { name: 'sex', alias: 'Sexo', example: 'M' },
        ];
    }

    renderToolbar(props) {
        return (
            <div>
                <MTableToolbar {...props} />
                <div style={{ paddingLeft: 25, paddingRight: 25, paddingBottom: 25, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                    <div style={{ width: 300, marginRight: 15 }}><DefaultSelect valueField={'value'} displayField={'label'} onChange={async (v) => { await this.setState({ filterStatus: v.target.value }); this.filterStudents(v.target.value, this.state.filterTags); }} docs={this.state.statusTypes} label={'Status da Matrícula'} value={this.state.filterStatus} /></div>
                    <div style={{ width: 300, marginRight: 15 }}><DefaultSelect multiple={true} search={true} valueField={'value'} displayField={'label'} onChange={async (v) => { await this.setState({ filterTags: v.target.value }); this.filterStudents(this.state.filterStatus, v.target.value); }} docs={this.state.paramDoc.tags} label={'Tags'} value={this.state.filterTags} /></div>
                </div>
            </div>
        )
    }

    renderStudentCategory(rowData) {
        if (rowData.companies[SessionHelper.getData().id_company]) {
            let category = this.state.paramDoc.categories.find(item => item.value == rowData.companies[SessionHelper.getData().id_company].category);
            return <div>{category ? category.label : ''}</div>
        } else {
            return <div />
        }
    }

    renderStudentLessonType(rowData) {
        if (rowData.companies[SessionHelper.getData().id_company]) {
            let lesson_type = rowData.companies[SessionHelper.getData().id_company].lesson_type;
            return <div>{UserHelper.getLessonTypeLookup()[lesson_type] ? UserHelper.getLessonTypeLookup()[lesson_type] : ''}</div>
        } else {
            return <div />
        }
    }

    addChildren = async () => {
        if (this.state.name && this.state.birth_date && moment(this.state.birth_date).isValid()) {

            if (!this.state.inputError) {

                if ((SessionHelper.getData().plan && SessionHelper.getData().plan.max_user - this.state.userCount > 0) || SessionHelper.getData().company.trial || SessionHelper.getData().type === 'super_admin') {

                    try {

                        this.setState({ loadingModal: true });

                        let id_companies = [SessionHelper.getData().id_company];

                        let companies = {};
                        companies[SessionHelper.getData().id_company] = {
                            category: this.state.category,
                            lesson_type: this.state.lesson_type,
                            replacement_lesson: 0,
                        }

                        let data = {
                            name: this.state.name,
                            parent_id: this.state.parentId,
                            sex: this.state.sex,
                            companies: companies,
                            id_companies: id_companies,
                            birth_date: this.state.birth_date,
                            kid: true,
                            date: new Date(),
                            active: true,
                            type: 'student',
                            app_login: false
                        };

                        await Firestore.insert(data, 'user');

                        toast.success("Cadastrado com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        await this.getUsersChildren(this.state.parentId);
                        await this.setState({
                            addingChild: false,
                            name: '',
                            email: '',
                            phone: '',
                            sex: 'F',
                            category: '',
                            lesson_type: 'group',
                            birth_date: null
                        }, async () => { await this.getDocs(); });

                        this.setState({ loadingModal: false });

                    } catch (error) {
                        toast.error("Houve um problema ao cadastrar", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        this.setState({ loadingModal: false });
                    }

                } else {

                    toast.warn(`Você atingiu o limite de ${this.state.companyLabel.plural.toLowerCase()} do seu plano atual. Faça um upgrade para aumentar.`);
                }

            } else {

                toast.warn("Preencha todos os campos corretamente", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }

        } else {

            toast.warn(`Preencha ao menos o Nome, E-mail e Cpf do ${this.state.companyLabel.singular.toLowerCase()}`);
        }
    }

    renderAddChildForm() {
        return (
            <div>
                {SessionHelper.getData().plan ? this.renderUserLimitInfo() : null}
                <FormLabel style={{ paddingTop: 28, paddingBottom: 18 }} component="legend">{`Dados do ${this.state.companyLabel.singular}`}</FormLabel>
                <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ name: v }) }} label={'Nome'} />

                <div style={{ marginBottom: 15, marginTop: 15 }}>
                    <InputLabel id="type">Data de Nascimento</InputLabel>
                    <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            style={{ width: '100%' }}
                            invalidDateMessage={false}
                            format={'dd/MM/yyyy'}
                            autoOk={true}
                            cancelLabel={'Cancelar'}
                            okLabel={'Confirmar'}
                            onChange={async (v) => { await this.setState({ birth_date: v }); }}
                            value={this.state.birth_date}
                        />
                    </MuiPickersUtilsProvider>
                </div>

                <div style={{ marginBottom: 15, marginTop: 15 }}>
                    <InputLabel id="type">Categoria</InputLabel>
                    <Select
                        style={{ width: '100%' }}
                        labelId="tipo"
                        value={this.state.category}
                        onChange={(v) => { this.setState({ category: v.target.value }) }}>

                        {

                            this.state.paramDoc && this.state.paramDoc.categories ? this.state.paramDoc.categories.map((category, key) => {
                                return <MenuItem value={category.value}>{category.label}</MenuItem>
                            }) : null

                        }

                    </Select>
                </div>

                <div style={{ marginBottom: 20, marginTop: 15 }}>
                    <InputLabel id="type">Tipo de Aula</InputLabel>
                    <Select
                        style={{ width: '100%' }}
                        labelId="tipo"
                        value={this.state.lesson_type}
                        onChange={(v) => { this.setState({ lesson_type: v.target.value }) }}>

                        {
                            Object.keys(UserHelper.getLessonTypeLookup()).map((key) => {
                                return <MenuItem value={key}>{UserHelper.getLessonTypeLookup()[key]}</MenuItem>
                            })
                        }

                    </Select>
                </div>

                <div style={{ paddingTop: 8 }}>
                    <FormLabel component="legend">Sexo</FormLabel>
                    <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} aria-label="sex" name="sex" value={this.state.sex} onChange={(evt) => { this.setState({ sex: evt.target.value }) }}>
                        <FormControlLabel value="F" control={<Radio style={{ color: Colors.primary }} />} label="Feminino" />
                        <FormControlLabel value="M" control={<Radio style={{ color: Colors.primary }} />} label="Masculino" />
                        <FormControlLabel value="O" control={<Radio style={{ color: Colors.primary }} />} label="Outro" />
                    </RadioGroup>
                </div>

                <div style={{ marginTop: 8 }} />
                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                    <DefaultButton onClick={() => { this.addChildren() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                    <DefaultButton onClick={() => {
                        this.setState({
                            addingChild: false,
                            name: '',
                            email: '',
                            phone: '',
                            sex: 'F',
                            category: '',
                            lesson_type: 'group',
                            birth_date: null,
                        })
                    }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                </div>
            </div>
        );
    }

    renderChildrenList() {
        return (
            <div>
                <DefaultButton leftIcon={<AddIcon />} onClick={() => { this.setState({ addingChild: true }) }} title={'Adicionar'} />

                <DefaultTable
                    title={``}
                    height={400}
                    marginTop={10}
                    actions={[
                        {
                            icon: LoopIcon,
                            tooltip: 'Aulas de Reposição',
                            onClick: (event, rowData) => { this.setState({ editId: rowData.id, replacementModal: true, replacementLesson: rowData.companies[SessionHelper.getData().id_company] ? rowData.companies[SessionHelper.getData().id_company].replacement_lesson || 0 : 0 }) }
                        },
                        {
                            icon: DeleteIcon,
                            tooltip: `Desvincular ${this.state.companyLabel.singular}`,
                            onClick: (event, rowData) => { this.setState({ editId: rowData.id, removeModal: true, removeFutureLessons: false }) }
                        },
                    ]}
                    filtering={true}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Companies', field: 'companies', hidden: true },
                        { title: 'Nome', field: 'name' },
                        { title: 'Status das Comandas', field: '', editable: false, render: rowData => <UserTicketStatus user={rowData.id} /> },
                        { title: 'Categoria', sorting: false, field: `category`, lookup: this.getCategoryLookup(), render: rowData => this.renderStudentCategory(rowData) },
                        { title: 'Tipo de Aula', sorting: true, field: `lesson_type`, lookup: UserHelper.getLessonTypeLookup(), render: rowData => this.renderStudentLessonType(rowData) },
                        { title: 'Sexo', field: 'sex', lookup: this.getSexLookup() },
                        { title: 'Data de Nascimento', field: 'birth_date', render: rowData => <div>{rowData.birth_date ? moment(rowData.birth_date.toDate ? rowData.birth_date.toDate() : rowData.birth_date).format('DD/MM/YYYY') : ''}</div>, editable: false },
                        { title: 'Data Cadastro', field: 'date', editable: false, render: rowData => <div>{rowData.date ? moment(rowData.date.toDate ? rowData.date.toDate() : rowData.date).format('DD/MM/YYYY HH:mm') : ''}</div> },
                    ]}
                    detailPanel={(rowData) => {
                        return <div>
                            <TicketsDetailPanel {...this.props} user={rowData.id} usersDocs={this.state.allChildren} />
                        </div>
                    }}
                    data={this.state.userChildren}
                    onRowUpdate={async (oldData, newData) => {

                        newData.date = oldData.date;

                        let prev = this.state.userChildren;
                        prev[prev.indexOf(oldData)] = newData;

                        this.setState({ userChildren: prev });

                        if (oldData.id) {

                            let update = {
                                name: newData.name,
                                sex: newData.sex || null,
                            };

                            update.companies = newData.companies;
                            update.companies[SessionHelper.getData().id_company].category = newData.category || null;
                            update.companies[SessionHelper.getData().id_company].lesson_type = newData.lesson_type || null;

                            await Firestore.update(update, 'user', newData.id);

                            update.category = newData.category || null;
                            update.lesson_type = newData.lesson_type || null;
                        }

                        toast.success("Editado com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        return prev;
                    }}
                />


                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                    <DefaultButton onClick={() => { this.setState({ addChildrenModal: false, parentId: null }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'100%'} title={'FECHAR'} />
                </div>
            </div>
        );
    }

    addChildrenModal() {
        if (this.state.addChildrenModal) {
            return this.state.addingChild ? this.renderAddChildForm() : this.renderChildrenList();
        }
    }

    qrCodeModal() {
        if (this.state.qrCodeModal) {
            return (
                <div>
                    <div style={{ marginBottom: 20 }}>
                        <FormLabel style={{ fontWeight: 'bold', fontSize: 16 }}>PDF Folha A4 para colar no espaço físico da acadêmia com o QR Code, para alunos escanearem e baixarem o ACE:</FormLabel>
                    </div>
                    <DefaultButton leftIcon={<CropFree />} onClick={async () => {
                        try {
                            let url = await Storage.getFileFromUrlRef(`gs://bg-tennis.appspot.com/platform_downloads/ACE - QR Code.pdf`);
                            window.open(url, '_blank');
                        } catch (error) {
                            toast.error('Houve um problema ao baixar o arquivo.');
                        }
                    }} title={'Baixar QR Code'} />

                    <div style={{ marginBottom: 40, marginTop: 10 }}>
                        <FormLabel error={true}>Caso o download não ocorra, permita os pop-ups na página.</FormLabel>
                    </div>

                    <div style={{ marginBottom: 20 }}>
                        <FormLabel style={{ fontWeight: 'bold', fontSize: 16 }}>Texto para mandar por mensagem para os alunos baixarem o ACE:</FormLabel>
                    </div>

                    <div style={{ marginBottom: 10 }}>
                        <FormLabel>
                            {`Pessoal, o ACE chegou na ${SessionHelper.getData().company.name}, por ele você vai conseguir ver e realizar seus/suas:`}
                            <br />
                            <br />
                            {`🏆 Inscrições de Torneios/Rankings`}
                            <br />
                            {`📊 Pedidos`}
                            <br />
                            {`📆 Alugueis de Quadra`}
                            <br />
                            {`📈 Matrículas`}
                            <br />
                            {`📖 Aulas`}
                            <br />
                            {`🚀 E mais...`}
                            <br />
                            <br />
                            {`Baixe agora e em sua próxima aula requisite o vínculo com ${SessionHelper.getData().company.name} na secretaria!`}
                            <br />
                            <br />
                            {`📱 iPhone: https://apple.co/3wQSFHk`}
                            <br />
                            <br />
                            {`📱 Android: https://bit.ly/3j58mXi`}
                            <br />
                            <br />
                            {`🎾 https://aceapp.com.br`}
                        </FormLabel>
                    </div>

                    <DefaultButton leftIcon={<FileCopy />} onClick={() => {
                        navigator.clipboard.writeText(`Pessoal, o ACE chegou na ${SessionHelper.getData().company.name}, por ele você vai conseguir ver e realizar seus/suas:\n\n🏆 Inscrições de Torneios/Rankings\n📊 Pedidos\n📆 Alugueis de Quadra\n📈 Matrículas \n📖 Aulas \n🚀 E mais...\n\nBaixe agora e em sua próxima aula requisite o vínculo com ${SessionHelper.getData().company.name} na secretaria!\n\n📱 iPhone: https://apple.co/3wQSFHk\n\n📱 Android: https://bit.ly/3j58mXi\n\n🎾 https://aceapp.com.br`);
                        toast.success(`Texto copiado para área de transferência.`)
                    }} title={'Copiar Texto'} />

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.setState({ qrCodeModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'100%'} title={'Fechar'} />
                    </div>
                </div>
            );
        }
    }

    async getUsersChildren(parentId) {
        this.setState({ loadingModal: true, addChildrenModal: true, parentId });

        let query = await Firestore.customQuery('user').where('id_companies', 'array-contains', SessionHelper.getData().id_company).where('parent_id', '==', parentId).where('type', '==', 'student').orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            if (data.companies && data.companies[SessionHelper.getData().id_company]) {
                data.category = data.companies[SessionHelper.getData().id_company].category || null;
                data.lesson_type = data.companies[SessionHelper.getData().id_company].lesson_type || null;
            }

            docs.push(data);
        });

        this.setState({ userChildren: docs, loadingModal: false });
    }

    getDependentsIds(parentId) {
        let ids = [];

        this.state.allChildren.forEach(child => {
            if (child.parent_id === parentId) ids.push(child.id);
        });

        return ids;
    }

    renderTags(tags) {

        if (tags) {

            return (

                <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                    {
                        tags.map((value, key) => {
    
                            let tag = this.state.paramDoc.tags.find(item => item.value === value);
    
                            if (tag) {
    
                                return <b style={{ backgroundColor: tag.color, color: 'white', paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8, borderRadius: 5 }}>{tag.label}</b>
                            }
    
                            return null;
                        })
                    }
                </div>
            )
        }
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <HeaderActionsWrapper style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon />} onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'} />
                        <div style={{ marginLeft: 8 }} />
                        <DefaultButton leftIcon={<PersonAdd />} onClick={() => { this.setState({ linkModal: true }) }} title={`Vincular ${this.state.companyLabel.singular.toLowerCase()} existente`} />
                        <div style={{ marginLeft: 8 }} />
                        <DefaultButton leftIcon={<DescriptionIcon />} onClick={() => { this.setState({ excelModal: true }) }} title={'Importar (.xlsx)'} />
                        <div style={{ marginLeft: 8 }} />
                        <DefaultButton leftIcon={<PhoneIphoneIcon />} onClick={() => { this.setState({ qrCodeModal: true }) }} title={'Baixar App'} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                    </div>
                </HeaderActionsWrapper>

                <DefaultTable
                    title={this.state.companyLabel.plural}
                    height={window.screen.height - 470}
                    marginTop={10}
                    actions={[
                        {
                            icon: WhatsAppIcon,
                            tooltip: 'Chamar no WhatsApp',
                            onClick: (event, rowData) => { window.open(`https://wa.me/${rowData.phone.replace(/[^\d]/g, '')}`, '_blank') }
                        },
                        {
                            icon: DateRangeIcon,
                            tooltip: 'Ver Agenda',
                            onClick: (event, rowData) => { this.setState({ agendaModal: true, editId: rowData.id }) }
                        },
                        {
                            icon: ChildFriendlyIcon,
                            tooltip: 'Cadastrar Dependente',
                            onClick: async (event, rowData) => { this.getUsersChildren(rowData.id); }
                        },
                        {
                            icon: PhoneIphoneIcon,
                            tooltip: 'Criar Login no App',
                            onClick: (event, rowData) => { this.technicianLogin(rowData) }
                        },
                        {
                            icon: LoopIcon,
                            tooltip: 'Aulas de Reposição',
                            onClick: (event, rowData) => { this.setState({ editId: rowData.id, replacementModal: true, replacementLesson: rowData.companies[SessionHelper.getData().id_company] ? rowData.companies[SessionHelper.getData().id_company].replacement_lesson || 0 : 0 }) }
                        },
                        {
                            icon: DeleteIcon,
                            tooltip: `Desvincular ${this.state.companyLabel.singular}`,
                            onClick: (event, rowData) => { this.setState({ editId: rowData.id, removeModal: true, removeFutureLessons: false }) }
                        },
                    ]}
                    detailPanel={(rowData) => {
                        return <div>
                            <StudentDetailPanel {...this.props} user={rowData.id} />
                            <TicketsDetailPanel {...this.props} user={rowData.id} usersDocs={this.state.docs} />
                        </div>
                    }}
                    onRowClick={(evt, rowData, togglePanel) => { togglePanel() }}
                    filtering={true}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Companies', field: 'companies', hidden: true },
                        { title: 'Nome', field: 'name' },
                        { title: 'Status da Matrícula', field: 'student_subscription', editable: false, render: rowData => <StudentSubscriptionStatus user={rowData.id} /> },
                        { title: 'Status das Comandas', field: '', editable: false, render: rowData => <UserTicketStatus user={rowData.id} /> },
                        { title: 'Dependentes Cadastrados', field: 'kids', editable: false, render: rowData => <div>{this.getDependentsIds(rowData.id).length ? `${this.getDependentsIds(rowData.id).length} dependente(s)` : `Nenhum`}</div> },
                        { title: 'E-mail', field: 'email' },
                        { title: 'Cpf', field: 'cpf', render: rowData => <div>{rowData.cpf ? rowData.cpf.toString().replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : ''}</div> },
                        { title: 'Categoria', sorting: false, field: `category`, lookup: this.getCategoryLookup(), render: rowData => this.renderStudentCategory(rowData) },
                        { title: 'Tipo de Aula', sorting: true, field: `lesson_type`, lookup: UserHelper.getLessonTypeLookup(), render: rowData => this.renderStudentLessonType(rowData) },
                        { title: 'Login no App', field: 'app_login', editable: false, render: rowData => <IosSwitch disabled={true} checked={rowData.app_login} /> },
                        { title: 'Cep', field: 'zipcode' },
                        { title: 'Cidade', field: 'city' },
                        { title: 'Estado', field: 'state' },
                        { title: 'Rua', field: 'street' },
                        { title: 'Número', field: 'number' },
                        { title: 'Bairro', field: 'neighbourhood' },
                        { title: 'Complemento', field: 'complement' },
                        { title: 'Telefone', field: 'phone' },
                        { title: 'Sexo', field: 'sex', lookup: this.getSexLookup() },
                        { title: 'Tags', field: 'tags', render: rowData => this.renderTags(rowData.tags), editComponent: props => <DefaultSelect search={true} multiple={true} valueField={'value'} displayField={'label'} onChange={(v) => props.onChange(v.target.value)} docs={this.state.paramDoc.tags} label={''} value={props.value} /> },
                        { title: 'Data de Nascimento', field: 'birth_date', render: rowData => <div>{rowData.birth_date ? moment(rowData.birth_date.toDate ? rowData.birth_date.toDate() : rowData.birth_date).format('DD/MM/YYYY') : ''}</div>, editable: false },
                        { title: 'Data Cadastro', field: 'date', editable: false, render: rowData => <div>{rowData.date ? moment(rowData.date.toDate ? rowData.date.toDate() : rowData.date).format('DD/MM/YYYY HH:mm') : ''}</div> },
                    ]}
                    data={this.state.docs}
                    onRowUpdate={async (oldData, newData) => {

                        newData.date = oldData.date;

                        let prev = this.state.docs;
                        prev[prev.indexOf(oldData)] = newData;

                        this.setState({ docs: prev });

                        if (oldData.id) {

                            let update = {
                                name: newData.name,
                                email: newData.email,
                                phone: newData.phone,
                                cpf: newData.cpf,
                                zipcode: newData.zipcode ? newData.zipcode.replace(/[^\d]/g, '') : '',
                                street: newData.street || '',
                                number: newData.number || '',
                                neighbourhood: newData.neighbourhood || '',
                                complement: newData.complement || '',
                                sex: newData.sex || null,
                                tags: newData.tags || [],
                            };

                            update.companies = newData.companies;
                            update.companies[SessionHelper.getData().id_company].category = newData.category || null;
                            update.companies[SessionHelper.getData().id_company].lesson_type = newData.lesson_type || null;

                            await Firestore.update(update, 'user', newData.id);

                            update.category = newData.category || null;
                            update.lesson_type = newData.lesson_type || null;
                        }

                        toast.success("Editado com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        return prev;
                    }}
                    components={{
                        Toolbar: props => (this.renderToolbar(props)),
                    }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.loginModal()} title={'Criar Login'} onClose={() => { this.setState({ loginModal: false }) }} open={this.state.loginModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={`Novo ${this.state.companyLabel.singular}`} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.linkModal()} title={`Vincular ${this.state.companyLabel.singular} Existente`} onClose={() => { this.setState({ linkModal: false, linkEmail: ``, linkCpf: `` }) }} open={this.state.linkModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.agendaModal()} title={'Agenda'} onClose={() => { this.setState({ agendaModal: false }) }} open={this.state.agendaModal} width={'90%'} />
                <DefaultModal loading={this.state.loadingModal} content={this.replacementModal()} title={'Aulas de Reposição'} onClose={() => { this.setState({ replacementModal: false }) }} open={this.state.replacementModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.emailExistsModal()} title={'Essa pessoa já existe no Ace'} onClose={() => { this.setState({ emailExistsModal: false }) }} open={this.state.emailExistsModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.cpfExistsModal()} title={'Essa pessoa já existe no Ace'} onClose={() => { this.setState({ cpfExistsModal: false }) }} open={this.state.cpfExistsModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.duplicateModal()} title={'Não inseridos'} onClose={() => { this.setState({ duplicates: [], duplicateModal: false }) }} open={this.state.duplicateModal} width={'80%'} />
                <DefaultModal loading={this.state.loadingModal} content={this.removeModal()} title={`Desvincular ${this.state.companyLabel.singular}`} onClose={() => { this.setState({ removeModal: false }) }} open={this.state.removeModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.qrCodeModal()} title={'Para os alunos baixarem o ACE'} onClose={() => { this.setState({ qrCodeModal: false }) }} open={this.state.qrCodeModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.addChildrenModal()} width={'80%'} title={this.state.addingChild ? 'Adicionar Dependente' : 'Lista de Dependentes'} onClose={() => { this.setState({ addChildrenModal: false }) }} open={this.state.addChildrenModal} />
                <ExcelModal
                    saveCallback={this.handleExcelImport}
                    cols={this.getColsSub()}
                    onClose={() => { this.setState({ excelModal: false, addModal: false }) }}
                    visible={this.state.excelModal}
                    modelSheet={'gs://bg-tennis.appspot.com/sheet_models/Modelo Importação - Alunos.xlsx'}
                    infoText={() => { return SessionHelper.getData().plan ? this.renderUserLimitInfo() : null }}
                />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
