import React from 'react';
import Colors from '../constants/Colors';
import Firestore from '../api/firebase/Firestore';
import SessionHelper from '../helper/SessionHelper';
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment-timezone";


export default class StudentSubscriptionTermStatus extends React.Component {

    state = {
        loading: false,
        status: null,
    }

    async componentDidMount() {

        await this.setState({ loading: true });
        await this.getSubscriptionStatus();

        this.setState({ loading: false });
    }

    async getSubscriptionStatus() {
        if(this.props.id_student_subscription_term) {
            let query = await Firestore.getDoc('student_subscription_term', this.props.id_student_subscription_term);

            if (query.exists) {

                
                let doc = query.data();
                console.log(doc?.signature?.date);

                this.setState({ status: doc?.signature?.date ? `Assinado em ${moment(doc.signature?.date?.toDate()).format('DD/MM/YYYY HH:mm')}` : 'Pendente de Assinatura' });
            }
        } else {
            this.setState({ status: 'Sem Contrato' });
        }
    }

    renderLoading() {

        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ClipLoader
                size={25}
                color={Colors.primary}
                loading={true}
                />
            </div>
        )
    }

    renderSubscriptionStatus(status) {

        let text;
        let color;

        text = status;

        if (status && status.includes('Assinado')) {
            color = Colors.success;
        } else if (status === 'Pendente de Assinatura') {
            color = Colors.danger;
        } else if (status === 'Sem Contrato') {
            color = 'lightgrey';
        } else {
            text = 'Sem Contrato';
            color = 'lightgrey';
        }

        return <b style={{ backgroundColor: color, color: 'white', paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8, borderRadius: 5 }}>{text}</b>
    }

    render() {

        return this.state.loading ? this.renderLoading() : (

            <div style={{ marginTop: 10 }}>
                {this.renderSubscriptionStatus(this.state.status)}
            </div>
        );
    }
}