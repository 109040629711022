import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import { Card, Button } from '@material-ui/core';
import DefaultInput from '../components/DefaultInput';
import Colors from '../constants/Colors';
import Auth from '../api/firebase/Auth';
import { withRouter } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import Firestore from '../api/firebase/Firestore';
import firebase from 'firebase/app';
import 'firebase/auth'
import FirebaseConfigTest from '../constants/FirebaseTest';
import DefaultLoader from '../components/DefaultLoader';
class LoginPage extends Component {

    state = {
        email: '',
        password: '',
        emailError: false,
        passwordError: false,
        emailErrorMessage: '',
        passwordErrorMessage: '',
        error: false,
        loading: false,
    }

    handleShortcut = (evt) => {
        if (evt.key === "Enter") {
            this.login();
        }
    }

    async login() {

        this.setState({ emailError: false, passwordError: false, emailErrorMessage: '', passwordErrorMessage: '', error: false, loading: true });

        if (this.state.email && this.state.password) {
            await this.setState({ email: this.state.email.replace(/\s/g, '') });

            sessionStorage.setItem('loginEmail', this.state.email);

            if (this.state.email == 'test_company2@aceapp.com.br') {
                if (firebase.apps.length) {
                    const app = firebase.app();
                    await app.delete();
                }

                firebase.initializeApp(FirebaseConfigTest);
            }

            Auth.login(this.state.email, this.state.password).then(async (result) => {

                if (result.user) {

                    let user = await Firestore.getDoc('user', result.user.uid);

                    if (user.exists) {

                        let data = user.data();

                        if (data.type === 'admin' || data.type === 'super_admin' || data.type === 'sales') {

                            this.props.history.push('/');

                        } else {

                            this.setState({ passwordError: true, emailError: true, passwordErrorMessage: 'E-mail ou senha incorretos', loading: false });
                        }
                    }

                } else {

                    this.setState({ passwordError: true, emailError: true, passwordErrorMessage: 'E-mail ou senha incorretos', loading: false });
                }
            });

        } else {

            if (!this.state.email) {
                this.setState({ emailError: true, emailErrorMessage: 'Informe o e-mail', loading: false, });
            }

            if (!this.state.password) {
                this.setState({ passwordError: true, passwordErrorMessage: 'Informe a senha', loading: false });
            }
        }
    }

    renderLoading() {
        return (
            <DefaultLoader
                css={{ position: 'absolute', top: '34vh', left: '49%' }}
                size={50}
                color={Colors.primary}
                loading={true}
            />
        );
    }

    render() {
        return (
            <Container style={{ backgroundColor: Colors.primary, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundImage: `url(${process.env.PUBLIC_URL + '/background.jpg'})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} maxWidth={false}>
                <div style={{ height: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                    <Card style={{ padding: 30, filter: this.state.loading ? 'blur(5px)' : '', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <img style={{ paddingBottom: 70, paddingTop: 15, height: 100, }} src={process.env.PUBLIC_URL + '/logo.png'} />
                        <form>
                            <DefaultInput onKeyDown={this.handleShortcut} error={this.state.emailError} helperText={this.state.emailErrorMessage} onChange={(v) => { this.setState({ email: v }) }} label={'E-mail'} />
                            <DefaultInput onKeyDown={this.handleShortcut} error={this.state.passwordError} helperText={this.state.passwordErrorMessage} onChange={(v) => { this.setState({ password: v }) }} type={'password'} label={'Senha'} />
                            <Button onClick={() => { this.login() }} style={{ width: '100%', fontWeight: 'bold', marginTop: 30, padding: 15, backgroundColor: Colors.primary, color: 'white' }} variant="contained" >ENTRAR</Button>
                        </form>
                        {/* <a href={'/register'} className={'register-link'}>{'Não possui uma conta? Contrate agora mesmo'}</a> */}
                        <a href={'/password_reset'} className={'register-link'}>{'Esqueci minha senha'}</a>
                    </Card>
                    {this.state.loading ? this.renderLoading() : null}
                </div>
            </Container>
        );
    }
}

export default withRouter(LoginPage);
