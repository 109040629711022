import React, { Component } from 'react';
import IosSwitch from '../IosSwitch';
import DefaultButton from '../DefaultButton';
import DefaultLoader from '../DefaultLoader';
import SessionHelper from '../../helper/SessionHelper';
import ReportHelper from '../../helper/ReportHelper';
import moment from "moment-timezone";
import Firestore from '../../api/firebase/Firestore';
import { toast } from 'react-toastify';
import { FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import Colors from '../../constants/Colors';
import CurrencyHelper from '../../helper/CurrencyHelper';
import ReportDefaultModalOptions from './ReportDefaultModalOptions';
import Pagarme from '../../api/pagarme/Pagarme';

export default class CashierFlowReport extends Component {

    state = {
        loadingModal: true,
        salesUserDocs: [],
        adminDocs: [],
        saleUsers: [],
        admins: [],
        start: moment().startOf('month').startOf('day').toDate(),
        end: moment().endOf('day').toDate(),
        output: 'download',
        format: 'pdf',
        detailed: false,
    }

    async componentDidMount() {

        if (this.props.getDocs && this.props.getDocs) {

            for (let index = 0; index < this.props.getDocs.length; index++) {
                
                const getDoc = this.props.getDocs[index];
                await getDoc(this);
            }
        }

        this.setState({ loadingModal: false });
    }

    async getReportDocs() {

        let returnDocs = [];
        let totalEntry = 0;
        let totalExit = 0;

        let start = moment(this.state.start).startOf('day').toDate();
        let end = moment(this.state.end).endOf('day').toDate();
        let orderQuery = await Firestore.customQuery('order').where('id_company', '==', SessionHelper.getData().id_company).where('date', '>=', start).where('date', '<=', end).where('type', 'in', ['product', 'ticket']).orderBy('date', 'desc').get();
        let expensesQuery = await Firestore.customQuery('expense').where('id_company', '==', SessionHelper.getData().id_company).where('date', '>=', start).where('date', '<=', end).where('paid', '==', true).orderBy('date', 'desc').get();
        let subscriptionsQuery = await Firestore.customQuery('student_subscription').where('id_company', '==', SessionHelper.getData().id_company).where('status', '==', 'paid').get();

        let orderDocs = [];
        let expenseDocs = [];
        let subscriptionDocs = [];

        orderQuery.forEach((orderDoc, key) => {

            let order = { ...orderDoc.data(), id: orderDoc.id };
            orderDocs.push(order);
        });

        expensesQuery.forEach((expenseDoc, key) => {

            let expense = { ...expenseDoc.data(), id: expenseDoc.id };
            expenseDocs.push(expense);
        });

        for (let subscriptionDoc of subscriptionsQuery.docs) {
            
            let subscription = { ...subscriptionDoc.data(), id: subscriptionDoc.id };

            if (subscription.id_subscription_pagarme) {

                let response = await Pagarme.get(`/subscriptions/${subscription.id_subscription_pagarme}`);

                if (response.id) {

                    subscription.subscription_pagarme = response;
                }
            }

            subscriptionDocs.push(subscription);
        }

        for (let m = moment(start); m.isBefore(moment(end)); m.add(1, 'days')) {

            let entry = 0;
            let exit = 0;

            let creditCardEntry = 0;
            let boletoEntry = 0;
            let moneyEntry = 0;
            let pixEntry = 0;
            let debitCardEntry = 0;
            let permutaEntry = 0;
            let bonificacaoEntry = 0;

            let creditCardExit = 0;
            let boletoExit = 0;
            let moneyExit = 0;
            let pixExit = 0;
            let debitCardExit = 0;
            let permutaExit = 0;
            let bonificacaoExit = 0;

            orderDocs.forEach((order, key) => {

                let orderDate = moment(order.date.toDate());
                let orderStatus = '';

                if (order.payment_method === 'credit-card') { order.payment_method = 'credit_card' };
                    
                if (order.payment_method === 'money') {

                    if (order.finished && !order.refunded && !order.canceled || (order.status === 'paid' && order.type === 'product' || order.type === 'ticket')) {
        
                        orderStatus = 'paid'
        
                    } else if (order.finished && order.refunded) {
        
                        orderStatus = 'refunded';
        
                    } else if (order.canceled) {
        
                        orderStatus = 'canceled';
        
                    } else {
        
                        orderStatus = 'waiting_payment';
                    }
        
                } else {
        
                    if (order.status === 'paid' || !order.status) {
        
                        if (order.refunded) {
        
                            orderStatus = 'refunded';
        
                        } else if (order.canceled) {
        
                            orderStatus = 'canceled'
        
                        } else {
        
                            orderStatus = 'paid';
                        }
        
                    } else if (order.status === 'waiting_payment' && !order.canceled && !order.refunded) {
        
                        orderStatus = 'waiting_payment';
        
                    } else if (order.canceled && !order.refunded) {
        
                        orderStatus = 'canceled';
        
                    } else {
        
                        orderStatus = 'refunded';
                    }
                }

                if (order.type !== 'ticket') {

                    if (orderDate.isValid() && orderDate.isSame(m, 'day') && orderDate.isSame(m, 'month') && orderDate.isSame(m, 'year')) {
                    
                        if (orderStatus === 'paid') {
    
                            entry += order.amount;

                            if (order.payment_method === 'credit_card') {

                                creditCardEntry += order.amount;

                            } else if (order.payment_method === 'boleto') {
                                
                                boletoEntry += order.amount;

                            } else if (order.payment_method === 'money') {
                                
                                moneyEntry += order.amount;

                            } else if (order.payment_method === 'pix') {
                                
                                pixEntry += order.amount;

                            } else if (order.payment_method === 'debit-card') {
                                
                                debitCardEntry += order.amount;

                            } else if (order.payment_method === 'permuta') {
                                
                                permutaEntry += order.amount;

                            } else if (order.payment_method === 'bonificacao') {
                                
                                bonificacaoEntry += order.amount;
                            }
                        }
                    }

                } else {

                    let paidValue = 0;
    
                    if (order.history) {
        
                        order.history.map((history, key) => {

                            let paymentDate = moment(history.date.toDate());

                            if (paymentDate.isValid() && paymentDate.isSame(m, 'day') && paymentDate.isSame(m, 'month') && paymentDate.isSame(m, 'year')) {

                                paidValue += history.amount;

                                if (history.payment_method === 'credit_card' || history.payment_method === 'credit-card') {

                                    creditCardEntry += history.amount;
    
                                } else if (history.payment_method === 'boleto') {
                                    
                                    boletoEntry += history.amount;
    
                                } else if (history.payment_method === 'money') {
                                    
                                    moneyEntry += history.amount;
    
                                } else if (history.payment_method === 'pix') {
                                    
                                    pixEntry += history.amount;
    
                                } else if (history.payment_method === 'debit-card') {
                                    
                                    debitCardEntry += history.amount;
    
                                } else if (history.payment_method === 'permuta') {
                                    
                                    permutaEntry += history.amount;
    
                                } else if (history.payment_method === 'bonificacao') {
                                    
                                    bonificacaoEntry += history.amount;
                                }
                            }
                        });
                    }
        
                    entry += paidValue;
                }
            });

            expenseDocs.forEach((expense, key) => {

                let paymentDate = moment(expense.date.toDate());

                if (paymentDate.isValid() && paymentDate.isSame(m, 'day') && paymentDate.isSame(m, 'month') && paymentDate.isSame(m, 'year')) {
                    
                    exit += expense.amount;

                    if (expense.payment_method === 'credit_card' || expense.payment_method === 'credit-card') {

                        creditCardEntry += expense.amount;

                    } else if (expense.payment_method === 'boleto') {
                        
                        boletoEntry += expense.amount;

                    } else if (expense.payment_method === 'money') {
                        
                        moneyEntry += expense.amount;

                    } else if (expense.payment_method === 'pix') {
                        
                        pixEntry += expense.amount;

                    } else if (expense.payment_method === 'debit-card') {
                        
                        debitCardEntry += expense.amount;

                    } else if (expense.payment_method === 'permuta') {
                        
                        permutaEntry += expense.amount;

                    } else if (expense.payment_method === 'bonificacao') {
                        
                        bonificacaoEntry += expense.amount;
                    }
                }
            });

            let balance = entry - exit;
            totalEntry += entry;
            totalExit += exit;
            
            returnDocs.push({
                date: m.format('DD/MMM/YYYY'),
                entry,
                exit,
                balance,
                payment_methods: [
                    { label: 'Cartão de Crédito', entry: creditCardEntry, exit: creditCardExit, balance: creditCardEntry - creditCardExit },
                    { label: 'Cartão de Débito', entry: debitCardEntry, exit: debitCardExit, balance: debitCardEntry - debitCardExit },
                    { label: 'Boleto', entry: boletoEntry, exit: boletoExit, balance: boletoEntry - boletoExit },
                    { label: 'Pagamento no Local', entry: moneyEntry, exit: moneyExit, balance: moneyEntry - moneyExit },
                    { label: 'Pix', entry: pixEntry, exit: pixExit, balance: pixEntry - pixExit },
                    { label: 'Permuta', entry: permutaEntry, exit: permutaExit, balance: permutaEntry - permutaExit },
                    { label: 'Bonificação', entry: bonificacaoEntry, exit: bonificacaoExit, balance: bonificacaoEntry - bonificacaoExit },
                ]
            });
        }

        return { docs: returnDocs.reverse(), totalizer: [{ entry: totalEntry, exit: totalExit, balance: totalEntry - totalExit }] }
    }

    async print() {

        if (this.state.start && this.state.end) {

            this.setState({ loadingModal: true });

            let name = `Fluxo de Caixa - ${SessionHelper.getData().company.name}`;
            let subtitle = `De ${moment(this.state.start).format('DD/MM/YYYY')} até ${moment(this.state.end).format('DD/MM/YYYY')}`;
            let docs = await this.getReportDocs();

            let report = await ReportHelper.createReport(name, 5, subtitle, this.state.format);

            let createPaymentMethodsColumn = async () => { };

            if (this.state.detailed) {

                createPaymentMethodsColumn =  async (report, actualDoc) => {

                    if (actualDoc.payment_methods && actualDoc.payment_methods.length) {
    
                        report = await ReportHelper.createColumns(report, actualDoc.payment_methods, [
                            { name: 'Método de Pagamento', width: 13, getText: (doc) => doc.label },
                            { name: 'Entradas', width: 60, getText: (doc) => CurrencyHelper.centsToMoney(doc.entry) },
                            { name: 'Entradas', width: 110, getText: (doc) => CurrencyHelper.centsToMoney(doc.exit) },
                        ], null, false, 6, [237, 237, 237], false);
    
                        return report;
                    }
                }
            }

            report = await ReportHelper.createColumns(report, docs.docs, [
                { name: 'Data', width: 13, getText: (doc) => doc.date },
                { name: 'Entradas', width: 60, getText: (doc) => CurrencyHelper.centsToMoney(doc.entry) },
                { name: 'Saídas', width: 110, getText: (doc) => CurrencyHelper.centsToMoney(doc.exit) },
                { name: 'Saldo Operacional', width: 165, getText: (doc) => CurrencyHelper.centsToMoney(doc.balance) },
            ], (report, actualDoc) => createPaymentMethodsColumn(report, actualDoc));
    
            report = await ReportHelper.createColumns(report, docs.totalizer, [
                { name: 'Total', width: 13, getText: (doc) => '' },
                { name: 'Entradas', width: 60, getText: (doc) => CurrencyHelper.centsToMoney(doc.entry) },
                { name: 'Saídas', width: 110, getText: (doc) => CurrencyHelper.centsToMoney(doc.exit) },
                { name: 'Saldo Operacional', width: 165, getText: (doc) => CurrencyHelper.centsToMoney(doc.balance) },
            ], null, false, 6);

            ReportHelper.print(report, this.state.output, this.state.format);

            this.setState({ loadingModal: false });

        } else {

            toast.warn('Preencha o período do relatório');
        }
    }

    renderLoading() {

        return (

            <div style={{ height: 200 }}>
                <DefaultLoader/>
            </div>
        )
    }

    renderModal() {

        return (

            <div>
                { ReportHelper.renderPeriodSelection(this, 'start', 'end') }
                <IosSwitch fullWidth label={'Detalhado'} checked={this.state.detailed} onChange={(e) => { this.setState({ detailed: e }) }} />
                <ReportDefaultModalOptions format={this.state.format} output={this.state.output} onOutputChange={(value) => { this.setState({ output: value }) }} onFormatChange={(value) => { this.setState({ format: value }) }}/>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <DefaultButton width={'100%'} title={'Imprimir'} onClick={() => { this.print() }} />
                </div>
            </div>
        )
    }

    render() {

        return (

            this.state.loadingModal ? this.renderLoading() : this.renderModal()
        )
    }
}