import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import { Card, CardActions, CardContent, Button, Tooltip, FormLabel } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import DefaultButton from '../components/DefaultButton';
import moment from "moment-timezone";
import { Doughnut } from 'react-chartjs-2';
import DefaultTable from '../components/DefaultTable';
import { toast } from 'react-toastify';
import SessionHelper from '../helper/SessionHelper';
import CurrencyHelper from '../helper/CurrencyHelper';
import Pagarme from '../api/pagarme/Pagarme';
import Firestore from '../api/firebase/Firestore';
import CallMadeIcon from '@material-ui/icons/CallMade';
import DescriptionIcon from '@material-ui/icons/Description';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Colors from '../constants/Colors';
import DefaultModal from '../components/DefaultModal';
import CurrencyInput from '../components/CurrencyInput';
import DefaultInput from '../components/DefaultInput';
import PagarmeConstants from '../constants/PagarMe';
import RelationField from '../components/grid/RelationField';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PaymentMethodHelper from '../helper/PaymentMethodHelper';

export default class HomePage extends Component {

    state = {
        loading: true,
        loadingModal: false,
        detailedBalanceModal: false,
        detailedBalance: null,
        recipientHistory: [],
        orderDocs: [],
        paymentAccountDocs: [],
        monthSales: 0,
        monthSalesGraph: [0, 0],
        transferAmount: 0,
        avaibleBalance: 0,
        waitingBalance: 0,
        updatedAt: moment().format('DD/MM/YYYY HH:mm'),
        withdrawModal: false,
        withdrawAccount: null,
        withdrawAmount: 0,
        rawWithdrawAmount: 0,
        detailedReceivableModal: false,
        receivableDocs: [],
    }

    async componentDidMount() {
        
        await this.getRecipientBalance();
        await this.getRecipientHistory();
        await this.getOrders();
        await this.getpaymentAccount();

        this.setState({ loading: false });
    }

    async getRecipientHistory() {

        let recipient = SessionHelper.getData().company.id_recipient_pagarme;

        if (recipient) {

            try {

                let response = await Pagarme.get(`/recipients/${recipient}/balance/operations`, `count=1000&page=${this.state.recipientHistoryPage}`);

                if (response && response.length) {
                    this.setState({ recipientHistory: response, fetchedHistory: true });
                }

            } catch (error) {
                toast.error('Erro ao buscar o seu histórico');
            }
        }
    }

    async getRecipientBalance() {

        let recipient = SessionHelper.getData().company.id_recipient_pagarme;

        if (recipient) {
            
            try {

                let response = await Pagarme.get(`/recipients/${recipient}/balance`, {});
            
                let avaible = response.available.amount;
                let waiting = response.waiting_funds.amount;

                this.setState({ avaibleBalance: avaible, waitingBalance: waiting, updatedAt: moment().format('DD/MM/YYYY HH:mm') })

            } catch (error) {
              
                toast.error('Erro ao buscar o seu saldo');
                this.setState({ avaibleBalance: 0, waitingBalance: 0, updatedAt: moment().format('DD/MM/YYYY HH:mm') })
            }

        } else {

            this.setState({ avaibleBalance: 0, waitingBalance: 0, updatedAt: moment().format('DD/MM/YYYY HH:mm') })
        }
    }

    async getOrders() {

        let query = await Firestore.customQuery('order').where('id_company', '==', SessionHelper.getData().id_company).get();
        let docs = [];
        let monthSales = 0;
        let monthSalesGraph = [0, 0, 0];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            if (moment(data.date.toDate()).isSame(moment(), 'month')) {
                monthSales ++;

                if (data.payment_method === 'boleto') {
                    monthSalesGraph[0] = monthSalesGraph[0] + 1;
                } else if (data.payment_method === 'credit_card') {

                    monthSalesGraph[1] = monthSalesGraph[1] + 1;

                } else if (data.payment_method === 'money') {
                    monthSalesGraph[2] = monthSalesGraph[2] + 1;
                }
            }

            docs.push(data);
        });

        this.setState({ orderDocs: docs, monthSales: monthSales, monthSalesGraph: monthSalesGraph });
    }

    async getpaymentAccount() {

        let query = await Firestore.customQuery('payment_account').where('id_company', '==', SessionHelper.getData().id_company).get();
        let docs = [];
        let primary = null;

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);

            if (data.primary) { primary = data.name }
        });

        this.setState({ paymentAccountDocs: docs, withdrawAccount: primary + ' (Conta Principal)' });
    }

    renderBalance() {

        return (
            <Card variant="outlined" style={{ width: '100%', height: window.screen.width < 1080 ? 'auto' : 200, margin: 5, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 10 }}>
                <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: 'bold', fontSize: 18 }}>Saldo Disponível</div>
                        <Tooltip title={'Esse é o seu dinheiro disponível para saque, resultado de suas vendas no app (Apenas cartão e boleto)'}><InfoIcon style={{ fontSize: 22 }}/></Tooltip>
                    </div>
                    <div style={{ paddingTop: 15 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ fontWeight: 'bold', fontSize: 30 }}>{'R$'}</div>
                            <div style={{ marginLeft: 10, fontSize: 40, fontWeight: 'lighter' }}>{CurrencyHelper.centsToMoney(this.state.avaibleBalance, false)}</div>
                        </div>
                        <div style={{ fontSize: 12, textAlign: 'center', color: 'grey' }}>{`Atualizado em ${this.state.updatedAt}`}</div>
                    </div>
                </CardContent>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <DefaultButton leftIcon={<AttachMoneyIcon />} color={`#59a63d`} onClick={() => { this.setState({ withdrawModal: true }) }} width={'90%'} title={'Realizar Saque'}/>
                </div>
            </Card>
        )
    }

    renderReceivable() {

        return (
            <Card variant="outlined" style={{ width: '100%', height: 200, margin: 5, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 10 }}>
                <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: 'bold', fontSize: 18 }}>À Receber</div>
                        <Tooltip title={'Esse é o total pendente para receber nos próximos dias'}><InfoIcon style={{ fontSize: 22 }}/></Tooltip>
                    </div>
                    <div style={{ paddingTop: 15 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ fontWeight: 'bold', fontSize: 30 }}>{'R$'}</div>
                            <div style={{ marginLeft: 10, fontSize: 40, fontWeight: 'lighter' }}>{CurrencyHelper.centsToMoney(this.state.waitingBalance, false)}</div>
                        </div>
                        <div style={{ fontSize: 12, textAlign: 'center', color: 'grey' }}>{`Atualizado em ${this.state.updatedAt}`}</div>
                    </div>
                </CardContent>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <DefaultButton leftIcon={<InsertDriveFileIcon />} color={Colors.primary} onClick={() => { this.handleReceivableModal() }} width={'90%'} title={'Visualizar Títulos'}/>
                </div>
            </Card>
        )
    }

    renderSales() {

        return (
            <Card variant="outlined" style={{ width: '100%', height: window.screen.width < 1080 ? 'auto' : 200, margin: 5, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 10 }}>
                <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: 'bold', fontSize: 18 }}>Vendas Nesse Mês</div>
                        <Tooltip title={'Total de vendas realizadas nesse mês'}><InfoIcon style={{ fontSize: 22 }}/></Tooltip>
                    </div>
                    <div style={{ paddingTop: 15 }}>
                        <Doughnut
                        height={window.screen.width < 1080 ? 250 : 70}
                        options={{ maintainAspectRatio: true, legend: false }}
                        data={{
                            labels: [
                                'Boleto',
                                'Cartão de Crédito',
                                'Pagamento no Local',
                            ],
                            datasets: [{
                                data: this.state.monthSalesGraph,
                                backgroundColor: [
                                '#FF6384',
                                '#36A2EB',
                                '#FFCE56'
                                ],
                                hoverBackgroundColor: [
                                '#FF6384',
                                '#36A2EB',
                                '#FFCE56'
                                ]
                            }]
                        }}/>
                        <div style={{ fontWeight: 'bold' }}>{`Total de Vendas: ${this.state.monthSales}`}</div>
                    </div>
                </CardContent>
            </Card>
        )
    }

    renderType(data) {

        const type = data.type;


        let color = '';
        let text = '';
        let icon = null;

        if (type === 'payable') {

            const movement_object = data.movement_object;

            if (movement_object && movement_object.type === 'credit') {

                color = Colors.success;
                text = 'Entrada';
                icon = <CallMadeIcon/>

            } else if (movement_object && movement_object.type === 'refund') {

                color = 'purple';
                text = 'Estorno';
                icon = <CallReceivedIcon/>
            }

        } else {

            color = Colors.error;
            text = 'Saída';
            icon = <CallReceivedIcon/>
        }

        return (
            <div style={{ color: color, display: 'flex', flexDirection: 'row', fontWeight: 'bold', alignItems: 'center' }}>
                {icon}
                <div style={{ marginLeft: 5 }}>{text}</div>
            </div>
        )
    }

    async fetchDetailedBalance(rowData) {

        let movement = rowData.movement_object;
        let data = null;

        this.setState({ loadingModal: true, detailedBalanceModal: true });

        if (movement.object === 'payable') {
            
            let response = await Pagarme.get(`/transactions/${movement.transaction_id}`);
            data = response;

            data.object = movement.object;
            data.discount_amount = movement.amount;

        } else if (movement.object === 'transfer') {

            data = movement;
        }
        
        this.setState({ loadingModal: false, detailedBalance: data, balance: rowData });
    }

    async handleReceivableModal() {

        let recipient = SessionHelper.getData().company.id_recipient_pagarme;
        let idCompany = SessionHelper.getData().id_company;

        if (recipient) {

            try {

                await this.setState({ detailedReceivableModal: true, loadingModal: true, receivableDocs: [] });

                let docs = [];
                let recipientOperations = await Pagarme.get('/payables', `recipient_id=${recipient}&type=credit&count=1000&page=1`);

                if (recipientOperations.errors && recipientOperations.errors.length) {

                    throw new Error(JSON.stringify(recipientOperations.errors));
                }

                let end = moment(recipientOperations[0].date_created).toDate().getTime();
                let start = moment(recipientOperations[recipientOperations.length - 1].date_created).toDate().getTime();;
                
                let periodTransactions = await Pagarme.get('/transactions', `status=paid&type=credit&count=1000&page=1&date_created=>=${start}&date_created=<=${end}&metadata.id_company=${idCompany}`);
    
                if (periodTransactions.errors && periodTransactions.errors.length) {
                    
                    throw new Error(JSON.stringify(periodTransactions.errors));
                }

                for (let operation of recipientOperations) {
                    
                    let alreadyRecevied = this.state.recipientHistory.find(item => item.movement_object.transaction_id === operation.transaction_id);

                    if (!alreadyRecevied) {

                        let transaction = periodTransactions.find(item => item.id === operation.transaction_id);

                        if (!transaction) {

                            let request = await Pagarme.get(`/transactions/${operation.transaction_id}`, ``);

                            if (request && !request.errors) {
                        
                                transaction = request;
                            }
                        }

                        docs.push({
                            amount: operation.amount - operation.fee,
                            date_created: moment(operation.date_created).format('DD/MM/YYYY HH:mm'),
                            fee: operation.fee,
                            installment: operation.installment,
                            installments: transaction.installments,
                            payment_date: moment(operation.payment_date).toDate(),
                            payment_method: transaction.payment_method,
                            customer: transaction.customer.name,
                            items: transaction.items,
                            id_order: transaction.metadata.id_order,
                            id_subscription: transaction.subscription_id,
                            status: transaction.status,
                        });
                    }
                }

                docs.sort((a, b) => {

                    return new Date(b.payment_date) - new Date(a.payment_date);
                });

                this.setState({ loadingModal: false, receivableDocs: docs });

            } catch (error) {

                toast.error('Houve um problema ao buscar os títulos');
            }
        }
    }

    renderTransactions() {

        return (

            <Card variant="outlined" style={{ width: '100%', height: 650, margin: 5, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 10 }}>
                <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: 'bold', fontSize: 18 }}>Movimentações</div>
                        <Tooltip title={'Essas são as movimentações realizadas na sua conta digital'}><InfoIcon style={{ fontSize: 22 }}/></Tooltip>
                    </div>
                    <div style={{ paddingTop: 15 }}>
                        <DefaultTable
                        title={''}
                        absoluteHeight={425}
                        actions={[
                            {
                                icon: DescriptionIcon,
                                tooltip: 'Detalhes da Movimentação',
                                onClick: (event, rowData) => { this.fetchDetailedBalance(rowData) } 
                            },
                        ]}
                        filtering={true}
                        columns={[
                            { title: 'Identificador', field: 'id' },
                            { title: 'Tipo', field: 'type', render: rowData => this.renderType(rowData) },
                            { title: 'Valor', field: 'amount', render: rowData => <div>{CurrencyHelper.centsToMoney(rowData.amount - (rowData.fee ? rowData.fee : 0), true)}</div> },
                            { title: 'Data da Movimentação', field: 'date_created', render: rowData => moment(rowData.date_created).format('DD/MM/YYYY HH:mm') },
                        ]}
                        data={this.state.recipientHistory}
                        borderRadius={0}
                        boxShadow={'rgba(50, 50, 50, 0) 1px 1px 10px 0px'}
                        />
                    </div>
                </CardContent>
            </Card>
        )
    }

    renderDetailedBalance() {
        console.log(this.state.balance)
        if (this.state.detailedBalance.object === 'payable') {
            return (
                <div>
                    <FormLabel style={{paddingBottom: 10}} component="legend">Dados</FormLabel>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: 'bold', marginRight: 5 }}>Tipo: </div>
                        {this.renderType(this.state.balance)}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: 'bold', marginRight: 5 }}>Valor: </div>
                        {CurrencyHelper.centsToMoney(this.state.detailedBalance.amount, true)}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: 'bold', marginRight: 5 }}>Valor Líquido: </div>
                        {CurrencyHelper.centsToMoney(this.state.balance.amount - (this.state.balance.fee ? this.state.balance.fee : 0), true)}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: 'bold', marginRight: 5 }}>Método de Pagamento: </div>
                        {this.state.detailedBalance.payment_method === 'boleto' ? 'Boleto' : 'Cartão de Crédito'}
                    </div>

                    { this.state.detailedBalance.metadata.id_order ?
                    
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ fontWeight: 'bold', marginRight: 5 }}>Pedido: </div>
                            <RelationField collection={'order'} field={'title'} id={this.state.detailedBalance.metadata.id_order} loading={false}/>
                        </div>
                
                    : null }

                    { this.state.detailedBalance.metadata.id_plan ?
                    
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: 'bold', marginRight: 5 }}>Plano: </div>
                        <RelationField collection={'student_plan'} field={'name'} id={this.state.detailedBalance.metadata.id_plan} loading={false}/>
                    </div>
            
                    : null }

                    { this.state.detailedBalance.items && this.state.detailedBalance.items.length ?
                    
                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                            <div style={{ fontWeight: 'bold', marginRight: 5 }}>Itens: </div>
                            { 
                                this.state.detailedBalance.items.map((item, key) => {
                                    return (
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div style={{ paddingRight: 3 }}>Nome: {item.title} </div>
                                            <div style={{ paddingRight: 3 }}>Quantidade: {item.quantity} </div>
                                            <div style={{ paddingRight: 3 }}>Valor Unitário: {CurrencyHelper.centsToMoney(item.unit_price, true)} </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    
                    : null }

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: 'bold', marginRight: 5 }}>Data de Pagamento: </div>
                        {moment(this.state.detailedBalance.date_updated).format('DD/MM/YYYY')}
                    </div>
    
                    <FormLabel style={{paddingTop: 25, paddingBottom: 10}} component="legend">Cliente</FormLabel>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: 'bold', marginRight: 5 }}>Nome: </div>
                        {this.state.detailedBalance.customer.name}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: 'bold', marginRight: 5 }}>E-mail: </div>
                        {this.state.detailedBalance.customer.email}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: 'bold', marginRight: 5 }}>Cpf: </div>
                        {this.state.detailedBalance.customer.documents.length ? this.state.detailedBalance.customer.documents[0].number : ''}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: 'bold', marginRight: 5 }}>Telefone: </div>
                        {this.state.detailedBalance.customer.phone_numbers ? this.state.detailedBalance.customer.phone_numbers[0] : ''}
                    </div>
    
                    { this.state.detailedBalance.billing && this.state.detailedBalance.billing.address? 

                        <div>
                            <FormLabel style={{paddingBottom: 10, paddingTop: 25}} component="legend">Endereço de Cobrança</FormLabel>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ fontWeight: 'bold', marginRight: 5 }}>Cep: </div>
                                {this.state.detailedBalance.billing.address.zipcode}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ fontWeight: 'bold', marginRight: 5 }}>Cidade: </div>
                                {this.state.detailedBalance.billing.address.city}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ fontWeight: 'bold', marginRight: 5 }}>Bairro: </div>
                                {this.state.detailedBalance.billing.address.neighborhood}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ fontWeight: 'bold', marginRight: 5 }}>Rua: </div>
                                {this.state.detailedBalance.billing.address.street}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ fontWeight: 'bold', marginRight: 5 }}>Número: </div>
                                {this.state.detailedBalance.billing.address.street_number}
                            </div>
                        </div>

                     : null }

                    { this.state.detailedBalance.address ? 

                        <div>
                            <FormLabel style={{paddingBottom: 10, paddingTop: 25}} component="legend">Endereço de Cobrança</FormLabel>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ fontWeight: 'bold', marginRight: 5 }}>Cep: </div>
                                {this.state.detailedBalance.address.zipcode}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ fontWeight: 'bold', marginRight: 5 }}>Cidade: </div>
                                {this.state.detailedBalance.address.city}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ fontWeight: 'bold', marginRight: 5 }}>Bairro: </div>
                                {this.state.detailedBalance.address.neighborhood}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ fontWeight: 'bold', marginRight: 5 }}>Rua: </div>
                                {this.state.detailedBalance.address.street}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ fontWeight: 'bold', marginRight: 5 }}>Número: </div>
                                {this.state.detailedBalance.address.street_number}
                            </div>
                        </div>

                     : null }
                </div>
            )

        } else if (this.state.detailedBalance.object === 'transfer') {
            return (
                <div>
                    <FormLabel style={{paddingBottom: 10}} component="legend">Dados</FormLabel>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: 'bold', marginRight: 5 }}>Tipo: </div>
                        {this.renderType(this.state.balance)}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: 'bold', marginRight: 5 }}>Valor: </div>
                        {CurrencyHelper.centsToMoney(this.state.detailedBalance.amount, true)}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: 'bold', marginRight: 5 }}>Taxa: </div>
                        {CurrencyHelper.centsToMoney(this.state.detailedBalance.fee, true)}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: 'bold', marginRight: 5 }}>Transferência: </div>
                        {this.state.detailedBalance.type.toUpperCase()}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: 'bold', marginRight: 5 }}>Data da Transferência: </div>
                        {moment(this.state.detailedBalance.date_created).format('DD/MM/YYYY')}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: 'bold', marginRight: 5 }}>Data Prevista de Crédito: </div>
                        {moment(this.state.detailedBalance.funding_estimated_date).format('DD/MM/YYYY')}
                    </div>

                    <FormLabel style={{paddingBottom: 10, paddingTop: 10}} component="legend">Conta Destino</FormLabel>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: 'bold', marginRight: 5 }}>Agencia: </div>
                        {this.state.detailedBalance.bank_account.agencia}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: 'bold', marginRight: 5 }}>Conta: </div>
                        {`${this.state.detailedBalance.bank_account.conta}-${this.state.detailedBalance.bank_account.conta_dv}`}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: 'bold', marginRight: 5 }}>Nome: </div>
                        {this.state.detailedBalance.bank_account.legal_name}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: 'bold', marginRight: 5 }}>{SessionHelper.getData().company.type === 'pj' ? 'Cnpj' : 'Cpf'}</div>
                        {this.state.detailedBalance.bank_account.document_number}
                    </div>
                </div>
            )
        }
    }

    detailedBalanceModal() {
        return (
            <div>
                { this.state.detailedBalance ? this.renderDetailedBalance() : null }
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.setState({ detailedBalanceModal: false, detailedBalance: null }) }} style={{width: '100%', fontWeight: 'bold', backgroundColor: Colors.primary, color: '#FFFFFF'}} variant={'contained'}>{'FECHAR'}</Button>
                </div>
            </div>
        )
    }

    detailedReceivableModal() {
        return (
            <div>
                {
                    this.state.receivableDocs.map((receivable, key) => {

                        return (

                            <div style={{ display: 'flex', flexDirection: 'row', padding: 0, paddingLeft: 12, paddingRight: 12, paddingTop: key === 0 ? 12 : 0, alignItems: 'flex-start', position: 'relative' }}>         
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <InsertDriveFileIcon style={{ border: `3px solid ${Colors.primary}`, color: Colors.primary, borderRadius: '100%', padding: 5, fontSize: 45, marginRight: 10 }}/>
                                    <div style={{ borderLeft: this.state.receivableDocs[key + 1] ? `3px dashed lightgrey` : '', height: 100, paddingRight: 10 }}/>
                                </div>                 
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingBottom: 25 }}>
                                    <div style={{ fontWeight: 'bold', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        { receivable.id_subscription ? <div>{'Matrícula'}</div> : <RelationField collection={'order'} field={'title'} id={receivable.id_order} loading={false}/> }
                                        <div style={{ marginLeft: 5, marginRight: 5 }}>{'•'}</div>
                                        {PaymentMethodHelper.getPaymentMethodLabel(receivable.payment_method)}
                                    </div>
                                    <div style={{ fontWeight: 'bold', color: receivable.status === 'refunded' ? 'purple' : Colors.success }}>{`${receivable.status === 'refunded' ? '-' : ''}${CurrencyHelper.centsToMoney(receivable.amount)} (${receivable.status === 'refunded' ? 'Estornado' : 'Pago'})`}</div>
                                    <div>{receivable.installment ? `Parcela ${receivable.installment} de ${receivable.installments}` : ''}</div>
                                    <div>{`${receivable.customer}`}</div>
                                    <div style={{ width: 280, boxShadow: Colors.boxShadow, fontWeight: 'bold', color: 'white', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 8, padding: 5, backgroundColor: Colors.danger, borderRadius: 5 }}>
                                        <AccessTimeIcon style={{ color: 'white', marginRight: 5 }}/>
                                        {`Crédito previsto para ${moment(receivable.payment_date).format('DD/MM/YYYY')}`}
                                    </div>
                                    <div style={{ fontSize: 12, position: 'absolute', right: 0, bottom: 0, color: 'grey' }}>{`Criado em ${receivable.date_created}`}</div>
                                </div>
                            </div>
                        )
                    })
                }
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.setState({ detailedReceivableModal: false }) }} style={{width: '100%', fontWeight: 'bold', backgroundColor: Colors.primary, color: '#FFFFFF'}} variant={'contained'}>{'FECHAR'}</Button>
                </div>
            </div>
        )
    }

    async withdraw() {
        let rawAmount = (this.state.rawWithdrawAmount * 100);
        let amount = rawAmount - PagarmeConstants.getKeys().transfer_tax;

        if (amount > 0) {

            if (rawAmount >= PagarmeConstants.getKeys().transfer_min_value) {

                if (rawAmount <= this.state.avaibleBalance) {

                    try {

                        this.setState({ loadingModal: true });

                        let data = {
                            amount: amount,
                            recipient_id: SessionHelper.getData().company.id_recipient_pagarme,
                            metadata: { company: SessionHelper.getData().id_company, id_user: SessionHelper.getFirebaseAuth().uid }
                        };
        
                        let response = await Pagarme.post('/transfers', data);
        
                        if (response.errors) {
                            throw new Error(JSON.stringify(response.errors));
                        }
        
                        await this.getRecipientBalance();
                        await this.getRecipientHistory();

                        await this.setState({ loadingModal: false, withdrawModal: false });
                        
                        toast.success('Saque realizado com sucesso');

                    } catch (error) {

                        toast.error('Houve um problema ao realizar a transferência');
                    }

                } else {

                    toast.warn('Saldo Insuficiente');
                }

            } else {

                toast.warn(`O valor mínimo de saque é ${CurrencyHelper.centsToMoney(PagarmeConstants.getKeys().transfer_min_value, true)}`);
            }
            
        } else {

            toast.warn('O valor de saque deve ser maior que zero');
        }
    }

    withdrawModal() {
        return (
            <div>
                <DefaultInput label={'Conta Destino'} value={this.state.withdrawAccount} disabled={true}/>
                
                <div style={{ paddingBottom: 10 }}>
                    <div style={{ fontWeight: 'bold', color: '#59a63d', fontSize: 16 }}>{`Seu saldo é de: ${CurrencyHelper.centsToMoney(this.state.avaibleBalance, true)}`}</div>
                </div>

                <CurrencyInput label={'Valor do Saque'} onChange={(values) => { const {formattedValue, value} = values; this.setState({withdrawAmount: formattedValue, rawWithdrawAmount: value}) }} />
                
                <div style={{ paddingTop: 2 }}>
                    <div style={{ fontWeight: 'normal', fontWeight: 'bold', color: '#59a63d' }}>{`Você recebe: ${this.state.rawWithdrawAmount * 100 > PagarmeConstants.getKeys().transfer_tax ? CurrencyHelper.centsToMoney((this.state.rawWithdrawAmount * 100) - PagarmeConstants.getKeys().transfer_tax) : CurrencyHelper.centsToMoney(0)}`}</div>
                    <div style={{ fontWeight: 'normal', fontWeight: 'bold', color: `#4d4d4d` }}>{`Taxa de Saque: ${CurrencyHelper.centsToMoney(PagarmeConstants.getKeys().transfer_tax, true)}`}</div>
                </div>

                <div style={{ paddingTop: 10 }}>
                    <div style={{ fontWeight: 'normal', fontWeight: 'normal', color: '#4d4d4d', fontSize: 12 }}>{`Saque mínimo: ${CurrencyHelper.centsToMoney(PagarmeConstants.getKeys().transfer_min_value, true)}`}</div>
                    <div style={{ fontWeight: 'normal', fontWeight: 'normal', color: '#4d4d4d', fontSize: 12, marginTop: 5 }}>{`Você recebe um TED na conta selecionada em até duas horas após a efetivação da transação ou no próximo dia útil, se realizar o saque em feriado ou fim de semana.`}</div>
                </div>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 20}}>
                    <Button onClick={() => { this.withdraw() }} style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ withdrawModal: false, rawWithdrawAmount: 0 }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }
    
    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={{ padding: 20, display: 'flex', flexDirection: 'column' }}>
                    
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'mobile-dashboard'}>
                        
                        { this.renderBalance() }
                        { this.renderReceivable() }
                        { this.renderSales() }

                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'mobile-dashboard'}>

                        { this.renderTransactions() }

                    </div>
                    <DefaultModal loading={this.state.loadingModal} content={this.withdrawModal()} title={'Realizar Saque'} onClose={() => { this.setState({ withdrawModal: false }) }} open={this.state.withdrawModal}/>
                    <DefaultModal loading={this.state.loadingModal} content={this.detailedBalanceModal()} title={'Detalhes da Movimentação'} onClose={() => { this.setState({ detailedBalanceModal: false }) }} open={this.state.detailedBalanceModal}/>
                    <DefaultModal loading={this.state.loadingModal} content={this.detailedReceivableModal()} title={'Recebíveis Futuros'} onClose={() => { this.setState({ detailedReceivableModal: false }) }} open={this.state.detailedReceivableModal}/>
            </div>
        );
    }
}
