import { toast } from 'react-toastify';

export default class PaymentMethodHelper {

    static getPaymentMethodsByService(service, exclude = [], showLocalPaymentAsMoney = false) {

        try {

            if (!service) { throw new Error() }

            let localPaymentLabel = showLocalPaymentAsMoney ? 'Dinheiro' : 'Pagamento no Local';
            let methods = [];

            switch (service) {

                case 'court_rent_packs':

                    methods = [

                        { label: 'Cartão de Crédito', value: 'credit_card' },
                        { label: 'Pix', value: 'pix' },
                    ]
                    break;
                case 'private_lesson_packs':

                    methods = [

                        { label: 'Cartão de Crédito', value: 'credit_card' },
                        { label: 'Pix', value: 'pix' },
                    ]
                    break;
                case 'court_rent':

                    methods = [

                        { label: 'Cartão de Crédito', value: 'credit_card' },
                        { label: 'Pix', value: 'pix' },
                        { label: localPaymentLabel, value: 'money' },
                    ]
                    break;
                case 'private_lesson':

                    methods = [

                        { label: 'Cartão de Crédito', value: 'credit_card' },
                        { label: 'Pix', value: 'pix' },
                        { label: localPaymentLabel, value: 'money' },
                    ]
                    break;
                case 'experimental_lesson':

                    methods = [

                        { label: 'Cartão de Crédito', value: 'credit_card' },
                        { label: 'Pix', value: 'pix' },
                        { label: localPaymentLabel, value: 'money' },
                    ]
                    break;
                case 'tournament':

                    methods = [

                        { label: 'Cartão de Crédito', value: 'credit_card' },
                        { label: 'Pix', value: 'pix' },
                        { label: 'Boleto', value: 'boleto' },
                    ]
                    break;
                case 'ranking':

                    methods = [

                        { label: 'Cartão de Crédito', value: 'credit_card' },
                        { label: 'Pix', value: 'pix' },
                        { label: 'Boleto', value: 'boleto' },
                    ]
                    break;
                case 'day_use':

                    methods = [

                        { label: 'Cartão de Crédito', value: 'credit_card' },
                        { label: 'Pix', value: 'pix' },
                        { label: localPaymentLabel, value: 'money' },
                    ]
                    break;
                case 'order':
                    methods = [

                        { value: 'boleto', label: 'Boleto' },
                        { value: 'credit_card', label: 'Cartão de Crédito' },
                        { value: 'debit-card', label: 'Cartão de Débito' },
                        { value: 'money', label: localPaymentLabel },
                        { value: 'pix', label: 'Pix' },
                        { value: 'ticket', label: 'Comanda em Aberto' },
                        { value: 'permuta', label: 'Permuta' },
                        { value: 'bonificacao', label: 'Bonificação' },
                    ]
                    break;
            }

            if (exclude.length) {

                exclude.forEach((exclude, key) => {

                    let index = methods.findIndex(item => item.value === exclude);

                    if (index >= 0) {

                        methods.splice(index, 1);
                    }
                });
            }

            return methods;

        } catch (error) {

            return error;
        }
    }

    static validateCompanyPaymentMethods(object) {

        let result = true;

        Object.keys(object).forEach((key) => {

            if (!object[key].length) {

                let text = '';

                if (key === 'court_rent_packs') { text = 'Pacote de Aluguéis de Quadra' }
                else if (key === 'private_lesson_packs') { text = 'Pacote de Aulas Avulsas' }
                else if (key === 'court_rent') { text = 'Aluguel de Quadra' }
                else if (key === 'private_lesson') { text = 'Aulas Avulsas' }
                else if (key === 'experimental_lesson') { text = 'Aulas Experimentais' }
                else if (key === 'tournament') { text = 'Inscrição de Torneio' }

                toast.warn(`Selecione ao menos um método de pagamento para ${text}`)
                result = false;
            }
        });

        return result;
    }

    static getPaymentMethodLabel(paymentMethod, showLocalPaymentAsMoney = false) {

        try {

            if (!paymentMethod) { throw new Error() }

            let localPaymentLabel = showLocalPaymentAsMoney ? 'Dinheiro' : 'Pagamento no Local';

            switch (paymentMethod) {

                case 'credit_card':

                    return 'Cartão de Crédito';

                case 'credit-card':

                    return 'Cartão de Crédito';

                case 'boleto':

                    return 'Boleto';

                case 'money':

                    return localPaymentLabel;

                case 'pix':

                    return 'Pix';

                case 'debit-card':

                    return 'Cartão de Débito';

                case 'ticket':

                    return 'Comanda em Aberto';

                case 'permuta':

                    return 'Permuta';

                case 'bonificacao':

                    return 'Bonificação';
            }

        } catch (error) {

            return error;
        }
    }

    static expensesLookup() {

        return {
            'boleto': 'Boleto',
            'credit_card': 'Cartão de Crédito',
            'debit-card': 'Cartão de Débito',
            'money': 'Dinheiro',
            'pix': 'Pix',
        }
    }
}