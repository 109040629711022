import React from "react";
import Colors from '../constants/Colors';
import SyncIcon from '@material-ui/icons/Sync';
import * as Sentry from "@sentry/react";

export default class ErrorContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });

    Sentry.captureException(error);
    Sentry.captureMessage("Blue Screen Error (ErrorContainer: render error)");
  }

  render() {

    if (this.state.hasError) {

      return (

        <div style={{ height: this.props.height ? this.props.height : '100vh', backgroundColor: Colors.primary, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: Colors.primary, fontWeight: 'bold' }}>
          <img style={{ height: 28, marginLeft: 'auto', marginRight: 'auto', position: `absolute`, top: 15, left: 15 }} src={process.env.PUBLIC_URL + '/bg-wide-white.png'} />
          <div style={{ fontSize: 120, color: 'white' }}>Oops!</div>
          <div style={{ fontSize: 25, marginTop: 50, color: 'white' }}>UM ERRO DESCONHECIDO OCORREU :(</div>
          <div style={{ fontSize: 14, marginTop: 8, color: 'white' }}>Sentimos muito pelo inconveniente, recebemos um relatório desse erro e estamos trabalhando para resolvê-lo.</div>
          <div onClick={() => { window.location.reload(true) }} style={{ paddingTop: 3, border: '4px solid white', cursor: 'pointer', marginTop: 100, paddingBottom: 3, paddingLeft: 15, borderRadius: 8, paddingRight: 15, backgroundColor: Colors.primary, boxShadow: Colors.boxShadow, width: 250, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <SyncIcon style={{ color: 'white', marginRight: 20 }} />
            <p style={{ fontWeight: 'bold', color: 'white', textAlign: 'center' }}>{'RECARREGAR PÁGINA'}</p>
          </div>
        </div>
      )
    }

    return this.props.children;
  }
}