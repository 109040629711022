import React, { Component } from 'react';
import Colors from '../constants/Colors';
import moment from "moment-timezone";
import 'moment/locale/pt-br';
import { Tooltip } from '@material-ui/core';
import CurrencyHelper from '../helper/CurrencyHelper';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { toast } from 'react-toastify';
import RelationField from './grid/RelationField';

export default class SaleCard extends Component {
    state = {
        user: {},
        adminUser: {},
        paymentOptions: {
            pix: {label: 'Pix'},
            money: {label: 'Dinheiro'},
            'credit-card': {label: 'Cartão de Crédito'},
            'debit-card': {label: 'Cartão de Débito'},
            'permuta': {label: 'Permuta'},
            'bonificacao': {label: 'Bonificação'},
        },
    }

    getUser = () => {
        return this.props.usersDocs.find(user => user.id === this.props.doc.id_user);
    }

    getAdminUser = () => {
        return this.props.adminUsers.find(user => user.id === this.props.doc.created_by);
    }


    renderImages = () => {
        let content = null;

        content = (
            <div style={{marginTop: 10, width: '100%'}}>
                <div style={{ ...styles.text, fontSize: 11, fontWeight: 'bold', marginTop: 5, color: `rgba(0, 0, 0, 0.54)`, flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>{moment(this.props.doc.date.toDate()).format('DD/MM/YYYY HH:mm')} <RelationField collection={'user'} id={this.props.doc.created_by} field={'name'} loading={false} /></div>
            </div>
        );

        return content;
    }

    renderTags = () => {
        let content = null;
        let productsCounter = 0,
            servicesCounter = 0,
            products = '',
            services = '';

        this.props.doc.items.forEach(item => {
            if (item.tangible) {
                productsCounter += item.quantity;
                products += `${item.title} (${item.quantity}) (${CurrencyHelper.centsToMoney(item.unit_price)}), `;
            } else {
                servicesCounter += 1;
                services += `${item.title} (${item.quantity}) (${CurrencyHelper.centsToMoney(item.unit_price)}), `;
            }
        })

        content = (
            <div>
                {productsCounter > 0 ? <Tooltip title={products}><div style={{ ...styles.indicatorTag, backgroundColor: Colors.primary, width: '100%' }}>{productsCounter} PRODUTOS</div></Tooltip> : null}
                {servicesCounter > 0 ? <Tooltip title={services}><div style={{ ...styles.indicatorTag, backgroundColor: Colors.contrast, width: '100%' }}>{servicesCounter} SERVIÇOS</div></Tooltip> : null}
                {this.props.doc.discount > 0 ? <Tooltip title={`O valor original era ${CurrencyHelper.centsToMoney(this.props.doc.brute_amount)}`}><div style={{ ...styles.indicatorTag, backgroundColor: Colors.danger, width: '100%' }}>{CurrencyHelper.centsToMoney(this.props.doc.discount)} DESCONTO</div></Tooltip> : null}
                {this.props.doc.payment_method ? <Tooltip title={`Pago com ${this.state.paymentOptions[this.props.doc.payment_method].label}`}><div style={{ ...styles.indicatorTag, backgroundColor: Colors.success, width: '100%' }}>{(this.state.paymentOptions[this.props.doc.payment_method].label).toUpperCase()}</div></Tooltip> : null}
            </div>
        );

        return content;
    }

    render() {
        return (
            <div style={{ ...styles.wrapper, margin: this.props.horizontal ? 5 : `5px 0px 0px 0px`, width: this.props.horizontal ? 250 : `100%` }} onClick={this.props.onClick}>
                <div onClick={(e) => { navigator.clipboard.writeText(this.props.doc.title); toast.info('Nº do pedido copiado!'); e.stopPropagation(); }} style={styles.titleContainer}><div style={styles.title}>{this.props.doc.title}</div> <FileCopyIcon style={{ ...styles.title, marginLeft: 6, marginBottom: 2 }} /></div>
                <div style={styles.primaryText}>{CurrencyHelper.centsToMoney(this.props.doc.amount)}</div>
                <div style={styles.primaryText}>{this.getUser() ? this.getUser().name : `Sem Usuário`}</div>
                {this.renderTags()}
                {this.renderImages()}
            </div>
        );
    }
}

const styles = {
    wrapper: {
        cursor: `pointer`,
        width: `100%`,
        heigth: 150,
        backgroundColor: `#fff`,
        marginTop: 5,
        borderRadius: 5,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
        boxShadow: `0px 0px 2px 0px rgba(34,34,34,0.46)`
    },
    title: {
        fontWeight: 'bold',
        fontSize: 16,
        color: Colors.dark
    },
    primaryText: {
        fontSize: 16,
        color: Colors.dark
    },
    titleIcon: {
        fontSize: 16,
        marginRight: 3
    },
    titleContainer: {
        display: `flex`,
        flexDirection: `row`,
        justifyContent: `flex-start`,
        alignItems: `center`,
        marginBottom: 4
    },
    text: {
        fontSize: 14,
        color: Colors.formLabel
    },
    teacherImage: {
        borderRadius: 12,
        heigth: 24,
        width: 24
    },
    teacherImageContainer: {
        marginTop: 10,
        width: `100%`,
        cursor: `pointer`,
        display: `flex`,
        flexDirection: `row`,
        justifyContent: `space-between`,
    },
    teacherImage: {
        borderRadius: 12,
        heigth: 24,
        width: 24
    },
    teacherImageContainer: {
        marginTop: 10,
        width: `100%`,
        cursor: `pointer`,
        display: `flex`,
        flexDirection: `row`,
        justifyContent: `space-between`,
    },
    indicatorTag: {
        marginTop: 8,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 2,
        paddingTop: 2,
        color: '#fff',
        borderRadius: 5,
        textAlign: 'center',
        width: 110,
        fontWeight: 'bold',
        fontSize: 13
    }
};