import React from 'react';
import Colors from '../constants/Colors';
import ClipLoader from "react-spinners/ClipLoader";
import SalesHelper from '../helper/SalesHelper';

export default class UserTicketStatus extends React.Component {

    state = {
        loading: true,
        status: null,
        openTickets: {}
    }

    async componentDidMount() {

        await this.setState({ loading: true });
        await this.getSubscriptionStatus();

        this.setState({ loading: false });
    }

    async componentDidUpdate(prev) {

        if (this.props.user !== prev.user) {

            await this.setState({ loading: true });
            await this.getSubscriptionStatus();

            this.setState({ loading: false });
        }
    }

    async getSubscriptionStatus() {
        let openTickets = await SalesHelper.getUserOpenTickets(this.props.user);
        this.setState({ openTickets });
    }

    renderLoading() {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ClipLoader
                    size={25}
                    color={Colors.primary}
                    loading={true}
                />
            </div>
        )
    }

    renderSubscriptionStatus(status) {
        return <div style={{ display: 'flex', flexDirection: 'row' }}>
            <b style={{ backgroundColor: this.state.openTickets.open > 0 ? Colors.danger : Colors.success, color: 'white', paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8, borderRadius: 5, marginRight: 10 }}>{this.state.openTickets.open} abertas</b>
            <b style={{ backgroundColor: this.state.openTickets.late > 0 ? Colors.error : Colors.success, color: 'white', paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8, borderRadius: 5 }}>{this.state.openTickets.late} vencidas</b>
        </div>
    }

    render() {

        return this.state.loading ? this.renderLoading() : (

            <div style={{}}>
                {this.renderSubscriptionStatus()}
            </div>
        );
    }
}