import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button, FormLabel, Select, MenuItem, InputLabel, Tooltip, IconButton, TextareaAutosize } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import GroupIcon from '@material-ui/icons/Group';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import WorkIcon from '@material-ui/icons/Work';
import ImageIcon from '@material-ui/icons/Image';
import DeleteIcon from '@material-ui/icons/Delete';
import EventIcon from '@material-ui/icons/Event';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import RoomIcon from '@material-ui/icons/Room';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import PrintIcon from '@material-ui/icons/Print';
import Firestore from '../api/firebase/Firestore';
import moment from "moment-timezone";
import IosSwitch from '../components/IosSwitch';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import CurrencyInput from '../components/CurrencyInput';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker, KeyboardTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DefaultSelect from '../components/DefaultSelect';
import domtoimage from 'dom-to-image';
import LiquidProfit from '../components/LiquidProfit';
import InputMask from "react-input-mask";
import ImagePicker from '../components/ImagePicker';
import GeographicHelper from '../helper/GeographicHelper';
import LocationPicker from '../components/LocationPicker';
import Geohash from '../helper/Geohash';
import TournamentHelper from '../helper/TournamentHelper';
import MaximumMatchesInTournament from '../components/MaximumMatchesInTournament';
import TournamentView from '../components/TournamentView';
import UnderConstruction from '../components/UnderConstruction';
import Functions from '../api/firebase/Functions';
import TournamentKeys from '../components/TournamentKeys';
import EmojiEvents from '@material-ui/icons/EmojiEvents';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import ReceiptIcon from '@material-ui/icons/Receipt';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import HeaderActionsWrapper from '../components/HeaderActionsWrapper';
import FileCopy from '@material-ui/icons/FileCopy';
import TermHelper from '../helper/TermHelper';
import SearchBar from '../components/SearchBar';
import ArrayHelper from '../helper/ArrayHelper';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        maxWidth: '100%',
        fontSize: 13
    }
}))(Tooltip);
export default class TournamentPage extends Component {

    state = {
        docs: [],
        studentDocs: [],
        orderDocs: [],
        courtDocs: [],
        name: '',
        sports: TournamentHelper.getSports(),
        city: '',
        sport: '',
        banner: [],
        sponsors: [],
        matchAverageDuration: '',
        intervalBetweenPlayerMatches: '',
        award: '',
        multipleClassEnroll: false,
        internalEnrollOnly: false,
        hasAbsoluteLimit: false,
        absoluteLimit: '',
        inCompany: true,
        companyCourts: true,
        price: null,
        rawPrice: null,
        studentPrice: null,
        rawStudentPrice: null,
        ticketStart: null,
        ticketLimit: null,
        matchStart: null,
        matchEnd: null,
        loading: true,
        loadingModal: false,
        addModal: false,
        participantsModal: false,
        editId: null,
        category: TournamentHelper.getDefaultTournamentCategories(),
        type: '',
        courts: [],
        print: null,
        tournamentName: '',
        start: null,
        end: null,
        coordinates: SessionHelper.getData().company.coordinates ? SessionHelper.getData().company.coordinates : GeographicHelper.setFirebaseGeopoint(-27, -52),
        addCourt: false,
        addCourtText: '',
        bannerModal: false,
        sponsorsModal: false,
        maxParticipants: 0,
        editing: false,
        printModal: false,
        printTournament: null,
        selectedCategory: null,
        selectedClass: null,
        printRounds: [],
        searchIndexedParticipants: [],
        categoryKey: null,
        classKey: null,
        emptySearch: false
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('tournament').where('id_company', '==', SessionHelper.getData().id_company).orderBy('start', 'desc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.price = data.price / 100;
            data.student_price = data.student_price / 100;
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    async getStudents(participants) {
        let users = [];

        if (participants && participants.length) {
            let reducedParticipants = participants.reduce((reducedArray, item, index) => {
                const chunkIndex = Math.floor(index / 10);

                if (!reducedArray[chunkIndex]) {
                    reducedArray[chunkIndex] = [] // start a new chunk
                }

                reducedArray[chunkIndex].push(item)

                return reducedArray;
            }, []);

            for (const chunk of reducedParticipants) {
                if (chunk.length > 0) {
                    let query = await Firestore.customQuery('user').where(Firestore.idField(), 'in', chunk).get();

                    query.docs.map((doc) => {
                        let current = { ...doc.data(), id: doc.id };
                        users.push(current);
                    });
                }
            }
        }

        this.setState({ studentDocs: users });
    }

    async getOrders(id_tournament) {
        let query = await Firestore.customQuery('order').where('id_tournament', '==', id_tournament).get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = { ...doc.data(), id: doc.id };

            docs.push(data);
        });

        this.setState({ orderDocs: docs });
    }

    async getCourts() {

        let query = await Firestore.customQuery('court').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;
            data.sport_label = this.getSportLookup()[data.sport || `tennis`];
            docs.push(data);
        });

        this.setState({ courtDocs: docs, loading: false });
    }

    componentDidMount() {
        this.getDocs();
        this.getCourts();
    }

    generateBrackets = async (id, callback) => {
        toast.warn('Gerando chaves... Não saia dessa página até a geração ser concluída!', { autoClose: false });

        try {
            setTimeout(async () => {
                await Functions.request('PUT', `tournament/generateTournament/${id}`, {});

                setTimeout(async () => {
                    let query = await Firestore.customQuery('tournament_event').where('id_tournament', '==', id).limit(1).get();

                    if (query.docs.length < 1) {
                        setTimeout(async () => {
                            await Functions.request('PUT', `tournament/generateTournament/${id}`, {});
                            if (callback) callback();
                            toast.success('Chaves geradas com sucesso! Agora é seguro sair da página!', { autoClose: false });
                        }, 5000);
                    } else {
                        if (callback) callback();
                        toast.success('Chaves geradas com sucesso! Agora é seguro sair da página!', { autoClose: false });
                    }
                }, 4000);
            }, 3000);
        } catch (e) {
            toast.error('Ocorreu um erro ao gerar as chaves. Contate o suporte do ACE para mais informações!', { autoClose: false });
            return;
        }

    }

    deleteTournament = async (id, callback) => {
        toast.warn('Apagando torneio... Não saia dessa página até a operação ser concluída!', { autoClose: false });

        try {
            let request = await Functions.request('PUT', `tournament/deleteTournament/${id}`, {});

            if (request.status === 200) {
                setTimeout(async () => {
                    let query = await Firestore.customQuery('tournament_event').where('id_tournament', '==', id).limit(1).get();

                    if (query.docs.length > 0) {
                        setTimeout(async () => {
                            await Functions.request('PUT', `tournament/deleteTournament/${id}`, {});
                            if (callback) callback(true);
                            toast.info('Torneio apagado! Agora é seguro sair da página!', { autoClose: false });
                        }, 5000);
                    } else {
                        if (callback) callback(true);
                        toast.info('Torneio apagado! Agora é seguro sair da página!', { autoClose: false });
                    }
                }, 6000);
            } else {
                if (callback) callback(false);
                toast.error(request.msg);
            }
        } catch (e) {
            if (callback) callback(false);
            toast.error('Ocorreu um erro ao apagar o torneio. Contate o suporte do ACE para mais informações!', { autoClose: false });
            return;
        }

    }

    async addTournament() {

        if (this.state.loadingModal == true) {
            toast.warn('Aguarde, realizando ação...');
            return;
        }

        if (this.state.name && this.state.sport && this.state.start && this.state.end && this.state.ticketLimit && this.state.ticketStart && this.state.matchStart && this.state.matchEnd && this.state.matchAverageDuration && this.state.intervalBetweenPlayerMatches && this.state.courts.length && this.state.end && this.state.type) {
            if (this.state.hasAbsoluteLimit == true && !this.state.absoluteLimit) {
                toast.warn('Informe o número do limite absoluto de inscrições ou desmarque a opção.');
                return;
            }

            if (TournamentHelper.validateCategories(this.state.category)) {

                let data = {
                    name: this.state.name,
                    sport: this.state.sport,
                    price: this.state.rawPrice ? this.state.rawPrice * 100 : '',
                    student_price: this.state.rawStudentPrice ? this.state.rawStudentPrice * 100 : '',
                    start: this.state.start,
                    end: this.state.end,
                    award: this.state.award,
                    banner: this.state.banner && this.state.banner.length ? this.state.banner[0] : '',
                    sponsors: this.state.sponsors,
                    ticket_limit: this.state.ticketLimit,
                    ticket_start: this.state.ticketStart,
                    match_average_duration: this.state.matchAverageDuration,
                    interval_between_player_matches: this.state.intervalBetweenPlayerMatches,
                    match_start: moment(this.state.matchStart).format('HH:mm'),
                    match_end: moment(this.state.matchEnd).format('HH:mm'),
                    category: this.state.category,
                    type: this.state.type,
                    courts: this.state.courts,
                    companyCourts: this.state.companyCourts,
                    participants: [],
                    in_company: this.state.inCompany,
                    multiple_class_enroll: this.state.multipleClassEnroll,
                    internal_enroll_only: this.state.internalEnrollOnly,
                    has_absolute_limit: this.state.hasAbsoluteLimit,
                    absolute_limit: this.state.absoluteLimit,
                    id_company: SessionHelper.getData().id_company,
                    created_at: new Date(),
                    active: true
                };

                if (data.in_company) {

                    data.city = SessionHelper.getData().company.city;
                    data.g = SessionHelper.getData().company.g;
                    data.coordinates = SessionHelper.getData().company.coordinates;

                } else {

                    let coordinates = GeographicHelper.getLatLngFromGeopoint(this.state.coordinates);

                    data.coordinates = this.state.coordinates;
                    data.city = this.state.city;
                    data.g = {
                        geohash: Geohash.encode(coordinates.lat, coordinates.lng),
                        geopoint: this.state.coordinates,
                    };
                }

                try {

                    this.setState({ loadingModal: true });

                    let id = await Firestore.getId('tournament');
                    await Firestore.insert(data, 'tournament', id);

                    toast.success("Cadastrado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    await this.generateBrackets(id);

                    await this.setState({
                        addModal: false,
                        name: '',
                        start: null,
                        end: null,
                        rawPrice: null,
                        price: null,
                        studentPrice: null,
                        rawStudentPrice: null,
                        start: null,
                        ticketStart: null,
                        ticketLimit: null,
                        matchStart: null,
                        matchEnd: null,
                        courts: [],
                        category: TournamentHelper.getDefaultTournamentCategories(),
                        sport: '',
                        banner: null,
                        match_average_duration: '',
                        interval_between_player_matches: '',
                        award: '',
                        type: '',
                        inCompany: true,
                        multipleClassEnroll: false,
                        internalEnrollOnly: false,
                        hasAbsoluteLimit: false,
                        absoluteLimit: ''
                    });
                    await this.getDocs();

                    this.setState({ loadingModal: false });

                } catch (error) {
                    console.log(error)
                    toast.error("Houve um problema ao cadastrar", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    this.setState({ loadingModal: false });
                }
            }

        } else {

            toast.warn("Preencha todos os campos", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    async saveBanner() {

        let actualDate = moment();
        let end = moment(this.state.end.toDate());

        if (!actualDate.isAfter(end)) {

            try {

                this.setState({ loadingModal: true });

                await Firestore.update({ banner: this.state.banner.length ? this.state.banner[0] : '' }, 'tournament', this.state.editId);
                await this.getDocs();

                toast.success('Banner alterado com sucesso');
                this.setState({ loadingModal: false, bannerModal: false, banner: [], editId: null });

            } catch (error) {

                toast.error('Houve um problema ao salvar o banner');
                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn('Esse torneio já foi finalizado');
        }
    }

    async saveSponsors() {

        let actualDate = moment();
        let end = moment(this.state.end.toDate());

        if (!actualDate.isAfter(end)) {

            try {

                this.setState({ loadingModal: true });

                await Firestore.update({ sponsors: this.state.sponsors }, 'tournament', this.state.editId);
                await this.getDocs();

                toast.success('Patrocinadores salvos com sucesso');
                this.setState({ loadingModal: false, sponsorsModal: false, sponsors: [], editId: null });

            } catch (error) {

                toast.error('Houve um problema ao salvar os patrocinadores');
                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn('Esse torneio já foi finalizado');
        }
    }

    getTotalMaxParticipants() {
        if (this.state.hasAbsoluteLimit == true && this.state.absoluteLimit > 0) {
            this.setState({ maxParticipants: this.state.absoluteLimit });
            return;
        }

        let total = 0;

        this.state.category.forEach(category => {
            if (category.checked) {
                category.classes.forEach(cClass => {
                    if (cClass.checked) {
                        if (parseInt(cClass.limit) > 0) total += parseInt(cClass.limit);
                    }
                });
            }
        });

        if (this.state.type == 'pair') total = total * 2;

        this.setState({ maxParticipants: total });
    }

    removeClass(categoryKey, classKey) {
        let category = this.state.category;

        if (category[categoryKey].classes.length < 2) return toast.info(`Categoria deve ter pelo menos uma classe, adicione pelo menos mais uma para poder apagar esta`);

        category[categoryKey].classes.splice(classKey, 1);
        this.handleBracketLimitChange();
        this.setState({ category });

        this.getTotalMaxParticipants();
    }

    addClass(categoryKey, title) {
        if (!title) return toast.warn(`Preencha o nome da classe`);

        let category = this.state.category;
        category[categoryKey].classes.unshift(TournamentHelper.getCustomClass(title));

        this.handleBracketLimitChange();
        this.setState({ category, addingClass: false });

        this.getTotalMaxParticipants();
    }

    autoClasses(categoryKey) {
        let category = this.state.category;
        category[categoryKey].classes = TournamentHelper.getClassicClasses();

        this.handleBracketLimitChange();
        this.setState({ category });

        this.getTotalMaxParticipants();
    }

    handleBracketLimitChange = async () => {
        this.setState({ loadingBracketSizes: true });

        setTimeout(() => {
            this.setState({ loadingBracketSizes: false });
        }, 50);
    }

    renderCategories() {

        return this.state.category.map((category, key) => {

            return (
                <div>
                    <IosSwitch fullWidth label={category.title} value={category.name} checked={category.checked} onChange={(v) => { let category = this.state.category; category[key].checked = v; this.setState({ category }); this.getTotalMaxParticipants(); }} />
                    {category.checked ?

                        <div style={{ marginLeft: 5 }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>

                                <Tooltip title={'Automático'} style={{ alignSelf: 'center', marginLeft: -8, justifyContent: 'center' }}>
                                    <IconButton onClick={() => { this.autoClasses(key) }}>
                                        <FlashOnIcon style={{ color: `rgb(240, 208, 62)`, fontSize: 28 }} />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title={'Adicionar Classe'} style={{ alignSelf: 'center', marginLeft: -8, justifyContent: 'center' }}>
                                    <IconButton onClick={() => { this.setState({ addingClass: true }) }}>
                                        <AddCircleIcon style={{ color: Colors.primary, fontSize: 28 }} />
                                    </IconButton>
                                </Tooltip>

                                <FormLabel style={{ paddingBottom: 18, paddingTop: 18, fontSize: 16, marginLeft: 5 }} component="legend">Classes</FormLabel>
                            </div>

                            {this.state.addingClass ?
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-start', marginBottom: 15, marginTop: 20 }}>
                                    <Tooltip title={'Cancelar'} style={{ alignSelf: 'center', marginRight: -5, justifyContent: 'center' }}>
                                        <IconButton onClick={() => { this.setState({ addClassName: ``, addingClass: false }) }}>
                                            <CancelIcon style={{ color: 'red', fontSize: 28 }} />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title={'Adicionar Classe'} style={{ alignSelf: 'center', marginRight: 10, justifyContent: 'center' }}>
                                        <IconButton onClick={() => { this.addClass(key, this.state.addClassName) }}>
                                            <CheckCircleIcon style={{ color: 'green', fontSize: 28 }} />
                                        </IconButton>
                                    </Tooltip>

                                    <DefaultInput onKeyDown={(evt) => { if (evt.key === "Enter") this.addClass(key, evt.target.value) }} onBlur={(v) => { this.setState({ addClassName: v }) }} label={'Nome da Classe'} />
                                </div>
                                : null
                            }

                            {
                                category.classes.map((categoryClass, classKey) => {
                                    return <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', marginBottom: 15, borderBottom: '1px solid lightgrey', paddingBottom: 20 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', width: '40%' }}>
                                            <Tooltip title={'Remover Classe'} style={{ alignSelf: 'center', marginRight: 15, justifyContent: 'flex-start' }}>
                                                <IconButton onClick={() => { this.removeClass(key, classKey) }}>
                                                    <DeleteIcon style={{ color: Colors.error, fontSize: 28 }} />
                                                </IconButton>
                                            </Tooltip>

                                            <FormLabel style={{ fontSize: 16, textAlign: 'left', alignSelf: 'center', color: `rgba(0, 0, 0, 0.87)` }}>{categoryClass.title}</FormLabel>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', alignContent: 'center', width: '30%' }}>
                                            <HtmlTooltip placement={'bottom'} width={600} title={TournamentHelper.renderBracketDescription(categoryClass.bracket_type)}>
                                                <InfoRoundedIcon style={{ marginRight: 10, fontSize: 28 }} />
                                            </HtmlTooltip>
                                            <DefaultSelect
                                                value={categoryClass.bracket_type}
                                                valueField={'name'}
                                                displayField={'title'}
                                                id={`${categoryClass.title}-${classKey}-select-bracket-type`}
                                                onChange={(v) => {
                                                    let category = this.state.category;
                                                    category[key].classes[classKey].bracket_type = v.target.value;
                                                    category[key].classes[classKey].limit = TournamentHelper.getBracketSizes()[v.target.value][0].value;

                                                    this.setState({ category }); this.getTotalMaxParticipants();

                                                    this.handleBracketLimitChange();
                                                }}
                                                docs={TournamentHelper.getBracketTypes()}
                                                label={`Tipo de Chave`}
                                            />
                                        </div>
                                        {!this.state.loadingBracketSizes ?
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', alignContent: 'center', width: '28%' }}>
                                                <Tooltip title={`Gera ${TournamentHelper.calculateMatchesGeneratedByBracketType(categoryClass.bracket_type, categoryClass.limit)} partidas`}>
                                                    <InfoRoundedIcon style={{ marginRight: 10, fontSize: 28 }} />
                                                </Tooltip>
                                                <DefaultSelect
                                                    value={categoryClass.limit}
                                                    valueField={'value'}
                                                    displayField={'title'}
                                                    id={`${categoryClass.title}-${classKey}-select-bracket-size`}
                                                    onChange={(v) => {
                                                        let category = this.state.category;
                                                        category[key].classes[classKey].limit = v.target.value;
                                                        this.setState({ category }); this.getTotalMaxParticipants();
                                                    }}
                                                    docs={TournamentHelper.getBracketSizes()[categoryClass.bracket_type]}
                                                    label={`Limite de Duplas/Participantes`}
                                                />
                                            </div>
                                            : <DefaultLoader size={28} color={Colors.primary} css={{ marginTop: 2 }} />}
                                    </div>
                                })
                            }

                        </div>

                        : null}
                </div>
            )
        });
    }

    handleMapChange = (geodata) => {
        let state = this.state;

        if (geodata.address) {
            Object.keys(geodata.address).forEach(key => {
                if (key == 'number') {
                    state[key] = geodata.address[key].replace(/\D*/, '');
                    state['complemento'] = geodata.address[key].replace(/\d*/, '');
                } else {
                    state[key] = geodata.address[key];
                }
            });
        }
        if (geodata.coords) {
            state.coordinates = GeographicHelper.setFirebaseGeopoint(geodata.coords.lat, geodata.coords.lng);
        }

        this.setState(state);
    }

    renderLocationPicker() {

        return <LocationPicker callback={this.handleMapChange} initialCenter={GeographicHelper.getLatLngFromGeopoint(this.state.coordinates)} />
    }

    addExternalCourt() {

        if (this.state.addCourtText) {

            let courts = this.state.courts;
            courts.push(this.state.addCourtText);

            this.setState({ courts: courts, addCourt: false, addCourtText: '' });

        } else {

            toast.warn('Preencha o nome da quadra');
        }
    }

    removeExternalCourt(key) {

        let courts = this.state.courts;
        courts.splice(key, 1);

        this.setState({ courts: courts });
    }

    renderExternalCourts() {

        return (

            <div>
                {
                    this.state.courts.map((court, key) => {

                        return (
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid lightgrey', paddingTop: 8, paddingBottom: 8 }}>
                                <div style={{ fontWeight: 'bold', display: 'flex', flexDirection: 'row' }}>
                                    <SportsTennisIcon style={{ marginRight: 8 }} />
                                    {court}
                                </div>
                                <Tooltip title={'Remover Quadra'}>
                                    <IconButton onClick={() => { this.removeExternalCourt(key) }} style={{ boxShadow: Colors.boxShadow }}>
                                        <DeleteIcon style={{ color: Colors.error }} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )
                    })
                }

                {!this.state.courts.length ? <p style={{ color: 'grey' }}>{'Nenhuma quadra cadastrada.'}</p> : null}

                <div style={{ marginTop: 15 }}>
                    {!this.state.addCourt ?

                        <Tooltip title={'Adicionar Quadra'}>
                            <IconButton onClick={() => { this.setState({ addCourt: true }) }} style={{ boxShadow: Colors.boxShadow }}>
                                <AddIcon style={{ color: Colors.primary }} />
                            </IconButton>
                        </Tooltip>

                        :

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <DefaultInput onBlur={(v) => { this.setState({ addCourtText: v }) }} label={'Nome'} />
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 8 }}>
                                <Button onClick={() => { this.addExternalCourt() }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'CONFIRMAR'}</Button>
                                <Button onClick={() => { this.setState({ addCourt: false, addCourtText: '' }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                            </div>
                        </div>

                    }
                </div>
            </div>
        )
    }

    renderBanner(edit = false) {

        return (
            <div>
                {!edit ? <InputLabel>Banner do Torneio</InputLabel> : null}
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                    <ImagePicker id={'banner'} limit={1} imageWidth={550} imageHeight={300} onUploadStart={() => { this.setState({ loadingModal: true }) }} onUploadEnd={() => { this.setState({ loadingModal: false }) }} onUpload={(files) => { this.setState({ banner: files }); }} images={this.state.banner} folder={'tournament_images'} multiple={false} />
                </div>
                <InputLabel style={{ color: 'orange' }}>*Utilize de preferência uma imagem com resolução de 1080x600 pixels.</InputLabel>
                <InputLabel style={{ color: 'orange', marginTop: 15, marginBottom: 25 }}>*Caso não forneça um banner, utilizaremos um banner padrão do Ace.</InputLabel>
            </div>
        )
    }

    renderSponsors(edit = false) {

        return (
            <div>
                {!edit ? <InputLabel>Patrocinadores</InputLabel> : null}
                <div style={{ marginBottom: 30 }}>
                    <ImagePicker id={'sponsors'} imageWidth={200} imageHeight={100} onUploadStart={() => { this.setState({ loadingModal: true }) }} onUploadEnd={() => { this.setState({ loadingModal: false }) }} onUpload={(files) => { this.setState({ sponsors: files }); }} images={this.state.sponsors} folder={'tournament_sponsor_images'} multiple={false} />
                </div>
                <InputLabel style={{ color: 'orange' }}>*Utilize de preferência uma imagem com resolução de 400x200 pixels.</InputLabel>
            </div>
        )
    }

    bannerModal() {

        if (this.state.bannerModal) {

            return (
                <div>
                    {this.renderBanner(true)}
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.saveBanner() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'SALVAR'} />
                        <DefaultButton onClick={() => { this.setState({ bannerModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    printModal() {

        if (this.state.printModal && this.state.printTournament) {

            return (
                <div>
                    <div style={{ marginBottom: 15, marginTop: 15 }}>
                        <InputLabel id="type">Selecione a Categoria</InputLabel>
                        <Select
                            style={{ width: '100%' }}
                            labelId="category"
                            value={this.state.selectedCategory}
                            onChange={(v) => { this.setState({ selectedCategory: v.target.value }) }}>

                            {
                                this.state.printTournament.category.map((category, key) => {

                                    if (category.checked) {

                                        return <MenuItem value={key}>{category.title}</MenuItem>
                                    }
                                })
                            }

                        </Select>

                        {
                            this.state.selectedCategory !== null ?

                                <div style={{ marginTop: 15 }}>
                                    <InputLabel id="type">Selecione a Classe</InputLabel>
                                    <Select
                                        style={{ width: '100%' }}
                                        labelId="category"
                                        value={this.state.selectedClass}
                                        onChange={(v) => { this.setState({ selectedClass: v.target.value }) }}>
                                        {
                                            this.state.printTournament.category[this.state.selectedCategory].classes.map((categoryClass, classKey) => {
                                                if (categoryClass.checked) {
                                                    return <MenuItem disabled={categoryClass.bracket_type !== 'eliminatory'} value={classKey}>{categoryClass.title} {categoryClass.bracket_type !== 'eliminatory' ? '(Somente Chaves Eliminatórias)' : ``}</MenuItem>
                                                }
                                            })
                                        }

                                    </Select>
                                </div>

                                : null
                        }

                    </div>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.printTournament() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'SALVAR'} />
                        <DefaultButton onClick={() => { this.setState({ printModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    sponsorsModal() {

        if (this.state.sponsorsModal) {

            return (
                <div>
                    {this.renderSponsors(true)}
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.saveSponsors() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'SALVAR'} />
                        <DefaultButton onClick={() => { this.setState({ sponsorsModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    handleDateChange(field, value) {

        try {

            let state = this.state;

            if (field) {

                state[field] = value;
                this.setState(state);
            }

        } catch (error) {

            console.log(error);
        }
    }

    addModal() {
        if (this.state.addModal) {
            return (
                <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                    <DefaultInput onBlur={(v) => { this.setState({ name: v }) }} label={'Nome'} />
                    <div style={{ paddingTop: 8, paddingBottom: 10 }}>
                        <DefaultSelect value={this.state.sport} id={'sport_selection'} valueField={'name'} displayField={'title'} onChange={(v) => { this.setState({ sport: v.target.value }) }} docs={this.state.sports} label={'Esporte'} />
                    </div>

                    {TournamentHelper.renderBracketDescription()}

                    {this.renderBanner()}
                    {this.renderSponsors()}

                    <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                        <KeyboardDateTimePicker
                            style={{ width: '100%', marginBottom: 15, paddingRight: 5 }}
                            invalidDateMessage={false}
                            format={'dd/MM/yyyy HH:mm'}
                            autoOk={true}
                            minDate={new Date()}
                            maxDate={moment(this.state.start).subtract(3, 'day')}
                            label="Data de Início das Inscrições"
                            cancelLabel={'Cancelar'}
                            okLabel={'Confirmar'}
                            onChange={(v) => { this.handleDateChange('ticketStart', v) }}
                            value={this.state.ticketStart}
                        />
                    </MuiPickersUtilsProvider>

                    <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                        <KeyboardDateTimePicker
                            style={{ width: '100%', marginBottom: 15, paddingRight: 5 }}
                            invalidDateMessage={false}
                            format={'dd/MM/yyyy HH:mm'}
                            autoOk={true}
                            minDate={this.state.ticketStart}
                            maxDate={this.state.start ? moment(this.state.start).subtract(2, 'day') : moment().add(99, 'year')}
                            label="Data de Finalização das Inscrições"
                            cancelLabel={'Cancelar'}
                            okLabel={'Confirmar'}
                            onChange={(v) => { this.handleDateChange('ticketLimit', v) }}
                            value={this.state.ticketLimit}
                        />
                    </MuiPickersUtilsProvider>

                    <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                        <KeyboardDateTimePicker
                            style={{ width: '100%', marginBottom: 15, paddingRight: 5 }}
                            invalidDateMessage={false}
                            format={'dd/MM/yyyy HH:mm'}
                            autoOk={true}
                            minDate={moment(this.state.ticketLimit).add(2, 'day') || new Date()}
                            label="Data de Início"
                            cancelLabel={'Cancelar'}
                            okLabel={'Confirmar'}
                            onChange={(v) => { this.handleDateChange('start', v) }}
                            value={this.state.start}
                        />
                    </MuiPickersUtilsProvider>

                    <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                        <KeyboardDateTimePicker
                            style={{ width: '100%', marginBottom: 15, paddingRight: 5 }}
                            invalidDateMessage={false}
                            format={'dd/MM/yyyy HH:mm'}
                            autoOk={true}
                            minDate={this.state.start}
                            label="Data de Finalização"
                            cancelLabel={'Cancelar'}
                            okLabel={'Confirmar'}
                            onChange={(v) => { this.handleDateChange('end', v) }}
                            value={this.state.end}
                        />
                    </MuiPickersUtilsProvider>

                    <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                        <KeyboardTimePicker
                            style={{ width: '100%', paddingRight: 5 }}
                            invalidDateMessage={false}
                            format={'HH:mm'}
                            autoOk={true}
                            label="Horário de Início das Partidas"
                            cancelLabel={'Cancelar'}
                            okLabel={'Confirmar'}
                            onChange={(v) => { this.handleDateChange('matchStart', v) }}
                            value={this.state.matchStart}
                        />
                    </MuiPickersUtilsProvider>
                    <FormLabel style={{ paddingBottom: 15, paddingTop: 5, fontSize: 13 }} component="legend">Exemplo: Diáriamente, durante o torneio, a primeira partida pode começar 08:00 da manhã.</FormLabel>

                    <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                        <KeyboardTimePicker
                            style={{ width: '100%', paddingRight: 5 }}
                            invalidDateMessage={false}
                            format={'HH:mm'}
                            autoOk={true}
                            label="Horário Final das Partidas"
                            cancelLabel={'Cancelar'}
                            okLabel={'Confirmar'}
                            onChange={(v) => { this.handleDateChange('matchEnd', v) }}
                            value={this.state.matchEnd}
                        />
                    </MuiPickersUtilsProvider>
                    <FormLabel style={{ paddingBottom: 15, paddingTop: 5, fontSize: 13 }} component="legend">Exemplo: Diáriamente, durante o torneio, a última partida pode começar no máximo 21:00 da noite.</FormLabel>

                    <InputMask onBlur={(v) => { this.setState({ matchAverageDuration: v }) }} mask="999" maskPlaceholder={''} alwaysShowMask={false}>
                        <DefaultInput required number label={'Duração Média de Partidas (Minutos)'} />
                    </InputMask>
                    <FormLabel style={{ paddingBottom: 15, paddingTop: 0, fontSize: 13 }} component="legend">Exemplo: Eu estimo que minhas partidas durem em torno de 90 minutos na média, o programa usará esse dado para gerar os horários das chaves de acordo com sua estimativa.</FormLabel>

                    <InputMask onBlur={(v) => { this.setState({ intervalBetweenPlayerMatches: v }) }} mask="999" maskPlaceholder={''} alwaysShowMask={false}>
                        <DefaultInput required number label={'Intervalo Mínimo Entre Jogos de um Jogador (Minutos)'} />
                    </InputMask>
                    <FormLabel style={{ paddingBottom: 15, paddingTop: 0, fontSize: 13 }} component="legend">Exemplo: Um jogador ganha uma partida, sua próxima partida deverá ter um intervalo mínimo de 60 minutos, para o jogador ter tempo de descansar.</FormLabel>

                    <IosSwitch fullWidth label={'Usar quadras da academia'} value={'company_courts'} checked={this.state.companyCourts} onChange={(v) => { this.setState({ companyCourts: v, courts: [] }) }} />

                    <div style={{ marginTop: 15, marginBottom: 15 }}>
                        {this.state.companyCourts ? <DefaultSelect search={true} multiple={true} searchField={'name'} id={'court_selection'} valueField={'name'} displayField={'name'} secondaryDisplay={'sport_label'} value={this.state.courts} onChange={(v) => { this.setState({ courts: v.target.value }) }} docs={this.state.courtDocs} label={'Quadras'} /> : this.renderExternalCourts()}
                    </div>

                    <CurrencyInput label={'Valor de Inscrição'} onChange={(values) => { const { formattedValue, value } = values; this.setState({ price: formattedValue, rawPrice: value }) }} value={this.state.price} />
                    <LiquidProfit price={this.state.rawPrice * 100} />
                    <LiquidProfit price={this.state.rawPrice * 100} type={'boleto'} />
                    <LiquidProfit price={this.state.rawPrice * 100} type={'pix'} />

                    <CurrencyInput label={'Valor de Inscrição para Alunos'} onChange={(values) => { const { formattedValue, value } = values; this.setState({ studentPrice: formattedValue, rawStudentPrice: value }) }} value={this.state.studentPrice} />
                    <LiquidProfit price={this.state.rawStudentPrice * 100} />
                    <LiquidProfit price={this.state.rawStudentPrice * 100} type={'boleto'} />
                    <LiquidProfit price={this.state.rawStudentPrice * 100} type={'pix'} />

                    <FormLabel style={{ paddingBottom: 18, paddingTop: 18, fontSize: 13 }} component="legend">Premiação</FormLabel>
                    <TextareaAutosize style={{ width: '100%', borderRadius: 5, borderColor: 'lightgrey', padding: 15, fontSize: '1rem' }} rowsMax={8} rowsMin={8} onBlur={(v) => { this.setState({ award: v.target.value }) }} placeholder="Escreva a premiação do torneio..." />

                    <div style={{ marginBottom: 15, marginTop: 15 }}>
                        <InputLabel id="type">Tipo</InputLabel>
                        <Select
                            style={{ width: '100%' }}
                            labelId="tipo"
                            value={this.state.type}
                            onChange={(v) => { this.setState({ type: v.target.value }) }}>

                            <MenuItem value={'simple'}>{'Simples'}</MenuItem>
                            <MenuItem value={'pair'}>{'Duplas'}</MenuItem>

                        </Select>
                    </div>

                    <FormLabel style={{ paddingBottom: 3, paddingTop: 18 }} component="legend">Categorias</FormLabel>

                    {this.renderCategories()}

                    <FormLabel style={{ paddingBottom: 15, paddingTop: 15, fontSize: 13, fontWeight: 'bold', color: 'orange' }} component="legend">Máximo de participantes total: {this.state.maxParticipants}</FormLabel>

                    <MaximumMatchesInTournament
                        matchStart={this.state.matchStart}
                        matchEnd={this.state.matchEnd}
                        matchAverageDuration={this.state.matchAverageDuration}
                        tournamentStart={this.state.start}
                        tournamentEnd={this.state.end}
                        numberOfCourts={this.state.courts.length}
                        numberOfParticipants={this.state.maxParticipants}
                        category={this.state.category}
                    />

                    <IosSwitch fullWidth label={'Definir limite absoluto de inscritos'} value={'has_absolute_limit'} checked={this.state.hasAbsoluteLimit} onChange={async (v) => {
                        await this.setState({ hasAbsoluteLimit: v });
                        if (!this.state.absoluteLimit) this.getTotalMaxParticipants();
                    }} />
                    {this.state.hasAbsoluteLimit ? this.renderAbsoluteLimitInput() : null}

                    <IosSwitch fullWidth label={'Torneio Interno (Apenas alunos vinculados podem inscrever-se)'} value={'internal_enroll_only'} checked={this.state.internalEnrollOnly} onChange={(v) => { this.setState({ internalEnrollOnly: v }) }} />

                    <IosSwitch fullWidth label={'Torneio localizado na academia'} value={'in_company'} checked={this.state.inCompany} onChange={(v) => { this.setState({ inCompany: v }) }} />
                    {!this.state.inCompany ? this.renderLocationPicker() : null}

                    <IosSwitch fullWidth label={'Permitir inscrições em múltiplas classes'} value={'multiple_class_enroll'} checked={this.state.multipleClassEnroll} onChange={(v) => { this.setState({ multipleClassEnroll: v }) }} />

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.addTournament() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'SALVAR'} />
                        <DefaultButton onClick={() => { this.setState({ addModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    renderAbsoluteLimitInput() {
        return (
            <InputMask onBlur={async (v) => {
                await this.setState({ absoluteLimit: v });
                this.getTotalMaxParticipants();
            }} mask="9999" maskPlaceholder={''} alwaysShowMask={false}>
                <DefaultInput required number label={'Número máximo (total) de inscrições'} />
            </InputMask>
        );
    }

    async removeParticipant(user) {

        if (moment().isAfter(this.state.start.toDate())) {

            toast.warn('Não é possível remover participantes após o início do torneio');

        } else if (!this.state.open) {

            toast.warn('Não é possível remover participantes após o fim do torneio');

        } else {

            let confirm = window.confirm('Tem certeza que deseja remover?');

            if (confirm) {

                let participants = this.state.participants;
                let index = participants.findIndex(item => item.id_user === user);

                if (participants[index]) {

                    participants.splice(index, 1);
                    this.setState({ participants: participants });

                    let ids = [];

                    participants.forEach((participant, key) => {
                        ids.push(participant.id_user);
                    });

                    Firestore.update({ participants: participants, id_participants: ids }, 'tournament', this.state.editId);
                    toast.success('Participante removido com sucesso');
                }
            }
        }
    }

    handleParticipantSearch = (result, categoryKey, classKey) => {
        let searchIndexedParticipants = [];

        if (result && result.length > 0) {
            result.forEach(player => {
                searchIndexedParticipants.push(player.id);
            });
        }

        this.setState({ searchIndexedParticipants, emptySearch: searchIndexedParticipants.length < 1, categoryKey, classKey });
    }

    participantsModal() {
        if (this.state.participantsModal) {
            let participantsNumber = TournamentHelper.getTotalTournamentParticipants(this.state.tournament);

            return (
                <div>
                    <div style={{ color: Colors.primary, display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 40, fontWeight: 'bold' }}>
                        <GroupIcon style={{ fontSize: 30, marginRight: 8 }} />
                        <div style={{ fontSize: 22 }}>
                            {`Total de inscritos: ${participantsNumber}${this.state.tournament.type === 'pair' && participantsNumber > 0 ? ` (${participantsNumber / 2} duplas)` : ``}`}
                        </div>
                    </div>

                    <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 30, fontWeight: 'bold' }}>
                        <InfoRoundedIcon style={{ fontSize: 25, marginRight: 8 }} />
                        <div>
                            {`Para cancelar a inscrição de um jogador, acesse a página de pedidos e faça o cancelamento.`}
                        </div>
                    </div>
                    {this.state.category.map((category, key) => {

                        return (
                            <div style={{ marginTop: 20 }}>
                                {category.checked ?

                                    <div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <h2>{`${category.title}`}</h2>
                                        </div>

                                        {
                                            category.classes.map((categoryClass, classKey) => {

                                                if (categoryClass.checked) {
                                                    return (
                                                        <div style={{ marginTop: 20 }}>
                                                            <FormLabel style={{ fontSize: 18 }}>{`${categoryClass.title} (${categoryClass.participants.length} inscrições)`}</FormLabel>
                                                            <div style={{ marginBottom: 10 }} />
                                                            {this.state.studentDocs.length > 0 && categoryClass.participants.length > 0 ? <SearchBar docs={this.state.studentDocs} search={'name'} onEmpty={(result) => { this.setState({ searchIndexedParticipants: [], emptySearch: true, categoryKey: null, classKey: null }) }} onSearch={(result) => { this.handleParticipantSearch(result, key, classKey) }} placeholder={'Busque por participantes...'} /> : null}

                                                            <div style={{ overflowX: 'scroll', display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                                                                {this.state.studentDocs.length ?
                                                                    categoryClass.participants.map((participant, participantKey) => {
                                                                        let canRender = true;

                                                                        if (!this.state.emptySearch
                                                                            && this.state.searchIndexedParticipants.length > 0
                                                                            && this.state.categoryKey === key
                                                                            && this.state.classKey === classKey) {
                                                                            if (!ArrayHelper.findCommonElementsInArrays(participant.players, this.state.searchIndexedParticipants)) {
                                                                                canRender = false;
                                                                            }
                                                                        }

                                                                        return canRender ? (
                                                                            <div style={{ display: 'flex', flexDirection: 'row', boxShadow: Colors.boxShadow, borderRadius: 5, border: `3px solid ${Colors.primary}`, marginRight: 15, }}>
                                                                                {
                                                                                    participant.players.map((player, playerKey) => {

                                                                                        let user = this.state.studentDocs.find(item => item.id === player);

                                                                                        if (user) {
                                                                                            let order = this.state.orderDocs.find(item => item.id === participant.id_order);
                                                                                            let isStudent = user.id_companies && user.id_companies.length > 0 && user.id_companies.includes(SessionHelper.getData().id_company);
                                                                                            let paid = order && (order.status === 'paid' || !order.status) && !order.refunded && !order.canceled;

                                                                                            return (
                                                                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: 200, borderLeft: playerKey === 1 ? `3px solid ${Colors.primary}` : '', paddingBottom: 20 }}>
                                                                                                    <img style={{ height: 90, width: 90, borderRadius: 90 / 2, boxShadow: Colors.boxShadow, marginBottom: 20, marginTop: 20 }} src={user.photo ? user.photo : process.env.PUBLIC_URL + '/empty_avatar.png'} />

                                                                                                    <Tooltip title={user.name}>
                                                                                                        <div style={{ marginTop: 8, display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: 12 }}>
                                                                                                            <PersonIcon style={{ marginRight: 5, color: Colors.primary }} />
                                                                                                            {user.name.length > 15 ? user.name.slice(0, 15) + '...' : user.name}
                                                                                                        </div>
                                                                                                    </Tooltip>
                                                                                                    <div style={{ marginTop: 8, display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: 12 }}>
                                                                                                        <ReceiptIcon style={{ marginRight: 5, color: Colors.primary }} />
                                                                                                        {order ? <div style={{ cursor: 'pointer' }} onClick={(e) => { navigator.clipboard.writeText(order.title); toast.info('Nº do pedido copiado!'); e.stopPropagation(); }}>{order.title} <FileCopy style={{ fontSize: 12, marginLeft: 3, marginTop: 1 }} /> </div> : 'Sem pedido'}
                                                                                                    </div>

                                                                                                    <div style={{ marginTop: 8, display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: 12 }}>
                                                                                                        <EventIcon style={{ marginRight: 5, color: Colors.primary }} />
                                                                                                        {moment(participant.date.toDate()).format('DD/MM/YYYY HH:mm')}
                                                                                                    </div>
                                                                                                    <div style={{ marginTop: 8, display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: 12 }}>
                                                                                                        <div style={{ backgroundColor: isStudent ? Colors.primary : Colors.contrast, paddingTop: 2, paddingBottom: 2, paddingLeft: 10, paddingRight: 10, color: 'white', fontWeight: 'bold', marginLeft: 8, borderRadius: 50 }}>{isStudent ? `` : `Não `}{TermHelper.getestablishmentTypeLabel(SessionHelper.getData().company.establishment_type).singular}</div>
                                                                                                    </div>
                                                                                                    {order ? <div style={{ marginTop: 8, display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: 12, marginBottom: 8 }}>
                                                                                                        <div style={{ backgroundColor: paid ? Colors.success : Colors.danger, paddingTop: 2, paddingBottom: 2, paddingLeft: 10, paddingRight: 10, color: 'white', fontWeight: 'bold', marginLeft: 8, borderRadius: 50 }}>{paid ? `Pago` : `Aguardando Pagamento`}</div>
                                                                                                    </div> : null}
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        ) : null
                                                                    })

                                                                    : null}
                                                            </div>

                                                            {!categoryClass.participants.length ? <p style={{ color: 'grey', minHeight: 50, paddingTop: 15 }}>{'Nenhum jogador inscrito.'}</p> : null}
                                                        </div>
                                                    )
                                                }
                                            })
                                        }

                                    </div>

                                    : null}
                            </div>
                        )
                    })}

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.setState({ participantsModal: false }) }} color={Colors.primary} width={'100%'} title={'FECHAR'} />
                    </div>
                </div>
            )
        }
    }

    renderCourts(data) {

        let text = '';

        if (data && data.length) {

            data.forEach((court, key) => {

                text += court;

                if (data[key + 1]) {
                    text += ', ';
                }
            });
        }

        return text;
    }

    async printTournament() {

        if (this.state.selectedCategory >= 0 && this.state.selectedClass >= 0) {

            let rounds = this.state.printTournament.category[this.state.selectedCategory].classes[this.state.selectedClass].rounds;

            await this.setState({ loadingModal: true });
            await this.getStudents(TournamentHelper.getTournamentParticipants(this.state.printTournament));
            await this.setState({ print: true, printRounds: rounds });

            setTimeout(async () => {

                let tournament = document.getElementById('print-tournament');
                tournament.style.display = 'block';

                if (tournament) {

                    try {

                        let png = await domtoimage.toPng(tournament, { bgcolor: '#fff' })

                        tournament.style.display = 'none';

                        var link = document.createElement('a');
                        link.download = `${this.state.printTournament.name}.png`;
                        link.href = png;
                        link.click();

                        this.setState({ print: false, loadingModal: false, printTournament: null, selectedCategory: null, selectedClass: null, printRounds: [], printModal: false, studentDocs: [] });

                    } catch (error) {

                        this.setState({ print: false, loadingModal: false, });
                    }
                }

            }, 2000);

        } else {

            toast.warn('Preencha todos os campos');
        }
    }

    renderCategory(category) {

        let text = '';

        category.forEach((item, key) => {

            text += item;

            if (category[key + 1]) {
                text += ', ';
            }
        });

        return text;
    }

    getSportLookup() {
        return {
            'tennis': 'Tennis',
            'padel': 'Padel',
            'beach_tennis': 'Beach Tennis',
            'squash': 'Squash',
            'badminton': 'Badminton'
        }
    }

    renderCategoryRow(categories) {

        let text = '';

        categories.forEach((category, key) => {

            if (category.checked) {

                text += category.title;
                text += ' (';

                category.classes.forEach((categoryClass, categoryKey) => {

                    if (categoryClass.checked) {
                        text += `${categoryClass.title}${category.classes[categoryKey + 1] && category.classes[categoryKey + 1].checked ? ', ' : ''}`;
                    }
                });

                text += `)${categories[key + 1] && categories[key + 1].checked ? ', ' : ''}`;
            }
        });

        return text;
    }

    renderStatus(rowData) {

        let text = '';
        let color = '';

        let start = moment(rowData.start.toDate());
        let end = moment(rowData.end.toDate());

        let actualDate = moment();

        if (actualDate.isBetween(start, end)) {

            text = 'Em Andamento';
            color = 'orange';

        } else if (actualDate.isBefore(start)) {

            text = `Começa ${start.calendar().toLowerCase()}`;
            color = 'orange';

        } else if (actualDate.isAfter(end)) {

            text = 'Finalizado';
            color = 'green';
        }

        return <div style={{ fontWeight: 'bold', color: color }}>{text}</div>
    }

    renderMapLink(coordinates) {

        let parsedCoordinates = coordinates.toJSON();

        return (
            <div onClick={() => { window.open(`http://maps.google.com/?q=${parsedCoordinates.latitude},${parsedCoordinates.longitude}`, '_blank') }} style={{ color: 'white', backgroundColor: Colors.primary, paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 15, borderRadius: 25, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <RoomIcon style={{ marginRight: 5 }} />
                {`${parsedCoordinates.latitude}, ${parsedCoordinates.longitude}`}
            </div>
        )
    }

    back() {

        this.setState({ editing: false, editId: null });
    }

    async openParticipantsModal(tournament) {

        this.setState({ participantsModal: true, studentDocs: [], loadingModal: true, category: tournament.category, tournament: tournament });

        await this.getOrders(tournament.id);
        await this.getStudents(TournamentHelper.getTournamentParticipants(tournament));

        this.setState({ loadingModal: false });
    }

    async handleParticipantsModal(rowData) {

        this.setState({ participantsModal: true, studentDocs: [], loadingModal: true, category: rowData.category, tournament: rowData });

        await this.getOrders(rowData.id);
        await this.getStudents(TournamentHelper.getTournamentParticipants(rowData));

        this.setState({ loadingModal: false });
    }

    render() {
        // if(!SessionHelper.isDevelopmentMode()) return <UnderConstruction title={`Torneios em construção`}/>;

        if (this.state.editing) {

            return (
                <div>
                    <TournamentView back={() => { this.back() }} openParticipantsModal={(tournament) => { this.openParticipantsModal(tournament) }} tournament={this.state.editId} generateBrackets={this.generateBrackets} />
                    <DefaultModal width={window.screen.width - (window.screen.width * 0.15)} loading={this.state.loadingModal} content={this.participantsModal()} title={''} onClose={() => { this.setState({ participantsModal: false }) }} open={this.state.participantsModal} />
                </div>
            )
        }

        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <HeaderActionsWrapper style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon />} onClick={() => {
                            this.setState({
                                addModal: true,
                                name: '',
                                start: null,
                                end: null,
                                rawPrice: null,
                                price: null,
                                studentPrice: null,
                                rawStudentPrice: null,
                                ticketStart: null,
                                ticketLimit: null,
                                matchStart: null,
                                matchEnd: null,
                                courts: [],
                                category: TournamentHelper.getDefaultTournamentCategories(),
                                sport: '',
                                banner: null,
                                sponsors: [],
                                match_average_duration: '',
                                interval_between_player_matches: '',
                                award: '',
                                type: '',
                                maxParticipants: 0,
                                inCompany: true,
                                multipleClassEnroll: false,
                                internalEnrollOnly: false,
                                hasAbsoluteLimit: false,
                                absoluteLimit: ''
                            })
                        }} title={'Adicionar'} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>

                    </div>
                </HeaderActionsWrapper>

                <DefaultTable
                    title={'Torneios'}

                    marginTop={10}
                    actions={[
                        {
                            icon: AccountTreeIcon,
                            tooltip: 'Acessar Torneio',
                            onClick: (event, rowData) => { this.setState({ editId: rowData.id, editing: true }) }
                        },
                        {
                            icon: GroupIcon,
                            tooltip: 'Inscrições',
                            onClick: (event, rowData) => { this.handleParticipantsModal(rowData) }
                        },
                        {
                            icon: WorkIcon,
                            tooltip: 'Patrocinadores',
                            onClick: (event, rowData) => { this.setState({ sponsorsModal: true, end: rowData.end, editId: rowData.id, sponsors: rowData.sponsors }) }
                        },
                        {
                            icon: ImageIcon,
                            tooltip: 'Banner',
                            onClick: (event, rowData) => { this.setState({ bannerModal: true, end: rowData.end, editId: rowData.id, banner: rowData.banner ? [rowData.banner] : [] }) }
                        },
                        {
                            icon: PrintIcon,
                            tooltip: 'Imprimir',
                            onClick: (event, rowData) => {
                                this.setState({ printModal: true, printTournament: rowData, })
                            }
                        },
                    ]}
                    filtering={true}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Status', field: 'status', render: rowData => this.renderStatus(rowData), editable: false },
                        { title: 'Nome', field: 'name' },
                        { title: 'Esporte', field: 'sport', lookup: this.getSportLookup(), editable: false },
                        { title: 'Categorias', field: 'category', render: rowData => this.renderCategoryRow(rowData.category), editable: false },
                        { title: 'Local', field: 'coordinates', render: rowData => this.renderMapLink(rowData.coordinates), editable: false },
                        { title: 'Torneio Interno', field: 'internal_enroll_only', type: 'boolean', editable: false, render: rowData => <IosSwitch disabled={true} checked={rowData.internal_enroll_only} />, editComponent: props => (<IosSwitch checked={props.value} />) },
                        { title: 'Data de Início', field: 'start', editable: false, render: rowData => <div>{moment(rowData.start.toDate()).format('DD/MM/YYYY HH:mm')}</div> },
                        { title: 'Data de Finalização', field: 'end', editable: false, render: rowData => <div>{moment(rowData.end.toDate()).format('DD/MM/YYYY HH:mm')}</div> },
                        { title: 'Data de Início das Inscrições', field: 'ticket_start', editable: false, render: rowData => <div>{moment(rowData.ticket_start.toDate()).format('DD/MM/YYYY HH:mm')}</div> },
                        { title: 'Data de Finalização das Inscrições', field: 'ticket_limit', editable: false, render: rowData => <div>{moment(rowData.ticket_limit.toDate()).format('DD/MM/YYYY HH:mm')}</div> },
                        { title: 'Horário de Início das Partidas', field: 'match_start', editable: false },
                        { title: 'Horário Final das Partidas', field: 'match_end', editable: false },
                        { title: 'Duração Média de Partidas', field: 'match_average_duration', editable: false, render: rowData => <div>{`${rowData.match_average_duration} minutos`}</div> },
                        { title: 'Intervalo Mínimo Entre Jogos de um Jogador', field: 'interval_between_player_matches', editable: false, render: rowData => <div>{`${rowData.interval_between_player_matches} minutos`}</div> },
                        { title: 'Quadras', field: 'courts', render: rowData => <div>{this.renderCourts(rowData.courts)}</div>, editable: false },
                        { title: 'Valor de Inscrição', field: 'price', type: 'currency', editable: false, currencySetting: { locale: 'brl', currencyCode: 'brl' }, editComponent: props => (<CurrencyInput value={props.value} onChange={(e) => { props.onChange(e.floatValue) }} />) },
                        { title: 'Valor de Inscrição para Alunos', field: 'student_price', editable: false, type: 'currency', currencySetting: { locale: 'brl', currencyCode: 'brl' }, editComponent: props => (<CurrencyInput value={props.value} onChange={(e) => { props.onChange(e.floatValue) }} />) },
                        { title: 'Premiação', field: 'award', editable: false },
                        { title: 'Tipo', field: 'type', editable: false, render: rowData => <div>{rowData.type === 'simple' ? 'Simples' : 'Dupla'}</div> },
                        { title: 'Permitir inscrições em múltiplas classes', field: 'multiple_class_enroll', type: 'boolean', editable: false, render: rowData => <IosSwitch disabled={true} checked={rowData.multiple_class_enroll} />, editComponent: props => (<IosSwitch checked={props.value} />) },
                        { title: 'Limite máximo de inscrições', field: 'absolute_limit', editable: false, render: rowData => <div>{rowData.absolute_limit ? `${rowData.absolute_limit} inscrições` : `Limite definido pelas chaves`}</div> },
                        { title: 'Data Criação', field: 'created_at', editable: false, render: rowData => <div>{moment(rowData.created_at.toDate()).format('DD/MM/YYYY HH:mm')}</div> },
                    ]}
                    data={this.state.docs}
                    onRowDelete={async (oldData) => {
                        if(this.state.deleting) {
                            toast.info('Apagando... Aguarde.');
                            return;
                        }

                        this.setState({ deleting: true });

                        await this.deleteTournament(oldData.id, (success) => {
                            this.setState({ deleting: false });
                            
                            if(success) {
                                let prev = this.state.docs;
                                prev.splice(prev.indexOf(oldData), 1);
                                this.setState({ docs: prev });
                                return prev;
                            }
                        });
                    }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} width={window.screen.width - (window.screen.width * 0.15)} title={'Novo Torneio'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.participantsModal()} width={window.screen.width - (window.screen.width * 0.15)} title={''} onClose={() => { this.setState({ participantsModal: false }) }} open={this.state.participantsModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.bannerModal()} title={'Banner'} onClose={() => { this.setState({ bannerModal: false }) }} open={this.state.bannerModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.sponsorsModal()} title={'Patrocinadores'} onClose={() => { this.setState({ sponsorsModal: false }) }} open={this.state.sponsorsModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.printModal()} title={'Imprimir'} onClose={() => { this.setState({ printModal: false }) }} open={this.state.printModal} />

                {this.state.print ?

                    <div style={{ display: 'none' }} id={'print-tournament'}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 25, borderBottom: '1px solid lightgrey', minWidth: 350 * 4, width: 340 * this.state.printRounds.length, backgroundColor: Colors.primary, color: 'white' }}>
                            <img style={{ width: 150, height: 50, alignSelf: 'flex-end' }} src={process.env.PUBLIC_URL + '/bg-wide-white.png'} />
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div style={{ fontWeight: 'bold', fontSize: 22 }}>{this.state.printTournament.name} - Categoria {this.state.printTournament.category[this.state.selectedCategory].title} - {this.state.printTournament.category[this.state.selectedCategory].classes[this.state.selectedClass].title}</div>
                                    <EmojiEvents style={{ color: 'orange', marginLeft: 5, fontSize: 30 }} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', marginTop: 20, marginLeft: 50 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ fontWeight: 'bold', fontSize: 16 }}>Academia</div>
                                        <div style={{ fontSize: 16 }}>{SessionHelper.getData().company.name}</div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ fontWeight: 'bold', fontSize: 16 }}>Data</div>
                                        <div style={{ fontSize: 16 }}>{`${moment(this.state.printTournament.start.toDate()).format('MM/YYYY HH:mm')} até ${moment(this.state.printTournament.end.toDate()).format('MM/YYYY HH:mm')}`}</div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ fontWeight: 'bold', fontSize: 16 }}>Inscritos</div>
                                        <div style={{ fontSize: 16 }}>{`${this.state.printTournament.category[this.state.selectedCategory].classes[this.state.selectedClass].participants.length} jogadores`}</div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ fontWeight: 'bold', fontSize: 16 }}>Impressão</div>
                                        <div style={{ fontSize: 16 }}>{`${moment().format('MM/YY HH:mm')}`}</div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div style={{ fontSize: 22 }}>aceapp.com.br</div>
                            </div>
                        </div>
                        <div style={{ padding: 50 }}>
                            <TournamentKeys print={true} indexes={{ category: this.state.selectedCategory, class: this.state.selectedClass }} users={this.state.studentDocs} tournament={this.state.printTournament} rounds={this.state.printRounds} />
                            {/* <TournamentKeys print={true} users={this.state.studentDocs} tournament={this.state.printTournament} rounds={this.state.printRounds} /> */}
                        </div>
                    </div>

                    : null}

            </div >
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
