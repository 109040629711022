import React from 'react';
import Colors from '../../constants/Colors';
import Firestore from '../../api/firebase/Firestore';
import ClipLoader from "react-spinners/ClipLoader";

export default class RelationField extends React.Component {

    state = {
        loading: true,
        name: null,
    }

    async componentDidMount() {

        await this.getUser();

        this.setState({ loading: false })
    }

    async componentDidUpdate(prev) {

        if (this.props.id !== prev.id) {

            await this.setState({ loading: true });
            await this.getUser();
    
            this.setState({ loading: false });
        }
    }

    async getUser() {

        if (this.props.id) {

            let query = await Firestore.getDoc(this.props.collection, this.props.id);

            if (query.exists) {
    
                let doc = query.data();
    
                this.setState({ name: doc[this.props.field] ? doc[this.props.field] : 'Não Encontrado' });
    
            } else {
    
                this.setState({ name: 'Não Encontrado' });
            }
        
        } else {

            this.setState({ name: 'Não Encontrado' });
        }
    }

    renderLoading() {

        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ClipLoader
                size={25}
                color={Colors.primary}
                loading={true}
                />
            </div>
        )
    }

    render() {

        return this.state.loading && this.props.loading !== false ? this.renderLoading() : (

            <div style={{ }}>
                { this.props.render ? this.props.render(this.state.name) : this.state.name }
            </div>
        );
    }
}