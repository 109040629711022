import React, { Component } from 'react';
import IosSwitch from '../IosSwitch';
import DefaultButton from '../DefaultButton';
import DefaultLoader from '../DefaultLoader';
import SessionHelper from '../../helper/SessionHelper';
import ReportHelper from '../../helper/ReportHelper';
import moment from "moment-timezone";
import Firestore from '../../api/firebase/Firestore';
import { toast } from 'react-toastify';
import { FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import Colors from '../../constants/Colors';
import CurrencyHelper from '../../helper/CurrencyHelper';
import ReportDefaultModalOptions from './ReportDefaultModalOptions';

export default class SalesByProductReport extends Component {

    state = {
        loadingModal: true,
        serviceDocs: [],
        productDocs: [],
        salesUserDocs: [],
        locationDocs: [],
        services: [],
        products: [],
        saleUsers: [],
        locations: [],
        allSalesUsers: true,
        allServices: true,
        allProducts: true,
        allLocations: true,
        start: moment().startOf('month').startOf('day').toDate(),
        end: moment().endOf('day').toDate(),
        salesByProductOrder: 'name',
        output: 'download',
        format: 'pdf',
    }

    async componentDidMount() {

        if (this.props.getDocs && this.props.getDocs) {

            for (let index = 0; index < this.props.getDocs.length; index++) {
                
                const getDoc = this.props.getDocs[index];
                await getDoc(this);
            }
        }

        this.setState({ loadingModal: false });
    }

    async getReportDocs() {

        let returnDocs = [];

        let start = moment(this.state.start).startOf('day').toDate();
        let end = moment(this.state.end).endOf('day').toDate();
        let orders = await Firestore.customQuery('order').where('id_company', '==', SessionHelper.getData().id_company).where('date', '>=', start).where('date', '<=', end).where('type', 'in', ['product', 'ticket']).orderBy('date', 'desc').get();

        let products = this.state.allProducts ? this.state.productDocs.map((product) => { return product.id }) : this.state.products;
        let services = this.state.allServices ? this.state.serviceDocs.map((service) => { return service.id }) : this.state.services;
        let saleUsers = this.state.allSalesUsers ? this.state.salesUserDocs.map((user) => { return user.id }) : this.state.saleUsers;
        let locations = this.state.allLocations ? this.state.locationDocs.map((location) => { return location.id }) : this.state.locations;

        let items = [...products, ...services];
        let docs = [...this.state.productDocs, ...this.state.serviceDocs];
        let count = {};

        orders.forEach((orderDoc, key) => {

            let order = { ...orderDoc.data(), id: orderDoc.id };

            if (this.state.allSalesUsers || saleUsers.includes(order.id_user)) {

                let orderItems = order.items;

                if (orderItems && orderItems.length) {

                    orderItems.forEach((item, key) => {
        
                        if (item.id && items.findIndex(product => product === item.id) >= 0) {

                            let doc = docs.find(doc => doc.id === item.id);

                            if (doc && this.state.allLocations || (doc.id_location && !this.state.allLocations && locations.includes(doc.id_location))) {

                                if (!count[doc.id]) { count[doc.id] = { count: 0, total: 0, total_discount: 0, name: doc.name, cost: doc.cost, price: doc.price } };

                                count[doc.id].count += parseInt(item.quantity);
                                count[doc.id].total += parseInt(item.unit_price * item.quantity);
                                count[doc.id].total_discount += parseInt(order.discount > 0 ? order.discount : 0);
                            }
                        }
                    });
                }
            }
        });

        let keys = Object.keys(count);
        
        keys.sort((a, b) => {

            let aItem = count[a];
            let bItem = count[b];

            if (this.state.salesByProductOrder === 'name') {

                return aItem.name > bItem.name;

            } else if (this.state.salesByProductOrder === 'quantity') {

                return aItem.count < bItem.count;
            }
        });

        let total = 0;
        let totalAmount = 0;
        let totalDiscount = 0;

        keys.forEach((item, key) => {

            let totalizer = count[item];
            let doc = docs.find(arrayItem => arrayItem.id === item);
            
            returnDocs.push({
                product: doc,
                count: totalizer.count,
                total_amount: totalizer.total,
                total_discount: totalizer.total_discount,
            });

            total += totalizer.count;
            totalAmount += totalizer.total;
            totalDiscount += totalizer.total_discount;
        });

        return { docs: returnDocs, totalizer: [{ count: total, total_amount: totalAmount, total_discount: totalDiscount }] }
    }

    async print() {

        if (this.state.start && this.state.end) {

            this.setState({ loadingModal: true });

            let name = `Vendas por Produto/Serviço - ${SessionHelper.getData().company.name}}`;
            let subtitle = `De ${moment(this.state.start).format('DD/MM/YYYY')} até ${moment(this.state.end).format('DD/MM/YYYY')}`;
            let docs = await this.getReportDocs();
            let report = await ReportHelper.createReport(name, 5, subtitle, this.state.format);

            report = await ReportHelper.createColumns(report, docs.docs, [
                { name: 'Produto', width: 13, getText: (doc) => this.state.format === 'pdf' ? ReportHelper.cutName(doc.product.name, 55) : doc.product.name },
                { name: 'Quantidade', width: 125, getText: (doc) => doc.count.toString() },
                { name: 'Valor Unitário', width: 150, getText: (doc) => CurrencyHelper.centsToMoney(doc.product.price) },
                { name: 'Valor Bruto', width: 178, getText: (doc) => CurrencyHelper.centsToMoney(doc.total_amount) },
            ]);
    
            report = await ReportHelper.createColumns(report, docs.totalizer, [
                { name: 'Total', width: 125, getText: (doc) => doc.count.toString() },
                { name: 'Total Bruto', width: 150, getText: (doc) => CurrencyHelper.centsToMoney(doc.total_amount)},
                { name: 'Total Líquido', width: 175, getText: (doc) => CurrencyHelper.centsToMoney(doc.total_amount - doc.total_discount)},
            ], null, false, 6);

            ReportHelper.print(report, this.state.output, this.state.format);

            this.setState({ loadingModal: false });

        } else {

            toast.warn('Preencha o período do relatório');
        }
    }

    renderLoading() {

        return (

            <div style={{ height: 200 }}>
                <DefaultLoader/>
            </div>
        )
    }

    renderModal() {

        return (

            <div>
                <IosSwitch fullWidth label={'Todos os Produtos'} checked={this.state.allProducts} onChange={(e) => { this.setState({ allProducts: e, products: [] }) }} />
                { !this.state.allProducts ? ReportHelper.renderProductSelection(this, this.state.productDocs, 'products') : null }

                <IosSwitch fullWidth label={'Todos os Serviços'} checked={this.state.allServices} onChange={(e) => { this.setState({ allServices: e, services: [] }) }} />
                { !this.state.allServices ? ReportHelper.renderServiceSelection(this, this.state.serviceDocs, 'services') : null }

                <IosSwitch fullWidth label={'Todos os Locais'} checked={this.state.allLocations} onChange={(e) => { this.setState({ allLocations: e, locations: [] }) }} />
                { !this.state.allLocations ? ReportHelper.renderLocationSelection(this, this.state.locationDocs, 'locations') : null }

                <IosSwitch fullWidth label={'Todos os Clientes'} checked={this.state.allSalesUsers} onChange={(e) => { this.setState({ allSalesUsers: e, saleUsers: [] }) }} />
                { !this.state.allSalesUsers ? ReportHelper.renderSalesUserSelection(this, this.state.salesUserDocs, 'saleUsers') : null }

                <div style={{ marginTop: 15 }}/>

                { ReportHelper.renderPeriodSelection(this, 'start', 'end') }

                <FormLabel style={{ marginTop: 18, fontWeight: '800' }} component="legend">Ordenação</FormLabel>
                <div style={{ flexDirection: 'row', paddingTop: 10 }}>
                    <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} value={this.state.salesByProductOrder} onChange={(evt) => { this.setState({ salesByProductOrder: evt.target.value }) }}>
                        <FormControlLabel value="name" control={<Radio style={{ color: Colors.primary }} />} label="Nome" />
                        <FormControlLabel value="quantity" control={<Radio style={{ color: Colors.primary }} />} label="Quantidade" />
                    </RadioGroup>
                </div>
                <ReportDefaultModalOptions format={this.state.format} output={this.state.output} onOutputChange={(value) => { this.setState({ output: value }) }} onFormatChange={(value) => { this.setState({ format: value }) }}/>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <DefaultButton width={'100%'} title={'Imprimir'} onClick={() => { this.print() }} />
                </div>
            </div>
        )
    }

    render() {

        return (

            this.state.loadingModal ? this.renderLoading() : this.renderModal()
        )
    }
}