import React, { Component } from 'react';
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import LoginPage from '../page/LoginPage';
import PasswordResetPage from '../page/PasswordResetPage';
import RegisterPage from '../page/RegisterPage';
import TrialPage from '../page/TrialPage';
import NotFoundPage from '../page/NotFoundPage';
import UserLinkPage from '../page/UserLinkPage';
import SignContractPage from '../page/SignContractPage';

export default class LoginNavigator extends Component {
    render() {
        return (
            <BrowserRouter forceRefresh={true}>
                <Switch>
                    <Route exact path="/" render={() => <LoginPage/> } />
                    <Route path="/register" render={() => <RegisterPage/> } />
                    <Route path="/trial" render={() => <TrialPage/> } />
                    <Route path="/password_reset" render={() => <PasswordResetPage/> } />
                    <Route path="/user_link" render={() => <UserLinkPage/> } />
                    <Route path="/sign_contract" render={() => <SignContractPage/> } />
                    <Route path="/404" render={() => <NotFoundPage/> } />
                    <Redirect to="/404" />
                </Switch>
            </BrowserRouter>
        )
    }
}
