import DefaultSelect from "../components/DefaultSelect";
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import PaymentMethodHelper from "./PaymentMethodHelper";
import jsPDF from 'jspdf';
import moment from "moment";
import SessionHelper from "./SessionHelper";
import React from "react";
import * as XLSX from 'xlsx-js-style';
import { Readable } from 'stream';
import Colors from "../constants/Colors";
import OrderConstants from '../constants/Order';

XLSX.stream.set_readable(Readable);

export default class ReportHelper {

    static async getLogo(){

        return new Promise( async (resolve, reject)=> {

            let headers = new Headers();
            headers.append('Access-Control-Allow-Origin', '*');

            let path = `${process.env.PUBLIC_URL}/logo.png`;
            
            let request = await fetch(path, { headers: headers });
            let blob = await request.blob();

            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = ()=> resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    static async header(report, title = '', subtitle = '', date = null){

        report.doc.setFontSize(13);
        report.doc.line(12, 5, report.doc.internal.pageSize.getWidth() - 12, 5);

        let logo = await this.getLogo();
        if (logo){ report.doc.addImage(logo, 'PNG', 14, 7, 30, 0)}

        report.doc.setFontType('bold');
        report.doc.text(title, report.doc.internal.pageSize.getWidth()/ 2, 10, {align: 'center'});
        report.doc.setFontSize(10);

        report.doc.setFontType('normal');
        report.doc.text(subtitle, report.doc.internal.pageSize.getWidth()/ 2, 17, {align: 'center'});
        
        if (date) {

            report.doc.setFontType('normal');
            report.doc.text(date, report.doc.internal.pageSize.getWidth() - 14, 20, {align: 'right'});
            report.doc.setFontType('bold');
        }

        report.doc.setFontType('bold');
        report.doc.line(12, 23, report.doc.internal.pageSize.getWidth()- 12, 23);

        return report;
    }

    static footer(report){

        if (report.orientation === 'p') {

            report.doc.setFontType('normal');
            report.doc.setFontSize(10);
            report.doc.line(12, 266, report.doc.internal.pageSize.getWidth()- 12, 266);
            report.doc.setFontSize(6);
            report.doc.setFontSize(10);
            report.doc.text('Documento emitido pela plataforma Ace', report.doc.internal.pageSize.getWidth() / 2, 271, {align: 'center'});
            report.doc.text(`Gerado por ${SessionHelper.getData().name}`, report.doc.internal.pageSize.getWidth() / 2, 276, {align: 'center'});
            report.doc.line(12, 280, report.doc.internal.pageSize.getWidth()- 12, 280);

        } else {

            report.doc.setFontType('normal');
            report.doc.setFontSize(10);
            report.doc.line(12, 178, report.doc.internal.pageSize.getWidth()- 12, 178);
            report.doc.setFontSize(6);
            report.doc.setFontSize(10);
            report.doc.text('Documento emitido pela plataforma Ace', report.doc.internal.pageSize.getWidth() / 2, 183, {align: 'center'});
            report.doc.text(`Gerado por ${SessionHelper.getData().name}`, report.doc.internal.pageSize.getWidth() / 2, 187, {align: 'center'});
            report.doc.line(12, 192, report.doc.internal.pageSize.getWidth()- 12, 192);
        }

        return report;
    }

    static setModalValue(instance, valueKey, value, callback = null) {

        let state = instance.state;
        state[valueKey] = value;
        
        instance.setState(state);

        if (callback) { callback(value) }
    }

    static renderUserSelection(instance, docs, valueKey) {

        return (
            <div style={{ marginTop: 20, marginBottom: 20 }}>
                <DefaultSelect search={true} multiple={true} searchField={'name'} id={'users'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setModalValue(instance, valueKey, v.target.value) }} value={instance.state[valueKey]} docs={docs} label={'Selecionar Alunos'}/>
            </div>
        )
    }

    static renderTeacherSelection(instance, docs, valueKey) {

        return (
            <div style={{ marginTop: 20, marginBottom: 20 }}>
                <DefaultSelect search={true} multiple={true} searchField={'name'} id={'users'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setModalValue(instance, valueKey, v.target.value) }} value={instance.state[valueKey]} docs={docs} label={'Selecionar Professores'}/>
            </div>
        )
    }

    static renderCategorySelectiony(instance, docs, valueKey) {

        return (
            <div style={{ marginTop: 20, marginBottom: 20 }}>
                <DefaultSelect search={true} multiple={true} searchField={'label'} id={'categories'} valueField={'value'} displayField={'label'} onChange={(v) => { this.setModalValue(instance, valueKey, v.target.value) }} value={instance.state[valueKey]} docs={docs} label={'Selecionar Categorias'}/>
            </div>
        )
    }

    static renderCourtSelection(instance, docs, valueKey) {

        return (
            <div style={{ marginTop: 20, marginBottom: 20 }}>
                <DefaultSelect search={true} multiple={true} searchField={'name'} id={'courts'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setModalValue(instance, valueKey, v.target.value) }} value={instance.state[valueKey]} docs={docs} label={'Selecionar Quadras'}/>
            </div>
        )
    }

    static renderTournamentSelection(instance, docs, valueKey, callback) {

        return (
            <div style={{ marginTop: 20, marginBottom: 20 }}>
                <DefaultSelect search={true} searchField={'name'} id={'users'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setModalValue(instance, valueKey, v.target.value, callback) }} value={instance.state[valueKey]} docs={docs} label={'Selecionar Torneio'}/>
            </div>
        )
    }

    static renderRankingSelection(instance, docs, valueKey, callback) {

        return (
            <div style={{ marginTop: 20, marginBottom: 20 }}>
                <DefaultSelect search={true} searchField={'name'} id={'ranking'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setModalValue(instance, valueKey, v.target.value, callback) }} value={instance.state[valueKey]} docs={docs} label={'Selecionar Ranking'}/>
            </div>
        )
    }

    static renderProductSelection(instance, docs, valueKey) {

        return (
            <div style={{ marginTop: 20, marginBottom: 20 }}>
                <DefaultSelect multiple={true} search={true} searchField={'name'} id={'users'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setModalValue(instance, valueKey, v.target.value) }} value={instance.state[valueKey]} docs={docs} label={'Selecionar Produtos'}/>
            </div>
        )
    }

    static renderServiceSelection(instance, docs, valueKey) {

        return (
            <div style={{ marginTop: 20, marginBottom: 20 }}>
                <DefaultSelect multiple={true} search={true} searchField={'name'} id={'users'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setModalValue(instance, valueKey, v.target.value) }} value={instance.state[valueKey]} docs={docs} label={'Selecionar Serviços'}/>
            </div>
        )
    }

    static renderSalesUserSelection(instance, docs, valueKey) {

        return (
            <div style={{ marginTop: 20, marginBottom: 20 }}>
                <DefaultSelect multiple={true} search={true} searchField={'name'} id={'users'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setModalValue(instance, valueKey, v.target.value) }} value={instance.state[valueKey]} docs={docs} label={'Selecionar Clientes'}/>
            </div>
        )
    }

    static renderAdminSelection(instance, docs, valueKey) {

        return (
            <div style={{ marginTop: 20, marginBottom: 20 }}>
                <DefaultSelect multiple={true} search={true} searchField={'name'} id={'users'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setModalValue(instance, valueKey, v.target.value) }} value={instance.state[valueKey]} docs={docs} label={'Selecionar Administradores'}/>
            </div>
        )
    }

    static renderProviderSelection(instance, docs, valueKey) {

        return (
            <div style={{ marginTop: 20, marginBottom: 20 }}>
                <DefaultSelect multiple={true} search={true} searchField={'name'} id={'providers'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setModalValue(instance, valueKey, v.target.value) }} value={instance.state[valueKey]} docs={docs} label={'Selecionar Fornecedores'}/>
            </div>
        )
    }

    static renderLocationSelection(instance, docs, valueKey) {

        return (
            <div style={{ marginTop: 20, marginBottom: 20 }}>
                <DefaultSelect multiple={true} search={true} searchField={'name'} id={'locations'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setModalValue(instance, valueKey, v.target.value) }} value={instance.state[valueKey]} docs={docs} label={'Selecionar Locais'}/>
            </div>
        )
    }

    static renderTagSelection(instance, docs, valueKey) {

        return (
            <div style={{ marginTop: 20, marginBottom: 20 }}>
                <DefaultSelect multiple={true} search={true} searchField={'label'} id={'tags'} valueField={'value'} displayField={'label'} onChange={(v) => { this.setModalValue(instance, valueKey, v.target.value) }} value={instance.state[valueKey]} docs={docs} label={'Selecionar Tags'}/>
            </div>
        )
    }

    static renderMonthSelection(instance, valueKey) {

        return (
            <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        style={{ width: '100%' }}
                        invalidDateMessage={false}
                        format={'MM/yyyy'}
                        autoOk={true}
                        label="Mês"
                        cancelLabel={'Cancelar'}
                        okLabel={'Confirmar'}
                        views={['month', 'year']}
                        openTo='month'
                        onChange={(v) => { this.setModalValue(instance, valueKey, v) }}
                        value={instance.state[valueKey] || null}
                    />
                </MuiPickersUtilsProvider>
            </div>
        )
    }

    static renderPeriodSelection(instance, valueKeyStart, valueKeyEnd, format = 'dd/MM/yyyy') {

        return (
            <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 20, marginTop: 20 }}>
                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                    
                    { format.includes('HH:mm') ?
                    
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <KeyboardDateTimePicker
                                style={{ width: '100%' }}
                                invalidDateMessage={false}
                                format={format}
                                autoOk={true}
                                label="De"
                                cancelLabel={'Cancelar'}
                                okLabel={'Confirmar'}
                                onChange={(v) => { this.setModalValue(instance, valueKeyStart, v) }}
                                value={instance.state[valueKeyStart] || null}
                            />
                            <div style={{ marginRight: 5, marginLeft: 5 }} />
                            <KeyboardDateTimePicker
                                style={{ width: '100%' }}
                                invalidDateMessage={false}
                                format={format}
                                autoOk={true}
                                label="Até"
                                cancelLabel={'Cancelar'}
                                okLabel={'Confirmar'}
                                onChange={(v) => { this.setModalValue(instance, valueKeyEnd, v) }}
                                minDate={instance.state[valueKeyStart] || null}
                                value={instance.state[valueKeyEnd] || null}
                            />
                        </div>

                    :

                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <KeyboardDatePicker
                                style={{ width: '100%' }}
                                invalidDateMessage={false}
                                format={format}
                                autoOk={true}
                                label="De"
                                cancelLabel={'Cancelar'}
                                okLabel={'Confirmar'}
                                onChange={(v) => { this.setModalValue(instance, valueKeyStart, v) }}
                                value={instance.state[valueKeyStart] || null}
                            />
                            <div style={{ marginRight: 5, marginLeft: 5 }} />
                            <KeyboardDatePicker
                                style={{ width: '100%' }}
                                invalidDateMessage={false}
                                format={format}
                                autoOk={true}
                                label="Até"
                                cancelLabel={'Cancelar'}
                                okLabel={'Confirmar'}
                                onChange={(v) => { this.setModalValue(instance, valueKeyEnd, v) }}
                                minDate={instance.state[valueKeyStart] || null}
                                value={instance.state[valueKeyEnd] || null}
                            />
                        </div>

                    }

                </MuiPickersUtilsProvider>
            </div>
        )
    }

    static renderPaymentMethodSelection(instance, valueKey, service) {

        return (
            <div style={{ marginTop: 25, marginBottom: 20 }}>
                <DefaultSelect id={'payment_method'} multiple={true} valueField={'value'} displayField={'label'} onChange={(v) => { this.setModalValue(instance, valueKey, v.target.value) }} value={instance.state[valueKey]} docs={PaymentMethodHelper.getPaymentMethodsByService(service, true)} label={'Métodos de Pagamento'}/>
            </div>
        )
    }

    static renderStatusSelection(instance, valueKey) {

        let docs = OrderConstants.statusTypes;

        if (docs[0].value === 'all') {

            docs.splice(0, 1);
        }

        return (
            <div style={{ marginTop: 25, marginBottom: 20 }}>
                <DefaultSelect id={'status'} multiple={true} valueField={'value'} displayField={'label'} onChange={(v) => { this.setModalValue(instance, valueKey, v.target.value) }} value={instance.state[valueKey]} docs={docs} label={'Status'}/>
            </div>
        )
    }

    static async createReport(name, offset = 5, subtitle = '', format = 'pdf', orientation = 'p') {

        let report = {};

        if (format === 'pdf') {

            report = new jsPDF(orientation);
            report = { doc: report, offset: offset ? offset : 5, name: name, subtitle: subtitle, orientation: orientation };
            report = await this.header(report, name, subtitle, moment().format('DD/MM/YYYY HH:mm'));
            report = this.footer(report);

            let pagePosition = report.orientation === 'p' ? 290 : 202;
            report.doc.text(report.doc.internal.pageSize.getWidth() - 20, pagePosition, 'página ' + report.doc.internal.getNumberOfPages());
        
        } else {
         
            report = { offset: offset ? offset : 5, name: name, subtitle: subtitle, json: [] };
        }

        report.format = format;

        return report;
    }

    static async addPage(report, fillColor) {

        const initialOffset = 30;

        let pagePosition = report.orientation === 'p' ? 290 : 202;

        report.offset = initialOffset;
        report.doc.addPage();
        report.doc.text(report.doc.internal.pageSize.getWidth() - 20, pagePosition, 'página ' + report.doc.internal.getNumberOfPages());

        report = await this.header(report, report.name, report.subtitle, moment().format('DD/MM/YYYY HH:mm'));
        report = this.footer(report);

        report.doc.rect(12, report.offset, report.doc.internal.pageSize.getWidth() - 24, 6);
        report.doc.setFontType('bold');

        if (report.columns) { await this.createColumnHeader(report, report.columns, 0) };

        return report;
    }

    static isOverlaping(offset, orientation = 'p') {

        const pageLimitHeight = orientation === 'p' ? 250 : 160;
        return offset > pageLimitHeight
    }

    static async createColumnHeader(report, columns, spacing) {

        if (report.format === 'pdf') {

            columns.forEach((column, key) => {

                if (column.customColumnHeader) {
    
                    column.customColumnHeader(report, column);
    
                } else {
    
                    report.doc.text(column.name, column.width, report.offset + 4, { align: 'left' });
                }
            });
    
            report.doc.setFontType('normal');
    
            if (this.isOverlaping(report.offset, report.orientation)) {
    
                report = await this.addPage(report);
            }

        } else {
            
            let headerPos = report.json.length ? report.json.length : 0;   
            if (!report.json[headerPos]) { report.json[headerPos] = [] };
         
            columns.forEach((column, key) => {

                report.json[headerPos].push({
                    v: column.name,
                    t: 's',
                    s: { border: { top: { color: { rgb: '000000' }, style: 'thin' }, bottom: { color: { rgb: '000000' }, style: 'thin' }, left: { color: { rgb: '000000' }, style: 'thin' }, right: { color: { rgb: '000000' }, style: 'thin' } }, fill: { fgColor: { rgb: "0f77e8" } }, font: { bold: true, color: { rgb: "ffffff" } } }
                });
            });
        }

        return report;
    }

    static async createColumn(report, column, width, hasNext, doc, fillColor) {

        if (report.format === 'pdf') {

            let text = column.getText(doc);

            if (column.getColor) { column.getColor(report, doc); }
    
            report.doc.setFontType('normal');
            report.doc.text(text, width, report.offset + 4, { align: 'left' });
            report.doc.setTextColor(0, 0, 0);

        } else {

            let text = column.getText(doc);
            let key = report.excel_row;

            if (!report.json[key]) { report.json[key] = [] };

            let row = report.json[key];
            let s = { border: { top: { color: { rgb: '000000' }, style: 'thin' }, bottom: { color: { rgb: '000000' }, style: 'thin' }, left: { color: { rgb: '000000' }, style: 'thin' }, right: { color: { rgb: '000000' }, style: 'thin' } } };

            if (fillColor[0] !== 255 && fillColor[1] !== 255 && fillColor[2] !== 255) {

                s.fill = { fgColor: { rgb: this.componentToHex(fillColor[0]) + this.componentToHex(fillColor[1]) + this.componentToHex(fillColor[2]) } }
            }

            row.push({
                v: text,
                t: 's',
                s: s,
            });

            report.json[key] = row;
        }

        return report;
    }

    static treatName(string) {

        string = string.toLowerCase();
        string = string.charAt(0).toUpperCase() + string.slice(1);
        string = string.split(/\s/);

        return `${string[0]}${string[string.length - 1][0] ? ` ${string[string.length - 1][0].toUpperCase()}.` : ''}`;
    }

    static cutName(string, limit) {

        return string.length < limit ? string : string.slice(0, limit).trim() + '...';
    }

    static addSpace(report, space = 6) {

        report.offset += space;

        return report;
    }

    static async createColumns(report, docs, columns, onColumnCreate = null, persistColumns = true, spacing = 25, fillColor = [255, 255, 255], withHeader = true) {

        if (persistColumns) { report.columns = columns };

        if (docs.length) {

            if (report.format === 'pdf' && withHeader) {

                report.offset += spacing;
                report.doc.setFillColor(fillColor[0] ,fillColor[1], fillColor[2]);
                report.doc.rect(12, report.offset, report.doc.internal.pageSize.getWidth() - 24, 6, 'FD');
                report.doc.setFontType('bold');
                report.doc.setFillColor(255, 255, 255);
            }

            if (withHeader) {

                report = await this.createColumnHeader(report, columns, spacing);
            }
        }

        if (report.format === 'xlxs') {

            report.excel_row = report.json.length;
        }

        for (let key = 0; key < docs.length; key++) {

            let hasNext = docs[key + 1];
            let doc = docs[key]; 

            if (report.format === 'pdf') {

                report = this.addSpace(report);

                report.doc.setFillColor(fillColor[0] ,fillColor[1], fillColor[2]);
                report.doc.rect(12, report.offset, report.doc.internal.pageSize.getWidth() - 24, 6, 'FD');
                report.doc.setFillColor(255, 255, 255);
            }

            for (let columnKey = 0; columnKey < columns.length; columnKey++) {

                report = await this.createColumn(report, columns[columnKey], columns[columnKey].width, hasNext, doc, fillColor);
            }

            if (report.format === 'pdf') {

                if (this.isOverlaping(report.offset, report.orientation)) {

                    report = await this.addPage(report);
                }
            }

            if (onColumnCreate) {

                if (report.format === 'pdf') {

                    if (this.isOverlaping(report.offset, report.orientation)) {

                        report = await this.addPage(report);
                    }
                }

                await onColumnCreate(report, doc);
            };

            if (hasNext && report.format === 'xlxs') { report.excel_row = report.excel_row + 1; }
        }

        return report;
    }

    static print(report, output = 'download', format = 'pdf') {

        if (format === 'pdf') {

            if (output === 'new_tab') {

                report.doc.output('dataurlnewwindow');
    
            } else if (output === 'download') {
    
                report.doc.save(`${report.name}.pdf`);
            }

        } else {

            let ws = XLSX.utils.aoa_to_sheet(report.json);
            ws['!cols'] = this.fitColumns(report)
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Relatório');
            XLSX.writeFile(wb, `${report.name}.xlsx`);
        }
    }

    static fitColumns(report) {

        let array = [];
        let json = report.json;

        json.splice(0, 1);

        report.columns.forEach((column, key) => {

            let largestWidth = 1;

            json.forEach((col, colKey) => {
                
                if (col[key]) {

                    let value = col[key].v;
                    let colWidth = value.length * 0.9;
    
                    if (colWidth > largestWidth) {
    
                        largestWidth = colWidth;
                    }
                }
            });

            array.push({wch: largestWidth});
        });

        return array;
    }

    static isDevelopmentMode() {

        return '_self' in React.createElement('div');
    }

    static getShortName(name) {
        if(name) {
            let split = name.split(' ');
            return `${split[0]} ${split[split.length - 1]}`;
        } else {
            return ``;
        }   
    }

    static componentToHex(c) {

        let hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }

    static updateProgress(parent, total, at, loadingText) {
        let progress = total > 0 ? (at * 100) / total : 100;
        parent.setState({ progress, loadingText });
    }
}