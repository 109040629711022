import { toast } from 'react-toastify';
import moment from "moment-timezone";
import { FormLabel } from '@material-ui/core';
import Colors from '../constants/Colors';

export default class TournamentHelper {
    static getBracketSizes() {
        let bracketSizes = {
            group: [
                { title: '3', value: '3' },
                { title: '4', value: '4' },
                { title: '5', value: '5' },
                { title: '6', value: '6' },
                { title: '7', value: '7' },
                { title: '8', value: '8' },
                { title: '9', value: '9' },
                { title: '10', value: '10' },
                { title: '20', value: '20' },
                { title: '30', value: '30' },
                { title: '40', value: '40' },
                { title: '50', value: '50' },
            ],
            multi_group: [
                { title: '6', value: '6' },
                { title: '10', value: '10' },
                { title: '15', value: '15' },
                { title: '20', value: '20' },
                { title: '25', value: '25' },
                { title: '30', value: '30' },
                { title: '35', value: '35' },
                { title: '40', value: '40' },
                { title: '45', value: '45' },
                { title: '50', value: '50' },
                { title: '55', value: '55' },
                { title: '60', value: '60' },
                { title: '65', value: '65' },
                { title: '72', value: '72' },
            ],
            eliminatory: [
                { title: '4', value: '4' },
                { title: '8', value: '8' },
                { title: '16', value: '16' },
                { title: '32', value: '32' },
                { title: '64', value: '64' },
                { title: '128', value: '128' },
                { title: '256', value: '256' },
                { title: '512', value: '512' },
            ],
            masters: [
                { title: '6', value: '6' },
                { title: '8', value: '8' },
                { title: '10', value: '10' },
                { title: '12', value: '12' },
                { title: '14', value: '14' },
                { title: '16', value: '16' },
                { title: '18', value: '18' },
                { title: '20', value: '20' },
            ]
        };

        return bracketSizes;
    }

    static getMatchAlias(aliasNumber) {
        return `Jogo ${parseInt(aliasNumber) >= 0 ? aliasNumber : `S/N`}`;
    }

    static getDefaultTournamentCategories(enforceBracketType) {
        let bracketType = enforceBracketType ? enforceBracketType : 'eliminatory';
        let limit = TournamentHelper.getBracketSizes()[bracketType][2].value;

        return [
            {
                title: 'Masculino',
                name: 'M',
                checked: false,
                participants: [],
                classes: [
                    { title: 'Classe Exemplo', name: 'example', bracket_type: bracketType, limit: limit, checked: true, rounds: [], participants: [] }
                ],
            },
            {
                title: 'Feminino',
                name: 'F',
                checked: false,
                participants: [],
                classes: [
                    { title: 'Classe Exemplo', name: 'example', bracket_type: bracketType, limit: limit, checked: true, rounds: [], participants: [] }
                ],
            },
            {
                title: 'Misto',
                name: 'U',
                checked: false,
                participants: [],
                classes: [
                    { title: 'Classe Exemplo', name: 'example', bracket_type: bracketType, limit: limit, checked: true, rounds: [], participants: [] }
                ],
            },
        ];
    }

    static renderBracketDescription(bracket_type) {
        if (bracket_type) {
            let selectedBracket = TournamentHelper.getBracketTypes().find(bracket => (bracket.name === bracket_type));

            if (selectedBracket) {
                return (
                    <div style={{ width: 545, padding: 10 }}>
                        <FormLabel style={{ textAlign: 'justify', color: Colors.background, fontWeight: '600' }}>{selectedBracket.description}</FormLabel>
                        {selectedBracket.image ? <img src={process.env.PUBLIC_URL + selectedBracket.image} style={{ width: 530, marginTop: 10 }} /> : null}
                    </div>
                );
            }
        }
    }

    static getClassicClasses(enforceBracketType) {
        let bracketType = enforceBracketType ? enforceBracketType : 'eliminatory';
        let limit = TournamentHelper.getBracketSizes()[bracketType][2].value;

        return [
            { title: '5ª Classe', name: '5', bracket_type: bracketType, limit: limit, checked: true, rounds: [], participants: [] },
            { title: '4ª Classe', name: '4', bracket_type: bracketType, limit: limit, checked: true, rounds: [], participants: [] },
            { title: '3ª Classe', name: '3', bracket_type: bracketType, limit: limit, checked: true, rounds: [], participants: [] },
            { title: '2ª Classe', name: '2', bracket_type: bracketType, limit: limit, checked: true, rounds: [], participants: [] },
            { title: '1ª Classe', name: '1', bracket_type: bracketType, limit: limit, checked: true, rounds: [], participants: [] },
        ];
    }

    static getCustomClass(title, enforceBracketType) {
        let bracketType = enforceBracketType ? enforceBracketType : 'eliminatory';
        let limit = TournamentHelper.getBracketSizes()[bracketType][2].value;

        return { title: title, name: `custom-tournament-class-${title.toLowerCase().replace(' ', '')}`, bracket_type: bracketType, limit: limit, checked: true, rounds: [], participants: [] }
    }

    static getSports() {

        return [
            { title: 'Tennis', name: 'tennis' },
            { title: 'Padel', name: 'padel' },
            { title: 'Beach Tennis', name: 'beach_tennis' },
            { title: 'Squash', name: 'squash' },
            { title: 'Badminton', name: 'badminton' },
        ]
    }

    static getBracketTypes() {
        return [
            { title: 'Eliminatória', name: 'eliminatory', image: '/brackets/eliminatory.png', description: 'Esse tipo de chave consiste de confrontos diretos, perdedores são desclassificados na primeira derrota e o ganhador avança na chave.' },
            { title: 'Grupo (Todos x Todos)', name: 'group', image: '/brackets/group.png', description: 'Quando há menos jogadores no torneio, o grupo é uma opção interessante, proporcionando partidas todos contra todos, definindo o campeão pelos critérios de pontuação.' },
            { title: 'Multigrupo', name: 'multi_group', image: '/brackets/multi_group.png', description: 'Mais comum no Beach Tennis, formado de um número de grupos, onde o primeiro e segundo colocado de cada grupo se classificam para gerar uma chave eliminatória e jogar contra classificados de outros grupos' },
            { title: 'Masters (ATP Finals)', name: 'masters', image: '/brackets/masters.png', description: 'Dois grupos, onde o primeiro e o segundo colocado de cada grupo classificam-se para uma chave eliminátória, com semi-final e final.' },
        ];
    }

    static getBracketTypeDescriptionByName(bracketType) {
        let types = {
            eliminatory: { title: 'Eliminatória', name: 'eliminatory', image: '/brackets/eliminatory.png', description: 'Esse tipo de chave consiste de confrontos diretos, perdedores são desclassificados na primeira derrota e o ganhador avança na chave.' },
            group: { title: 'Grupo (Todos x Todos)', name: 'group', image: '/brackets/group.png', description: 'Quando há menos jogadores no torneio, o grupo é uma opção interessante, proporcionando partidas todos contra todos, definindo o campeão pelos critérios de pontuação.' },
            multi_group: { title: 'Multigrupo', name: 'multi_group', image: '/brackets/multi_group.png', description: 'Mais comum no Beach Tennis, formado de um número de grupos, onde o primeiro e segundo colocado de cada grupo se classificam para gerar uma chave eliminatória e jogar contra classificados de outros grupos' },
            masters: { title: 'Masters (ATP Finals)', name: 'masters', image: '/brackets/masters.png', description: 'Dois grupos, onde o primeiro e o segundo colocado de cada grupo classificam-se para uma chave eliminátória, com semi-final e final.' },
        };

        return types[bracketType];
    }

    static validateCategories(categories = []) {

        let valid = true;

        if (categories.length) {

            let uncheckedCount = 0;

            categories.forEach((category, key) => {

                if (!category.checked) {

                    uncheckedCount++;

                    if (uncheckedCount === categories.length) {

                        toast.warn('Selecione ao menos uma categoria para o torneio');

                        valid = false
                        return;
                    }

                } else {
                    let uncheckedClasses = 0;

                    category.classes.forEach((categoryClass, categoryClassKey) => {

                        if (!categoryClass.checked) {

                            uncheckedClasses++;

                            if (uncheckedClasses === category.classes.length) {

                                toast.warn(`Selecione ao menos uma classe para a categoria ${category.title}`);

                                valid = false
                                return;
                            }
                        } else {
                            if (!categoryClass.limit || categoryClass.limit <= 0) {

                                toast.warn(`Informe o limite de participantes para a categoria ${category.title}`);

                                valid = false
                                return;
                            }
                        }
                    });
                }
            });

        } else {

            toast.warn('Categorias inválidas');

            valid = false
            return;
        }

        return valid;
    }

    static calculateMatchesGeneratedByBracketType(bracket_type, limit) {
        let matchesGenerated = 0;

        limit = parseInt(limit);

        if (bracket_type == 'eliminatory') {
            matchesGenerated = parseInt(limit) - 1;
        } else if (bracket_type == 'group') {
            matchesGenerated += (parseInt(limit) - 1) * (limit / 2);
        } else if (bracket_type == 'multi_group') {
            let nOfGroups = Math.floor(limit / 3);
            let groupsOf4 = parseInt(limit % 3);
            let groupsOf3 = nOfGroups - groupsOf4;
            matchesGenerated += 3 * groupsOf3;
            matchesGenerated += 6 * groupsOf4;

            let multiplier = limit > 48 ? 1 : 2;
            let eliminatoryMatches = (TournamentHelper.getCloserKey(nOfGroups * multiplier) / 2);
            let nOfByes = eliminatoryMatches - nOfGroups;

            matchesGenerated += (eliminatoryMatches - nOfByes) - 1;
        } else if (bracket_type == 'masters') {
            matchesGenerated += (((limit / 2) - 1) * (limit / 4)) * 2;
            matchesGenerated += 3;
        }

        return matchesGenerated;
    }

    static getCloserKey(nOfPlayers) {
        let output = 1;

        for (let i = 512; i >= 1; i /= 2) {
            if (nOfPlayers >= i) {
                output = i;
                break;
            } else if (nOfPlayers < i) {
                output = i;
                if (nOfPlayers > (i / 2)) break;
            }
        }

        return output;
    }

    static async getGeneratedMatchesInPeriod(categories) {
        if (categories) {
            let generatedMatches = 0;

            categories.forEach((category) => {
                if (category.checked) {
                    category.classes.forEach((categoryClass) => {
                        generatedMatches += TournamentHelper.calculateMatchesGeneratedByBracketType(categoryClass.bracket_type, categoryClass.limit);
                    });
                }
            });

            return generatedMatches;
        } else {
            return false;
        }
    }

    static async getMaximumMatchesInPeriod(matchStart, matchEnd, matchAverageDuration, tournamentStart, tournamentEnd, numberOfCourts) {
        if (matchStart && matchEnd && matchAverageDuration && tournamentStart && tournamentEnd && numberOfCourts) {
            let diffTime = (moment(matchEnd).diff(matchStart, 'minutes')) * (moment(tournamentEnd).diff(tournamentStart, 'day') + 1) * numberOfCourts;
            let maximumMatches = diffTime / matchAverageDuration;

            return maximumMatches;
        } else {
            return false;
        }
    }

    static getTournamentParticipants(tournament) {
        let output = [];

        const categories = tournament.category;

        for (let gender = 0; gender < categories.length; gender++) {
            if (categories[gender].checked == true) {

                for (let classes = 0; classes < categories[gender].classes.length; classes++) {
                    if (categories[gender].classes[classes].checked == true) {
                        const participants = categories[gender].classes[classes].participants;

                        for (let participant = 0; participant < participants.length; participant++) {
                            categories[gender].classes[classes].participants[participant].players.forEach((user_id) => {
                                if (!output.includes(user_id)) output.push(user_id);
                            });
                        }
                    }
                }
            }
        }

        return output;
    }

    static getTotalTournamentParticipants(tournament) {
        let output = 0;

        const categories = tournament.category;

        for (let gender = 0; gender < categories.length; gender++) {
            if (categories[gender].checked == true) {

                for (let classes = 0; classes < categories[gender].classes.length; classes++) {
                    if (categories[gender].classes[classes].checked == true) {
                        const participants = categories[gender].classes[classes].participants;

                        for (let participant = 0; participant < participants.length; participant++) {
                            categories[gender].classes[classes].participants[participant].players.forEach((user_id) => {
                                output++;
                            });
                        }
                    }
                }
            }
        }

        return output;
    }

    static getParticipantsByCategoryAndClass(participantDocs, tournament, categoryKey, classKey, selectFormat) {
        let output = [];

        const categories = tournament.category;

        if (categories[categoryKey].classes[classKey] && categories[categoryKey].classes[classKey].checked == true) {
            const participants = categories[categoryKey].classes[classKey].participants;

            for (let participant = 0; participant < participants.length; participant++) {
                if (selectFormat) {
                    if (tournament.type === 'simple') {
                        categories[categoryKey].classes[classKey].participants[participant].players.forEach((user_id) => {
                            let user = participantDocs.find(elem => elem.id === user_id);
                            if (user) output.push(user);
                        });
                    } else {
                        let names = [];

                        categories[categoryKey].classes[classKey].participants[participant].players.forEach((user_id) => {
                            let user = participantDocs.find(elem => elem.id === user_id);
                            if (user) names.push(user.name);
                        });

                        output.push({ id: TournamentHelper.treatPairSelectionId(categories[categoryKey].classes[classKey].participants[participant].players), name: names.join(', ') });
                    }
                } else {
                    categories[categoryKey].classes[classKey].participants[participant].players.forEach((user_id) => {
                        let user = participantDocs.find(elem => elem.id === user_id);
                        if (user) output.push(user);
                    });
                }
            }
        }

        return output;
    }

    static treatPairSelectionId(players, reverse) {
        if(!reverse) {
            return players.join('|').toString();
        } else {
            return players.split('|');
        }
    }

}