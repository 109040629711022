import React, { Component } from 'react';
import IosSwitch from '../IosSwitch';
import DefaultButton from '../DefaultButton';
import DefaultLoader from '../DefaultLoader';
import SessionHelper from '../../helper/SessionHelper';
import ReportHelper from '../../helper/ReportHelper';
import moment from "moment-timezone";
import CurrencyHelper from '../../helper/CurrencyHelper';
import Firestore from '../../api/firebase/Firestore';
import { toast } from 'react-toastify';
import ReportDefaultModalOptions from './ReportDefaultModalOptions';

export default class StudentPlanReport extends Component {

    state = {
        name: `Matrículas por Aluno - ${SessionHelper.getData().company.name}`,
        loadingModal: true,
        teacherDocs: [],
        studentPlanDocs: [],
        studentDocs: [],
        paramDoc: {},
        teachers: [],
        allTeachers: true,
        start: moment().startOf('month').startOf('day').toDate(),
        output: 'download',
        format: 'pdf',
    }

    async componentDidMount() {

        if (this.props.getDocs && this.props.getDocs) {

            for (let index = 0; index < this.props.getDocs.length; index++) {
                
                const getDoc = this.props.getDocs[index];
                await getDoc(this);
            }
        }

        this.setState({ loadingModal: false });
    }

    async getReportDocs() {

        let students = this.state.studentDocs;
        let docs = [];

        for (let student of students) {
         
            let userDoc = student;

            let query = await Firestore.customQuery('student_subscription').where('id_user', '==', userDoc.id).where('id_company', '==', SessionHelper.getData().id_company).orderBy('created_at', 'desc').limit(1).get();
            let studentSubscription = null;
            let studentPlan = '';

            if (query.size === 1) {

                studentSubscription = query.docs[0].data();
                studentPlan = this.state.studentPlanDocs.find(item => item.id === studentSubscription.id_plan);
            };

            userDoc.plan = studentPlan ? `${studentPlan.name} - ${CurrencyHelper.centsToMoney(studentPlan.price)}` : 'Sem Matrícula';

            docs.push(userDoc);
        }

        docs.sort((a, b) => {

            return a.name.toLowerCase() > b.name.toLowerCase();
        });

        return docs;
    }

    addThree(report, column) {

        report.doc.setLineWidth(0.6);
        report.doc.line(column.width - 0.3, report.offset + 4, 18, report.offset + 4);
        report.doc.line(column.width, report.offset + 4, column.width, report.offset + 2);
        report.doc.setLineWidth(0.2);
    }

    getStudentTags(tags) {

        let result = [];

        if (tags && tags.length && this.state.paramDoc.tags && this.state.paramDoc.tags.length) {

            tags.forEach((value, key) => {

                let tag = this.state.paramDoc.tags.find(item => item.value === value);

                if (tag) {

                    result.push(tag.label);
                }
            });
        }

        return result.join(', ');
    }

    async print() {

        try {

            this.setState({ loadingModal: true });

            let docs = await this.getReportDocs();
            let report = await ReportHelper.createReport(this.state.name, 5, moment().format('MMMM/YYYY'), this.state.format, 'l');

            report = await ReportHelper.createColumns(report, docs, [
                { name: 'Aluno', width: 13, getText: (doc) => doc.name ? this.state.format === 'pdf' ? ReportHelper.cutName(ReportHelper.getShortName(doc.name), 18) : doc.name : '' },
                { name: 'Telefone', width: 50, getText: (doc) => doc.phone || '' },
                { name: 'Cpf', width: 85, getText: (doc) => doc.cpf ? doc.cpf.toString().replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : '' },
                { name: 'Tags', width: 115, getText: (doc) => this.state.format === 'pdf' ? ReportHelper.cutName(this.getStudentTags(doc.tags), 42) : this.getStudentTags(doc.tags) },
                { name: 'Plano', width: 200, getText: (doc) => this.state.format === 'pdf' ? ReportHelper.cutName(doc.plan, 45) : doc.plan },
            ]);
    
            ReportHelper.print(report, this.state.output, this.state.format);

            this.setState({ loadingModal: false });    

        } catch (error) {

            this.setState({ loadingModal: false });
            toast.error('Houve um problema ao imprimir o relatório')
        }

    }

    renderLoading() {

        return (

            <div style={{ height: 200 }}>
                <DefaultLoader/>
            </div>
        )
    }

    renderModal() {

        return (
            <div>
                <ReportDefaultModalOptions format={this.state.format} output={this.state.output} onOutputChange={(value) => { this.setState({ output: value }) }} onFormatChange={(value) => { this.setState({ format: value }) }}/>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <DefaultButton width={'100%'} title={'Imprimir'} onClick={() => { this.print() }} />
                </div>
            </div>
        )
    }

    render() {

        return (

            this.state.loadingModal ? this.renderLoading() : this.renderModal()
        )
    }
}