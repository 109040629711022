import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import DefaultInput from '../components/DefaultInput';
import InputMask from "react-input-mask";
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import DefaultModal from '../components/DefaultModal';
import Colors from '../constants/Colors';
import PersonAdd from '@material-ui/icons/PersonAdd';
import AddIcon from '@material-ui/icons/Add';
import Firestore from '../api/firebase/Firestore';
import moment from "moment-timezone";
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import StudentSubscriptionStatus from '../components/StudentSubscriptionStatus';
import SessionHelper from '../helper/SessionHelper';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TermHelper from '../helper/TermHelper';
import HeaderActionsWrapper from '../components/HeaderActionsWrapper';
import UserTicketStatus from '../components/UserTicketsStatus';
import TicketsDetailPanel from '../components/TicketsDetailPanel';
import { Button, FormLabel } from '@material-ui/core';
import ZipCodeHelper from '../helper/ZipCodeHelper';
import DataHelper from '../helper/DataHelper';

export default class ClientPage extends Component {

    state = {
        docs: [],
        userCount: null,
        name: '',
        email: '',
        phone: '',
        cpf: '',
        zipcode: '',
        street: '',
        number: '',
        neighbourhood: '',
        complement: '',
        state: SessionHelper.getData().company.state,
        city: SessionHelper.getData().company.city,
        active: true,
        loading: true,
        loadingModal: false,
        addModal: false,
        companyLabel: TermHelper.getestablishmentTypeLabel(SessionHelper.getData().company.establishment_type),
    }

    async componentDidMount() {
        await this.getDocs();
        this.setState({ loading: false });
    }

    async getDocs() {
        let query = await Firestore.customQuery('user').where('id_companies', 'array-contains', SessionHelper.getData().id_company).where('type', 'in', ['sales_user']).orderBy('name', 'asc').get();
        let docs = [];
        let userCount = 0;

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
            userCount++;
        });

        this.setState({ docs: docs, userCount: userCount });
    }

    renderUserType(type) {
        let color, text;

        if (type == 'student') {
            text = TermHelper.getestablishmentTypeLabel(SessionHelper.getData().company.establishment_type).singular;
            color = Colors.success;
        } else {
            text = 'Cliente de Bar / Loja';
            color = Colors.primary;
        }

        return <b style={{ backgroundColor: color, color: 'white', paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8, borderRadius: 5 }}>{text}</b>;
    }

    async zipCodeFetch(zipCode) {
        if (zipCode.length) {

            let response = await ZipCodeHelper.getAddress(zipCode.replace(/[^\d]/g, ''));

            if (response !== false) {

                this.setState({ street: response.logradouro, city: response.localidade, state: response.uf, neighbourhood: response.bairro, complement: response.complemento })

            } else {

                this.setState({ street: '', city: '', state: '', neighbourhood: '', complement: '' })
            }
        }
    }

    async addUser() {
        if (this.state.loadingModal) {
            toast.info('Aguarde, adicionando...');
            return;
        }

        if (!this.state.name) {
            toast.warn('Nome do cliente é obrigatório.');
            return;
        }

        this.setState({ loadingModal: true });

        try {
            await Firestore.insert({
                name: this.state.name,
                type: "sales_user",
                id_companies: [SessionHelper.getData().id_company],
                created_at: new Date(),
                cpf: this.state.cpf ? this.state.cpf.replace(/[^\d]/g, '') : null,
                email: this.state.email || null,
                phone: this.state.phone ? `${this.state.phone.replace(/[^\d]/g, '')}` : null,
                state: this.state.state || null,
                city: this.state.city || null,
                zipcode: this.state.zipcode ? this.state.zipcode.replace(/[^\d]/g, '') : null,
                street: this.state.street || null,
                number: this.state.number || null,
                neighbourhood: this.state.neighbourhood || null,
                complement: this.state.complement || null
            }, 'user');

            toast.success('Cliente cadastrado!');
        } catch (e) {
            toast.error('Não foi possível inserir o cliente');
            this.setState({ addModal: false, loadingModal: false });
            return;
        }

        this.setState({
            name: '',
            email: '',
            phone: '',
            cpf: '',
            zipcode: '',
            street: '',
            number: '',
            neighbourhood: '',
            complement: '',
            state: SessionHelper.getData().company.state,
            city: SessionHelper.getData().company.city,
        });
        await this.getDocs();

        this.setState({ addModal: false, loadingModal: false });
    }

    addModal() {
        return (
            <div>
                <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', fontWeight: 'bold', marginBottom: 10 }}>
                    <InfoRoundedIcon style={{ fontSize: 20, marginRight: 8 }} />
                    <div>{'Se o valor for ficar em débito recomendamos que preencha todos os dados'}</div>
                </div>

                <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ name: v }) }} label={'Nome'} />
                <InputMask onBlur={(v) => { this.setState({ cpf: v }) }} mask="999.999.999-99" maskPlaceholder={''}>
                    <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} minLength={11} label={'CPF'} />
                </InputMask>

                <DefaultInput email onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ email: v }) }} label={'E-mail'} />

                <InputMask onBlur={(v) => { this.setState({ phone: DataHelper.treatPhone(v) }) }} mask="+55 (99) 9 9999-9999" maskPlaceholder={''}>
                    <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} minLength={11} label={'Telefone'} />
                </InputMask>

                <FormLabel style={{ paddingTop: 28, paddingBottom: 18 }} component="legend">Endereço</FormLabel>
                <div style={{ color: Colors.contrast, display: 'flex', flexDirection: 'row', fontWeight: 'bold', marginBottom: 10 }}>
                    <InfoRoundedIcon style={{ fontSize: 20, marginRight: 8 }} />
                    <div>{'Informe o CEP para auto-completar o endereço.'}</div>
                </div>
                <InputMask onBlur={(v) => { this.zipCodeFetch(v) }} onChange={(v) => { this.setState({ zipcode: v }) }} mask="99999-999" value={this.state.zipcode}>
                    <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} minLength={8} label={'Cep'} />
                </InputMask>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: '50%', paddingRight: 5 }}><DefaultInput value={this.state.state} disabled={true} label={'Estado'} /></div>
                    <div style={{ width: '50%', paddingLeft: 5 }}><DefaultInput value={this.state.city} disabled={true} label={'Cidade'} /></div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: '70%', paddingRight: 5 }}><DefaultInput value={this.state.street} onChange={(v) => { this.setState({ street: v }) }} label={'Rua'} /></div>
                    <div style={{ width: '30%', paddingLeft: 5 }}><DefaultInput value={this.state.number} onChange={(v) => { this.setState({ number: v }) }} label={'Número'} /></div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: '50%', paddingRight: 5 }}><DefaultInput value={this.state.neighbourhood} onChange={(v) => { this.setState({ neighbourhood: v }) }} label={'Bairro'} /></div>
                    <div style={{ width: '50%', paddingLeft: 5 }}><DefaultInput value={this.state.complement} onChange={(v) => { this.setState({ complement: v }) }} label={'Complemento'} /></div>
                </div>
                <div style={{ marginTop: 8 }} />
                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                    <Button onClick={() => { this.addUser() }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ addModal: false }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        );
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <HeaderActionsWrapper style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon />} onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar Cliente'} />
                    </div>
                </HeaderActionsWrapper>

                <DefaultTable
                    title={"Clientes da Loja"}
                    marginTop={10}
                    actions={[
                        {
                            icon: WhatsAppIcon,
                            tooltip: 'Chamar no WhatsApp',
                            onClick: (event, rowData) => { rowData.phone ? window.open(`https://wa.me/${rowData.phone.replace(/[^\d]/g, '')}`, '_blank') : toast.error('Esse usuário não possuí telefone cadastrado') }
                        }
                    ]}

                    detailPanel={(rowData) => { return <TicketsDetailPanel {...this.props} user={rowData.id} usersDocs={this.state.docs} /> }}
                    onRowClick={(evt, rowData, togglePanel) => { togglePanel() }}
                    filtering={true}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Companies', field: 'companies', hidden: true },
                        { title: 'Nome', field: 'name' },
                        { title: 'Comandas', field: '', editable: false, render: rowData => <UserTicketStatus user={rowData.id} /> },
                        { title: 'Tipo', field: 'type', editable: false, render: rowData => this.renderUserType(rowData.type) },
                        { title: 'Telefone', field: 'phone' },
                        { title: 'E-mail', field: 'email' },
                        { title: 'Cpf', field: 'cpf', render: rowData => <div>{rowData.cpf ? rowData.cpf.toString().replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : ''}</div> },
                        { title: 'Cep', field: 'zipcode' },
                        { title: 'Cidade', field: 'city' },
                        { title: 'Estado', field: 'state' },
                        { title: 'Rua', field: 'street' },
                        { title: 'Número', field: 'number' },
                        { title: 'Bairro', field: 'neighbourhood' },
                        { title: 'Complemento', field: 'complement' },
                        { title: 'Data Cadastro', field: 'created_at', editable: false, render: rowData => <div>{rowData.date || rowData.created_at ? moment(rowData.date ? rowData.date.toDate ? rowData.date.toDate() : rowData.date : rowData.created_at.toDate ? rowData.created_at.toDate() : rowData.created_at).format('DD/MM/YYYY HH:mm') : ''}</div> },
                    ]}
                    data={this.state.docs}
                    onRowUpdate={async (oldData, newData) => {
                        if (oldData.type === 'student') {
                            toast.error('Só é possível editar Clientes nessa tela, se você quiser editar/remover Alunos vá para a opção Alunos no menu lateral.', { onClick: () => { window.location = '/student' } });
                            return;
                        }

                        newData.date = oldData.date;

                        let prev = this.state.docs;
                        prev[prev.indexOf(oldData)] = newData;

                        this.setState({ docs: prev });

                        if (oldData.id) {

                            let update = {
                                name: newData.name,
                                email: newData.email,
                                phone: newData.phone,
                                cpf: newData.cpf,
                                zipcode: newData.zipcode ? newData.zipcode.replace(/[^\d]/g, '') : '',
                                street: newData.street || '',
                                number: newData.number || '',
                                neighbourhood: newData.neighbourhood || '',
                                complement: newData.complement || ''
                            };

                            await Firestore.update(update, 'user', newData.id);

                            update.category = newData.category || null;
                            update.lesson_type = newData.lesson_type || null;
                        }

                        toast.success("Editado com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        return prev;
                    }}
                    onRowDelete={async (oldData) => {
                        if (oldData.type === 'student') {
                            toast.error('Só é possível remover Clientes nessa tela, se você quiser editar/remover Alunos vá para a opção Alunos no menu lateral.', { onClick: () => { window.location = '/student' } });
                            return;
                        }

                        let prev = this.state.docs;
                        prev.splice(prev.indexOf(oldData), 1);

                        this.setState({ docs: prev });

                        if (oldData.id) {
                            await Firestore.delete('user', oldData.id);
                        }

                        toast.success("Removido com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        return prev;
                    }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Cliente'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal} />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
