import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import { Card, FormLabel } from '@material-ui/core';
import moment from "moment-timezone";
import Colors from '../constants/Colors';
import SessionHelper from '../helper/SessionHelper';
import Functions from '../constants/Functions';
import EditIcon from '@material-ui/icons/Edit';
import ClipLoader from "react-spinners/ClipLoader";

export default class SignContractPage extends Component {
    state = {
        loading: true,
        success: false,
        parsedTerm: '',
        user: {},
        company: {},
        loadingBtn: false,
        showMessage: false
    }

    async componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const hash = urlParams.get('hash');

        if (hash) {
            const request = await fetch(`${Functions.getEndPoint()}/getContract`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify({ hash: hash })
            });

            let response = await request.json();
            response.status = request.status;

            let success = false;

            if (response.status === 200) {
                success = true;
                this.setState({ loading: false, success: success, parsedTerm: response.contract, user: response.user, company: response.company, signature: response.signature });
            } else {
                this.setState({ loading: false, success: success, error: response.msg });
            }
        } else {
            this.setState({ sucess: false, loading: false, error: `Link inválido.` });
        }
    }

    signContract = async () => {
        this.setState({ loadingBtn: true });

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const hash = urlParams.get('hash');

        if (hash) {
            const request = await fetch(`${Functions.getEndPoint()}/signContract`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify({ hash: hash })
            });

            let response = await request.json();
            response.status = request.status;

            let success = false;

            if (response.status === 200) {
                success = true;
            }

            this.setState({ loadingBtn: false, success: success, showMessage: true });
        } else {
            this.setState({ error: `Link inválido.`, sucess: false, loadingBtn: false });
        }
    }

    renderStatus() {

        if (this.state.success) {

            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <img style={{ height: 300 }} src={process.env.PUBLIC_URL + '/ilustrations/confirm_link_page.png'} />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 25 }}>
                        <p style={{ fontSize: 25, fontWeight: 'bold' }}>{`Sucesso!`}</p>
                        <div style={{ color: 'grey', fontSize: 16, textAlign: 'center' }}>{'Contrato assinado com sucesso!'}</div>
                    </div>
                </div>
            )

        } else {

            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <img style={{ height: 300 }} src={process.env.PUBLIC_URL + '/ilustrations/error_link_page.png'} />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 25 }}>
                        <p style={{ fontSize: 25, fontWeight: 'bold' }}>{`Ops!`}</p>
                        <div style={{ color: 'grey', fontSize: 16, textAlign: 'center' }}>{this.state.error}</div>
                    </div>
                </div>
            )
        }
    }

    renderSign() {
        if(this.state.signature) {
            return (
                <div>
                    <FormLabel >Contrato assinado por {this.state.user.name}. Dados:</FormLabel>
                    <div style={{marginTop: 10}}>Data: {this.state?.signature?.formatedDate}</div>
                    <div>IP: {this.state?.signature?.ip}</div>
                    <div>Dispositivo: {this.state?.signature?.userAgent}</div>
                </div>
            )
        } else {
            return (
                <div>
                    <FormLabel >Ao clicar em "Assinar Digitalmente" você concorda em ser {this.state.user.name} e aceita todos os termos legais descritos no contrato. Caso você não seja {this.state.user.name}, não assine. Esta ação pode ter implicações legais.</FormLabel>
    
                    <div style={{ marginBottom: 20 }} />
    
                    <DefaultButton loading={this.state.loadingBtn} leftIcon={<EditIcon />} title={'Assinar Digitalmente'} onClick={this.signContract} />
                </div>
            )
        }
    }

    renderMembershipTermPreview() {
        if (this.state.success && !this.state.showMessage) {
            return (
                <div style={{ padding: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: 200, paddingBottom: 20, paddingTop: 5, justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
                        <img style={{ height: 32 }} src={process.env.PUBLIC_URL + '/logo.png'} />
                        {this.state.company?.logo ? '-' : ''}
                        {this.state.company?.logo ? <img style={{ height: 32 }} src={this.state.company.logo} /> : ''}
                    </div>

                    <div style={{ marginBottom: 20 }} dangerouslySetInnerHTML={{ __html: this.state.parsedTerm }} />

                    {this.renderSign()}
                </div>
            )
        } else {
            return this.renderStatus();
        }

    }

    renderLoading() {

        return (
            <div style={{ minWidth: 300, height: '70vh', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <ClipLoader
                    size={this.props.size ? this.props.size : 50}
                    color={this.props.color ? this.props.color : Colors.primary}
                    loading={true}
                />
            </div>
        )
    }

    render() {
        return (
            <div style={styles.container}>
                <div style={{ width: window.innerWidth * .8, backgroundColor: Colors.background, borderRadius: 5, overflowY: 'auto', padding: 50, margin: 20, height: '70vh', alignItems: 'center', justifyContent: 'center' }}>
                    {this.state.loading ? this.renderLoading() : this.renderMembershipTermPreview()}
                </div>
            </div>
        );
    }
}

const styles = {
    container: {
        backgroundColor: Colors.primary,
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    }
}