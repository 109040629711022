export default class ArrayHelper {
    static *chunkArray(arr, n) {
        for (let i = 0; i < arr.length; i += n) {
            yield arr.slice(i, i + n);
        }
    }

    static findCommonElementsInArrays(a1, a2) {
        return a1.some(item => a2.includes(item));
    }
}