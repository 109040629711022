import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button, FormControlLabel, FormLabel, Radio, RadioGroup, Tooltip, Select, MenuItem } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import ClearIcon from '@material-ui/icons/Clear';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DeleteIcon from '@material-ui/icons/Delete';
import Firestore from '../api/firebase/Firestore';
import moment from "moment-timezone";
import DefaultModal from '../components/DefaultModal';
import HeaderActionsWrapper from '../components/HeaderActionsWrapper';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import CurrencyInput from '../components/CurrencyInput';
import GeographicHelper from '../helper/GeographicHelper';
import TermHelper from '../helper/TermHelper';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import LiquidProfit from '../components/LiquidProfit';
import ImagePicker from '../components/ImagePicker';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import brLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from '@date-io/date-fns';
import IosSwitch from '../components/IosSwitch';
import DefaultSelect from '../components/DefaultSelect';
import CompanyTaskHelper from '../helper/CompanyTaskHelper';
import { TwitterPicker, CirclePicker } from 'react-color';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import AddBox from '@material-ui/icons/AddBox';
import FlashOn from '@material-ui/icons/FlashOn';
import FlashOff from '@material-ui/icons/FlashOff';
import SettingsIcon from '@material-ui/icons/Settings';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import DefaultSlider from '../components/DefaultSlider';
import InputMask from "react-input-mask";
import UnderConstruction from '../components/UnderConstruction';
import CurrencyHelper from '../helper/CurrencyHelper';
export default class CourtPage extends Component {

    state = {
        docs: [],
        name: '',
        rent_days: [
            { label: 'Segunda', checked: false, startHour: '00:00', endHour: '00:00', add: false, hours: [] },
            { label: 'Terça', checked: false, startHour: '00:00', endHour: '00:00', add: false, hours: [] },
            { label: 'Quarta', checked: false, startHour: '00:00', endHour: '00:00', add: false, hours: [] },
            { label: 'Quinta', checked: false, startHour: '00:00', endHour: '00:00', add: false, hours: [] },
            { label: 'Sexta', checked: false, startHour: '00:00', endHour: '00:00', add: false, hours: [] },
            { label: 'Sábado', checked: false, startHour: '00:00', endHour: '00:00', add: false, hours: [] },
            { label: 'Domingo', checked: false, startHour: '00:00', endHour: '00:00', add: false, hours: [] },
        ],
        copy_court_rent_days: [
            { label: 'Segunda', checked: false },
            { label: 'Terça', checked: false },
            { label: 'Quarta', checked: false },
            { label: 'Quinta', checked: false },
            { label: 'Sexta', checked: false },
            { label: 'Sábado', checked: false },
            { label: 'Domingo', checked: false },
        ],
        sports: [
            { title: 'Tennis', name: 'tennis' },
            { title: 'Padel', name: 'padel' },
            { title: 'Beach Tennis', name: 'beach_tennis' },
            { title: 'Squash', name: 'squash' },
            { title: 'Badminton', name: 'badminton' },
            { title: 'Vôlei de Praia', name: 'beach_volley' },
            { title: 'Futevôlei', name: 'foot_volley' },
            { title: 'Outros', name: 'others' }
        ],
        alternativeSportsInSelection: [],
        alternativeAllowedSports: [
            'beach_tennis',
            'beach_volley',
            'foot_volley'
        ],
        racquetRentSports: [
            'tennis',
            'padel',
            'beach_tennis',
            'squash',
            'badminton'
        ],
        images: [],
        alternative_sports: [],
        racquet_rent: {},
        light_hours: {},
        price: null,
        rawPrice: null,
        studentPrice: null,
        rawStudentPrice: null,
        forRent: false,
        exclusiveRent: false,
        racquetRent: false,
        active: true,
        loading: true,
        loadingModal: false,
        addModal: false,
        daysModal: false,
        altSportsModal: false,
        racquetRentModal: false,
        lightsModal: false,
        copyHoursModal: false,
        editId: null,
        sport: "tennis",
        type: "clay",
        indoor: false,
        scheduleDistanceLimit: '',
        inputError: false,
        copyCourtOrigin: '',
        copyCourtTarget: [],
        color: '#1273DE',
        settingsModal: false,
        dayDiscountModal: false,
        court_rent_settings: {},
        day_discount: {}
    }

    async getDocs() {
        let query = await Firestore.customQuery('court').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.price = data.price / 100;
            data.student_price = data.student_price / 100;
            data.sport_label = this.getSportLookup()[data.sport || `tennis`];
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs });
    }

    async syncRacquetRent() {
        let query = await Firestore.getDoc('company', SessionHelper.getData().id_company);
        let doc = query.data();

        if (!doc.racquet_rent) doc.racquet_rent = {};

        Object.keys(this.getSportLookup()).forEach((key) => {
            if (this.state.racquetRentSports.includes(key)) {
                if (!doc.racquet_rent[key]) doc.racquet_rent[key] = { price: 0, label: this.getSportLookup()[key] };
            }
        });

        let sorted = Object.keys(doc.racquet_rent).sort().reduce((acc, key) => ({
            ...acc, [key]: doc.racquet_rent[key]
        }), {})

        this.setState({ racquet_rent: sorted });
    }

    async syncLightHours() {
        let query = await Firestore.getDoc('company', SessionHelper.getData().id_company);
        let doc = query.data();

        if (!doc.light_hours) {
            doc.light_hours = {
                active: false,
                external_only: true,
                price: 0,
                start: '19:00',
                end: '06:00'
            };
        }

        this.setState({ light_hours: doc.light_hours });
    }

    async syncDayDiscount() {
        let query = await Firestore.getDoc('company', SessionHelper.getData().id_company);
        let doc = query.data();

        if (!doc.day_discount) {
            doc.day_discount = {
                active: false,
                days: {
                    monday: {
                        checked: false,
                        label: 'Segunda-feira',
                        start: '18:00',
                        end: '23:00',
                        amount: 0,
                        id_courts: []
                    },
                    tuesday: {
                        checked: false,
                        label: 'Terça-feira',
                        start: '18:00',
                        end: '23:00',
                        amount: 0,
                        id_courts: [],
                    },
                    wednesday: {
                        checked: false,
                        label: 'Quarta-feira',
                        start: '18:00',
                        end: '23:00',
                        amount: 0,
                        id_courts: []
                    },
                    thursday: {
                        checked: false,
                        label: 'Quinta-feira',
                        start: '18:00',
                        end: '23:00',
                        id_courts: []
                    },
                    friday: {
                        checked: false,
                        label: 'Sexta-feira',
                        start: '18:00',
                        end: '23:00',
                        amount: 0,
                        id_courts: []
                    },
                    saturday: {
                        checked: false,
                        label: 'Sábado',
                        start: '18:00',
                        end: '23:00',
                        amount: 0,
                        id_courts: []
                    },
                    sunday: {
                        checked: false,
                        label: 'Domingo',
                        start: '18:00',
                        end: '23:00',
                        amount: 0,
                        id_courts: []
                    },
                },
            };
        }

        this.setState({ day_discount: doc.day_discount });
    }

    async syncCourtRentSettings() {
        let query = await Firestore.getDoc('company', SessionHelper.getData().id_company);
        let doc = query.data();

        let defaults = {
            tennis: [2, 4],
            padel: [4, 4],
            beach_tennis: [4, 4],
            squash: [2, 4],
            badminton: [2, 4],
            beach_volley: [4, 4],
            foot_volley: [4, 4],
            others: [1, 1]
        }

        if (!doc.court_rent_settings) doc.court_rent_settings = {};
        if (!doc.court_rent_settings.rent_model) doc.court_rent_settings.rent_model = 'person_hour';

        Object.keys(this.getSportLookup()).forEach((key) => {
            if (!doc.court_rent_settings.player_range) doc.court_rent_settings.player_range = {};
            if (!doc.court_rent_settings.player_range[key]) doc.court_rent_settings.player_range[key] = defaults[key];
        });

        this.setState({ court_rent_settings: doc.court_rent_settings });
    }

    async componentDidMount() {
        await this.getDocs();
        await this.syncRacquetRent();
        await this.syncLightHours();
        await this.syncDayDiscount();
        await this.syncCourtRentSettings();

        this.setState({ loading: false });
    }

    async addCourt() {

        if (!this.state.inputError) {

            if (this.state.type && this.state.name) {

                let data = {
                    price: this.state.rawPrice * 100,
                    student_price: this.state.rawStudentPrice * 100,
                    name: this.state.name,
                    forRent: this.state.forRent,
                    exclusiveRent: this.state.exclusiveRent,
                    racquetRent: this.state.racquetRent && this.state.racquetRentSports.includes(this.state.sport),
                    active: this.state.active,
                    id_company: SessionHelper.getData().id_company,
                    images: this.state.images,
                    type: this.state.type,
                    sport: this.state.sport,
                    alternative_sports: this.state.alternativeAllowedSports.includes(this.state.sport) ? this.state.alternative_sports : [],
                    indoor: this.state.indoor,
                    schedule_distance_limit: this.state.scheduleDistanceLimit,
                    color: this.state.color
                };

                let rentDays = [];

                this.state.rent_days.forEach((rent, key) => {

                    rentDays.push({
                        label: rent.label,
                        checked: rent.checked,
                        hours: rent.hours,
                    });
                });

                data.rent_days = rentDays;

                try {
                    this.setState({ loadingModal: true });

                    if (SessionHelper.getData().company.coordinates) {
                        await Firestore.geoInsert(data, 'court',
                            GeographicHelper.getLatLngFromGeopoint(SessionHelper.getData().company.coordinates).lat,
                            GeographicHelper.getLatLngFromGeopoint(SessionHelper.getData().company.coordinates).lng
                        );
                    } else {
                        await Firestore.insert(data, 'court');
                    }


                    toast.success("Cadastrado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    await this.setState({
                        addModal: false, name: '', rawPrice: null, price: null, studentPrice: null, indoor: false, rawStudentPrice: null, forRent: false, exclusiveRent: false, racquetRent: false, active: true, alternative_sports: [], images: [], rent_days: [
                            { label: 'Segunda', checked: false, startHour: '00:00', endHour: '00:00', add: false, hours: [] },
                            { label: 'Terça', checked: false, startHour: '00:00', endHour: '00:00', add: false, hours: [] },
                            { label: 'Quarta', checked: false, startHour: '00:00', endHour: '00:00', add: false, hours: [] },
                            { label: 'Quinta', checked: false, startHour: '00:00', endHour: '00:00', add: false, hours: [] },
                            { label: 'Sexta', checked: false, startHour: '00:00', endHour: '00:00', add: false, hours: [] },
                            { label: 'Sábado', checked: false, startHour: '00:00', endHour: '00:00', add: false, hours: [] },
                            { label: 'Domingo', checked: false, startHour: '00:00', endHour: '00:00', add: false, hours: [] }]
                    });


                    if (!this.state.docs.length) {

                        CompanyTaskHelper.completeTask('first_court', this.props.tasks);
                    }

                    await this.getDocs();

                    this.setState({ loadingModal: false });

                } catch (error) {
                    toast.error("Houve um problema ao cadastrar", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    this.setState({ loadingModal: false });
                }

            } else {

                toast.warn('Preencha todos os campos corretamente');
            }
        } else {

            toast.warn("Preencha todos os campos corretamente", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    async saveImages(files, id) {
        if (files.length) {
            await Firestore.update({ images: files }, 'court', id);
        }
    }

    handleCopyCourtRentDays() {

        this.setState({
            copyHoursModal: true, copy_court_rent_days: [
                { label: 'Segunda', checked: true },
                { label: 'Terça', checked: true },
                { label: 'Quarta', checked: true },
                { label: 'Quinta', checked: true },
                { label: 'Sexta', checked: true },
                { label: 'Sábado', checked: true },
                { label: 'Domingo', checked: true },
            ]
        });
    }

    getDaysIndex() {
        return [
            { key: 'monday' },
            { key: 'tuesday' },
            { key: 'wednesday' },
            { key: 'thursday' },
            { key: 'friday' },
            { key: 'saturday' },
            { key: 'sunday' },
        ];
    }

    detailPanel(data) {
        let docs = [];
        Object.assign(docs, this.state.docs);
        let doc = docs[docs.indexOf(data)];

        return (
            <div style={{ padding: 25, width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', borderTop: '1px solid lightgrey', borderBottom: '1px solid lightgrey', paddingTop: 25, paddingBottom: 25, color: Colors.primary }}>
                    <div style={{ fontWeight: 'bold', marginLeft: 3, fontSize: 18 }}>{'Detalhes da quadra'}</div>
                </div>
                <FormLabel style={{ marginTop: 18, fontWeight: '800' }} component="legend">Imagens da quadra</FormLabel>
                <FormLabel style={{ fontSize: 14, marginTop: 5 }} component="legend">Estas imagens serão exibidas ao usuário na página de aluguel de quadra. A primeira imagem é a principal.</FormLabel>
                <ImagePicker imageWidth={300} imageHeight={300} onUploadStart={() => { this.setState({ loading: true }) }} onUploadEnd={() => { this.setState({ loading: false }) }} onUpload={(files) => {
                    doc.images = files;
                    this.setState({ docs });
                    this.saveImages(files, doc.id);
                }} images={doc.images ? doc.images : []} folder={'court_images'} multiple={true} />
            </div>
        )
    }

    handleRentDayChange(value, key) {

        let rentDays = this.state.rent_days;
        rentDays[key].checked = value;

        this.setState({ rent_days: rentDays });
    }

    handleCopyRentDayChange(value, key) {

        let rentDays = this.state.copy_court_rent_days;
        rentDays[key].checked = value;

        this.setState({ copy_court_rent_days: rentDays });
    }

    renderSurfaces() {

        let sport = this.state.sport;

        return (
            <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} aria-label="type" name="type" value={this.state.type} onChange={(evt) => { this.setState({ type: evt.target.value }) }}>
                {sport === 'tennis' || sport === 'badminton' ? <FormControlLabel value="clay" control={<Radio style={{ color: Colors.primary }} />} label="Saibro" /> : null}
                {sport === 'tennis' || sport === 'badminton' || sport === 'padel' || sport === 'squash' ? <FormControlLabel value="hard" control={<Radio style={{ color: Colors.primary }} />} label="Dura" /> : null}
                {sport === 'tennis' || sport === 'badminton' ? <FormControlLabel value="grass" control={<Radio style={{ color: Colors.primary }} />} label="Grama" /> : null}
                {sport === 'tennis' || sport === 'badminton' || sport === 'padel' ? <FormControlLabel value="artificial_grass" control={<Radio style={{ color: Colors.primary }} />} label="Grama Artificial" /> : null}
                {sport === 'padel' ? <FormControlLabel value="synthetic_resin" control={<Radio style={{ color: Colors.primary }} />} label="Resina Sintética" /> : null}
                {sport === 'beach_tennis' || sport === 'foot_volley' || sport === 'beach_volley' ? <FormControlLabel value="sand" control={<Radio style={{ color: Colors.primary }} />} label="Areia" /> : null}
                {sport === 'padel' ? <FormControlLabel value="porous_concrete" control={<Radio style={{ color: Colors.primary }} />} label="Concreto Poroso" /> : null}
                {sport === 'others' ? <FormControlLabel value="others" control={<Radio style={{ color: Colors.primary }} />} label="Outras" /> : null}
            </RadioGroup>
        )
    }

    async handleSportChange(value) {
        if (value !== 'beach_tennis') this.setState({ alternative_sports: [] });

        let type = '';

        if (value === 'beach_tennis' || value === 'beach_volley' || value === 'foot_volley') {

            type = 'sand';

        } else if (value === 'squash' || value === 'padel') {

            type = 'hard';

        } else if (value === 'tennis' || value === 'badminton') {

            type = 'clay';

        } else if (value === 'others') {

            type = 'others';

        }

        await this.setState({ sport: value, type: type });
        if (this.state.alternativeAllowedSports.includes(value)) await this.getAlternativeSportsSelect(value);
    }

    addModal() {

        if (this.state.addModal) {

            return (
                <div>
                    <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ name: v }) }} label={'Nome'} />
                    <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                        <DefaultSelect value={this.state.sport} id={'sport_selection'} valueField={'name'} displayField={'title'} onChange={(v) => { this.handleSportChange(v.target.value) }} docs={this.state.sports} label={'Esporte'} />
                    </div>

                    {this.state.alternativeAllowedSports.includes(this.state.sport) && this.state.alternativeSportsInSelection.length > 0 ?
                        <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                            <DefaultSelect multiple value={this.state.alternative_sports} id={'alt_sport_selection'} valueField={'name'} displayField={'title'} onChange={(v) => { this.setState({ alternative_sports: v.target.value }); }} docs={this.state.alternativeSportsInSelection} label={'Esportes Alternativos'} />
                        </div>
                        : null}

                    <div style={{ paddingTop: 8 }}>
                        <FormLabel component="legend">Tipo de Superfície</FormLabel>
                        {this.renderSurfaces()}
                    </div>

                    <FormLabel style={{ marginTop: 18, fontWeight: '800' }} component="legend">Imagens da quadra</FormLabel>
                    <FormLabel style={{ fontSize: 14, marginTop: 5 }} component="legend">Estas imagens serão exibidas ao usuário na página de aluguel de quadra. A primeira imagem é a principal.</FormLabel>
                    <ImagePicker imageWidth={300} imageHeight={300} onUploadStart={() => { this.setState({ loadingModal: true }) }} onUploadEnd={() => { this.setState({ loadingModal: false }) }} onUpload={(files) => {
                        this.setState({ images: files });
                    }} images={this.state.images} folder={'court_images'} multiple={true} />

                    <div style={{ width: '100%', marginTop: 15 }}>
                        <IosSwitch label="Quadra Coberta" value="indoor" checked={this.state.indoor} onChange={(v) => { this.setState({ indoor: v }) }} />
                    </div>

                    <IosSwitch label="Ativo" value="active" checked={this.state.active} onChange={(v) => { this.setState({ active: v }) }} />

                    <div style={{ width: '100%' }}>
                        <IosSwitch label="Disponível para Locação" value="for_rent" checked={this.state.forRent} onChange={(v) => { this.setState({ forRent: v }) }} />
                    </div>

                    {this.state.forRent ?
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            {this.state.racquetRentSports.includes(this.state.sport) ?
                                <IosSwitch label={`Oferecer opção de alugar raquetes`} value="racquetRent" checked={this.state.racquetRent} onChange={(v) => { this.setState({ racquetRent: v }) }} />
                                : null}
                            <IosSwitch label={`Locação apenas para ${TermHelper.getestablishmentTypeLabel(SessionHelper.getData().company.establishment_type).plural}`} value="exclusive_rent" checked={this.state.exclusiveRent} onChange={(v) => { this.setState({ exclusiveRent: v }) }} />
                        </div>
                        : null}

                    <div style={{ width: '100%' }}>
                        <FormLabel style={{ marginTop: 18, fontWeight: '800', marginBottom: 10 }} component="legend">Cor da Quadra na Agenda</FormLabel>
                        <CirclePicker color={this.state.color} onChangeComplete={(color) => { this.setState({ color: color.hex }) }} colors={Colors.pickerColors} />
                    </div>

                    {this.state.forRent ?

                        <div>
                            <div style={{ paddingTop: 20, display: 'flex', flexDirection: 'row', color: 'orange', alignItems: 'center' }}><InfoRoundedIcon style={{ paddingRight: 5 }} />{'Vazio ou R$ 0,00 significa gratuíto'}</div>

                            <CurrencyInput required onError={(error, msg) => { this.setState({ inputError: error }); }} label={`Valor de Locação por Hora${this.state.court_rent_settings.rent_model === 'person_hour' ? ` e Pessoa` : ``}`} onChange={(values) => { const { formattedValue, value } = values; this.setState({ price: formattedValue, rawPrice: value }) }} value={this.state.price} />
                            <LiquidProfit price={this.state.rawPrice * 100} />
                            <LiquidProfit price={this.state.rawPrice * 100} type={'pix'} />

                            <CurrencyInput required onError={(error, msg) => { this.setState({ inputError: error }) }} label={`Valor de Locação por Hora${this.state.court_rent_settings.rent_model === 'person_hour' ? ` e Pessoa` : ``} para ${TermHelper.getestablishmentTypeLabel(SessionHelper.getData().company.establishment_type).plural}`} onChange={(values) => { const { formattedValue, value } = values; this.setState({ studentPrice: formattedValue, rawStudentPrice: value }) }} value={this.state.studentPrice} />
                            <LiquidProfit price={this.state.rawStudentPrice * 100} />
                            <LiquidProfit price={this.state.rawStudentPrice * 100} type={'pix'} />

                            <div style={{ paddingTop: 10 }} />
                            <DefaultInput number required onError={(error, msg) => { this.setState({ inputError: error }) }} onChange={(v) => { this.setState({ scheduleDistanceLimit: v }) }} label={'Distância máxima de agendamentos ("3" permite locações de até 3 semanas para frente da data atual)'} />

                            <FormLabel style={{ paddingTop: 28, paddingBottom: 18, fontSize: 18 }} component="legend">Para configurar horários de locação, depois de cadastrar clique em Ações {'>'} "<AccessTimeIcon style={{ fontSize: 20 }}/>" </FormLabel>
                        </div>

                        : null}

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.addCourt() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                        <DefaultButton onClick={() => { this.setState({ addModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    renderDaysSelection() {

        return (

            <div>
                <FormLabel style={{ marginTop: 40, marginBottom: 10 }} component="legend">Dias para Replicar</FormLabel>
                {
                    this.state.copy_court_rent_days.map((rent, key) => {

                        return (
                            <div>
                                <IosSwitch label={rent.label} value={rent.label} checked={rent.checked} onChange={(v) => { this.handleCopyRentDayChange(v, key) }} />
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    copyHoursModal() {

        if (this.state.copyHoursModal) {

            return (
                <div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', fontWeight: 'bold', marginBottom: 50, marginTop: 15 }}>
                            <InfoRoundedIcon style={{ fontSize: 40, marginRight: 8 }} />
                            <div>
                                Ao confirmar, todos os horários da quadra de origem serão replicados para as quadras de destino, esse procedimento é irreversível, faça com atenção.
                            </div>
                        </div>
                        <DefaultSelect search={true} searchField={'name'} id={'court_selection'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ copyCourtOrigin: v.target.value }) }} value={this.state.copyCourtOrigin} docs={this.state.docs} label={'Quadra de Origem'} />
                        <div style={{ border: '0.3px solid lightgrey', boxShadow: Colors.boxShadow, width: 75, height: 75, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin: 20, borderRadius: 75 / 2 }}>
                            <ArrowDownwardIcon style={{ fontSize: 70, color: Colors.primary }} />
                        </div>
                        <DefaultSelect multiple search={true} searchField={'name'} id={'court_selection_target'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ copyCourtTarget: v.target.value }) }} value={this.state.copyCourtTarget} docs={this.state.docs} label={'Quadras de Destino'} />
                    </div>
                    {this.renderDaysSelection()}
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50, width: '100%' }}>
                        <Button onClick={() => { this.copyHours() }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ copyHoursModal: false }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    async copyHours() {

        try {

            if (this.state.copyCourtOrigin && this.state.copyCourtTarget.length) {

                const originCourtId = this.state.copyCourtOrigin;
                let originCourt = this.state.docs.find(item => item.id === originCourtId);

                let copyCourtRentDays = this.state.copy_court_rent_days;

                if (originCourt) {

                    if (originCourt.rent_days) {

                        this.setState({ loadingModal: true });

                        const targetCourts = this.state.copyCourtTarget;

                        for (const id of targetCourts) {

                            let targetCourt = this.state.docs.find(item => item.id === id);
                            let targetRentDays = targetCourt.rent_days;

                            for (let index = 0; index < copyCourtRentDays.length; index++) {

                                const day = copyCourtRentDays[index];

                                if (day.checked && targetRentDays[index] && originCourt.rent_days[index]) {

                                    targetRentDays[index] = originCourt.rent_days[index];
                                }
                            }

                            await Firestore.update({ rent_days: targetRentDays, schedule_distance_limit: originCourt.schedule_distance_limit }, 'court', id);
                        }

                        await this.getDocs();
                        toast.success('Horários replicados com sucesso');

                        this.setState({ loadingModal: false, copyCourtOrigin: '', copyCourtTarget: [], copyHoursModal: false });

                    } else {

                        toast.warn('A quadra selecionada não possui nenhum horário para replicar');
                    }

                } else {

                    toast.warn('Quadra de origem não encontrada');
                }

            } else {

                toast.warn('Preencha todos os campos corretamente');
            }

        } catch (error) {

            this.setState({ loadingModal: false });
            toast.error('Houve um problema ao replicar os horários');
        }
    }

    async saveDays() {

        if (this.state.editId) {

            try {

                this.setState({ loadingModal: true });

                let rentDays = [];

                this.state.rent_days.forEach((rent, key) => {

                    rentDays.push({
                        label: rent.label,
                        checked: rent.checked,
                        hours: rent.hours,
                    });
                });

                await Firestore.update({ rent_days: rentDays }, 'court', this.state.editId);

                toast.success('Dias atualizados com sucesso');

                this.setState({
                    daysModal: false, days: null, editId: null, loadingModal: false, rent_days: [
                        { label: 'Segunda', checked: false, startHour: '00:00', endHour: '00:00', add: false, hours: [] },
                        { label: 'Terça', checked: false, startHour: '00:00', endHour: '00:00', add: false, hours: [] },
                        { label: 'Quarta', checked: false, startHour: '00:00', endHour: '00:00', add: false, hours: [] },
                        { label: 'Quinta', checked: false, startHour: '00:00', endHour: '00:00', add: false, hours: [] },
                        { label: 'Sexta', checked: false, startHour: '00:00', endHour: '00:00', add: false, hours: [] },
                        { label: 'Sábado', checked: false, startHour: '00:00', endHour: '00:00', add: false, hours: [] },
                        { label: 'Domingo', checked: false, startHour: '00:00', endHour: '00:00', add: false, hours: [] }]
                });

                this.getDocs();

            } catch (error) {

                toast.error('Erro ao atualizar dias');
                this.setState({ daysModal: false, days: null, editId: null, loadingModal: false });
            }

        } else {

            toast.warn('Preencha todos os campos');
        }
    }

    addHour(key) {

        let rentDays = this.state.rent_days;

        if (rentDays[key].startHour && rentDays[key].endHour) {

            rentDays[key].hours.push({
                start: moment(rentDays[key].startHour).format('HH:mm'),
                end: moment(rentDays[key].endHour).format('HH:mm'),
                priceType: rentDays[key].priceType,
                price: CurrencyHelper.moneyToCents(rentDays[key].price)
            });

            rentDays[key].add = false;
            rentDays[key].startHour = new Date();
            rentDays[key].endHour = new Date();
            rentDays[key].priceType = 'default';
            rentDays[key].price = null;

            if (rentDays[key] && rentDays[key].hours && rentDays[key].hours.length) {
                rentDays[key].hours.sort((a, b) => {
                    if (a.start < b.start) { return -1; }
                    if (a.start > b.start) { return 1; }
                    return 0;
                });
            }

            this.setState({ rent_days: rentDays });

        } else {

            toast.warn('Preencha todos os campos corretamente');
        }
    }

    getPreviousRentHour(rent) {
        let interval = 60;
        let start = moment();
        let end = moment().add(interval, 'minute');

        if (rent.checked && rent.hours && rent.hours.length > 0) {
            let hour = rent.hours[rent.hours.length - 1];

            if (hour.end && hour.start && hour.start.length == 5 && hour.end.length == 5) {
                let auxStart = moment().set({ 'hour': hour.start.replace(/:\d{2}/gm, ``), 'minute': hour.start.replace(/\d{2}:/gm, ``) });

                start = moment().set({ 'hour': hour.end.replace(/:\d{2}/gm, ``), 'minute': hour.end.replace(/\d{2}:/gm, ``) });
                interval = Math.abs(moment(start).diff(moment(auxStart), 'minute'));
                end = moment(start).set({ 'hour': hour.end.replace(/:\d{2}/gm, ``), 'minute': hour.end.replace(/\d{2}:/gm, ``) }).add(interval, 'minute');
            }
        }

        return { start, end };
    }

    getLastDayCheckedKey(rent, fromKey) {
        if (fromKey < 0) return -1;

        let lastCheckedKey = fromKey;
        if (rent[lastCheckedKey] && rent[lastCheckedKey].checked) return lastCheckedKey;

        for (let i = fromKey; i > 0; i--) {
            if (rent[i] && rent[i].checked) {
                lastCheckedKey = i;
                break;
            }
        }

        return lastCheckedKey;
    }

    daysModal() {

        if (this.state.daysModal) {
            return (
                <div>

                    {
                        this.state.rent_days.map((rent, key) => {
                            let lastCheckedKey = this.getLastDayCheckedKey(this.state.rent_days, key - 1);

                            return (
                                <div>
                                    <IosSwitch label={rent.label} value={rent.label} checked={rent.checked} onChange={(v) => { this.handleRentDayChange(v, key) }} />

                                    {
                                        rent.checked ? rent.hours.map((hour, hourKey) => {

                                            return (
                                                <div key={`${key}-${hourKey}-rent-hour-choise`} style={{ padding: 10, marginBottom: 8, marginTop: 8, borderRadius: 5, fontWeight: 'bold', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', border: `1px solid ${Colors.disabled}` }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 200 }}>
                                                        <AccessTimeIcon style={{ marginRight: 5 }} />
                                                        {`De ${hour.start} até ${hour.end}`}
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: 240 }}>
                                                        {hour.priceType && hour.priceType === 'custom' ? `(Personalizado) ${CurrencyHelper.centsToMoney(hour.price, true)}` : `Padrão da Quadra`}
                                                    </div>
                                                    <Tooltip title={'Remover horário'}>
                                                        <DeleteIcon onClick={() => { let rentDays = this.state.rent_days; rentDays[key].hours.splice(hourKey, 1); this.setState({ rent_days: rentDays }) }} style={{ marginLeft: 5, color: Colors.error, cursor: 'pointer' }} />
                                                    </Tooltip>
                                                </div>
                                            )
                                        }) : null
                                    }

                                    {this.renderEditCourtHour(rent, key)}

                                    {rent.checked && !rent.hours.length ? <div style={{ color: 'grey', paddingTop: 10, paddingBottom: 10 }}>{'Nenhum horário cadastrado'}</div> : null}

                                    {
                                        rent.checked && !rent.add ?
                                            <div style={{ marginTop: 10, marginBottom: 10, display: 'flex', flexDirection: 'row' }}>
                                                <DefaultButton
                                                    leftIcon={<AddIcon style={{ marginRight: 5 }} />}
                                                    onClick={() => {
                                                        let rentDays = this.state.rent_days;
                                                        let hours = this.getPreviousRentHour(rent);

                                                        rentDays[key].add = true;
                                                        rentDays[key].startHour = hours.start;
                                                        rentDays[key].endHour = hours.end;
                                                        rentDays[key].priceType = 'default';
                                                        rentDays[key].price = null;
                                                        this.setState({ rent_days: rentDays });
                                                    }}
                                                    title={'Adicionar Horário'}
                                                />
                                                {key > 0 && lastCheckedKey > -1 ?
                                                    <div style={{ marginLeft: 15 }}>
                                                        <DefaultButton
                                                            leftIcon={<FlashOn style={{ marginRight: 5 }} />}
                                                            textColor={Colors.dark}
                                                            color={Colors.aceYellow}
                                                            onClick={() => {
                                                                let rentDays = this.state.rent_days;
                                                                let clone = {};
                                                                Object.assign(clone, this.state.rent_days[lastCheckedKey]);
                                                                clone = { ...clone, label: rentDays[key].label };
                                                                rentDays[key] = clone;
                                                                this.setState({ rent_days: rentDays });
                                                            }}
                                                            title={`Copiar horários de ${this.state.rent_days[lastCheckedKey].label}`}
                                                        />
                                                    </div>
                                                    : null}
                                            </div> : null
                                    }

                                </div>
                            )
                        })
                    }

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.saveDays() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'SALVAR'} />
                        <DefaultButton onClick={() => { this.setState({ daysModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    renderEditCourtHour(rent, key) {
        return (
            <div>
                {rent.checked && rent.add ?
                    <div style={{ border: `1px solid ${Colors.disabled}`, padding: 25, borderRadius: 5 }}>
                        <RadioGroup
                            style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}
                            aria-label="checkPriceType"
                            name="checkPriceType"
                            value={rent.priceType}
                            onChange={(evt) => {
                                let rentDays = this.state.rent_days;
                                rentDays[key].priceType = evt.target.value;

                                if (evt.target.value == 'default') this.setState({ formattedHourValue: null });
                                this.setState({ rent_days: rentDays })
                            }}
                        >
                            <FormControlLabel value={'default'} control={<Radio style={{ color: Colors.primary }} />} label={'Usar Valor da quadra'} />
                            <FormControlLabel value={'custom'} control={<Radio style={{ color: Colors.primary }} />} label={'Valor personalizado'} />
                        </RadioGroup>

                        {
                            rent.priceType === 'default' ?
                                <div style={{ border: `1px solid orange`, borderRadius: 5, marginBottom: 25 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', borderBottom: `1px solid orange`, padding: 10 }}>
                                        <div style={{ fontWeight: 'bold', color: 'orange', width: '20%' }}>Não Aluno:</div>
                                        <div style={{ width: '30%', marginRight: 15 }}><LiquidProfit price={(this.state.rawPrice * (moment(rent.endHour).diff(rent.startHour, 'hour', true) || 1)) * 100} /></div>
                                        <div style={{ width: '30%' }}><LiquidProfit price={(this.state.rawPrice * (moment(rent.endHour).diff(rent.startHour, 'hour', true) || 1)) * 100} type={'pix'} /></div>
                                        <div style={{ fontWeight: 'bold', color: 'orange', width: '20%', textAlign: 'right' }}>({this.state.court_rent_settings.rent_model === 'person_hour' ? `Por hora por pessoa` : `Por hora`})</div>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', padding: 10 }}>
                                        <div style={{ fontWeight: 'bold', color: 'orange', width: '20%' }}>Aluno:</div>
                                        <div style={{ width: '30%', marginRight: 15 }}><LiquidProfit price={(this.state.rawStudentPrice * (moment(rent.endHour).diff(rent.startHour, 'hour', true) || 1)) * 100} /></div>
                                        <div style={{ width: '30%' }}><LiquidProfit price={(this.state.rawStudentPrice * (moment(rent.endHour).diff(rent.startHour, 'hour', true) || 1)) * 100} type={'pix'} /></div>
                                        <div style={{ fontWeight: 'bold', color: 'orange', width: '20%', textAlign: 'right' }}>({this.state.court_rent_settings.rent_model === 'person_hour' ? `Por hora por pessoa` : `Por hora`})</div>
                                    </div>
                                </div> :
                                <div>
                                    <CurrencyInput
                                        required
                                        onError={(error, msg) => { this.setState({ inputError: error }); }}
                                        label={`Valor personalizado para este horário`}
                                        onChange={(values) => {
                                            const { formattedValue, value } = values;
                                            let rentDays = this.state.rent_days;
                                            rentDays[key].price = Math.abs(value);
                                            this.setState({ formattedHourValue: formattedValue, rent_days: rentDays })
                                        }}
                                        value={this.state.hourPrice}
                                    />

                                    <div style={{ border: `1px solid orange`, borderRadius: 5, marginBottom: 25 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', padding: 10 }}>
                                            <div style={{ fontWeight: 'bold', color: 'orange', width: '20%' }}>Valor único:</div>
                                            <div style={{ width: '35%', marginRight: 15 }}><LiquidProfit price={(this.state.rawHourPrice * (moment(rent.endHour).diff(rent.startHour, 'hour', true) || 1)) * 100} /></div>
                                            <div style={{ width: '35%' }}><LiquidProfit price={(this.state.rawHourPrice * (moment(rent.endHour).diff(rent.startHour, 'hour', true) || 1)) * 100} type={'pix'} /></div>
                                        </div>
                                    </div>
                                </div>
                        }

                        <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                            <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    style={{ width: '100%', marginBottom: 10, paddingRight: 5 }}
                                    invalidDateMessage={false}
                                    format={'HH:mm'}
                                    autoOk={true}
                                    label="Início"
                                    cancelLabel={'Cancelar'}
                                    okLabel={'Confirmar'}
                                    onChange={(v) => {
                                        if (moment(v).isValid()) {
                                            let rentDays = this.state.rent_days;
                                            rentDays[key].startHour = v;
                                            rentDays[key].endHour = moment(v).add(1, 'hours').toDate();
                                            this.setState({ rent_days: rentDays })
                                        }
                                    }}
                                    value={rent.startHour}
                                />
                                <KeyboardTimePicker
                                    style={{ width: '100%', marginBottom: 10, paddingLeft: 5 }}
                                    invalidDateMessage={false}
                                    format={'HH:mm'}
                                    autoOk={true}
                                    label="Fim"
                                    onChange={(v) => {
                                        if (moment(v).isValid() && moment(v).isAfter(rent.startHour)) {
                                            let rentDays = this.state.rent_days;
                                            rentDays[key].endHour = v;
                                            this.setState({ rent_days: rentDays })
                                        } else {
                                            if (moment(v).isValid()) {
                                                toast.warn('Horário de final deve ser depois de horário de ínicio');
                                            }
                                        }
                                    }}
                                    cancelLabel={'Cancelar'}
                                    okLabel={'Confirmar'}
                                    value={rent.endHour}
                                />
                            </MuiPickersUtilsProvider>
                            <div style={{ paddingLeft: 15 }}>
                                <DefaultButton
                                    color={Colors.success}
                                    leftIcon={<CheckIcon style={{ marginRight: 5 }} />}
                                    onClick={() => {
                                        if (rent.priceType === 'custom' && rent.price === null) return toast.info('Preencha o valor personalizado');
                                        this.addHour(key);
                                    }}
                                    title={'Adicionar'}
                                /></div>
                            <div style={{ paddingLeft: 5 }}><DefaultButton color={Colors.error} leftIcon={<ClearIcon style={{ marginRight: 5 }} />} onClick={() => { let rentDays = this.state.rent_days; rentDays[key].add = false; this.setState({ rent_days: rentDays }) }} title={'Cancelar'} /></div>
                        </div>
                    </div>
                    : null}
            </div>
        );
    }

    async saveRacquetRent() {
        this.setState({ loadingModal: true });

        try {
            await Firestore.update({ racquet_rent: this.state.racquet_rent }, 'company', SessionHelper.getData().id_company);
            toast.success('Salvo com sucesso!');
        } catch (e) {
            toast.error('Ocorreu um erro ao atualizar o valor do aluguel das raquetes, tente novamente mais tarde.');
        }

        await this.syncRacquetRent();

        this.setState({ racquetRentModal: false, loadingModal: false });
    }

    racquetRentModal() {
        if (this.state.racquetRentModal) {
            return (
                <div>
                    <div style={{ paddingTop: 20, display: 'flex', flexDirection: 'row', color: Colors.contrast, alignItems: 'center', fontSize: 16, marginBottom: 30 }}>
                        <InfoRoundedIcon style={{ paddingRight: 5, fontSize: 28 }} />
                        Para ativar o aluguel de raquetes de uma quadra, edite uma quadra existente ou adicione uma nova, e marque a opção "Aluguel de Raquetes" na quadra. O aluguel de raquetes é por esporte da quadra! Assim clientes que ainda não possuem raquetes também podem jogar :)
                    </div>

                    {Object.keys(this.state.racquet_rent).map(key => (
                        <CurrencyInput required onError={(error, msg) => { this.setState({ inputError: error }); }} label={`Valor de aluguel de raquete de ${this.state.racquet_rent[key].label}`} onChange={(values) => { const { formattedValue, value } = values; this.setState({ racquet_rent: { ...this.state.racquet_rent, [key]: { ...this.state.racquet_rent[key], price: value * 100 } } }) }} value={this.state.racquet_rent[key].price > 0 ? this.state.racquet_rent[key].price / 100 : this.state.racquet_rent[key].price} />
                    ))}

                    <div style={{ paddingTop: 20, display: 'flex', flexDirection: 'row', color: Colors.danger, alignItems: 'center', fontSize: 16 }}>
                        <InfoRoundedIcon style={{ paddingRight: 5, fontSize: 28 }} />
                        O valor é por raquete, se o cliente selecionar mais de uma raquete, será sempre multiplicado o valor pela quantidade de raquetes, o máximo de raquetes que ele pode selecionar é a quantidade de pesoas do aluguel.
                    </div>

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.saveRacquetRent() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'SALVAR'} />
                        <DefaultButton onClick={() => { this.setState({ racquetRentModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    async saveLightHours() {
        this.setState({ loadingModal: true });

        try {
            await Firestore.update({ light_hours: this.state.light_hours }, 'company', SessionHelper.getData().id_company);
            toast.success('Salvo com sucesso!');
        } catch (e) {
            toast.error('Ocorreu um erro ao atualizar o valor adicional da iluminação, tente novamente mais tarde.');
        }

        await this.syncLightHours();

        this.setState({ lightsModal: false, loadingModal: false });
    }

    async saveSettings() {
        this.setState({ loadingModal: true });

        try {
            await Firestore.update({ court_rent_settings: this.state.court_rent_settings }, 'company', SessionHelper.getData().id_company);
            toast.success('Salvo com sucesso!');
        } catch (e) {
            toast.error('Ocorreu um erro ao atualizar as configurações, tente novamente mais tarde.');
        }

        await this.syncCourtRentSettings();

        this.setState({ settingsModal: false, loadingModal: false });
    }

    async saveDayDiscount() {
        this.setState({ loadingModal: true });

        try {
            let canUpdate = false;

            if (!this.state.day_discount.active) {
                canUpdate = true;
            } else {
                let activeCount = 0;
                let hasMissingFields = false;

                Object.keys(this.state.day_discount.days).forEach(key => {
                    let day = this.state.day_discount.days[key];
                    if (day.checked) {
                        activeCount++;

                        Object.keys(day).forEach(dayProps => {
                            if (!day[dayProps]) hasMissingFields = true;

                            if (dayProps === 'id_courts') if (day[dayProps].length < 1) hasMissingFields = true;
                        });
                    }
                });

                if (activeCount == 0) {
                    await this.setState({ day_discount: { ...this.state.day_discount, active: false } });
                    canUpdate = true;
                } else if (activeCount > 0 && !hasMissingFields) {
                    canUpdate = true;
                } else {
                    canUpdate = false;
                }
            }

            if (canUpdate) {
                await Firestore.update({ day_discount: this.state.day_discount }, 'company', SessionHelper.getData().id_company);
                toast.success('Salvo com sucesso!');
            } else {
                toast.warn(`Preencha todos os campos dos dias marcados`);
                return this.setState({ loadingModal: false });
            }
        } catch (e) {
            toast.error('Ocorreu um erro ao atualizar, tente novamente mais tarde.');
        }

        await this.syncDayDiscount();

        this.setState({ dayDiscountModal: false, loadingModal: false });
    }

    isDevelopmentMode() {
        return '_self' in React.createElement('div');
    }

    dayDiscountModal() {
        if (this.state.dayDiscountModal) {
            return (
                <div>
                    <div style={{ marginBottom: 20, fontSize: 20 }}>
                        <FormLabel style={{ fontSize: 20 }}>Porcentagem de desconto por dia e horário</FormLabel>
                    </div>

                    <IosSwitch label={`Ativo`} onChange={(evt) => { this.setState({ day_discount: { ...this.state.day_discount, active: evt } }); }} checked={this.state.day_discount.active} />

                    {this.state.day_discount.active ?

                        <div style={{ paddingTop: 10 }}>
                            <FormLabel style={{ fontSize: 14, marginTop: 10, marginBottom: 3, fontWeight: '600' }} component="legend">Dias de semana (selecionado significa ativo)</FormLabel>

                            {this.getDaysIndex().map(dayIndex => {
                                let key = dayIndex.key;
                                let day = this.state.day_discount.days[key];

                                if (day.label) {
                                    return (
                                        <div style={{ paddingTop: 10, flexDirection: 'column', display: 'flex', maxWidth: 500 }}>
                                            <IosSwitch label={day.label} onChange={(evt) => { this.setState({ day_discount: { ...this.state.day_discount, days: { ...this.state.day_discount.days, [key]: { ...this.state.day_discount.days[key], checked: evt } } } }); }} checked={day.checked || false} />
                                            {day.checked ?
                                                <div style={{ paddingLeft: 25, paddingTop: 10, flexDirection: 'column', display: 'flex' }}>

                                                    {day.checked ?
                                                        <div>
                                                            <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: 15 }}>
                                                                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                                                    <KeyboardTimePicker
                                                                        style={{ width: '100%', marginBottom: 30, paddingRight: 5 }}
                                                                        invalidDateMessage={false}
                                                                        format={'HH:mm'}
                                                                        autoOk={true}
                                                                        label="Início do Horário do Desconto"
                                                                        cancelLabel={'Cancelar'}
                                                                        okLabel={'Confirmar'}
                                                                        onChange={(v) => {
                                                                            this.setState({ day_discount: { ...this.state.day_discount, days: { ...this.state.day_discount.days, [key]: { ...this.state.day_discount.days[key], start: moment(v).format('HH:mm') } } } });
                                                                        }}
                                                                        value={moment(day.start, 'HH:mm')}
                                                                    />
                                                                    <KeyboardTimePicker
                                                                        style={{ width: '100%', marginBottom: 30, paddingLeft: 5 }}
                                                                        invalidDateMessage={false}
                                                                        format={'HH:mm'}
                                                                        autoOk={true}
                                                                        label="Fim do Horário do Desconto"
                                                                        cancelLabel={'Cancelar'}
                                                                        okLabel={'Confirmar'}
                                                                        onChange={(v) => {
                                                                            this.setState({ day_discount: { ...this.state.day_discount, days: { ...this.state.day_discount.days, [key]: { ...this.state.day_discount.days[key], end: moment(v).format('HH:mm') } } } });
                                                                        }}
                                                                        value={moment(day.end, 'HH:mm')}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </div>

                                                            <div style={{ marginTop: 15, marginBottom: 15 }}>
                                                                <DefaultSelect
                                                                    search={true}
                                                                    multiple={true}
                                                                    searchField={'name'}
                                                                    id={'court_selection'}
                                                                    valueField={'id'}
                                                                    displayField={'name'}
                                                                    secondaryDisplay={'sport_label'}
                                                                    value={day.id_courts}
                                                                    onChange={(v) => {
                                                                        this.setState({ day_discount: { ...this.state.day_discount, days: { ...this.state.day_discount.days, [key]: { ...this.state.day_discount.days[key], id_courts: v.target.value } } } });
                                                                    }}
                                                                    docs={this.state.docs}
                                                                    label={`Quadras para aplicar o desconto de ${day.label}`}
                                                                />
                                                            </div>

                                                            <InputMask defaultValue={day.amount} onBlur={(v) => { this.setState({ day_discount: { ...this.state.day_discount, days: { ...this.state.day_discount.days, [key]: { ...this.state.day_discount.days[key], amount: parseInt(v) } } } }); }} mask="99%" maskPlaceholder={''} alwaysShowMask={true}>
                                                                <DefaultInput required label={`Desconto de ${day.label} em (%)`} />
                                                            </InputMask>
                                                        </div>
                                                        : null}
                                                </div>
                                                : null
                                            }
                                        </div>
                                    );
                                }
                            })}
                        </div>

                        : null}


                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.saveDayDiscount() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'SALVAR'} />
                        <DefaultButton onClick={() => { this.setState({ dayDiscountModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            );
        }
    }

    renderPersonHourRentModel() {
        return (
            <div>
                <div style={{ color: Colors.contrast, display: 'flex', flexDirection: 'row', fontWeight: 'bold', alignItems: 'center', marginBottom: 25, padding: 15, boxShadow: Colors.boxShadow, borderRadius: 5, border: '0.5px solid lightgrey' }}>
                    <InfoRoundedIcon style={{ fontSize: 35, marginRight: 8 }} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        No modelo por hora e pessoa: 4 pessoas a R$ 20,00 por pessoa por hora, durante uma hora, pagam R$ 80,00, mas 2 pessoas nessa mesma configuração pagariam R$ 40,00.
                    </div>
                </div>

                <div style={{ marginBottom: 20 }}>
                    <FormLabel style={{ fontSize: 20 }}>Mínimo e máximo de jogadores no aluguel por esporte</FormLabel>
                </div>

                {Object.keys(this.state.court_rent_settings.player_range).sort().map(sportKey => {
                    let sportLabel = (this.state.sports.find(sport => sportKey === sport.name)).title;
                    let range = this.state.court_rent_settings.player_range[sportKey];

                    return (
                        <div style={{ width: `93%`, marginTop: 10, marginBottom: 10, marginLeft: `2%` }}>
                            <FormLabel style={{ marginBottom: 5, fontSize: 18 }}>{sportLabel}</FormLabel>
                            <br />
                            <FormLabel style={{ marginBottom: 5, fontSize: 14 }}>Mínimo: {range[0]} ~ Máximo: {range[1]}</FormLabel>
                            <DefaultSlider
                                onChangeCommitted={
                                    (evt, value) => {
                                        this.setState({ court_rent_settings: { ...this.state.court_rent_settings, player_range: { ...this.state.court_rent_settings.player_range, [sportKey]: value } } });
                                    }
                                } defaultValue={range} value={range} max={4} min={1} />
                        </div>
                    );
                })}
            </div>
        );
    }

    renderHourRentModel() {
        return (
            <div>
                <div style={{ color: Colors.contrast, display: 'flex', flexDirection: 'row', fontWeight: 'bold', alignItems: 'center', marginBottom: 25, padding: 15, boxShadow: Colors.boxShadow, borderRadius: 5, border: '0.5px solid lightgrey' }}>
                    <InfoRoundedIcon style={{ fontSize: 35, marginRight: 8 }} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        No modelo por hora: Idependente de quantas pessoas estejam no aluguel de quadra, elas sempre pagarão o mesmo valor por hora. (Hora da quadra)
                    </div>
                </div>
            </div>
        );
    }

    settingsModal() {
        if (this.state.settingsModal) {
            return (
                <div>
                    <RadioGroup style={{ display: 'flex', flexDirection: 'row', marginBottom: 25, marginTop: 15 }} aria-label="internalSchedule" name="internalSchedule" value={this.state.court_rent_settings.rent_model} onChange={(evt) => { this.setState({ court_rent_settings: { ...this.state.court_rent_settings, rent_model: evt.target.value } }) }}>
                        <FormControlLabel value={'person_hour'} control={<Radio style={{ color: Colors.primary }} />} label={'Cobrar por hora e pessoa'} />
                        <FormControlLabel value={'hour'} control={<Radio style={{ color: Colors.primary }} />} label={'Cobrar por hora'} />
                    </RadioGroup>

                    {this.state.court_rent_settings.rent_model == 'hour' && this.renderHourRentModel()}
                    {this.state.court_rent_settings.rent_model == 'person_hour' && this.renderPersonHourRentModel()}

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.saveSettings() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'SALVAR'} />
                        <DefaultButton onClick={() => { this.setState({ settingsModal: false }, () => { this.syncCourtRentSettings() }); }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            );
        }
    }

    lightsModal() {
        if (this.state.lightsModal) {
            return (
                <div>
                    <div style={{ paddingTop: 20, display: 'flex', flexDirection: 'row', color: Colors.contrast, alignItems: 'center', fontSize: 16, marginBottom: 30 }}>
                        <InfoRoundedIcon style={{ paddingRight: 5, fontSize: 28 }} />
                        Se você cobra à mais, durante um certo horário para a iluminação das quadras, adione os horários e o valor aqui! ;)
                    </div>

                    <div style={{ width: '100%', marginTop: 15, marginBottom: 15 }}>
                        <IosSwitch label="Ativar cobrança adicional de iluminação" value="active" checked={this.state.light_hours.active} onChange={(v) => { this.setState({ light_hours: { ...this.state.light_hours, active: v } }) }} />
                    </div>

                    {this.state.light_hours.active ?
                        <div>
                            <div style={{ width: '100%', marginTop: 15, marginBottom: 15 }}>
                                <IosSwitch label="Cobrar adicional somente em quadras externas" value="external_only" checked={this.state.light_hours.external_only} onChange={(v) => { this.setState({ light_hours: { ...this.state.light_hours, external_only: v } }) }} />
                            </div>

                            <FormLabel>Valor adicional por hora</FormLabel>
                            <div style={{ marginTop: 10 }} />

                            <CurrencyInput required onError={(error, msg) => { this.setState({ inputError: error }); }} label={`Valor adicional para iluminação da quadra por hora`} onChange={(values) => { const { formattedValue, value } = values; this.setState({ light_hours: { ...this.state.light_hours, price: value * 100 } }) }} value={this.state.light_hours.price > 0 ? this.state.light_hours.price / 100 : this.state.light_hours.price} />
                            <div style={{ paddingTop: 5, paddingBottom: 10, display: 'flex', flexDirection: 'row', color: Colors.contrast, alignItems: 'center', fontSize: 16, marginBottom: 30 }}>
                                <InfoRoundedIcon style={{ paddingRight: 5, fontSize: 28 }} />
                                Aqui o valor é calculado com base em quantos minutos de iluminação o aluno utilizou, você aponta o valor da hora da iluminação e o sistema faz o resto! ;)
                            </div>

                            <FormLabel>Horários de inicio e fim da cobrança adicional</FormLabel>
                            <div style={{ marginTop: 10 }} />

                            <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <FlashOn style={{ fontSize: 42, marginRight: 10, color: Colors.success, marginTop: -30 }} />
                                    <KeyboardTimePicker
                                        style={{ width: '100%', marginBottom: 30, paddingRight: 5 }}
                                        invalidDateMessage={false}
                                        format={'HH:mm'}
                                        autoOk={true}
                                        label="Cobrar à valor partir de"
                                        cancelLabel={'Cancelar'}
                                        okLabel={'Confirmar'}
                                        onChange={(v) => {
                                            this.setState({ light_hours: { ...this.state.light_hours, start: moment(v).format('HH:mm') } });
                                        }}
                                        value={moment(this.state.light_hours.start, 'HH:mm')}
                                    />
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <FlashOff style={{ fontSize: 42, marginRight: 10, color: Colors.error, marginTop: -30 }} />
                                    <KeyboardTimePicker
                                        style={{ width: '100%', marginBottom: 30, paddingLeft: 5 }}
                                        invalidDateMessage={false}
                                        format={'HH:mm'}
                                        autoOk={true}
                                        label="Parar de cobrar à valor partir de"
                                        cancelLabel={'Cancelar'}
                                        okLabel={'Confirmar'}
                                        onChange={(v) => {
                                            this.setState({ light_hours: { ...this.state.light_hours, end: moment(v).format('HH:mm') } });
                                        }}
                                        value={moment(this.state.light_hours.end, 'HH:mm')}
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                        </div>
                        : null}

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.saveLightHours() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'SALVAR'} />
                        <DefaultButton onClick={() => { this.setState({ lightsModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    altSportsModal() {
        if (this.state.altSportsModal) {
            return (
                <div>
                    <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                        <DefaultSelect multiple value={this.state.alternative_sports} id={'alt_sport_selection'} valueField={'name'} displayField={'title'} onChange={(v) => { this.setState({ alternative_sports: v.target.value }) }} docs={this.state.alternativeSportsInSelection} label={'Esportes Alternativos'} />

                        <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                            <DefaultButton onClick={() => { this.saveAltSportsEdit() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'SALVAR'} />
                            <DefaultButton onClick={() => { this.setState({ altSportsModal: false, alternative_sports: [], editId: null }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                        </div>
                    </div>
                </div>
            )
        }
    }

    async saveAltSportsEdit() {
        this.setState({ loadingModal: true });

        try {
            await Firestore.update({ alternative_sports: this.state.alternative_sports || [] }, 'court', this.state.editId);

            let doc = this.state.docs.find(item => item.id === this.state.editId);
            doc.alternative_sports = this.state.alternative_sports || [];

            toast.success('Esportes alternativos alterados com sucesso!');
        } catch (e) {
            toast.error('Ocorreu um erro ao atualizar os esportes alternativos da quadra. Tente novamente mais tarde ou contate o suporte.');
        }

        this.setState({ altSportsModal: false, loadingModal: false, alternative_sports: [], editId: null });
    }

    getSportLookup() {
        return {
            'tennis': 'Tennis',
            'padel': 'Padel',
            'beach_tennis': 'Beach Tennis',
            'squash': 'Squash',
            'badminton': 'Badminton',
            'beach_volley': 'Vôlei de Praia',
            'foot_volley': 'Futevôlei',
            'others': 'Outros'
        }
    }

    getAlternativeSportsSelect = async (sport) => {
        await this.setState({ alternativeSportsInSelection: [], alternative_sports: [] });

        let alternativeSportsInSelection = [
            { title: 'Beach Tennis', name: 'beach_tennis' },
            { title: 'Vôlei de Praia', name: 'beach_volley' },
            { title: 'Futevôlei', name: 'foot_volley' },
        ];


        if (sport) {
            alternativeSportsInSelection.forEach((alt_sport, index) => {
                if (alt_sport.name === sport) {
                    alternativeSportsInSelection.splice(index, 1);
                }
            });
        }

        await this.setState({ alternativeSportsInSelection });
    }

    getTypeLookup() {
        return {
            'clay': 'Saibro',
            'hard': 'Dura',
            'grass': 'Grama',
            'artificial_grass': 'Grama Artificial',
            'synthetic_resin': 'Resina Sintética',
            'sand': 'Areia',
            'porous_concrete': 'Concreto Poroso',
            'others': 'Outros'
        }
    }

    renderSurfaceEdit(props) {

        let sport = props.rowData.sport;
        let surfaces = [];

        if (sport === 'tennis' || sport === 'badminton') {
            surfaces.push({ title: 'Saibro', name: 'clay' });
            surfaces.push({ title: 'Grama', name: 'grass' });
        }

        if (sport === 'tennis' || sport === 'badminton' || sport === 'padel' || sport === 'squash') {
            surfaces.push({ title: 'Dura', name: 'hard' });
        }

        if (sport === 'tennis' || sport === 'badminton' || sport === 'padel') {
            surfaces.push({ title: 'Grama Artificial', name: 'artificial_grass' });
        }

        if (sport === 'padel') {
            surfaces.push({ title: 'Resina Sintética', name: 'synthetic_resin' });
        }

        if (sport === 'beach_tennis' || sport === 'beach_volley' || sport === 'foot_volley') {
            surfaces.push({ title: 'Areia', name: 'sand' });
        }

        if (sport === 'padel') {
            surfaces.push({ title: 'Concreto Poroso', name: 'porous_concrete' });
        }

        return (
            <Select value={props.value} onChange={(v) => { props.onChange(v.target.value) }}>
                {
                    surfaces.map((surface, key) => {
                        return <MenuItem value={surface.name}>{surface.title}</MenuItem>
                    })
                }
            </Select>
        )
    }

    async canDelete(id) {

        let canDelete = true;

        let lessonCount = await (await Firestore.customQuery('lesson').where('id_company', '==', SessionHelper.getData().id_company).where('id_court', '==', id).limit(1).get()).size;
        if (lessonCount === 1) { canDelete = false }

        let courtRentCount = await (await Firestore.customQuery('court_rent').where('id_company', '==', SessionHelper.getData().id_company).where('id_court', '==', id).limit(1).get()).size;
        if (courtRentCount === 1) { canDelete = false }

        let tournamentEventCount = await (await Firestore.customQuery('tournament_event').where('id_company', '==', SessionHelper.getData().id_company).where('id_court', '==', id).limit(1).get()).size;
        if (tournamentEventCount === 1) { canDelete = false }

        let experimentalLessonCount = await (await Firestore.customQuery('experimental_lesson').where('id_company', '==', SessionHelper.getData().id_company).where('id_court', '==', id).limit(1).get()).size;
        if (experimentalLessonCount === 1) { canDelete = false }

        let privateLessonCount = await (await Firestore.customQuery('private_lesson').where('id_company', '==', SessionHelper.getData().id_company).where('id_court', '==', id).limit(1).get()).size;
        if (privateLessonCount === 1) { canDelete = false }

        let courtRentPacksCount = await (await Firestore.customQuery('court_rent_packs').where('id_company', '==', SessionHelper.getData().id_company).where('courts', 'array-contains', id).limit(1).get()).size;
        if (courtRentPacksCount === 1) { canDelete = false }

        return canDelete;
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <HeaderActionsWrapper style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }} className={'header-actions-buttons'}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon />} onClick={() => { this.setState({ addModal: true, sport: 'tennis', type: 'clay', rent_days: [{ label: 'Segunda', checked: false, add: false, hours: [] }, { label: 'Terça', checked: false, add: false, hours: [] }, { label: 'Quarta', checked: false, add: false, hours: [] }, { label: 'Quinta', checked: false, add: false, hours: [] }, { label: 'Sexta', checked: false, add: false, hours: [] }, { label: 'Sábado', checked: false, add: false, hours: [] }, { label: 'Domingo', checked: false, add: false, hours: [] },] }) }} title={'Adicionar'} />
                        <div style={{ marginLeft: 8 }} />
                        <DefaultButton leftIcon={<FileCopyIcon />} color={`#3ea6b0`} onClick={() => { this.handleCopyCourtRentDays() }} title={'Replicar Horários'} />
                        <div style={{ marginLeft: 8 }} />
                        <DefaultButton leftIcon={<SportsTennisIcon />} color={`#41b03e`} onClick={() => { this.setState({ racquetRentModal: true }) }} title={'Aluguel de Raquetes'} />
                        <div style={{ marginLeft: 8 }} />
                        <DefaultButton leftIcon={<WbIncandescentIcon />} color={`#c9ba0a`} onClick={() => { this.setState({ lightsModal: true }) }} title={'Valor de Iluminação'} />
                        <div style={{ marginLeft: 8 }} />
                        <DefaultButton leftIcon={<LocalOfferIcon />} color={`#b05e3e`} onClick={() => { this.setState({ dayDiscountModal: true }) }} title={'Descontos'} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<SettingsIcon />} onClick={() => { this.setState({ settingsModal: true }) }} />
                    </div>
                </HeaderActionsWrapper>

                <DefaultTable
                    title={'Quadras'}
                    marginTop={10}
                    detailPanel={(rowData) => { return this.detailPanel(rowData) }}
                    onRowClick={(evt, rowData, togglePanel) => { togglePanel() }}
                    actions={[
                        {
                            icon: AccessTimeIcon,
                            tooltip: 'Gerenciar horários de locação',
                            onClick: (event, rowData) => { this.setState({ daysModal: true, rent_days: rowData.rent_days || this.state.rent_days, editId: rowData.id, rawPrice: rowData.price, rawStudentPrice: rowData.student_price }) }
                        },
                        (rowData) => this.state.alternativeAllowedSports.includes(rowData.sport) ? {
                            icon: AddBox,
                            tooltip: 'Esportes Alternativos',
                            onClick: async (event, rowData) => { await this.getAlternativeSportsSelect(rowData.sport); this.setState({ altSportsModal: true, editId: rowData.id, alternative_sports: rowData.alternative_sports || [] }); }
                        } : null,
                    ]}
                    filtering={true}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Cor', field: 'color', render: rowData => <div style={{ height: 28, width: 28, borderRadius: 5, backgroundColor: rowData.color || Colors.primary }}></div>, editComponent: props => (<CirclePicker color={props.value} onChangeComplete={(color) => { props.onChange(color.hex); }} colors={Colors.pickerColors} />) },
                        { title: 'Nome', field: 'name' },
                        { title: `Valor de Locação por Hora${this.state.court_rent_settings.rent_model === 'person_hour' ? ` e Pessoa` : ``}`, field: 'price', type: 'currency', currencySetting: { locale: 'brl', currencyCode: 'brl' }, editComponent: props => (<CurrencyInput value={props.value} onChange={(e) => { props.onChange(e.floatValue) }} />) },
                        { title: `Valor de Locação por Hora${this.state.court_rent_settings.rent_model === 'person_hour' ? ` e Pessoa` : ``} para ${TermHelper.getestablishmentTypeLabel(SessionHelper.getData().company.establishment_type).plural}`, field: 'student_price', type: 'currency', currencySetting: { locale: 'brl', currencyCode: 'brl' }, editComponent: props => (<CurrencyInput value={props.value} onChange={(e) => { props.onChange(e.floatValue) }} />) },
                        { title: 'Esporte', field: 'sport', lookup: this.getSportLookup() },
                        { title: 'Tipo de Superfície', field: 'type', editComponent: (props) => this.renderSurfaceEdit(props), lookup: this.getTypeLookup() },
                        { title: 'Disponível para Locação', field: 'forRent', type: 'boolean', render: rowData => <IosSwitch disabled={true} checked={rowData.forRent} />, editComponent: props => (<IosSwitch checked={props.value} onChange={(e) => { props.onChange(e) }} />) },
                        { title: `Locação apenas para ${TermHelper.getestablishmentTypeLabel(SessionHelper.getData().company.establishment_type).plural}`, field: 'exclusiveRent', type: 'boolean', render: rowData => <IosSwitch disabled={true} checked={rowData.exclusiveRent} />, editComponent: props => (<IosSwitch checked={props.value} onChange={(e) => { props.onChange(e) }} />) },
                        { title: `Oferecer Aluguel de Raquetes`, disabled: rowData => !this.state.racquetRentSports.includes(rowData.sport), field: 'racquetRent', type: 'boolean', render: rowData => <IosSwitch disabled={true} checked={rowData.racquetRent} />, editComponent: props => (<IosSwitch checked={props.value} onChange={(e) => { props.onChange(e) }} />) },
                        { title: 'Quadra Coberta', field: 'indoor', type: 'boolean', render: rowData => <IosSwitch disabled={true} checked={rowData.indoor} />, editComponent: props => (<IosSwitch checked={props.value} onChange={(e) => { props.onChange(e) }} />) },
                        { title: 'Distância máxima de agendamentos', field: 'schedule_distance_limit', type: 'numeric', render: rowData => <div>{rowData.schedule_distance_limit ? `${rowData.schedule_distance_limit} semana${rowData.schedule_distance_limit > 1 ? 's' : ''}` : ''}</div> },
                        { title: 'Ativo', field: 'active', type: 'boolean', render: rowData => <IosSwitch disabled={true} checked={rowData.active} />, editComponent: props => (<IosSwitch checked={props.value} onChange={(e) => { props.onChange(e) }} />) },
                    ]}
                    data={this.state.docs}
                    onRowUpdate={async (oldData, newData) => {

                        let prev = this.state.docs;
                        prev[prev.indexOf(oldData)] = newData;

                        if (!newData.type) {
                            toast.warn('Preencha o tipo de superfície');
                            return;
                        }

                        this.setState({ docs: prev });

                        if (oldData.id) {

                            let update = {
                                name: newData.name,
                                price: newData.price * 100,
                                student_price: newData.student_price * 100,
                                active: newData.active,
                                forRent: newData.forRent,
                                exclusiveRent: newData.exclusiveRent || false,
                                racquetRent: (newData.racquetRent || false) && this.state.racquetRentSports.includes(newData.sport),
                                alternative_sports: this.state.alternativeAllowedSports.includes(newData.sport) ? newData.alternative_sports || [] : [],
                                indoor: newData.indoor || false,
                                type: newData.type || 'clay',
                                sport: newData.sport || 'tennis',
                                schedule_distance_limit: newData.schedule_distance_limit || '',
                                color: newData.color || this.state.color
                            };

                            await Firestore.update(update, 'court', oldData.id);
                        }

                        toast.success("Editado com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        return prev;
                    }}
                    onRowDelete={async (oldData) => {

                        if (oldData.id) {

                            if (await (this.canDelete(oldData.id))) {

                                let prev = this.state.docs;
                                prev.splice(prev.indexOf(oldData), 1);

                                this.setState({ docs: prev });

                                await Firestore.delete('court', oldData.id);

                                toast.success("Removido com sucesso", {
                                    position: toast.POSITION.TOP_RIGHT
                                });

                                return prev;

                            } else {

                                toast.warn('Não é possível remover uma quadra com dados vínculados');
                                return;
                            }
                        }
                    }}
                />

                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} width={830} title={'Nova Quadra'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.copyHoursModal()} width={830} title={'Replicar Horários'} onClose={() => { this.setState({ copyHoursModal: false }) }} open={this.state.copyHoursModal} />
                <DefaultModal width={800} loading={this.state.loadingModal} content={this.daysModal()} title={'Horários de Locação'} onClose={() => { this.setState({ daysModal: false }) }} open={this.state.daysModal} />
                <DefaultModal width={800} loading={this.state.loadingModal} content={this.racquetRentModal()} title={'Aluguel de Raquetes'} onClose={() => { this.setState({ racquetRentModal: false }) }} open={this.state.racquetRentModal} />
                <DefaultModal width={800} loading={this.state.loadingModal} content={this.lightsModal()} title={'Adicionais de Luzes'} onClose={() => { this.setState({ lightsModal: false }) }} open={this.state.lightsModal} />
                <DefaultModal width={800} loading={this.state.loadingModal} content={this.altSportsModal()} title={'Esportes Alternativos da Quadra'} onClose={() => { this.setState({ altSportsModal: false, alternative_sports: [], editId: null }) }} open={this.state.altSportsModal} />
                <DefaultModal width={830} loading={this.state.loadingModal} content={this.settingsModal()} title={'Configurações do Aluguel'} onClose={() => { this.setState({ settingsModal: false }) }} open={this.state.settingsModal} />
                <DefaultModal width={830} loading={this.state.loadingModal} content={this.dayDiscountModal()} title={'Desconto por dia'} onClose={() => { this.setState({ dayDiscountModal: false }) }} open={this.state.dayDiscountModal} />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
