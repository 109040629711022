import React, { Component, forwardRef } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button, FormControlLabel, FormLabel, RadioGroup, Radio, Tooltip } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import moment from "moment-timezone";
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultSelect from '../components/DefaultSelect';
import NotificationHelper from '../helper/NotificationHelper';
import DefaultTable from '../components/DefaultTable';
import IosSwitch from '../components/IosSwitch';
import AddIcon from '@material-ui/icons/Add';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import PersonIcon from '@material-ui/icons/Person';
import SessionHelper from '../helper/SessionHelper';
import AgendaConflict from '../components/AgendaConflict';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker, KeyboardTimePicker } from '@material-ui/pickers';
import brLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from '@date-io/date-fns';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import InputMask from 'react-input-mask';
import HeaderActionsWrapper from '../components/HeaderActionsWrapper';
import RelationField from '../components/grid/RelationField';
import DataHelper from '../helper/DataHelper';
import TermHelper from '../helper/TermHelper';

export default class ExperimentalLessonPage extends Component {

    state = {
        docs: [],
        courtDocs: [],
        userDocs: [],
        teacherDocs: [],
        type: 'extern',
        name: '',
        email: '',
        cpf: '',
        phone: '',
        loading: true,
        loadingModal: false,
        addModal: false,
        daysModal: false,
        editId: null,
        idUser: null,
        scheduleStart: null,
        scheduleEnd: null,
        scheduleTeacher: null,
        scheduleCourt: null,
        experimentalLesson: SessionHelper.getData().company.services ? SessionHelper.getData().company.services.find(item => item.name === 'test_lesson') : { label: 'Aula Experimental', name: 'test_lesson', checked: false },
        scheduleDays: [
            { label: 'Segunda', checked: false, startHour: '00:00', endHour: '00:00', idCourt: null, idTeacher: null, add: false, hours: [] },
            { label: 'Terça', checked: false, startHour: '00:00', endHour: '00:00', idCourt: null, idTeacher: null, add: false, hours: [] },
            { label: 'Quarta', checked: false, startHour: '00:00', endHour: '00:00', idCourt: null, idTeacher: null, add: false, hours: [] },
            { label: 'Quinta', checked: false, startHour: '00:00', endHour: '00:00', idCourt: null, idTeacher: null, add: false, hours: [] },
            { label: 'Sexta', checked: false, startHour: '00:00', endHour: '00:00', idCourt: null, idTeacher: null, add: false, hours: [] },
            { label: 'Sábado', checked: false, startHour: '00:00', endHour: '00:00', idCourt: null, idTeacher: null, add: false, hours: [] },
            { label: 'Domingo', checked: false, startHour: '00:00', endHour: '00:00', idCourt: null, idTeacher: null, add: false, hours: [] },
        ],
        scheduleId: null,
        inputError: false,
        sendNotificationStudent: true,
        sendNotificationTeacher: true,
        scheduleDistanceLimit: '',
    }

    async getDocs() {

        let query = await Firestore.customQuery('experimental_lesson').where('id_company', '==', SessionHelper.getData().id_company).orderBy('created_at', 'desc').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs });
    }

    async getScheduleConfig() {

        let query = await Firestore.customQuery('experimental_lesson_schedule').where('id_company', '==', SessionHelper.getData().id_company).get();
        
        if (query.size === 1) {

            this.setState({ scheduleDays: query.docs[0].data().schedule_days, scheduleId: query.docs[0].id, scheduleDistanceLimit: query.docs[0].data().schedule_distance_limit });
        }
    }

    async getTeachers() {

        let query = await Firestore.customQuery('user').where('id_company', '==', SessionHelper.getData().id_company).where('type', '==', 'teacher').orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ teacherDocs: docs });
    }

    async getCourts() {

        let query = await Firestore.customQuery('court').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.price = data.price / 100;
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ courtDocs: docs });
    }

    async getStudents() {

        let style = {
            color: `white`,
            fontSize: 12,
            paddingLeft: 5,
            paddingRight: 5,
            borderRadius: 5,
            marginLeft: 5,
            maxWidth: 100
        };

        let query = await Firestore.customQuery('user').where('id_companies', 'array-contains', SessionHelper.getData().id_company).where('type', 'in', ['student', 'sales_user']).orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            if (data.type == 'student') {
                data.type_label = <div style={{ backgroundColor: Colors.success, ...style }}>{TermHelper.getestablishmentTypeLabel(SessionHelper.getData().company.establishment_type).singular}</div>;
            } else {
                data.type_label = <div style={{ backgroundColor: Colors.primary, ...style }}>Bar/Loja</div>;
            }

            docs.push(data);
        });

        this.setState({ userDocs: docs });
    }

    async componentDidMount() {

        await this.setState({ loading: true });

        await this.getDocs();
        await this.getCourts();
        await this.getStudents();
        await this.getTeachers();
        await this.getScheduleConfig();

        await this.setState({ loading: false });
    }

    async addExperimentalLesson() {

        if (((this.state.name && this.state.email && this.state.cpf && this.state.phone) || this.state.idUser) && this.state.scheduleStart && this.state.scheduleEnd && this.state.scheduleTeacher && this.state.scheduleCourt) {

            let data = {
                id_company: SessionHelper.getData().id_company,
                start: this.state.scheduleStart,
                end: this.state.scheduleEnd,
                id_teacher: this.state.scheduleTeacher,
                id_court: this.state.scheduleCourt,
                created_at: new Date(),
                rated: false
            };

            if (!this.state.idUser) {

                data.name = this.state.name;
                data.phone = this.state.phone.replace(/[^\d]/g, '');
                data.email = this.state.email;
                data.cpf = this.state.cpf.replace(/[^\d]/g, '');

            } else {

                data.id_user = this.state.idUser;
            }

            try {

                this.setState({ loadingModal: true });

                let doc = await Firestore.getId('experimental_lesson');
                await Firestore.insert(data, 'experimental_lesson', doc);

                if (this.state.idUser && this.state.sendNotificationStudent) {
                    
                    let userDoc = this.state.userDocs.find(item => item.id === this.state.idUser);

                    if (userDoc && userDoc.notification_token && userDoc.notification_token.data) {

                        let token = userDoc.notification_token.data;
                        let notification = {
                            icon: 'flask',
                            color: 'orange',
                            navigate: 'ExperimentalLesson',
                            experimental_lesson_id: doc,
                            start: data.start,
                        };

                        NotificationHelper.send('Aula Experimental', `A sua aula experimental foi agendada para ${moment(data.start).format('DD/MM/YYYY HH:mm')}, abra o app e confira.`, notification, token, this.state.idUser);
                    }
                }

                if (this.state.sendNotificationTeacher) {
                    
                    let teacherDoc = this.state.teacherDocs.find(item => item.id === this.state.scheduleTeacher);

                    if (teacherDoc && teacherDoc.notification_token && teacherDoc.notification_token.data) {

                        let token = teacherDoc.notification_token.data;
                        let notification = {
                            icon: 'calendar-plus',
                            color: 'green',
                            navigate: 'TeacherExperimentalLesson',
                            experimental_lesson_id: doc,
                            start: data.start,
                        };

                        NotificationHelper.send('Aula Agendada', `Nova aula agendada para ${moment(data.start).format('DD/MM/YYYY HH:mm')}, abra o app e confira.`, notification, token, teacherDoc.id);
                    }
                }

                toast.success("Cadastrado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.setState({ addModal: false, name: '', email: '', cpf: '', idUser: null, scheduleStart: null, scheduleEnd: null, scheduleTeacher: null, scheduleCourt: null });
                await this.getDocs();

                this.setState({ loadingModal: false, loading: false });

            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn("Preencha todos os campos", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    async saveDays() {

        try {

            if (Number.isInteger(parseInt(this.state.scheduleDistanceLimit))) {

                this.setState({ loadingModal: true });

                let scheduleDays = [];

                this.state.scheduleDays.forEach((rent, key) => {

                    scheduleDays.push({
                        label: rent.label,
                        checked: rent.checked,
                        hours: rent.hours,
                    });
                });

                if (this.state.scheduleId) {

                    await Firestore.update({ schedule_days: scheduleDays, schedule_distance_limit: this.state.scheduleDistanceLimit }, 'experimental_lesson_schedule', this.state.scheduleId);

                } else {

                    let data = {
                        id_company: SessionHelper.getData().id_company,
                        schedule_days: scheduleDays,
                        schedule_distance_limit: this.state.scheduleDistanceLimit,
                    };

                    await Firestore.insert(data, 'experimental_lesson_schedule');
                }

                await this.getScheduleConfig();

                toast.success('Horários atualizados com sucesso');

                this.setState({ daysModal: false, days: null, editId: null, loadingModal: false });

            } else {

                toast.warn('Preencha corretamente o limite de distância');
            }

        } catch (error) {

            toast.error('Erro ao atualizar horários');
            this.setState({ daysModal: false, loadingModal: false });
        }
    }

    addHour(key) {

        let scheduleDays = this.state.scheduleDays;

        if (scheduleDays[key].startHour && scheduleDays[key].endHour && scheduleDays[key].idCourt && scheduleDays[key].idTeacher) {

            scheduleDays[key].hours.push({ start: moment(scheduleDays[key].startHour).format('HH:mm'), end: moment(scheduleDays[key].endHour).format('HH:mm'), id_court: scheduleDays[key].idCourt, id_teacher: scheduleDays[key].idTeacher });
            scheduleDays[key].add = false;
            scheduleDays[key].startHour = '00:00';
            scheduleDays[key].endHour = '00:00';
            scheduleDays[key].idTeacher = null;
            scheduleDays[key].idCourt = null;

            if(scheduleDays[key] && scheduleDays[key].hours && scheduleDays[key].hours.length) {
                scheduleDays[key].hours.sort((a, b) => {
                    if(a.start < b.start) { return -1; }
                    if(a.start > b.start) { return 1; }
                    return 0;
                });
            }
    
            this.setState({ scheduleDays: scheduleDays });

        } else {

            toast.warn('Preencha todos os campos corretamente');
        }
    }

    daysModal() {

        if (this.state.daysModal) {

            return (
                <div>
                    <DefaultInput value={this.state.scheduleDistanceLimit} number required onError={(error, msg) => { this.setState({ inputError: error }) }} onChange={(v) => { this.setState({ scheduleDistanceLimit: v }) }} label={'Distância máxima de agendamentos ("3" = até 3 semanas para frente)'} />
                    { 
                        this.state.scheduleDays.map((rent, key) => {

                            return (
                                <div>
                                    <IosSwitch label={rent.label} value={rent.label} checked={rent.checked} onChange={(v) => { this.handleRentDayChange(v, key) }}/>
                                    
                                    {
                                        rent.checked ? rent.hours.map((hour, hourKey) => {

                                            return (
                                                <div style={{ paddingTop: 15, paddingBottom: 15, marginTop: 5, fontWeight: 'bold', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', borderBottom: '1px solid lightgrey' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                            <AccessTimeIcon style={{ marginRight: 5 }}/>
                                                            {`De ${hour.start} até ${hour.end}`}
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 8 }}>
                                                            <SportsTennisIcon style={{ marginRight: 5 }}/>
                                                            {`Quadra - ${this.state.courtDocs.find(item => item.id === hour.id_court).name}`}
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 8 }}>
                                                            <PersonIcon style={{ marginRight: 5 }}/>
                                                            {`Professor ${this.state.teacherDocs.find(item => item.id === hour.id_teacher).name}`}
                                                        </div>
                                                    </div>
                                                    <Tooltip title={'Remover horário'}>
                                                        <DeleteIcon onClick={() => { let scheduleDays = this.state.scheduleDays; scheduleDays[key].hours.splice(hourKey, 1); this.setState({ scheduleDays: scheduleDays }) }} style={{ marginLeft: 5, color: 'red', cursor: 'pointer' }}/>
                                                    </Tooltip>
                                                </div>
                                            )
                                        }) 

                                    : null }

                                    { rent.checked && !rent.hours.length ? <div style={{ color: 'grey', paddingTop: 10, paddingBottom: 10 }}>{'Nenhum horário cadastrado'}</div> : null }
                                    { rent.checked && !rent.add ? <div style={{ marginTop: 10, marginBottom: 10 }}><DefaultButton leftIcon={<AddIcon style={{ marginRight: 5 }}/>} onClick={() => { let scheduleDays = this.state.scheduleDays; scheduleDays[key].add = true; this.setState({ scheduleDays: scheduleDays }) }} title={'Adicionar Horário'}/></div> : null }

                                    { rent.checked && rent.add ? 
                                    
                                        <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: 20 }}>
                                            <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', width: '100%' }}>
                                                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                                    <KeyboardTimePicker
                                                        style={{ width: '100%', marginBottom: 10, paddingRight: 5 }}
                                                        invalidDateMessage={false}
                                                        format={'HH:mm'}
                                                        autoOk={true}
                                                        label="Início"
                                                        cancelLabel={'Cancelar'}
                                                        okLabel={'Confirmar'}
                                                        onChange={(v) => { let scheduleDays = this.state.scheduleDays; scheduleDays[key].startHour = v; scheduleDays[key].endHour = moment(v).add(1, 'hours').toDate(); this.setState({ scheduleDays: scheduleDays }) }}
                                                        value={rent.startHour}
                                                    />
                                                    <KeyboardTimePicker
                                                        style={{ width: '100%', marginBottom: 10, paddingLeft: 5 }}
                                                        invalidDateMessage={false}
                                                        format={'HH:mm'}
                                                        autoOk={true}
                                                        disabled={true}
                                                        label="Fim"
                                                        cancelLabel={'Cancelar'}
                                                        okLabel={'Confirmar'}
                                                        value={rent.endHour}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>

                                            <DefaultSelect search={true} searchField={'name'} id={'teacher_selection'} valueField={'id'} displayField={'name'} onChange={(v) => { let scheduleDays = this.state.scheduleDays; scheduleDays[key].idTeacher = v.target.value; this.setState({ scheduleDays: scheduleDays }) }} docs={this.state.teacherDocs} label={'Professor'}/>
                                            <div style={{marginTop: 15}}/>
                                            <DefaultSelect search={true} searchField={'name'} id={'court_selection'} valueField={'id'} displayField={'name'} onChange={(v) => { let scheduleDays = this.state.scheduleDays; scheduleDays[key].idCourt = v.target.value; this.setState({ scheduleDays: scheduleDays }) }} docs={this.state.courtDocs} label={'Quadra'}/>
                                            
                                            <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', marginTop: 15, marginBottom: 15 }}>
                                                <DefaultButton leftIcon={<CheckIcon style={{ marginRight: 5 }}/>} onClick={() => { this.addHour(key) }} title={'Adicionar'}/>
                                                <div style={{ marginRight: 5 }}></div>
                                                <DefaultButton leftIcon={<ClearIcon style={{ marginRight: 5 }}/>} onClick={() => { let scheduleDays = this.state.scheduleDays; scheduleDays[key].add = false; scheduleDays[key].startHour = '00:00'; scheduleDays[key].endHour = '00:00'; this.setState({ scheduleDays: scheduleDays }) }} title={'Cancelar'}/>
                                            </div>
                                        </div>

                                    : null }

                                </div>
                            )
                        }) 
                    }

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.saveDays() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'SALVAR'} />
                        <DefaultButton onClick={() => { this.setState({ daysModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    handleRentDayChange(value, key) {

        let scheduleDays = this.state.scheduleDays;
        scheduleDays[key].checked = value;

        this.setState({ scheduleDays: scheduleDays });
    }

    addModal() {

        if (this.state.addModal) {

            return (
                <div>
                    <FormLabel style={{paddingBottom: 18}} component="legend">Dados do Aluno</FormLabel>

                    <RadioGroup style={{display: 'flex', flexDirection: 'row'}} aria-label="type" name="type"  value={this.state.type} onChange={(evt) => { this.setState({ type: evt.target.value, idUser: null, name: '', email: '', cpf: '', phone: '' }) }}>
                        <FormControlLabel value="extern" control={<Radio style={{color: Colors.primary}}/>} label="Aluno Externo" />
                        <FormControlLabel value="intern" control={<Radio style={{color: Colors.primary}}/>} label="Aluno Interno" />
                    </RadioGroup>

                    { 
                    
                    this.state.type === 'extern' ?

                        <div>
                            <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ name: v }) }} label={'Nome'}/>
                            <DefaultInput required email onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ email: v }) }} label={'E-mail'}/>
                            <InputMask onBlur={(v) => { this.setState({ cpf: v }) }} mask="999.999.999-99" maskPlaceholder={''}>
                                <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={11} label={'Cpf'} />
                            </InputMask>
                            <InputMask onBlur={(v) => { this.setState({ phone: DataHelper.treatPhone(v) }) }} mask="(99) 9 9999-99999999" maskPlaceholder={''}>
                                <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={11} label={'Telefone'} />
                            </InputMask>
                        </div>
                    
                    : <div style={{ paddingTop: 10 }}><DefaultSelect useCustomRender secondaryComponent={'type_label'} search={true} searchField={'name'} id={'student_selection'} valueField={'id'} displayField={'name'} value={this.state.idUser} onChange={(v) => { this.setState({ idUser: v.target.value }) }} docs={this.state.userDocs} label={'Aluno'}/></div> }

                    <FormLabel style={{paddingBottom: 18, paddingTop: 18}} component="legend">Dados da Aula</FormLabel>

                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10}}>
                        <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                            <KeyboardDateTimePicker
                            style={{width: '100%', marginBottom: 15, paddingRight: 5}}
                            invalidDateMessage={false}
                            format={'dd/MM/yyyy HH:mm'}
                            autoOk={true}
                            label="Início"
                            cancelLabel={'Cancelar'}
                            okLabel={'Confirmar'}
                            onChange={(v) => { this.setState({ scheduleStart: v, scheduleEnd: moment(v).add(1, 'hour').toDate() }) }}
                            value={this.state.scheduleStart}
                            />
                            <KeyboardDateTimePicker
                            style={{width: '100%', marginBottom: 15, paddingLeft: 5}}
                            invalidDateMessage={false}
                            format={'dd/MM/yyyy HH:mm'}
                            autoOk={true}
                            label="Fim"
                            cancelLabel={'Cancelar'}
                            okLabel={'Confirmar'}
                            minDate={this.state.scheduleStart}
                            onChange={(v) => { this.setState({ scheduleEnd: v }) }}
                            value={this.state.scheduleEnd}
                            />
                        </MuiPickersUtilsProvider>
                    </div>

                    <DefaultSelect search={true} searchField={'name'} id={'teacher_selection'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ scheduleTeacher: v.target.value }) }} docs={this.state.teacherDocs} label={'Professor'}/>
                    
                    <div style={{marginTop: 15}}/>
                    <DefaultSelect search={true} searchField={'name'} id={'court_selection'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ scheduleCourt: v.target.value }) }} docs={this.state.courtDocs} label={'Quadra'}/>

                    <AgendaConflict start={this.state.scheduleStart} end={this.state.scheduleEnd} court={this.state.scheduleCourt} teacher={this.state.scheduleTeacher} teachers={this.state.teacherDocs} courts={this.state.courtDocs} editId={this.state.editId || ''} editType={'experimental_lesson'}/>

                    { this.state.type === 'intern' ?

                        <IosSwitch fullWidth label="Notificar Aluno" value="repeat" checked={this.state.sendNotificationStudent} onChange={(v) => { this.setState({ sendNotificationStudent: v }) }}/>

                    : null }

                    <IosSwitch fullWidth label="Notificar Professor" value="repeat" checked={this.state.sendNotificationTeacher} onChange={(v) => { this.setState({ sendNotificationTeacher: v }) }}/>

                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.addExperimentalLesson() }} style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ addModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    handleSchedule(rowData) {

        this.setState({ scheduleModal: true, scheduleStart: rowData.start.toDate(), scheduleEnd: rowData.end.toDate(), scheduleCourt: rowData.id_court, scheduleTeacher: rowData.id_teacher, editId: rowData.id, idUser: rowData.id_user, days: rowData.days, hours: rowData.hours })
    }

    async handleSwitch(v) {

        let experimentalLesson = this.state.experimentalLesson;
        experimentalLesson.checked = v;

        this.setState({ experimentalLesson: experimentalLesson });

        let services = SessionHelper.getData().company.services || [
            { label: 'Matrícula', name: 'sign_up', checked: true },
            { label: 'Aula Avulsa', name: 'private_lesson', checked: false },
            { label: 'Aula Experimental', name: 'test_lesson', checked: false },
        ];

        let index = services.findIndex(item => item.name === 'test_lesson');
        services[index] = experimentalLesson;

        await Firestore.update({ services: services }, 'company', SessionHelper.getData().id_company);
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <HeaderActionsWrapper style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon style={{ marginRight: 5 }}/>} onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar Aula'}/>  
                        <div style={{ marginRight: 5 }}/>
                        <DefaultButton leftIcon={<AccessTimeIcon style={{ marginRight: 5 }}/>} onClick={() => { this.setState({ daysModal: true }) }} title={'Gerenciar Horários'}/>  
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <IosSwitch onChange={(v) => { this.handleSwitch(v) }} label={this.state.experimentalLesson.label} checked={this.state.experimentalLesson.checked}/>
                    </div>
                </HeaderActionsWrapper>
                <DefaultTable
                title={'Aulas Experimentais'}
                marginTop={10}
                
                filtering={true}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Pedido', editable: false, field: 'id_order', render: rowData => rowData.id_order ? <RelationField collection={'order'} id={rowData.id_order} field={'title'}/> : 'Sem Pedido' },
                    { title: 'Aluno', editable: false, field: 'name', render: rowData => rowData.id_user ? <RelationField collection={'user'} id={rowData.id_user} field={'name'}/> : rowData.name },
                    { title: 'E-mail', editable: false, field: 'email', render: rowData => rowData.id_user ? <RelationField collection={'user'} id={rowData.id_user} field={'email'}/> : rowData.email },
                    { title: 'Cpf', editable: false, field: 'cpf', render: rowData => rowData.id_user ? <RelationField collection={'user'} id={rowData.id_user} field={'cpf'}/> : rowData.cpf },
                    { title: 'Telefone', editable: false, field: 'phone', render: rowData => rowData.id_user ? <RelationField collection={'user'} id={rowData.id_user} field={'phone'}/> : rowData.phone },
                    { title: 'Quadra', field: 'id_court', render: rowData => <div>{this.state.courtDocs.find(item => item.id === rowData.id_court).name}</div> },
                    { title: 'Professor', field: 'id_teacher', render: rowData => <div>{this.state.teacherDocs.find(item => item.id === rowData.id_teacher).name}</div> },
                    { title: 'De', editable: false, field: 'start', render: rowData => <div>{moment(rowData.start.toDate()).format('DD/MM/YYYY HH:mm')}</div> },
                    { title: 'Até', editable: false, field: 'end', render: rowData => <div>{moment(rowData.end.toDate()).format('DD/MM/YYYY HH:mm')}</div> },
                    { title: 'Data de Criação', editable: false, field: 'created_at', render: rowData => <div>{moment(rowData.created_at.toDate()).format('DD/MM/YYYY HH:mm')}</div> },
                ]}
                data={this.state.docs}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Nova Aula Experimental'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.daysModal()} title={'Gerenciar Horários'} onClose={() => { this.setState({ daysModal: false }) }} open={this.state.daysModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
