import React, { Component } from 'react';
import Colors from '../constants/Colors';
import moment from "moment-timezone";
import 'moment/locale/pt-br';
import { Tooltip, FormLabel, IconButton } from '@material-ui/core';
import CurrencyHelper from '../helper/CurrencyHelper';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { toast } from 'react-toastify';
import RelationField from './grid/RelationField';
import DefaultModal from './DefaultModal';
import AddIcon from '@material-ui/icons/Add';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DefaultButton from './DefaultButton';
import WhatsApp from '@material-ui/icons/WhatsApp';
import QRCode from 'qrcode.react';
import CropFreeIcon from '@material-ui/icons/CropFree';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Pagarme from '../api/pagarme/Pagarme';
import IosSwitch from './IosSwitch';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import DefaultSelect from './DefaultSelect';
import CurrencyInput from './CurrencyInput';
import Firestore from '../api/firebase/Firestore';
import SessionHelper from '../helper/SessionHelper';
import CloseIcon from '@material-ui/icons/Close';
import CheckLightIcon from '@material-ui/icons/Check';
import PagarmeConstants from '../constants/PagarMe';
import Functions from '../api/firebase/Functions';
import InventoryHelper from '../helper/InventoryHelper';
import SalesHelper from '../helper/SalesHelper';
import LayoutHelper from '../helper/LayoutHelper';
import Add from '@material-ui/icons/Add';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DataHelper from '../helper/DataHelper';

export default class TicketCard extends Component {

    state = {
        doc: this.props.doc,
        productsDocs: this.props.productsDocs,
        loadingModal: false,
        editSaleModal: false,
        editSaleDoc: null,
        editSaleUser: null,
        addSaleData: {
            items: [],
            payment_method: '',
            value: 0,
            discount: 0,
        },
        paymentOptions: SalesHelper.getPaymentOptions(),
        paymentValue: 0,
        putBackInStock: false,
        actionType: ``,
    }

    async componentDidMount() {
        if (!this.props.fromSalesPage) {
            await SalesHelper.getProducts(this);
        }
    }

    componentDidUpdate(prev) {

        if (this.props.doc !== prev.doc) {

            this.setState({ doc: this.props.doc });
        }
    }

    async updateDoc() {

        let doc = await Firestore.getDoc('order', this.state.doc.id);

        if (doc.exists) {

            this.setState({ doc: { ...doc.data(), id: doc.id } });
        }
    }

    async getOrderBoleto(id_transaction_pagarme) {
        try {

            this.setState({ loadingModal: true });

            let response = await Pagarme.get(`/transactions/${id_transaction_pagarme}`);

            if (response.payment_method === 'boleto') {

                let boletoUrl = response.boleto_url;

                if (boletoUrl) {

                    window.open(boletoUrl, '_blank');
                }
            }

            this.setState({ loadingModal: false });

        } catch (error) {

            toast.error('Houve um problema ao buscar os itens do pedido');
            this.setState({ loadingModal: false });
        }
    }

    async generateBoleto() {
        try {
            this.setState({ loadingModal: true });

            let paidValue = 0;

            if (this.state.editSaleDoc.history) {
                this.state.editSaleDoc.history.map((history, key) => {
                    paidValue += history.amount;
                });
            }

            let id_order = this.state.editSaleDoc.id;
            let id_user = this.state.editSaleDoc.id_user;

            let price = parseInt(this.state.editSaleDoc.amount - paidValue);

            if (price < 500) {
                toast.warn('O valor restante para gerar um boleto deve ser no mínimo R$ 5');
                throw new Error('Minimum value error');
            }

            let boletoExpirationDate = moment().isSameOrBefore(this.state.editSaleDoc.end.toDate()) ? moment(this.state.editSaleDoc.end.toDate()) : moment().add(3, 'days');

            let user = (await Firestore.getDoc('user', id_user)).data();
            let canSendBillEmail = false;

            let customer = {
                external_id: SessionHelper.getData().id_company,
                name: SessionHelper.getData().company.name,
                email: SessionHelper.getData().email,
                country: 'br',
                type: SessionHelper.getData().company.type === 'pj' ? 'corporation' : 'individual',
                documents: [
                    {
                        type: SessionHelper.getData().company.type === 'pj' ? 'cnpj' : 'cpf',
                        number: SessionHelper.getData().company.type === 'pj' ? SessionHelper.getData().company.cnpj : SessionHelper.getData().company.cpf,
                    }
                ],
                phone_numbers: [
                    `+55${DataHelper.treatPhone(`${SessionHelper.getData().company.ddd}${SessionHelper.getData().company.phone}`)}`,
                ]
            };

            if (user && user.name && user.email && user.cpf && user.phone) {
                canSendBillEmail = true;

                customer = {
                    external_id: id_user,
                    name: user.name,
                    email: user.email,
                    country: 'br',
                    type: 'individual',
                    documents: [
                        {
                            type: 'cpf',
                            number: user.cpf,
                        }
                    ],
                    phone_numbers: [
                        `+${DataHelper.treatPhone(user.phone)}`,
                    ]
                }
            }

            let items = this.state.editSaleDoc.items;
            let transactionItems = [];

            items.forEach((item, key) => {

                transactionItems.push({
                    id: item.id,
                    title: item.title,
                    unit_price: item.unit_price,
                    quantity: item.quantity,
                    tangible: item.tangible,
                });
            });

            let body = {
                amount: price,
                payment_method: 'boleto',
                async: false,
                soft_descriptor: 'Ace',
                postback_url: PagarmeConstants.getKeys().ticket_boleto_postback,
                boleto_expiration_date: boletoExpirationDate.format('yyyy-MM-dd'),
                customer: customer,
                items: transactionItems,
                split_rules: [
                    {
                        recipient_id: PagarmeConstants.getKeys().easydata_recipient_id,
                        liable: false,
                        charge_processing_fee: false,
                        amount: PagarmeConstants.getKeys().easydata_transaction_amount_boleto,
                        charge_remainder: true,
                    },
                    {
                        recipient_id: SessionHelper.getData().company.id_recipient_pagarme,
                        liable: true,
                        charge_processing_fee: true,
                        amount: price - PagarmeConstants.getKeys().easydata_transaction_amount_boleto,
                        charge_remainder: false,
                    }
                ],
                metadata: {
                    id_user: id_user,
                    id_company: SessionHelper.getData().id_company,
                    id_order: id_order,
                    type: 'ticket',
                },
            }

            let response = await Pagarme.post('/transactions', body);

            if (response.errors && response.errors.length) {
                throw new Error('Payment Error');
            }

            await Firestore.update({
                tax: PagarmeConstants.getKeys().easydata_transaction_amount_boleto,
                payment_method: 'boleto',
                id_transaction_pagarme: response.id,
                boleto_expiration_date: boletoExpirationDate.toDate(),
                status: response.status
            }, 'order', id_order);

            if (canSendBillEmail) {
                Functions.request('POST', `sales/sendBillEmail`, { to: user.email, id_order, companyName: SessionHelper.getData().company.name, boletoUrl: response.boleto_url, boletoExpirationDate: `${moment(boletoExpirationDate).format('DD/MM/YYYY')}`, boletoTotalAmount: price, paidValue });
            }

            await this.updateDoc();

            this.setState({ boletoCode: response.boleto_barcode, boletoLink: response.boleto_url, actionType: `generate_boleto`, loadingModal: false });
        } catch (e) {
            console.log(e)
            toast.error('Ocorreu um erro ao gerar o boleto');
            this.setState({ loadingModal: false });
        }
    }

    async generatePix() {
        try {
            this.setState({ loadingModal: true });

            let paidValue = 0;

            if (this.state.editSaleDoc.history) {
                this.state.editSaleDoc.history.map((history, key) => {
                    paidValue += history.amount;
                });
            }

            let id_order = this.state.editSaleDoc.id;
            let id_user = this.state.editSaleDoc.id_user;

            let price = parseInt(this.state.editSaleDoc.amount - paidValue);

            if (price < 500) {
                toast.warn('O valor restante para gerar um pix deve ser no mínimo R$ 5');
                throw new Error('Minimum value error');
            }

            let pixExpirationDate = moment().isSameOrBefore(this.state.editSaleDoc.end.toDate()) ? moment(this.state.editSaleDoc.end.toDate()) : moment().add(3, 'days');

            let user = (await Firestore.getDoc('user', id_user)).data();
            let canSendBillEmail = false;

            let customer = {
                external_id: SessionHelper.getData().id_company,
                name: SessionHelper.getData().company.name,
                email: SessionHelper.getData().email,
                country: 'br',
                type: SessionHelper.getData().company.type === 'pj' ? 'corporation' : 'individual',
                documents: [
                    {
                        type: SessionHelper.getData().company.type === 'pj' ? 'cnpj' : 'cpf',
                        number: SessionHelper.getData().company.type === 'pj' ? SessionHelper.getData().company.cnpj : SessionHelper.getData().company.cpf,
                    }
                ],
                phone_numbers: [
                    `+55${DataHelper.treatPhone(`${SessionHelper.getData().company.ddd}${SessionHelper.getData().company.phone}`)}`,
                ]
            };

            if (user && user.name && user.email && user.cpf && user.phone) {
                canSendBillEmail = true;

                customer = {
                    external_id: id_user,
                    name: user.name,
                    email: user.email,
                    country: 'br',
                    type: 'individual',
                    documents: [
                        {
                            type: 'cpf',
                            number: user.cpf,
                        }
                    ],
                    phone_numbers: [
                        `+${DataHelper.treatPhone(user.phone)}`,
                    ]
                }
            }

            let items = this.state.editSaleDoc.items;
            let transactionItems = [];

            items.forEach((item, key) => {

                transactionItems.push({
                    id: item.id,
                    title: item.title,
                    unit_price: item.unit_price,
                    quantity: item.quantity,
                    tangible: item.tangible,
                });
            });

            let body = {
                amount: price,
                payment_method: 'pix',
                async: false,
                soft_descriptor: 'Ace',
                postback_url: PagarmeConstants.getKeys().ticket_boleto_postback,
                pix_expiration_date: pixExpirationDate.format('YYYY-MM-DD'),
                customer: customer,
                items: transactionItems,
                split_rules: [
                    {
                        recipient_id: PagarmeConstants.getKeys().easydata_recipient_id,
                        liable: false,
                        charge_processing_fee: false,
                        amount: PagarmeConstants.getKeys().easydata_transaction_amount_pix,
                        charge_remainder: true,
                    },
                    {
                        recipient_id: SessionHelper.getData().company.id_recipient_pagarme,
                        liable: true,
                        charge_processing_fee: true,
                        amount: price - PagarmeConstants.getKeys().easydata_transaction_amount_pix,
                        charge_remainder: false,
                    }
                ],
                metadata: {
                    id_user: id_user,
                    id_company: SessionHelper.getData().id_company,
                    id_order: id_order,
                    type: 'ticket',
                },
            }

            let response = await Pagarme.post('/transactions', body);

            if (response.errors && response.errors.length) {
                throw new Error('Payment Error');
            }

            await Firestore.update({
                tax: PagarmeConstants.getKeys().easydata_transaction_amount_pix,
                payment_method: 'pix',
                id_transaction_pagarme: response.id,
                boleto_expiration_date: pixExpirationDate.toDate(),
                status: response.status,
                pix_qr_code: response.pix_qr_code,
            }, 'order', id_order);

            if (canSendBillEmail) {
                Functions.request('POST', `sales/sendPixEmail`, { to: user.email, id_order, companyName: SessionHelper.getData().company.name, pixQrCode: response.pix_qr_code, pixExpirationDate: `${moment(pixExpirationDate).format('DD/MM/YYYY')}`, pixTotalAmount: price, paidValue });
            }

            await this.updateDoc();

            this.setState({ pixQrCode: response.pix_qr_code, actionType: `generate_pix`, loadingModal: false });
        } catch (e) {
            toast.error('Ocorreu um erro ao gerar o Pix');
            this.setState({ loadingModal: false });
        }
    }

    addPaymentValue = async () => {
        try {
            await SalesHelper.updatePrice(this);

            let paidValue = 0;
            let editSaleDoc = this.state.editSaleDoc;

            if (this.state.editSaleDoc && this.state.editSaleDoc.history) this.state.editSaleDoc.history.map(history => { paidValue += history.amount });

            if (this.state.paymentValue > (editSaleDoc.amount - paidValue)) {
                toast.warn('Pagamento não pode ser maior que o valor da comanda, veja: "Faltando para comanda ser paga"');
                return;
            }

            if (this.state.paymentValue <= 0 || !this.state.addSaleData.payment_method) {
                toast.warn('Preencha método de pagamento e valor');
                return;
            }

            this.setState({ loadingModal: true });


            let paymentValue = parseInt(Math.ceil(this.state.paymentValue));

            if (!editSaleDoc.history) editSaleDoc.history = [];
            editSaleDoc.history.push({ amount: paymentValue, payment_method: this.state.addSaleData.payment_method, id_user: SessionHelper.getFirebaseAuth().uid, date: new Date() });

            if (parseInt(paidValue + paymentValue) === parseInt(editSaleDoc.amount)) editSaleDoc.status = 'paid';

            this.setState({
                editSaleDoc,
                addingPayment: false,
                paymentValue: 0,
                addSaleData: {
                    ...this.state.addSaleData,
                    payment_method: ``
                }
            })

            await Firestore.update({ history: editSaleDoc.history, status: editSaleDoc.status }, 'order', editSaleDoc.id);
        } catch (e) {
            console.log(e)
            toast.error('Não foi possível registrar o pagamento');
        }

        this.setState({ loadingModal: false });
    }

    saveTicketChanges = async () => {
        try {

            await SalesHelper.updatePrice(this);

            if (this.state.addSaleData.items.length < 1) {
                return toast.info('Adicione pelo menos um produto a esta venda.')
            } else {
                this.state.addSaleData.items.forEach((item, key) => {
                    if (!item.id && (!item.id && item.id_event && (!item.title || !item.unit_price))) {
                        return toast.error(`Produto ou serviço do item ${key + 1} está vazio.`);
                    }
                })
            }
            if (this.state.addSaleData.value < this.state.addSaleData.discount) {
                return toast.error('Desconto não pode ser maior que valor total');
            }

            let divisionValid = SalesHelper.validateProductDivision(this.state.addSaleData.items);

            if (!divisionValid) {
                return toast.warn('Preencha todos os campos do rateio corretamente');
            }

            this.setState({ loadingModal: true });

            let price = parseInt(this.state.addSaleData.value - this.state.addSaleData.discount);

            let prevProds = ((await Firestore.getDoc('order', this.state.editSaleDoc.id)).data()).items;

            let currentItemsIds = [];
            let index = 0;

            for (let item of this.state.addSaleData.items) {
                if (item.tangible) {
                    currentItemsIds.push(item.id);

                    let isPrevProd = false;

                    for (let prevItem of prevProds) {
                        if (item.id === prevItem.id) {
                            isPrevProd = true;
                            if (item.quantity > prevItem.quantity) await InventoryHelper.addProductMovement(item.id, item.quantity - prevItem.quantity, 'exit', `Adição em ${this.state.editSaleDoc.title}`, false);
                            if (item.quantity < prevItem.quantity && this.state.putBackInStock) await InventoryHelper.addProductMovement(item.id, prevItem.quantity - item.quantity, 'entry', `Devolução de ${this.state.editSaleDoc.title}`, false);
                        }
                    }

                    if (!isPrevProd) await InventoryHelper.addProductMovement(item.id, item.quantity, 'exit', this.state.editSaleDoc.title, false);

                    if (item.divided && !item.division_inserted && item.divisions && item.divisions.length) {

                        await SalesHelper.createProductDivisions(this, index);
                    }
                }

                index++;
            }

            for (let prevItem of prevProds) {
                if (prevItem.tangible) {
                    if (!currentItemsIds.includes(prevItem.id) && this.state.putBackInStock) await InventoryHelper.addProductMovement(prevItem.id, prevItem.quantity, 'entry', `Devolução de ${this.state.editSaleDoc.title}`, false);
                }
            }

            await Firestore.update({
                items: this.state.addSaleData.items,
                amount: price,
                discount: parseInt(this.state.addSaleData.discount),
                brute_amount: parseInt(this.state.addSaleData.value),
            }, 'order', this.state.editSaleDoc.id);

            this.setState({
                editSaleModal: false,
                editSaleDoc: {},
                addingPayment: false,
                paymentValue: 0,
                actionType: ``,
                addSaleData: {
                    ...this.state.addSaleData,
                    id_user: '',
                    items: [],
                    value: 0,
                    discount: 0,
                    payment_method: ``
                }
            });

            if (this.props.onIsEditing) { this.props.onIsEditing(false) }

            this.updateDoc();

            if (this.props.fromSalesPage && this.props.getSalesDocs) {

                this.props.getSalesDocs();
            }

            if (this.props.fromSalesPage && this.props.getTicketsDocs) {

                this.props.getTicketsDocs();
            }

        } catch (e) {
            console.log(e)
            toast.error('Não foi possível registrar o pagamento');
        }

        this.setState({ loadingModal: false });
    }

    renderImages = () => {
        let content = null;

        content = (
            <div style={{ marginTop: 10, maxWidth: this.props.width ? this.props.width : '100%', flexWrap: 'wrap', flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ ...styles.text, fontSize: 11, fontWeight: 'bold', marginTop: 5, color: `rgba(0, 0, 0, 0.54)` }}>{moment(this.state.doc.date.toDate()).format('DD/MM/YYYY HH:mm')} </div>
                <div style={{ ...styles.text, fontSize: 11, fontWeight: 'bold', marginTop: 5, color: `rgba(0, 0, 0, 0.54)` }}><RelationField collection={'user'} id={this.state.doc.created_by} field={'name'} loading={false} /></div>
            </div>
        );

        return content;
    }

    renderTags = () => {
        let content = null;
        let productsCounter = 0,
            servicesCounter = 0,
            products = '',
            services = '';

        this.state.doc.items.forEach(item => {
            if (item.tangible) {
                productsCounter += item.quantity;
                products += `${item.title} (${item.quantity}) (${CurrencyHelper.centsToMoney(item.unit_price)}), `;
            } else {
                servicesCounter += 1;
                services += `${item.title} (${item.quantity}) (${CurrencyHelper.centsToMoney(item.unit_price)}), `;
            }
        })

        content = (
            <div>
                {productsCounter > 0 ? <Tooltip title={products}><div style={{ ...styles.indicatorTag, backgroundColor: Colors.primary, width: '100%' }}>{productsCounter} PRODUTOS</div></Tooltip> : null}
                {servicesCounter > 0 ? <Tooltip title={services}><div style={{ ...styles.indicatorTag, backgroundColor: Colors.contrast, width: '100%' }}>{servicesCounter} SERVIÇOS</div></Tooltip> : null}
                {this.state.doc.discount > 0 ? <Tooltip title={`O valor original era ${CurrencyHelper.centsToMoney(this.state.doc.brute_amount)}`}><div style={{ ...styles.indicatorTag, backgroundColor: Colors.danger, width: '100%' }}>{CurrencyHelper.centsToMoney(this.state.doc.discount)} DESCONTO</div></Tooltip> : null}
                {this.state.doc.end.toDate && moment(this.state.doc.end.toDate()).isValid() && this.state.doc.status !== 'paid' ?
                    <Tooltip title={`Marque como pago para dar baixa nessa venda`}>
                        {moment(this.state.doc.end.toDate()).diff(moment(), 'days') < 1 ?
                            <div style={{ ...styles.indicatorTag, backgroundColor: Colors.error, width: '100%' }}>
                                {moment(this.state.doc.end.toDate()).diff(moment(), 'seconds') < 0 ? `VENCEU` : `VENCE`} {moment(this.state.doc.end.toDate()).calendar().toUpperCase()}
                            </div>
                            : <div style={{ ...styles.indicatorTag, backgroundColor: `#49b0c4`, width: '100%' }}>
                                VENCE EM {moment(this.state.doc.end.toDate()).format('DD/MM/YYYY')}
                            </div>
                        }
                    </Tooltip> :
                    <Tooltip title={`O valor foi pago`}>
                        <div style={{ ...styles.indicatorTag, backgroundColor: Colors.success, width: '100%' }}>
                            PAGO
                        </div>
                    </Tooltip>
                }
                {this.state.doc.status !== 'paid' && this.state.doc.payment_method === 'boleto' ?
                    <Tooltip title={`O valor foi pago`}>
                        <div style={{ ...styles.indicatorTag, backgroundColor: Colors.danger, width: '100%' }}>
                            BOLETO GERADO
                        </div>
                    </Tooltip>
                    : null}

                {this.state.doc.status !== 'paid' && this.state.doc.payment_method === 'pix' ?
                    <Tooltip title={`O valor foi pago`}>
                        <div style={{ ...styles.indicatorTag, backgroundColor: Colors.danger, width: '100%' }}>
                            PIX GERADO
                        </div>
                    </Tooltip>
                    : null}
            </div>
        );

        return content;
    }

    getPaidValue() {
        let paidValue = 0;

        if (this.state.editSaleDoc && this.state.editSaleDoc.history && this.state.editSaleDoc.history.length > 0) {
            this.state.editSaleDoc.history.forEach((history, key) => {
                paidValue += history.amount;
            });
        }

        return paidValue;
    }

    renderPaymentHistory() {
        let paidValue = this.getPaidValue();

        return (
            <div>
                <FormLabel>Histórico de Pagamentos</FormLabel>
                {this.state.editSaleDoc && this.state.editSaleDoc.history && this.state.editSaleDoc.history.length > 0 ?
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundColor: Colors.primary, fontSize: 16, padding: 5, color: '#fff', border: `1px solid ${Colors.primary}` }}>
                        <div style={{ width: '25%' }}><b>Data</b></div>
                        <div style={{ width: '25%' }}><b>Valor</b></div>
                        <div style={{ width: '25%' }}><b>Método</b></div>
                        <div style={{ width: '25%' }}><b>Operador</b></div>
                    </div>
                    : null}
                {this.state.editSaleDoc && this.state.editSaleDoc.history && this.state.editSaleDoc.history.length > 0 ?
                    this.state.editSaleDoc.history.map((history, key) => {
                        return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundColor: key % 2 !== 0 ? Colors.secondaryButton : `#fff`, fontSize: 16, padding: 5, color: Colors.dark, border: `1px solid ${Colors.secondaryButton}` }}>
                            <div style={{ width: '25%' }}>{moment(history.date.toDate ? history.date.toDate() : history.date).format('DD/MM/YYYY HH:mm')}</div>
                            <div style={{ width: '25%' }}>{CurrencyHelper.centsToMoney(history.amount)}</div>
                            <div style={{ width: '25%' }}>{(this.state.paymentOptions.find(opt => opt.key === history.payment_method)).label}</div>
                            <div style={{ width: '25%' }}><RelationField collection={'user'} id={history.id_user} field={'name'} loading={false} /></div></div>
                    })
                    : null}
                {this.state.editSaleDoc.payment_method === 'boleto' && this.state.editSaleDoc.status === 'paid' && this.state.editSaleDoc.boleto_payment_date ? <div style={{ backgroundColor: (this.state.editSaleDoc.history ? this.state.editSaleDoc.history.length : 0) % 2 !== 0 ? Colors.secondaryButton : `#fff`, fontSize: 16, paddingTop: 5, paddingBottom: 5, paddingLeft: 10, color: Colors.dark, border: `1px solid ${Colors.secondaryButton}` }}>{moment(this.state.editSaleDoc.boleto_payment_date.toDate()).format('DD/MM/YYYY HH:mm')} | <b>{CurrencyHelper.centsToMoney(this.state.editSaleDoc.amount - paidValue)}</b> - Boleto - Pago pelo cliente.</div> : null}
                {this.state.editSaleDoc.status !== 'paid' ? <h4 style={{ color: Colors.danger, fontSize: 16 }}>Faltando para a comanda ser paga {CurrencyHelper.centsToMoney(this.state.editSaleDoc.amount - paidValue)}</h4> : null}
                {this.state.editSaleDoc.status === 'paid' ? <h4 style={{ color: Colors.success, fontSize: 16 }}>Comanda paga!</h4> : null}
                {this.state.editSaleDoc.status === 'paid' && this.state.editSaleDoc && this.state.editSaleDoc.items ?
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundColor: Colors.primary, fontSize: 16, padding: 5, color: '#fff', border: `1px solid ${Colors.primary}` }}>
                        <div style={{ width: '70%' }}><b>Produto</b></div>
                        <div style={{ width: '15%' }}><b>Quantidade</b></div>
                        <div style={{ width: '10%' }}><b>Histórico</b></div>
                    </div>
                    : null}
                {this.state.editSaleDoc.status === 'paid' && this.state.editSaleDoc && this.state.editSaleDoc.items ?
                    this.state.addSaleData.items.map((item, key) => {
                        return <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center', backgroundColor: key % 2 !== 0 ? Colors.secondaryButton : `#fff`, fontSize: 16, padding: 5, color: Colors.dark, border: `1px solid ${Colors.secondaryButton}` }}>
                                <div style={{ width: '70%' }}>{item.title}</div>
                                <div style={{ width: '15%' }}>{item.quantity}</div>
                                <div style={{ width: '10%', justifyContent: 'center', paddingLeft: '2%' }}>
                                    <Tooltip title={'Histórico'}>
                                        <IconButton disabled={!item.log} onClick={() => { this.setState({ showElementLog: this.state.showElementLog && this.state.showElementLog === item.id ? null : item.id }) }} style={{ width: 32, height: 32, transform: `rotate(${this.state.showElementLog && this.state.showElementLog === item.id ? -45 : 0}deg)` }}>
                                            <Add style={{ color: this.state.showElementLog && this.state.showElementLog === item.id ? Colors.error : Colors.dark }} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                            {
                                this.state.showElementLog && this.state.showElementLog === item.id ? <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 10 }}>{SalesHelper.renderElementLog(item, key)}</div> : null
                            }
                        </div>
                    })
                    : null}
            </div>
        );
    }

    renderEditProducts() {
        return (
            <div>
                {SalesHelper.renderProductSelection(this)}

                <div style={{ width: '100%' }}>
                    <IosSwitch label="Devolver produtos removidos ao estoque" value="putBackInStock" checked={this.state.putBackInStock} onChange={(v) => { this.setState({ putBackInStock: v }) }} />
                </div>

                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                    <DefaultButton onClick={() => {
                        this.setState({
                            editSaleModal: false,
                            editSaleDoc: {},
                            addingPayment: false,
                            paymentValue: 0,
                            actionType: ``,
                            addSaleData: {
                                ...this.state.addSaleData,
                                id_user: '',
                                items: [],
                                value: 0,
                                discount: 0,
                                payment_method: ``
                            }
                        })

                        if (this.props.onIsEditing) { this.props.onIsEditing(false) }

                    }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'Cancelar'} />
                    <DefaultButton onClick={() => { this.saveTicketChanges() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'Salvar Edições'} />
                </div>
            </div>
        );
    }

    renderEndSale() {
        return (
            <div>
                <h4 style={{ color: Colors.primary, fontSize: 16 }}>Valor da venda {CurrencyHelper.centsToMoney(this.state.editSaleDoc.amount)}</h4>
                <h4 style={{ color: Colors.error, fontSize: 16 }}>Desconto {CurrencyHelper.centsToMoney(this.state.editSaleDoc.discount)}</h4>

                {this.renderPaymentHistory()}

                <div style={{ marginTop: 30, width: '100%', justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 20 }}>
                    {this.renderPayment()}

                    <DefaultButton leftIcon={<CreditCardIcon />} onClick={async () => { this.setState({ addingPayment: true }); }} color={Colors.success} loading={this.state.loadingModal} width={'85%'} title={'Apontar Pagamento'} disabled={this.state.addingPayment} />
                    <div style={{ marginTop: 10 }} />
                    <DefaultButton leftIcon={<ViewWeekIcon />} onClick={async () => { if (window.confirm(`Gerar o Boleto do valor em aberto? Depois de gerado o boleto essa comanda será fechada. O vencimento do boleto será o vencimento da comanda. Se já está vencida será de 3 dias à partir de agora.`)) await this.generateBoleto(); }} color={Colors.primary} loading={this.state.loadingModal} width={'85%'} title={'Gerar Boleto'} />
                    <div style={{ marginTop: 10 }} />
                    <DefaultButton leftIcon={<CropFreeIcon />} onClick={async () => { if (window.confirm(`Gerar Pix do valor em aberto? Depois de gerado o pix essa comanda será fechada`)) await this.generatePix(); }} color={Colors.primary} loading={this.state.loadingModal} width={'85%'} title={'Gerar Pix'} />
                </div>

                <DefaultButton onClick={() => {
                    this.setState({
                        editSaleModal: false,
                        editSaleUser: null,
                        editSaleDoc: {},
                        addingPayment: false,
                        paymentValue: 0,
                        actionType: ``,
                        addSaleData: {
                            ...this.state.addSaleData,
                            id_user: '',
                            items: [],
                            value: 0,
                            discount: 0,
                            payment_method: ``
                        }
                    })

                    if (this.props.onIsEditing) { this.props.onIsEditing(false) }

                }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'100%'} title={'Cancelar'} />
            </div>
        )
    }

    renderGenerateBoleto() {
        if (this.state.editSaleModal && this.state.actionType === `generate_boleto`) {
            return (
                <div>
                    <div style={{ marginBottom: 50, width: '100%', height: 400, overflowY: 'scroll', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 50 }}>
                        <h2 style={{ textAlign: 'center' }}>Boleto Gerado!<br /> Aguardando o pagamento.</h2>
                        <p style={{ textAlign: 'center', fontSize: 16 }}>O código do boleto é:</p>
                        <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 18 }}>{this.state.boletoCode || ``}</p>
                        <p style={{ textAlign: 'center', fontSize: 16 }}>E você pode encontrar o boleto <a target={'_blank'} href={this.state.boletoLink || ``}>aqui</a>.</p>
                        <p style={{ textAlign: 'center', fontWeight: 'light', fontSize: 12 }}>Um e-mail com o boleto foi enviado automáticamente para o e-mail do cliente.</p>
                        <p style={{ textAlign: 'center', fontWeight: 'light', fontSize: 12 }}>Recomendamos que caso não haja pagamento do boleto você envie o boleto ao cliente o informando da cobrança.</p>
                    </div>

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 20 }}>
                        <DefaultButton onClick={() => {
                            this.setState({
                                editSaleModal: false,
                                editSaleUser: null,
                                editSaleDoc: {},
                                addingPayment: false,
                                paymentValue: 0,
                                actionType: ``,
                                addSaleData: {
                                    ...this.state.addSaleData,
                                    id_user: '',
                                    items: [],
                                    value: 0,
                                    discount: 0,
                                    payment_method: ``
                                }
                            })

                            if (this.props.onIsEditing) { this.props.onIsEditing(false) }

                        }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'100%'} title={'Fechar'} />
                    </div>
                </div>
            );
        }
    }

    renderGeneratePix() {
        if (this.state.editSaleModal && this.state.actionType === `generate_pix`) {

            return (
                <div>
                    <div style={{ marginBottom: 50, width: '100%', height: 400, overflowY: 'scroll', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 50 }}>
                        <h2 style={{ textAlign: 'center', marginBottom: 0 }}>Pix Gerado!</h2>

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {this.state.editSaleUser && this.state.editSaleUser.phone ?

                                <div onClick={() => { window.open(`https://wa.me/${this.state.editSaleUser.phone.replace(/[^\d]/g, '')}?text=${window.encodeURIComponent(this.state.pixQrCode)}`, '_blank') }} style={{ marginTop: 8, marginRight: 8, cursor: 'pointer', boxShadow: Colors.boxShadow, backgroundColor: '#25D366', display: 'flex', paddingTop: 8, paddingBottom: 8, paddingLeft: 15, paddingRight: 15, borderRadius: 60, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                    <WhatsApp style={{ color: 'white', fontSize: 18 }} />
                                    <div style={{ fontWeight: 'bold', marginLeft: 8, color: 'white' }}>Enviar Código "Copia e Cola"</div>
                                </div>

                                : null}

                            <div onClick={() => { navigator.clipboard.writeText(this.state.pixQrCode); toast.success('Código copiado para a àrea de transferência') }} style={{ marginTop: 8, cursor: 'pointer', boxShadow: Colors.boxShadow, backgroundColor: Colors.primary, display: 'flex', paddingTop: 8, paddingBottom: 8, paddingLeft: 15, paddingRight: 15, borderRadius: 60, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                <FileCopyIcon style={{ color: 'white', fontSize: 18 }} />
                                <div style={{ fontWeight: 'bold', marginLeft: 8, color: 'white' }}>Copiar Código "Copia e Cola"</div>
                            </div>
                        </div>

                        <div style={{ padding: 15, marginTop: 40, backgroundColor: 'white', border: `8px solid ${Colors.primary}`, boxShadow: Colors.boxShadow, borderRadius: 20 }}>
                            <QRCode size={200} value={this.state.pixQrCode} />
                        </div>
                        <p style={{ textAlign: 'center', fontWeight: 'light', fontSize: 12, marginTop: 20 }}>Um e-mail com os dados da comanda foi enviado automáticamente para o e-mail do cliente.</p>
                    </div>

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 20 }}>
                        <DefaultButton onClick={() => {
                            this.setState({
                                editSaleModal: false,
                                editSaleUser: null,
                                editSaleDoc: {},
                                addingPayment: false,
                                paymentValue: 0,
                                actionType: ``,
                                addSaleData: {
                                    ...this.state.addSaleData,
                                    id_user: '',
                                    items: [],
                                    value: 0,
                                    discount: 0,
                                    payment_method: ``
                                }
                            })

                            if (this.props.onIsEditing) { this.props.onIsEditing(false) }

                        }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'100%'} title={'Fechar'} />
                    </div>
                </div>
            );
        }
    }

    editSaleModal() {
        if (this.state.editSaleModal) {
            return (
                this.state.editSaleDoc.status === 'waiting_payment' && this.state.editSaleDoc.payment_method !== 'boleto' && this.state.editSaleDoc.payment_method !== 'pix' ?
                    <div>
                        {!this.state.actionType ?
                            <div>
                                <ul style={styles.ticketCardsContainer}>
                                    <li
                                        onClick={() => { this.setState({ actionType: `edit_sale` }) }}
                                        style={{ color: Colors.dark, border: `1px solid ${Colors.primary}`, ...styles.paymentMethodWrapper }}>
                                        <AddIcon style={styles.paymentIcon} />
                                        Editar Produtos e Serviços
                                    </li>
                                    <li
                                        onClick={() => { this.setState({ actionType: `end_sale` }) }}
                                        style={{ color: Colors.dark, border: `1px solid ${Colors.primary}`, ...styles.paymentMethodWrapper }}>
                                        <AttachMoneyIcon style={styles.paymentIcon} />
                                        Pagamento
                                    </li>
                                </ul>
                                <DefaultButton onClick={(evt) => {

                                    evt.stopPropagation();

                                    this.setState({
                                        editSaleModal: false,
                                        editSaleUser: null,
                                        editSaleDoc: {},
                                        addingPayment: false,
                                        paymentValue: 0,
                                        actionType: ``,
                                        addSaleData: {
                                            ...this.state.addSaleData,
                                            id_user: '',
                                            items: [],
                                            value: 0,
                                            discount: 0,
                                            payment_method: ``
                                        }
                                    })

                                    if (this.props.onIsEditing) { this.props.onIsEditing(false) }

                                }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'100%'} title={'Cancelar'} />
                            </div>
                            : null}
                        {this.state.actionType === `edit_sale` ? this.renderEditProducts() : null}
                        {this.state.actionType === `end_sale` ? this.renderEndSale() : null}
                        {this.state.actionType === `generate_boleto` ? this.renderGenerateBoleto() : null}
                        {this.state.actionType === `generate_pix` ? this.renderGeneratePix() : null}
                    </div>
                    : this.state.editSaleDoc.status === 'paid' ?
                        <div>
                            <h4 style={{ color: Colors.primary, fontSize: 16 }}>Valor da venda {CurrencyHelper.centsToMoney(this.state.editSaleDoc.amount)}</h4>
                            <h4 style={{ color: Colors.error, fontSize: 16 }}>Desconto {CurrencyHelper.centsToMoney(this.state.editSaleDoc.discount)}</h4>

                            {this.renderPaymentHistory()}

                            <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                                <DefaultButton onClick={(evt) => {

                                    evt.stopPropagation();

                                    this.setState({
                                        editSaleModal: false,
                                        editSaleUser: null,
                                        editSaleDoc: {},
                                        addingPayment: false,
                                        paymentValue: 0,
                                        actionType: ``,
                                        addSaleData: {
                                            ...this.state.addSaleData,
                                            id_user: '',
                                            items: [],
                                            value: 0,
                                            discount: 0,
                                            payment_method: ``
                                        }
                                    })

                                    if (this.props.onIsEditing) { this.props.onIsEditing(false) }

                                }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'100%'} title={'Fechar'} />
                            </div>
                        </div>
                        : this.state.editSaleDoc.status === 'waiting_payment' && this.state.editSaleDoc.payment_method === 'boleto' ?
                            <div>
                                <h4 style={{ color: Colors.primary, fontSize: 16 }}>Valor da venda {CurrencyHelper.centsToMoney(this.state.editSaleDoc.amount)}</h4>
                                <h4 style={{ color: Colors.error, fontSize: 16 }}>Desconto {CurrencyHelper.centsToMoney(this.state.editSaleDoc.discount)}</h4>

                                {this.renderPaymentHistory()}

                                <h4 style={{ color: Colors.primary, fontSize: 16, color: 'orange' }}>Boleto no valor acima gerado. Aguardando o pagamento, quando for pago a comanda será marcada como paga automaticamente.</h4>


                                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingTop: 20 }} >
                                    <DefaultButton onClick={() => {
                                        this.getOrderBoleto(this.state.editSaleDoc.id_transaction_pagarme);
                                    }} color={Colors.primary} loading={this.state.loadingModal} width={'100%'} title={'Visualizar Boleto Gerado'} />

                                    <div style={{ paddingTop: 5 }} />

                                    <DefaultButton onClick={(evt) => {

                                        evt.stopPropagation();

                                        this.setState({
                                            editSaleModal: false,
                                            editSaleUser: null,
                                            editSaleDoc: {},
                                            addingPayment: false,
                                            paymentValue: 0,
                                            actionType: ``,
                                            addSaleData: {
                                                ...this.state.addSaleData,
                                                id_user: '',
                                                items: [],
                                                value: 0,
                                                discount: 0,
                                                payment_method: ``
                                            }
                                        })

                                        if (this.props.onIsEditing) { this.props.onIsEditing(false) }

                                    }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'100%'} title={'Fechar'} />
                                </div>
                            </div >
                            :

                            this.state.editSaleDoc.status === 'waiting_payment' && this.state.editSaleDoc.payment_method === 'pix' ?
                                <div>
                                    <h4 style={{ color: Colors.primary, fontSize: 16 }}>Valor da venda {CurrencyHelper.centsToMoney(this.state.editSaleDoc.amount)}</h4>
                                    <h4 style={{ color: Colors.error, fontSize: 16 }}>Desconto {CurrencyHelper.centsToMoney(this.state.editSaleDoc.discount)}</h4>

                                    {this.renderPaymentHistory()}

                                    <h4 style={{ color: Colors.primary, fontSize: 16, color: 'orange' }}>Pix no valor acima gerado. Aguardando o pagamento, quando for pago a comanda será marcada como paga automaticamente.</h4>


                                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingTop: 20 }}>

                                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                                            {this.state.editSaleUser && this.state.editSaleUser.phone ?

                                                <div onClick={() => { window.open(`https://wa.me/${this.state.editSaleUser.phone.replace(/[^\d]/g, '')}?text=${window.encodeURIComponent(this.state.editSaleDoc.pix_qr_code)}`, '_blank') }} style={{ marginTop: 8, marginRight: 8, cursor: 'pointer', boxShadow: Colors.boxShadow, backgroundColor: '#25D366', display: 'flex', paddingTop: 8, paddingBottom: 8, paddingLeft: 15, paddingRight: 15, borderRadius: 60, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                    <WhatsApp style={{ color: 'white', fontSize: 18 }} />
                                                    <div style={{ fontWeight: 'bold', marginLeft: 8, color: 'white' }}>Enviar Código "Copia e Cola"</div>
                                                </div>

                                                : null}

                                            <div onClick={() => { navigator.clipboard.writeText(this.state.editSaleDoc.pix_qr_code); toast.success('Código copiado para a àrea de transferência') }} style={{ marginTop: 8, cursor: 'pointer', boxShadow: Colors.boxShadow, backgroundColor: Colors.primary, display: 'flex', paddingTop: 8, paddingBottom: 8, paddingLeft: 15, paddingRight: 15, borderRadius: 60, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                <FileCopyIcon style={{ color: 'white', fontSize: 18 }} />
                                                <div style={{ fontWeight: 'bold', marginLeft: 8, color: 'white' }}>Copiar Código "Copia e Cola"</div>
                                            </div>
                                        </div>

                                        <div style={{ padding: 15, marginTop: 40, marginBottom: 40, backgroundColor: 'white', border: `8px solid ${Colors.primary}`, boxShadow: Colors.boxShadow, borderRadius: 20 }}>
                                            <QRCode size={200} value={this.state.editSaleDoc.pix_qr_code} />
                                        </div>

                                        <div style={{ paddingTop: 5 }} />

                                        <DefaultButton onClick={(evt) => {

                                            evt.stopPropagation();

                                            this.setState({
                                                editSaleModal: false,
                                                editSaleUser: null,
                                                editSaleDoc: {},
                                                addingPayment: false,
                                                paymentValue: 0,
                                                actionType: ``,
                                                addSaleData: {
                                                    ...this.state.addSaleData,
                                                    id_user: '',
                                                    items: [],
                                                    value: 0,
                                                    discount: 0,
                                                    payment_method: ``
                                                }
                                            })

                                            if (this.props.onIsEditing) { this.props.onIsEditing(false) }

                                        }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'100%'} title={'Fechar'} />
                                    </div>
                                </div >

                                : null
            )
        }
    }

    renderPayment() {
        if (this.state.addingPayment) {
            const options = [...this.state.paymentOptions];
            options.forEach((opt, key) => { if (opt.key === 'ticket') options.splice(key, 1) })

            return (
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center', paddingTop: 20, paddingBottom: 20 }}>
                    <DefaultSelect
                        id={'payment_selection'}
                        valueField={'key'}
                        displayField={'label'}
                        value={this.state.addSaleData.payment_method}
                        onChange={(v) => {

                            let addSaleData = this.state.addSaleData;
                            addSaleData['payment_method'] = v.target.value;

                            this.setState({ addSaleData: addSaleData });
                        }}
                        docs={options}
                        label={'Método de pagamento'}
                    />
                    <div style={{ marginRight: 10 }} />
                    <Tooltip title={'Preencher valor total'}><IconButton style={{ height: 64, width: 64, color: Colors.primary }} onClick={() => { this.setState({ paymentValue: (this.state.editSaleDoc.amount - this.getPaidValue()) }) }} ><DoneAllIcon /></IconButton></Tooltip>
                    <CurrencyInput required onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Valor (R$)'} onChange={(values) => { const { formattedValue, value } = values; this.setState({ paymentValue: value * 100 }) }} value={this.state.paymentValue > 0 ? this.state.paymentValue / 100 : this.state.paymentValue} />
                    <div style={{ marginRight: 10 }} />
                    <IconButton style={{ height: 64, width: 64, color: Colors.error }} onClick={() => { this.setState({ addingPayment: false, paymentValue: 0, addSaleData: { ...this.state.addSaleData, payment_method: `` } }) }} ><CloseIcon /></IconButton>
                    <IconButton style={{ height: 64, width: 64, color: Colors.success }} onClick={() => { this.addPaymentValue() }} ><CheckLightIcon /></IconButton>
                </div>
            );
        }
    }

    render() {
        return (
            <div style={{ ...styles.wrapper, backgroundColor: this.props.backgroundColor || `#FFFF99`, maxWidth: this.props.width ? this.props.width : LayoutHelper.isSmallScreen() ? '96%' : LayoutHelper.calcCardSize(), minWidth: this.props.width ? this.props.width : LayoutHelper.isSmallScreen() ? '96%' : LayoutHelper.calcCardSize(), }} onClick={async () => {
                if (!this.props.readOnly) {
                    if (!this.state.editSaleModal) {
                        let user = null;
    
                        if (this.state.doc.id_user) {
    
                            user = this.props.usersDocs.find(item => item.id === this.state.doc.id_user);
                        }
    
                        this.setState({
                            editSaleModal: true,
                            editSaleDoc: this.state.doc,
                            editSaleUser: user,
                            addSaleData: {
                                ...this.state.addSaleData,
                                id_user: this.state.doc.id_user,
                                items: this.state.doc.items,
                                value: this.state.doc.brute_amount,
                                discount: this.state.doc.discount,
                                history: this.state.doc.history
                            },
                        });
    
                        if (this.props.onIsEditing) { this.props.onIsEditing(true) }
                    }
                }
            }}>
                <div onClick={(e) => { navigator.clipboard.writeText(this.state.doc.title); toast.info('Nº do pedido copiado!'); e.stopPropagation(); }} style={styles.titleContainer}><div style={styles.title}>{this.state.doc.title}</div> <FileCopyIcon style={{ ...styles.title, marginLeft: 6, marginBottom: 2 }} /></div>
                <div style={styles.primaryText}>{CurrencyHelper.centsToMoney(this.state.doc.amount)}</div>
                <div style={styles.primaryText}>
                    <RelationField collection={'user'} id={this.state.doc.id_user} field={'name'} loading={false} />
                </div>
                {this.renderTags()}
                {this.renderImages()}

                <DefaultModal loading={this.state.loadingModal} content={this.editSaleModal()} title={'Editar Comanda'} onClose={(evt) => {

                    evt.stopPropagation();

                    this.setState({
                        editSaleModal: false,
                        editSaleUser: null,
                        editSaleDoc: {},
                        addingPayment: false,
                        paymentValue: 0,
                        actionType: ``,
                        addSaleData: {
                            ...this.state.addSaleData,
                            id_user: '',
                            items: [],
                            value: 0,
                            discount: 0,
                            payment_method: ``
                        }
                    })

                    if (this.props.onIsEditing) { this.props.onIsEditing(false) }

                }} open={this.state.editSaleModal} width={window.screen.width < 1360 ? '99%' : window.screen.width - (window.screen.width * 0.3)} />
            </div>
        );
    }
}

const styles = {
    wrapper: {
        margin: 10,
        cursor: `pointer`,
        height: 'auto',
        flexGrow: 1,
        marginTop: 5,
        borderRadius: 0,
        padding: 10,
        boxShadow: `0px 0px 2px 0px rgba(34,34,34,0.46)`
    },
    title: {
        fontWeight: 'bold',
        fontSize: 16,
        color: Colors.dark
    },
    primaryText: {
        fontSize: 16,
        color: Colors.dark
    },
    titleIcon: {
        fontSize: 16,
        marginRight: 3
    },
    titleContainer: {
        display: `flex`,
        flexDirection: `row`,
        justifyContent: `flex-start`,
        alignItems: `center`,
        marginBottom: 4
    },
    text: {
        fontSize: 14,
        color: Colors.formLabel
    },
    teacherImage: {
        borderRadius: 12,
        heigth: 24,
        width: 24
    },
    teacherImageContainer: {
        marginTop: 10,
        width: `100%`,
        cursor: `pointer`,
        display: `flex`,
        flexDirection: `row`,
        justifyContent: `space-between`,
    },
    teacherImageContainer: {
        marginTop: 10,
        width: `100%`,
        cursor: `pointer`,
        display: `flex`,
        flexDirection: `row`,
        justifyContent: `space-between`,
    },
    indicatorTag: {
        marginTop: 8,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 2,
        paddingTop: 2,
        color: '#fff',
        borderRadius: 5,
        textAlign: 'center',
        width: 110,
        fontWeight: 'bold',
        fontSize: 13
    },
    ticketCardsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 0,
        listStyleType: 'none',
        paddingInlineStart: 0
    },
    paymentMethodWrapper: {
        display: `flex`,
        flexDirection: 'column',
        margin: 10,
        cursor: `pointer`,
        backgroundColor: `#fff`,
        fontSize: 20,
        justifyContent: 'center',
        alignItems: 'center',
        height: 200,
        width: 200,
        flexGrow: 1,
        marginTop: 5,
        borderRadius: 5,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5
    },
    paymentIcon: {
        color: Colors.primary,
        fontSize: 64,
        marginBottom: 10
    },
    clientSelectionText: {
        color: `white`,
        fontSize: 12,
        paddingLeft: 5,
        paddingRight: 5,
        borderRadius: 5,
        marginLeft: 5,
        maxWidth: 100
    },
};