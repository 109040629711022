import React, { Component } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import Colors from '../constants/Colors';

export default class DefaultLoader extends Component {
  render() {
    return (
      <div style={this.props.css ? this.props.css : { display: 'flex', margin: 'auto', marginTop: '22%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

        {this.props.progress ?
          <div style={styles.progressBarContainer}>
            <div style={styles.progressBar}>
              <div style={{ ...styles.progressElement, backgroundColor: this.props.color ? this.props.color : Colors.primary, width: `${parseInt(100 * (this.props.progress / 100))}%` }} >{parseInt(this.props.progress)}%</div>
            </div>
          </div>
          : <ClipLoader
            size={this.props.size ? this.props.size : 50}
            color={this.props.color ? this.props.color : Colors.primary}
            loading={true}
          />
          }

        {this.props.loadingText ? <h2 style={{ color: this.props.color ? this.props.color : Colors.primary, fontSize: 16 }}>{this.props.loadingText}</h2> : null}
      </div>
    );
  }
}

const styles = {
  progressBarContainer: {
    display: 'flex',
    marginBottom: 15,
    width: '90%',
    justifyContent: 'center'
  },
  progressBar: {
    backgroundColor: Colors.notSelected,
    borderRadius: 12,
    width: '100%',
    height: 24
  },
  progressElement: {
    borderRadius: 'inherit',
    height: 'inherit',
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'right',
    padding: 2,
    paddingRight: 5,
    backgroundColor: Colors.primary
  }
}