import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Firestore from '../api/firebase/Firestore';
import DefaultLoader from './DefaultLoader';
import TournamentKeys from './TournamentKeys';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import SettingsIcon from '@material-ui/icons/Settings';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CasinoIcon from '@material-ui/icons/Casino';
import CheckIcon from '@material-ui/icons/CheckCircle';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import GroupIcon from '@material-ui/icons/Group';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import CloseIcon from '@material-ui/icons/Close';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import SyncIcon from '@material-ui/icons/Sync';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Colors from '../constants/Colors';
import { Button, Tooltip, MenuItem, Accordion, AccordionSummary, AccordionDetails, Tabs, Tab, Box, Select, InputLabel, IconButton, Table, TableHead, TableBody, TableRow, TableCell, FormLabel, TextareaAutosize, Stepper, Step, StepLabel, RadioGroup, FormControlLabel, Radio, Menu } from '@material-ui/core';
import moment from "moment-timezone";
import TournamentColumnView from './TournamentColumnView';
import TournamentMatchPanel from './TournamentMatchPanel';
import DefaultModal from './DefaultModal';
import DefaultButton from './DefaultButton';
import DefaultSelect from './DefaultSelect';
import DefaultInput from './DefaultInput';
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker, KeyboardTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { toast } from 'react-toastify';
import RoomIcon from '@material-ui/icons/Room';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CommentIcon from '@material-ui/icons/Comment';
import Functions from '../api/firebase/Functions';
import SessionHelper from '../helper/SessionHelper';
import IosSwitch from './IosSwitch';
import InputMask from "react-input-mask";
import NotificationHelper from '../helper/NotificationHelper';
import PersonIcon from '@material-ui/icons/Person';
import PrintIcon from '@material-ui/icons/Print';
import jsPDF from 'jspdf';
import ReportHelper from '../helper/ReportHelper';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PagarmeConstants from '../constants/PagarMe';
import Pagarme from '../api/pagarme/Pagarme';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import QRCode from 'qrcode.react';
import HeaderActionsWrapper from './HeaderActionsWrapper';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import EditIcon from '@material-ui/icons/Edit';
import TournamentHelper from '../helper/TournamentHelper';
import TournamentGroups from './TournamentGroups';
import DefaultTable from './DefaultTable';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import TermHelper from '../helper/TermHelper';
import CurrencyHelper from '../helper/CurrencyHelper';
import LayoutHelper from '../helper/LayoutHelper';
import RelationField from './grid/RelationField';
import FileCopy from '@material-ui/icons/FileCopy';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import Cancel from '@material-ui/icons/Cancel';
import DataHelper from '../helper/DataHelper';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        maxWidth: '100%',
        fontSize: 13
    }
}))(Tooltip);
export default class TournamentView extends Component {

    state = {
        loading: true,
        loadingModal: false,
        loadingBracketSizes: false,
        tournament: {},
        courts: [],
        userDocs: [],
        groupResultsTableMatches: [],
        selectedClass: null,
        selectedCategory: null,
        classKey: null,
        categoryKey: null,
        tab: 0,
        matchOpen: false,
        matchPanel: null,
        observationModal: false,
        addParticipantModal: false,
        editMatchModal: false,
        groupResultsTable: false,
        selectedGroupIndex: -1,
        editMatchData: null,
        courtModal: false,
        resultModal: false,
        resultSets: [{ p1: 0, p2: 0 }],
        resultSetsTiebreak: [{ p1: 0, p2: 0 }],
        saveResultSets: [{ p1: 0, p2: 0 }],
        resultTiebreak: {},
        editCategoryModal: false,
        matchHourModal: false,
        rescheduleModal: false,
        rescheduleFrom: null,
        rescheduleTo: null,
        settingsModal: false,
        hasAbsoluteLimit: false,
        absoluteLimit: '',
        internalEnrollOnly: false,
        name: '',
        award: '',
        addParticipantModalData: {
            categoryIndex: null,
            classIndex: null,
            boletoLink: '',
            boletoCode: '',
            steps: ['Categoria', 'Classe', 'Dados da Inscrição', 'Finalização'],
            studentPrice: 'non_student',
            step: 0,
            name: '',
            secondName: '',
            email: '',
            observation: '',
            paymentMethod: 'boleto',
            pixQrCode: '',
        },
        isWo: false,
        gaveUp: false,
        optionsMenuOpen: false,
        ticketStart: null,
        ticketLimit: null,
        start: null,
        end: null,
        playerSwap: {
            p1: [],
            p2: []
        }
    }

    async componentDidMount() {
        await this.sync();
    }

    async sync() {
        await this.setState({ loading: true });

        await this.getTournament();
        await this.getUsers();
        await this.getTournamentCourts();

        await this.setState({ loading: false });
    }

    async getTournament() {
        try {

            let tournamentQuery = await Firestore.getDoc('tournament', this.props.tournament);
            let tournament = {};

            if (tournamentQuery.exists) {

                tournament = { ...tournamentQuery.data(), id: tournamentQuery.id };

                tournament.category.forEach((category, key) => {
                    tournament.category[key].expanded = true;
                });

                this.setState({
                    tournament: tournament,
                    hasAbsoluteLimit: tournament.has_absolute_limit || false,
                    absoluteLimit: tournament.absolute_limit || '',
                    internalEnrollOnly: tournament.internal_enroll_only || false,
                    ticketStart: tournament.ticket_start.toDate ? tournament.ticket_start.toDate() : tournament.ticket_start || new Date(),
                    ticketLimit: tournament.ticket_limit.toDate ? tournament.ticket_limit.toDate() : tournament.ticket_limit || new Date(),
                    start: tournament.start.toDate ? tournament.start.toDate() : tournament.start || new Date(),
                    end: tournament.end.toDate ? tournament.end.toDate() : tournament.end || new Date(),
                    award: tournament.award || '',
                    name: tournament.name || 'Torneio no ACE',
                });
            }

        } catch (error) {

            this.setState({ loadError: true });
        }
    }

    async getUsers() {
        let participants = TournamentHelper.getTournamentParticipants(this.state.tournament);
        let users = [];

        if (participants && participants.length) {

            participants.forEach(async (participant, key) => {

                let user = await Firestore.getDoc('user', participant);

                if (user.exists) {

                    let data = { ...user.data(), id: user.id };

                    users.push(data);
                }
            });
        }

        this.setState({ userDocs: users });
    }

    async getTournamentCourts() {

        let courts = this.state.tournament.courts || [];

        this.setState({ courts: courts });
    }

    async sortPlayers() {
        if (this.state.loading) return;

        let confirm = window.confirm('Tem certeza que deseja sortear os jogadores nas chaves do torneio?');

        if (confirm) {

            try {

                let orders = await Firestore.customQuery('order').where('id_tournament', '==', this.state.tournament.id).where('id_company', '==', SessionHelper.getData().id_company).get();
                let canSort = true;

                orders.forEach((order, key) => {

                    let data = { ...order.data() };

                    if (data.payment_method === 'boleto' && data.status !== 'paid') {

                        canSort = false;
                        return;
                    }
                });

                let sortAnyways = false;

                if (!canSort) {
                    let secondaryConfirm = window.confirm('Há jogadores com pagamento de inscrição pendente, tem certeza que quer sortear as chaves mesmo assim?');
                    if (secondaryConfirm) sortAnyways = true;
                }

                await this.setState({ loading: true });

                if (canSort || sortAnyways) {

                    await Firestore.update({ sorted_players: true, sort_date: new Date() }, 'tournament', this.state.tournament.id);
                    await Functions.request('PUT', `tournament/drawPlayersIntoTournament/${this.state.tournament.id}`, {});

                    await this.getTournament();
                    await this.setState({ loading: false });

                    await this.changeView(this.state.tab, true);

                    toast.success('Jogadores sorteados com sucesso');

                } else {

                    toast.warn('Existem inscrições pendentes de pagamento. Acesse a página de pedidos para visualizar as inscrições.', { autoClose: false });
                    await this.setState({ loading: false });
                }

            } catch (error) {

                toast.error('Houve um problema ao inserir os jogadores');
                this.setState({ loading: false });
            }
        }
    }

    async makeBracketsVisible() {
        if (this.state.loading) return;

        let confirm = window.confirm('Tem certeza que deseja deixar as chaves visiveis aos participantes do torneio? Se você aceitar, alterações à partir de agora serão visualizadas por todos os participantes. Uma vez visível não é possível ocultar novamente.');

        this.setState({ loading: true });

        if (confirm) {
            try {
                let visible_brackets = new Date();
                await Firestore.update({ visible_brackets }, 'tournament', this.state.tournament.id);
                toast.success('Chaves dos torneios agora estão visíveis aos participantes');

                await this.getTournament();
                await this.sendVisibleBracketsNotifications();
            } catch (e) {
                toast.error('Ocorreu um erro ao deixar as chaves visíveis, recarregue a página e tente novamente.');
            }

        }

        this.setState({ loading: false });
    }

    renderHeader() {

        return (
            <HeaderActionsWrapper style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ alignItems: 'center' }} className={'header-actions-buttons'}>
                    <Tooltip title={'Voltar'}>
                        <Button style={{ color: 'white', backgroundColor: Colors.primary }} variant={'contained'} onClick={() => { this.props.back() }}>
                            <ArrowBackIcon style={{ marginLeft: 3 }} />
                        </Button>
                    </Tooltip>
                    {!this.state.tournament.sorted_players ?
                        <Tooltip title={'Adicionar Participante Manualmente'}>
                            <Button style={{ color: 'white', backgroundColor: Colors.primary, marginLeft: 8 }} variant={'contained'} onClick={async () => { await await this.getTournament(); this.setState({ addParticipantModal: true }); }}>
                                <PersonAddIcon />
                            </Button>
                        </Tooltip>
                        : null}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <EmojiEventsIcon style={{ marginRight: 8, color: '#f0d03e', fontSize: 20 }} />
                    <h3>{this.state.tournament.name}</h3>
                </div>

                <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                    <Tooltip title={this.state.tournament.sorted_players ? `Sorteio feito em ${moment(this.state.tournament.sort_date.toDate()).format('DD/MM/YY HH:mm')}` : 'Sortear Jogadores'}>
                        <div style={{ marginBottom: 10 }}>
                            <Button disabled={this.state.tournament.sorted_players} style={{ color: 'white', backgroundColor: this.state.tournament.sorted_players ? Colors.success : Colors.primary, marginRight: 8 }} variant={'contained'} onClick={() => { this.sortPlayers() }}>
                                {this.state.tournament.sorted_players ?
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 8 }}>
                                        <CasinoIcon style={{ marginRight: 5 }} />
                                        <CheckIcon />
                                    </div>
                                    :
                                    <CasinoIcon style={{ marginLeft: 3 }} />}
                            </Button>
                        </div>
                    </Tooltip>
                    {this.state.tournament.sorted_players ?
                        <Tooltip title={this.state.tournament.visible_brackets ? `Chaves Visíveis desde ${moment(this.state.tournament.visible_brackets.toDate()).format('DD/MM/YY HH:mm')}` : 'Visibilidade das chaves aos participantes do torneio'}>
                            <div style={{ marginBottom: 10 }}>
                                <Button disabled={this.state.tournament.visible_brackets} style={{ color: 'white', backgroundColor: this.state.tournament.visible_brackets ? Colors.success : Colors.danger, marginRight: 8 }} variant={'contained'} onClick={() => { this.makeBracketsVisible(true) }}>
                                    {this.state.tournament.visible_brackets ?
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 5 }}>
                                            <VisibilityIcon style={{ marginRight: 5 }} />
                                            <CheckIcon />
                                        </div>
                                        : <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 5 }}>
                                            <VisibilityOffIcon style={{ marginRight: 5 }} />
                                            <div style={{ fontSize: 13 }}>{`Chaves Ocultas`}</div>
                                        </div>
                                    }
                                </Button>
                            </div>
                        </Tooltip>
                        : null}

                    <div style={{ marginBottom: 10 }}>
                        <DefaultButton disabled={this.state.loading} color={Colors.primary} width={220} rightIcon={<ArrowDropDown style={{ marginLeft: 6 }} />} leftIcon={<MoreVertIcon style={{ marginRight: 6 }} />} onClick={(evt) => { this.setState({ optionsMenuOpen: evt.currentTarget }) }} title={'Ferramentas'} />
                    </div>

                    <Menu
                        anchorEl={this.state.optionsMenuOpen}
                        style={{ marginTop: 50 }}
                        keepMounted
                        open={Boolean(this.state.optionsMenuOpen)}
                        onClose={() => { this.setState({ optionsMenuOpen: null }) }}>

                        <MenuItem style={{ color: Colors.dark, fontWeight: 'bold', paddingTop: 12, paddingBottom: 12 }} onClick={() => { this.setState({ rescheduleModal: true }) }}>
                            <SyncIcon style={{ marginRight: 6 }} />
                            Reagendar Partidas
                        </MenuItem>
                        <MenuItem style={{ color: Colors.dark, fontWeight: 'bold', paddingTop: 12, paddingBottom: 12 }} onClick={() => { this.setState({ matchHourModal: true }) }}>
                            <AccessTimeIcon style={{ marginRight: 6 }} />
                            Horário das Partidas
                        </MenuItem>
                        <MenuItem style={{ color: Colors.dark, fontWeight: 'bold', paddingTop: 12, paddingBottom: 12 }} onClick={() => { this.props.openParticipantsModal(this.state.tournament) }}>
                            <GroupIcon style={{ marginRight: 6 }} />
                            Inscrições
                        </MenuItem>
                        <MenuItem style={{ color: Colors.dark, fontWeight: 'bold', paddingTop: 12, paddingBottom: 12 }} onClick={() => { this.setState({ observationModal: true }) }}>
                            <CommentIcon style={{ marginRight: 6 }} />
                            Observações
                        </MenuItem>
                        <MenuItem style={{ color: Colors.dark, fontWeight: 'bold', paddingTop: 12, paddingBottom: 12 }} onClick={() => { this.setState({ courtModal: true }) }}>
                            <RoomIcon style={{ marginRight: 6 }} />
                            Quadras
                        </MenuItem>
                        <MenuItem style={{ color: Colors.dark, fontWeight: 'bold', paddingTop: 12, paddingBottom: 12 }} onClick={() => { this.setState({ settingsModal: true }) }}>
                            <SettingsIcon style={{ marginRight: 6 }} />
                            Configurações
                        </MenuItem>

                    </Menu>
                </div>


            </HeaderActionsWrapper>
        )
    }

    toggleCategory(key) {
        let tournament = this.state.tournament;
        tournament.category[key].expanded = !tournament.category[key].expanded;

        this.setState({ tournament });
    }

    async changeClass(category, categoryClass, categoryKey, classKey) {

        if (!this.state.selectedClass || (this.state.selectedClass.name !== categoryClass.name || this.state.selectedCategory.name !== category.name)) {
            let tabIndex = 0;

            if (!categoryClass.bracket_type) categoryClass.bracket_type = 'eliminatory';

            if (categoryClass.bracket_type === 'group') {
                tabIndex = 2;
            } else {
                tabIndex = 0;
            }

            await this.setState({ selectedClass: null, selectedCategory: null, loading: true, classKey: null, categoryKey: null });
            await this.setState({ selectedCategory: category, selectedClass: categoryClass, classKey: classKey, categoryKey: categoryKey, loading: false, tab: tabIndex });
        }
    }

    renderCategories() {

        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: LayoutHelper.isSmallScreen() ? '100%' : '15%', marginBottom: LayoutHelper.isSmallScreen() ? 10 : 0 }}>
                {
                    this.state.tournament.category.map((category, key) => {

                        if (category.checked) {

                            return (
                                <Accordion expanded={category.expanded} style={{ width: '100%', border: '1px solid lightgrey', margin: 0 }}>
                                    <AccordionSummary onClick={() => { this.toggleCategory(key) }} expandIcon={!category.expanded ? <ChevronRightIcon /> : <ExpandLessIcon />}>
                                        <h3>{category.title}</h3>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                                        {
                                            category.classes.map((categoryClass, categoryClassKey) => {

                                                if (categoryClass.checked) {

                                                    return (
                                                        <Tooltip title={categoryClass.title}>
                                                            <MenuItem
                                                                selected={this.state.selectedCategory && this.state.selectedClass
                                                                    ? categoryClass.name === this.state.selectedClass.name && category.name === this.state.selectedCategory.name : false}
                                                                onClick={() => { this.changeClass(category, categoryClass, key, categoryClassKey) }}
                                                                style={{ padding: 15, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                {categoryClass.title}
                                                            </MenuItem>
                                                        </Tooltip>
                                                    );
                                                }
                                            })
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            )
                        }
                    })
                }
            </div>
        )
    }

    TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                {...other}>
                {value === index && (
                    <Box style={{ backgroundColor: '#f5f5f5' }}>
                        <div style={{ overflowX: 'scroll' }}>{children}</div>
                    </Box>
                )}
            </div>
        );
    }

    async changeView(index, force = false) {

        if (index !== this.state.tab || force) {

            await this.setState({ selectedClass: null, selectedCategory: null });

            let category = this.state.categoryKey;
            let classKey = this.state.classKey;

            let tournament = this.state.tournament;

            if (tournament.category[category] && tournament.category[category].classes[classKey]) {

                await this.setState({ tab: index, selectedCategory: tournament.category[category], selectedClass: tournament.category[category].classes[classKey] });
                this.forceUpdate();
            }
        }
    }

    editCategoryModal() {
        if (this.state.selectedCategory && this.state.selectedClass && this.state.editCategoryModal) {
            return (
                <div>
                    <DefaultInput defaultValue={this.state.selectedClass.title} onBlur={(v) => { this.setState({ selectedClass: { ...this.state.selectedClass, title: v } }) }} label={'Nome'} />

                    {!this.state.tournament.sorted_players ?
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', alignContent: 'center', width: '100%', marginBottom: 15, marginTop: 15 }}>
                                <HtmlTooltip placement={'bottom'} width={600} title={TournamentHelper.renderBracketDescription(this.state.selectedClass.bracket_type)}>
                                    <InfoRoundedIcon style={{ marginRight: 10, fontSize: 28 }} />
                                </HtmlTooltip>

                                <DefaultSelect
                                    value={this.state.selectedClass.bracket_type}
                                    valueField={'name'}
                                    displayField={'title'}
                                    id={`${this.state.selectedClass.title}-${this.state.classKey}-select-bracket-type`}
                                    onChange={(v) => {
                                        let selectedClass = this.state.selectedClass;
                                        selectedClass.bracket_type = v.target.value;
                                        selectedClass.limit = TournamentHelper.getBracketSizes()[v.target.value][0].value;

                                        this.setState({ selectedClass });

                                        this.handleBracketLimitChange();
                                    }}
                                    docs={TournamentHelper.getBracketTypes() || []}
                                    label={`Tipo de Chave`}
                                />
                            </div>

                            {!this.state.loadingBracketSizes ?
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', alignContent: 'center', width: '100%' }}>
                                    <Tooltip title={`Gera ${TournamentHelper.calculateMatchesGeneratedByBracketType(this.state.selectedClass.bracket_type, this.state.selectedClass.limit)} partidas`}>
                                        <InfoRoundedIcon style={{ marginRight: 10, fontSize: 28 }} />
                                    </Tooltip>
                                    <DefaultSelect
                                        value={this.state.selectedClass.limit}
                                        valueField={'value'}
                                        displayField={'title'}
                                        id={`${this.state.selectedClass.title}-${this.state.classKey}-select-bracket-size`}
                                        onChange={(v) => {
                                            let selectedClass = this.state.selectedClass;
                                            selectedClass.limit = v.target.value;
                                            this.setState({ selectedClass });
                                        }}
                                        docs={TournamentHelper.getBracketSizes()[this.state.selectedClass.bracket_type] || []}
                                        label={`Limite de Duplas/Participantes`}
                                    />
                                </div>
                                : <DefaultLoader size={28} color={Colors.primary} css={{ marginTop: 2 }} />}

                            <FormLabel style={{ paddingBottom: 15, paddingTop: 20, fontSize: 13 }} component="legend">Não afeta inscrições já realizadas, apenas limita para novas inscrições à partir de agora.</FormLabel>
                        </div> :
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', alignContent: 'center', width: '100%', backgroundColor: Colors.primary, marginTop: 20, marginBottom: 20, borderRadius: 10 }}>
                                {TournamentHelper.renderBracketDescription(this.state.selectedClass.bracket_type || 'eliminatory')}
                            </div>

                            <FormLabel>Tipo de chave não pode ser editado após o sorteio</FormLabel>
                        </div>
                    }

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.applyCategoryEdit() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'SALVAR'} />
                        <DefaultButton onClick={() => { this.setState({ editCategoryModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            );
        }
    }

    applyCategoryEdit = async () => {
        this.setState({ loadingModal: true });

        if (!this.state.selectedClass.title) {
            toast.info('Nome não pode ser vazio');
            return;
        }

        let tournament = await Firestore.getDoc('tournament', this.state.tournament.id);

        if (tournament.exists) {
            try {
                let category = tournament.data().category;
                let categoryKey = this.state.categoryKey;
                let classKey = this.state.classKey;

                let hasToGenerateBrackets = category[categoryKey].classes[classKey].bracket_type !== this.state.selectedClass.bracket_type || category[categoryKey].classes[classKey].limit !== this.state.selectedClass.limit;

                if (category[categoryKey] && category[categoryKey].classes[classKey]) {
                    if (category[categoryKey].classes[classKey].title !== this.state.selectedClass.title) category[categoryKey].classes[classKey].title = this.state.selectedClass.title;
                    if (category[categoryKey].classes[classKey].bracket_type !== this.state.selectedClass.bracket_type) category[categoryKey].classes[classKey].bracket_type = this.state.selectedClass.bracket_type;
                    if (category[categoryKey].classes[classKey].limit !== this.state.selectedClass.limit) category[categoryKey].classes[classKey].limit = this.state.selectedClass.limit;

                    await Firestore.update({ category }, 'tournament', tournament.id);
                }

                if (hasToGenerateBrackets) {
                    await this.props.generateBrackets(tournament.id, async () => { await this.sync(); await this.changeView(this.state.tab, true); this.setState({ editCategoryModal: false, loadingModal: false }); toast.success(`Editado com sucesso`); });
                } else {
                    this.setState({ editCategoryModal: false, loadingModal: false });
                    toast.success(`Editado com sucesso`);
                }
            } catch (e) {
                toast.error(`Erro ao editar`);
                this.setState({ editCategoryModal: false, loadingModal: false });
            }

        }
    }

    handleBracketLimitChange = async () => {
        this.setState({ loadingBracketSizes: true });

        setTimeout(() => {
            this.setState({ loadingBracketSizes: false });
        }, 50);
    }

    renderCategoryHeader() {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                {!this.state.sort_date && !this.state.sorted_players ?
                    <IconButton style={{ marginRight: 5 }} onClick={() => { this.setState({ editCategoryModal: true }) }}>
                        <EditIcon />
                    </IconButton>
                    : null}
                <h4>{`${this.state.selectedCategory.title} - ${this.state.selectedClass.title}`}</h4>
            </div>
        );
    }

    renderMatches() {
        if (this.state.selectedClass.notEnoughPlayers) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: LayoutHelper.isSmallScreen() ? '100%' : '85%' }}>
                    <img style={{ height: LayoutHelper.isSmallScreen() ? 210 : 240, marginBottom: 40 }} src={process.env.PUBLIC_URL + '/ilustrations/blank_canvas.png'} />
                    <p style={{ color: Colors.dark, fontWeight: '600', maxWidth: LayoutHelper.isSmallScreen() ? 280 : '60%' }}>Só há um jogador inscrito nesta classe, não é possível gerar partidas, cancele o pedido deste jogador em "Financeiro &gt; Pedidos" para devolver o dinheiro caso necessário.</p>
                    <p style={{ color: Colors.dark, fontWeight: '600', maxWidth: LayoutHelper.isSmallScreen() ? 280 : '60%' }}>{this.state.selectedClass.participants && this.state.selectedClass.participants[0] && this.state.selectedClass.participants[0].id_order ? <RelationField render={(title) => title ? <div style={{ cursor: 'pointer' }} onClick={(e) => { navigator.clipboard.writeText(title); toast.info('Nº do pedido copiado!'); e.stopPropagation(); }}>{title} <FileCopy style={{ fontSize: 12, marginLeft: 3, marginTop: 1 }} /> </div> : 'Sem pedido'} collection={'order'} id={this.state.selectedClass.participants[0].id_order} field={'title'} loading={true} /> : null}</p>
                    <p style={{ color: Colors.dark, fontWeight: '600', maxWidth: LayoutHelper.isSmallScreen() ? 280 : '60%' }}>{this.state.selectedClass.participants && this.state.selectedClass.participants[0] && this.state.selectedClass.participants[0].id_order ? <RelationField render={(canceled) => canceled === true ? null : <div style={{ padding: 5, backgroundColor: Colors.danger, color: '#fff', borderRadius: 5 }}>Pedido do jogador ainda em aberto, cancele</div>} collection={'order'} id={this.state.selectedClass.participants[0].id_order} field={'canceled'} loading={true} /> : <div style={{ padding: 5, backgroundColor: Colors.success, color: '#fff', borderRadius: 5 }}>Pedido do jogador já cancelado, nada à fazer</div>}</p>
                </div>
            );
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: LayoutHelper.isSmallScreen() ? '100%' : '85%', border: '1px solid lightgrey', backgroundColor: '#FFF', marginLeft: LayoutHelper.isSmallScreen() ? 0 : 20, borderRadius: 5, boxShadow: Colors.boxShadow }}>
                {this.renderCategoryHeader()}
                <Tabs
                    style={{ backgroundColor: '#fff' }}
                    value={this.state.tab}
                    variant={'fullWidth'}
                    TabIndicatorProps={{ style: { background: Colors.primary } }}
                    onChange={(event, index) => { this.changeView(index) }}>
                    <Tab disabled={this.state.selectedClass.bracket_type === 'group'} label={<span style={{ fontWeight: this.state.tab === 0 ? 'bold' : '', color: Colors.primary, width: 300 }}>{LayoutHelper.isSmallScreen() ? `Colunas` : `Visualização em Colunas`}</span>} />
                    <Tab disabled={this.state.selectedClass.bracket_type === 'group'} label={<span style={{ fontWeight: this.state.tab === 1 ? 'bold' : '', color: Colors.primary, width: 300 }}>{LayoutHelper.isSmallScreen() ? `Chaves` : `Visualização em Chaves`}</span>} />
                    <Tab disabled={this.state.selectedClass.bracket_type === 'eliminatory'} label={<span style={{ fontWeight: this.state.tab === 2 ? 'bold' : '', color: Colors.primary, width: 300 }}>{LayoutHelper.isSmallScreen() ? `Grupos` : `Visualização de Grupos`}</span>} />
                </Tabs>

                <this.TabPanel value={this.state.tab} index={0}>
                    <TournamentColumnView openBracketHeads={!this.state.tournament.visible_brackets && this.state.tournament.sorted_players ? this.openBracketHeads : () => { toast.info('Cabeças de chave só podem ser editadas no momento que o torneio estiver sorteado e com as chaves invisíveis. (Sorteie -> Edite Cabeças de Chave -> Deixe as chaves visíveis)') }} openMatch={(match) => { this.openMatch(match) }} key={0} users={this.state.userDocs} indexes={{ category: this.state.categoryKey, class: this.state.classKey }} tournament={this.state.tournament} rounds={this.state.selectedClass.rounds} />
                </this.TabPanel>
                <this.TabPanel value={this.state.tab} index={1}>
                    <TournamentKeys openMatch={(match) => { this.openMatch(match) }} ref={(ref) => { this.tournamentKeys = ref }} indexes={{ category: this.state.categoryKey, class: this.state.classKey }} key={1} users={this.state.userDocs} tournament={this.state.tournament} rounds={this.state.selectedClass.rounds} />
                </this.TabPanel>
                <this.TabPanel value={this.state.tab} index={2}>
                    <TournamentGroups openMatch={(match) => { this.openMatch(match) }} ref={(ref) => { this.tournamentGroups = ref }} indexes={{ category: this.state.categoryKey, class: this.state.classKey, group: this.state.selectedGroupIndex }} key={2} users={this.state.userDocs} tournament={this.state.tournament} finalists={this.state.selectedClass.finalists} groups={this.state.selectedClass.groups} openResultsTable={async (groupIndex) => { this.setState({ selectedGroupIndex: groupIndex, loadingResultTable: true, groupResultsTable: true }); await this.getGroupResultsTableMatches(groupIndex); }} />
                </this.TabPanel>
            </div>
        )
    }

    renderEmpty() {

        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginLeft: LayoutHelper.isSmallScreen() ? 0 : '25%' }}>
                <img style={{ height: LayoutHelper.isSmallScreen() ? 280 : 350 }} src={process.env.PUBLIC_URL + '/ilustrations/grand_slam.png'} />
                <p style={{ color: 'grey' }}>Selecione uma classe para visualizar as partidas.</p>
            </div>
        )
    }

    openMatch(match) {
        this.setState({ matchOpen: true, matchPanel: match });
    }

    handleMatchEditPlayerSelection(players, position) {
        let match = this.state.editMatchData;

        if (this.state.tournament.type === 'simple') {
            match[position] = [players];
        } else {
            match[position] = TournamentHelper.treatPairSelectionId(players, true);
        }

        if (players.length < 1) match[position] = [];

        this.setState({ editMatchData: match });
    }

    async updateMatch() {
        if ((this.state.editMatchData.court && this.state.editMatchData.start && this.state.editMatchData.end) || this.isBye(this.state.editMatchData)) {
            try {

                this.setState({ loadingModal: true });

                let matchExists = await Firestore.getDoc('tournament_event', this.state.editMatchData.id);

                if (matchExists.exists) {
                    let match = this.state.editMatchData;

                    let id_players = [];

                    if (match.p1 != null) match.p1.forEach(p => id_players.push(p))
                    if (match.p2 != null) match.p2.forEach(p => id_players.push(p))

                    let editedData = {
                        court: this.state.editMatchData.court,
                        id_court: this.state.editMatchData.id_court,
                        id_players,
                        start: this.state.editMatchData.start,
                        end: this.state.editMatchData.end,
                        p1: this.state.editMatchData.p1,
                        p2: this.state.editMatchData.p2
                    }

                    if (this.state.tournament.id && match.id) {
                        await Firestore.update({ ...editedData }, 'tournament_event', this.state.editMatchData.id);
                        await Functions.request('POST', `tournament/notifyMatchEdit/${this.state.tournament.id}/${this.state.editMatchData.id}`, {});
                    }
                }

                toast.success('Partida editada com sucesso');
                await this.setState({ loadingModal: false, editMatchModal: false, editMatchData: null, panelOpen: false, matchPanel: null });

                this.changeView(this.state.tab, true);
            } catch (error) {
                this.setState({ loadingModal: false });
                toast.error('Houve um problema ao editar a partida');
            }

        } else {

            toast.warn('Preencha todos os campos');
        }
    }

    async saveMatchResult() {
        if (!this.state.editMatchData.winner) {
            return toast.info('Selecione um vencedor');
        }

        try {
            this.setState({ loadingModal: true });

            let matchExists = await Firestore.getDoc('tournament_event', this.state.editMatchData.id);

            if (matchExists.exists) {
                let match = { ...matchExists.data(), id: matchExists.id };

                let result = {
                    date: new Date(),
                    sets: this.state.saveResultSets,
                    tiebreak: this.state.resultTiebreak,
                    isWo: this.state.isWo,
                    gaveUp: this.state.gaveUp
                };

                let winner = this.state.editMatchData.winner;

                if (this.state.tournament.id && match.id && result && winner) {
                    await Firestore.update({ result, winner }, 'tournament_event', match.id);

                    if (match.indexes.round > -1) await Functions.request('PUT', `tournament/moveWinnerForward/${this.state.tournament.id}/${match.id}/${winner}`, {});
                    await Functions.request('POST', `tournament/notifyMatchResult/${this.state.tournament.id}/${match.id}`, {});
                }
            }

            toast.success('Resultado informado com sucesso');

            await this.setState({ panelOpen: false, matchPanel: null, loadingModal: false, resultModal: false, isWo: false, gaveUp: false, editMatchData: null });
            this.changeView(this.state.tab, true);

            this.changeView(this.state.tab, true);
        } catch (error) {

            this.setState({ loadingModal: false });
            toast.error('Houve um problema ao informar o resultado');
        }
    }

    courtModal() {

        if (this.state.courtModal) {

            return (
                <div>
                    {this.renderExternalCourts()}
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.updateCourts() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'SALVAR'} />
                        <DefaultButton onClick={() => { this.setState({ courtModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    handleMatchHourChange(value, key) {

        let tournament = this.state.tournament;
        tournament[key] = value;

        this.setState({ tournament });
    }

    async saveMatchHour() {

        if (this.state.tournament.match_start && this.state.tournament.match_end) {

            try {

                await this.setState({ loadingModal: true });

                let start = typeof this.state.tournament.match_start === 'string' ? this.state.tournament.match_start : moment(this.state.tournament.match_start).format('HH:mm');
                let end = typeof this.state.tournament.match_end === 'string' ? this.state.tournament.match_end : moment(this.state.tournament.match_end).format('HH:mm');

                await Firestore.update({ match_start: start, match_end: end }, 'tournament', this.state.tournament.id);

                await this.getTournament();
                await this.setState({ loadingModal: false, matchHourModal: false });

                toast.success('Horários alterados com sucesso!');
                toast.warn('Reagende as Partidas para a mudança surtir efeito nas chaves');

            } catch (error) {

                toast.error('Houve um problema ao alterar os horários');
                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn('Preencha todos os campos');
        }
    }

    matchHourModal() {

        if (this.state.matchHourModal) {

            return (
                <div>
                    <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                        <KeyboardTimePicker
                            style={{ width: '100%', paddingRight: 5 }}
                            invalidDateMessage={false}
                            format={'HH:mm'}
                            autoOk={true}
                            label="Horário de Início das Partidas"
                            cancelLabel={'Cancelar'}
                            okLabel={'Confirmar'}
                            onChange={(v) => { this.handleMatchHourChange(v, 'match_start') }}
                            value={moment(this.state.tournament.match_start, 'HH:mm')}
                        />
                    </MuiPickersUtilsProvider>
                    <FormLabel style={{ paddingBottom: 15, paddingTop: 5, fontSize: 13 }} component="legend">Exemplo: Diáriamente, durante o torneio, a primeira partida pode começar 08:00 da manhã.</FormLabel>

                    <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                        <KeyboardTimePicker
                            style={{ width: '100%', paddingRight: 5 }}
                            invalidDateMessage={false}
                            format={'HH:mm'}
                            autoOk={true}
                            label="Horário Final das Partidas"
                            cancelLabel={'Cancelar'}
                            okLabel={'Confirmar'}
                            onChange={(v) => { this.handleMatchHourChange(v, 'match_end') }}
                            value={moment(this.state.tournament.match_end, 'HH:mm')}
                        />
                    </MuiPickersUtilsProvider>
                    <FormLabel style={{ paddingBottom: 15, paddingTop: 5, fontSize: 13 }} component="legend">Exemplo: Diáriamente, durante o torneio, a última partida pode começar no máximo 21:00 da noite.</FormLabel>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.saveMatchHour() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'SALVAR'} />
                        <DefaultButton onClick={() => { this.setState({ matchHourModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    async rescheduleTournament() {

        if (this.state.rescheduleFrom && this.state.rescheduleTo) {

            try {

                await this.setState({ loadingModal: true });

                let from = moment(this.state.rescheduleFrom).unix();
                let to = moment(this.state.rescheduleTo).unix();

                await Functions.request('PUT', `tournament/reescheduleTournament/${this.state.tournament.id}/${from}/${to}`, {});

                await this.getTournament();
                await this.setState({ loadingModal: false, rescheduleFrom: null, rescheduleTo: null, rescheduleModal: false });

                await this.changeView(this.state.tab, true);

                toast.success('Partidas reagendadas com sucesso');

                if (this.state.tournament.visible_brackets) await this.sendHourChangeNotifications();

            } catch (error) {

                toast.error('Houve um problema ao reagendar as partidas');
                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn('Preencha todos os campos');
        }
    }

    rescheduleModal() {

        if (this.state.rescheduleModal) {

            return (
                <div>
                    <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                        <KeyboardDateTimePicker
                            style={{ width: '100%', marginBottom: 15, paddingRight: 5 }}
                            invalidDateMessage={false}
                            format={'dd/MM/yyyy HH:mm'}
                            autoOk={true}
                            minDate={moment(this.state.tournament.start.toDate()) || moment().subtract(1, 'weeks')}
                            label="A partir de"
                            cancelLabel={'Cancelar'}
                            okLabel={'Confirmar'}
                            onChange={(v) => { this.setState({ rescheduleFrom: v }) }}
                            value={this.state.rescheduleFrom}
                        />
                    </MuiPickersUtilsProvider>

                    <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                        <KeyboardDateTimePicker
                            style={{ width: '100%', marginBottom: 15, paddingRight: 5 }}
                            invalidDateMessage={false}
                            format={'dd/MM/yyyy HH:mm'}
                            autoOk={true}
                            minDate={new Date()}
                            label="Para"
                            cancelLabel={'Cancelar'}
                            okLabel={'Confirmar'}
                            onChange={(v) => { this.setState({ rescheduleTo: v }) }}
                            value={this.state.rescheduleTo}
                        />
                    </MuiPickersUtilsProvider>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.rescheduleTournament() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                        <DefaultButton onClick={() => { this.setState({ rescheduleModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    applySettings = async () => {
        try {
            if (!this.state.name) {
                toast.info('Preencha o nome');
                return;
            }

            if (this.state.hasAbsoluteLimit && (!this.state.absoluteLimit || this.state.absoluteLimit < 1)) {
                toast.info('Preencha o limite de inscrições (deve ser maior ou igual a 1) ou desmarque a opção de aplicar limite');
                return;
            }

            if (moment(this.state.ticketStart).isSameOrAfter(moment(this.state.ticketLimit)) || moment(this.state.ticketStart).isSameOrAfter(moment(this.state.start))) {
                toast.info('Data de inicio das inscrições não pode ser depois da data de finalização das inscrições, ou da data de inicio');
                return;
            }

            if (moment(this.state.ticketLimit).isSameOrAfter(moment(this.state.start))) {
                toast.info('Data de finalização das inscrições não pode ser depois da data de inicio.');
                return;
            }

            if (moment(this.state.start).isSameOrAfter(moment(this.state.end))) {
                toast.info('Data de inicio não pode ser depois da data de finalização.');
                return;
            }

            this.setState({ loadingModal: true });

            const oldStart = this.state.tournament.start.toDate ? this.state.tournament.start.toDate() : this.state.tournament.start;

            await Firestore.update({
                has_absolute_limit: this.state.hasAbsoluteLimit,
                absolute_limit: this.state.absoluteLimit,
                internal_enroll_only: this.state.internalEnrollOnly,
                ticket_start: this.state.ticketStart,
                ticket_limit: this.state.ticketLimit,
                start: this.state.start,
                end: this.state.end,
                award: this.state.award,
                name: this.state.name
            }, 'tournament', this.state.tournament.id);

            if (moment(this.state.start).diff(moment(oldStart), 'minute') != 0) {
                await this.setState({ rescheduleFrom: moment(oldStart).startOf('day'), rescheduleTo: moment(this.state.start) });
                toast.info('O torneio será reagendado, aguarde...');
                await this.rescheduleTournament();
            }

            toast.success('Editado com sucesso.');
        } catch (e) {
            console.log(e);
            toast.error('Não foi possível aplicar as mudanças, recarregue a página e tente novamente.');
        }

        this.setState({ settingsModal: false, loadingModal: false });
        return;
    }

    handleDateChange(field, value) {
        try {
            let state = this.state;

            if (field) {
                state[field] = value;
                this.setState(state);
            }
        } catch (error) {
            console.log(error);
        }
    }

    settingsModal() {
        if (this.state.settingsModal) {
            return (
                <div>
                    <DefaultInput defaultValue={this.state.name} onBlur={(v) => { this.setState({ name: v }) }} label={'Nome'} />

                    <IosSwitch fullWidth label={'Definir limite absoluto de inscritos'} value={'has_absolute_limit'} checked={this.state.hasAbsoluteLimit} onChange={async (v) => {
                        await this.setState({ hasAbsoluteLimit: v });
                    }} />
                    {this.state.hasAbsoluteLimit ? this.renderAbsoluteLimitInput() : null}
                    <FormLabel style={{ paddingBottom: 15, paddingTop: 0, fontSize: 13 }} component="legend">Não afeta inscrições já realizadas, apenas limita para novas inscrições à partir de agora.</FormLabel>

                    <IosSwitch fullWidth label={'Torneio Interno (Apenas alunos vinculados podem inscrever-se)'} value={'internal_enroll_only'} checked={this.state.internalEnrollOnly} onChange={(v) => { this.setState({ internalEnrollOnly: v }) }} />
                    <FormLabel style={{ paddingBottom: 15, paddingTop: 0, fontSize: 13 }} component="legend">Não afeta inscrições já realizadas, apenas trata inscrições à partir de agora.</FormLabel>

                    <FormLabel style={{ paddingBottom: 18, paddingTop: 18, fontSize: 13 }} component="legend">Premiação</FormLabel>
                    <TextareaAutosize defaultValue={this.state.award} style={{ width: '100%', borderRadius: 5, borderColor: 'lightgrey', padding: 15, fontSize: '1rem' }} rowsMax={8} rowsMin={8} onBlur={(v) => { this.setState({ award: v.target.value }) }} placeholder="Escreva a premiação do torneio..." />

                    {!this.state.tournament.sorted_players ? <div>
                        <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                            <KeyboardDateTimePicker
                                style={{ width: '100%', marginBottom: 15, paddingRight: 5 }}
                                invalidDateMessage={false}
                                format={'dd/MM/yyyy HH:mm'}
                                autoOk={true}
                                maxDate={moment(this.state.start).subtract(3, 'day')}
                                label="Data de Início das Inscrições"
                                cancelLabel={'Cancelar'}
                                okLabel={'Confirmar'}
                                onChange={(v) => { this.handleDateChange('ticketStart', v) }}
                                value={this.state.ticketStart}
                            />
                        </MuiPickersUtilsProvider>

                        <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                            <KeyboardDateTimePicker
                                style={{ width: '100%', marginBottom: 15, paddingRight: 5 }}
                                invalidDateMessage={false}
                                format={'dd/MM/yyyy HH:mm'}
                                autoOk={true}
                                minDate={this.state.ticketStart}
                                maxDate={this.state.start ? moment(this.state.start).subtract(2, 'day') : moment().add(99, 'year')}
                                label="Data de Finalização das Inscrições"
                                cancelLabel={'Cancelar'}
                                okLabel={'Confirmar'}
                                onChange={(v) => { this.handleDateChange('ticketLimit', v) }}
                                value={this.state.ticketLimit}
                            />
                        </MuiPickersUtilsProvider>

                        <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                            <KeyboardDateTimePicker
                                style={{ width: '100%', marginBottom: 15, paddingRight: 5 }}
                                invalidDateMessage={false}
                                format={'dd/MM/yyyy HH:mm'}
                                autoOk={true}
                                minDate={moment(this.state.ticketLimit).add(2, 'day') || new Date()}
                                label="Data de Início"
                                cancelLabel={'Cancelar'}
                                okLabel={'Confirmar'}
                                onChange={(v) => { this.handleDateChange('start', v) }}
                                value={this.state.start}
                            />
                        </MuiPickersUtilsProvider>
                        <FormLabel style={{ paddingBottom: 15, paddingTop: 0, fontSize: 13, color: Colors.error }} component="legend">Cuidado ao alterar a data de ínicio, as partidas não jogadas serão reagendadas para à partir do novo início.</FormLabel>

                        <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                            <KeyboardDateTimePicker
                                style={{ width: '100%', marginBottom: 15, paddingRight: 5 }}
                                invalidDateMessage={false}
                                format={'dd/MM/yyyy HH:mm'}
                                autoOk={true}
                                minDate={this.state.start}
                                maxDate={moment(this.state.start).add(2, 'week')}
                                label="Data de Finalização"
                                cancelLabel={'Cancelar'}
                                okLabel={'Confirmar'}
                                onChange={(v) => { this.handleDateChange('end', v) }}
                                value={this.state.end}
                            />
                        </MuiPickersUtilsProvider>

                    </div> : null}

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.applySettings() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'SALVAR'} />
                        <DefaultButton onClick={() => { this.setState({ settingsModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    renderAbsoluteLimitInput() {
        return (
            <InputMask onBlur={async (v) => {
                await this.setState({ absoluteLimit: v });
            }} mask="9999" maskPlaceholder={''} alwaysShowMask={false}>
                <DefaultInput defaultValue={this.state.absoluteLimit} required number label={'Número máximo (total) de inscrições'} />
            </InputMask>
        );
    }

    resultModal() {

        if (this.state.resultModal) {

            let p1 = this.state.editMatchData.p1.map((player, key) => {

                let user = this.state.userDocs.find(item => item.id === player);

                return user ? user.name : '';
            });

            let p2 = this.state.editMatchData.p2.map((player, key) => {

                let user = this.state.userDocs.find(item => item.id === player);

                return user ? user.name : '';
            });

            p1 = p1.join(', ');
            p2 = p2.join(', ');

            return (
                <div>
                    <div style={{ width: '100%', marginTop: 15, marginBottom: 15 }}>
                        <InputLabel>Selecione o vencedor</InputLabel>
                        <Select style={{ width: '100%' }} onChange={(v) => { this.setState({ editMatchData: { ...this.state.editMatchData, winner: v.target.value } }) }}>
                            <MenuItem value={undefined}>{'Selecione um jogador'}</MenuItem>
                            <MenuItem value={'p1'}>{p1}</MenuItem>
                            <MenuItem value={'p2'}>{p2}</MenuItem>
                        </Select>
                    </div>

                    <InputLabel style={{ marginTop: 50 }}>Sets</InputLabel>
                    <Table style={{ marginTop: 20, marginBottom: 20, minWidth: 300 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold', width: 50 }} align="left">{'Set'}</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }} align="center">{this.state.gaveUp && this.state.editMatchData && this.state.editMatchData.winner === 'p2' ? <LocalHospitalIcon style={{ color: Colors.error, marginRight: 6, fontSize: 18 }} /> : null}{p1}</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }} align="right">{''}</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }} align="right">{this.state.gaveUp && this.state.editMatchData && this.state.editMatchData.winner === 'p1' ? <LocalHospitalIcon style={{ color: Colors.error, marginRight: 6, fontSize: 18 }} /> : null}{p2}</TableCell>
                                <TableCell style={{ width: 50 }} align="right">{''}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                this.state.resultSets.map((set, key) => {

                                    return (
                                        <TableRow>
                                            <TableCell align="left">{key + 1}</TableCell>
                                            <TableCell style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} align="left">
                                                {this.state.resultSets[key]['p1']}
                                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8, marginRight: 8 }}>
                                                    <IconButton style={{ width: 30, height: 30 }}>
                                                        <ArrowUpwardIcon onClick={() => { this.addValueToSet(key, 'p1', 1) }} style={{ fontSize: 15 }} />
                                                    </IconButton>
                                                    <IconButton style={{ width: 30, height: 30 }}>
                                                        <ArrowDownwardIcon onClick={() => { this.addValueToSet(key, 'p1', -1) }} style={{ fontSize: 15 }} />
                                                    </IconButton>
                                                </div>
                                                {(this.state.resultSets[key]['p1'] == 7 || this.state.resultSets[key]['p2'] == 7) &&
                                                    this.state.resultSets[key]['p1'] < 8 && this.state.resultSets[key]['p2'] < 8 &&
                                                    this.state.resultSets[key]['p1'] > 5 && this.state.resultSets[key]['p2'] > 5 ?
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                        ({this.state.resultSetsTiebreak[key]['p1']})
                                                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>
                                                            <IconButton style={{ width: 30, height: 30 }}>
                                                                <ArrowUpwardIcon onClick={() => { this.addValueToSetTiebreak(key, 'p1', 1) }} style={{ fontSize: 15 }} />
                                                            </IconButton>
                                                            <IconButton style={{ width: 30, height: 30 }}>
                                                                <ArrowDownwardIcon onClick={() => { this.addValueToSetTiebreak(key, 'p1', -1) }} style={{ fontSize: 15 }} />
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                    : null}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }} align="right">{'X'}</TableCell>
                                            <TableCell style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }} align="left">
                                                {this.state.resultSets[key]['p2']}
                                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>
                                                    <IconButton onClick={() => { this.addValueToSet(key, 'p2', 1) }} style={{ width: 30, height: 30 }}>
                                                        <ArrowUpwardIcon style={{ fontSize: 15 }} />
                                                    </IconButton>
                                                    <IconButton onClick={() => { this.addValueToSet(key, 'p2', -1) }} style={{ width: 30, height: 30 }}>
                                                        <ArrowDownwardIcon style={{ fontSize: 15 }} />
                                                    </IconButton>
                                                </div>
                                                {(this.state.resultSets[key]['p1'] == 7 || this.state.resultSets[key]['p2'] == 7) &&
                                                    this.state.resultSets[key]['p1'] < 8 && this.state.resultSets[key]['p2'] < 8 &&
                                                    this.state.resultSets[key]['p1'] > 5 && this.state.resultSets[key]['p2'] > 5 ?

                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                        ({this.state.resultSetsTiebreak[key]['p2']})
                                                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>
                                                            <IconButton style={{ width: 30, height: 30 }}>
                                                                <ArrowUpwardIcon onClick={() => { this.addValueToSetTiebreak(key, 'p2', 1) }} style={{ fontSize: 15 }} />
                                                            </IconButton>
                                                            <IconButton style={{ width: 30, height: 30 }}>
                                                                <ArrowDownwardIcon onClick={() => { this.addValueToSetTiebreak(key, 'p2', -1) }} style={{ fontSize: 15 }} />
                                                            </IconButton>
                                                        </div>
                                                    </div>

                                                    : null}
                                            </TableCell>
                                            <TableCell align="left">{''}
                                                <Tooltip title={'Remover Set'}>
                                                    <IconButton onClick={() => { this.removeSet(key) }}>
                                                        <DeleteIcon style={{ color: Colors.error }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }

                            {

                                Object.keys(this.state.resultTiebreak).length ?

                                    <TableRow>
                                        <TableCell align="left">{'Tiebreak'}</TableCell>
                                        <TableCell style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} align="left">
                                            {this.state.resultTiebreak['p1']}
                                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>
                                                <IconButton style={{ width: 30, height: 30 }}>
                                                    <ArrowUpwardIcon onClick={() => { this.addValueToTiebreak('p1', 1) }} style={{ fontSize: 15 }} />
                                                </IconButton>
                                                <IconButton style={{ width: 30, height: 30 }}>
                                                    <ArrowDownwardIcon onClick={() => { this.addValueToTiebreak('p1', -1) }} style={{ fontSize: 15 }} />
                                                </IconButton>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="right">{'X'}</TableCell>
                                        <TableCell style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }} align="left">
                                            {this.state.resultTiebreak['p2']}
                                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>
                                                <IconButton onClick={() => { this.addValueToTiebreak('p2', 1) }} style={{ width: 30, height: 30 }}>
                                                    <ArrowUpwardIcon style={{ fontSize: 15 }} />
                                                </IconButton>
                                                <IconButton onClick={() => { this.addValueToTiebreak('p2', -1) }} style={{ width: 30, height: 30 }}>
                                                    <ArrowDownwardIcon style={{ fontSize: 15 }} />
                                                </IconButton>
                                            </div>
                                        </TableCell>
                                        <TableCell align="left">{''}
                                            <Tooltip title={'Remover Tiebreak'}>
                                                <IconButton onClick={() => { this.setState({ resultTiebreak: {} }) }}>
                                                    <DeleteIcon style={{ color: Colors.error }} />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>

                                    : null
                            }

                            {this.state.isWo ?
                                <TableRow>
                                    <TableCell align="left">{'Desclassificação'}</TableCell>
                                    <TableCell style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} align="left">
                                        {this.state.editMatchData && this.state.editMatchData.winner && this.state.editMatchData.winner === 'p1' ? `W.O.` : `--`}
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} align="right">{'X'}</TableCell>
                                    <TableCell style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }} align="left">
                                        {this.state.editMatchData && this.state.editMatchData.winner && this.state.editMatchData.winner === 'p2' ? `W.O.` : `--`}
                                    </TableCell>
                                </TableRow>
                                : null}

                        </TableBody>
                    </Table>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 20 }}>
                        <DefaultButton loading={this.state.loadingModal} onClick={() => { this.addEmptySet() }} width={'100%'} leftIcon={<AddIcon style={{ marginRight: 5 }} />} title={'Adicionar Set'} />
                        <div style={{ marginLeft: 3, marginRight: 3 }} />
                        <DefaultButton disabled={Object.keys(this.state.resultTiebreak).length > 0} onClick={() => { this.addEmptyTiebreak() }} width={'100%'} leftIcon={<PlusOneIcon style={{ marginRight: 5 }} />} title={'Tiebreak'} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Tooltip title={`Atribui o status de W.O. ao vencedor (Selecione o vencedor primeiro)`}>
                            <div style={{ width: '50%' }}>
                                <DefaultButton color={Colors.danger} loading={this.state.loadingModal} onClick={() => { this.addWo() }} width={'100%'} leftIcon={<HourglassEmptyIcon style={{ marginRight: 5 }} />} title={`${this.state.isWo ? `Remover ` : ``}W.O.`} />
                            </div>
                        </Tooltip>
                        <div style={{ marginLeft: 3, marginRight: 3 }} />
                        <Tooltip title={`Atribui o status de desistência ao perdedor (Selecione o vencedor primeiro)`}>
                            <div style={{ width: '50%' }}>
                                <DefaultButton color={Colors.error} disabled={this.state.isWo} onClick={() => { this.addGaveUp() }} width={'100%'} leftIcon={<LocalHospitalIcon style={{ marginRight: 5 }} />} title={`${this.state.gaveUp ? `Remover ` : ``}Desistência`} />
                            </div>
                        </Tooltip>
                    </div>

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton loading={this.state.loadingModal} onClick={() => { this.saveMatchResult() }} color={Colors.primary} width={'48%'} title={'SALVAR'} />
                        <DefaultButton onClick={() => { this.setState({ resultModal: false, isWo: false, gaveUp: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    removeSet(key) {
        let sets = this.state.resultSets;
        let setsTiebreak = this.state.resultSetsTiebreak;
        let saveSets = this.state.saveResultSets;

        if (sets[key]) sets.splice(key, 1);
        if (setsTiebreak[key]) setsTiebreak.splice(key, 1);
        if (saveSets[key]) saveSets.splice(key, 1);

        this.setState({ resultSets: sets, resultSetsTiebreak: setsTiebreak, saveResultSets: saveSets });
    }

    addValueToSet(key, player, value) {
        let saveSets = this.state.saveResultSets;
        let setsTiebreak = this.state.resultSetsTiebreak;
        let sets = this.state.resultSets;

        if ((sets[key][player] + value) >= 0) {
            sets[key][player] += value;

            if (!setsTiebreak[key]) setsTiebreak[key] = { p1: 0, p2: 0 };
            if (!saveSets[key]) saveSets[key] = { ...sets[key] };
            saveSets[key] = { ...sets[key] };

            this.setState({ resultSets: sets, saveResultSets: saveSets, resultSetsTiebreak: setsTiebreak })
        }
    }

    addValueToSetTiebreak(key, player, value) {
        let saveSets = this.state.saveResultSets;
        let setsTiebreak = this.state.resultSetsTiebreak;
        let sets = this.state.resultSets;

        if ((setsTiebreak[key][player] + value) >= 0) {
            setsTiebreak[key][player] += value;
            saveSets[key][player] = `${sets[key][player]} (${setsTiebreak[key][player]})`;
            this.setState({ resultSetsTiebreak: setsTiebreak, saveResultSets: saveSets, resultSets: sets });
        }
    }

    addValueToTiebreak(player, value) {
        let tiebreak = this.state.resultTiebreak;

        if ((tiebreak[player] + value) >= 0) {
            tiebreak[player] += value;
            this.setState({ resultTiebreak: tiebreak })
        }
    }

    addEmptySet() {
        let sets = this.state.resultSets;
        sets.push({ p1: 0, p2: 0 });

        this.setState({ resultSets: sets, isWo: false })
    }

    addWo() {
        if (!this.state.editMatchData.winner) return toast.info('Informe um vencedor informar W.O.');

        this.setState({ resultSets: [], resultTiebreak: {}, isWo: !this.state.isWo, gaveUp: false });
    }

    addGaveUp() {
        if (!this.state.editMatchData.winner) return toast.info('Informe um vencedor para informar desistencia');

        this.setState({ gaveUp: !this.state.gaveUp, isWo: false });
    }

    addEmptyTiebreak() {

        let tiebreak = this.state.resultTiebreak;
        tiebreak = { p1: 0, p2: 0 };

        this.setState({ resultTiebreak: tiebreak, isWo: false })
    }

    async updateCourts() {

        try {

            this.setState({ loadingModal: true });

            await Firestore.update({ courts: this.state.courts }, 'tournament', this.state.tournament.id);

            await this.getTournament();
            await this.getTournamentCourts();
            await this.changeBracketsCourts();
            if (this.state.tournament.visible_brackets) await this.sendHourChangeNotifications();

            toast.success('Quadras alteradas com sucesso');
            this.setState({ loadingModal: false, courtModal: false });

        } catch (error) {

            toast.warn('Houve um problema ao alterar as quadras');
            this.setState({ loadingModal: false });
        }
    }

    async changeBracketsCourts() {
        try {
            const start = this.state.tournament.start.toDate();
            const end = this.state.tournament.end.toDate();

            if (moment().isBefore(end)) {
                let from = moment().unix();
                let to;

                if (moment().isAfter(start)) {
                    to = moment().add(15, 'minutes').unix();
                } else {
                    to = moment(start).unix();
                }

                if (moment(from).isValid() && moment(to).isValid()) {
                    await Functions.request('PUT', `tournament/reescheduleTournament/${this.state.tournament.id}/${from}/${to}`, {});

                    await this.getTournament();
                    await this.changeView(this.state.tab, true);

                    toast.success('Quadras alteradas nas chaves com sucesso!');
                }
            }
        } catch (error) {
            toast.error('Houve um problema ao alterar as quadras nas chaves.');
        }
    }

    sendHourChangeNotifications = async () => {
        let participants = this.state.userDocs;

        if (participants && participants.length > 0) {
            toast.warn(`Notificando jogadores deste torneio da mudança, espere pelo menos até ${moment().add(5, 'minutes').format('HH:mm')} (5 min) para fechar esta página.`, { autoClose: false });

            participants.forEach(participant => {
                if (participant && participant.notification_token && participant.notification_token.data) {
                    let token = participant.notification_token.data;
                    let notification = {
                        icon: 'clock',
                        color: 'orange',
                        navigate: 'Tournament',
                        id: this.state.tournament.id
                    };

                    NotificationHelper.send(
                        `Seus horários de ${this.state.tournament.name} podem ter mudado!`,
                        `Foram feitas alterações pela acadêmia no torneio. Se você tinha partidas futuras, seus horários podem ter mudado, cheque no app para não perder suas partidas.`,
                        notification, token, participant.id
                    );
                }
            })
        }
    }

    sendVisibleBracketsNotifications = async () => {
        let participants = this.state.userDocs;

        if (participants && participants.length > 0) {
            toast.warn(`Notificando jogadores da postagem das chaves, espere pelo menos até ${moment().add(5, 'minutes').format('HH:mm')} (5 min) para fechar esta página.`, { autoClose: false });

            participants.forEach(participant => {
                if (participant && participant.notification_token && participant.notification_token.data) {
                    let token = participant.notification_token.data;
                    let notification = {
                        icon: 'clock',
                        color: 'green',
                        navigate: 'Tournament',
                        id: this.state.tournament.id
                    };

                    NotificationHelper.send(
                        `Chaves de ${this.state.tournament.name} disponíveis!`,
                        `Os horários de suas partidas do torneio ${this.state.tournament.name} já estão disponíveis, abra o app para visualizar.`,
                        notification, token, participant.id
                    );
                }
            })
        }
    }

    addExternalCourt() {

        if (this.state.addCourtText) {

            let courts = this.state.courts;
            courts.push(this.state.addCourtText);

            this.setState({ courts: courts, addCourt: false, addCourtText: '' });

        } else {

            toast.warn('Preencha o nome da quadra');
        }
    }

    removeExternalCourt(key) {

        let courts = this.state.courts;
        courts.splice(key, 1);

        this.setState({ courts: courts });
    }

    renderExternalCourts() {

        return (

            <div>
                {
                    this.state.courts.map((court, key) => {

                        return (
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid lightgrey', paddingTop: 8, paddingBottom: 8 }}>
                                <div style={{ fontWeight: 'bold', display: 'flex', flexDirection: 'row' }}>
                                    <SportsTennisIcon style={{ marginRight: 8 }} />
                                    {court}
                                </div>
                                <Tooltip title={'Remover Quadra'}>
                                    <IconButton onClick={() => { this.removeExternalCourt(key) }} style={{ boxShadow: Colors.boxShadow }}>
                                        <DeleteIcon style={{ color: Colors.error }} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )
                    })
                }

                {!this.state.courts.length ? <p style={{ color: 'grey' }}>{'Nenhuma quadra cadastrada.'}</p> : null}

                <div style={{ marginTop: 15 }}>
                    {!this.state.addCourt ?

                        <Tooltip title={'Adicionar Quadra'}>
                            <IconButton onClick={() => { this.setState({ addCourt: true }) }} style={{ boxShadow: Colors.boxShadow }}>
                                <AddIcon style={{ color: Colors.primary }} />
                            </IconButton>
                        </Tooltip>

                        :

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <DefaultInput onBlur={(v) => { this.setState({ addCourtText: v }) }} label={'Nome'} />
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 8 }}>
                                <Button onClick={() => { this.addExternalCourt() }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'CONFIRMAR'}</Button>
                                <Button onClick={() => { this.setState({ addCourt: false, addCourtText: '' }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                            </div>
                        </div>

                    }
                </div>
            </div>
        )
    }

    async exportObservation(observations) {
        if (Object.keys(observations) < 1) return toast.info(`Nada para imprimir`);

        this.setState({ loadingModal: true });

        let doc = new jsPDF();
        let rectYOffset = 5;
        let img = await ReportHelper.imageToBase64(process.env.PUBLIC_URL + '/logo.png');

        doc = ReportHelper.header(doc, `OBSERVAÇÕES DE INSCRITOS`, this.state.tournament.name.toUpperCase(), img);
        doc = ReportHelper.footer(doc);
        doc.text(doc.internal.pageSize.getWidth() - 20, 290, 'página ' + doc.internal.getNumberOfPages());

        Object.keys(observations).forEach((observationsClassKey) => {
            let observationsClass = observations[observationsClassKey];

            if (observationsClass.observations.length > 0) {
                observationsClass.observations.forEach((event, key) => {
                    doc.rect(15, 28 + rectYOffset, doc.internal.pageSize.getWidth() - 27, 26);

                    doc.setFontType('bold');
                    doc.text(`${event.names}`, 16, 32 + rectYOffset);
                    doc.setFontType('normal');

                    doc.text(`${TermHelper.getestablishmentTypeLabel(SessionHelper.getData().company.establishment_type).singular}: ${event.status ? `Sim` : `Não`}`, 16, 32 + rectYOffset + 4);
                    doc.text(`Classe: ${observationsClass.title}`, 16, 32 + rectYOffset + 8);
                    doc.text(16, 32 + rectYOffset + 13, `Observação: ${event.observation}`, { maxWidth: 180 });


                    rectYOffset += 28;

                    if (rectYOffset > 208 && observationsClass.observations[key + 1]) {

                        rectYOffset = 5;

                        doc.addPage();
                        doc.text(doc.internal.pageSize.getWidth() - 20, 290, 'página ' + doc.internal.getNumberOfPages());

                        doc = ReportHelper.header(doc, `OBSERVAÇÕES DE INSCRITOS`, this.state.tournament.name.toUpperCase(), img);
                        doc = ReportHelper.footer(doc);
                    }
                });

            }

        });

        doc.save(`Observações de ${this.state.tournament.name}.pdf`);

        this.setState({ loadingModal: false });

    }

    async checkEmailExists(email) {
        const query = await Firestore.customQuery('user').where('email', '==', email).where('type', '==', 'student').get();

        if (query.docs.length > 0) {
            return query.docs[0].id;
        } else {
            return false;
        }
    }

    async createParticipantPaymentAndOrder(id_user, id_order) {
        let orderTitle = `Pedido #${moment().format('HHmmss')}`;
        let price = this.state.addParticipantModalData.studentPrice === 'student' ? this.state.tournament.student_price : this.state.tournament.price;
        let ticketLimit = moment(this.state.tournament.ticket_limit.toDate ? this.state.tournament.ticket_limit.toDate() : this.state.tournament.ticket_limit);
        let expirationDate = moment().isAfter(ticketLimit) ? moment().add(1, 'day') : moment(ticketLimit);
        let paymentMethod = this.state.addParticipantModalData.paymentMethod;

        let body = {
            amount: price,
            payment_method: paymentMethod,
            async: false,
            soft_descriptor: 'Ace',
            postback_url: PagarmeConstants.getKeys().tournament_enroll_postback,
            customer: {
                external_id: SessionHelper.getData().id_company,
                name: SessionHelper.getData().company.name,
                email: SessionHelper.getData().email,
                country: 'br',
                type: SessionHelper.getData().company.type === 'pj' ? 'corporation' : 'individual',
                documents: [
                    {
                        type: SessionHelper.getData().company.type === 'pj' ? 'cnpj' : 'cpf',
                        number: SessionHelper.getData().company.type === 'pj' ? SessionHelper.getData().company.cnpj : SessionHelper.getData().company.cpf,
                    }
                ],
                phone_numbers: [
                    `+55${DataHelper.treatPhone(`${SessionHelper.getData().company.ddd}${SessionHelper.getData().company.phone}`)}`,
                ]
            },
            items: [
                {
                    id: this.state.tournament.id,
                    title: `Inscrição de Torneio - ${this.state.tournament.name} (${this.state.tournament.category[this.state.addParticipantModalData.categoryIndex].title} - ${this.state.tournament.category[this.state.addParticipantModalData.categoryIndex].classes[this.state.addParticipantModalData.classIndex].title})`,
                    unit_price: price,
                    quantity: 1,
                    tangible: false,
                }
            ],
            split_rules: [
                {
                    recipient_id: PagarmeConstants.getKeys().easydata_recipient_id,
                    liable: false,
                    charge_processing_fee: false,
                    amount: paymentMethod === 'boleto' ? PagarmeConstants.getKeys().easydata_transaction_amount_boleto : PagarmeConstants.getKeys().easydata_transaction_amount_pix,
                    charge_remainder: true,
                },
                {
                    recipient_id: SessionHelper.getData().company.id_recipient_pagarme,
                    liable: true,
                    charge_processing_fee: true,
                    amount: price - (paymentMethod === 'boleto' ? PagarmeConstants.getKeys().easydata_transaction_amount_boleto : PagarmeConstants.getKeys().easydata_transaction_amount_pix),
                    charge_remainder: false,
                }
            ],
            metadata: {
                id_user: id_user,
                id_company: SessionHelper.getData().id_company,
                id_tournament: this.state.tournament.id,
                id_order: id_order,
                type: 'tournament_ticket',
            },
        }

        if (paymentMethod === 'boleto') {

            body.boleto_expiration_date = expirationDate.format('yyyy-MM-dd');

        } else {

            body.pix_expiration_date = expirationDate.format('YYYY-MM-DD');
        }

        let response = await Pagarme.post('/transactions', body);

        if (response.errors && response.errors.length) {
            throw new Error('Payment Error');
        }

        let order = {
            title: orderTitle,
            amount: price,
            tax: PagarmeConstants.getKeys().easydata_transaction_amount_boleto,
            payment_method: paymentMethod,
            id_user: id_user,
            type: 'tournament_ticket',
            finished: false,
            refunded: false,
            id_company: SessionHelper.getData().id_company,
            date: new Date(),
            id_transaction_pagarme: response.id,
            id_tournament: this.state.tournament.id,
            tournament_category: this.state.addParticipantModalData.categoryIndex,
            tournament_class: this.state.addParticipantModalData.classIndex,
            boleto_expiration_date: expirationDate.toDate(),
            status: response.status
        }

        if (paymentMethod === 'pix') {

            order.pix_qr_code = response.pix_qr_code;
        }

        await Firestore.insert(order, 'order', id_order);

        this.setState({ addParticipantModalData: { ...this.state.addParticipantModalData, boletoCode: response.boleto_barcode || '', boletoLink: response.boleto_url || '', pixQrCode: response.pix_qr_code || '' } });

        if (paymentMethod === 'pix') {

            Functions.request('POST', `tournament/sendPixEmail`, { to: this.state.addParticipantModalData.email, tournamentName: this.state.tournament.name, expirationDate: moment(response.boleto_expiration_date).format('DD/MM/YYYY'), pixQrCode: response.pix_qr_code });

        } else {

            Functions.request('POST', `tournament/sendBillEmail`, { to: this.state.addParticipantModalData.email, tournamentName: this.state.tournament.name, boletoUrl: this.state.addParticipantModalData.boletoLink, boletoExpirationDate: moment(response.boleto_expiration_date).format('DD/MM/YYYY') });
        }
    }

    addParticipantToTournament = async () => {
        let success = true;

        this.setState({ loadingModal: true });

        try {
            let id_user = (await this.checkEmailExists(this.state.addParticipantModalData.email));
            let id_friend = null;

            if (id_user === false) {
                id_user = await Firestore.getId('user');

                await Firestore.insert({
                    name: this.state.addParticipantModalData.name,
                    type: "tournament_user",
                    created_at: new Date(),
                    sex: this.state.tournament.category[this.state.addParticipantModalData.categoryIndex].name,
                    bill_sending_email: this.state.addParticipantModalData.email
                }, 'user', id_user);
            }

            let players = [id_user];

            if (this.state.tournament.type === 'pair') {
                id_friend = await Firestore.getId('user');

                await Firestore.insert({
                    name: this.state.addParticipantModalData.secondName,
                    type: "tournament_user",
                    sex: this.state.tournament.category[this.state.addParticipantModalData.categoryIndex].name,
                    created_at: new Date()
                }, 'user', id_friend);
            }

            if (this.state.tournament.type === 'pair') players.push(id_friend);

            let id_order = await Firestore.getId('order');
            await this.createParticipantPaymentAndOrder(id_user, id_order);

            let tournament = this.state.tournament;

            if (tournament.category[this.state.addParticipantModalData.categoryIndex].classes[this.state.addParticipantModalData.classIndex]) {
                if (!tournament.participants.includes(id_user)) tournament.participants.push(id_user);
                if (tournament.type === 'pair') tournament.participants.push(id_friend);

                tournament.category[this.state.addParticipantModalData.categoryIndex].classes[this.state.addParticipantModalData.classIndex].participants.push({
                    date: new Date(),
                    payemnt_type: 'boleto',
                    observation: this.state.addParticipantModalData.observation,
                    id_order: id_order,
                    id_user,
                    players
                });
            }

            await Firestore.update({ participants: tournament.participants, category: tournament.category }, 'tournament', this.state.tournament.id);

            toast.success('Inscrição realizada com sucesso!');
        } catch (e) {
            console.log(e);
            success = false;
        }

        await this.getTournament();

        this.setState({ loadingModal: false });

        return success;
    }

    renderAddParticipantCategories() {
        if (this.state.addParticipantModal) {
            let categories = [];

            this.state.tournament.category.map((category, key) => {
                if (category.checked === true) categories.push({ title: category.title, name: category.name, index: key })
            });

            return (
                <DefaultSelect search={true} multiple={false} searchField={'title'} id={'category_selection_add'} valueField={'index'} displayField={'title'} value={this.state.addParticipantModalData.categoryIndex} onChange={(v) => { this.setState({ addParticipantModalData: { ...this.state.addParticipantModalData, categoryIndex: v.target.value } }) }} docs={categories} label={`Selecione a Categoria`} />
            );
        }
    }

    renderAddParticipantClasses() {
        if (this.state.addParticipantModal && this.state.addParticipantModalData.categoryIndex !== null && this.state.tournament.category[this.state.addParticipantModalData.categoryIndex].classes) {
            let classes = [];
            let fullClasses = [];

            this.state.tournament.category[this.state.addParticipantModalData.categoryIndex].classes.map((clss, key) => {
                if (clss.checked === true && ((parseInt(clss.limit) - parseInt(clss.participants.length)) >= 1)) classes.push({ title: clss.title, name: clss.name, index: key })
                if (((parseInt(clss.limit) - parseInt(clss.participants.length)) < 1)) {
                    fullClasses.push(
                        <div style={{ color: Colors.danger, display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, fontWeight: 'bold', marginBottom: 20 }}>
                            <InfoRoundedIcon style={{ fontSize: 25, marginRight: 8 }} />
                            <div>
                                {`${clss.title} está cheia e não aceitará mais inscrições.`}
                            </div>
                        </div>
                    );
                }
            });

            return (
                <div>
                    {fullClasses.length > 0 ? fullClasses : null}
                    <DefaultSelect search={true} multiple={false} searchField={'title'} id={'category_selection_add'} valueField={'index'} displayField={'title'} value={this.state.addParticipantModalData.classIndex} onChange={(v) => { this.setState({ addParticipantModalData: { ...this.state.addParticipantModalData, classIndex: v.target.value } }) }} docs={classes} label={`Selecione a Classe`} />
                </div>
            );
        }
    }

    renderAddParticipantData() {
        if (this.state.addParticipantModal) {
            return (
                <div>
                    <FormLabel style={{ marginTop: 18, fontWeight: '800' }} component="legend">Método de Pagamento</FormLabel>
                    <div style={{ flexDirection: 'row', paddingTop: 10 }}>
                        <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} value={this.state.addParticipantModalData.paymentMethod} onChange={(evt) => { this.setState({ addParticipantModalData: { ...this.state.addParticipantModalData, paymentMethod: evt.target.value } }) }}>
                            <FormControlLabel value="boleto" control={<Radio style={{ color: Colors.primary }} />} label="Boleto" />
                            <FormControlLabel value="pix" control={<Radio style={{ color: Colors.primary }} />} label="Pix" />
                        </RadioGroup>
                    </div>

                    <FormLabel style={{ marginTop: 18, fontWeight: '800' }} component="legend">Valor</FormLabel>
                    <div style={{ flexDirection: 'row', paddingTop: 10 }}>
                        <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} value={this.state.addParticipantModalData.studentPrice} onChange={(evt) => { this.setState({ addParticipantModalData: { ...this.state.addParticipantModalData, studentPrice: evt.target.value } }) }}>
                            <FormControlLabel value="non_student" control={<Radio style={{ color: Colors.primary }} />} label={`Não ${TermHelper.getestablishmentTypeLabel(SessionHelper.getData().company.establishment_type).singular} (${CurrencyHelper.centsToMoney(this.state.tournament.price)})`} />
                            <FormControlLabel value="student" control={<Radio style={{ color: Colors.primary }} />} label={`${TermHelper.getestablishmentTypeLabel(SessionHelper.getData().company.establishment_type).singular} (${CurrencyHelper.centsToMoney(this.state.tournament.student_price)})`} />
                        </RadioGroup>
                    </div>
                    <div style={{ color: Colors.contrast, display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, fontWeight: 'bold', marginBottom: 20 }}>
                        <InfoRoundedIcon style={{ fontSize: 25, marginRight: 8 }} />
                        <div>
                            {`Será gerado um ${this.state.addParticipantModalData.paymentMethod} e o participante será adicionado. Quando o participante efetuar o pagamento do mesmo a inscrição será confirmada.`}
                        </div>
                    </div>

                    <DefaultInput required defaultValue={this.state.addParticipantModalData.name} onBlur={(v) => { this.setState({ addParticipantModalData: { ...this.state.addParticipantModalData, name: v } }) }} label={'Nome e Sobrenome do Participante'} />
                    {this.state.tournament.type !== 'simple' ? <DefaultInput required defaultValue={this.state.addParticipantModalData.secondName} onBlur={(v) => { this.setState({ addParticipantModalData: { ...this.state.addParticipantModalData, secondName: v } }) }} label={'Nome e Sobrenome do 2º Participante'} /> : null}
                    <DefaultInput required defaultValue={this.state.addParticipantModalData.email} onBlur={(v) => { this.setState({ addParticipantModalData: { ...this.state.addParticipantModalData, email: v } }) }} label={'E-mail do Participante'} />
                    <FormLabel style={{ paddingBottom: 10, paddingTop: 0, fontSize: 13 }} component="legend">Este e-mail será utilizado para enviar o boleto e para validar se esse usuário já existe no ACE. Você também pode enviar o e-mail por WhatsApp, etc.</FormLabel>
                    <FormLabel style={{ paddingBottom: 30, paddingTop: 0, fontSize: 13, color: 'red' }} component="legend">[IMPORTANTE] Não envie para seu próprio e-mail ou qualquer outro que não seja o do participante.</FormLabel>

                    <TextareaAutosize maxLength={150} style={{ width: '100%', borderRadius: 5, borderColor: 'lightgrey', padding: 15, fontSize: '1rem' }} rowsMax={4} rowsMin={2} onBlur={(v) => { this.setState({ addParticipantModalData: { ...this.state.addParticipantModalData, observation: v.target.value } }) }} placeholder="Informe as observações desta inscrição em até 150 caracteres (Opcional)..." />
                </div>
            );
        }
    }

    renderFinished() {
        return (
            <div style={{ marginBottom: 50, width: '100%', height: 400, overflowY: 'scroll', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 50 }}>
                <h2 style={{ textAlign: 'center' }}>Sucesso!<br />Inscrição realizada.</h2>
                {
                    this.state.addParticipantModalData.paymentMethod === 'boleto' ?

                        <div>
                            <p style={{ textAlign: 'center', fontSize: 16 }}>O código do boleto é:</p>
                            <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 18 }}>{this.state.addParticipantModalData.boletoCode}</p>
                            <p style={{ textAlign: 'center', fontSize: 16 }}>E você pode encontrar o boleto <a target={'_blank'} href={this.state.addParticipantModalData.boletoLink}>aqui</a>.</p>
                            <p style={{ textAlign: 'center', fontWeight: 'light', fontSize: 10 }}>Um e-mail com o boleto foi enviado automáticamente para o e-mail informado no passo anterior.</p>
                        </div>

                        :

                        <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingTop: 20 }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div onClick={() => { navigator.clipboard.writeText(this.state.addParticipantModalData.pixQrCode); toast.success('Código copiado para a àrea de transferência') }} style={{ marginTop: 8, cursor: 'pointer', boxShadow: Colors.boxShadow, backgroundColor: Colors.primary, display: 'flex', paddingTop: 8, paddingBottom: 8, paddingLeft: 15, paddingRight: 15, borderRadius: 60, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                    <FileCopyIcon style={{ color: 'white', fontSize: 18 }} />
                                    <div style={{ fontWeight: 'bold', marginLeft: 8, color: 'white' }}>Copiar Código "Copia e Cola"</div>
                                </div>
                            </div>
                            <div style={{ padding: 15, marginTop: 40, backgroundColor: 'white', border: `8px solid ${Colors.primary}`, boxShadow: Colors.boxShadow, borderRadius: 20 }}>
                                <QRCode size={200} value={this.state.addParticipantModalData.pixQrCode} />
                            </div>
                        </div>
                }
            </div>
        )
    }

    async handleNext() {
        let isValid = true;
        let step = this.state.addParticipantModalData.step;

        this.setState({ loadingModal: true });

        await this.getTournament();

        if (step === 0) {
            isValid = this.state.addParticipantModalData.categoryIndex > -1 && this.state.addParticipantModalData.categoryIndex !== null;
            if (!isValid) toast.warn('Selecione uma categoria');
        } else if (step === 1) {
            isValid = (this.state.addParticipantModalData.categoryIndex > -1 && this.state.addParticipantModalData.categoryIndex !== null) && (this.state.addParticipantModalData.classIndex > -1 && this.state.addParticipantModalData.classIndex !== null);
            if (!isValid) toast.warn('Selecione uma classe');
        } else if (step === 2) {
            if (!this.state.addParticipantModalData.name || (this.state.tournament.type === 'pair' && !this.state.addParticipantModalData.secondName) || !this.state.addParticipantModalData.email) {
                isValid = false;
                toast.warn('Preencha os campos obrigatórios');
            }

            if (isValid) {
                isValid = (await this.addParticipantToTournament());
                if (!isValid) toast.error('Não foi possível inscrever o participante, tente novamente mais tarde ou contate o suporte.');
            }
        } else if (step === 3) {
            isValid = false;
            step = 0;
            this.setState({
                addParticipantModal: false,
                addParticipantModalData: {
                    categoryIndex: null,
                    classIndex: null,
                    boletoLink: '',
                    boletoCode: '',
                    steps: ['Categoria', 'Classe', 'Dados da Inscrição', 'Finalização'],
                    studentPrice: 'non_student',
                    step: 0,
                    name: '',
                    secondName: '',
                    email: '',
                    observation: '',
                    pixQrCode: '',
                    paymentMethod: 'boleto',
                }
            });
        }

        if (isValid) { step++ }

        this.setState({ addParticipantModalData: { ...this.state.addParticipantModalData, step }, loadingModal: false })
    };

    handleBack() {
        this.setState({ loadingModal: true });

        let step = this.state.addParticipantModalData.step;
        step--;

        this.setState({ addParticipantModalData: { ...this.state.addParticipantModalData, step }, loadingModal: false });
    };

    renderIcon(step) {
        return (
            <div style={{ backgroundColor: this.state.addParticipantModalData.step < step ? 'lightgrey' : Colors.primary, width: 30, height: 30, padding: 5, fontSize: 14, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ color: '#FFFFFF' }}>{this.state.addParticipantModalData.step < step + 1 ? (step + 1) : <CheckIcon style={{ fontSize: 20, paddingTop: 3 }} />}</div>
            </div>
        )
    }

    getGroupResultsTableMatches = async (selectedGroupIndex) => {
        if (this.state.classKey > -1 && this.state.categoryKey > -1 && selectedGroupIndex > -1) {
            this.setState({ loadingResultTable: true, loadingModal: true });

            let query = await Firestore.customQuery('tournament_event').where('id_tournament', '==', this.state.tournament.id)
                .where('indexes.category', '==', this.state.categoryKey).where('indexes.class', '==', this.state.classKey)
                .where('indexes.group', '==', selectedGroupIndex).get();

            let matches = [];

            query.forEach(match => { matches.push({ ...match.data(), id: match.id }) });
            matches.sort((a, b) => a.indexes.match - b.indexes.match);

            this.setState({ groupResultsTableMatches: matches, loadingResultTable: false, loadingModal: false });
        }
    }

    openBracketHeads = (indexes) => {
        this.setState({ openBracketHeadsModal: true, bracketHeadsIndexes: indexes });
    }

    handleMatchSwapSelection(players, position) {
        let playerSwap = this.state.playerSwap;

        if (this.state.tournament.type === 'simple') {
            playerSwap[position] = [players];
        } else {
            playerSwap[position] = TournamentHelper.treatPairSelectionId(players, true);
        }

        if (players.length < 1) playerSwap[position] = [];

        this.setState({ playerSwap });
    }

    swapPlayers = async () => {
        this.setState({ loadingModal: true });

        let req = await Functions.request('POST', `tournament/swapPlayers`,
            {
                p1: this.state.playerSwap.p1,
                p2: this.state.playerSwap.p2,
                tournamentId: this.state.tournament.id,
                categoryIndex: this.state.bracketHeadsIndexes.category,
                classIndex: this.state.bracketHeadsIndexes.class
            }
        );

        if (req && req.status === 200) {
            toast.success('Troca efetuada.');

            this.setState({ playerSwap: { p1: [], p2: [] } });

            await this.getTournament();
            await this.changeView(this.state.tab, true);
        } else {
            toast.error('Erro ao trocar.');
        }

        this.setState({ loadingModal: false });
    }

    openBracketHeadsModal() {
        if (this.state.bracketHeadsIndexes && this.state.selectedClass && this.state.selectedClass.rounds) {
            let playerLabel = this.state.tournament.type === 'simple' ? 'Jogador' : 'Dupla';

            return (
                <div style={{ display: 'flex', flexDirection: LayoutHelper.isSmallScreen() ? 'column-reverse' : 'row', justifyContent: LayoutHelper.isSmallScreen() ? 'center' : 'space-between' }}>
                    <div style={{ minWidth: LayoutHelper.isSmallScreen() ? '100%' : 'auto', height: LayoutHelper.isSmallScreen() ? '40vh' : '80vh', overflowY: 'auto', padding: 5 }}>
                        <FormLabel>Primeira rodada</FormLabel>
                        <TournamentColumnView bracketHeadsSwap={true} openMatch={(match) => {  }} key={0} users={this.state.userDocs} indexes={{ category: this.state.categoryKey, class: this.state.classKey }} tournament={this.state.tournament} rounds={[this.state.selectedClass.rounds[0]]} />
                    </div>
                    <div style={{ width: LayoutHelper.isSmallScreen() ? '100%' : '38%', height: LayoutHelper.isSmallScreen() ? '40vh' : '80vh', overflowY: 'auto', padding: 15 }}>
                        <FormLabel>Observações dos participantes</FormLabel>
                        {this.observationModal(this.state.bracketHeadsIndexes)}
                    </div>
                    <div style={{ width: LayoutHelper.isSmallScreen() ? '100%' : '32%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 20 }}>
                        <div style={{ color: Colors.contrast, display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 25, marginBottom: 40, fontWeight: 'bold' }}>
                            <InfoRoundedIcon style={{ fontSize: 25, marginRight: 8 }} />
                            <div>
                                Aqui você troca a posição de um jogador pela posição de outro na chave da primeira rodada, para evitar conflitos de horário e fazer suas cabeças de chave.
                            </div>
                        </div>

                        <DefaultSelect search={true} searchField={'name'} id={'student_selection_1'} valueField={'id'} displayField={'name'} value={this.state.tournament.type === 'simple' ? this.state.playerSwap.p1 : TournamentHelper.treatPairSelectionId(this.state.playerSwap.p1)} onChange={(v) => { this.handleMatchSwapSelection(v.target.value, 'p1') }} docs={TournamentHelper.getParticipantsByCategoryAndClass(this.state.userDocs, this.state.tournament, this.state.categoryKey, this.state.classKey, true)} label={`${playerLabel} 1`} />
                        <SwapHorizIcon style={{ fontSize: 28, marginTop: 20, marginBottom: 20 }} />
                        <DefaultSelect search={true} searchField={'name'} id={'student_selection_2'} valueField={'id'} displayField={'name'} value={this.state.tournament.type === 'simple' ? this.state.playerSwap.p2 : TournamentHelper.treatPairSelectionId(this.state.playerSwap.p2)} onChange={(v) => { this.handleMatchSwapSelection(v.target.value, 'p2') }} docs={TournamentHelper.getParticipantsByCategoryAndClass(this.state.userDocs, this.state.tournament, this.state.categoryKey, this.state.classKey, true)} label={`${playerLabel} 2`} />

                        <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column', alignItems: 'center', paddingTop: 50, height: 150, width: '100%' }}>
                            <DefaultButton leftIcon={<SwapHorizIcon />} onClick={this.swapPlayers} color={Colors.primary} loading={this.state.loadingModal} width={'100%'} title={'Realizar Troca'} />
                            <DefaultButton leftIcon={<Cancel />} onClick={() => { this.setState({ openBracketHeadsModal: false, bracketHeadsIndexes: null, playerSwap: { p1: [], p2: [] } }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'100%'} title={'Fechar este menu'} />
                        </div>
                    </div>
                </div>
            );
        }
    }

    groupResultsTable() {
        if (this.state.loadingResultTable) {
            return <div style={{ minHeight: 350 }} />
        }

        if (this.state.selectedClass && this.state.groupResultsTable && this.state.selectedGroupIndex > -1 && this.state.groupResultsTableMatches.length > 0) {
            let participants = [];

            this.state.groupResultsTableMatches.forEach((match) => {
                if (match.p1 !== null && match.p2 !== null) {
                    let p1 = match.p1.sort().toString();
                    let p2 = match.p2.sort().toString();

                    let result = {};

                    if (match.winner) {
                        result = {
                            winner: match.winner,
                            ...match.result
                        }
                    }

                    let includesP1 = false, includesP2 = false;

                    participants.forEach(part => {
                        if (p1 === part.players.sort().toString()) {
                            includesP1 = true;
                            part.results.push({ ...result, position: 'p1' });
                        }

                        if (p2 === part.players.sort().toString()) {
                            includesP2 = true;
                            part.results.push({ ...result, position: 'p2' });
                        }
                    });

                    if (!includesP1) participants.push({ players: match.p1, results: [{ ...result, position: 'p1' }] });
                    if (!includesP2) participants.push({ players: match.p2, results: [{ ...result, position: 'p2' }] });
                }
            });

            let resultDocs = participants.map((participant, key) => {
                let participantData = {
                    key: key,
                    players: participant.players,
                    names: [],
                    photos: [],
                    matches: 0,
                    wins: 0,
                    defeats: 0,
                    sets: 0,
                    games: 0,
                    tiebreaks: 0
                }

                participant.players.map(player => {
                    let user = this.state.userDocs.find(item => item.id === player);

                    if (user) {
                        participantData.names.push(user.name || '');
                        participantData.photos.push(user.photo || null);
                    }
                });

                participant.results.map(result => {
                    if (result.winner) {
                        participantData.matches++;

                        let otherPosition = result.position === 'p1' ? 'p2' : 'p1';

                        if (result.winner === result.position) {
                            participantData.wins++;
                        } else {
                            participantData.defeats++;
                        }

                        result.sets.forEach(set => {
                            if (set[result.position] > -1) participantData.games += set[result.position];
                            if (set[result.position] > set[otherPosition]) participantData.sets++;

                            if (isNaN(set[result.position]) && set[result.position].match(/\d+\s\(\d+\)/gm)) {
                                participantData.games += parseInt(set[result.position].match(/\d+\s/gm)[0].replace(' ', ''));

                                if (parseInt(set[result.position].match(/\d+\s/gm)[0]) >
                                    parseInt(set[otherPosition].match(/\(\d+\)/gm)[0].replace('(', '').replace(')', ''))) participantData.tiebreaks++;
                            }
                        });

                        if (result.tiebreak[result.position] && (result.tiebreak[result.position] > result.tiebreak[otherPosition])) participantData.tiebreaks++;
                    }
                });

                return participantData;
            })

            let nOfQualifications = this.state.selectedClass.participants.length > 48 ? 1 : 2;
            let finalists = this.state.selectedClass.finalists || [];

            let advanceCount = 0;
            finalists.forEach(finalist => {
                if (parseInt(finalist.group) === parseInt(this.state.selectedGroupIndex)) advanceCount++;
            });

            let canDisplayAdvance = this.state.selectedClass.bracket_type === 'multi_group' || this.state.selectedClass.bracket_type === 'masters';

            resultDocs.sort((a, b) => (b.wins - a.wins)).forEach((participantData, key) => { participantData.key = key; });

            return (
                <div>
                    <div style={{ display: 'flex', width: '100%', position: 'relative', top: -50, flexDirection: 'row-reverse', marginBottom: -50 }}><IconButton><CloseIcon onClick={() => { if (!this.state.loadingModal) this.setState({ groupResultsTable: false, selectedGroupIndex: -1 }) }} /></IconButton></div>
                    <DefaultTable
                        title={`Grupo ${this.state.selectedGroupIndex + 1}`}
                        actions={advanceCount < nOfQualifications ? [
                            canDisplayAdvance ? rowData => {
                                let canAdvance = true;

                                finalists.forEach(finalist => {
                                    if (finalist.players.sort().toString() === rowData.players.sort().toString()) canAdvance = false;
                                });

                                return canAdvance ? {
                                    icon: SkipNextIcon,
                                    tooltip: 'Avançar para chave eliminatória',
                                    onClick: async (event, rowData) => {
                                        let confirm = window.confirm(`Tem certeza que quer marcar ${rowData.names.join(`,`)} como um dos vencedores e envia-los(as) para as eliminatórias? Essa ação não pode ser revertida.`);

                                        if (confirm) {
                                            if (this.state.loadingModal) {
                                                toast.warn('Aguarde, realizando ação...');
                                                return;
                                            }

                                            this.setState({ loadingModal: true });

                                            let players = rowData.players.join('|');
                                            let req = await Functions.request('PUT', `tournament/movePlayerToEliminatory/${this.state.tournament.id}/${this.state.categoryKey}/${this.state.classKey}/${this.state.selectedGroupIndex}/${players}`, {});

                                            if (req && req.status === 200) {
                                                toast.success('Jogador movido para a etapa eliminatória com sucesso');

                                                await this.getTournament();
                                                await this.changeView(this.state.tab, true);
                                                await this.getGroupResultsTableMatches(this.state.selectedGroupIndex);
                                            } else {
                                                toast.error('Ocorreu um erro ao mover jogador para a etapa eliminatória');
                                            }

                                            this.setState({ loadingModal: false });
                                        }
                                    }
                                } : null;
                            } : null,
                            this.state.selectedClass.bracket_type === 'group' ? rowData => {
                                let canAdvance = true;

                                finalists.forEach(finalist => {
                                    if (finalist.players.sort().toString() === rowData.players.sort().toString()) canAdvance = false;
                                });

                                return canAdvance ? {
                                    icon: EmojiEventsIcon,
                                    tooltip: 'Marcar como um dos vencedores',
                                    onClick: async (event, rowData) => {
                                        let confirm = window.confirm(`Tem certeza que quer marcar ${rowData.names.join(`,`)} como um dos vencedores(as)?`);

                                        if (confirm) {
                                            if (this.state.loadingModal) {
                                                toast.warn('Aguarde, realizando ação...');
                                                return;
                                            }

                                            this.setState({ loadingModal: true });

                                            let players = rowData.players.join('|');
                                            let req = await Functions.request('PUT', `tournament/declareGroupWinner/${this.state.tournament.id}/${this.state.categoryKey}/${this.state.classKey}/${this.state.selectedGroupIndex}/${players}`, {});

                                            if (req && req.status === 200) {
                                                toast.success('Jogador marcado como um dos vencedores.');

                                                await this.getTournament();
                                                await this.changeView(this.state.tab, true);
                                                await this.getGroupResultsTableMatches(this.state.selectedGroupIndex);
                                            } else {
                                                toast.error('Erro ao marcar jogador como um dos vencedores.');
                                            }

                                            this.setState({ loadingModal: false });
                                        }
                                    }
                                } : null;
                            } : null,
                        ] : null}
                        marginTop={10}
                        filtering={true}
                        pageSize={resultDocs.length}
                        rowStyle={(rowData) => {
                            return (
                                { backgroundColor: rowData.key < nOfQualifications ? 'rgba(133, 212, 140, 0.1)' : 'rgba(242, 148, 136, 0.2)' }
                            )
                        }}
                        columns={[
                            { title: 'key', field: 'key', hidden: true },
                            { title: 'Participante', field: 'names', render: rowData => this.renderParticipantsInTable(rowData, finalists), editable: false },
                            { title: 'Jogos', field: 'matches', editable: false, type: 'numeric' },
                            { title: 'Vitórias', field: 'wins', editable: false, type: 'numeric' },
                            { title: 'Derrotas', field: 'defeats', editable: false, type: 'numeric' },
                            { title: 'Sets', field: 'sets', editable: false, type: 'numeric' },
                            { title: 'Games', field: 'games', editable: false, type: 'numeric' },
                            { title: 'Tiebreaks', field: 'tiebreaks', editable: false, type: 'numeric' },
                        ]}
                        data={resultDocs}
                    />
                </div>
            );
        }
    }

    renderParticipantsInTable(rowData, finalists) {
        return (
            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                {finalists.map(finalist => {
                    if (finalist.players.sort().toString() === rowData.players.sort().toString()) {
                        return <Tooltip title={`Avançado para eliminatórias em ${moment(finalist.date.toDate()).format('DD/MM/YYYY HH:mm:ss')}`}><AccountTreeIcon style={{ color: 'orange', marginRight: 10 }} /></Tooltip>;
                    }
                })}
                {rowData.photos && rowData.photos.length ?
                    rowData.photos.map((photo, key) => (
                        <img
                            style={{
                                height: 32,
                                width: 32,
                                borderRadius: 32 / 2,
                                boxShadow: Colors.boxShadow,
                                marginLeft: key > 0 ? -10 : 0
                            }}
                            src={photo ? photo : process.env.PUBLIC_URL + '/empty_avatar.png'}
                        />
                    ))
                    : null}
                {rowData.names && rowData.names.length ?
                    <div style={{ marginLeft: 15 }}>{rowData.names.join(`,`)}</div>
                    : null}
            </div>
        );
    }

    addParticipantModal() {
        if (this.state.addParticipantModal) {

            return (
                <div>
                    <Stepper style={{ width: '100%' }} activeStep={this.state.activeStep} alternativeLabel>
                        {this.state.addParticipantModalData.steps.map((label, key) => (
                            <Step key={label}>
                                <StepLabel icon={this.renderIcon(key)}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    {this.state.addParticipantModalData.step === 0 && this.renderAddParticipantCategories()}
                    {this.state.addParticipantModalData.step === 1 && this.renderAddParticipantClasses()}
                    {this.state.addParticipantModalData.step === 2 && this.renderAddParticipantData()}
                    {this.state.addParticipantModalData.step === 3 && this.renderFinished()}

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton leftIcon={<NavigateBeforeIcon />} onClick={() => { this.handleBack() }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'Voltar'} disabled={this.state.addParticipantModalData.step === 0 || this.state.addParticipantModalData.step === this.state.addParticipantModalData.steps.length - 1} />
                        <DefaultButton rightIcon={this.state.addParticipantModalData.step === this.state.addParticipantModalData.steps.length - 1 ? <CheckIcon style={{ marginLeft: 3 }} /> : <NavigateNextIcon />} onClick={() => { this.handleNext() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={this.state.addParticipantModalData.step === this.state.addParticipantModalData.steps.length - 1 ? 'Finalizar' : 'Avançar'} />
                    </div>
                </div>
            )
        }
    }

    observationModal(optIndexes) {
        let observations = {};

        if (this.state.observationModal || optIndexes) {
            let categories = this.state.tournament.category;

            if (optIndexes) {
                categories = [
                    { ...this.state.tournament.category[optIndexes.category], classes: [{ ...this.state.tournament.category[optIndexes.category].classes[optIndexes.class] }] }
                ]
            }

            return (
                <div>
                    {!optIndexes ? <DefaultButton disabled={this.state.loadingModal} width={`auto`} leftIcon={<PrintIcon style={{ marginLeft: 8 }} />} onClick={() => { this.exportObservation(observations) }} title={'Imprimir Observações'} /> : null}
                    {categories.map((category, key) => {

                        return (
                            <div style={{ marginTop: 15 }}>
                                {category.checked ?

                                    <div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            {!optIndexes ? <h2>{`${category.title}`}</h2> : null}
                                        </div>

                                        {
                                            category.classes.map((categoryClass, classKey) => {
                                                if (!observations[classKey]) observations[classKey] = { title: categoryClass.title, observations: [] };

                                                return (
                                                    <div style={{ marginTop: 20 }}>
                                                        {!optIndexes ? <FormLabel style={{ fontSize: 18 }}>{`${categoryClass.title}`}</FormLabel> : null}

                                                        <div style={{ overflowX: !optIndexes ? 'scroll' : 'hidden', display: 'flex', flexDirection: 'column', marginTop: 20 }}>
                                                            {this.state.userDocs.length ?
                                                                categoryClass.participants.map((participant, key) => {
                                                                    if (participant.observation && participant.observation.length > 0) {
                                                                        let images = [];
                                                                        let names = [];
                                                                        let status = [];

                                                                        let content = (
                                                                            <div style={{ display: 'flex', flexDirection: 'row', boxShadow: Colors.boxShadow, borderRadius: 5, marginTop: 15, border: `3px solid ${Colors.primary}`, padding: 10 }}>
                                                                                {
                                                                                    participant.players.map((player, playerKey) => {
                                                                                        let user = this.state.userDocs.find(item => item.id === player);

                                                                                        if (user) {
                                                                                            names.push(user.name);
                                                                                            images.push(user.photo ? user.photo : process.env.PUBLIC_URL + '/empty_avatar.png');
                                                                                            status.push(user.id_companies && user.id_companies.length > 0 && user.id_companies.includes(SessionHelper.getData().id_company));
                                                                                        }
                                                                                    })
                                                                                }

                                                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: 170, paddingLeft: 10 }}>
                                                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                                        {images.map((elem, key) =>
                                                                                            <div style={{ paddingTop: 10, paddingBottom: 5, marginLeft: key > 0 ? -20 : 0 }}>
                                                                                                <img style={{ height: 72, width: 72, borderRadius: 72 / 2, boxShadow: Colors.boxShadow, border: `3px solid ${status[key] ? Colors.success : `orange`}` }} src={elem} />
                                                                                                <div style={{ marginTop: -15, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', fontSize: 10, width: 72 }}>
                                                                                                    {status[key] ?
                                                                                                        <div style={{ backgroundColor: Colors.success, paddingTop: 3, paddingBottom: 3, paddingLeft: 5, paddingRight: 5, color: 'white', fontWeight: 'bold', marginLeft: 8, borderRadius: 50 }}>{TermHelper.getestablishmentTypeLabel(SessionHelper.getData().company.establishment_type).singular}</div> :
                                                                                                        <div style={{ backgroundColor: 'orange', paddingTop: 3, paddingBottom: 3, paddingLeft: 5, paddingRight: 5, color: 'white', fontWeight: 'bold', marginLeft: 8, borderRadius: 50 }}>Não {TermHelper.getestablishmentTypeLabel(SessionHelper.getData().company.establishment_type).singular}</div>}
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                    <div style={{ paddingBottom: 10, display: 'column', flexDirection: 'row', alignSelf: 'center', textAlign: 'center', justifyContent: 'center' }}>
                                                                                        {names.map((name, key) => (
                                                                                            <Tooltip title={name}>
                                                                                                <div style={{ marginTop: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: 12, maxWidth: 170 }}>
                                                                                                    <PersonIcon style={{ marginRight: 5, color: status[key] ? Colors.success : 'orange' }} />
                                                                                                    {name.length > 15 ? name.slice(0, 15) + '...' : name}
                                                                                                </div>
                                                                                            </Tooltip>
                                                                                        ))}
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ padding: 20, display: 'flex', flexWrap: 'wrap', maxWidth: 320 }}>
                                                                                    <p>
                                                                                        "{participant.observation}"
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        );

                                                                        observations[classKey].observations.push({ names, status, observation: participant.observation });

                                                                        return content;
                                                                    }
                                                                })

                                                                : null}
                                                        </div>

                                                        {!categoryClass.participants.length ? <p style={{ color: 'grey', minHeight: 50, paddingTop: 15 }}>{'Nenhum jogador inscrito.'}</p> : null}
                                                    </div>
                                                )

                                            })
                                        }

                                    </div>

                                    : null}
                            </div>
                        )
                    })}
                    {!optIndexes ?
                        <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                            <DefaultButton onClick={() => { this.setState({ observationModal: false }) }} color={Colors.primary} loading={this.state.loadingModal} width={'100%'} title={'FECHAR'} />
                        </div>
                        : null}
                </div>
            )
        }
    }

    isBye(match) { return match.p1.length && !match.p2 }

    editMatchModal() {

        if (this.state.editMatchModal && this.state.editMatchData) {
            let playerLabel = this.state.tournament.type === 'simple' ? 'Jogador' : 'Dupla';

            if (this.isBye(this.state.editMatchData)) {
                return (
                    <div>
                        <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 25, marginBottom: 25, fontWeight: 'bold' }}>
                            <InfoRoundedIcon style={{ fontSize: 25, marginRight: 8 }} />
                            <div>
                                {`Atenção: tenha cuidado ao alterar uma partida manualmente.`}
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <DefaultSelect search={true} searchField={'name'} id={'student_selection_1'} valueField={'id'} displayField={'name'} value={this.state.tournament.type === 'simple' ? this.state.editMatchData.p1 : TournamentHelper.treatPairSelectionId(this.state.editMatchData.p1)} onChange={(v) => { this.handleMatchEditPlayerSelection(v.target.value, 'p1') }} docs={TournamentHelper.getParticipantsByCategoryAndClass(this.state.userDocs, this.state.tournament, this.state.categoryKey, this.state.classKey, true)} label={`${playerLabel} 1`} />
                        </div>
                        <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                            <DefaultButton onClick={() => { this.updateMatch() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'SALVAR'} />
                            <DefaultButton onClick={() => { this.setState({ editMatchModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                        </div>
                    </div>
                )
            }

            return (
                <div>
                    <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 25, marginBottom: 25, fontWeight: 'bold' }}>
                        <InfoRoundedIcon style={{ fontSize: 25, marginRight: 8 }} />
                        <div>
                            {`Atenção: tenha cuidado ao alterar uma partida manualmente.`}
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <DefaultSelect search={true} searchField={'name'} id={'student_selection_1'} valueField={'id'} displayField={'name'} value={this.state.tournament.type === 'simple' ? this.state.editMatchData.p1 : TournamentHelper.treatPairSelectionId(this.state.editMatchData.p1)} onChange={(v) => { this.handleMatchEditPlayerSelection(v.target.value, 'p1') }} docs={TournamentHelper.getParticipantsByCategoryAndClass(this.state.userDocs, this.state.tournament, this.state.categoryKey, this.state.classKey, true)} label={`${playerLabel} 1`} />
                        <div style={{ margin: 15, fontSize: 30 }}>X</div>
                        <DefaultSelect search={true} searchField={'name'} id={'student_selection_2'} valueField={'id'} displayField={'name'} value={this.state.tournament.type === 'simple' ? this.state.editMatchData.p2 : TournamentHelper.treatPairSelectionId(this.state.editMatchData.p2)} onChange={(v) => { this.handleMatchEditPlayerSelection(v.target.value, 'p2') }} docs={TournamentHelper.getParticipantsByCategoryAndClass(this.state.userDocs, this.state.tournament, this.state.categoryKey, this.state.classKey, true)} label={`${playerLabel} 2`} />

                        <div style={{ width: '100%', marginTop: 15 }}>
                            <InputLabel>Quadra</InputLabel>
                            <Select style={{ width: '100%' }} value={this.state.editMatchData.court} onChange={(v) => { this.setState({ editMatchData: { ...this.state.editMatchData, court: v.target.value } }) }}>
                                {
                                    this.state.tournament.courts.map((court, key) => {

                                        return <MenuItem value={court}>{court}</MenuItem>
                                    })
                                }
                            </Select>
                        </div>
                        <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', paddingTop: 10 }}>
                            <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                <KeyboardDateTimePicker
                                    style={{ width: '100%', paddingRight: 5, marginBottom: 15 }}
                                    invalidDateMessage={false}
                                    format={'dd/MM/yyyy HH:mm'}
                                    autoOk={true}
                                    label="Início"
                                    cancelLabel={'Cancelar'}
                                    okLabel={'Confirmar'}
                                    minDate={new Date()}
                                    onChange={(v) => { this.setState({ editMatchData: { ...this.state.editMatchData, start: v, end: moment(v).add(1, 'hour').toDate() } }) }}
                                    value={this.state.editMatchData.start.toDate ? this.state.editMatchData.start.toDate() : this.state.editMatchData.start}
                                />
                                <KeyboardDateTimePicker
                                    style={{ width: '100%', paddingLeft: 5, marginBottom: 15 }}
                                    invalidDateMessage={false}
                                    format={'dd/MM/yyyy HH:mm'}
                                    autoOk={true}
                                    label="Fim"
                                    cancelLabel={'Cancelar'}
                                    minDate={this.state.editMatchData.start}
                                    okLabel={'Confirmar'}
                                    onChange={(v) => { this.setState({ editMatchData: { ...this.state.editMatchData, end: v } }) }}
                                    value={this.state.editMatchData.end.toDate ? this.state.editMatchData.end.toDate() : this.state.editMatchData.end}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.updateMatch() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'SALVAR'} />
                        <DefaultButton onClick={() => { this.setState({ editMatchModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    render() {
        return this.state.loading ? <DefaultLoader /> :
            (

                <div style={{ margin: LayoutHelper.isSmallScreen() ? 5 : 25, padding: LayoutHelper.isSmallScreen() ? 10 : 25, backgroundColor: 'white', boxShadow: Colors.boxShadow, borderRadius: 5 }}>

                    {this.renderHeader()}

                    <div style={{ display: 'flex', flexDirection: LayoutHelper.isSmallScreen() ? 'column' : 'row', marginTop: 50 }}>

                        {this.renderCategories()}

                        {this.state.selectedClass && this.state.selectedCategory ? this.renderMatches() : this.renderEmpty()}

                    </div>

                    {
                        this.state.matchPanel ?

                            <TournamentMatchPanel
                                users={this.state.userDocs}
                                tournament={this.state.tournament.id}
                                match={this.state.matchPanel}
                                panelOpen={this.state.matchOpen}
                                close={() => { this.setState({ matchOpen: false, matchPanel: null }) }}
                                openEditModal={(match) => { this.setState({ editMatchModal: true, editMatchData: { ...match } }) }}
                                openResultModal={async (originalMatch) => {
                                    let match = { ...originalMatch };

                                    this.setState({
                                        resultModal: true,
                                        editMatchData: match,
                                        resultSets: match.result.sets && match.result.sets.length ? match.result.sets : [{ p1: 0, p2: 0 }],
                                        saveResultSets: match.result.sets && match.result.sets.length ? match.result.sets : [{ p1: 0, p2: 0 }],
                                        resultSetsTiebreak: [{ p1: 0, p2: 0 }], resultTiebreak: {}
                                    })
                                }}
                            />

                            : null
                    }

                    <DefaultModal loading={this.state.loadingModal} content={this.editCategoryModal()} title={'Editar Categoria'} onClose={() => { this.setState({ editCategoryModal: false }) }} open={this.state.editCategoryModal} />
                    <DefaultModal loading={this.state.loadingModal} content={this.editMatchModal()} title={'Editar Partida'} onClose={() => { this.setState({ editMatchModal: false }) }} open={this.state.editMatchModal} />
                    <DefaultModal loading={this.state.loadingModal} content={this.courtModal()} title={'Quadras'} onClose={() => { this.setState({ courtModal: false }) }} open={this.state.courtModal} />
                    <DefaultModal loading={this.state.loadingModal} content={this.matchHourModal()} title={'Horário das Partidas'} onClose={() => { this.setState({ matchHourModal: false }) }} open={this.state.matchHourModal} />
                    <DefaultModal loading={this.state.loadingModal} content={this.rescheduleModal()} title={'Reagendar Partidas'} onClose={() => { this.setState({ rescheduleModal: false }) }} open={this.state.rescheduleModal} />
                    <DefaultModal loading={this.state.loadingModal} content={this.resultModal()} title={'Informar Resultado'} onClose={() => { this.setState({ resultModal: false, isWo: false, gaveUp: false }) }} open={this.state.resultModal} width={800} />
                    <DefaultModal loading={this.state.loadingModal} content={this.settingsModal()} title={'Configurações'} onClose={() => { this.setState({ settingsModal: false }) }} open={this.state.settingsModal} />
                    <DefaultModal loading={this.state.loadingModal} content={this.observationModal()} title={'Observações de Inscritos'} onClose={() => { this.setState({ observationModal: false }) }} open={this.state.observationModal} />
                    <DefaultModal loading={this.state.loadingModal} content={this.addParticipantModal()} title={'Adicionar Participante Manualmente'} onClose={() => { this.setState({ addParticipantModal: false }) }} open={this.state.addParticipantModal} />
                    <DefaultModal loading={this.state.loadingModal} content={this.groupResultsTable()} title={'Resultados de Grupo'} onClose={() => { this.setState({ groupResultsTable: false, selectedGroupIndex: -1 }) }} open={this.state.groupResultsTable} width={window.screen.width - 20} />
                    <DefaultModal loading={this.state.loadingModal} content={this.openBracketHeadsModal()} title={'Cabeças de chave e impedimentos de horários'} onClose={() => { this.setState({ openBracketHeadsModal: false, bracketHeadsIndexes: null, playerSwap: { p1: [], p2: [] } }) }} open={this.state.openBracketHeadsModal} width={window.screen.width - 20} />
                </div>
            )
    }
}
