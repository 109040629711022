import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import { Card, FormLabel, IconButton, Tooltip, List, ListItem, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import SessionHelper from '../helper/SessionHelper';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import AddIcon from '@material-ui/icons/Add';
import LabelIcon from '@material-ui/icons/Label';
import GroupIcon from '@material-ui/icons/Group';
import EventIcon from '@material-ui/icons/Event';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Colors from '../constants/Colors';
import ReactDragListView from 'react-drag-listview/lib/index.js';
import IosSwitch from '../components/IosSwitch';
import LocalNotificationHelper from '../helper/LocalNotificationHelper';
import HeaderActionsWrapper from '../components/HeaderActionsWrapper';
import { CirclePicker } from 'react-color';

export default class ParamPage extends Component {

    state = {
        config: {
            categories: [
                { label: 'Categoria 1', value: '1', hover: false, color: Colors.categoryColors[4] },
                { label: 'Categoria 2', value: '2', hover: false, color: Colors.categoryColors[3] },
                { label: 'Categoria 3', value: '3', hover: false, color: Colors.categoryColors[2] },
                { label: 'Categoria 4', value: '4', hover: false, color: Colors.categoryColors[1] },
                { label: 'Categoria 5', value: '5', hover: false, color: Colors.categoryColors[0] },
            ],
            tags: [],
            calendar_default_view: 'day',
            auto_replacement_lesson: false,
        },
        addCategory: false,
        category_name: '',
        addTag: false,
        tag_name: '',
        tag_color: Colors.primary,
        category_color: Colors.primary,
        hasPermission: false,
        loading: true,
    }

    async getDocs() {

        let query = await Firestore.customQuery('settings').where('id_company', '==', SessionHelper.getData().id_company).get();
        let data = this.state.config;

        if (query.size === 1 && query.docs[0]) {

            data = query.docs[0].data();
            data.id = query.docs[0].id;

            if (!data.tags) data.tags = [];
            if (!data.calendar_default_view) data.calendar_default_view = 'day';
            if (!data.auto_replacement_lesson) data.auto_replacement_lesson = false;
        }

        this.setState({ config: data });
    }

    async componentDidMount() {

        await this.getDocs();
        await this.getPermission();

        this.setState({ loading: false });
    }

    getPermission() {

        if (window.Notification) {

            if (Notification.permission === 'granted') {
                this.setState({ hasPermission: true });
            }
        }
    }

    async save() {

        if (this.state.config && this.state.config.id) {

            this.setState({ loading: true });

            let categories = this.state.config.categories;
            let categoriesInsert = [];

            categories.forEach((category, key) => {

                delete category.hover;

                categoriesInsert.push(category);
            });

            let tags = this.state.config.tags;
            let tagsInsert = [];

            tags.forEach((tag, key) => {

                delete tag.hover;

                tagsInsert.push(tag);
            });

            let data = {
                categories: categoriesInsert,
                tags: tagsInsert,
                calendar_default_view: this.state.config.calendar_default_view,
                auto_replacement_lesson: this.state.config.auto_replacement_lesson,
            };

            try {

                await Firestore.update(data, 'settings', this.state.config.id);
                await this.getDocs();

                toast.success("Salvo com sucesso");
                this.setState({ loading: false });

            } catch (error) {
                this.setState({ loading: false });
                toast.error("Houve um problema ao cadastrar");
            }

        } else {

            let data = {
                categories: this.state.config.categories,
                tags: [],
                id_company: SessionHelper.getData().id_company,
                calendar_default_view: this.state.config.calendar_default_view,
            };

            try {

                await Firestore.insert(data, 'settings');

                toast.success("Salvo com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

            } catch (error) {

                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    async removeCategory(key) {

        let confirm = window.confirm('Tem certeza que deseja remover?');

        if (confirm) {

            try {

                this.setState({ loading: true });

                let config = this.state.config;
                let category = config.categories[key].value;

                let query = await Firestore.customQuery('user').where('type', '==', 'student').where('id_companies', 'array-contains', SessionHelper.getData().id_company).where('category', '==', category.toString()).get();

                if (query.size === 0) {

                    config.categories.splice(key, 1);

                } else {

                    toast.warn('Ainda existem alunos usando essa categoria');
                }

                this.setState({ config: config, loading: false });

            } catch (error) {

                toast.error('Houve um erro ao remover essa categoria');
                this.setState({ loading: false });
            }
        }
    }

    async removeTag(key) {

        let confirm = window.confirm('Tem certeza que deseja remover?');

        if (confirm) {

            try {

                this.setState({ loading: true });

                let config = this.state.config;
                config.tags.splice(key, 1);

                this.setState({ config: config, loading: false });

            } catch (error) {

                toast.error('Houve um erro ao remover essa tag');
                this.setState({ loading: false });
            }
        }
    }

    randomHash() {

        return (Math.random() + 1).toString(36).substring(2);
    }

    addCategory() {

        if (this.state.category_name) {

            let config = this.state.config;

            config.categories.push({
                value: this.randomHash(),
                label: this.state.category_name,
                color: this.state.category_color,
                hover: false,
            });

            this.setState({ config: config, category_name: '', category_color: Colors.primary, addCategory: false });

        } else {

            toast.warn('Preencha o nome da categoria');
        }
    }

    addTag() {

        if (this.state.tag_name) {

            let config = this.state.config;

            config.tags.push({
                value: this.randomHash(),
                label: this.state.tag_name,
                color: this.state.tag_color,
                hover: false,
            });

            this.setState({ config: config, tag_name: '', tag_color: Colors.primary, addTag: false });

        } else {

            toast.warn('Preencha o nome da tag');
        }
    }

    toggleCategoryHover(value, key) {

        let config = this.state.config;
        config.categories[key].hover = value;

        this.setState({ config: config });
    }

    toggleTagHover(value, key) {

        let config = this.state.config;
        config.tags[key].hover = value;

        this.setState({ config: config });
    }

    reorderCategories(startIndex, endIndex) {

        let config = this.state.config;
        let list = config.categories;

        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        config.categories = result;

        this.setState({ config: config });
    };

    reorderTags(startIndex, endIndex) {

        let config = this.state.config;
        let list = config.tags;

        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        config.tags = result;

        this.setState({ config: config });
    };

    renderCategories() {
        return (
            <List style={{ display: 'flex', flexDirection: 'column' }}>

                <ReactDragListView onDragEnd={(from, to) => { this.reorderCategories(from, to) }} nodeSelector={'li'} handleSelector={'#category-drag'} lineClassName={'category-drag-line'}>
                    {

                        this.state.config.categories.map((category, key) => {

                            return (
                                <li onMouseEnter={() => { this.toggleCategoryHover(true, key) }} onMouseLeave={() => { this.toggleCategoryHover(false, key) }} button style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', minHeight: 70, padding: 10, fontSize: 15, borderRadius: 5, borderBottom: '0.3px solid lightgrey', boxShadow: Colors.boxShadow }}>

                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <input defaultValue={category.color || Colors.primary} value={category.color} onChange={(v) => { category.color = v.target.value }} style={{ height: 24, width: 24, borderRadius: 12, marginRight: 8, marginLeft: 8, backgroundColor: category.color || Colors.primary }} type={'color'} />
                                        {category.label}
                                    </div>


                                    {category.hover ?

                                        <div>
                                            <IconButton style={{ color: 'grey', cursor: 'move' }} id={'category-drag'}>
                                                <DragHandleIcon />
                                            </IconButton>
                                            <Tooltip title={'Remover'}>
                                                <IconButton onClick={() => { this.removeCategory(key) }}>
                                                    <ClearIcon style={{ color: Colors.error }} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>

                                        : null}

                                </li>
                            )
                        })

                    }
                </ReactDragListView>

                <div>
                    {!this.state.addCategory && <div style={{ marginTop: 20 }}><DefaultButton leftIcon={<AddIcon style={{ marginRight: 5 }} />} onClick={() => { this.setState({ addCategory: true }) }} title={'Adicionar Categoria'} /></div>}

                    {this.state.addCategory ?

                        <ListItem style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minHeight: 70, padding: 20, borderRadius: 5, borderBottom: '0.3px solid lightgrey', boxShadow: Colors.boxShadow }}>
                            <DefaultInput onBlur={(v) => { this.setState({ category_name: v }) }} label={'Nome da Categoria'} />
                            <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: 3, marginLeft: 20, marginRight: 20 }}>
                                <FormLabel style={{ marginBottom: 4 }} component="legend">Cor</FormLabel>
                                <CirclePicker color={this.state.category_color} onChangeComplete={(color) => { this.setState({ category_color: color.hex }) }} colors={Colors.pickerColors} />
                            </div>
                            <Tooltip title={'Adicionar'}>
                                <IconButton onClick={() => { this.addCategory() }}>
                                    <CheckIcon style={{ color: 'green' }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={'Cancelar'}>
                                <IconButton onClick={() => { this.setState({ category_name: '', addCategory: false }) }}>
                                    <ClearIcon style={{ color: 'red' }} />
                                </IconButton>
                            </Tooltip>
                        </ListItem>

                        : null}
                </div>
            </List>
        )
    }

    renderTags() {
        return (
            <List style={{ display: 'flex', flexDirection: 'column' }}>

                <ReactDragListView onDragEnd={(from, to) => { this.reorderTags(from, to) }} nodeSelector={'li'} handleSelector={'#tag-drag'} lineClassName={'category-drag-line'}>
                    {

                        this.state.config.tags.map((tag, key) => {

                            return (
                                <li onMouseEnter={() => { this.toggleTagHover(true, key) }} onMouseLeave={() => { this.toggleTagHover(false, key) }} button style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', minHeight: 70, padding: 10, fontSize: 15, borderRadius: 5, borderBottom: '0.3px solid lightgrey', boxShadow: Colors.boxShadow, backgroundColor: tag.color }}>

                                    <div style={{ color: 'white', fontWeight: 'bold' }}>{tag.label}</div>

                                    {tag.hover ?

                                        <div>
                                            <IconButton style={{ color: 'white', cursor: 'move' }} id={'tag-drag'}>
                                                <DragHandleIcon />
                                            </IconButton>
                                            <Tooltip title={'Remover'}>
                                                <IconButton onClick={() => { this.removeTag(key) }}>
                                                    <ClearIcon style={{ color: 'white' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>

                                        : null}

                                </li>
                            )
                        })

                    }
                </ReactDragListView>

                {!this.state.config.tags.length ? <p style={{ color: 'grey', marginLeft: 5 }}>{'Nenhuma tag cadastrada.'}</p> : null}

                <div>
                    {!this.state.addTag && <div style={{ marginTop: 20 }}><DefaultButton leftIcon={<AddIcon style={{ marginRight: 5 }} />} onClick={() => { this.setState({ addTag: true }) }} title={'Adicionar Tag'} /></div>}

                    {this.state.addTag ?

                        <ListItem style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minHeight: 70, padding: 20, borderRadius: 5, borderBottom: '0.3px solid lightgrey', boxShadow: Colors.boxShadow }}>
                            <DefaultInput onBlur={(v) => { this.setState({ tag_name: v }) }} label={'Nome da Tag'} />
                            <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: 3, marginLeft: 20 }}>
                                <FormLabel style={{}} component="legend">Cor</FormLabel>
                                <input value={this.state.tag_color} onChange={(v) => { this.setState({ tag_color: v.target.value }) }} style={{ height: 50, width: 150, paddingBottom: 3 }} type={'color'} />
                            </div>
                            <Tooltip title={'Adicionar'}>
                                <IconButton onClick={() => { this.addTag() }}>
                                    <CheckIcon style={{ color: 'green' }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={'Cancelar'}>
                                <IconButton onClick={() => { this.setState({ tag_name: '', tag_color: Colors.primary, addTag: false }) }}>
                                    <ClearIcon style={{ color: 'red' }} />
                                </IconButton>
                            </Tooltip>
                        </ListItem>

                        : null}
                </div>
            </List>
        )
    }

    async handleNotificationPermission(value) {

        if (value) {

            let value = LocalNotificationHelper.askPermission();

            if (value === 'granted') {

                this.setState({ hasPermission: true });
            }

        } else {

            toast.warn('Desabilite as notificações através do seu navegador');
        }
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <HeaderActionsWrapper style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<CheckIcon style={{ marginRight: 5 }} />} onClick={() => { this.save(); }} title={'Salvar'} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>

                    </div>
                </HeaderActionsWrapper>
                <div style={{ paddingTop: 10 }}>
                    <Card style={{ paddingLeft: 20, paddingRight: 20, marginBottom: 15, paddingBottom: 40 }}>
                        <p style={{ fontSize: 18, fontWeight: 'bold', }}>{'Configurações'}</p>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <FormLabel style={{ paddingTop: 18, paddingBottom: 18 }} component="legend">Agenda</FormLabel>
                            <EventIcon style={{ color: 'grey', marginLeft: 8 }} />
                            <div style={{ borderBottom: '1px solid lightgrey', width: '100%', marginLeft: 15 }} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginBottom: 10 }}>
                            <IosSwitch fullWidth label="Reposições Automatizadas" value="repeat" checked={this.state.config.auto_replacement_lesson} onChange={(v) => { this.setState({ config: { ...this.state.config, auto_replacement_lesson: v } }) }} />
                            <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '80%' }}>
                                <InfoRoundedIcon style={{ color: 'grey', fontSize: 15, marginRight: 5 }} />
                                <FormLabel style={{ fontSize: 13 }} component="legend"> Quando um aluno recusa uma aula pelo app, uma vaga de reposição é automáticamente aberta à alunos das mesmas categorias da aula. (Recomendamos que cadastre as categorias dos alunos, para as aulas serem mais balanceadas)</FormLabel>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20 }}>
                            <FormLabel style={{ marginTop: 18, fontSize: 13 }} component="legend">Visualização padrão da agenda</FormLabel>
                            <div style={{ flexDirection: 'row', paddingTop: 10 }}>
                                <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} value={this.state.config.calendar_default_view} onChange={(evt) => { let config = this.state.config; config.calendar_default_view = evt.target.value; this.setState({ config }); }}>
                                    <FormControlLabel value="month" control={<Radio style={{ color: Colors.primary }} />} label="Mês" />
                                    <FormControlLabel value="week" control={<Radio style={{ color: Colors.primary }} />} label="Semana" />
                                    <FormControlLabel value="day" control={<Radio style={{ color: Colors.primary }} />} label="Dia" />
                                </RadioGroup>
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <FormLabel style={{ paddingTop: 18, paddingBottom: 18 }} component="legend">Categorias</FormLabel>
                            <GroupIcon style={{ color: 'grey', marginLeft: 8 }} />
                            <div style={{ borderBottom: '1px solid lightgrey', width: '100%', marginLeft: 15 }} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20 }}>
                            {this.renderCategories()}
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <FormLabel style={{ paddingTop: 18, paddingBottom: 18 }} component="legend">Tags</FormLabel>
                            <LabelIcon style={{ color: 'grey', marginLeft: 8 }} />
                            <div style={{ borderBottom: '1px solid lightgrey', width: '100%', marginLeft: 15 }} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20 }}>
                            {this.renderTags()}
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <FormLabel style={{ paddingTop: 18, paddingBottom: 18 }} component="legend">Notificações</FormLabel>
                            <NotificationsIcon style={{ color: 'grey', marginLeft: 8 }} />
                            <div style={{ borderBottom: '1px solid lightgrey', width: '100%', marginLeft: 15 }} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20 }}>
                            <IosSwitch fullWidth label="Notificações Push" value="repeat" checked={this.state.hasPermission} onChange={(v) => { this.handleNotificationPermission(v) }} />
                        </div>
                    </Card>
                </div>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
