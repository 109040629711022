import { toast } from 'react-toastify';
import Firestore from '../api/firebase/Firestore';
import SessionHelper from './SessionHelper';

export default class InventoryHelper {

    static async addProductMovement(id_product, quantity, type, obs, system) {

        try {

            if (!id_product) { throw new Error('id error') }
            if (!quantity) { throw new Error('quantity error') }
            if (type !== 'entry' && type !== 'exit') { throw new Error('type should be "entry or exit"') }
            
            let productQuery = await Firestore.getDoc('product', id_product);

            if (!productQuery.exists) {

                throw new Error('product dont exists');

            } else {

                let product = { ...productQuery.data(), id: productQuery.id };
                let quantity_available = parseInt(product.quantity_available);

                if (type === 'entry') {

                    quantity_available += parseInt(quantity);

                } else if (type === 'exit') {

                    quantity_available -= parseInt(quantity);
                }

                let movement = {
                    id_product: product.id,
                    price: product.price,
                    quantity: parseInt(quantity),
                    quantity_available: parseInt(quantity_available),
                    quantity_before: parseInt(product.quantity_available),
                    type: type,
                    id_user: SessionHelper.getFirebaseAuth().uid,
                    system: system,
                    date: new Date(),
                    obs: obs,
                    id_company: SessionHelper.getData().id_company,
                }

                await Firestore.insert(movement, 'inventory_movement');
                await Firestore.update({ quantity_available: parseInt(quantity_available), notification_sended: false }, 'product', product.id);
            }

        } catch (error) {
            
            toast.error('Houve um problema ao gerar a movimentação do produto');
        }
    }

    static async deleteProductMovement(id_movement, update_product = true) {

        try {

            if (!id_movement) { throw new Error('id error') }

            let movementQuery = await Firestore.getDoc('inventory_movement', id_movement);

            if (!movementQuery.exists) {

                throw new Error('movement dont exists');

            } else {

                let movement = { ...movementQuery.data(), id: movementQuery.id };
                let productQuery = await Firestore.getDoc('product', movement.id_product);

                if (!productQuery.exists) {
    
                    throw new Error('product dont exists');
    
                } else {
    
                    let product = { ...productQuery.data(), id: productQuery.id };
                    let quantity_available = parseInt(product.quantity_available);

                    if (movement.type === 'entry') {
    
                        quantity_available -= parseInt(movement.quantity);
    
                    } else if (movement.type === 'exit') {
    
                        quantity_available += parseInt(movement.quantity);
                    }
    
                    await Firestore.delete('inventory_movement', id_movement);
                    
                    if (update_product) {

                        await Firestore.update({ quantity_available: parseInt(quantity_available) }, 'product', product.id);
                    }
                }
            }
             
        } catch(error) {

            toast.error('Houve um problema ao remover essa movimentação');
        }
    }

    static async deleteProductsMovement(id_movements = [], update_product = true) {

        for (const id of id_movements) {

            await this.deleteProductMovement(id, update_product);
        }
    }

    static async addProductsMovement(id_products, quantity, type, obs, system = false) {

        for (const id of id_products) {

            await this.addProductMovement(id, quantity, type, obs, system);
        };
    }
}