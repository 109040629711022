import React, { Component } from 'react';
import DefaultLoader from '../DefaultLoader';
import { Card, IconButton, Tooltip, Grid } from '@material-ui/core';
import Firestore from '../../api/firebase/Firestore';
import moment from "moment-timezone";
import { Doughnut, Bar } from 'react-chartjs-2';
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import RefreshIcon from '@material-ui/icons/Refresh';
import SessionHelper from '../../helper/SessionHelper';
import Colors from '../../constants/Colors';

const gridSize = window.screen.width < 1080 ? 12 : 6;

export default class StudentSubscriptionDashboard extends Component {

    state = {
        loading: true,
        studentSubscriptionDocs: [],
        userDocs: [],
        studentPlanDocs: [],
        subscriptionsByStatusChart_start: moment().startOf('year').toDate(),
        subscriptionsByStatusChart_end: moment().endOf('year').toDate(),
        subscriptionsByStatusChart: [],
        subscriptionCountChart: [],
        subscriptionsByPlan_start: moment().startOf('year').toDate(),
        subscriptionsByPlan_end: moment().endOf('year').toDate(),
        subscriptionsByPlan: {
            labels: [],
            datasets: [
                {
                    label: 'Plano',
                    backgroundColor: '#36A2EB',
                    borderWidth: 1,
                    data: []
                }
            ]
        },
        newSubscriptionsChart: {
            labels: ['Hoje', 'Essa semana', 'Esse mês', 'Ano todo'],
            datasets: [
                {
                    label: 'Número de matrículas',
                    backgroundColor: '#FF6384',
                    borderWidth: 1,
                    data: [],
                }
            ]
        },
    }

    async getStudentSubscriptions() {

        let query = await Firestore.customQuery('student_subscription').where('id_company', '==', SessionHelper.getData().id_company).get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ studentSubscriptionDocs: docs });
    }

    async getStudentPlans() {

        let query = await Firestore.customQuery('student_plan').where('id_company', '==', SessionHelper.getData().id_company).get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ studentPlanDocs: docs });
    }

    async getUsers() {

        let query = await Firestore.customQuery('user').where('type', '==', 'student').where('id_companies', 'array-contains', SessionHelper.getData().id_company).get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ userDocs: docs });
    }

    async componentDidMount() {

        this.setState({ loading: true });

        await this.getStudentSubscriptions();
        await this.getUsers();
        await this.getStudentPlans();

        await this.subscriptionsByStatusChart();
        await this.newSubscriptionsChart();
        await this.subscriptionCountChart();
        await this.subscriptionsByPlan();

        this.setState({ loading: false });
    }

    async subscriptionsByStatusChart() {

        let data = {
            paid: 0,
            trialing: 0,
            canceled: 0,
            ended: 0,
            pending_payment: 0,
            unpaid: 0,
        };

        this.state.studentSubscriptionDocs.forEach((subscription, key) => {

            let date = moment(subscription.created_at.toDate());

            if (((moment(this.state.subscriptionsByStatusChart_start).isValid() && (date.isSameOrAfter(moment(this.state.subscriptionsByStatusChart_start)))) && moment(this.state.subscriptionsByStatusChart_end).isValid() && (date.isSameOrBefore(moment(this.state.subscriptionsByStatusChart_end))))) {

                if (subscription.status === 'paid') {
                    
                    data.paid++;

                } else if (subscription.status === 'trialing') {

                    data.trialing++;

                } else if (subscription.status === 'canceled') {
                    
                    data.canceled++;

                } else if (subscription.status === 'ended') {
                    
                    data.ended++;

                } else if (subscription.status === 'pending_payment') {
                    
                    data.pending_payment++;

                } else if (subscription.status === 'unpaid') {
                    
                    data.unpaid++;
                }
            }
        });

        this.setState({ subscriptionsByStatusChart: [data.paid, data.canceled, data.ended, data.pending_payment, data.unpaid] });
    }

    async newSubscriptionsChart() {

        let counts = {
            today: 0,
            week: 0,
            month: 0,
            year: 0,
        };

        this.state.studentSubscriptionDocs.forEach((subscription, key) => {

            let date = moment(subscription.created_at.toDate());
            let actualDate = moment();

            if (actualDate.isSame(date, 'day')) {
                counts.today++;
            }

            if (actualDate.isSame(date, 'week')) {
                counts.week++;
            }

            if (actualDate.isSame(date, 'month')) {
                counts.month++;
            }

            if (actualDate.isSame(date, 'year')) {
                counts.year++;
            }
        });


        let data = {
            labels: ['Hoje', 'Essa semana', 'Esse mês', 'Ano todo'],
            datasets: [
                {
                    label: 'Número de matrículas',
                    backgroundColor: '#FFCE56',
                    borderWidth: 1,
                    data: [counts.today, counts.week, counts.month, counts.year],
                }
            ]
        };

        this.setState({ newSubscriptionsChart: data });
    }

    async subscriptionCountChart() {

        let data = {
            active: 0,
            inactive: 0,
        };

        this.state.userDocs.forEach((user, key) => {

            let userSubscriptions = [];
            userSubscriptions = this.state.studentSubscriptionDocs.filter((subscription) => {

                return subscription.id_user === user.id
            });

            if (userSubscriptions.length) {

                userSubscriptions.sort((a, b) => {

                    return b.created_at.toDate() > a.created_at.toDate();
                });

                if (userSubscriptions[0].status !== 'ended' && userSubscriptions[0].status !== 'canceled') {
    
                    data.active ++;
    
                } else {
    
                    data.inactive ++;
                }

            } else {

                data.inactive ++;
            }
        });

        this.setState({ subscriptionCountChart: [data.active, data.inactive] });
    }

    async subscriptionsByPlan() {

        let data = {
            labels: [],
            datasets: [
                {
                    label: 'Número de Matrículas',
                    backgroundColor: '#36A2EB',
                    borderWidth: 1,
                    data: []
                }
            ]
        };

        let subscriptionsByPlan = {};

        this.state.studentSubscriptionDocs.forEach((subscription, key) => {

            let date = moment(subscription.created_at.toDate());
            let planDoc = this.state.studentPlanDocs.find(item => item.id === subscription.id_plan);

            if (subscriptionsByPlan[subscription.id_plan] === undefined) {

                subscriptionsByPlan[subscription.id_plan] = 0;
                data.labels.push(planDoc.name);
            }

            if ((moment(this.state.subscriptionsByPlan_start).isValid() && (date.isSameOrAfter(moment(this.state.subscriptionsByPlan_start)))) && moment(this.state.subscriptionsByPlan_end).isValid() && (date.isSameOrBefore(moment(this.state.subscriptionsByPlan_end)))) {

                if (subscription.id_plan === planDoc.id) {
                    subscriptionsByPlan[subscription.id_plan]++;
                }
            }
        });

        Object.keys(subscriptionsByPlan).forEach((value, key) => {
            data.datasets[0].data.push(subscriptionsByPlan[value]);
        });

        this.setState({ subscriptionsByPlan: data });
    }

    render() {

        return this.state.loading ? <DefaultLoader /> : (

            <div style={{ width: '100%' }}>
                <Grid container spacing={1}>
                    <Grid item xs={gridSize}>
                        <Card style={{ padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'auto', boxShadow: Colors.boxShadow }} className={'mobile-dashboard'}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', marginBottom: 20, marginTop: 10 }}>
                                <div style={{ fontSize: 20 }}>{'Matrículas Ativas'}</div>
                                <Tooltip onClick={async () => { await this.getUsers(); await this.getStudentPlans(); await this.getStudentSubscriptions(); await this.subscriptionsByStatusChart(); }} title="Atualizar"><IconButton><RefreshIcon style={{}} /></IconButton></Tooltip>
                            </div>
                            <Doughnut
                                height={window.screen.width < 1080 ? 250 : 80}
                                options={{ maintainAspectRatio: true }}
                                data={{
                                    labels: [
                                        'Pago',
                                        'Cancelado',
                                        'Encerrado',
                                        'Aguardando Pagamento',
                                        'Inadimplente',
                                    ],
                                    datasets: [{
                                        data: this.state.subscriptionsByStatusChart,
                                        backgroundColor: [
                                            Colors.success,
                                            Colors.danger,
                                            '#7d7d7d',
                                            '#FFCE56',
                                            Colors.error,
                                        ],

                                    }]
                                }}
                            />

                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', alignSelf: 'flex-end', marginTop: 20 }}>
                                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        style={{ width: '100%', padding: 15 }}
                                        invalidDateMessage={false}
                                        format={'dd/MM/yyyy'}
                                        autoOk={true}
                                        cancelLabel={'Cancelar'}
                                        okLabel={'Confirmar'}
                                        onChange={async (v) => { await this.setState({ subscriptionsByStatusChart_start: v }); this.subscriptionsByStatusChart(); }}
                                        value={this.state.subscriptionsByStatusChart_start}
                                    />
                                </MuiPickersUtilsProvider>
                                <div style={{ color: 'grey' }}>{'até'}</div>
                                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        style={{ width: '100%', padding: 15 }}
                                        invalidDateMessage={false}
                                        format={'dd/MM/yyyy'}
                                        autoOk={true}
                                        cancelLabel={'Cancelar'}
                                        okLabel={'Confirmar'}
                                        onChange={async (v) => { await this.setState({ subscriptionsByStatusChart_end: v }); this.subscriptionsByStatusChart(); }}
                                        value={this.state.subscriptionsByStatusChart_end}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={gridSize}>
                        <Card style={{ padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', boxShadow: Colors.boxShadow }} className={'mobile-dashboard'}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', marginBottom: 20, marginTop: 10 }}>
                                <div style={{ fontSize: 20 }}>{'Alunos Matrículados'}</div>
                                <Tooltip onClick={async () => { await this.getStudentSubscriptions(); await this.subscriptionCountChart(); }} title="Atualizar"><IconButton><RefreshIcon style={{}} /></IconButton></Tooltip>
                            </div>

                            <Doughnut
                                height={window.screen.width < 1080 ? 250 : 80}
                                options={{ maintainAspectRatio: true }}
                                data={{
                                    labels: [
                                        'Matrículados',
                                        'Sem Matrícula'
                                    ],
                                    datasets: [{
                                        data: this.state.subscriptionCountChart,
                                        backgroundColor: [
                                            Colors.success,
                                            'lightgrey',
                                        ],

                                    }]
                                }}
                            />

                        </Card>
                    </Grid>
                    <Grid item xs={gridSize}>
                        <Card style={{ padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', boxShadow: Colors.boxShadow }} className={'mobile-dashboard'}>

                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ fontSize: 20 }}>{'Novas Matrículas'}</div>
                                <Tooltip onClick={async () => { await this.getStudentSubscriptions(); await this.newSubscriptionsChart(); }} title="Atualizar"><IconButton><RefreshIcon style={{}} /></IconButton></Tooltip>
                            </div>

                            <Bar
                                height={window.screen.width < 1080 ? 250 : 125}
                                width={500}
                                options={{ maintainAspectRatio: true, scales: { yAxes: [{ ticks: { beginAtZero: true, precision: 0 } }] } }}
                                data={this.state.newSubscriptionsChart} />

                        </Card>
                    </Grid>
                    <Grid item xs={gridSize}>
                        <Card style={{ padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'auto', boxShadow: Colors.boxShadow }} className={'mobile-dashboard'}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', marginBottom: 20, marginTop: 10 }}>
                                <div style={{ fontSize: 20 }}>{'Matrículas por Plano'}</div>
                                <Tooltip onClick={async () => { await this.getStudentSubscriptions(); await this.getStudentPlans(); await this.getUsers(); await this.subscriptionsByPlan(); }} title="Atualizar"><IconButton><RefreshIcon style={{}} /></IconButton></Tooltip>
                            </div>

                            <Bar
                                height={window.screen.width < 1080 ? 250 : 80}
                                options={{ maintainAspectRatio: true, scales: { yAxes: [{ ticks: { beginAtZero: true, precision: 0 } }] } }}
                                data={this.state.subscriptionsByPlan} />

                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', alignSelf: 'flex-end', marginTop: 20 }}>
                                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        style={{ width: '100%', padding: 15 }}
                                        invalidDateMessage={false}
                                        format={'dd/MM/yyyy'}
                                        autoOk={true}
                                        cancelLabel={'Cancelar'}
                                        okLabel={'Confirmar'}
                                        onChange={async (v) => { await this.setState({ subscriptionsByPlan_start: v }); this.subscriptionsByPlan(); }}
                                        value={this.state.subscriptionsByPlan_start}
                                    />
                                </MuiPickersUtilsProvider>
                                <div style={{ color: 'grey' }}>{'até'}</div>
                                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        style={{ width: '100%', padding: 15 }}
                                        invalidDateMessage={false}
                                        format={'dd/MM/yyyy'}
                                        autoOk={true}
                                        cancelLabel={'Cancelar'}
                                        okLabel={'Confirmar'}
                                        onChange={async (v) => { await this.setState({ subscriptionsByPlan_end: v }); this.subscriptionsByPlan(); }}
                                        value={this.state.subscriptionsByPlan_end}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        )
    }
}