import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import DefaultTable from '../components/DefaultTable';
import DefaultInput from '../components/DefaultInput';
import DefaultModal from '../components/DefaultModal';
import Firestore from '../api/firebase/Firestore';
import { Card, CardContent, Tooltip, Switch, FormLabel, RadioGroup, FormControlLabel, Radio, Button } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CancelIcon from '@material-ui/icons/Cancel';
import moment from "moment-timezone";
import Colors from '../constants/Colors';
import CurrencyHelper from '../helper/CurrencyHelper';
import CheckIcon from '@material-ui/icons/Check';
import DescriptionIcon from '@material-ui/icons/Description';
import SessionHelper from '../helper/SessionHelper';
import Pagarme from '../api/pagarme/Pagarme';
import { toast } from 'react-toastify';
import InputMask from "react-input-mask";
import PagarmeConstants from '../constants/PagarMe';
import TermHelper from '../helper/TermHelper';
import HeaderActionsWrapper from '../components/HeaderActionsWrapper';

export default class HomePage extends Component {

    state = {
        loading: false,
        docs: [],
        transactions: [],
        planSwitchModal: false,
        planCancelModal: false,
        paymentMethodChangeModal: false,
        paymentMethod: 'credit_card',
        plan: '',
        planDoc: null,
        inputError: false,
        companyLabel: TermHelper.getestablishmentTypeLabel(SessionHelper.getData().company.establishment_type),
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('plan').where('active', '==', true).get();
        let docs = [];
        let ids = [];

        query.forEach((doc, key) => {
            
            let data = { ...doc.data(), id: doc.id };

            if (!data.id_company || (data.id_company && data.id_company === SessionHelper.getData().id_company)) {
                
                docs.push(data);
                ids.push(data.id);
            }
        });

        if (SessionHelper.getData().company.id_plan) {

            let companyPlan = await Firestore.getDoc('plan', SessionHelper.getData().company.id_plan);

            if (companyPlan.exists) {
    
                let companyPlanData = { ...companyPlan.data(), id: companyPlan.id };
    
                if (!ids.includes(companyPlan.id)) {
    
                    docs.push(companyPlanData);
                }
            }
        }

        docs.sort((a, b) => {

            return a.order > b.order;
        });

        this.setState({ docs: docs, loading: false });
    }

    async getTransactions() {

        if (SessionHelper.getData().company.id_subscription_pagarme) {

            this.setState({ loading: true });

            let response = await Pagarme.get(`/subscriptions/${SessionHelper.getData().company.id_subscription_pagarme}/transactions`, {});
    
            this.setState({ transactions: response, loading: false });
        }
    }

    componentDidMount() {

        this.getDocs();
        this.getTransactions();
    }

    async cancelPlan() {

        try {

            this.setState({ loadingModal: true });

            let actualSubscription = SessionHelper.getData().company.id_subscription_pagarme;

            if (actualSubscription) {
                
                let response = await Pagarme.post(`/subscriptions/${actualSubscription}/cancel`, {});

                if (response.errors && response.errors.length) {
                    throw new Error('Pagarme Error');
                }

                await Firestore.update({ id_subscription_pagarme: null, id_plan: null, active: false, status: 'canceled' }, 'company', SessionHelper.getData().id_company);
            }

            this.setState({ loadingModal: false, planCancelModal: false, plan: null });
            toast.success('Seu plano foi cancelado com sucesso');

        } catch (error) {

            this.setState({ loadingModal: false, planCancelModal: false, plan: null });
            toast.error('Houve um problema ao cancelar o seu plano');
        }
    }

    async switchPlan() {
        
        try {

            this.setState({ loadingModal: true });

            let actualSubscription = SessionHelper.getData().company.id_subscription_pagarme;

            if (actualSubscription) {
                
                let subscriptionObject = await Pagarme.get(`/subscriptions/${actualSubscription}`);

                if (subscriptionObject.errors && subscriptionObject.errors.length) {
        
                    throw new Error('Pagarme Error');
                }

                let data = {
                    plan_id: this.state.plan,
                    card_number: this.state.card_number,
                    card_expiration_date: this.state.card_expiration_date,
                    card_cvv: this.state.card_cvv,
                    card_holder_name: this.state.name,
                };

                if (subscriptionObject.payment_method !== this.state.paymentMethod) {
                    data.payment_method = this.state.paymentMethod;

                    if (this.state.paymentMethod === 'boleto') {

                        data.boleto_fine = PagarmeConstants.getKeys().default_boleto_fine;
                        data.boleto_interest = PagarmeConstants.getKeys().default_boleto_interest;
                    }
                }
        
                let response = await Pagarme.put(`/subscriptions/${actualSubscription}`, data);
        
                if (response.errors && response.errors.length) {
        
                    throw new Error('Pagarme Error');
                }
        
                if (response.object === 'subscription') {
    
                    let update = { id_plan: this.state.planDoc, active: true };
                    await Firestore.update(update, 'company', SessionHelper.getData().id_company);
                }
    
                this.setState({ loadingModal: false, planSwitchModal: false, plan: null });
                toast.success('Plano alterado com sucesso');

                window.location.reload();

            } else {

                let usersQuery = await Firestore.customQuery('user').where('type', '==', 'student').where('id_companies', 'array-contains', SessionHelper.getData().id_company).get();

                let planData = this.state.docs.find(item => item.id === this.state.planDoc);
                let maxUser = planData.max_user;

                if (usersQuery.size > maxUser) {

                    toast.warn(`Você possui ${usersQuery.size} ${this.state.companyLabel.plural.toLowerCase()} e o plano selecionado comporta ${maxUser}, selecione um plano maior.`, { autoClose: false })
                    this.setState({ loadingModal: false });

                } else {

                    let subscription = {
                        plan_id: this.state.plan,
                        payment_method: this.state.paymentMethod,
                        customer: {
                            email: SessionHelper.getData().company.email,
                            name: SessionHelper.getData().company.name,
                            document_number: SessionHelper.getData().company.type === 'pj' ? SessionHelper.getData().company.cnpj.replace(/[^\d]/g, '') : SessionHelper.getData().company.cpf.replace(/[^\d]/g, ''),
                            address: {
                                street: SessionHelper.getData().company.street,
                                street_number: SessionHelper.getData().company.number.replace(/[^\d]/g, ''),
                                complementary: SessionHelper.getData().company.complemento,
                                neighborhood: SessionHelper.getData().company.neighborhood,
                                zipcode: SessionHelper.getData().company.zipcode.replace(/[^\d]/g, ''),
                            },
                            phone: {
                                ddd: SessionHelper.getData().company.ddd.replace(/[^\d]/g, ''),
                                number: SessionHelper.getData().company.phone.replace(/[^\d]/g, ''),
                            },
                        },
                        postback_url: PagarmeConstants.getKeys().company_postback,
                        metadata: {
                            id_plan: this.state.planDoc,
                            id_company: SessionHelper.getData().id_company,
                        }
                    };
        
                    if (this.state.paymentMethod === 'credit_card') {
        
                        subscription.card_number = this.state.card_number.replace(/[^\d]/g, '');
                        subscription.card_cvv = this.state.card_cvv.replace(/[^\d]/g, '');
                        subscription.card_holder_name = this.state.name;
                        subscription.card_expiration_date = this.state.card_expiration_date.replace(/[^\d]/g, '');
                        subscription.soft_descriptor = 'Ace';
    
                    } else if (this.state.paymentMethod === 'boleto') {
                    
                        subscription.boleto_fine = PagarmeConstants.getKeys().default_boleto_fine;
                        subscription.boleto_interest = PagarmeConstants.getKeys().default_boleto_interest;
                    }
    
                    let subscriptionResponse = await Pagarme.post('/subscriptions', subscription);
        
                    if (subscriptionResponse.errors && subscriptionResponse.errors.length) {
        
                        throw new Error('Subscription Error');
                    }
    
                    if (subscriptionResponse.object === 'subscription') {
        
                        let update = { id_plan: this.state.planDoc, id_subscription_pagarme: subscriptionResponse.id, trial: false, trial_expiration_date: null, active: true };
                        await Firestore.update(update, 'company', SessionHelper.getData().id_company);
                    }
        
                    this.setState({ loadingModal: false, planSwitchModal: false, plan: null });
                    toast.success('Plano contratado com sucesso');
    
                    window.location.reload();
                }
            }

        } catch (error) {
            console.log(error);
            this.setState({ loadingModal: false });
            toast.error('Houve um problema ao alterar o seu plano');
        }
    }

    async updatePaymentMethod() {

        if (this.state.paymentMethod === 'credit_card' && (!this.state.card_number || !this.state.card_expiration_date || !this.state.card_cvv || !this.state.name)) {

            toast.warn('Preencha todos os campos');

        } else {

            try {

                this.setState({ loadingModal: true });
    
                let actualSubscription = SessionHelper.getData().company.id_subscription_pagarme;
    
                if (actualSubscription) {
                    
                    let subscriptionObject = await Pagarme.get(`/subscriptions/${actualSubscription}`);
    
                    if (subscriptionObject.errors && subscriptionObject.errors.length) {
            
                        throw new Error('Pagarme Error');
                    }
    
                    if (this.state.paymentMethod === 'credit_card' || subscriptionObject.payment_method !== this.state.paymentMethod) {

                        let data = {
                            payment_method: this.state.paymentMethod
                        };
    
                        if (this.state.paymentMethod === 'credit_card') {
                            
                            data.card_number = this.state.card_number;
                            data.card_expiration_date = this.state.card_expiration_date;
                            data.card_cvv = this.state.card_cvv;
                            data.card_holder_name = this.state.name;

                        } else if (this.state.paymentMethod === 'boleto') {
                
                            data.boleto_fine = PagarmeConstants.getKeys().default_boleto_fine;
                            data.boleto_interest = PagarmeConstants.getKeys().default_boleto_interest;
                        }
                
                        let response = await Pagarme.put(`/subscriptions/${actualSubscription}`, data);
                
                        if (response.errors && response.errors.length) {
                
                            throw new Error('Pagarme Error');
                        }
            
                        this.setState({ loadingModal: false, paymentMethodChangeModal: false });
                        toast.success('Método de pagamento alterado com sucesso');

                    } else {

                        this.setState({ loadingModal: false });
                        toast.warn('Selecione um método de pagamento diferente do atual');
                    }
                }
    
            } catch (error) {
    
                this.setState({ loadingModal: false, paymentMethodChangeModal: false });
                toast.error('Houve um problema ao alterar o método de pagamento');
            }
        }
    }

    planCancelModal() {
        return (
            <div>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.cancelPlan() }} style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ planCancelModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    planSwitchModal() {
        return (
            <div>
                <div style={{fontWeight: 'bold', display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'orange', paddingBottom: 15}}><InfoIcon style={{marginRight: 10}}/>{'Caso você já tenha uma assinatura ativa, a assinatura atual será cancelada, e substituída com o novo plano'}</div>
                
                <div style={{paddingTop: 8}}>
                    <FormLabel component="legend">Método de Pagamento</FormLabel>
                    <RadioGroup style={{display: 'flex', flexDirection: 'row'}} aria-label="sex" name="sex"  value={this.state.paymentMethod} onChange={(evt) => { this.setState({ paymentMethod: evt.target.value }) }}>
                        <FormControlLabel value="credit_card" control={<Radio style={{color: Colors.primary}}/>} label="Cartão de Crédito" />
                        {/* <FormControlLabel value="boleto" control={<Radio style={{color: Colors.primary}}/>} label="Boleto" /> */}
                    </RadioGroup>
                </div>

                { this.state.paymentMethod === 'credit_card' ?
             
                    <div>
                        <InputMask onBlur={(v) => { this.setState({ card_number: v.replace(/[^\d]/g, '') }) }} mask="9999 9999 9999 9999">
                            <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} hasMask={true} number={true} minLength={16} label={'Número do Cartão'} />
                        </InputMask>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <InputMask onBlur={(v) => { this.setState({ card_expiration_date: v.replace(/[^\d]/g, '') }) }} mask="99/99">
                                <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} hasMask={true} number={true} minLength={4} label={'Data de Vencimento'} />
                            </InputMask>
                            <div style={{marginLeft: 5, marginRight: 5}}/>
                            <InputMask onBlur={(v) => { this.setState({ card_cvv: v.replace(/[^\d]/g, '') }) }} maskPlaceholder={''}  number={true} mask="9999">
                                <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} number={true} label={'Código Verificador'} />
                            </InputMask>
                        </div>
                        <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Nome no Cartão'} onBlur={(v) => { this.setState({ name: v }) }}/>
                    </div>

                : null }

                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.switchPlan() }} style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ planSwitchModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    paymentMethodChangeModal() {
        return (
            <div>
                <div style={{paddingTop: 8}}>
                    <FormLabel component="legend">Método de Pagamento</FormLabel>
                    <RadioGroup style={{display: 'flex', flexDirection: 'row'}} aria-label="sex" name="sex"  value={this.state.paymentMethod} onChange={(evt) => { this.setState({ paymentMethod: evt.target.value }) }}>
                        <FormControlLabel value="credit_card" control={<Radio style={{color: Colors.primary}}/>} label="Cartão de Crédito" />
                        {/* <FormControlLabel value="boleto" control={<Radio style={{color: Colors.primary}}/>} label="Boleto" /> */}
                    </RadioGroup>
                </div>

                { this.state.paymentMethod === 'credit_card' ?
             
                    <div>
                        <InputMask onBlur={(v) => { this.setState({ card_number: v.replace(/[^\d]/g, '') }) }} mask="9999 9999 9999 9999">
                            <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} hasMask={true} number={true} minLength={16} label={'Número do Cartão'} />
                        </InputMask>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <InputMask onBlur={(v) => { this.setState({ card_expiration_date: v.replace(/[^\d]/g, '') }) }} mask="99/99">
                                <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} hasMask={true} number={true} minLength={4} label={'Data de Vencimento'} />
                            </InputMask>
                            <div style={{marginLeft: 5, marginRight: 5}}/>
                            <InputMask onBlur={(v) => { this.setState({ card_cvv: v.replace(/[^\d]/g, '') }) }} maskPlaceholder={''}  number={true} mask="9999">
                                <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} number={true} label={'Código Verificador'} />
                            </InputMask>
                        </div>
                        <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Nome no Cartão'} onBlur={(v) => { this.setState({ name: v }) }}/>
                    </div>

                : null }

                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.updatePaymentMethod() }} style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ paymentMethodChangeModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    renderBalance() {

        return (
            <Card variant="outlined" style={{ width: '100%', height: 'auto', margin: 5, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 10 }}>
                <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                    <HeaderActionsWrapper style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: 'bold', fontSize: 18 }}>Planos Disponíveis</div>
                        <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                            <DefaultButton disabled={!SessionHelper.getData().company.id_subscription_pagarme} leftIcon={<CreditCardIcon style={{ marginRight: 10 }}/>} onClick={() => { this.setState({ paymentMethodChangeModal: true }) }} title={'Alterar Método de Pagamento'}/>
                            <div style={{ marginLeft: 5 }}/>
                            <DefaultButton disabled={!SessionHelper.getData().company.id_subscription_pagarme} leftIcon={<CancelIcon style={{ marginRight: 10 }}/>} onClick={() => { this.setState({ planCancelModal: true }) }} title={'Cancelar Assinatura'}/>
                        </div>
                    </HeaderActionsWrapper>
                    <div style={{ paddingTop: 15, display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', overflowX: 'auto' }}>
                        
                        {
                        
                        this.state.docs.map((plan, key) => {

                            return (
                                <div style={{ width: 275, height: 375, border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: 10, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', display: 'flex', flexDirection: 'column', marginRight: 15 }}>
                                    <div style={{ height: 50, borderTopLeftRadius: 10, borderTopRightRadius: 10, backgroundColor: Colors.primary, color: '#FFFFFF', padding: 15, textAlign: 'center', fontWeight: 'bold', fontSize: 16 }}>{plan.name}</div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingTop: 15 }}>
                                        <div style={{ fontSize: 32, fontWeight: 'bold', paddingRight: 10 }}>R$</div>
                                        <div style={{ fontWeight: 'lighter', fontSize: 40 }}>{CurrencyHelper.centsToMoney(plan.price, false)}</div>
                                    </div>
                                    <div style={{ textAlign: 'center', fontSize: 11, color: 'grey' }}>{'*cobrados mensalmente, cancele quando quiser.'}</div>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', paddingTop: 15 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 15, paddingRight: 15, paddingTop: 5, paddingBottom: 5 }}>
                                            <CheckIcon style={{ color: 'green', marginRight: 5 }}/>
                                            <div>{`De ${plan.min_user <= 9 ? `0${plan.min_user}` : plan.min_user} até ${plan.max_user <= 9 ? `0${plan.max_user}` : plan.max_user} ${this.state.companyLabel.plural.toLowerCase()}`}</div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 15, paddingRight: 15, paddingTop: 5, paddingBottom: 5 }}>
                                            <CheckIcon style={{ color: 'green', marginRight: 5 }}/>
                                            <div>{`Gestão completa da sua escola`}</div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 15, paddingRight: 15, paddingTop: 5, paddingBottom: 5 }}>
                                            <CheckIcon style={{ color: 'green', marginRight: 5 }}/>
                                            <div>{`Logins simultâneos ilimitados`}</div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 15, paddingRight: 15, paddingTop: 5, paddingBottom: 5 }}>
                                            <CheckIcon style={{ color: 'green', marginRight: 5 }}/>
                                            <div>{`Suporte 100% humanizado`}</div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 15, paddingRight: 15, paddingTop: 5, paddingBottom: 15 }}>
                                            <CheckIcon style={{ color: 'green', marginRight: 5 }}/>
                                            <div>{`App do ${this.state.companyLabel.singular.toLowerCase()}`}</div>
                                        </div>
                                    </div>
                                    <div style={{ borderTop: '1px solid lightgrey', display: 'flex', justifyContent: 'center' }}>
                                        <Button onClick={() => { this.setState({ planSwitchModal: true, plan: plan.id_plan_pagarme, planDoc: plan.id }) }} disabled={SessionHelper.getData().company.id_plan === plan.id ? true : false} style={{ fontWeight: 'bold', width: 275}}>{ SessionHelper.getData().company.id_plan === plan.id ? 'PLANO ATUAL' : 'CONTRATAR' }</Button>
                                    </div>
                                </div>
                            )
                        })

                        }

                    </div>
                </CardContent>
            </Card>
        )
    }

    renderStatus(status) {

        let text = '';
        let color = '';

        if (status === 'pending_transfer') {
            text = 'Transferência pendente';
            color = 'orange';
        } else if (status === 'transferred') {
            text = 'Transferido com sucesso';
            color = 'green';
        } else if (status === 'failed') {
            text = 'Transferência falhou';
            color = 'red';
        } else if (status === 'processing') {
            text = 'Processando';
            color = 'orange';
        } else if (status === 'canceled') {
            text = 'Cancelado';
            color = 'grey'
        } else if (status === 'paid') {
            color = 'green';
            text = 'Pago';
        } else if (status === 'waiting_payment') {
            color = 'orange';
            text = 'Aguardando Pagamento';
        }

        return <div style={{color: color, fontWeight: 'bold'}}>{text}</div>
    }

    renderTransactions() {

        return (

            <Card variant="outlined" style={{ width: '100%', height: 725, margin: 5, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 10 }}>
                <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: 'bold', fontSize: 18 }}>Faturamento</div>
                        <Tooltip title={'Essas são as suas últimas transações realizadas'}><InfoIcon style={{ fontSize: 22 }}/></Tooltip>
                    </div>
                    <div style={{ paddingTop: 15 }}>
                        <DefaultTable
                        title={''}
                        absoluteHeight={500}
                        filtering={true}
                        actions={[
                            (rowData) => rowData.payment_method === 'boleto' ? {
                                icon: DescriptionIcon,
                                tooltip: 'Visualizar Boleto',
                                onClick: (event, rowData) => { window.open(rowData.boleto_url, '_blank') } 
                            } : null,
                        ]}
                        columns={[
                            { title: 'Identificador', field: 'id' },
                            { title: 'Método de Pagamento', field: 'payment_method', render: rowData => <div>{rowData.payment_method === 'boleto' ? 'Boleto' : 'Cartão de Crédito'}</div> },
                            { title: 'Valor', field: 'amount', type: 'currency', render: rowData => <div>{CurrencyHelper.centsToMoney(rowData.amount, true)}</div> },
                            { title: 'Valor Pago', field: 'paid_amount', type: 'currency', render: rowData => <div>{rowData.paid_amount > 0 ? CurrencyHelper.centsToMoney(rowData.paid_amount, true) : ''}</div> },
                            { title: 'Status', field: 'status', render: rowData => this.renderStatus(rowData.status) },
                            { title: 'Data de Vencimento', field: 'date_created', render: rowData => rowData.boleto_expiration_date ? moment(rowData.boleto_expiration_date).format('DD/MM/YYYY') : '' },
                            { title: 'Data de Criação', field: 'date_created', render: rowData => moment(rowData.date_created).format('DD/MM/YYYY') },
                        ]}
                        data={this.state.transactions}
                        />
                    </div>
                </CardContent>
            </Card>
        )
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={{ padding: 20, display: 'flex', flexDirection: 'column' }}>
                    
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    
                    { this.renderBalance() }

                </div>

                <div style={{ display: 'flex', flexDirection: 'row' }}>

                    { this.renderTransactions() }

                </div>
                <DefaultModal loading={this.state.loadingModal} content={this.planSwitchModal()} title={'Preencha as informações de pagamento'} onClose={() => { this.setState({ planSwitchModal: false }) }} open={this.state.planSwitchModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.paymentMethodChangeModal()} title={'Alterar Método de Pagamento'} onClose={() => { this.setState({ paymentMethodChangeModal: false }) }} open={this.state.paymentMethodChangeModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.planCancelModal()} title={'Tem certeza que deseja cancelar?'} onClose={() => { this.setState({ planCancelModal: false }) }} open={this.state.planCancelModal}/>
            </div>
        );
    }
}
