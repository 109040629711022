import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button, InputLabel, Select, MenuItem, Tooltip } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import IosSwitch from '../components/IosSwitch';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import CurrencyInput from '../components/CurrencyInput';
import moment from "moment-timezone";
import LiquidProfit from '../components/LiquidProfit';
import AddIcon from '@material-ui/icons/Add';
import InfoOutlinedIcon from '@material-ui/icons/InfoRounded';
import CurrencyHelper from '../helper/CurrencyHelper';
import HeaderActionsWrapper from '../components/HeaderActionsWrapper';

export default class PrivateLessonPacksPage extends Component {

    state = {
        docs: [],
        name: '',
        lessons: 0,
        paymentType: ['credit_card'],
        max_parcel: 12,
        price: null,
        rawPrice: null,
        loading: true,
        loadingModal: false,
        addModal: false,
        active: true,
        inputError: false,
        private_lesson_price: SessionHelper.getData().company.private_lesson_price || 0,
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('private_lesson_packs').where('id_company', '==', SessionHelper.getData().id_company).get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;
            data.price = data.price / 100;

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    componentDidMount() {

        this.getDocs();
    }

    async addPack() {

        if (this.state.name && this.state.rawPrice && this.state.paymentType.length && this.state.lessons > 0) {

            this.setState({ loadingModal: true });

            let data = {
                name: this.state.name,
                price: this.state.rawPrice * 100,
                lessons: this.state.lessons,
                payment_type: this.state.paymentType,
                max_parcel: this.state.max_parcel,
                active: this.state.active,
                id_company: SessionHelper.getData().id_company,
                date: new Date(),
            };

            try {

                await Firestore.insert(data, 'private_lesson_packs');

                toast.success("Cadastrado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.setState({ loadingModal: false, addModal: false, name: '', price: null, rawPrice: null, lessons: 0, paymentType: ['credit_card'], max_parcel: 12 });
                await this.getDocs();

            } catch (error) {

                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn("Preencha os campos obrigatórios", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    renderNormalPrice() {

        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'orange' }}>
                <div>Valor total se comprado separadamente: <strong>{CurrencyHelper.centsToMoney((this.state.private_lesson_price * this.state.lessons))}</strong></div>
                <Tooltip title={`Hoje você cobra ${CurrencyHelper.centsToMoney(this.state.private_lesson_price)} por aula avulsa`}>
                    <InfoOutlinedIcon onClick={() => { window.open('https://easydata.net.br', '_blank') }} style={{ fontSize: 17, marginLeft: 5, cursor: 'pointer' }}/>
                </Tooltip>
            </div>
        )
    }

    addModal() {
        return (
            <div>
                <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ name: v }) }} label={'Nome'}/>
                <DefaultInput required number onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ lessons: v }) }} label={'Número de Aulas'}/>
                <CurrencyInput required onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Valor'} onChange={(values) => { const {formattedValue, value} = values; this.setState({price: formattedValue, rawPrice: value}) }} value={this.state.price}/>
                <LiquidProfit installments={this.state.max_parcel} price={this.state.rawPrice * 100}/>
                <LiquidProfit price={this.state.rawPrice * 100} type={'pix'}/>
                { this.state.private_lesson_price > 0 ? this.renderNormalPrice() : null }
                <div style={{marginBottom: 15, marginTop: 15}}>
                    <InputLabel id="type">Formas de Pagamento</InputLabel>
                    <Select
                    disabled={true}
                    style={{width: '100%'}}
                    labelId="tipo"
                    value={this.state.paymentType}
                    multiple={true}
                    onChange={(v) => { this.setState({ paymentType: v.target.value }) }}>
                            <MenuItem value={'credit_card'}>{'Cartão de Crédito'}</MenuItem>
                            <MenuItem value={'boleto'}>{'Boleto'}</MenuItem>
                    </Select>
                </div>
                { this.state.paymentType.includes('credit_card') && <DefaultInput number required value={this.state.max_parcel} onChange={(v) => { this.setState({ max_parcel: v }) }} label={'Número Máximo de Parcelas (Crédito)'}/> }
                <div style={{ paddingTop: 15 }}>
                    <IosSwitch label="Ativo" value="active" checked={this.state.active} onChange={(v) => { this.setState({ active: v }) }}/>
                </div>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.addPack() }} style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ addModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    renderPaymentMethods(data) {

        let text = '';

        data.forEach((method, key) => {

            let label = '';

            if (method === 'boleto') {
                label = 'Boleto';

            } else if (method === 'credit_card') {
                label = 'Cartão de Crédito';

            } else if (method === 'money') {
                label = 'Dinheiro';

            } else {
                label = 'Maquininha';
            }

            text += label;

            if (data[key + 1]) {
                text += ', ';
            }
        });

        return text;
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <HeaderActionsWrapper style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon/>} onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'}/>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        
                    </div>
                </HeaderActionsWrapper>  

                <DefaultTable
                title={'Pacotes de Aulas Avulsas'}
                marginTop={10}
                
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Nome', field: 'name' },
                    { title: 'Valor', field: 'price', type: 'currency', currencySetting: {locale: 'brl', currencyCode: 'brl'}, editComponent: props => ( <CurrencyInput value={props.value} onChange={(e) => { props.onChange(e.floatValue) }} /> ) },
                    { title: 'Métodos de Pagamento', field: 'payment_type', render: rowData => <div>{this.renderPaymentMethods(rowData.payment_type)}</div>, editComponent: props => <Select disabled={true} style={{width: '100%'}} labelId="tipo" value={props.value} multiple={true} onChange={(v) => { props.onChange(v.target.value) }}><MenuItem value={'credit_card'}>{'Cartão de Crédito'}</MenuItem><MenuItem value={'boleto'}>{'Boleto'}</MenuItem><MenuItem value={'money'}>{'Dinheiro'}</MenuItem><MenuItem value={'maquininha'}>{'Maquininha'}</MenuItem></Select> },
                    { title: 'Número Máximo de Parcelas', field: 'max_parcel', type: 'numeric' },
                    { title: 'Número de Aulas', field: 'lessons', type: 'numeric' },
                    { title: 'Ativo', field: 'active', type: 'boolean', render: rowData => <IosSwitch disabled={true} checked={rowData.active}/>, editComponent: props => (<IosSwitch checked={props.value} onChange={(e) => { props.onChange(e) }} /> ) },
                    { title: 'Data Criação', field: 'date', render: rowData => <div>{moment(rowData.date.toDate()).format('DD/MM/YYYY HH:mm')}</div>, editable: false },
                ]}
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                    newData.date = oldData.date;

                    let prev = this.state.docs;
                    prev[prev.indexOf(oldData)] = newData;

                    this.setState({ docs: prev });

                    if (oldData.id) {
                        
                        let update = {
                            name: newData.name,
                            price: newData.price * 100,
                            payment_type: newData.payment_type,
                            max_parcel: newData.max_parcel,
                            lessons: newData.lessons,
                            active: newData.active,
                        };

                        await Firestore.update(update, 'private_lesson_packs', oldData.id);
                    }

                    toast.success("Editado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Pacote'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
