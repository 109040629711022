import React, { Component } from 'react';
import Colors from '../constants/Colors';
import TournamentMatch from './TournamentMatch';
import SearchBar from './SearchBar';
import moment from "moment-timezone";
import DefaultLoader from './DefaultLoader';
import Firestore from '../api/firebase/Firestore';
import DefaultTable from './DefaultTable';

export default class RankingColumnView extends Component {

    state = {
        docs: [],
        loadingPoints: true
    }

    async componentDidMount() {
        this.loadSelectedInstancePoints();
    }

    async loadSelectedInstancePoints() {
        this.setState({ loadingRounds: true });

        let indexes = this.props.indexes;
        let id_ranking = this.props.ranking.id;

        let query = await Firestore.customQuery('ranking_points').where('id_ranking', '==', id_ranking)
            .where('indexes.category', '==', indexes.category).where('indexes.class', '==', indexes.class).orderBy('totalizer', 'desc').get();

        let docs = [];

        query.docs.forEach((doc, index) => {
            let names = [];
            let photos = [];

            doc.data().players.map(player => {
                let user = this.props.users.find(item => item.id === player);

                if (user) {
                    names.push(user.name || '');
                    photos.push(user.photo || null);
                }
            });

            docs.push({ ...doc.data(), id: doc.id, position: index + 1, names, photos });
        });

        this.setState({ docs, loadingPoints: false });
    }

    renderParticipantsInTable(rowData) {
        return (
            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                {rowData.photos && rowData.photos.length ?
                    rowData.photos.map((photo, key) => (
                        <img
                            style={{
                                height: 32,
                                width: 32,
                                borderRadius: 32 / 2,
                                boxShadow: Colors.boxShadow,
                                marginLeft: key > 0 ? -10 : 0
                            }}
                            src={photo ? photo : process.env.PUBLIC_URL + '/empty_avatar.png'}
                        />
                    ))
                    : null}
                {rowData.names && rowData.names.length ?
                    <div style={{ marginLeft: 15 }}>{rowData.names.join(`,`)}</div>
                    : null}
            </div>
        );
    }

    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                {this.state.loadingPoints ?
                    <DefaultLoader /> :
                    <DefaultTable
                        title={<p style={{ color: Colors.disabled, fontSize: 10 }}>*A ordenação é dada por: ((Vitórias - W.O's ganhos) * Peso Vitória) + (Tiebreaks) + (Games) +
                            ((Derrotas - W.O's perdidos) * Peso Derrota) + (W.O's ganhos * Peso W.O. ganho) + (W.O's perdidos * Peso W.O. perdido)</p>}
                        marginTop={1}
                        filtering={true}
                        pageSize={this.state.docs.length}
                        columns={[
                            { title: 'key', field: 'key', hidden: true },
                            { title: 'Participante', field: 'names', render: rowData => this.renderParticipantsInTable(rowData), editable: false },
                            { title: 'Posição', field: 'position', editable: false, type: 'numeric', render: rowData => (<div style={{ fontWeight: rowData.position < 4 ? 'bold' : 'normal' }}>{rowData.position}ª</div>) },
                            { title: 'Pontos', field: 'score', editable: false, type: 'numeric' },
                            { title: 'Jogos', field: 'matches', editable: false, type: 'numeric' },
                            { title: 'Vitórias', field: 'wins', editable: false, type: 'numeric' },
                            { title: 'Derrotas', field: 'defeats', editable: false, type: 'numeric' },
                            { title: 'Sets', field: 'sets', editable: false, type: 'numeric' },
                            { title: 'Games', field: 'games', editable: false, type: 'numeric' },
                            { title: 'Tiebreaks', field: 'tiebreaks', editable: false, type: 'numeric' },
                            { title: `W.O's`, field: 'wos', editable: false, type: 'numeric' },
                        ]}
                        data={this.state.docs}
                    />
                }
            </div>
        )
    }
}