import React, { Component } from 'react';
import IosSwitch from '../IosSwitch';
import DefaultButton from '../DefaultButton';
import DefaultLoader from '../DefaultLoader';
import SessionHelper from '../../helper/SessionHelper';
import ReportHelper from '../../helper/ReportHelper';
import moment from "moment-timezone";
import Firestore from '../../api/firebase/Firestore';
import { toast } from 'react-toastify';
import ReportDefaultModalOptions from './ReportDefaultModalOptions';

export default class InventoryMovementReport extends Component {

    state = {
        loadingModal: true,
        productDocs: [],
        adminDocs: [],
        products: [],
        admins: [],
        allProducts: true,
        allAdmins: true,
        entry: true,
        exit: true,
        start: moment().startOf('month').startOf('day').toDate(),
        end: moment().endOf('day').toDate(),
        output: 'download',
        format: 'pdf',
    }

    async componentDidMount() {

        if (this.props.getDocs && this.props.getDocs) {

            for (let index = 0; index < this.props.getDocs.length; index++) {
                
                const getDoc = this.props.getDocs[index];
                await getDoc(this);
            }
        }

        this.setState({ loadingModal: false });
    }

    async getReportDocs() {

        let docs = [];

        let start = moment(this.state.start).startOf('day').toDate();
        let end = moment(this.state.end).endOf('day').toDate();
        let inventoryMovements = await Firestore.customQuery('inventory_movement').where('id_company', '==', SessionHelper.getData().id_company).where('date', '>=', start).where('date', '<=', end).orderBy('date', 'desc').get();

        let products = this.state.allProducts ? this.state.productDocs.map((product) => { return product.id }) : this.state.products;
        let admins = this.state.allAdmins ? this.state.adminDocs.map((admin) => { return admin.id }) : this.state.admins;

        inventoryMovements.forEach((movementDoc, key) => {

            let movement = { ...movementDoc.data(), id: movementDoc.id };

            if (products.includes(movement.id_product) && (this.state.allAdmins || admins.includes(movement.id_user)) && ((movement.type === 'entry' && this.state.entry) || (movement.type === 'exit' && this.state.exit))) {

                let product = this.state.productDocs.find(item => item.id === movement.id_product);
                let user = 'Sistema';

                if (movement.id_user) {

                    let userDoc = this.state.adminDocs.find(item => item.id === movement.id_user);

                    if (userDoc) {

                        user = userDoc.name;

                    } else {

                        user = 'Usuário Desconhecido';
                    }
                }

                docs.push({
                    ...movement,
                    user: user,
                    product: product.name,
                })
            }
        });

        return docs;
    }

    getColor(report, doc) {

        if (doc.type === 'entry') {

            report.doc.setTextColor(89, 166, 61);

        } else {
            
            report.doc.setTextColor(215, 77, 78);
        }
    }

    async print() {

        if (this.state.start && this.state.end) {

            this.setState({ loadingModal: true });

            let docs = await this.getReportDocs();
            let name = `Movimentações do Estoque - ${SessionHelper.getData().company.name}`;
            let subtitle = `De ${moment(this.state.start).format('DD/MM/YYYY')} até ${moment(this.state.end).format('DD/MM/YYYY')}`;
            let report = await ReportHelper.createReport(name, 5, subtitle, this.state.format);

            report = await ReportHelper.createColumns(report, docs, [
                { name: 'Produto', width: 13, getText: (doc) => doc.product ? this.state.format === 'pdf' ? ReportHelper.cutName(doc.product, 33) : doc.product : '' },
                { name: 'Tipo', getColor: (report, doc) => this.getColor(report, doc), width: 87, getText: (doc) => doc.type === 'entry' ? 'Entrada' : 'Saída' },
                { name: 'Movimentação', width: 102, getText: (doc) => `${doc.quantity_before} -> ${doc.quantity_available}` },
                { name: 'Usuário', width: 130, getText: (doc) => doc.user !== 'Usuário Desconhecido' ? this.state.format === 'pdf' ? ReportHelper.cutName(ReportHelper.getShortName(doc.user), 20) : doc.user : doc.user },
                { name: 'Data', width: 169, getText: (doc) => moment(doc.date.toDate()).format('DD/MM/YYYY HH:mm') },
            ]);
    
            ReportHelper.print(report, this.state.output, this.state.format);

            this.setState({ loadingModal: false });

        } else {

            toast.warn('Preencha o período do relatório');
        }
    }

    renderLoading() {

        return (

            <div style={{ height: 200 }}>
                <DefaultLoader/>
            </div>
        )
    }

    renderModal() {

        return (

            <div>
                <IosSwitch fullWidth label={'Todos os Produtos'} checked={this.state.allProducts} onChange={(e) => { this.setState({ allProducts: e, products: [] }) }} />
                { !this.state.allProducts ? ReportHelper.renderProductSelection(this, this.state.productDocs, 'products') : null }

                <IosSwitch fullWidth label={'Todos os Administradores'} checked={this.state.allAdmins} onChange={(e) => { this.setState({ allAdmins: e, admins: [] }) }} />
                { !this.state.allAdmins ? ReportHelper.renderAdminSelection(this, this.state.adminDocs, 'admins') : null }

                <IosSwitch fullWidth label={'Exibir Entradas'} checked={this.state.entry} onChange={(e) => { this.setState({ entry: e }) }} />
                <IosSwitch fullWidth label={'Exibir Saídas'} checked={this.state.exit} onChange={(e) => { this.setState({ exit: e }) }} />

                <div style={{ marginTop: 15 }}/>

                { ReportHelper.renderPeriodSelection(this, 'start', 'end') }

                <ReportDefaultModalOptions format={this.state.format} output={this.state.output} onOutputChange={(value) => { this.setState({ output: value }) }} onFormatChange={(value) => { this.setState({ format: value }) }}/>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <DefaultButton width={'100%'} title={'Imprimir'} onClick={() => { this.print() }} />
                </div>
            </div>
        )
    }

    render() {

        return (

            this.state.loadingModal ? this.renderLoading() : this.renderModal()
        )
    }
}