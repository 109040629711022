export default class LayoutHelper {
    static isSmallScreen() {
        return window.screen.width < 1360;
    }

    static calcCardSize() {
        let min = 178;

        return window.innerWidth * 0.10 < min ? min : window.innerWidth * 0.10;
    }
}