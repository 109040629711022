import React, { Component } from 'react';
import { IconButton, Badge, Menu, MenuItem, Tooltip, Divider, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import Colors from '../constants/Colors';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EditIcon from '@material-ui/icons/Edit';
import PeopleIcon from '@material-ui/icons/People';
import AddIcon from '@material-ui/icons/Add';
import BusinessIcon from '@material-ui/icons/Business';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import Firestore from '../api/firebase/Firestore';
import Storage from '../api/firebase/Storage';
import SessionHelper from '../helper/SessionHelper';
import DefaultLoader from './DefaultLoader';
import { toast } from 'react-toastify';
import firebase from 'firebase/app'
import LanguageIcon from "@material-ui/icons/Language";
import Check from '@material-ui/icons/Check';

import i18n from '../i18n';
import { useTranslation } from 'react-i18next'
import moment from "moment-timezone";

export default class LanguageSelector extends Component {
    state = {
        menuOpen: false,
        loading: false,
        mouseX: 0,
        mouseY: 0,
        currentLanguage: ``
    }

    async componentDidMount() {
        this.setState({ currentLanguage: i18n.language });
    }

    async toggleMenu() {
        let menu = !this.state.menuOpen;

        this.setState({ menuOpen: menu });
    }

    getSystemLanguages() {
        return [
            { name: '🇧🇷 Português', alias: 'pt-BR' },
            { name: '🇺🇸 English (In Development)', alias: 'en' },
            { name: '🇪🇸 Español (En Desarrollo)', alias: 'es' }
        ];
    }

    handleLanguageChange = async (selected) => {
        this.setState({ currentLanguage: selected, menuOpen: false });
        i18n.changeLanguage(selected);
        moment.locale(selected);

        window.location.reload();
    }

    renderLanguageList() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', fontSize: 12, padding: 10, minWidth: 300, width: 'auto' }}>
                <List component="nav" style={{ maxHeight: '100%', overflowY: 'auto' }}>
                    {this.getSystemLanguages().map(item =>
                        <div>
                            <ListItem disabled={!SessionHelper.getConstants().isDevelopment && item.alias !== 'pt-BR'} onClick={() => { if (SessionHelper.getConstants().isDevelopment || item.alias === 'pt-BR') this.handleLanguageChange(item.alias) }} selected={this.state.currentLanguage === item.alias} style={this.state.currentLanguage === item.alias ? { cursor: 'pointer', color: Colors.background, backgroundColor: Colors.primary } : { cursor: 'pointer', color: Colors.dark }}>
                                <ListItemText primary={item.name} />
                                {this.state.currentLanguage === item.alias ?
                                    <Check style={{ color: Colors.background }} />
                                    : null}
                            </ListItem>
                            <Divider />
                        </div>

                    )}
                </List>
            </div>
        )
    }

    renderLoading() {
        return (
            <div style={{ minWidth: 300, minHeight: 150 }}>
                <DefaultLoader size={25}
                    color={'lightgrey'} />
            </div>
        )
    }

    render() {

        return (
            <div style={{ display: 'flex' }}>
                <IconButton color="inherit" aria-controls={'primary-search-account-menu'} aria-haspopup="true" onClick={(event) => { this.setState({ mouseX: event.clientX, mouseY: event.clientY }); this.toggleMenu(); }}>
                    <LanguageIcon style={{ color: '#fff' }} />
                </IconButton>

                <Menu
                    getContentAnchorEl={null}
                    anchorOrigin={this.props.useMouseAsAnchor ? { vertical: this.state.mouseY, horizontal: this.state.mouseX } : { vertical: 'top', horizontal: 'right' }}
                    id={'primary-search-account-menu'}
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={this.state.menuOpen}
                    style={{ marginTop: 40, minHeight: 490, maxHeight: 520, height: 'auto' }}
                    onClose={() => { this.toggleMenu() }} >
                    {this.state.loading ? this.renderLoading() : this.renderLanguageList()}
                </Menu>

            </div>
        )

    }
}
