export default class LocalNotificationHelper {
    
    static async askPermission() {

        try {

            if (!window.Notification) {

                    throw new Error('Browser dont support notifications');
    
              } else {
                  
                if (Notification.permission !== 'denied') {
                  
                    return await Notification.requestPermission();
                }
            }

        } catch (error) {
            console.log(error);
        }
    }

    static send(title, body) {

        if (window.Notification) {

            if (Notification.permission === 'granted') {

                const notification = new Notification(title, { body: body, icon: 'https://firebasestorage.googleapis.com/v0/b/bg-tennis.appspot.com/o/icon.png?alt=media&token=36132c0c-2eca-46ed-b87f-988cc257659c' });
              
                notification.onclick = (e) => {
                    e.preventDefault();
                    window.focus();
                    notification.close();
                }
            }
        }
    }
}