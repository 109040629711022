import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import { Button, Tooltip, IconButton } from '@material-ui/core';
import Colors from '../constants/Colors';
import Firestore from '../api/firebase/Firestore';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import moment from "moment-timezone";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import HistoryIcon from '@material-ui/icons/History';
import DefaultModal from '../components/DefaultModal';
import { toast } from 'react-toastify';
import NotificationHelper from '../helper/NotificationHelper';
import RelationField from '../components/grid/RelationField';

export default class ActivePacksPage extends Component {

    state = {
        docs: [],
        packDocs: [],
        historyEvents: [],
        historyType: '',
        loading: true,
        loadingModal: false,
        historyModal: false,
        remainingCreditsModal: false,
        remainingCredits: null,
        pack: null,
        editId: null,
    }

    async getDocs() {

        let query = await Firestore.customQuery('user_pack').where('id_company', '==', SessionHelper.getData().id_company).orderBy('active', 'desc').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs });
    }

    async componentDidMount() {

        await this.getDocs();

        this.setState({ loading: false });
    }

    async getPackHistory(id, type) {

        try {

            await this.setState({ historyModal: true, loadingModal: true, historyEvents: [], historyType: '' });

            const collection = type === 'private_lesson' ? 'private_lesson' : 'court_rent';
            
            let query = await Firestore.customQuery(collection).where('id_package', '==', id).orderBy('start', 'desc').get();
            let events = [];

            for (let doc of query.docs) {
                
                let data = { ...doc.data(), id: doc.id };

                if (data.friends) {

                    let friendsQuery = await Firestore.customQuery('user').where(Firestore.idField(), 'in', data.friends).get();

                    if (friendsQuery.size > 0) {

                        data.friends = [];

                        friendsQuery.forEach((friend, key) => {

                            data.friends.push(friend.data().name);
                        });
                    }
                }

                events.push(data);
            }

            this.setState({ historyEvents: events, historyType: collection, loadingModal: false });
            
        } catch (error) {

            toast.error('Houve um problema ao buscar os eventos');
            this.setState({ loadingModal: false, historyEvents: [], historyType: '' });
        }
    }

    renderPackType(type) {

        let label = '';
        let color = '';
        let icon = '';

        if (type === 'private_lesson') {
            label = 'Aulas Avulsas';
            color = '#f04141';
            icon = <SportsTennisIcon/>;

        } else if (type === 'court_rent') {
            label = 'Aluguéis de Quadra';
            color = '#3b9c3e';
            icon = <AttachMoneyIcon/>;
        }

        return (
            <div style={{ color: color, fontWeight: 'bold', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {icon}
                <div style={{ marginLeft: 5 }}>{label}</div>
            </div>
        );
    }

    renderPackActive(active) {

        let label = '';
        let color = '';
        let icon = '';

        if (active) {
            label = 'Ativo';
            color = Colors.success;
            icon = <CheckIcon/>;

        } else {
            label = 'Inativo';
            color = Colors.error;
            icon = <HighlightOffIcon/>;
        }

        return (
            <div style={{ backgroundColor: color, color: 'white', fontWeight: 'bold', display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center', padding: 3, borderRadius: 5 }}>
                {icon}
                <div style={{ marginLeft: 5 }}>{label}</div>
            </div>
        );
    }

    renderRemaining(data) {

        if (data.type === 'private_lesson') {

            return <div>{`${data.remaining_lessons} de ${data.total_lessons}`}</div>

        } else {

            return <div>{`${data.remaining_rents} de ${data.total_rents}`}</div>
        }
    }

    historyModal() {

        if (this.state.historyModal) {
            return (
                <div>
                    { 
                        this.state.historyEvents.map((event, key) => {
                            return (
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', color: 'white' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: 3, backgroundColor: this.state.historyType === 'private_lesson' ? '#f04141' : '#3b9c3e', borderRadius: 3, margin: 3 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 3 }}>
                                            { this.state.historyType === 'private_lesson' ? <SportsTennisIcon/> : <AttachMoneyIcon/> }
                                            <div>{ this.state.historyType === 'private_lesson' ? 'Aula Avulsa' : 'Aluguél de Quadra' }</div>
                                        </div>
                                        { this.state.historyType === 'court_rent' ? <div style={{ fontSize: 12, paddingLeft: 25 }}>{`${event.playerCount} jogadores`}</div> : null }
                                        { event.friends ? <div style={{ fontSize: 12, paddingLeft: 25 }}>{`Amigos convidados: ${event.friends.join(', ')}`}</div> : null }
                                        <div style={{ fontSize: 12, paddingLeft: 25, paddingBottom: 3 }}>{`De ${moment(event.start.toDate()).format('DD/MM/YYYY HH:mm')} Até ${moment(event.end.toDate()).format('DD/MM/YYYY HH:mm')}`}</div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    { !this.state.historyEvents.length ? <p style={{ color: 'grey', textAlign: 'center', marginTop: 50, marginBottom: 50 }}>Nenhum evento agendado com esse pacote.</p> : null }

                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.setState({ historyModal: false }) }} style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '100%'}} variant={'contained'}>{'FECHAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    async addCredit() {

        try {

            let total = this.state.pack.type === 'private_lesson' ? this.state.pack.total_lessons : this.state.pack.total_rents;
            let remainingCredits = this.state.remainingCredits;

            if (remainingCredits < total ) {

                if (this.state.loadingModal) {
                    toast.warn('Aguarde, realizando ação...');
                    return;
                }
    
                this.setState({ loadingModal: true });
    
                remainingCredits++;
    
                let data = {};
    
                if (this.state.pack.type === 'private_lesson') {
    
                    data.remaining_lessons = remainingCredits;
    
                } else {
    
                    data.remaining_rents = remainingCredits;
                }

                if (remainingCredits > 0 && !this.state.pack.active) {
                    data.active = true;
                }
    
                Firestore.update(data, 'user_pack', this.state.editId);
    
                let query = await Firestore.getDoc('user', this.state.pack.id_user);
                let userData = { id: query.id, ...query.data() };

                const collection = this.state.pack.type === 'private_lesson' ? 'private_lesson_packs' : 'court_rent_packs';

                let packQuery = await Firestore.getDoc(collection, this.state.pack.id_pack);
                let pack = { id: packQuery.id, ...packQuery.data() };
    
                if (userData.notification_token && userData.notification_token.data) {
    
                    let token = userData.notification_token.data;
                    let notification = {
                        icon: 'box',
                        color: Colors.primary,
                        navigate: 'Pack'
                    };
    
                    NotificationHelper.send('Saldo de Créditos', `Você ganhou um crédito no pacote ${pack.name}, abra o app para mais detalhes.`, notification, token, this.state.editId);
                }
    
                this.getDocs();
                this.setState({ loadingModal: false, remainingCredits: remainingCredits });
    
                toast.success('Crédito adicionado com sucesso');

            } else {

                toast.warn('Limite de créditos atingido');
            }

        } catch (error) {

            this.setState({ loadingModal: false });
            toast.error('Houve um problema ao adicionar a aula');
        }
    }

    async removeCredit() {

        try {

            let remainingCredits = this.state.remainingCredits;

            if (remainingCredits > 0) {

                if (this.state.loadingModal) {
                    toast.warn('Aguarde, realizando ação...');
                    return;
                }
    
                this.setState({ loadingModal: true });
    
                remainingCredits--;
    
                let data = {};
    
                if (this.state.pack.type === 'private_lesson') {
    
                    data.remaining_lessons = remainingCredits;
    
                } else {
    
                    data.remaining_rents = remainingCredits;
                }

                if (remainingCredits == 0 && this.state.pack.active) {
                    data.active = false;
                }
    
                Firestore.update(data, 'user_pack', this.state.editId);
    
                let query = await Firestore.getDoc('user', this.state.pack.id_user);
                let userData = { id: query.id, ...query.data() };

                const collection = this.state.pack.type === 'private_lesson' ? 'private_lesson_packs' : 'court_rent_packs';

                let packQuery = await Firestore.getDoc(collection, this.state.pack.id_pack);
                let pack = { id: packQuery.id, ...packQuery.data() };
    
                if (userData.notification_token && userData.notification_token.data) {
    
                    let token = userData.notification_token.data;
                    let notification = {
                        icon: 'box',
                        color: Colors.primary,
                        navigate: 'Pack'
                    };
    
                    NotificationHelper.send('Saldo de Créditos', `Descontado um crédito no pacote ${pack.name}, abra o app para mais detalhes.`, notification, token, this.state.editId);
                }
    
                this.getDocs();
                this.setState({ loadingModal: false, remainingCredits: remainingCredits });
    
                toast.success('Crédito removido com sucesso');

            } else {

                toast.warn('Limite de créditos atingido');
            }

        } catch (error) {

            this.setState({ loadingModal: false });
            toast.error('Houve um problema ao adicionar a aula');
        }
    }

    remainingCreditsModal() {

        if (this.state.remainingCreditsModal) {

            return (
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <Tooltip title={'Reduzir Aula'}>
                            <IconButton onClick={() => { this.removeCredit() }}>
                                <RemoveIcon />
                            </IconButton>
                        </Tooltip>
                        <div style={{ fontSize: 50, margin: 15 }}>{this.state.remainingCredits}</div>
                        <Tooltip title={'Adicionar Aula'}>
                            <IconButton onClick={() => { this.addCredit() }}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', fontWeight: 'bold', marginTop: 20 }}>
                        <InfoRoundedIcon style={{ fontSize: 40, marginRight: 8 }} />
                        <div>{'Ao alterar os créditos, o cliente será notificado.'}<br/>{'Ao incrementar um pacote com 0 créditos, ele voltará a ficar ativo.'}</div>
                    </div>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { this.setState({ remainingCreditsModal: false }) }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '100%' }} variant={'contained'}>{'FECHAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    renderPack(id, type) {
        
        const collection = type === 'private_lesson' ? 'private_lesson_packs' : 'court_rent_packs';
        return <RelationField collection={collection} field={'name'} id={id}/>
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>

                <DefaultTable
                title={'Pacotes Ativos'}
                marginTop={10}
                
                actions={[
                    {
                        icon: HistoryIcon,
                        tooltip: 'Histórico de Agendamentos',
                        onClick: (event, rowData) => { this.getPackHistory(rowData.id, rowData.type) } 
                    },
                    {
                        icon: EditIcon,
                        tooltip: 'Editar Saldo',
                        onClick: (event, rowData) => { this.setState({ editId: rowData.id, remainingCreditsModal: true, remainingCredits: rowData.type === 'private_lesson' ? rowData.remaining_lessons : rowData.remaining_rents, pack: rowData }) } 
                    },
                ]}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Cliente', field: 'id_user', render: rowData => <RelationField collection={'user'} field={'name'} id={rowData.id_user}/>, editable: false },
                    { title: 'Pacote', field: 'id_pack', render: rowData => this.renderPack(rowData.id_pack, rowData.type), editable: false },
                    { title: 'Créditos Restantes', field: 'remaining_lessons', render: rowData => this.renderRemaining(rowData), editable: false },
                    { title: 'Tipo', field: 'type', render: rowData => this.renderPackType(rowData.type), editable: false },
                    { title: 'Pedido', field: 'id_order', render: rowData => <RelationField collection={'order'} field={'title'} id={rowData.id_order}/>, editable: false },
                    { title: 'Status', field: 'active', render: rowData => this.renderPackActive(rowData.active), editable: false },
                    { title: 'Data', field: 'date', render: rowData => <div>{moment(rowData.date.toDate()).format('DD/MM/YYYY HH:mm')}</div>, editable: false },
                ]}
                data={this.state.docs}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.historyModal()} title={'Histórico do Pacote'} onClose={() => { this.setState({ historyModal: false, historyEvents: [], historyType: '' }) }} open={this.state.historyModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.remainingCreditsModal()} title={'Editar Saldo'} onClose={() => { this.setState({ remainingCreditsModal: false }) }} open={this.state.remainingCreditsModal} />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
