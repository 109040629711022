import React from 'react';
import DefaultModal from './DefaultModal';
import SalesHelper from '../helper/SalesHelper';
import moment from "moment-timezone";
import { Stepper, Step, StepLabel, FormLabel, IconButton } from '@material-ui/core';
import DefaultButton from './DefaultButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Colors from '../constants/Colors';
import CheckIcon from '@material-ui/icons/CheckCircle';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import DefaultSelect from './DefaultSelect';
import AddIcon from '@material-ui/icons/Add';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import DefaultInput from './DefaultInput';
import InputMask from "react-input-mask";
import Firestore from '../api/firebase/Firestore';
import { toast } from 'react-toastify';
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import InventoryHelper from '../helper/InventoryHelper';
import SessionHelper from '../helper/SessionHelper';
import TicketCard from './TicketCard';
import DefaultLoader from './DefaultLoader';
import ZipCodeHelper from '../helper/ZipCodeHelper';
import DataHelper from '../helper/DataHelper';

export default class AddSale extends React.Component {

    state = {
        addClient: false,
        loadingModal: false,
        loadingOpenTickets: false,
        openTickets: {},
        addSaleData: {
            step: 0,
            steps: ['Cliente', 'Produtos ou Serviços', 'Método de Pagamento', 'Finalização'],
            id_user: '',
            items: [],
            payment_method: '',
            value: 0,
            discount: 0,
            name: '',
            email: '',
            phone: '',
            cpf: '',
            zipcode: '',
            street: '',
            number: '',
            neighbourhood: '',
            complement: '',
        },
        end: moment().add(1, 'day').toDate(),
        paymentOptions: SalesHelper.getPaymentOptions(),
        productsDocs: this.props.productsDocs || [],
        salesDocs: this.props.salesDocs || [],
        usersDocs: this.props.usersDocs || [],
        adminUsers: this.props.adminUsers || [],
    }

    async componentDidMount() {

        if (!this.props.fromSalesPage) {

            await SalesHelper.getProducts(this);
            await SalesHelper.getSystemUsers(this);

            if (!this.props.usersDocs) {

                await SalesHelper.getUsers(this);
            }

            if (this.props.event && this.props.event.manual_info && this.props.event.manual_info.length) {
                this.setState({ addSaleData: { ...this.state.addSaleData, name: this.props.event.manual_info[0].name, phone: DataHelper.treatPhone(this.props.event.manual_info[0].phone) } })
            }
        }
    }

    getOpenTickets = async (id_user) => {
        this.setState({ loadingOpenTickets: true });

        let openTickets = await SalesHelper.getUserOpenTickets(id_user);

        this.setState({
            openTickets
        });

        this.setState({ loadingOpenTickets: false });
    }

    getOrInsertUser = async () => {

        if (this.state.addSaleData.id_user || this.state.addSaleData.name) {

            if (this.state.addSaleData.id_user) return this.state.addSaleData.id_user;

            let id_user = await Firestore.getId('user');

            await Firestore.insert({
                name: this.state.addSaleData.name,
                type: "sales_user",
                id_companies: [SessionHelper.getData().id_company],
                created_at: new Date(),
                cpf: this.state.addSaleData.cpf ? this.state.addSaleData.cpf.replace(/[^\d]/g, '') : null,
                email: this.state.addSaleData.email || null,
                phone: this.state.addSaleData.phone ? `${this.state.addSaleData.phone.replace(/[^\d]/g, '')}` : null,
                state: this.state.addSaleData.state || null,
                city: this.state.addSaleData.city || null,
                zipcode: this.state.addSaleData.zipcode ? this.state.addSaleData.zipcode.replace(/[^\d]/g, '') : null,
                street: this.state.addSaleData.street || null,
                number: this.state.addSaleData.number || null,
                neighbourhood: this.state.addSaleData.neighbourhood || null,
                complement: this.state.addSaleData.complement || null
            }, 'user', id_user);

            return id_user;

        } else {

            return '';
        }
    }

    insertAndFinish = async () => {
        let success = true;

        this.setState({ loadingModal: true });

        await SalesHelper.updatePrice(this);

        let divisionValid = SalesHelper.validateProductDivision(this.state.addSaleData.items);

        if (!divisionValid) {
            toast.warn('Preencha todos os campos do rateio corretamente');
            throw new Error('Error no Rateio');
        }

        let id_user = await this.getOrInsertUser();

        let orderTitle = `${this.state.addSaleData.payment_method === 'ticket' ? `Comanda` : `Pedido`} #${moment().format('HHmmss')}`;
        let price = parseInt(this.state.addSaleData.value - this.state.addSaleData.discount);
        let event = {};

        if (this.props.event) {

            event.collection = this.props.event.collection;
            event.id = this.props.event.id;
        }

        let index = 0;

        for (let item of this.state.addSaleData.items) {

            if (item.divided && !item.division_inserted && item.divisions && item.divisions.length) {

                await SalesHelper.createProductDivisions(this, index);
            }

            index++;
        }

        let order = {
            items: this.state.addSaleData.items,
            date: new Date(),
            title: orderTitle,
            amount: price,
            discount: parseInt(this.state.addSaleData.discount),
            brute_amount: parseInt(this.state.addSaleData.value),
            tax: 0,
            payment_method: this.state.addSaleData.payment_method,
            id_user: id_user,
            type: this.state.addSaleData.payment_method === 'ticket' ? 'ticket' : 'product',
            finished: false,
            refunded: false,
            id_company: SessionHelper.getData().id_company,
            date: new Date(),
            id_transaction_pagarme: null,
            created_by: SessionHelper.getFirebaseAuth().uid,
            status: this.state.addSaleData.payment_method === 'ticket' ? 'waiting_payment' : 'paid',
            event,
        };

        if (this.state.addSaleData.payment_method === 'ticket') order.end = this.state.end;

        try {
            await Firestore.insert(order, 'order');

            for (let item of order.items) {
                if (item.tangible) {
                    await InventoryHelper.addProductMovement(item.id, item.quantity, 'exit', orderTitle, false);
                }
            }

            if (this.props.getDocs) { await this.props.getDocs(); }
            if (this.props.onAdd) { await this.props.onAdd(); }
            if (this.props.onClose) { await this.props.onClose(); }

            this.setState({
                end: moment().add(1, 'day').toDate(),
                openTickets: {},
                addClient: false,
                addSaleData: {
                    ...this.state.addSaleData,
                    id_user: '',
                    items: [],
                    payment_method: '',
                    value: 0,
                    discount: 0,
                    name: '',
                    email: '',
                    phone: '',
                    cpf: '',
                    zipcode: '',
                    street: '',
                    number: '',
                    neighbourhood: '',
                    complement: '',
                }
            })

        } catch (e) {
            success = false;
        }

        this.setState({ loadingModal: false });

        return success;
    }

    async handleNext() {
        let isValid = true;
        let step = this.state.addSaleData.step;

        this.setState({ loadingModal: true });

        if (step === 1) {
            if (this.state.addSaleData.items.length < 1) {
                isValid = false;
                toast.info('Adicione pelo menos um produto a esta venda.')
            } else {
                this.state.addSaleData.items.forEach((item, key) => {

                    if (!item.id && (!item.id && item.id_event && (!item.title || !item.unit_price))) {
                        isValid = false;
                        toast.error(`Produto ou serviço do item ${key + 1} está vazio.`);
                    }
                })
            }
            if (this.state.addSaleData.value < this.state.addSaleData.discount) {
                isValid = false;
                toast.error('Desconto não pode ser maior que valor total');
            }
        } else if (step === 2) {
            if (!this.state.addSaleData.payment_method) {
                isValid = false;
                toast.info('Selecione um método de pagamento');
            }
            if (this.state.addSaleData.payment_method === 'ticket' && !moment(this.state.end).isValid()) {
                isValid = false;
                toast.error('Comanda deve ter uma data máxima de pagamento');
            }
        } else if (step === 3) {
            isValid = await this.insertAndFinish();
            step = 0;
            if (!isValid) toast.error('Ocorreu um erro ao finalizar esta venda.');

            isValid = false;
        }

        if (isValid) { step++ }

        this.setState({ addSaleData: { ...this.state.addSaleData, step }, loadingModal: false })
    };

    handleBack() {
        this.setState({ loadingModal: true });

        let step = this.state.addSaleData.step;
        step--;

        this.setState({ addSaleData: { ...this.state.addSaleData, step }, loadingModal: false });
    };

    salesModal() {
        if (this.props.open) {
            return (
                <div>
                    <Stepper style={{ width: '100%' }} activeStep={this.state.addSaleData.step} alternativeLabel>
                        {this.state.addSaleData.steps.map((label, key) => (
                            <Step key={label}>
                                <StepLabel icon={this.renderIcon(key)}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    {this.state.addSaleData.step === 0 && this.renderClientSelection()}
                    {this.state.addSaleData.step === 1 && SalesHelper.renderProductSelection(this)}
                    {this.state.addSaleData.step === 2 && this.renderPaymenOptions()}
                    {this.state.addSaleData.step === 3 && this.renderOverview()}

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton leftIcon={<NavigateBeforeIcon />} onClick={() => { this.handleBack() }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'Voltar'} disabled={this.state.addSaleData.step === 0 || this.state.addSaleData.step === this.state.addSaleData.steps.length - 1} />
                        <DefaultButton rightIcon={this.state.addSaleData.step === this.state.addSaleData.steps.length - 1 ? <CheckIcon style={{ marginLeft: 3 }} /> : <NavigateNextIcon />} onClick={() => { this.handleNext() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={this.state.addSaleData.step === this.state.addSaleData.steps.length - 1 ? 'Finalizar' : 'Avançar'} />
                    </div>
                </div>
            )
        }
    }

    renderOverview() {
        if (this.state.addSaleData) {
            return (
                <div>
                    <div style={{ marginBottom: 50, width: '100%', height: 400, overflowY: 'scroll', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 50 }}>
                        <h2 style={{ textAlign: 'center' }}>Revise os dados da venda.</h2>
                        <p style={{ textAlign: 'center', fontSize: 16 }}><b>Cliente:</b> {this.state.addSaleData.name ? this.state.addSaleData.name : 'Não Informado'}</p>
                        <p style={{ textAlign: 'center', fontSize: 16 }}><b>Produtos:</b> {this.state.addSaleData.items.map(item => `${item.title} (${item.quantity}), `)}</p>
                        <p style={{ textAlign: 'center', fontSize: 16 }}><b>Valor:</b> {SalesHelper.getLiquidAmount(this)}</p>
                        <p style={{ textAlign: 'center', fontSize: 16 }}><b>Método de pagamento:</b> {(this.state.paymentOptions.find(opt => opt.key === this.state.addSaleData.payment_method)).label}</p>
                        {this.state.addSaleData.payment_method === 'ticket' ? <p style={{ textAlign: 'center', fontSize: 16 }}><b>Cliente pode pagar comanda até:</b> {moment(this.state.end).calendar()}</p> : null}
                        <p style={{ textAlign: 'center', fontWeight: 'light', fontSize: 10 }}>O ACE não se responsabiliza por esta transação monetária, o PDV do ACE apenas resgitra os dados para fins de controle financeiro.</p>
                    </div>
                </div>
            );
        }
    }

    renderPaymenOptions(paymentOptions) {
        if (!paymentOptions) paymentOptions = this.state.paymentOptions;

        return (
            <div>
                <ul style={styles.ticketCardsContainer}>
                    {paymentOptions.map(paymentOption => {

                        if (!this.props.allowedPaymentMethods || (this.props.allowedPaymentMethods && this.props.allowedPaymentMethods.length && this.props.allowedPaymentMethods.includes(paymentOption.key))) {

                            if (paymentOption.key !== 'ticket' || (this.state.addSaleData.id_user || this.state.addSaleData.name)) {

                                return (
                                    <li
                                        onClick={() => { this.setState({ addSaleData: { ...this.state.addSaleData, payment_method: paymentOption.key } }) }}
                                        style={{ color: Colors.dark, boxShadow: `0px 0px 2px 0px ${this.state.addSaleData.payment_method === paymentOption.key ? Colors.primary : `rgba(34,34,34,0.46)`}`, ...styles.paymentMethodWrapper }}>
                                        {paymentOption.icon}
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            {this.state.addSaleData.payment_method === paymentOption.key ? <CheckIcon style={{ marginRight: 4, color: Colors.primary }} /> : null}
                                            {paymentOption.label}
                                        </div>
                                        {this.state.addSaleData.payment_method === 'ticket' && paymentOption.key === 'ticket' ?
                                            <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                                <KeyboardDateTimePicker
                                                    style={{ width: '80%', marginTop: 15 }}
                                                    invalidDateMessage={false}
                                                    format={'dd/MM/yyyy HH:mm'}
                                                    autoOk={true}
                                                    label="Data Limite para Pagamento"
                                                    cancelLabel={'Cancelar'}
                                                    okLabel={'Confirmar'}
                                                    onChange={(v) => { this.setState({ end: v }) }}
                                                    minDate={new Date()}
                                                    value={this.state.end || null}
                                                />
                                            </MuiPickersUtilsProvider>
                                            : null}
                                    </li>
                                )
                            }
                        }
                    })}
                </ul>
                {this.state.openTickets && this.state.openTickets.open > 0 ?
                    <div style={{ color: Colors.danger, display: 'flex', flexDirection: 'row', fontWeight: 'bold', marginBottom: 10, width: '100%', textAlign: 'center', fontSize: 22 }}>
                        <InfoRoundedIcon style={{ marginRight: 8, fontSize: 28 }} />
                        <div>{`Esse cliente já possui ${this.state.openTickets.open} comandas em aberto.`}</div>
                    </div>
                    : null}
                {this.state.openTickets && this.state.openTickets.late > 0 ?
                    <div style={{ color: Colors.error, display: 'flex', flexDirection: 'row', fontWeight: 'bold', marginBottom: 10, width: '100%', textAlign: 'center', fontSize: 22 }}>
                        <InfoRoundedIcon style={{ marginRight: 8, fontSize: 28 }} />
                        <div>{`Esse cliente tem ${this.state.openTickets.late} comandas vencidas e não pagas.`}</div>
                    </div>
                    : null}
            </div>
        );
    }

    async zipCodeFetch(zipCode) {
        if (zipCode.length) {
            let response = await ZipCodeHelper.getAddress(zipCode.replace(/[^\d]/g, ''));

            if (response !== false) {
                this.setState({ addSaleData: { ...this.state.addSaleData, street: response.logradouro, city: response.localidade, state: response.uf, neighbourhood: response.bairro, complement: response.complemento } })
            } else {
                this.setState({ addSaleData: { ...this.state.addSaleData, street: '', city: '', state: '', neighbourhood: '', complement: '' } })
            }
        }
    }

    renderClientForm() {
        if (this.state.addClient) {
            return (
                <div>
                    <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', fontWeight: 'bold', marginBottom: 10 }}>
                        <InfoRoundedIcon style={{ fontSize: 20, marginRight: 8 }} />
                        <div>{'Se o valor for ficar em débito recomendamos que preencha todos os dados'}</div>
                    </div>

                    <DefaultInput required defaultValue={this.state.addSaleData.name} onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ addSaleData: { ...this.state.addSaleData, name: v } }) }} label={'Nome'} />
                    <InputMask onBlur={(v) => { this.setState({ addSaleData: { ...this.state.addSaleData, cpf: v } }) }} mask="999.999.999-99" maskPlaceholder={''}>
                        <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} minLength={11} label={'CPF'} />
                    </InputMask>

                    <DefaultInput email onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ addSaleData: { ...this.state.addSaleData, email: v } }) }} label={'E-mail'} />

                    <InputMask defaultValue={this.state.addSaleData.phone} onBlur={(v) => { this.setState({ addSaleData: { ...this.state.addSaleData, phone: DataHelper.treatPhone(v) } }) }} mask="+55 (99) 9 9999-9999" maskPlaceholder={''}>
                        <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} minLength={11} label={'Telefone'} />
                    </InputMask>

                    <FormLabel style={{ paddingTop: 28, paddingBottom: 18 }} component="legend">Endereço</FormLabel>
                    <div style={{ color: Colors.contrast, display: 'flex', flexDirection: 'row', fontWeight: 'bold', marginBottom: 10 }}>
                        <InfoRoundedIcon style={{ fontSize: 20, marginRight: 8 }} />
                        <div>{'Informe o CEP para auto-completar o endereço.'}</div>
                    </div>
                    <InputMask onBlur={(v) => { this.zipCodeFetch(v) }} onChange={(v) => { this.setState({ addSaleData: { ...this.state.addSaleData, zipcode: v } }) }} mask="99999-999" value={this.state.zipcode}>
                        <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} minLength={8} label={'Cep'} />
                    </InputMask>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '50%', paddingRight: 5 }}><DefaultInput value={this.state.addSaleData.state} disabled={true} label={'Estado'} /></div>
                        <div style={{ width: '50%', paddingLeft: 5 }}><DefaultInput value={this.state.addSaleData.city} disabled={true} label={'Cidade'} /></div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '70%', paddingRight: 5 }}><DefaultInput value={this.state.addSaleData.street} onChange={(v) => { this.setState({ addSaleData: { ...this.state.addSaleData, street: v } }) }} label={'Rua'} /></div>
                        <div style={{ width: '30%', paddingLeft: 5 }}><DefaultInput value={this.state.addSaleData.number} onChange={(v) => { this.setState({ addSaleData: { ...this.state.addSaleData, number: v } }) }} label={'Número'} /></div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '50%', paddingRight: 5 }}><DefaultInput value={this.state.addSaleData.neighbourhood} onChange={(v) => { this.setState({ addSaleData: { ...this.state.addSaleData, neighbourhood: v } }) }} label={'Bairro'} /></div>
                        <div style={{ width: '50%', paddingLeft: 5 }}><DefaultInput value={this.state.addSaleData.complement} onChange={(v) => { this.setState({ addSaleData: { ...this.state.addSaleData, complement: v } }) }} label={'Complemento'} /></div>
                    </div>
                    <div style={{ marginTop: 8 }} />
                </div>
            );
        }
    }

    renderUserOpenTickets() {
        return (
            this.state.openTickets && this.state.openTickets.docs && this.state.openTickets.docs.length > 0 ?
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ color: Colors.contrast, display: 'flex', flexDirection: 'row', fontWeight: 'bold', paddingTop: 20 }}>
                        <InfoRoundedIcon style={{ fontSize: 20, marginRight: 8 }} />
                        <div>Comandas em aberto: {this.state.openTickets.open}</div>
                    </div>
                    <div style={{ color: Colors.error, display: 'flex', flexDirection: 'row', fontWeight: 'bold', paddingTop: 8 }}>
                        <InfoRoundedIcon style={{ fontSize: 20, marginRight: 8 }} />
                        <div>Comandas em atrasadas: {this.state.openTickets.late}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'auto', paddingTop: 10 }}>
                        {this.state.openTickets.docs.map(doc => <TicketCard width={250} onIsEditing={(flag) => { this.getOpenTickets(this.state.addSaleData.id_user); }} fromSalesPage={false} productsDocs={this.state.productsDocs} doc={doc} usersDocs={this.state.usersDocs} adminUsers={this.state.adminUsers} />)}
                    </div>
                </div>
                : this.state.loadingOpenTickets ?
                    <div style={{ color: Colors.danger, display: 'flex', flexDirection: 'row', fontWeight: 'bold', paddingTop: 20 }}>
                        <DefaultLoader size={20} css={{ marginRight: 8 }} color={Colors.danger} />
                        <div>Carregando comandas deste cliente</div>
                    </div>
                    : null
        );
    }

    renderClientSelection() {

        let disableAddCustomClient = (this.state.addSaleData.id_user || this.props.disableAddCustomClient) && !(this.props.event && this.props.event.manual_info && this.props.event.manual_info.length);

        return (
            <div>
                <DefaultSelect
                    useCustomRender
                    search={true}
                    searchField={'name'}
                    id={'user_selection'}
                    valueField={'id'}
                    displayField={'name'}
                    secondaryComponent={'type_label'}
                    value={this.state.addSaleData.id_user}
                    onChange={(v) => {
                        let name = ``;

                        let doc = this.state.usersDocs.find(doc => doc.id === v.target.value);

                        if (doc) {
                            name = doc.name;
                            this.getOpenTickets(doc.id);
                        }

                        this.setState({
                            addSaleData: { ...this.state.addSaleData, id_user: v.target.value, name }
                        });
                    }}
                    docs={this.state.usersDocs}
                    label={'Selecione um Cliente Cadastrado'}
                />

                {this.renderUserOpenTickets()}

                <div style={{ marginTop: 20, marginBottom: 20, fontSize: 20, color: Colors.disabled, width: '100%', textAlign: 'center' }}>ou</div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
                    <FormLabel style={{ paddingTop: 10, marginRight: 10 }} component="legend">{`Adicione um cliente`}</FormLabel>
                    <IconButton
                        disabled={disableAddCustomClient}
                        onClick={() => {
                            if (!this.state.addClient === false) {
                                this.setState({ addSaleData: { ...this.state.addSaleData, name: '', cpf: '', email: '', phone: '', street: '', city: '', state: '', neighbourhood: '', complement: '' } });
                            }
                            this.setState({ addClient: !this.state.addClient })
                        }}
                        style={{ boxShadow: Colors.boxShadow, backgroundColor: disableAddCustomClient ? Colors.disabled : Colors.primary, height: 32, width: 32, borderRadius: 16, color: '#fff' }}>
                        <AddIcon style={{ transform: this.state.addClient ? 'rotate(45deg)' : 'rotate(0deg)', transition: `.8s ease-in-out` }} />
                    </IconButton>
                </div>

                <div style={{ color: Colors.disabled, display: 'flex', flexDirection: 'row', fontWeight: 'bold', paddingTop: 20 }}>
                    <InfoRoundedIcon style={{ fontSize: 20, marginRight: 8 }} />
                    <div>Caso não queira informar um cliente clique em avançar sem selecionar nada.</div>
                </div>
                {this.renderClientForm()}
            </div>
        );
    }

    renderIcon(step) {
        return (
            <div style={{ backgroundColor: this.state.addSaleData.step < step ? 'lightgrey' : Colors.primary, width: 30, height: 30, padding: 5, fontSize: 14, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ color: '#FFFFFF' }}>{this.state.addSaleData.step < step + 1 ? (step + 1) : <CheckIcon style={{ fontSize: 20, paddingTop: 3 }} />}</div>
            </div>
        )
    }

    render() {

        return (
            <DefaultModal loading={this.state.loadingModal} content={this.salesModal()} title={'Nova Venda'} onClose={() => {
                this.setState({
                    end: moment().add(1, 'day').toDate(),
                    openTickets: {},
                    addSaleData: {
                        ...this.state.addSaleData,
                        step: 0,
                        id_user: '',
                        items: [],
                        payment_method: '',
                        value: 0,
                        discount: 0,
                        name: '',
                        email: '',
                        phone: '',
                        cpf: '',
                        zipcode: '',
                        street: '',
                        number: '',
                        neighbourhood: '',
                        complement: '',
                    }
                });

                this.props.onClose();

            }} open={this.props.open} width={window.screen.width < 1360 ? '99%' : window.screen.width - (window.screen.width * 0.3)} />
        )
    }
}

const styles = {
    ticketCardsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 0,
        listStyleType: 'none',
        paddingInlineStart: 0
    },
    paymentMethodWrapper: {
        display: `flex`,
        flexDirection: 'column',
        margin: 10,
        cursor: `pointer`,
        backgroundColor: `#fff`,
        fontSize: 20,
        justifyContent: 'center',
        alignItems: 'center',
        height: 200,
        width: 200,
        flexGrow: 1,
        marginTop: 5,
        borderRadius: 5,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5
    },
}