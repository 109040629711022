import React, { Component } from 'react';
import IosSwitch from '../IosSwitch';
import DefaultButton from '../DefaultButton';
import DefaultLoader from '../DefaultLoader';
import ReportHelper from '../../helper/ReportHelper';
import moment from "moment-timezone";
import { FormLabel } from '@material-ui/core';
import Firestore from '../../api/firebase/Firestore';
import SessionHelper from '../../helper/SessionHelper';
import ReportDefaultModalOptions from './ReportDefaultModalOptions';
import { toast } from 'react-toastify';

export default class RankingMatchReport extends Component {

    state = {
        loadingModal: true,
        studentDocs: [],
        users: [],
        courtDocs: [],
        courts: [],
        rankingDocs: [],
        tournamentDoc: null,
        tournament: null,
        allCourts: true,
        allUsers: true,
        start: moment().startOf('day').toDate(),
        end: moment().endOf('day').toDate(),
        output: 'download',
        format: 'pdf',
    }

    async componentDidMount() {

        if (this.props.getDocs && this.props.getDocs.length) {

            for (let index = 0; index < this.props.getDocs.length; index++) {
                
                const getDoc = this.props.getDocs[index];
                await getDoc(this);
            }
        }

        this.setState({ loadingModal: false });
    }

    async getReportDocs() {

        let docs = [];
        let tournament = this.state.tournamentDoc;

        let courts = this.state.allCourts ? this.state.courtDocs : this.state.courts;
        let courtDocs = [];

        courts.forEach((court, key) => {

            let courtDoc = court;

            if (!this.state.allCourts) {

                courtDoc = this.state.courtDocs.find(item => item.id === court);
            }

            courtDocs.push(courtDoc);
        });

        let query = await Firestore.customQuery('ranking_event').where('id_ranking', '==', this.state.tournamentDoc.id).where('id_company', '==', SessionHelper.getData().id_company).where('start', '>=', this.state.start).where('start', '<=', this.state.end).get();

        let counter = 0;
        for (let doc of query.docs) {
            let findCourt = false;
            let event = { ...doc.data(), id: doc.id };
            let userNames = [];
            let idUsers = event.id_players;

            if (tournament.category[event.indexes.category].print && tournament.category[event.indexes.category].classes[event.indexes.class].print) {

                for (const user of idUsers) {
                
                    let query = await Firestore.getDoc('user', user);
                    let doc = { ...query.data(), id: query.id };
        
                    if (doc && doc.name) {
                        
                        userNames.push(ReportHelper.getShortName(doc.name));
    
                    } else {
    
                        userNames.push('');
                    }
                }
    
                let court = courtDocs.find(item => item.id === event.id_court);
                if (court) { findCourt = true };
    
                if (findCourt || (this.state.allCourts)) {
    
                    let categoryLabel = tournament.category[event.indexes.category].name === 'M' ? 'Masc.' : tournament.category[event.indexes.category].name === 'F' ? 'Fem.' : 'Mis.';

                    docs.push({
                        date: `${moment(event.start.toDate()).format('DD/MM/YY HH:mm')} ~ ${moment(event.end.toDate()).format('HH:mm')}`,
                        court: court ? court.name : event.court ? event.court : 'Sem Localização',
                        students: userNames.join(', '),
                        type: 'Partida de Torneio',
                        round: `${categoryLabel} - ${tournament.category[event.indexes.category].classes[event.indexes.class].title}`
                    });
                }
            }

            counter++;
            ReportHelper.updateProgress(this, query.docs.length, counter, null);
        }

        docs.sort((a, b) => {

            return a.date > b.date;
        });

        return docs;
    }

    async print() {

        if (this.state.tournamentDoc) {

            this.setState({ loadingModal: true });

            let docs = await this.getReportDocs();
            let name = `Partidas - ${this.state.tournamentDoc.name}`;
            let subtitle = `De ${moment(this.state.start).format('DD/MM/YYYY HH:mm')} Até ${moment(this.state.end).format('DD/MM/YYYY HH:mm')}`;
            let report = await ReportHelper.createReport(name, 5, subtitle, this.state.format);
    
            report = await ReportHelper.createColumns(report, docs, [
                { name: 'Data', width: 13, getText: (doc) => doc.date },
                { name: 'Quadra', width: 55, getText: (doc) => this.state.format === 'pdf' ? ReportHelper.cutName(doc.court, 17) : doc.court },
                { name: 'Categoria', width: 90, getText: (doc) => this.state.format === 'pdf' ? ReportHelper.cutName(doc.round, 28) : doc.round },
                { name: 'Jogadores', width: 145, getText: (doc) => this.state.format === 'pdf' ? ReportHelper.cutName(doc.students, 27) : doc.students },
            ]);
    
            ReportHelper.print(report, this.state.output, this.state.format);
    
            this.setState({ loadingModal: false });
        
        } else {

            toast.warn('Selecione o torneio');
        }
    }

    renderLoading() {
        return (
            <div style={{ height: 200 }}>
                <DefaultLoader progress={this.state.progress || 0} loadingText={this.state.loadingText || ``} />
            </div>
        )
    }

    handleTournamentChange(value) {

        let doc = this.state.rankingDocs.find(item => item.id === value);

        for (let categoryIndex = 0; categoryIndex < doc.category.length; categoryIndex++) {
            
            doc.category[categoryIndex].print = true;

            for (let classIndex = 0; classIndex < doc.category[categoryIndex].classes.length; classIndex++) {

                doc.category[categoryIndex].classes[classIndex].print = true;
            }
        }
        
        this.setState({ tournamentDoc: doc, start: doc.start.toDate(), end: doc.end.toDate() });
    }
    
    renderModal() {

        return (

            <div>
                { ReportHelper.renderRankingSelection(this, this.state.rankingDocs, 'ranking', (value) => { this.handleTournamentChange(value) }) }

                { this.state.tournamentDoc ? <FormLabel style={{ paddingBottom: 18 }} component="legend">Selecione as Classes</FormLabel> : null }
                {
                    this.state.tournamentDoc ?

                        this.state.tournamentDoc.category.map((category, key) => {

                            if (category.checked) {

                                return (

                                    <div>
                                        <IosSwitch fullWidth label={category.title} checked={category.print} onChange={(e) => { let tournament = this.state.tournamentDoc; tournament.category[key].print = e; this.setState({ tournamentDoc: tournament }) }} />
                                        
                                        {
                                            this.state.tournamentDoc.category[key].print ? 

                                                this.state.tournamentDoc.category[key].classes.map((categoryClass, classKey) => {
                                                    
                                                    if (categoryClass.checked) {

                                                        return (
                                                            <div style={{ marginLeft: 20 }}>
                                                                <IosSwitch fullWidth label={categoryClass.title} checked={categoryClass.print} onChange={(e) => { let tournament = this.state.tournamentDoc; tournament.category[key].classes[classKey].print = e; this.setState({ tournamentDoc: tournament }) }} />
                                                            </div>
                                                        )
                                                    }
                                                })

                                            : null
                                        }
                                        
                                    </div>
                                )
                            }
                        })

                    : null
                }

                <IosSwitch fullWidth label={'Todas as Quadras'} checked={this.state.allCourts} onChange={(e) => { this.setState({ allCourts: e, courts: [] }) }} />
                { !this.state.allCourts ? ReportHelper.renderCourtSelection(this, this.state.courtDocs, 'courts') : null }

                { ReportHelper.renderPeriodSelection(this, 'start', 'end', 'dd/MM/yyyy HH:mm') }
                
                <ReportDefaultModalOptions format={this.state.format} output={this.state.output} onOutputChange={(value) => { this.setState({ output: value }) }} onFormatChange={(value) => { this.setState({ format: value }) }}/>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <DefaultButton width={'100%'} title={'Imprimir'} onClick={() => { this.print() }} />
                </div>
            </div>
        )
    }

    render() {

        return (

            this.state.loadingModal ? this.renderLoading() : this.renderModal()
        )
    }
}