import React from 'react';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import Colors from '../constants/Colors';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

export default class CalendarToolbar extends Toolbar {

	componentDidMount() {

	}

    renderNavigateButton(label, navigate, leftIcon = null, rightIcon = null, first = false, last = false) {

        return <button style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', padding: 12, width: 90, fontWeight: 'bold', backgroundColor: 'white', boxShadow: Colors.boxShadow, color: 'black', border: '1px solid lightgrey', borderTopLeftRadius: first ? 5 : 0, borderBottomLeftRadius: first ? 5 : 0, borderTopRightRadius: last ? 5 : 0, borderBottomRightRadius: last ? 5 : 0 }} type="button" onClick={() => this.navigate(navigate)}>{leftIcon}{label}{rightIcon}</button>
    }

    renderViewButton(label, view, first = false, last = false) {

        return <button style={{ cursor: 'pointer', padding: 12, width: 80, fontWeight: 'bold', backgroundColor: this.props.view === view ? Colors.primary : 'white', boxShadow: Colors.boxShadow, color: this.props.view === view ? 'white' : 'black', border: '1px solid lightgrey', borderTopLeftRadius: first ? 5 : 0, borderBottomLeftRadius: first ? 5 : 0, borderTopRightRadius: last ? 5 : 0, borderBottomRightRadius: last ? 5 : 0 }} type="button" onClick={this.view.bind(null, view)}>{label}</button>
    }

	render() {
		return (
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 15 }} className={'header-actions-buttons'}>
				<div className="rbc-btn-group" style={{ display: 'flex', flexDirection: 'row', marginBottom: window.screen.width < 1080 ? 12 : 0 }}>
                    {this.renderNavigateButton('Hoje', 'TODAY', null, null, true, false)}
                    {this.renderNavigateButton('Voltar', 'PREV', <ChevronLeft style={{ fontSize: 16 }}/>, null)}
                    {this.renderNavigateButton('Avançar', 'NEXT', null, <ChevronRight style={{ fontSize: 16 }}/>, false, true)}
				</div>
				<div className="rbc-toolbar-label" style={{ fontWeight: 'bold', fontSize: 16, marginBottom: window.screen.width < 1080 ? 12 : 0 }}>{this.props.label}</div>
				<div className="rbc-btn-group">
                    {this.renderViewButton('Mês', 'month', true, false)}
                    {this.renderViewButton('Semana', 'week')}
                    {this.renderViewButton('Dia', 'day', false, true)}
                    {/* {this.renderViewButton('Agenda', 'agenda', false, true)} */}
				</div>
			</div>
		);
	}
}