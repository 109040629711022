import React, { Component } from 'react';
import DefaultLoader from '../DefaultLoader';
import { Card, IconButton, Tooltip, Grid } from '@material-ui/core';
import Firestore from '../../api/firebase/Firestore';
import moment from "moment-timezone";
import { Doughnut, Bar } from 'react-chartjs-2';
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import RefreshIcon from '@material-ui/icons/Refresh';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import SessionHelper from '../../helper/SessionHelper';
import CurrencyHelper from '../../helper/CurrencyHelper';
import Colors from '../../constants/Colors';

const gridSize = window.screen.width < 1080 ? 12 : 6;

export default class HomePage extends Component {

    state = {
        loading: true,
        orderDocs: [],
        userDocs: [],
        totalSalesChart_start: moment().startOf('month').toDate(),
        totalSalesChart_end: moment().endOf('month').toDate(),
        totalSalesTypeChart_start: moment().startOf('month').toDate(),
        totalSalesTypeChart_end: moment().endOf('month').toDate(),
        totalSalesChart: [],
        totalSalesTypeChart: [],
        clientSalesChart_start: moment().startOf('month').toDate(),
        clientSalesChart_end: moment().endOf('month').toDate(),
        clientSalesChart: {
            labels: [],
            datasets: [
                {
                    label: 'Cliente',
                    backgroundColor: '#36A2EB',
                    borderWidth: 1,
                    data: []
                }
            ]
        },
        totalReceipt: 0,
        totalReceiptRaw: 0,
        totalReceiptLastMonth: 0,
        totalReceiptLastMonthRaw: 0,
    }

    async getOrders() {

        let query = await Firestore.customQuery('order').where('id_company', '==', SessionHelper.getData().id_company).get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ orderDocs: docs });
    }

    async getUsers() {

        let query = await Firestore.customQuery('user').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ userDocs: docs });
    }

    async componentDidMount() {

        this.setState({ loading: true });

        await this.getOrders();
        await this.getUsers();

        await this.totalSalesChart();
        await this.totalSalesTypeChart();
        await this.totalReceiptChart();
        await this.clientSalesChart();

        this.setState({ loading: false });
    }

    async totalSalesChart() {

        let data = {
            boleto: 0,
            credit_card: 0,
            money: 0,
            pix: 0,
            ticket: 0,
        };

        this.state.orderDocs.forEach((order, key) => {

            let date = moment(order.date.toDate());

            if (((moment(this.state.totalSalesChart_start).isValid() && (date.isSameOrAfter(moment(this.state.totalSalesChart_start)))) && moment(this.state.totalSalesChart_end).isValid() && (date.isSameOrBefore(moment(this.state.totalSalesChart_end))))) {

                if (order.payment_method === 'boleto') {
                    
                    data.boleto++;

                } else if (order.payment_method === 'credit_card' || order.payment_method === 'credit-card') {

                    data.credit_card++;

                } else if (order.payment_method === 'money') {
                    
                    data.money++;

                } else if (order.payment_method === 'pix') {
                    
                    data.pix++;

                } else if (order.payment_method === 'ticket') {
                    
                    data.ticket++;
                }
            }
        });

        this.setState({ totalSalesChart: [data.boleto, data.credit_card, data.money, data.pix, data.ticket] });
    }

    async totalSalesTypeChart() {

        let data = {
            product: 0,
            ticket: 0,
            tournament_ticket: 0,
            court_rent: 0,
            private_lesson: 0,
            experimental_lesson: 0,
            day_use: 0,
            packs: 0,
        };

        this.state.orderDocs.forEach((order, key) => {

            let date = moment(order.date.toDate());

            if (((moment(this.state.totalSalesTypeChart_start).isValid() && (date.isSameOrAfter(moment(this.state.totalSalesTypeChart_start)))) && moment(this.state.totalSalesTypeChart_end).isValid() && (date.isSameOrBefore(moment(this.state.totalSalesTypeChart_end))))) {

                if (order.type === 'product') {
                    data.product++;

                } else if (order.type === 'tournament_ticket') {
                    data.tournament_ticket++;

                } else if (order.type === 'court_rent') {
                    data.court_rent++;

                } else if (order.type === 'private_lesson') {
                    data.private_lesson++;

                } else if (order.type === 'experimental_lesson') {
                    data.experimental_lesson++;

                } else if (order.type === 'private_lesson_pack' || order.type === 'court_rent_pack') {
                    data.packs++;

                } else if (order.type === 'ticket') {
                    data.ticket++;

                } else if (order.type === 'day_use') {
                    data.day_use++;

                }
            }
        });

        this.setState({ totalSalesTypeChart: [data.product, data.ticket, data.tournament_ticket, data.court_rent, data.private_lesson, data.experimental_lesson, data.packs, data.day_use] });
    }

    async totalReceiptChart() {

        let total = 0;
        let totalLastMonth = 0;

        this.state.orderDocs.forEach((order, key) => {

            if (!order.refunded && !order.canceled) {

                let date = moment(order.date.toDate());

                let actualMonth = moment();
                let lastMonth = moment().subtract(1, 'month');
    
                if (date.isSame(actualMonth, 'month')) {
    
                    total += order.amount;
    
                } else if (date.isSame(lastMonth, 'month')) {
    
                    totalLastMonth += order.amount;
                }
            }
        });

        this.setState({ totalReceipt: CurrencyHelper.centsToMoney(total, true), totalReceiptLastMonth: CurrencyHelper.centsToMoney(totalLastMonth, true), totalReceiptLastMonthRaw: totalLastMonth, totalReceiptRaw: total });
    }

    async clientSalesChart() {

        let data = {
            labels: [],
            datasets: [
                {
                    label: 'Pedidos',
                    backgroundColor: '#36A2EB',
                    borderWidth: 1,
                    data: []
                }
            ]
        };

        let clientSalesChart = {};

        this.state.orderDocs.forEach((order, key) => {

            let date = moment(order.date.toDate());

            let userDoc = this.state.userDocs[this.state.userDocs.findIndex(item => item.id === order.id_user)];

            if (userDoc) {

                if (clientSalesChart[order.id_user] === undefined) {

                    clientSalesChart[order.id_user] = 0;
                    data.labels.push(userDoc.name);
                }
    
                if ((moment(this.state.clientSalesChart_start).isValid() && (date.isSameOrAfter(moment(this.state.clientSalesChart_start)))) && moment(this.state.clientSalesChart_end).isValid() && (date.isSameOrBefore(moment(this.state.clientSalesChart_end)))) {
    
                    if (order.id_user === userDoc.id) {
                        clientSalesChart[order.id_user]++;
                    }
                }
            }
        });

        Object.keys(clientSalesChart).forEach((value, key) => {
            data.datasets[0].data.push(clientSalesChart[value]);
        });

        this.setState({ clientSalesChart: data });
    }

    render() {

        return this.state.loading ? <DefaultLoader /> : (

            <div style={{ width: '100%' }}>
                <Grid container spacing={1}>
                    <Grid item xs={gridSize}>
                        <Card style={{ padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'auto', boxShadow: Colors.boxShadow }} className={'mobile-dashboard'}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', marginBottom: 20, marginTop: 10 }}>
                                <div style={{ fontSize: 20 }}>{'Vendas por Método de Pagamento'}</div>
                                <Tooltip onClick={async () => { await this.getOrders(); await this.totalSalesChart(); }} title="Atualizar"><IconButton><RefreshIcon style={{}} /></IconButton></Tooltip>
                            </div>
                            <Doughnut
                                height={window.screen.width < 1080 ? 250 : 80}
                                options={{ maintainAspectRatio: true }}
                                data={{
                                    labels: [
                                        'Boleto Bancário',
                                        'Cartão de Crédito',
                                        'Pagamento no Local',
                                        'Pix',
                                        'Comanda em Aberto',
                                    ],
                                    datasets: [{
                                        data: this.state.totalSalesChart,
                                        backgroundColor: [
                                            '#36A2EB',
                                            '#1a1f71',
                                            Colors.success,
                                            '#01a99b',
                                            Colors.order.ticket,
                                        ],

                                    }]
                                }}
                            />

                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', alignSelf: 'flex-end', marginTop: 20 }}>
                                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        style={{ width: '100%', padding: 15 }}
                                        invalidDateMessage={false}
                                        format={'dd/MM/yyyy'}
                                        autoOk={true}
                                        cancelLabel={'Cancelar'}
                                        okLabel={'Confirmar'}
                                        onChange={async (v) => { await this.setState({ totalSalesChart_start: v }); this.totalSalesChart(); }}
                                        value={this.state.totalSalesChart_start}
                                    />
                                </MuiPickersUtilsProvider>
                                <div style={{ color: 'grey' }}>{'até'}</div>
                                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        style={{ width: '100%', padding: 15 }}
                                        invalidDateMessage={false}
                                        format={'dd/MM/yyyy'}
                                        autoOk={true}
                                        cancelLabel={'Cancelar'}
                                        okLabel={'Confirmar'}
                                        onChange={async (v) => { await this.setState({ totalSalesChart_end: v }); this.totalSalesChart(); }}
                                        value={this.state.totalSalesChart_end}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={gridSize}>
                        <Card style={{ padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'auto', boxShadow: Colors.boxShadow }} className={'mobile-dashboard'}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', marginBottom: 20, marginTop: 10 }}>
                                <div style={{ fontSize: 20 }}>{'Total de Vendas'}</div>
                                <Tooltip onClick={async () => { await this.getOrders(); await this.totalSalesTypeChart(); }} title="Atualizar"><IconButton><RefreshIcon style={{}} /></IconButton></Tooltip>
                            </div>

                            <Doughnut
                                height={window.screen.width < 1080 ? 250 : 80}
                                options={{ maintainAspectRatio: true }}
                                data={{
                                    labels: [
                                        'Produtos',
                                        'Comanda',
                                        'Ingresso de Torneio',
                                        'Aluguel de Quadra',
                                        'Aula Avulsa',
                                        'Aula Experimental',
                                        'Pacotes',
                                        'Day Use',
                                    ],
                                    datasets: [{
                                        data: this.state.totalSalesTypeChart,
                                        backgroundColor: [
                                            Colors.order.product,
                                            Colors.order.ticket,
                                            Colors.order.tournament_ticket,
                                            Colors.order.court_rent,
                                            Colors.order.private_lesson,
                                            Colors.order.experimental_lesson,
                                            Colors.order.court_rent_pack,
                                            Colors.order.day_use,
                                        ],

                                    }]
                                }}
                            />

                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', alignSelf: 'flex-end', marginTop: 20 }}>
                                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        style={{ width: '100%', padding: 15 }}
                                        invalidDateMessage={false}
                                        format={'dd/MM/yyyy'}
                                        autoOk={true}
                                        cancelLabel={'Cancelar'}
                                        okLabel={'Confirmar'}
                                        onChange={async (v) => { await this.setState({ totalSalesTypeChart_start: v }); this.totalSalesTypeChart(); }}
                                        value={this.state.totalSalesTypeChart_start}
                                    />
                                </MuiPickersUtilsProvider>
                                <div style={{ color: 'grey' }}>{'até'}</div>
                                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        style={{ width: '100%', padding: 15 }}
                                        invalidDateMessage={false}
                                        format={'dd/MM/yyyy'}
                                        autoOk={true}
                                        cancelLabel={'Cancelar'}
                                        okLabel={'Confirmar'}
                                        onChange={async (v) => { await this.setState({ totalSalesTypeChart_end: v }); this.totalSalesTypeChart(); }}
                                        value={this.state.totalSalesTypeChart_end}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={gridSize}>
                        <Card style={{ padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', boxShadow: Colors.boxShadow }} className={'mobile-dashboard'}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', marginBottom: 20, marginTop: 10 }}>
                                <div style={{ fontSize: 20 }}>{'Receita de Vendas'}</div>
                                <Tooltip onClick={async () => { await this.getOrders(); await this.totalReceiptChart(); }} title="Atualizar"><IconButton><RefreshIcon style={{}} /></IconButton></Tooltip>
                            </div>

                            <div style={{ height: 'auto', display: 'flex', flexDirection: 'row' }} className={'mobile-dashboard'}>
                                <div style={{ display: 'flex', flexDirection: 'column', margin: 25, fontSize: 30 }}>
                                    <div style={{ fontWeight: 'lighter', fontSize: 20, paddingLeft: 5 }}>{'Mês Passado'}</div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: this.state.totalReceiptLastMonthRaw > this.state.totalReceiptRaw ? Colors.success : Colors.error }}>
                                        {this.state.totalReceiptLastMonthRaw > this.state.totalReceiptRaw ? <ArrowUpwardIcon style={{ fontSize: 40 }} /> : <ArrowDownwardIcon style={{ fontSize: 40 }} />}
                                        {this.state.totalReceiptLastMonth}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', margin: 25, fontSize: 30 }}>
                                    <div style={{ fontWeight: 'lighter', fontSize: 20, paddingLeft: 5 }}>{'Mês Atual'}</div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: this.state.totalReceiptRaw >= this.state.totalReceiptLastMonthRaw ? Colors.success : Colors.error }}>
                                        {this.state.totalReceiptRaw >= this.state.totalReceiptLastMonthRaw ? <ArrowUpwardIcon style={{ fontSize: 40 }} /> : <ArrowDownwardIcon style={{ fontSize: 40 }} />}
                                        {this.state.totalReceipt}
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', alignSelf: 'flex-end', marginTop: 20 }}>

                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={gridSize}>
                        <Card style={{ padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'auto', boxShadow: Colors.boxShadow }} className={'mobile-dashboard'}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', marginBottom: 20, marginTop: 10 }}>
                                <div style={{ fontSize: 20 }}>{'Vendas por Cliente'}</div>
                                <Tooltip onClick={async () => { await this.getOrders(); await this.getUsers(); await this.clientSalesChart(); }} title="Atualizar"><IconButton><RefreshIcon style={{}} /></IconButton></Tooltip>
                            </div>

                            <Bar
                                height={window.screen.width < 1080 ? 250 : 80}
                                options={{ maintainAspectRatio: true, scales: { yAxes: [{ ticks: { beginAtZero: true, precision: 0 } }] } }}
                                data={this.state.clientSalesChart} />

                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', alignSelf: 'flex-end', marginTop: 20 }}>
                                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        style={{ width: '100%', padding: 15 }}
                                        invalidDateMessage={false}
                                        format={'dd/MM/yyyy'}
                                        autoOk={true}
                                        cancelLabel={'Cancelar'}
                                        okLabel={'Confirmar'}
                                        onChange={async (v) => { await this.setState({ clientSalesChart_start: v }); this.clientSalesChart(); }}
                                        value={this.state.clientSalesChart_start}
                                    />
                                </MuiPickersUtilsProvider>
                                <div style={{ color: 'grey' }}>{'até'}</div>
                                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        style={{ width: '100%', padding: 15 }}
                                        invalidDateMessage={false}
                                        format={'dd/MM/yyyy'}
                                        autoOk={true}
                                        cancelLabel={'Cancelar'}
                                        okLabel={'Confirmar'}
                                        onChange={async (v) => { await this.setState({ clientSalesChart_end: v }); this.clientSalesChart(); }}
                                        value={this.state.clientSalesChart_end}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        )
    }
}