import React, { Component } from 'react';
import Slider from '@material-ui/core/Slider';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Colors from '../constants/Colors';

const CustomSlider = withStyles({
    root: {
      color: Colors.primary,
      height: 8,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 8,
      borderRadius: 4,
    },
    rail: {
      height: 8,
      borderRadius: 4,
    },
  })(Slider);

export default class DefaultSlider extends Component {
    getStyle() {
        return {
            root: {
                color: Colors.primary,
                height: 8,
            },
            thumb: {
                height: 24,
                width: 24,
                backgroundColor: '#fff',
                border: '2px solid currentColor',
                marginTop: -8,
                marginLeft: -12,
                '&:focus, &:hover, &$active': {
                    boxShadow: 'inherit',
                },
            },
            active: {},
            valueLabel: {
                left: 'calc(-50% + 4px)',
            },
            track: {
                height: 8,
                borderRadius: 4,
            },
            rail: {
                height: 8,
                borderRadius: 4,
            },
            ...this.props.style
        }
    }

    render() {
        return <CustomSlider
            style={this.getStyle()}
            valueLabelDisplay={'auto'}
            aria-label={`custom-slider`}
            defaultValue={this.props.defaultValue}
            onChangeCommitted={this.props.onChangeCommitted}
            onChange={this.props.onChange}
            step={this.props.step || 1}
            max={this.props.max || 100}
            min={this.props.min || 0}
        />
    }
}