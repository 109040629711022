import React, { Component } from 'react';
import IosSwitch from '../IosSwitch';
import DefaultButton from '../DefaultButton';
import DefaultLoader from '../DefaultLoader';
import SessionHelper from '../../helper/SessionHelper';
import ReportHelper from '../../helper/ReportHelper';
import moment from "moment-timezone";
import CurrencyHelper from '../../helper/CurrencyHelper';
import Firestore from '../../api/firebase/Firestore';
import { toast } from 'react-toastify';
import ReportDefaultModalOptions from './ReportDefaultModalOptions';
import Pagarme from '../../api/pagarme/Pagarme';

export default class PaidStudentSubscriptionReport extends Component {

    state = {
        name: `Matrículas Pagas - ${SessionHelper.getData().company.name}`,
        loadingModal: true,
        teacherDocs: [],
        studentSubscriptionsDocs: [],
        studentDocs: [],
        paramDoc: {},
        teachers: [],
        studentPlanDocs: [],
        allTeachers: true,
        start: moment().startOf('month').startOf('day').toDate(),
        end: moment().endOf('month').endOf('day').toDate(),
        selectedTags: [],
        output: 'download',
        format: 'pdf',
    }

    async componentDidMount() {

        if (this.props.getDocs && this.props.getDocs) {

            for (let index = 0; index < this.props.getDocs.length; index++) {
                
                const getDoc = this.props.getDocs[index];
                await getDoc(this);
            }
        }

        this.setState({ loadingModal: false });
    }

    userHasTag(student, tags) {

        let result = false;

        if (student && student.tags) {

            student.tags.forEach((tag, key) => {

                if (tags.includes(tag)) {

                    result = true;
                    return;
                }
            });
        }

        return result;
    }

    getStudentTags(tags) {

        let result = [];

        if (tags && tags.length && this.state.paramDoc.tags && this.state.paramDoc.tags.length) {

            tags.forEach((value, key) => {

                let tag = this.state.paramDoc.tags.find(item => item.value === value);

                if (tag) {

                    result.push(tag.label);
                }
            });
        }

        return result.join(', ');
    }

    async getReportDocs() {

        let students = this.state.studentDocs;
        let docs = [];
        let total = 0;

        let query = await Firestore.customQuery('student_subscription').where('id_company', '==', SessionHelper.getData().id_company).orderBy('created_at', 'desc').get();

        for (let doc of query.docs) {
         
            let studentSubscription = { ...doc.data(), id: doc.id };
            let userDoc = students.find(item => item.id === studentSubscription.id_user);
            let planDoc = this.state.studentPlanDocs.find(item => item.id === studentSubscription.id_plan);

            if (!this.state.selectedTags.length || (this.state.selectedTags.length && this.userHasTag(userDoc, this.state.selectedTags))) {
                    
                // if (studentSubscription.status === 'paid') {

                    let response = await Pagarme.get(`/subscriptions/${studentSubscription.id_subscription_pagarme}/transactions`, {});

                    if (!response.errors) {

                        let count = 1;

                        for (let transaction of response) {
                            
                            if (transaction.status === 'paid' || (studentSubscription.settled_charges && studentSubscription.settled_charges[count])) {

                                let paymentDate = transaction.status === 'paid' || transaction.payment_method === 'credit_card' ? moment(transaction.date_updated) : moment(transaction.boleto_expiration_date);
                                let paymentDateSettled = null;

                                if (studentSubscription.settled_charges && studentSubscription.settled_charges[count]) {

                                    paymentDateSettled = moment(studentSubscription.settled_charges[count].date.toDate());
                                }

                                if ((paymentDate.isSameOrAfter(moment(this.state.start)) && paymentDate.isSameOrBefore(this.state.end)) || (studentSubscription.settled_charges && studentSubscription.settled_charges[count] && paymentDateSettled.isSameOrAfter(moment(this.state.start)) && paymentDateSettled.isSameOrBefore(this.state.end))) {
                                    
                                    let reportDoc = {};
                                    reportDoc.name = userDoc ? userDoc.name : '';
                                    reportDoc.tags = userDoc ? userDoc.tags : '';
                                    reportDoc.amount = transaction.paid_amount;
                                    reportDoc.payment_date = moment(transaction.date_updated).toDate();

                                    reportDoc.plan = planDoc.name;
    
                                    if (studentSubscription.settled_charges && studentSubscription.settled_charges[count]) {
                                        
                                        reportDoc.amount = studentSubscription.settled_charges[count].amount_paid;
                                        reportDoc.payment_date = studentSubscription.settled_charges[count].date.toDate()
                                    }

                                    docs.push(reportDoc);
                                    total += reportDoc.amount;
                                }
                            }

                            count++;
                        }
                    }
                // }
            }
        }

        docs.sort((a, b) => {

            return a.name.toLowerCase() > b.name.toLowerCase();
        });

        return { docs: docs, totalizer: total };
    }

    addThree(report, column) {

        report.doc.setLineWidth(0.6);
        report.doc.line(column.width - 0.3, report.offset + 4, 18, report.offset + 4);
        report.doc.line(column.width, report.offset + 4, column.width, report.offset + 2);
        report.doc.setLineWidth(0.2);
    }

    async print() {

        try {

            this.setState({ loadingModal: true });

            let docs = await this.getReportDocs();
            let report = await ReportHelper.createReport(this.state.name, 5, `De ${moment(this.state.start).format('DD/MM/YYYY')} até ${moment(this.state.end).format('DD/MM/YYYY')}`, this.state.format, 'l');

            report = await ReportHelper.createColumns(report, docs.docs, [
                { name: 'Aluno', width: 13, getText: (doc) => doc.name ? this.state.format === 'pdf' ? ReportHelper.cutName(doc.name, 18) : doc.name : '' },
                { name: 'Tags', width: 53, getText: (doc) => this.state.format === 'pdf' ? ReportHelper.cutName(this.getStudentTags(doc.tags), 18) : this.getStudentTags(doc.tags) },
                { name: 'Data', width: 95, getText: (doc) => moment(doc.payment_date).format('DD/MM/YYYY HH:mm') },
                { name: 'Plano', width: 127, getText: (doc) => this.state.format === 'pdf' ? ReportHelper.cutName(doc.plan, 63) : doc.plan },
                { name: 'Valor', width: 260, getText: (doc) => CurrencyHelper.centsToMoney(doc.amount) },
            ]);

            report = await ReportHelper.createColumns(report, [{ count: docs.docs.length, total: docs.totalizer }], [
                { name: 'Total de Alunos', width: 13, getText: (doc) => doc.count.toString() },
                { name: 'Total Recebido', width: 230, getText: (doc) => CurrencyHelper.centsToMoney(doc.total) },
            ], null, false, 12);
    
            ReportHelper.print(report, this.state.output, this.state.format);

            this.setState({ loadingModal: false });    

        } catch (error) {

            this.setState({ loadingModal: false });
            toast.error('Houve um problema ao imprimir o relatório')
        }

    }

    renderLoading() {

        return (

            <div style={{ height: 200 }}>
                <DefaultLoader/>
            </div>
        )
    }

    renderModal() {

        return (
            <div>
                { ReportHelper.renderPeriodSelection(this, 'start', 'end', 'dd/MM/yyyy') }
                { ReportHelper.renderTagSelection(this, this.state.paramDoc.tags || [], 'selectedTags') }
                <ReportDefaultModalOptions format={this.state.format} output={this.state.output} onOutputChange={(value) => { this.setState({ output: value }) }} onFormatChange={(value) => { this.setState({ format: value }) }}/>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <DefaultButton width={'100%'} title={'Imprimir'} onClick={() => { this.print() }} />
                </div>
            </div>
        )
    }

    render() {

        return (

            this.state.loadingModal ? this.renderLoading() : this.renderModal()
        )
    }
}