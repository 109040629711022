import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button, Tooltip } from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Firestore from '../api/firebase/Firestore';
import moment from "moment-timezone";
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import AddIcon from '@material-ui/icons/Add';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import HeaderActionsWrapper from '../components/HeaderActionsWrapper';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import CurrencyInput from '../components/CurrencyInput';
import CurrencyHelper from '../helper/CurrencyHelper';
import DefaultSelect from '../components/DefaultSelect';
import TextEditorTranslation from '../constants/TextEditorTranslation';
import MembershipTermsHelper from '../helper/MembershipTermsHelper';

export default class MembershipTermsPage extends Component {

    state = {
        docs: [],
        planDocs: [],
        loading: true,
        loadingModal: false,
        editModal: false,
        edit: null,
        editId: null,
        deleteModal: false,
        deleteId: null,
        addModal: false,
        editor: EditorState.createEmpty(),
        editorHtml: '',
        name: '',
        tax: null,
        rawTax: null,
        plans: [],
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('membership_terms').where('id_company', '==', SessionHelper.getData().id_company).get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs });
    }

    async getStudentPlans() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('student_plan').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ planDocs: docs });
    }

    async componentDidMount() {

        await this.getDocs();
        await this.getStudentPlans();

        this.setState({ loading: false });
    }

    editItem(doc) {

        const blocksFromHtml = htmlToDraft(doc.content);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);

        this.setState({ editModal: true, edit: doc, editId: doc.id, name: doc.name, editor: editorState, editorHtml: doc.content, tax: doc.tax / 100, rawTax: doc.tax / 100, plans: doc.plans || [] })
    }

    onChange = (value) => { this.setState({editor: value, editorHtml: value.toString('html')}) };

    async addMembershipTerm() {

        if (this.state.editor && this.state.name) {

            let data = {
                name: this.state.name,
                content: this.state.editorHtml,
                tax: this.state.rawTax * 100,
                plans: this.state.plans,
                id_company: SessionHelper.getData().id_company,
                date: new Date(),
            };

            try {

                this.setState({ loadingModal: true });

                await Firestore.insert(data, 'membership_terms');

                toast.success("Cadastrado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.setState({ loadingModal: false, addModal: false, editor: EditorState.createEmpty(), editorHtml: '', tax: null, rawTax: null, plans: [] });
                await this.getDocs();

                this.setState({ loading: false });

            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }
        } else {

            toast.warn("Preencha todos os campos", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    async editMembershipTerm() {

        if (this.state.editor && this.state.name && this.state.editId) {

            let data = {
                name: this.state.name,
                content: this.state.editorHtml,
                tax: this.state.rawTax * 100,
                plans: this.state.plans,
            };

            try {

                this.setState({ loadingModal: true });

                await Firestore.update(data, 'membership_terms', this.state.editId);

                toast.success("Editado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.setState({ loadingModal: false, editModal: false, editor: EditorState.createEmpty(), editorHtml: '', name: '', editId: null, tax: null, rawTax: null, plans: [] });
                await this.getDocs();

                this.setState({ loading: false });

            } catch (error) {
                toast.error("Houve um problema ao editar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }
        } else {

            toast.warn("Preencha todos os campos", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    getPlansTooltip(plans) {

        let tooltip = [];

        if (plans && plans.length) {

            plans.forEach((plan, key) => {

                let doc = this.state.planDocs.find(item => item.id === plan);

                if (doc) {

                    tooltip.push(doc.name);
                }
            });
        }

        return tooltip.join(', ');
    }

    renderVariables() {

        return (

            <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', fontWeight: 'bold', alignItems: 'center', marginBottom: 25, padding: 15, boxShadow: Colors.boxShadow, borderRadius: 5, border: '0.5px solid lightgrey' }}>
                <InfoRoundedIcon style={{ fontSize: 35, marginRight: 8 }} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {`Variáveis Disponíveis:`}
                    <div style={{ fontWeight: 'normal' }}>
                        {
                            Object.keys(MembershipTermsHelper.getVariables({ term: {}, company: {}, user: {}, plan: {}, expirationDate: '' })).map(key => {

                                return `[${key}]`;

                            }).join(', ')
                        }.
                    </div>
                </div>
            </div>
        )
    }

    addModal() {
        return (
            <div>
                <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} onChange={(v) => { this.setState({ name: v }) }} label={'Nome'}/>
                <CurrencyInput required onError={(error, msg) => { this.setState({ inputError: error }); }} label={`Valor da Multa`} onChange={(values) => { const { formattedValue, value } = values; this.setState({ tax: formattedValue, rawTax: value }) }} value={this.state.tax} />
                <div style={{ marginTop: 10 }}/>
                <DefaultSelect search={true} multiple={true} searchField={'name'} id={'plan_selection'} valueField={'id'} displayField={'name'} value={this.state.plans} onChange={(v) => { this.setState({ plans: v.target.value }) }} docs={this.state.planDocs} label={'Planos Vinculados'} />
                <h2>{'Conteúdo'}</h2>
                <div style={{ marginTop: 20 }}>
                    { this.renderVariables() }
                    <Editor localization={{ translations: TextEditorTranslation }} editorStyle={{ minHeight: 500, border: '1px solid #F1F1F1' }} onEditorStateChange={(editor) => this.setState({ editor, editorHtml: draftToHtml(convertToRaw(editor.getCurrentContent())) })} />
                </div>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.addMembershipTerm() }} style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ addModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    editModal() {

        if (this.state.edit) {

            return (
                <div>
                    <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} value={this.state.name} onChange={(v) => { this.setState({ name: v }) }} label={'Nome'}/>
                    <CurrencyInput required onError={(error, msg) => { this.setState({ inputError: error }); }} label={`Valor da Multa`} onChange={(values) => { const { formattedValue, value } = values; this.setState({ tax: formattedValue, rawTax: value }) }} value={this.state.tax} />
                    <div style={{ marginTop: 10 }}/>
                    <DefaultSelect search={true} multiple={true} searchField={'name'} id={'plan_selection'} valueField={'id'} displayField={'name'} value={this.state.plans} onChange={(v) => { this.setState({ plans: v.target.value }) }} docs={this.state.planDocs} label={'Planos Vinculados'} />
                    <h2>{'Conteúdo'}</h2>
                    <div style={{ marginTop: 20 }}>
                        { this.renderVariables() }
                        <Editor editorState={this.state.editor} editorStyle={{ minHeight: 500, border: '1px solid #F1F1F1' }} onEditorStateChange={(editor) => this.setState({ editor, editorHtml: draftToHtml(convertToRaw(editor.getCurrentContent())) })} />
                    </div>
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.editMembershipTerm() }} style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ editModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <HeaderActionsWrapper style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon/>} onClick={() => { this.setState({ addModal: true, editor: EditorState.createEmpty(), editorHtml: '', name: '', tax: null, rawTax: null, plans: [] }) }} title={'Adicionar'}/>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        
                    </div>
                </HeaderActionsWrapper>  
                
                <DefaultTable
                title={'Contratos'}
                marginTop={10}
                actions={[
                    {
                        tooltip: 'Editar Contrato',
                        icon: InsertDriveFileIcon,
                        onClick: (event, rowData) => { this.editItem(rowData) },
                    }
                ]}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Nome', field: 'name' },
                    { title: 'Valor da Multa', field: 'tax', render: (rowData) => <div>{CurrencyHelper.centsToMoney(rowData.tax ? rowData.tax : 0)}</div> },
                    { title: 'Planos Vinculados', field: 'plans', render: (rowData) => <Tooltip title={this.getPlansTooltip(rowData.plans)}><div>{rowData.plans ? rowData.plans.length + ' Planos' : 'Nenhum plano selecionado'}</div></Tooltip> },
                    { title: 'Data Criação', editable: false, field: 'date', render: rowData => moment(rowData.date.toDate()).format('DD/MM/YYYY HH:mm') },
                ]}
                data={this.state.docs}
                onRowDelete={ async (oldData) => {

                    let prev = this.state.docs;
                    prev.splice(prev.indexOf(oldData), 1);
                    
                    this.setState({ docs: prev });

                    if (oldData.id) {
                        await Firestore.delete('membership_terms', oldData.id);
                    }

                    toast.success("Removido com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                />

                <DefaultModal width={1200} loading={this.state.loadingModal} content={this.addModal()} title={'Novo Contrato'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
                <DefaultModal width={1200} loading={this.state.loadingModal} content={this.editModal()} title={'Editar Contrato'} onClose={() => { this.setState({ editModal: false }) }} open={this.state.editModal}/>

            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
