export default {
    error: '#d74d4e',
    success: '#59a63d',
    secondaryButton: '#d5d5d5',
    dark: '#22262E',
    formLabel: `rgba(0, 0, 0, 0.54)`,
    danger: '#fcac00',
    primary: '#0f77e8',
    aceYellow: '#ffed00',
    contrast: '#0099e5',
    disabled: '#999999',
    secondary: 'white',
    background: '#FAFAFA',
    selected: '#FFFFFF',
    notSelected: '#b4bcc8',
    boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px',
    pickerColors: ['#333333', '#999999', '#bd3500', '#db0000', '#fcac00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#5300EB', '#EB9694', '#8f3c3b'],
    altPickerColors: ['#333333', '#999999', '#bd3500', '#db0000', '#fcac00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#5300EB', '#EB9694'],
    categoryColors: ['#db0000', '#fcac00', '#008B02', '#006B76', '#1273DE'],
    order: {
        product: '#0f77e8',
        tournament_ticket: 'orange',
        ranking_ticket: '#d86843',
        court_rent: '#3b9c3e',
        private_lesson: '#f04141',
        experimental_lesson: 'purple',
        private_lesson_pack: 'grey',
        court_rent_pack: 'grey',
        ticket: '#c9c614',
        day_use: '#5300EB',
    }
}