import { FormLabel } from '@material-ui/core';
import Info from '@material-ui/icons/Info';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import DefaultInput from './DefaultInput';

export default class RankingWeightSelector extends Component {
    state = {
        weights: {
            win: 100,
            loss: 0,
            wo_winner: 25,
            wo_loser: -25
        },
        invalid: false,
        error: {
            win: false,
            loss: false,
            tiebreak: false,
            game: false,
            wo_winner: false,
            wo_loser: false
        }
    }

    async componentDidMount() {
        if (this.props.weights) {
            this.setState({ weights: this.props.weights }, () => { if (this.props.callback) this.props.callback(this.state); });
        }

        if (this.props.callback && !this.props.weights) this.props.callback(this.state);
    }

    handleChange(field, value) {
        let invalid = false;

        if (isNaN(parseInt(value))) {
            invalid = "Valor do peso deve ser númérico";
            this.setState({ error: { ...this.state.error, [field]: true } });
        } else {
            if (this.state.error[field]) {
                this.setState({ error: { ...this.state.error, [field]: false } });
            }
        }

        this.setState({ weights: { ...this.state.weights, [field]: value }, invalid }, () => { if (this.props.callback) this.props.callback(this.state); });
    }

    getHelperText(field) {
        if (this.state.error[field]) {
            return this.state.invalid;
        }

        let text;

        switch (field) {
            case 'win':
                text = 'Pontuação que um jogador recebe quando ganha uma partida';
                break;
            case 'loss':
                text = 'Pontuação que um jogador recebe quando perde uma partida (0 para não retirar ou adicionar pontos, ou use números negativos para retirar pontos, Ex: -50)';
                break;
            case 'wo_winner':
                text = 'Pontuação que um jogador recebe quando é o ganhador do W.O.';
                break;
            case 'wo_loser':
                text = 'Pontuação que um jogador recebe quando é o perdedor do W.O. (0 para não retirar ou adicionar pontos, ou use números negativos para retirar pontos, Ex: -50)';
                break;
            default:
                text = '';
                break;
        }

        return text;
    }

    renderEdit() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <FormLabel style={{ marginBottom: 20, marginTop: 20 }}>Pesos das partidas:</FormLabel>

                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20 }}>
                    <DefaultInput value={this.state.weights.win} type={'number'} error={this.state.error.win} helperText={this.getHelperText('win')} onChange={(v) => { this.handleChange('win', v); }} label={'Vitória'} />
                    <DefaultInput value={this.state.weights.loss} type={'number'} error={this.state.error.loss} helperText={this.getHelperText('loss')} onChange={(v) => { this.handleChange('loss', v); }} label={'Derrota'} />
                    <DefaultInput value={this.state.weights.wo_winner} type={'number'} error={this.state.error.wo_winner} helperText={this.getHelperText('wo_winner')} onChange={(v) => { this.handleChange('wo_winner', v); }} label={'Vitória por W.O.'} />
                    <DefaultInput value={this.state.weights.wo_loser} type={'number'} error={this.state.error.wo_loser} helperText={this.getHelperText('wo_loser')} onChange={(v) => { this.handleChange('wo_loser', v); }} label={'Derrota por W.O.'} />
                    <div style={{ fontWeight: 'bold', display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'orange', paddingBottom: 15 }}><Info style={{ marginRight: 10 }} />{'Pesos só podem ser definidos agora, no momento do cadastro, depois não serão os mesmos até o fim do ranking.'}</div>
                </div>
            </div>
        );
    }

    renderReadOnly() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <DefaultInput disabled={true} value={this.state.weights.win} type={'number'} error={this.state.error.win} helperText={this.getHelperText('win')} onChange={(v) => { this.handleChange('win', v); }} label={'Vitória'} />
                <DefaultInput disabled={true} value={this.state.weights.loss} type={'number'} error={this.state.error.loss} helperText={this.getHelperText('loss')} onChange={(v) => { this.handleChange('loss', v); }} label={'Derrota'} />
                <DefaultInput disabled={true} value={this.state.weights.wo_winner} type={'number'} error={this.state.error.wo_winner} helperText={this.getHelperText('wo_winner')} onChange={(v) => { this.handleChange('wo_winner', v); }} label={'Vitória por W.O.'} />
                <DefaultInput disabled={true} value={this.state.weights.wo_loser} type={'number'} error={this.state.error.wo_loser} helperText={this.getHelperText('wo_loser')} onChange={(v) => { this.handleChange('wo_loser', v); }} label={'Derrota por W.O.'} />
                <div style={{ fontWeight: 'bold', display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'orange', paddingBottom: 15 }}><Info style={{ marginRight: 10 }} />{'Pesos só podem ser configurados no momento do cadastro, não podem ser alterados depois que o ranking está gerado.'}</div>
            </div>
        );
    }

    render() {
        return this.props.weights ? this.renderReadOnly() : this.renderEdit();
    }
}