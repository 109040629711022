import React, { Component } from 'react';
import { IconButton, Badge, Menu, MenuItem } from "@material-ui/core";
import ClipLoader from "react-spinners/ClipLoader";
import Colors from '../constants/Colors';
import CheckIcon from "@material-ui/icons/Check";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import SessionHelper from '../helper/SessionHelper';

export default class CompanyTask extends Component {
    
    state = {
        tasks: [],
        badgeCount: 0,
    }

    componentDidMount() {
        
        this.getTasks();
    }

    getTasks() {
        
        let company = SessionHelper.getData().company;

        let tasks = [];
        let badgeCount = 0;
        
        Object.keys(company.tasks).forEach((task, key) => {

            tasks.push(company.tasks[task]);

            if (company.tasks[task].completed === false) {
                badgeCount ++;
            }
        });

        tasks = tasks.sort((a, b) => {

            return a.order > b.order;
        });

        this.setState({ tasks: tasks, badgeCount: badgeCount });
    }

    async toggleMenu() {

        let menu = !this.state.menuOpen;

        this.setState({ menuOpen: menu });
    }

    renderNotifications() {

        return this.state.tasks.map((notification, key) => {

            let color = Colors.success;

            return (
                <MenuItem onClick={() => { window.location.href = notification.redirect }} style={{ display: 'flex', flexDirection: 'row', padding: 0, paddingLeft: 12, paddingRight: 12, paddingTop: key === 0 ? 12 : 0, alignItems: 'flex-start' }}>
                    
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <CheckIcon style={{ border: `3px solid ${notification.completed ? color : 'lightgrey'}`, color: notification.completed ? color : 'lightgrey', borderRadius: '100%', padding: 5, fontSize: 45, marginRight: 10 }}/>
                        <div style={{ borderLeft: this.state.tasks[key + 1] ? `3px dashed ${notification.completed ? Colors.success : 'lightgrey'}` : '', height: 30, paddingRight: 10 }}/>
                    </div>
                    
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <div style={{ fontWeight: 'bold' }}>

                            { notification.title }

                        </div>
                        <div style={{ fontSize: 12 }}>{notification.body}</div>
                    </div>

                </MenuItem>
            )
        });
    }

    renderLoading() {

        return (
            <ClipLoader
            size={25}
            color={'lightgrey'}
            loading={true}
            />
        )
    }

    renderHeader() {
        return (
            <MenuItem style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid lightgrey', fontSize: 12 }}>
                <div style={{ fontWeight: 'bold', color: 'black', fontSize: 13 }}>{'Configuração Inicial'}</div>
            </MenuItem>
        )
    }

    render() {

        return (
            <div style={{ display: 'flex' }}>
                <IconButton color="inherit" aria-controls={'primary-search-account-menu'} aria-haspopup="true" onClick={() => { this.toggleMenu() }}>
                    <Badge badgeContent={this.state.badgeCount} color="secondary">
                        <EmojiObjectsIcon style={{ color: '#FFFFFF' }}/>
                    </Badge>
                </IconButton>

                <Menu
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={'primary-search-account-menu'}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={this.state.menuOpen}
                style={{marginTop: 40, height: 500}}
                onClose={() => { this.toggleMenu() }} >
                    
                    { this.renderHeader() }
                    { this.renderNotifications() }

                </Menu>

            </div>
        )

    }
}
