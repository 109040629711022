import React, { Component } from 'react';
import IosSwitch from '../IosSwitch';
import DefaultButton from '../DefaultButton';
import DefaultLoader from '../DefaultLoader';
import SessionHelper from '../../helper/SessionHelper';
import ReportHelper from '../../helper/ReportHelper';
import moment from "moment-timezone";
import Firestore from '../../api/firebase/Firestore';
import { toast } from 'react-toastify';
import { FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import Colors from '../../constants/Colors';
import CurrencyHelper from '../../helper/CurrencyHelper';
import PaymentMethodHelper from '../../helper/PaymentMethodHelper';
import ReportDefaultModalOptions from './ReportDefaultModalOptions';
import Order from '../../constants/Order';

export default class SalesInPeriodReport extends Component {

    state = {
        loadingModal: true,
        serviceDocs: [],
        productDocs: [],
        salesUserDocs: [],
        adminDocs: [],
        locationDocs: [],
        services: [],
        products: [],
        admins: [],
        paymentMethods: [],
        saleUsers: [],
        locations: [],
        allPaymentMethods: true,
        allSalesUsers: true,
        allServices: true,
        allProducts: true,
        allAdmins: true,
        allLocations: true,
        start: moment().startOf('month').startOf('day').toDate(),
        end: moment().endOf('day').toDate(),
        orderItems: false,
        output: 'download',
        format: 'pdf',
        allStatuses: true,
        statuses: [],
    }

    async componentDidMount() {

        if (this.props.getDocs && this.props.getDocs) {

            for (let index = 0; index < this.props.getDocs.length; index++) {
                
                const getDoc = this.props.getDocs[index];
                await getDoc(this);
            }
        }

        this.setState({ loadingModal: false });
    }

    async getReportDocs() {

        let start = moment(this.state.start).startOf('day').toDate();
        let end = moment(this.state.end).endOf('day').toDate();
        let query = await Firestore.customQuery('order').where('id_company', '==', SessionHelper.getData().id_company).where('date', '>=', start).where('date', '<=', end).where('type', 'in', ['product']).orderBy('date', 'desc').get();

        let productDocs = [ ...this.state.productDocs, ...this.state.serviceDocs ];
        let products = this.state.allProducts ? this.state.productDocs.map((product) => { return product.id }) : this.state.products;
        let services = this.state.allServices ? this.state.serviceDocs.map((service) => { return service.id }) : this.state.services;
        let saleUsers = this.state.allSalesUsers ? this.state.salesUserDocs.map((user) => { return user.id }) : this.state.saleUsers;
        let admins = this.state.allAdmins ? this.state.adminDocs.map((admin) => { return admin.id }) : this.state.admins;
        let locations = this.state.allLocations ? this.state.locationDocs.map((location) => { return location.id }) : this.state.locations;
        let statuses = this.state.allStatuses ? Order.statusTypes.map((status) => { return status.value }) : this.state.statuses;

        let orders = [];
        let totalAmount = 0;
        let count = 0;
        let docs = [];

        query.forEach((orderDoc, key) => {

            orders.push({ ...orderDoc.data(), id: orderDoc.id });
        });

        for (let clientId of saleUsers) {

            let client = this.state.salesUserDocs.find(item => item.id === clientId);
            let doc = {
                client: client,
                total_payed: 0,
                orders: [],
            };

            for (let order of orders) {
        
                if (order.id_user === clientId) {

                    let event = null;
    
                    if (order.payment_method === 'credit-card') { order.payment_method = 'credit_card' };
                    
                    let orderStatus = '';
                    let orderStatusLabel = '';
        
                    if (order.canceled && !order.refunded) {
        
                        orderStatus = 'canceled';
                        orderStatusLabel = 'Cancelado';
        
                    } else if (order.canceled && order.refunded) {
        
                        orderStatus = 'refunded';
                        orderStatusLabel = 'Estornado';
                    
                    } else if (order.status === 'paid') {
        
                        orderStatus = 'paid';
                        orderStatusLabel = 'Pago';
                    
                    } else if (order.status === 'waiting_payment') {
        
                        orderStatus = 'waiting_payment';
                        orderStatusLabel = 'Aguardando';
                    }
        
                    if ((this.state.allStatuses || statuses.includes(orderStatus)) && (this.state.allAdmins || admins.includes(order.created_by)) && (this.state.allSalesUsers || saleUsers.includes(order.id_user)) && (this.state.allPaymentMethods || this.state.paymentMethods.includes(order.payment_method))) {
        
                        let orderItems = order.items;
                        let items = [...products, ...services];
                        let user = this.state.adminDocs.find(item => item.id === order.created_by);
        
                        let findProduct = false;
                        let findLocation = this.state.allLocations ? true : false;
                        
                        if (orderItems && orderItems.length) {
        
                            orderItems.forEach((item, key) => {
                                
                                if ((!findProduct && item.id && items.findIndex(product => product === item.id) >= 0) || (!findProduct && item.id_event && this.state.allProducts && this.state.allServices )) {
        
                                    findProduct = true;
                                }
        
                                if (!findLocation && item.id) {
        
                                    locations.forEach((location, key) => {
        
                                        let product = productDocs.find(product => product.id === item.id);
                                        
                                        if (product.id_location === location) {
            
                                            findLocation = true;
                                        }
                                    });
                                }
                            });
                        }
        
                        if (findProduct && findLocation) {

                            doc.total_payed += order.amount;
                            totalAmount += order.amount;
                            count ++;
        
                            doc.orders.push({
                                order,
                                order_items: orderItems,
                                client: client,
                                user: user,
                                status: orderStatusLabel,
                                event,
                            });
                        }
                    }
                }
            }

            if (doc.orders.length) {

                doc.orders.sort((a, b) => {

                    if (!a.order.date) { return false };

                    return b.order.date.toDate() > a.order.date.toDate();
                });

                docs.push(doc);
            }
        }

        return { docs: docs, totalizer: [{ count: count, total_amount: totalAmount }] };
    }

    getColor(report, doc) {

        if (doc.order.payment_method === 'money') {

            if (doc.order.finished && !doc.order.refunded && !doc.order.canceled) {

                report.doc.setTextColor(89, 166, 61);
            
            } else if (doc.order.finished && doc.order.refunded) {

                report.doc.setTextColor(156, 64, 255);

            } else if (doc.order.canceled) {

                report.doc.setTextColor(156, 64, 255);

            } else {

                report.doc.setTextColor(89, 166, 61);
            }

        } else {

            if (doc.order.status === 'paid' || !doc.order.status) {

                if (doc.order.refunded) {
    
                    report.doc.setTextColor(156, 64, 255);
    
                } else if (doc.order.canceled) {

                    report.doc.setTextColor(156, 64, 255);

                } else {

                    report.doc.setTextColor(89, 166, 61);
                }
            
            } else if (doc.order.status === 'waiting_payment' && !doc.order.canceled && !doc.order.refunded) {
                
                report.doc.setTextColor(240, 144, 0);

            } else if (doc.order.canceled && !doc.order.refunded) {

                report.doc.setTextColor(156, 64, 255);

            } else {

                report.doc.setTextColor(156, 64, 255);
            }
        }
    }

    addThree(report, column) {

        report.doc.setLineWidth(0.6);
        report.doc.line(column.width - 0.3, report.offset + 4, 18, report.offset + 4);
        report.doc.line(column.width, report.offset + 4, column.width, report.offset + 2);
        report.doc.setLineWidth(0.2);
    }

    async print() {

        if (this.state.start && this.state.end) {

            this.setState({ loadingModal: true });

            let name = `Vendas da Loja - ${SessionHelper.getData().company.name}`;
            let subtitle = `De ${moment(this.state.start).format('DD/MM/YYYY')} até ${moment(this.state.end).format('DD/MM/YYYY')}`;
            let docs = await this.getReportDocs();
            let report = await ReportHelper.createReport(name, 5, subtitle, this.state.format);

            let createOrderItemsColumn = async () => { };

            if (this.state.orderItems) {

                createOrderItemsColumn =  async (report, actualDoc) => {

                    if (actualDoc.order_items && actualDoc.order_items.length) {

                        report = await ReportHelper.createColumns(report, actualDoc.order_items, [
                            { name: 'Produto', width: 26, getText: (doc) => this.state.format === 'pdf' ? ReportHelper.cutName(doc.title, 46) : doc.title },
                            { name: 'Quantidade', width: 115, getText: (doc) => doc.quantity.toString() },
                            { name: 'Valor Unitário', width: 170, getText: (doc) => CurrencyHelper.centsToMoney(doc.unit_price) },
                            { name: '', width: 0, getText: (doc) => '' },
                        ], null, false, 6, [237, 237, 237]);
    
                        return report;
                    }
                }
            }

            let createOrderColumn = async () => { };

            createOrderColumn =  async (report, actualDoc) => {

                if (actualDoc.orders && actualDoc.orders.length) {

                    report = await ReportHelper.createColumns(report, actualDoc.orders, [
                        { name: '', width: 14, getText: (doc) => '', customColumnHeader: (report, column) => this.addThree(report, column) },
                        { name: 'Pedido', width: 19, getText: (doc) => doc.order.title.split(' ')[1] },
                        { name: 'Valor', width: 36, getText: (doc) => `${CurrencyHelper.centsToMoney(doc.order.amount)}` },
                        { name: 'Status', width: 60, getColor: (report, doc) => this.getColor(report, doc), getText: (doc) => doc.status.split(' ')[0] },
                        { name: 'Pagamento', width: 90, getText: (doc) => PaymentMethodHelper.getPaymentMethodLabel(doc.order.payment_method, true) },
                        { name: 'Emissão', width: 130, getText: (doc) => moment(doc.order.date.toDate()).format('DD/MM/YYYY HH:mm') },
                    ], (report, actualDoc) => createOrderItemsColumn(report, actualDoc), false, 6, [237, 237, 237]);

                    return report;
                }
            }

            report = await ReportHelper.createColumns(report, docs.docs, [
                { name: 'Cliente', width: 13, getText: (doc) => `${doc.client.name}` },
                { name: 'Vendas', width: 115, getText: (doc) => doc.orders.length.toString() },
                { name: 'Total Recebido', width: 170, getText: (doc) => CurrencyHelper.centsToMoney(doc.total_payed) },
            ], (report, actualDoc) => createOrderColumn(report, actualDoc));
    
            report = await ReportHelper.createColumns(report, docs.totalizer, [
                { name: 'Vendas', width: 115, getText: (doc) => doc.count.toString() },
                { name: 'Total Recebido', width: 170, getText: (doc) => CurrencyHelper.centsToMoney(doc.total_amount)},
                { name: '', width: 0, getText: (doc) => '' },
                { name: '', width: 0, getText: (doc) => '' },
            ], null, false, 6);

            ReportHelper.print(report, this.state.output, this.state.format);

            this.setState({ loadingModal: false });

        } else {

            toast.warn('Preencha o período do relatório');
        }
    }

    renderLoading() {

        return (

            <div style={{ height: 200 }}>
                <DefaultLoader/>
            </div>
        )
    }

    renderModal() {

        return (

            <div>
                <IosSwitch fullWidth label={'Todos os Produtos'} checked={this.state.allProducts} onChange={(e) => { this.setState({ allProducts: e, products: [] }) }} />
                { !this.state.allProducts ? ReportHelper.renderProductSelection(this, this.state.productDocs, 'products') : null }

                <IosSwitch fullWidth label={'Todos os Serviços'} checked={this.state.allServices} onChange={(e) => { this.setState({ allServices: e, services: [] }) }} />
                { !this.state.allServices ? ReportHelper.renderServiceSelection(this, this.state.serviceDocs, 'services') : null }

                <IosSwitch fullWidth label={'Todos os Clientes'} checked={this.state.allSalesUsers} onChange={(e) => { this.setState({ allSalesUsers: e, saleUsers: [] }) }} />
                { !this.state.allSalesUsers ? ReportHelper.renderSalesUserSelection(this, this.state.salesUserDocs, 'saleUsers') : null }

                <IosSwitch fullWidth label={'Todos os Administradores'} checked={this.state.allAdmins} onChange={(e) => { this.setState({ allAdmins: e, admins: [] }) }} />
                { !this.state.allAdmins ? ReportHelper.renderAdminSelection(this, this.state.adminDocs, 'admins') : null }

                <IosSwitch fullWidth label={'Todos os Métodos de Pagamento'} checked={this.state.allPaymentMethods} onChange={(e) => { this.setState({ allPaymentMethods: e, paymentMethods: [] }) }} />
                { !this.state.allPaymentMethods ? ReportHelper.renderPaymentMethodSelection(this, 'paymentMethods', 'order') : null }

                <IosSwitch fullWidth label={'Todos os Status'} checked={this.state.allStatuses} onChange={(e) => { this.setState({ allStatuses: e, statuses: [] }) }} />
                { !this.state.allStatuses ? ReportHelper.renderStatusSelection(this, 'statuses') : null }

                <IosSwitch fullWidth label={'Todos os Locais'} checked={this.state.allLocations} onChange={(e) => { this.setState({ allLocations: e, locations: [] }) }} />
                { !this.state.allLocations ? ReportHelper.renderLocationSelection(this, this.state.locationDocs, 'locations') : null }

                <IosSwitch fullWidth label={'Exibir Itens da Venda'} checked={this.state.orderItems} onChange={(e) => { this.setState({ orderItems: e }) }} />

                <div style={{ marginTop: 15 }}/>

                { ReportHelper.renderPeriodSelection(this, 'start', 'end') }

                <ReportDefaultModalOptions format={this.state.format} output={this.state.output} onOutputChange={(value) => { this.setState({ output: value }) }} onFormatChange={(value) => { this.setState({ format: value }) }}/>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <DefaultButton width={'100%'} title={'Imprimir'} onClick={() => { this.print() }} />
                </div>
            </div>
        )
    }

    render() {

        return (

            this.state.loadingModal ? this.renderLoading() : this.renderModal()
        )
    }
}