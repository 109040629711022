import React, { Component } from 'react';
import Colors from '../constants/Colors';
import moment from "moment-timezone";
import { Button, FormLabel, TextareaAutosize } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import DefaultLoader from './DefaultLoader';
import DefaultButton from './DefaultButton';
import CloseIcon from '@material-ui/icons/Close';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import firebase from 'firebase/app';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CheckIcon from '@material-ui/icons/Check';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PeopleIcon from '@material-ui/icons/People';
import TerrainIcon from '@material-ui/icons/Terrain';
import ImageIcon from '@material-ui/icons/Image';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import CurrencyHelper from '../helper/CurrencyHelper';
import DefaultModal from '../components/DefaultModal';
import IosSwitch from '../components/IosSwitch';
import { toast } from 'react-toastify';
import EmojiEvents from '@material-ui/icons/EmojiEvents';
import NotificationHelper from '../helper/NotificationHelper';
import SessionHelper from '../helper/SessionHelper';
import StudentSubscriptionStatus from './StudentSubscriptionStatus';
import WhatsApp from '@material-ui/icons/WhatsApp';
import RepeatIcon from '@material-ui/icons/Repeat';
import PaymentMethodHelper from '../helper/PaymentMethodHelper';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Brightness4 from '@material-ui/icons/Brightness4';
import TournamentHelper from '../helper/TournamentHelper';
import TicketCard from './TicketCard';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AddIcon from '@material-ui/icons/Add';
import AddSale from './AddSale';
import ActiveLessonReplacements from './ActiveLessonReplacements';
import Computer from '@material-ui/icons/Computer';
import PhoneIphone from '@material-ui/icons/PhoneIphone';
import SportsVolleyballIcon from '@material-ui/icons/SportsVolleyball';
import Person from '@material-ui/icons/Person';
import RelationField from './grid/RelationField';

export default class CalendarComponent extends Component {

    state = {
        loading: true,
        loadingModal: false,
        updateOnFutureLessons: false,
        court: null,
        courts: null,
        students: [],
        teacher: null,
        lesson: null,
        order: null,
        user_pack: null,
        delete: false,
        collection: null,
        tournament: null,
        unpaidSubscriptions: [],
        cancelText: ``,
        studentTickets: [],
        addSaleModal: false,
    }

    async componentDidMount() {

        await this.setState({ loading: true });

        await this.getCollection();
        await this.getEvent();

        if (this.props.event.id_teacher) {

            await this.getTeacher();
        }

        if (this.props.event.id_order) {

            await this.getOrder();
        }

        if (this.props.event.id_package) {

            await this.getPackage();
        }

        if (this.props.event.id_court) {

            await this.getCourt();
        }

        if (this.props.event.id_courts) {

            await this.getCourts();
        }

        if (this.props.event.id_tournament) {

            await this.getTournament();
        }

        if (this.state.collection === 'lesson') {

            await this.getUnpaidSubscriptions();
        }

        if (!this.props.event.type || this.props.event.type === 'court_rent' || this.props.event.type === 'private_lesson') {

            await this.getStudentTickets();
        }

        await this.getStudents();

        await this.setState({ loading: false });
    }

    async getCollection() {

        let collection = 'lesson';
        let type = this.props.event.type;

        if (type === 'court_rent') {

            collection = 'court_rent';

        } else if (type === 'tournament') {

            collection = 'tournament_event';

        } else if (type === 'experimental_lesson') {

            collection = 'experimental_lesson';

        } else if (type === 'private_lesson') {

            collection = 'private_lesson';
        } else if (type === 'day_use') {

            collection = 'day_use';
        }

        this.setState({ collection });
    }

    async getUnpaidSubscriptions() {

        if (this.props.event.id_student[0]) {

            let query = await Firestore.customQuery('student_subscription').where('id_user', 'in', this.props.event.id_student).where('id_company', '==', SessionHelper.getData().id_company).where('status', '==', 'unpaid').orderBy('created_at', 'desc').get();
            let unpaidSubscriptions = [];
            let ids = [];

            query.forEach((doc, key) => {

                let subscription = { ...doc.data(), id: doc.id };

                if (!ids.includes(subscription.id_user)) {

                    unpaidSubscriptions.push(subscription.id_user);
                    ids.push(subscription.id_user);
                }

                this.setState({ unpaidSubscriptions });
            });
        }
    }

    async getStudentTickets() {
        let docs = [];

        if (this.props.event.id_student && this.props.event.id_student[0]) {
            let query = await Firestore.customQuery('order').where('end', '>=', new Date()).where('type', '==', 'ticket').where('id_user', 'in', this.props.event.id_student).orderBy('end', 'desc').get();

            query.forEach((doc, key) => {

                let data = { ...doc.data(), id: doc.id };

                docs.push(data);
            });
        } else if (this.props.event.manual_info && this.props.event.manual_info.length) {
            let query = await Firestore.customQuery('order').where('type', '==', 'ticket').where('event.id', '==', this.props.event.id).get();

            query.forEach((doc, key) => {
                let data = { ...doc.data(), id: doc.id };

                docs.push(data);
            });
        }

        this.setState({ studentTickets: docs });
    }

    async getTournament() {

        let query = await Firestore.getDoc('tournament', this.props.event.id_tournament);

        if (query.exists) {

            this.setState({ tournament: { ...query.data(), id: query.id } });
        }
    }

    async getCourt() {

        let query = await Firestore.getDoc('court', this.props.event.id_court);

        if (query.exists) {

            this.setState({ court: { ...query.data(), id: query.id } });
        }
    }

    async getCourts() {

        let query = await Firestore.customQuery('court').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc').get();

        if (query.size) {

            let courts = [];

            query.forEach(court => {
                if (this.props.event.id_courts.includes(court.id)) {
                    courts.push({ ...court.data(), id: court.id });
                }
            });

            this.setState({ courts });
        }
    }

    async getOrder() {

        let query = await Firestore.getDoc('order', this.props.event.id_order);

        if (query.exists) {

            this.setState({ order: { ...query.data(), id: query.id } });
        }
    }

    async getPackage() {

        let query = await Firestore.getDoc('user_pack', this.props.event.id_package);

        if (query.exists) {

            this.setState({ user_pack: { ...query.data(), id: query.id } });
        }
    }

    async getTeacher() {

        let query = await Firestore.getDoc('user', this.props.event.id_teacher);

        if (query.exists) {

            this.setState({ teacher: { ...query.data(), id: query.id } });
        }
    }

    async getEvent() {

        let query = await Firestore.getDoc(this.state.collection, this.props.event.id);

        if (query.exists) {

            this.setState({ lesson: { ...query.data(), id: query.id } });
        }
    }

    async getStudents() {

        if (this.props.event.id_student && this.props.event.id_student[0]) {

            let query = await Firestore.customQuery('user').where(firebase.firestore.FieldPath.documentId(), 'in', this.props.event.id_student).get();
            let docs = [];

            query.forEach((doc, key) => {

                let data = { ...doc.data(), id: doc.id };

                docs.push(data);
            });

            this.setState({ students: docs });
        }
    }

    async delete() {

        if (this.state.loadingModal == true) {
            toast.warn('Aguarde, realizando ação...');
            return;
        }

        let collection = this.props.event.type ? this.props.event.type : 'lesson';

        if (this.props.event.id) {

            try {

                this.setState({ loadingModal: true });

                let doc = this.props.event.id;

                if (!this.props.event.type) {
                    try {
                        if (moment(this.props.event.start).isSameOrAfter(moment())) {
                            await this.props.notifyCanceledLesson(this.props.instance, doc, this.state.cancelText);

                            if (this.props.event.id_student && this.props.event.id_student[0] && this.state.students.length) {
                                for (let user of this.state.students) {
                                    let refused = this.state.lesson.refused || [];

                                    if (!refused.includes(user.id)) {
                                        let companyData = user.companies[SessionHelper.getData().id_company];

                                        if (!companyData.replacement_lesson) companyData.replacement_lesson = 1;

                                        await this.props.addUserReplacementLesson(user.id, companyData.replacement_lesson, true);
                                    }
                                }
                            }

                            toast.success('Alunos dessa aula, que estavam com o status aceito ou pendente, receberam +1 reposição cada, automaticamente.', { autoClose: false });
                            this.props.instance.getStudents();
                        }

                        if (this.props.event.id_replacement_lesson) {
                            try {
                                await Firestore.delete('replacement_lesson', this.props.event.id_replacement_lesson);
                            } catch (e) {
                                console.error('Id de aula de reposição não existe');
                            }
                        }

                    } catch (e) {

                        toast.success('Não foi possível aumentar o contador de reposição dos alunos da aula.');
                    }
                }

                if (this.props.event.type == 'court_rent') {
                    if (moment(this.props.event.start).isSameOrAfter(moment())) {
                        await this.props.notifyCanceledCourtRent(this.props.instance, doc, this.state.cancelText);

                        if (this.props.event.id_package && this.state.user_pack) {
                            try {
                                await this.getPackage();

                                let pack = this.state.user_pack;

                                let creditsSpent = 0;

                                if (!this.state.lesson.pack_credits_paid) {
                                    creditsSpent = this.state.lesson.playerCount;
                                } else {
                                    creditsSpent = this.state.lesson.pack_credits_paid;
                                }

                                pack.remaining_rents += parseInt(creditsSpent);

                                await Firestore.update({ remaining_rents: pack.remaining_rents, active: true }, 'user_pack', this.props.event.id_package);

                                toast.success(`${creditsSpent} créditos devolvidos ao usuário`);
                            } catch (e) {
                                toast.warn(`Não foi possível devolver créditos do pacote ao usuário`);
                            }
                        }
                    }
                }

                if (this.props.event.type == 'private_lesson') {
                    if (moment(this.props.event.start).isSameOrAfter(moment())) {
                        await this.props.notifyCanceledPrivateLesson(this.props.instance, doc, this.state.cancelText);

                        if (this.props.event.id_package && this.state.user_pack) {
                            try {
                                await this.getPackage();

                                let pack = this.state.user_pack;

                                pack.remaining_lessons += 1;

                                await Firestore.update({ remaining_lessons: pack.remaining_lessons, active: true }, 'user_pack', this.props.event.id_package);

                                toast.success(`1 crédito devolvido ao usuário`);
                            } catch (e) {
                                toast.warn(`Não foi possível devolver créditos do pacote ao usuário`);
                            }
                        }
                    }
                }

                if (this.props.event.type == 'day_use') {
                    if (moment(this.props.event.start).isSameOrAfter(moment()) && this.props.event.participants.length < 1) {
                        try {
                            await Firestore.update({ active: false }, collection, this.props.event.id);

                            toast.success(`Cancelado com sucesso!`);

                            await this.props.filterEvents(this.props.instance);
                        } catch (e) {
                            toast.warn(`Há participantes vinculados a esse day use, não é possível cancelar. Cancele os pedidos deste day use para poder cancelar.`);
                        }

                        return;
                    }
                }

                if (this.props.event.type == 'experimental_lesson') if (moment(this.props.event.start).isSameOrAfter(moment())) await this.props.notifyCanceledExperimentalLesson(this.props.instance, doc, this.state.cancelText);

                if (this.props.event.id_parent_event && this.state.updateOnFutureLessons && (collection === 'lesson' || collection === 'court_rent')) {

                    let referenceStart = this.props.event.start;
                    let query = await Firestore.customQuery(collection).where('id_parent_event', '==', this.props.event.id_parent_event).where('start', '>=', moment(referenceStart).toDate()).orderBy('start', 'desc').get();

                    if (query.docs.length > 0) {
                        for (let i = 0; i < query.docs.length; i++) {

                            const editId = query.docs[i].id;
                            await Firestore.delete(collection, editId);
                        }

                        if (this.props.event.id_student) {

                            this.props.event.id_student.forEach((student, key) => {

                                let userDoc = this.state.students.find(item => item.id === student);

                                if (userDoc && userDoc.notification_token && userDoc.notification_token.data) {

                                    let token = userDoc.notification_token.data;
                                    let notification = {
                                        icon: 'calendar-times',
                                        color: 'red',
                                        navigate: 'Notification'
                                    };

                                    if (collection === 'lesson') {

                                        NotificationHelper.send('Aulas Canceladas', `${query.docs.length} aulas futuras foram canceladas. ${this.state.cancelText ? `Motivo: ${this.state.cancelText}` : ``}`, notification, token, student);

                                    } else {

                                        NotificationHelper.send('Aluguéis Cancelados', `${query.docs.length} aluguéis futuros foram cancelados. ${this.state.cancelText ? `Motivo: ${this.state.cancelText}` : ``}`, notification, token, student);
                                    }
                                }
                            });
                        }

                        if (this.props.event.id_teacher) {

                            let teacherDoc = this.state.teacher;

                            if (teacherDoc && teacherDoc.notification_token && teacherDoc.notification_token.data) {

                                let token = teacherDoc.notification_token.data;
                                let notification = {
                                    icon: 'calendar-times',
                                    color: 'red',
                                    navigate: 'Notification'
                                };

                                NotificationHelper.send('Aula Canceladas', `${query.docs.length} aulas futuras foram canceladas, abra o app e confira.`, notification, token, this.props.event.id_teacher);
                            }
                        }
                    }
                }

                //TODO: testar cancelamentos para ver se está ok
                if (this.state.lesson.linked_rents) {
                    for (let id of this.state.lesson.linked_rents) {
                        await Firestore.delete(collection, id);
                    }
                } else {
                    await Firestore.delete(collection, this.props.event.id);
                }

                await this.props.filterEvents(this.props.instance);

                toast.success('Evento cancelado com sucesso');

                this.setState({ delete: false, loadingModal: false, updateOnFutureLessons: false, cancelText: `` });

            } catch (error) {
                console.log(error);
                this.setState({ delete: false });
                toast.error('Erro ao cancelar evento');
            }
        }
    }

    renderLessonStatus(status) {

        if (status === 'confirmed') {

            return <CheckIcon style={{ marginRight: 3, fontSize: 20, width: 30, height: 30, borderRadius: '100%', position: 'absolute', right: 0, top: 8, padding: 3, color: 'white', backgroundColor: Colors.success }} />

        } else if (status === 'refused') {

            return <CloseIcon style={{ marginRight: 3, fontSize: 20, width: 30, height: 30, borderRadius: '100%', position: 'absolute', right: 0, top: 8, padding: 3, color: 'white', backgroundColor: Colors.error }} />

        } else {

            return <div style={{ marginRight: 3, fontSize: 20, width: 30, height: 30, borderRadius: '100%', position: 'absolute', right: 0, top: 8, padding: 3, color: 'white', backgroundColor: Colors.danger, display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold' }}>?</div>
        }
    }

    renderUnpaidSubscription() {

        return (
            <div style={{ backgroundColor: Colors.error, width: 20, height: 20, borderRadius: 20 / 2, padding: 3, marginLeft: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <AttachMoneyIcon style={{ fontSize: 16, color: 'white' }} />
            </div>
        );
    }

    renderStudents() {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'scroll' }}>

                {this.props.event.id_student && this.state.students.length ?

                    this.state.students.map((user, key) => {

                        let isReplacement = false;
                        let status = null;
                        let color = '';

                        if (this.state.collection === 'lesson' && this.state.lesson) {

                            status = this.state.lesson.accepted && this.state.lesson.accepted.includes(user.id) ? 'confirmed' : this.state.lesson.refused && this.state.lesson.refused.includes(user.id) ? 'refused' : 'pending';
                            color = status === 'confirmed' ? Colors.success : status === 'refused' ? Colors.error : Colors.danger;
                            isReplacement = this.state.lesson.replacement_lesson_students && this.state.lesson.replacement_lesson_students.includes(user.id) ? true : false;
                        }

                        return (
                            <div style={{ marginRight: 35 }}>
                                <div style={{ position: 'relative', height: 125, width: 125, borderRadius: 125 / 2 }}>
                                    <img src={user.photo || process.env.PUBLIC_URL + '/empty_avatar.png'} style={{ height: 125, width: 125, borderRadius: 125 / 2, border: status ? `6px solid ${color}` : '' }} />
                                    {status ? this.renderLessonStatus(status) : null}
                                </div>
                                <div style={{ marginTop: 8, fontWeight: 'bold', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingBottom: 20 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        {user.name.length > 15 ? user.name.slice(0, 15) + '...' : user.name}

                                        {user.phone ?

                                            <div onClick={() => { window.open(`https://wa.me/${user.phone.replace(/[^\d]/g, '')}`, '_blank') }} style={{ cursor: 'pointer', boxShadow: Colors.boxShadow, backgroundColor: '#25D366', display: 'flex', padding: 3, width: 30, height: 30, borderRadius: 30 / 2, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginLeft: 8 }}>
                                                <WhatsApp style={{ color: 'white', fontSize: 18 }} /></div>

                                            : null}

                                    </div>
                                    <div style={{ marginTop: 12 }}><StudentSubscriptionStatus user={user.id} /></div>

                                    {isReplacement ?

                                        <div style={{ marginTop: 12 }}>
                                            <b style={{ backgroundColor: Colors.success, color: 'white', paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8, borderRadius: 5 }}>{'Reposição'}</b>
                                        </div>

                                        : null}

                                </div>
                            </div>
                        )
                    })

                    : <p>{'Não Informado'}</p>}

            </div>
        )
    }

    renderExternalStudents() {

        return (
            <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'scroll' }}>

                {this.state.lesson.name ?

                    <div style={{ marginRight: 35 }}>
                        <img src={process.env.PUBLIC_URL + '/empty_avatar.png'} style={{ height: 125, width: 125, borderRadius: 125 / 2, boxShadow: Colors.boxShadow }} />
                        <div style={{ marginTop: 8, fontWeight: 'bold', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            {this.state.lesson.name > 15 ? this.state.lesson.name.slice(0, 15) + '...' : this.state.lesson.name}
                        </div>
                    </div>

                    : this.props.event.manual_info && this.props.event.manual_info.name ?
                        <div style={{ marginRight: 35 }}>
                            <img src={process.env.PUBLIC_URL + '/empty_avatar.png'} style={{ height: 125, width: 125, borderRadius: 125 / 2, boxShadow: Colors.boxShadow }} />
                            <div style={{ marginTop: 8, fontWeight: 'bold', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                {this.props.event.manual_info.name > 15 ? this.props.event.manual_info.name.slice(0, 15) + '...' : this.props.event.manual_info.name}
                                {this.props.event.manual_info.phone ?

                                    <div onClick={() => { window.open(`https://wa.me/55${this.props.event.manual_info.phone.replace(/[^\d]/g, '')}`, '_blank') }} style={{ cursor: 'pointer', boxShadow: Colors.boxShadow, backgroundColor: '#25D366', display: 'flex', padding: 3, width: 30, height: 30, borderRadius: 30 / 2, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginLeft: 8 }}>
                                        <WhatsApp style={{ color: 'white', fontSize: 18 }} /></div>
                                    : null}
                            </div>

                        </div>
                        : this.props.event.manual_info && this.props.event.manual_info[0] && this.props.event.manual_info[0].name ?
                            this.props.event.manual_info.map((user, key) => (
                                <div style={{ marginRight: 35 }}>
                                    <img src={process.env.PUBLIC_URL + '/empty_avatar.png'} style={{ height: 125, width: 125, borderRadius: 125 / 2, boxShadow: Colors.boxShadow }} />
                                    <div style={{ marginTop: 8, fontWeight: 'bold', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                        {user.name > 15 ? user.name.slice(0, 15) + '...' : user.name}
                                        {user.phone ?
                                            <div onClick={() => { window.open(`https://wa.me/55${user.phone.replace(/[^\d]/g, '')}`, '_blank') }} style={{ cursor: 'pointer', boxShadow: Colors.boxShadow, backgroundColor: '#25D366', display: 'flex', padding: 3, width: 30, height: 30, borderRadius: 30 / 2, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginLeft: 8 }}>
                                                <WhatsApp style={{ color: 'white', fontSize: 18 }} /></div>
                                            : null}
                                    </div>

                                </div>
                            ))
                            :
                            <p>{'Não Informado'}</p>}

            </div>
        )
    }

    renderTeacher() {

        let user = this.state.teacher;

        if (user) {

            return (
                <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'scroll' }}>
                    <div style={{ marginRight: 35 }}>
                        <img src={user.photo || process.env.PUBLIC_URL + '/empty_avatar.png'} style={{ height: 125, width: 125, borderRadius: 125 / 2, boxShadow: Colors.boxShadow }} />
                        <div style={{ marginTop: 8, fontWeight: 'bold', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            {user.name.length > 15 ? user.name.slice(0, 15) + '...' : user.name}
                        </div>
                    </div>
                </div>
            )
        }
    }

    getCourtTypeLookup() {
        return {
            'clay': 'Saibro',
            'hard': 'Dura',
            'grass': 'Grama',
            'artificial_grass': 'Grama Artificial',
            'synthetic_resin': 'Resina Sintética',
            'sand': 'Areia',
            'porous_concrete': 'Concreto Poroso',
            'others': 'Outras',
        }
    }

    getSportLookup() {
        return {
            'tennis': 'Tennis',
            'padel': 'Padel',
            'beach_tennis': 'Beach Tennis',
            'squash': 'Squash',
            'badminton': 'Badminton',
            'beach_volley': 'Vôlei de Praia',
            'foot_volley': 'Futevôlei',
            'others': 'Outros',
        }
    }

    renderCourt(court) {
        if (!court) court = this.state.court;

        if (court) {

            return (
                <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'scroll', marginBottom: 15 }}>
                    <div style={{ marginRight: 35, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: 200 }}>
                        {court.images && court.images.length ? <img src={court.images[0]} style={{ height: 125, width: 125, borderRadius: 125 / 2, boxShadow: Colors.boxShadow }} /> : <ImageIcon style={{ height: 125, width: 125, color: 'lightgrey', padding: 25, border: '1px solid lightgrey', borderRadius: 125 / 2, boxShadow: Colors.boxShadow }} />}
                        <div style={{ marginTop: 8, fontWeight: 'bold' }}>
                            {court.name}
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <div style={{ color: 'white', backgroundColor: 'orange', marginTop: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: 5, paddingRight: 15, borderRadius: 5, marginLeft: 5 }}>
                            <InfoOutlinedIcon style={{ marginRight: 3 }} />{court.indoor ? 'Interna' : 'Externa'}
                        </div>
                        <div style={{ color: 'white', backgroundColor: Colors.success, marginTop: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: 5, paddingRight: 15, borderRadius: 5, marginLeft: 5 }}>
                            <TerrainIcon style={{ marginRight: 3 }} />{this.getCourtTypeLookup()[court.type]}
                        </div>
                        <div style={{ color: 'white', backgroundColor: Colors.primary, marginTop: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: 5, paddingRight: 15, borderRadius: 5, marginLeft: 5 }}>
                            <SportsTennisIcon style={{ marginRight: 3 }} />{this.getSportLookup()[court.sport]}
                        </div>
                    </div>
                </div>
            )

        } else if (this.props.event.type === 'tournament' && this.props.event.court) {

            return (
                <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'scroll' }}>
                    <div style={{ marginRight: 35, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: 200 }}>
                        <ImageIcon style={{ height: 100, width: 100, color: 'lightgrey', padding: 25, border: '1px solid lightgrey', borderRadius: 100 / 2, boxShadow: Colors.boxShadow }} />
                        <div style={{ marginTop: 8, fontWeight: 'bold' }}>
                            {this.props.event.court}
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                    </div>
                </div>
            )
        }
    }

    renderTags() {

        return (
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 8 }}>
                {
                    this.props.event.tags.map((tag, key) => {

                        return <div style={{ marginRight: 5, backgroundColor: tag.color || Colors.primary, paddingTop: 3, paddingBottom: 3, paddingLeft: 8, paddingRight: 8, fontWeight: 'bold', borderRadius: 5, color: 'white' }}>{tag.label}</div>
                    })
                }
            </div>
        )
    }

    renderData() {

        return (

            <div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <AccessTimeIcon style={{ marginRight: 8, color: Colors.primary }} />
                    <p>{`Dia ${moment(this.props.event.start).format('DD/MM/YYYY')} das ${moment(this.props.event.start).format('HH:mm')} até ${moment(this.props.event.end).format('HH:mm')}`}</p>
                </div>

                {this.props.event.category_label ?

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <PeopleIcon style={{ marginRight: 8, color: Colors.primary }} />
                        <p>{this.props.event.category_label}</p>
                    </div>

                    : null}

                {this.props.event.tags ? this.renderTags() : null}

                {this.state.lesson.playerCount ?

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <PeopleIcon style={{ marginRight: 8, color: Colors.primary }} />
                        <p>{`${this.state.lesson.playerCount} jogadores`}</p>
                    </div>

                    : null}

                {this.props.event.type === 'court_rent' && this.state.lesson.racquetCount && this.state.lesson.racquetCount > 0 ?

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <SportsTennisIcon style={{ marginRight: 8, color: Colors.primary }} />
                        <p>{`Quantidade de raquetes alugadas: ${this.state.lesson.racquetCount}`}</p>
                    </div>

                    : null}

                {this.state.lesson.participants ?

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <PeopleIcon style={{ marginRight: 8, color: Colors.primary }} />
                        <p>{`${this.state.lesson.participants.length || 0} inscritos`}</p>
                    </div>

                    : null}

                {this.state.lesson.sport ?

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <SportsVolleyballIcon style={{ marginRight: 8, color: Colors.primary }} />
                        <p>{`Esporte Selecionado: ${this.getSportLookup()[this.state.lesson.sport]}`}</p>
                    </div>

                    : null}

                {this.state.lesson.repeat_until ?

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <RepeatIcon style={{ marginRight: 8, color: Colors.primary }} />
                        <p>Repete-se uma vez por semana até {moment(this.state.lesson.repeat_until.toDate()).format('DD/MM/YYYY')}</p>
                    </div>

                    : null}

                {this.props.event.type === 'court_rent' ?

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        {this.props.event.id_order ? <PhoneIphone style={{ marginRight: 8, color: Colors.primary }} /> : <Computer style={{ marginRight: 8, color: Colors.primary }} />}
                        {this.props.event.id_order ? <p>Usuário agendou pelo App</p> : <p>{`Agendado manualmente`}</p>}
                    </div>

                    : null}

                {this.props.event.id_admin ? 
                
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Person style={{ marginRight: 8, color: Colors.primary }} />
                        <p><RelationField render={(title) => title ? `Agendado por ${title}` : null} collection={'user'} id={this.props.event.id_admin} field={'name'} loading={false} /></p>
                    </div>
                : null }

                {this.state.lesson.repeat_until && this.state.lesson.renewed_until ?

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <CheckIcon style={{ marginRight: 8, color: Colors.success }} />
                        <RepeatIcon style={{ marginRight: 8, color: Colors.success }} />
                        <p>Essa repetição foi renovada até {moment(this.state.lesson.renewed_until.toDate()).format('DD/MM/YYYY')}</p>
                    </div>

                    : null}

                {this.state.tournament ?

                    <div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                            <EmojiEvents style={{ marginRight: 8, color: Colors.primary }} />
                            <p>{this.state.tournament.name}</p>
                        </div>
                        <div style={{ marginTop: 5, borderTop: `1px solid lightgrey` }}>
                            <p style={{ flexDirection: 'row' }}><b>Categoria: </b>{`${this.state.lesson.indexes && this.state.lesson.indexes.category >= 0 ? this.state.tournament.category[this.state.lesson.indexes.category].title : `Desconhecida`}`}</p>
                            <p style={{ flexDirection: 'row' }}><b>Classe: </b>{`${this.state.lesson.indexes && this.state.lesson.indexes.class >= 0 ? this.state.tournament.category[this.state.lesson.indexes.category].classes[this.state.lesson.indexes.class].title : `Desconhecida`}`}</p>
                            <p style={{ flexDirection: 'row' }}><b>Rodada: </b>{`${this.state.lesson.indexes && this.state.lesson.indexes.round >= 0 ? parseInt(this.state.lesson.indexes.round) + 1 : `Desconhecida`}`}</p>

                            {this.state.lesson.indexes && this.state.lesson.indexes.category >= 0 &&
                                this.state.lesson.indexes.class >= 0 && this.state.lesson.indexes.match >= 0
                                && this.state.lesson.indexes.round >= 0 ?
                                <p onClick={(e) => { navigator.clipboard.writeText(TournamentHelper.getMatchAlias(this.state.lesson.indexes.gameAlias)); toast.info('ID copiado!'); e.stopPropagation(); }} style={{ cursor: 'pointer' }}><b>Identificador: </b>{`${TournamentHelper.getMatchAlias(this.state.lesson.indexes.gameAlias)}`}  <FileCopyIcon style={{ marginLeft: 4, marginTop: 4, fontSize: 16 }} /></p>
                                : null}
                        </div>
                    </div>


                    : null}

            </div>
        )
    }

    renderStatus() {
        let status = this.state.order.status, refunded = this.state.order.refunded, finished = this.state.order.finished, method = this.state.order.payment_method, canceled = this.state.order.canceled, type = this.state.order.type;
        let text = '';
        let color = '';

        if (method === 'money') {

            if (finished && !refunded && !canceled || (status === 'paid' && type === 'product' || type === 'ticket')) {

                text = 'Pago';
                color = Colors.success;

            } else if (finished && refunded) {

                text = 'Estornado';
                color = 'purple';

            } else if (canceled) {

                text = 'Cancelado';
                color = 'purple';

            } else {

                text = 'Aguardando Pagamento';
                color = 'orange';
            }

        } else {

            if (status === 'paid' || !status) {

                if (refunded) {

                    text = 'Estornado';
                    color = 'purple';

                } else if (canceled) {

                    text = 'Cancelado';
                    color = 'purple';

                } else {

                    text = 'Pago';
                    color = Colors.success;
                }

            } else if (status === 'waiting_payment' && !canceled && !refunded) {

                text = 'Aguardando Pagamento';
                color = 'orange';

            } else if (canceled && !refunded) {

                text = 'Cancelado';
                color = 'purple';

            } else {

                text = 'Estornado';
                color = 'purple';
            }
        }

        return <div style={{ fontWeight: 'bold', color: color, marginLeft: 8 }}>{text}</div>
    }

    renderOrderData() {
        if (this.state.order) {
            return (
                <div>
                    <p onClick={(e) => { navigator.clipboard.writeText(this.state.order.title); toast.info('Nº do pedido copiado!'); e.stopPropagation(); }} style={{ fontWeight: 'bold', cursor: 'pointer' }}>{`Número: ${this.state.order.title}`}  <FileCopyIcon style={{ marginLeft: 4, marginTop: 4, fontSize: 16 }} /></p>
                    <p style={{ fontWeight: 'bold', flexDirection: 'row', display: 'flex' }}>Status: {this.renderStatus()}</p>
                    <p style={{ fontWeight: 'bold' }}>{`Método de Pagamento: ${PaymentMethodHelper.getPaymentMethodLabel(this.state.order.payment_method)}`}</p>
                    <p style={{ fontWeight: 'bold', flexDirection: 'row' }}>{`Valor: ${CurrencyHelper.centsToMoney(this.state.order.amount)}`}</p>
                    <p style={{ fontWeight: 'bold' }}>{`Data: ${moment(this.state.order.date.toDate()).format('DD/MM/YYYY HH:mm')}`}</p>
                </div>
            )
        }
    }

    renderPackData() {
        let creditsSpent = 0;

        if (!this.state.lesson.pack_credits_paid) {
            creditsSpent = this.state.lesson.playerCount;
        } else {
            creditsSpent = this.state.lesson.pack_credits_paid;
        }

        if (this.state.user_pack) {

            return (

                <div>
                    <p style={{ fontWeight: 'bold' }}>{`Método de pagamento: Pacote de aluguél de quadra`}</p>
                    <p style={{ fontWeight: 'bold' }}>{`Créditos utilizados: ${creditsSpent} crédito(s)`}</p>
                    <p style={{ fontWeight: 'bold' }}>{`Número de horários alugados: ${this.state.lesson.linked_rents ? this.state.lesson.linked_rents.length : 1}`}</p>
                    <p style={{ fontWeight: 'bold' }}>{`Créditos restantes no pacote: ${this.state.user_pack.remaining_rents} crédito(s)`}</p>
                </div>
            )
        }
    }

    getDisableRepeatEvent() {
        let disable = true;

        if (this.props.event.repeat_until) {
            let startOfDay = moment(this.props.event.repeat_until.toDate()).startOf('day');
            let endOfDay = moment(this.props.event.repeat_until.toDate()).endOf('day');

            if (moment(this.props.event.start).isBetween(startOfDay, endOfDay, 'minutes', '[]') && !this.props.event.renewed_until) {
                disable = false;
            }
        }

        return disable;
    }

    getDisableAddSale() {

        let disable = true;

        if (!this.props.event.type || this.props.event.type === 'court_rent' || this.props.event.type === 'private_lesson') {

            disable = false;
        }

        return disable;
    }

    handleEdit() {

        if (!this.props.event.type) {

            this.props.editLesson(this.props.event);

        } else if (this.props.event.type && this.props.event.type == 'court_rent' && !this.props.event.id_order && !this.props.event.id_package) {

            this.props.editCourtRent(this.props.event);

        } else if (this.props.event.type && this.props.event.type == 'day_use') {

            this.props.editDayUse(this.props.event);

        }
    }

    canCancelEvent() {

        let flag = false;

        if (!this.props.event.type) {

            flag = true;

        } else if (this.props.event.type && this.props.event.deletable) {

            if (this.props.event.type == 'court_rent' || this.props.event.type == 'experimental_lesson' || this.props.event.type == 'day_use' || this.props.event.type == 'private_lesson') {

                flag = true;
            }
        }

        return !flag;
    }

    renderContent() {

        let canEdit = !this.props.event.type || (this.props.event.type && ((this.props.event.type == 'court_rent' && !this.props.event.id_order && !this.props.event.id_package) || this.props.event.type === 'day_use')) ? false : true;

        return (

            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingBottom: 50 }}>
                <div style={{ backgroundColor: this.props.event.color || '#36A2EB', paddingTop: 10, paddingBottom: 10, paddingLeft: 50, paddingRight: 50, color: 'white', fontWeight: 'bold', borderRadius: 5, boxShadow: Colors.boxShadow, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    {this.renderIcon()}
                    {this.renderTitle()}
                </div>

                <p style={{ marginTop: 25, fontWeight: 'bold', fontSize: 25 }}>{'Dados do Evento'}</p>
                {this.renderData()}

                {this.state.order ? <p style={{ marginTop: 25, fontWeight: 'bold', fontSize: 25 }}>{'Pedido'}</p> : null}
                {this.renderOrderData()}

                <div style={{ marginTop: 20, width: '100%', display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
                    <DefaultButton onClick={() => { this.handleEdit() }} disabled={canEdit} leftIcon={<EditIcon style={{ marginRight: 5 }} />} title={'Editar'} />
                    <div style={{ marginTop: 8 }} />
                    <DefaultButton color={Colors.error} onClick={() => { this.setState({ delete: true, updateOnFutureLessons: false }) }} disabled={this.canCancelEvent()} leftIcon={<CancelIcon style={{ marginRight: 5 }} />} title={'Cancelar'} />
                    <div style={{ marginTop: 8 }} />
                    <DefaultButton onClick={() => { this.props.repeatEvent(this.props.event) }} disabled={this.getDisableRepeatEvent()} color={Colors.success} leftIcon={<RepeatIcon style={{ marginRight: 5 }} />} title={'Renovar Repetições'} />
                    <div style={{ marginTop: 8 }} />
                    <DefaultButton onClick={() => { this.setState({ addSaleModal: true }) }} disabled={this.getDisableAddSale()} color={Colors.danger} leftIcon={<div style={{ display: 'flex', flexDirection: 'row' }}><ReceiptIcon /> <AddIcon style={{ fontSize: 16 }} /></div>} title={'Abrir Comanda'} />
                </div>

                {this.state.students.length ? this.renderStudents() : this.renderExternalStudents()}

                {!this.props.event.type ? <ActiveLessonReplacements id_lesson={this.props.event.id} paramDoc={this.props.paramDoc} setLessonHasReplacementLesson={this.props.setLessonHasReplacementLesson} removeReplacementLesson={this.props.removeReplacementLesson} /> : null}

                {this.state.teacher ? <p style={{ marginTop: 25, fontWeight: 'bold', fontSize: 25 }}>{'Professor'}</p> : null}
                {this.renderTeacher()}

                {this.props.event && this.props.event.id_court ?
                    <div>
                        <p style={{ marginTop: 25, fontWeight: 'bold', fontSize: 25 }}>{'Quadra'}</p>
                        {this.renderCourt()}
                    </div>
                    : null}

                {this.props.event && this.props.event.id_courts ?
                    <div>
                        <p style={{ marginTop: 25, fontWeight: 'bold', fontSize: 25 }}>{'Quadras'}</p>
                        <div>{this.state.courts.map(court => this.renderCourt(court))}</div>
                    </div>
                    : null}

                {!this.props.event.type || this.props.event.type === 'court_rent' || this.props.event.type === 'private_lesson' ? this.renderStudentTickets() : null}



                {this.state.user_pack ? <p style={{ marginTop: 25, fontWeight: 'bold', fontSize: 25 }}>{'Informações do Pacote'}</p> : null}
                {this.renderPackData()}

                <AddSale
                    onClose={() => { this.setState({ addSaleModal: false }) }}
                    open={this.state.addSaleModal}
                    usersDocs={this.state.students}
                    disableAddCustomClient={true}
                    allowedPaymentMethods={['ticket']}
                    onAdd={() => { this.getStudentTickets() }}
                    event={{ ...this.state.lesson, collection: this.state.collection, title: this.renderTitle() }}
                />
            </div>
        )
    }

    renderStudentTickets() {

        return (

            <div>
                <p style={{ marginTop: 25, fontWeight: 'bold', fontSize: 25 }}>{'Comandas em Aberto'}</p>
                {!this.state.studentTickets.length ? <p style={{ color: 'grey', marginLeft: 3 }}>{'Nenhuma comanda encontrada.'}</p> : null}

                <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'scroll' }}>
                    {
                        this.state.studentTickets.map((doc, key) => {

                            return (
                                <TicketCard
                                    width={220}
                                    doc={doc}
                                    usersDocs={this.state.students}
                                    adminUsers={this.state.adminUsers}
                                    event={{ ...this.state.lesson, collection: this.state.collection, title: this.renderTitle() }}
                                />
                            )
                        })
                    }
                </div>

            </div>
        )
    }

    renderTitle() {

        return this.props.event.type === 'tournament' ? 'Partida de Torneio' :
            this.props.event.type === 'court_rent' ? 'Aluguel de Quadra' :
                this.props.event.type === 'experimental_lesson' ? 'Aula Experimental' :
                    this.props.event.type === 'private_lesson' ? 'Aula Avulsa' :
                        this.props.event.type === 'day_use' ? 'Day Use' :
                            `Aula com ${this.props.event.teacher}`;
    }

    renderIcon() {

        let icon = null;

        if (this.props.event) {

            let type = this.props.event.type;

            if (type === 'court_rent') {

                icon = <AttachMoneyIcon style={{ marginRight: 10 }} />;

            } else if (type === 'experimental_lesson') {

                icon = <img src={process.env.PUBLIC_URL + '/icons/flask-grey.png'} style={{ height: 15, width: 18, marginRight: 8 }} />;

            } else if (type === 'private_lesson') {

                icon = <SportsTennisIcon style={{ marginRight: 10 }} />;

            } else if (type === 'day_use') {

                icon = <Brightness4 style={{ marginRight: 10 }} />;

            } else {

                icon = <SportsTennisIcon style={{ marginRight: 10 }} />;
            }
        }

        return icon;
    }

    deleteModal() {
        return (
            <div>
                {this.props.event.id_parent_event != null ? <IosSwitch fullWidth label={`Cancelar eventos futuros de ${moment(this.props.event.start).format('dddd HH:mm')}`} checked={this.state.updateOnFutureLessons} onChange={(v) => { this.setState({ updateOnFutureLessons: v }) }} /> : null}
                <FormLabel style={{ paddingBottom: 18, paddingTop: 18, fontSize: 13 }} component="legend">Motivo do Cancelamento</FormLabel>
                <TextareaAutosize maxLength={120} style={{ width: '100%', borderRadius: 5, borderColor: 'lightgrey', padding: 15, fontSize: '1rem' }} rowsMax={4} rowsMin={2} onBlur={(v) => { this.setState({ cancelText: v.target.value }) }} placeholder="Informe o motivo do cancelamento do evento em até 140 caracteres (Opcional)..." />
                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                    <DefaultButton onClick={() => { this.delete() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                    <DefaultButton onClick={() => { this.setState({ delete: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                </div>

            </div>
        )
    }

    render() {

        return (

            <SlidingPane
                className={'right-panel'}
                isOpen={this.props.panelOpen}
                hideHeader={true}
                closeIcon={<CloseIcon style={{ width: 30, marginTop: 5 }} />}
                width={window.screen.width < 1080 ? "80%" : "40%"}
                onRequestClose={() => this.props.close()}>

                {this.state.loading ? <DefaultLoader /> : this.renderContent()}
                <DefaultModal loading={this.state.loadingModal} content={this.deleteModal()} title={'Tem certeza que deseja cancelar esse evento?'} onClose={() => { this.setState({ delete: false }) }} open={this.state.delete} />
            </SlidingPane>
        )
    }
}