import React from "react";
export default class SessionHelper {
    
    constructor() {
        this.firebaseAuth = null;
        this.data = null;
        this._constants = {
            isDevelopment: false,
            isTestUser: false
        };
    }

    static setFirebaseAuth(auth) {
        this.firebaseAuth = auth;
    }

    static getFirebaseAuth() {
        return this.firebaseAuth;
    }

    static setData(data) {
        this.data = data;
    }

    static getData() {
        return this.data;
    }

    static setConstants(constants) {
        this._constants = constants;
    }

    static getConstants() {
        return this._constants;
    }

    static destroy() {
        this.firebaseAuth = null;
        this.data = null;

        return true;
    }

    static isDevelopmentMode() {
        return '_self' in React.createElement('div');
    }
}