import React, { Component } from 'react';
import { InputLabel, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Colors from '../constants/Colors';
import { toast } from 'react-toastify';
import Storage from '../api/firebase/Storage';

export default class ImagePicker extends Component {

    state = {
        images: this.props.images || [],
        photoBlob: null,
        hover: null,
        limit: this.props.limit ? this.props.limit : 6,
        sizeLimit: this.props.sizeLimit ? this.props.sizeLimit : 1,
    }

    async upload(files) {

        try {

            if (this.props.onUploadStart) {
                this.props.onUploadStart();
            }

            let filesArray = Array.from(files);
            let links = this.state.images;

            for (let index = 0; index < filesArray.length; index++) {
                
                let filesize = ((filesArray[index].size/1024)/1024).toFixed(4);

                if (filesize <= this.state.sizeLimit) {

                    let ref = await Storage.uploadFile(this.props.folder || '', filesArray[index]);
                    let downloadPath = await Storage.getFileFromRef(ref);
            
                    if (!downloadPath) {
                        throw new Error('upload error');
                    }
    
                    links.push(downloadPath);
        
                    toast.success("Imagem adicionada com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });   

                } else {

                    toast.warn(`O tamanho da imagem selecionada é maior que ${this.state.sizeLimit}Mb`, {
                        position: toast.POSITION.TOP_RIGHT
                    });  
                }
            }

            if (this.props.onUpload) {
                this.props.onUpload(links);
            }

            await this.setState({ images: links });

            if (this.props.onUploadEnd) {
                this.props.onUploadEnd();
            }

        } catch (error) {

            toast.error("Houve um problema ao salvar a imagem", {
                position: toast.POSITION.TOP_RIGHT
            });

            if (this.props.onUploadEnd) {
                this.props.onUploadEnd();
            }
        }
    }

    async delete(image, key) {

        if (this.state.images.includes(image)) {

            try {

                if (this.props.onUploadStart) {
                    this.props.onUploadStart();
                }

                await Storage.removeFile(image);

                let images = this.state.images;
                images.splice(key, 1);

                if (this.props.onUpload) {
                    this.props.onUpload(images);
                }
    
                await this.setState({ images: images });
    
                if (this.props.onUploadEnd) {
                    this.props.onUploadEnd();
                }

                toast.success("Imagem removida com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });  

            } catch (error) {

                toast.error("Houve um problema ao remover a imagem", {
                    position: toast.POSITION.TOP_RIGHT
                });
    
                if (this.props.onUploadEnd) {
                    this.props.onUploadEnd();
                }
            }
        }
    }

    toggleHover(image) {

        this.setState({ hover: image });
    }

    renderImages() {
        return this.state.images.map((image, key) => {
            return (

                <div key={`image_picker_image_${key}`} style={{ position: 'relative' }} onMouseOver={() => { this.toggleHover(image) }} onMouseLeave={() => { this.toggleHover(null) }}>
                    { this.state.hover === image && <Tooltip title={'Remover imagem'}><DeleteIcon onClick={() => { this.delete(image, key) }} style={{ color: Colors.primary, cursor: 'pointer', position: 'absolute', top: 0, left: 0, margin: 5, marginTop: 15, width: 50, height: 50, padding: 10, borderRadius: '100%', backgroundColor: 'white', boxShadow: Colors.boxShadow }} /></Tooltip> }
                    <img src={image} style={{ height: this.props.imageHeight ? this.props.imageHeight : 250, width: this.props.imageWidth ? this.props.imageWidth : 150, backgroundColor: '#ededed', borderRadius: 5, marginRight: 10, marginTop: 10 }}/>
                </div>
            )
        });
    }

    render() {

        return (
            <div>
                { this.props.label && <InputLabel>{this.props.label}</InputLabel> }
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20, flexWrap: 'wrap' }}>
                    { this.renderImages() }
                    <input
                    multiple={this.props.multiple}
                    accept="image/*"
                    style={{display: 'none'}}
                    id={this.props.id || "contained-button-file"}
                    type="file"
                    onChange={(v) => { this.upload(v.target.files) }}
                    />

                        { this.state.images.length < this.state.limit ?
                        
                        <label htmlFor={this.props.id || "contained-button-file"}>
                            <div style={{ height: this.props.imageHeight ? this.props.imageHeight : 250, width: this.props.imageWidth ? this.props.imageWidth : 150, backgroundColor: '#ededed', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', marginTop: 10 }}>
                                <AddIcon style={{ fontSize: 40, color: 'grey' }}/>
                            </div>
                        </label>

                        : null }

                </div>
            </div>
        )
    }
}