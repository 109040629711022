import React from 'react';
import Colors from '../constants/Colors';
import Firestore from '../api/firebase/Firestore';
import SessionHelper from '../helper/SessionHelper';
import ClipLoader from "react-spinners/ClipLoader";
import Pagarme from '../api/pagarme/Pagarme';
import moment from "moment-timezone";

export default class StudentSubscriptionStatus extends React.Component {

    state = {
        loading: false,
        status: null,
    }

    async componentDidMount() {

        await this.setState({ loading: true });
        await this.getSubscriptionStatus();

        this.setState({ loading: false });
    }

    async componentDidUpdate(prev) {

        if (this.props.user !== prev.user) {

            await this.setState({ loading: true });
            await this.getSubscriptionStatus();
    
            this.setState({ loading: false });
        }
    }

    async getSubscriptionStatus() {

        const statusPriority = [
            'pending_payment',
            'unpaid',
            'paid',
            'canceled',
            'ended',
        ];

        let query = await Firestore.customQuery('student_subscription').where('id_user', '==', this.props.user).where('id_company', '==', SessionHelper.getData().id_company).orderBy('created_at', 'desc').get();
        let status = '';
        let docs = [];

        if (query.size > 0) {

            for (let index = 0; index < query.docs.length; index++) {

                let doc = query.docs[index].data();
                docs.push(doc);
            };

            docs.sort((a, b) => {

                let statusA = a.status;
                let statusB = b.status;

                const firstIndex = statusPriority.indexOf(statusA);
                const secondIndex = statusPriority.indexOf(statusB);

                return firstIndex == -1 ? 1 : secondIndex == -1 ? -1 : firstIndex - secondIndex;
            });

            const doc = docs[0];
            status = doc.status;

            if (doc.status === 'unpaid') {
    
                let subscription = await Pagarme.get(`/subscriptions/${doc.id_subscription_pagarme}`, {});
    
                if (!subscription.errors) {

                    if (subscription.payment_method === 'boleto') {
                        
                        let expirationDate = subscription.current_transaction.boleto_expiration_date;
                        
                        if (expirationDate) {

                            expirationDate = moment(expirationDate).startOf('day');

                            if (moment().startOf('day').isSameOrBefore(expirationDate)) {

                                status = 'pending_payment';
                            }
                        }
                    }
                }
            }

            this.setState({ status: status });
        }
    }

    renderLoading() {

        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ClipLoader
                size={25}
                color={Colors.primary}
                loading={true}
                />
            </div>
        )
    }

    renderSubscriptionStatus(status) {

        let text;
        let color;

        if (status === 'paid') {

            text = 'Pagamento em dia';
            color = Colors.success;

        } else if (status === 'trialing') {

            text = 'Período Gratuíto';
            color = Colors.danger;

        } else if (status === 'canceled') {

            text = 'Cancelado';
            color = Colors.error;

        } else if (status === 'ended') {

            text = 'Encerrado';
            color = Colors.success;

        } else if (status === 'pending_payment') {

            text = 'Aguardando Pagamento';
            color = Colors.danger;

        } else if (status === 'unpaid') {

            text = 'Inadimplente';
            color = Colors.error;

        } else {

            text = 'Sem Matrícula';
            color = 'lightgrey';
        }

        return <b style={{ backgroundColor: color, color: 'white', paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8, borderRadius: 5 }}>{text}</b>
    }

    render() {

        return this.state.loading ? this.renderLoading() : (

            <div style={{ }}>
                {this.renderSubscriptionStatus(this.state.status)}
            </div>
        );
    }
}