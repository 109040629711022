import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import { Card, CardActions, CardContent, Button, Tooltip, FormControlLabel, Switch, Select, MenuItem, FormLabel, IconButton } from '@material-ui/core';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CheckIcon from '@material-ui/icons/Check';
import DefaultInput from '../components/DefaultInput';
import DefaultModal from '../components/DefaultModal';
import IosSwitch from '../components/IosSwitch';
import Colors from '../constants/Colors';
import Firestore from '../api/firebase/Firestore';
import SessionHelper from '../helper/SessionHelper';
import AddIcon from '@material-ui/icons/Add'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import DefaultSelect from '../components/DefaultSelect';
import BankList from 'bancos-brasileiros/bancos.json';
import { toast } from 'react-toastify';
import Pagarme from '../api/pagarme/Pagarme';
import CompanyTaskHelper from '../helper/CompanyTaskHelper';
import InputMask from "react-input-mask";

export default class PaymentAccountPage extends Component {

    state = {
        docs: [],
        loading: true,
        loadingModal: false,
        addModal: false,
        bank_code: null,
        agencia: '',
        conta: '',
        conta_dv: '',
        agencia_dv: '',
        document: SessionHelper.getData().company.type === 'pj' ? SessionHelper.getData().company.cnpj : SessionHelper.getData().company.cpf,
        owner_name: '',
        primary: true,
        type: 'conta_corrente',
    }

    componentDidMount() {

        this.getDocs();
    }

    async getDocs() {

        let query = await Firestore.customQuery('payment_account').where('id_company', '==', SessionHelper.getData().id_company).get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;
            data.hover = false;

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    toggleHover(key) {

        let docs = this.state.docs;
        docs[key].hover = !docs[key].hover;

        this.setState({ docs: docs });
    }

    async changeMainAccount(doc) {

        if (doc.primary) {

            toast.warn('Essa já é a sua conta principal');

        } else {

            let confirm = window.confirm('Tem certeza que deseja alterar a sua conta de saque?');

            if (confirm) {

                try {

                    this.setState({ loading: true });

                    let recipient = SessionHelper.getData().company.id_recipient_pagarme;

                    let body = {
                        bank_account_id: doc.id_bank_account_pagarme,
                    };

                    let response = await Pagarme.put(`/recipients/${recipient}`, body);

                    if (response.errors && response.errors.length) {
                        throw new Error('Account Error');
                    }

                    this.resetPrimaryAccount();

                    await Firestore.update({ primary: true }, 'payment_account', doc.id);

                    toast.success('Conta principal alterada com sucesso');

                    this.getDocs();
                    this.setState({ loading: false });

                } catch (error) {
                    this.setState({ loading: false });
                    toast.error('Houve um problema ao alterar a sua conta');
                }
            }
        }
    }
    
    renderIcons(doc) {

        return (
            <div>
                <Tooltip title={'Alterar para conta principal'}>
                    <SyncAltIcon onClick={() => { this.changeMainAccount(doc) }} style={{ cursor: 'pointer', color: 'grey' }}/>
                </Tooltip>
            </div>
        )
    }

    renderCards() {

        return this.state.docs.map((doc, key) => {

            let bank = BankList.find(item => item.COMPE === doc.bank_code);

            if (bank) {

                return (
                    <Card onMouseEnter={() => { this.toggleHover(key) }} onMouseLeave={() => { this.toggleHover(key) }} variant="outlined" style={{ width: '32%', height: 200, margin: 5, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 10 }}>
                        <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', height: 30 }}>
                                <div style={{ fontWeight: 'bold', fontSize: 18 }}>{`${bank.COMPE} - ${bank.ShortName}`}</div>
                                { this.state.docs[key].hover && this.renderIcons(doc) }
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'space-between', height: '100%', alignItems: 'flex-start', paddingTop: 25 }}>
                                <div style={{ width: '80%' }}>
                                    <div>{`Conta ${doc.conta}-${doc.conta_dv}`}</div>
                                    <div>{`Agência ${doc.agencia}${doc.agencia_dv ? `-${doc.agencia_dv}` : ''}`}</div>
                                    <div>{`${doc.owner_name} • ${doc.document.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1 $2 $3/$4-$5")}`}</div>
                                    { doc.primary && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontWeight: 'bold', color: 'green', paddingTop: 20 }}><CheckIcon style={{ marginRight: 5 }}/>Principal</div> }
                                </div>
                                <AccountBalanceIcon style={{ color: 'grey', fontSize: 60 }}/>
                            </div>
                        </CardContent>
                    </Card>
                )
            }
        });
    }

    renderEmpty() {
        return (
            <Card onClick={() => { this.setState({ addModal: true }) }} variant="outlined" style={{ width: '32%', height: 200, margin: 5, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 10, cursor: 'pointer' }}>
                <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    <AddIcon style={{ fontSize: 80, color: 'grey' }}/>
                    <div style={{ fontSize: 12, textAlign: 'center', color: 'grey' }}>{`Adicionar Nova Conta`}</div>
                </CardContent>
            </Card>
        )
    }

    async resetPrimaryAccount() {

        let docs = this.state.docs;

        docs.forEach((doc, key) => {

            if (doc.primary) { Firestore.update({ primary: false }, 'payment_account', doc.id) }
        });
    }

    async addAccount() {

        if (this.state.bank_code && this.state.conta && this.state.agencia && this.state.conta_dv && this.state.owner_name && this.state.document) {

            if (this.state.owner_name.length > 30) {

                toast.warn('O nome deve ter no máximo 30 caractéres');

            } else {

                try {

                    this.setState({ loadingModal: true });
    
                    let shouldReload = false;
                    let recipient = SessionHelper.getData().company.id_recipient_pagarme;
                    let bank = BankList.find(item => item.COMPE === this.state.bank_code);
    
                    let data = {
                        bank_code: this.state.bank_code,
                        name: bank.ShortName,
                        conta: this.state.conta.replace(/[^\d]/g, ''),
                        conta_dv: this.state.conta_dv.replace(/[^\d]/g, ''),
                        agencia: this.state.agencia.replace(/[^\d]/g, ''),
                        agencia_dv: this.state.agencia_dv.replace(/[^\d]/g, ''),
                        owner_name: this.state.owner_name,
                        document: this.state.document,
                        created_at: new Date(),
                        primary: this.state.primary,
                        type: this.state.type,
                        id_user: SessionHelper.getFirebaseAuth().uid,
                        id_company: SessionHelper.getData().id_company,
                    }
    
                    let bankCode = this.state.bank_code;
    
                    if (bankCode.length === 2) {
    
                        bankCode = `0${bankCode}`;
                    }
    
                    let body = {
                        bank_code: bankCode,
                        agencia: data.agencia,
                        conta: data.conta,
                        conta_dv: data.conta_dv,
                        type: data.type,
                        document_number: data.document,
                        legal_name: data.owner_name,
                    };
    
                    if (data.agencia_dv) {
                        body.agencia_dv = data.agencia_dv;
                    }
    
                    let response = await Pagarme.post('/bank_accounts', body);
    
                    if (response.errors && response.errors.length) {
    
                        throw new Error('Account Error');
                    }
    
                    data.id_bank_account_pagarme = response.id;
    
                    if (!recipient) {
    
                        let recipientBody = {
                            transfer_interval: 'monthly',
                            bank_account_id: data.id_bank_account_pagarme,
                            transfer_day: '3',
                            transfer_enabled: false,
                        };
    
                        let recipientResponse = await Pagarme.post(`/recipients`, recipientBody);
    
                        if (recipientResponse.errors && recipientResponse.errors.length) {
    
                            throw new Error('Account Error');
                        }
    
                        recipient = recipientResponse.id;
                        
                        await Firestore.update({ id_recipient_pagarme: recipient }, 'company', SessionHelper.getData().id_company);
    
                        shouldReload = true;
                    }
    
                    if (data.primary && data.id_bank_account_pagarme) {
    
                        this.resetPrimaryAccount();
    
                        let body = {
                            bank_account_id: data.id_bank_account_pagarme,
                        };
    
                        let response = await Pagarme.put(`/recipients/${recipient}`, body);
    
                        if (response.errors && response.errors.length) {
    
                            throw new Error('Account Error');
                        }
                    }
    
                    await Firestore.insert(data, 'payment_account');
    
                    this.setState({ loadingModal: false, bank_code: null, agencia: '', agencia_dv: '', conta: '', conta_dv: '', owner_name: '', addModal: false });
                    toast.success('Conta adicionada com sucesso');
    
                    if (!this.state.docs.length) {
    
                        CompanyTaskHelper.completeTask('first_payment_account', this.props.tasks);
                    }
    
                    if (shouldReload) {
    
                        window.location.reload();
    
                    } else {
    
                        this.getDocs();
                    }
    
                } catch (error) {
    
                    this.setState({ loadingModal: false, bank_code: null, agencia: '', conta: '', conta_dv: '', agencia_dv: '', owner_name: '' });
                    toast.error('Erro ao cadastrar conta');
                }
            }

        } else {

            toast.warn('Preencha todos os campos');
        }
    }

    renderDocument() {

        if (SessionHelper.getData().company.type === 'pj') {

            return <DefaultInput label={'Cnpj'} value={this.state.document.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1 $2 $3/$4-$5")} disabled={true}/>

        } else {

            return <DefaultInput label={'Cpf'} value={this.state.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")} disabled={true}/>
        }
    }

    addModal() {
        return (
            <div>
                <div style={{ marginTop: 10 }}>
                    <DefaultSelect search={true} searchField={'LongName'} id={'student_selection'} valueField={'COMPE'} displayField={'LongName'} secondaryDisplay={'COMPE'} onChange={(v) => { this.setState({ bank_code: v.target.value }) }} value={this.state.bank_code} docs={BankList} label={'Banco'}/>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                    <div style={{ width: '70%', paddingRight: 10 }}>
                        <InputMask onChange={(v) => { this.setState({ conta: v }) }} mask="9999999999999" maskPlaceholder={''} alwaysShowMask={false} value={this.state.conta}>
                            <DefaultInput required number onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Conta'}/>
                        </InputMask>
                    </div>
                    <div style={{ width: '30%' }}>
                        <InputMask onChange={(v) => { this.setState({ conta_dv: v }) }} mask="99" maskPlaceholder={''} alwaysShowMask={false} value={this.state.conta_dv}>
                            <DefaultInput required number onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Digito Verificador'}/>
                        </InputMask>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                    <div style={{ width: '50%', paddingRight: 10 }}>
                        <InputMask onChange={(v) => { this.setState({ agencia: v }) }} mask="9999" maskPlaceholder={''} alwaysShowMask={false} value={this.state.agencia}>
                            <DefaultInput required number onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Agência'}/>
                        </InputMask>
                    </div>
                    <div style={{ width: '50%' }}>
                        <InputMask onChange={(v) => { this.setState({ agencia_dv: v }) }} mask="*" maskPlaceholder={''} alwaysShowMask={false} value={this.state.agencia_dv}>
                            <DefaultInput placeholder={'Deixe vazio caso não tenha'} onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Digito Verificador da Agência'}/>
                        </InputMask>
                    </div>
                </div>

                <FormLabel style={{paddingTop: 10}} component="legend">Tipo da Conta</FormLabel>
                <Select
                    style={{width: '100%', marginBottom: 10, marginTop: 10}}
                    labelId="tipo"
                    value={this.state.type}
                    onChange={(v) => { this.setState({ type: v.target.value }) }}>
                        <MenuItem value={'conta_corrente'}>{'Conta Corrente'}</MenuItem>
                        <MenuItem value={'conta_poupanca'}>{'Conta Poupança'}</MenuItem>
                        <MenuItem value={'conta_corrente_conjunta'}>{'Conta Corrente Conjunta'}</MenuItem>
                        <MenuItem value={'conta_poupanca_conjunta'}>{'Conta Poupança Conjunta'}</MenuItem>
                </Select>

                <DefaultInput maxLength={30} required onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Nome Completo'} onBlur={(v) => { this.setState({ owner_name: v }) }}/>
                {this.renderDocument()}
                <div style={{width: '100%', marginTop: 10}}>
                    <IosSwitch label="Conta Principal" value="primary" checked={this.state.primary} onChange={(v) => { this.setState({ primary: v }) }}/>
                </div>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.addAccount() }} style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ addModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (

            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', padding: 20 }}>
                        
                { this.renderCards() }
                { this.renderEmpty() }

                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Nova Conta'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
            </div>
            
        );
    }
}
