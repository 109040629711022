import firebase from 'firebase/app'
import 'firebase/storage'
import { v4 as uuidv4 } from 'uuid';

export default class Storage {

    static async uploadFile(folder, file) {

        const ext = file.name.split('.').pop();
        const fileName = `${uuidv4()}.${ext}`;

        const refFile = `${folder}/${fileName}`;

        await firebase.storage().ref(refFile).put(file);
        
        return refFile;
    }

    static async getFileFromRef(ref) {
        return await firebase.storage().ref(ref).getDownloadURL();
    }

    static async getFileFromUrlRef(ref) {
        return await firebase.storage().refFromURL(ref).getDownloadURL();
    }

    static async removeFile(ref) {

        try {

            return await firebase.storage().refFromURL(ref).delete();

        } catch (error) {

            return error;
        }
    }
}