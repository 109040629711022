import SessionHelper from "./SessionHelper";
import Firestore from "../api/firebase/Firestore";

export default class CashierHelper {

    static async getLastOperation(instance, returnDoc = false) {

        let query = await Firestore.customQuery('store_cashier').where('id_company', '==', SessionHelper.getData().id_company).orderBy('date', 'desc').limit(1).get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            docs.push({ ...data, id: doc.id });
        });

        let lastOperation = docs[0] || { operation: 'close', amount: 0 };

        if (returnDoc) {

            return lastOperation;

        } else {

            instance.setState({ cashierDocs: docs, cashierLastOperation: lastOperation });
        }
    }
}