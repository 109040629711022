import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import Firestore from '../api/firebase/Firestore';
import IosSwitch from '../components/IosSwitch';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import moment from "moment-timezone";
import AddIcon from '@material-ui/icons/Add';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import HeaderActionsWrapper from '../components/HeaderActionsWrapper';
import CurrencyInput from '../components/CurrencyInput';
import DefaultSelect from '../components/DefaultSelect';
import PaymentMethodHelper from '../helper/PaymentMethodHelper';
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import RelationField from '../components/grid/RelationField';
import TodayIcon from '@material-ui/icons/Today';
import DateRangeIcon from '@material-ui/icons/DateRange';
import RestoreIcon from '@material-ui/icons/Restore';
import CurrencyHelper from '../helper/CurrencyHelper';
import FilePicker from '../components/FilePicker';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CashierHelper from '../helper/CashierHelper';

export default class ExpensePage extends Component {

    state = {
        docs: [],
        adminDocs: [],
        providerDocs: [],
        name: '',
        payment_method: '',
        amount: null,
        rawAmount: null,
        date: null,
        expire_date: null,
        admin: SessionHelper.getFirebaseAuth().uid,
        provider: '',
        loading: true,
        loadingModal: false,
        addModal: false,
        buying: false,
        inputError: false,
        todayExpenses: 0,
        monthExpenses: 0,
        lastMonthExpenses: 0,
        fileModal: false,
        editId: null,
        files: [],
        paid: false,
        payModal: false,
        payModalPaymentMethod: false,
        payModalAdmin: false,
        payModalDate: null,
        moneyFromCashier: false,
    }

    async componentDidMount() {

        await this.getDocs();
        await this.getProviders();
        await this.getAdmins();

        this.setState({ loading: false });
    }

    async getDocs() {

        let query = await Firestore.customQuery('expense').where('id_company', '==', SessionHelper.getData().id_company).orderBy('created_on', 'desc').get();
        let docs = [];
        
        let actualDate = moment();
        let lastMonth = moment().subtract(1, 'month');
        let todayExpenses = 0;
        let monthExpenses = 0;
        let lastMonthExpenses = 0;

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;
            data.amount = data.amount / 100;
            
            if (data.date) {

                data.date = moment(data.date.toDate()).format('DD/MM/YYYY HH:mm');
            }

            if (data.expire_date) {

                data.expire_date = moment(data.expire_date.toDate()).format('DD/MM/YYYY');
            }

            let compareDate = moment(data.created_on.toDate());

            if (compareDate.isSame(actualDate, 'day')) {

                todayExpenses += data.amount * 100;
            }

            if (compareDate.isSame(actualDate, 'month')) {

                monthExpenses += data.amount * 100;
            }

            if (lastMonth.month() === compareDate.month() && lastMonth.isSame(compareDate, 'year')) {

                lastMonthExpenses += data.amount * 100;
            }

            docs.push(data);
        });

        this.setState({ docs: docs, todayExpenses, monthExpenses, lastMonthExpenses });
    }

    async getAdmins() {

        let query = await Firestore.customQuery('user').where('id_company', '==', SessionHelper.getData().id_company).where('type', '==', 'admin').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ adminDocs: docs });
    }

    async getProviders() {

        let query = await Firestore.customQuery('provider').where('id_company', '==', SessionHelper.getData().id_company).get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ providerDocs: docs });
    }

    async addExpense() {

        if (this.state.name) {

            if (this.state.paid && (!this.state.payment_method || !this.state.date || !this.state.admin)) {

                toast.warn('Informe os dados do pagamento para continuar');
                return;
            }

            try {

                this.setState({ loadingModal: true });

                if ((this.state.payModalPaymentMethod === 'money' || this.state.payment_method === 'money') && this.state.moneyFromCashier) {

                    let response = await this.takeMoneyFromCashier();

                    if (!response) {

                        toast.warn('Saldo insuficiente em caixa');
                        this.setState({ loadingModal: false });
                        return;
                    }
                }

                let data = {
                    name: this.state.name,
                    payment_method: this.state.payment_method,
                    amount: this.state.rawAmount * 100,
                    date: this.state.date,
                    expire_date: this.state.expire_date,
                    paid_by: this.state.admin,
                    buying: this.state.buying,
                    provider: this.state.provider,
                    id_company: SessionHelper.getData().id_company,
                    created_on: new Date(),
                    created_by: SessionHelper.getFirebaseAuth().uid,
                    paid: this.state.paid,
                };

                await Firestore.insert(data, 'expense');
                toast.success("Cadastrado com sucesso");

                await this.setState({ loadingModal: false, addModal: false, name: '', payment_method: '', amount: null, rawAmount: null, expire_date: null, date: null, paid_by: SessionHelper.getFirebaseAuth().uid, paid: false, buying: false, provider: '' });
                await this.getDocs();

            } catch (error) {

                toast.error("Houve um problema ao cadastrar");
                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn("Preencha ao menos o Nome", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    async payExpense() {

        if (this.state.payModalPaymentMethod && this.state.payModalAdmin && this.state.payModalDate) {

            try {

                this.setState({ loadingModal: true });
    
                if ((this.state.payModalPaymentMethod === 'money' || this.state.payment_method === 'money') && this.state.moneyFromCashier) {

                    let response = await this.takeMoneyFromCashier();

                    if (!response) {

                        toast.warn('Saldo insuficiente em caixa');
                        this.setState({ loadingModal: false });
                        return;
                    }
                }

                await Firestore.update({ payment_method: this.state.payModalPaymentMethod, paid_by: this.state.payModalAdmin, paid: true, date: this.state.payModalDate }, 'expense', this.state.editId);
                await this.getDocs();

                toast.success('Pago com sucesso');
    
                this.setState({  loading: true});
                this.setState({  loading: false, loadingModal: false, payModal: false, payModalPaymentMethod: null, payModalAdmin: SessionHelper.getFirebaseAuth().uid, editId: null, payModalAdmin: new Date() });

            } catch (error) {
    
                this.setState({ loadingModal: false });
                toast.error('Houve um problema ao pagar a despesa');
            }

        } else {

            toast.warn('Preencha todos os campos');
        }
    }

    async takeMoneyFromCashier() {

        let response = false;
        let amount = 0;
        let cashier = await CashierHelper.getLastOperation(this, true);

        if (cashier.id) {

            if (this.state.editId) {

                let doc = await Firestore.getDoc('expense', this.state.editId);
                amount = doc.data().amount;
    
            } else {
    
                amount = this.state.rawAmount * 100;
            }
    
            if (cashier && !isNaN(cashier.amount) && parseInt(cashier.amount) >= amount) {

                if (cashier.operation === 'close') {
    
                    let openData = {
                        operation: 'open',
                        amount: parseInt(cashier.amount),
                        date: new Date(),
                        id_user: SessionHelper.getFirebaseAuth().uid,
                        id_company: SessionHelper.getData().id_company,
                    };
    
                    await Firestore.insert(openData, 'store_cashier');
    
                    let closeData = {
                        operation: 'close',
                        amount: parseInt(cashier.amount - amount),
                        date: new Date(),
                        id_user: SessionHelper.getFirebaseAuth().uid,
                        id_company: SessionHelper.getData().id_company,
                    };
    
                    await Firestore.insert(closeData, 'store_cashier');
    
                } else {
    
                    await Firestore.update({ amount: parseInt(cashier.amount - amount) }, 'store_cashier', cashier.id);
                }

                response = true;
            }
        }

        return response;
    }

    async handleFileModal(id) {

        let query = await Firestore.getDoc('expense', id);
        let doc = { ...query.data() };

        await this.setState({ loadingModal: false, files: doc.files, fileModal: true, editId: id });
    }

    async saveImages(files) {

        if (files.length) {

            await Firestore.update({ files: files }, 'expense', this.state.editId);
        }
    }

    handlePayModal(id, paid) {

        if (paid) {

            toast.warn('Essa despesa já está paga');
        
        } else {

            this.setState({ editId: id, payModal: true, payModalPaymentMethod: null, payModalAdmin: SessionHelper.getFirebaseAuth().uid, payModalDate: new Date(), moneyFromCashier: false });
        }
    }

    addModal() {

        if (this.state.addModal) {

            return (
                <div>
                    <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ name: v }) }} label={'Descrição'}/>
                    <CurrencyInput required onError={(error, msg) => { this.setState({ inputError: error }); }} label={'Valor'} onChange={(values) => { const { formattedValue, value } = values; this.setState({ amount: formattedValue, rawAmount: value }) }} value={this.state.amount} />
                    <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            style={{ width: '100%', marginBottom: 20 }}
                            invalidDateMessage={false}
                            format={'dd/MM/yyyy'}
                            autoOk={true}
                            label="Data de Vencimento"
                            cancelLabel={'Cancelar'}
                            okLabel={'Confirmar'}
                            onChange={(v) => { this.setState({ expire_date: v }) }}
                            value={this.state.expire_date}
                        />
                    </MuiPickersUtilsProvider>

                    <IosSwitch fullWidth={true} label="Pago" value="paid" checked={this.state.paid} onChange={(v) => { this.setState({ paid: v, date: new Date() }) }}/>
                    {
                        this.state.paid ?

                            <div style={{ marginTop: 10, marginBottom: 10 }}>
                                <DefaultSelect valueField={'value'} displayField={'label'} onChange={(v) => { this.setState({ payment_method: v.target.value }) }} value={this.state.payment_method} docs={PaymentMethodHelper.getPaymentMethodsByService('order', ['ticket'], true)} label={'Método de Pagamento'} />
                                { this.renderMoneyFromCashier() }
                                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                    <KeyboardDateTimePicker
                                        style={{ width: '100%', marginBottom: 20, marginTop: 10 }}
                                        invalidDateMessage={false}
                                        format={'dd/MM/yyyy HH:mm'}
                                        autoOk={true}
                                        label="Data de Pagamento"
                                        cancelLabel={'Cancelar'}
                                        okLabel={'Confirmar'}
                                        onChange={(v) => { this.setState({ date: v }) }}
                                        value={this.state.date}
                                    />
                                </MuiPickersUtilsProvider>
                                <DefaultSelect label={'Pago Por'} search={true} searchField={'name'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ admin: v.target.value }) }} value={this.state.admin} docs={this.state.adminDocs} />
                            </div>

                        : null
                    }
                    
                    <IosSwitch fullWidth={true} label="É uma compra?" value="buying" checked={this.state.buying} onChange={(v) => { this.setState({ buying: v }) }}/>
                    {
                        this.state.buying ?
    
                        <div style={{ marginTop: 10, marginBottom: 10 }}>
                            <DefaultSelect label={'Fornecedor'} search={true} searchField={'name'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ provider: v.target.value }) }} value={this.state.provider} docs={this.state.providerDocs} />
                        </div>
    
                        : null
                    }
    
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.addExpense() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                        <DefaultButton onClick={() => { this.setState({ addModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    renderMoneyFromCashier() {

        if (this.state.payModalPaymentMethod === 'money' || this.state.payment_method === 'money') {

            return (

                <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <IosSwitch fullWidth={true} label="Retirar do Caixa" value="moneyFromCashier" checked={this.state.moneyFromCashier} onChange={(v) => { this.setState({ moneyFromCashier: v }) }}/>
                </div>
            )

        } else {

            return null;
        }
    }

    payModal() {

        if (this.state.payModal) {

            return (
                <div>
                    <DefaultSelect valueField={'value'} displayField={'label'} onChange={(v) => { this.setState({ payModalPaymentMethod: v.target.value }) }} value={this.state.payModalPaymentMethod} docs={PaymentMethodHelper.getPaymentMethodsByService('order', ['ticket'], true)} label={'Método de Pagamento'} />
                    { this.renderMoneyFromCashier() }
                    <div style={{ marginTop: 10 }}/>
                    <DefaultSelect label={'Pago Por'} search={true} searchField={'name'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ payModalAdmin: v.target.value }) }} value={this.state.payModalAdmin} docs={this.state.adminDocs} />
                    <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                        <KeyboardDateTimePicker
                            style={{ width: '100%', marginTop: 20 }}
                            invalidDateMessage={false}
                            format={'dd/MM/yyyy HH:mm'}
                            autoOk={true}
                            label="Data de Pagamento"
                            cancelLabel={'Cancelar'}
                            okLabel={'Confirmar'}
                            onChange={(v) => { this.setState({ payModalDate: v }) }}
                            value={this.state.payModalDate}
                        />
                    </MuiPickersUtilsProvider>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.payExpense() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                        <DefaultButton onClick={() => { this.setState({ payModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    fileModal() {

        if (this.state.fileModal) {

            return (
                <div>
                    <FilePicker
                        label={''}
                        multiple={true}
                        files={this.state.files}
                        limit={5}
                        onUploadStart={() => { this.setState({ loadingModal: true }) }}
                        onUploadEnd={() => { this.setState({ loadingModal: false }) }}
                        folder={'expense_files'}
                        onUpload={(files) => { this.setState({ files: files }); this.saveImages(files); }}
                    />

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.setState({ fileModal: false, editId: null }) }} color={Colors.primary} loading={this.state.loadingModal} width={'100%'} title={'FECHAR'} />
                    </div>
                </div>
            )
        }
    }

    renderDateEdit(props, label, picker = 'datetime') {

        if (picker === 'datetime') {

            return (

                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                        KeyboardButtonProps={{ disabled: true, style: { display: 'none' } }}
                        style={{ width: '100%', marginBottom: 20, marginTop: 10 }}
                        invalidDateMessage={false}
                        format={'dd/MM/yyyy HH:mm'}
                        autoOk={true}
                        label={label}
                        cancelLabel={'Cancelar'}
                        okLabel={'Confirmar'}
                        onChange={(v) => {  props.onChange(moment(v, 'dd/MM/yyyy HH:mm').format('DD/MM/YYYY HH:mm')) }}
                        value={props.value ? moment(props.value, 'DD/MM/YYYY HH:mm') : null}
                    />
                </MuiPickersUtilsProvider>
            )

        } else {

            return (

                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        KeyboardButtonProps={{ disabled: true, style: { display: 'none' } }}
                        style={{ width: '100%', marginBottom: 20, marginTop: 10 }}
                        invalidDateMessage={false}
                        format={'dd/MM/yyyy'}
                        autoOk={true}
                        label={label}
                        cancelLabel={'Cancelar'}
                        okLabel={'Confirmar'}
                        onChange={(v) => {  props.onChange(moment(v, 'dd/MM/yyyy').format('DD/MM/YYYY')) }}
                        value={props.value ? moment(props.value, 'DD/MM/YYYY') : null}
                    />
                </MuiPickersUtilsProvider>
            )            
        }
    }

    renderExpensesResume() {

        return (

            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', backgroundColor: 'white', padding: 15, marginRight: 5, marginTop: 10, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <TodayIcon style={{ color: Colors.primary, fontSize: 60, marginRight: 15 }}/>
                    <div>
                        <h3 style={{ color: Colors.primary, marginBottom: 3, marginTop: 2 }}>Lançamentos de Hoje</h3>
                        <div style={{ fontWeight: 'bold' }}>{CurrencyHelper.centsToMoney(this.state.todayExpenses)}</div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', backgroundColor: 'white', padding: 15, marginLeft: 5, marginRight: 5, marginTop: 10, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <DateRangeIcon style={{ color: Colors.primary, fontSize: 60, marginRight: 15 }}/>
                    <div>
                        <h3 style={{ color: Colors.primary, marginBottom: 3, marginTop: 2 }}>Lançamentos do Mês</h3>
                        <div style={{ fontWeight: 'bold' }}>{CurrencyHelper.centsToMoney(this.state.monthExpenses)}</div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', backgroundColor: 'white', padding: 15, marginLeft: 5, marginTop: 10, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <RestoreIcon style={{ color: Colors.primary, fontSize: 60, marginRight: 15 }}/>
                    <div>
                        <h3 style={{ color: Colors.primary, marginBottom: 3, marginTop: 2 }}>Mês Anterior</h3>
                        <div style={{ fontWeight: 'bold' }}>{CurrencyHelper.centsToMoney(this.state.lastMonthExpenses)}</div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <HeaderActionsWrapper style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon/>} onClick={() => { this.setState({ addModal: true, moneyFromCashier: false }) }} title={'Adicionar'}/>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        
                    </div>
                </HeaderActionsWrapper>  

                { this.renderExpensesResume() }

                <DefaultTable
                title={'Despesas'}
                marginTop={10}
                height={window.screen.height - 500}
                actions={[
                    {
                        icon: AttachMoneyIcon,
                        tooltip: 'Pagar',
                        onClick: (event, rowData) => { this.handlePayModal(rowData.id, rowData.paid) }
                    },
                    {
                        icon: InsertDriveFileIcon,
                        tooltip: 'Anexos',
                        onClick: (event, rowData) => { this.handleFileModal(rowData.id) }
                    },
                ]}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Descrição', field: 'name' },
                    { title: 'Método de Pagamento', field: 'payment_method', render: rowData => rowData.payment_method ? PaymentMethodHelper.getPaymentMethodLabel(rowData.payment_method, true) : '', lookup: PaymentMethodHelper.expensesLookup() },
                    { title: 'Valor', field: 'amount', type: 'currency', currencySetting: {locale: 'brl', currencyCode: 'brl'}, editComponent: props => ( <CurrencyInput value={props.value} onChange={(e) => { props.onChange(e.floatValue) }} /> ) },
                    { title: 'Pago', field: 'paid', type: 'boolean', render: rowData => <IosSwitch disabled={true} checked={rowData.paid}/>, editComponent: props => (<IosSwitch checked={props.value} onChange={(e) => { props.onChange(e) }} /> ) },
                    { title: 'Data Vencimento', field: 'expire_date', render: rowData => <div>{rowData.expire_date ? rowData.expire_date : ''}</div>, editComponent: props => this.renderDateEdit(props, 'Data Vencimento', 'date') },
                    { title: 'Data Pagamento', field: 'date', render: rowData => <div>{rowData.date ? rowData.date : ''}</div>, editComponent: props => this.renderDateEdit(props, 'Data Pagamento') },
                    { title: 'Pago Por', field: 'paid_by', render: rowData => rowData.paid_by ? <RelationField collection={'user'} field={'name'} id={rowData.paid_by}/> : '', editComponent: props => <DefaultSelect displayField={'name'} search={true} searchField={'name'} valueField={'id'} onChange={(v) => { props.onChange(v.target.value) }} value={props.value} docs={this.state.adminDocs}/> },
                    { title: 'Compra', field: 'buying', type: 'boolean', render: rowData => <IosSwitch disabled={true} checked={rowData.buying}/>, editComponent: props => (<IosSwitch checked={props.value} onChange={(e) => { props.onChange(e) }} /> ) },
                    { title: 'Fornecedor', field: 'provider', type: 'boolean', render: rowData => rowData.provider ? <RelationField collection={'provider'} field={'name'} id={rowData.provider}/> : '', editComponent: props =>  ( <DefaultSelect search={true} searchField={'name'} valueField={'id'} displayField={'name'} onChange={(v) => { props.onChange(v.target.value) }} value={props.value} docs={this.state.providerDocs} /> ) },
                    { title: 'Criado Por', field: 'created_by', render: rowData => rowData.created_by ? <RelationField collection={'user'} field={'name'} id={rowData.created_by}/> : '', editable: false },
                    { title: 'Data Lançamento', field: 'created_on', render: rowData => <div>{moment(rowData.created_on.toDate ? rowData.created_on.toDate() : rowData.created_on).format('DD/MM/YYYY HH:mm')}</div>, editable: false },
                ]}
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                    if (newData.name) {

                        newData.created_on = oldData.created_on;

                        let prev = this.state.docs;
                        prev[prev.indexOf(oldData)] = newData;
    
                        this.setState({ docs: prev });

                        if (oldData.id) {

                            let update = {
                                name: newData.name,
                                payment_method: newData.payment_method,
                                amount: newData.amount * 100,
                                date: newData.date ? moment(newData.date, 'DD/MM/YYYY HH:mm').toDate() : '',
                                expire_date: newData.expire_date ? moment(newData.expire_date, 'DD/MM/YYYY').toDate() : '',
                                paid_by: newData.paid_by,
                                buying: newData.buying,
                                provider: newData.provider ? newData.provider : '',
                                paid: newData.paid,
                            };
    
                            await Firestore.update(update, 'expense', oldData.id);
                        }
    
                        toast.success("Editado com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        return prev;

                    } else {

                        toast.warn('Preencha todos os campos');
                    }
                }}
                onRowDelete={async (oldData) => {

                    if (oldData.id) {

                        let prev = this.state.docs;
                        prev.splice(prev.indexOf(oldData), 1);

                        this.setState({ docs: prev });

                        await Firestore.delete('expense', oldData.id);

                        toast.success("Removido com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        return prev;
                    }
                }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Nova Despesa'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.payModal()} title={'Pagar'} onClose={() => { this.setState({ payModal: false }) }} open={this.state.payModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.fileModal()} title={'Arquivos'} onClose={() => { this.setState({ fileModal: false, files: [], editId: null }) }} open={this.state.fileModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
