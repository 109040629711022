import CurrencyHelper from "./CurrencyHelper";

export default class MembershipTermsHelper {

    static getVariables(data) {

        return {
            'NOME DA EMPRESA': data.company.name,
            'ENDEREÇO DA EMPRESA': [data.company.street, data.company.number, data.company.complemento, data.company.neighborhood].join(', ').replace(', , ', ', ') + `, ${data.company.city} - ${data.company.state}, ` + [data.company.zipcode].join(', ').replace(', , ', ', '),
            'CIDADE DA EMPRESA': data.company.city,
            'NOME DO CLIENTE': data.user.name,
            'NOME DOS DEPENDENTES': data.kids ? (data.kids.map((kid) => (kid.name))).join(', ') : '',
            'ENDEREÇO DO CLIENTE': [data.user.street, data.user.number, data.user.neighbourhood].join(', ').replace(', , ', ', ') + `, ${data.user.city} - ${data.user.state}, ` + [data.user.zipcode].join(', ').replace(', ', ''),
            'VALOR': CurrencyHelper.centsToMoney(data.plan.price),
            'PLANO': data.plan.name,
            'VALOR MULTA': CurrencyHelper.centsToMoney(data.term.tax),
            'DIA VENCIMENTO': data.expirationDate,
            'PAÍS': data.company.country,
        };
    }

    static parseTermVariables(content, termData, companyData, userData, planData, expirationDate, kidsData) {

        let data = { term: termData, company: companyData, user: userData, kids: kidsData, plan: planData, expirationDate: expirationDate };
        let variables = this.getVariables(data);

        Object.keys(variables).forEach(key => {
            content = content.replace(`[${key}]`, variables[key]);
        });

        return content;
    }
}