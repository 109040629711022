import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import IosSwitch from '../components/IosSwitch';
import { Card, MenuItem, Select } from '@material-ui/core';
import DefaultDashboard from '../components/dashboard/DefaultDashboard';
import SalesDashboard from '../components/dashboard/SalesDashboard';
import TournamentDashboard from '../components/dashboard/TournamentDashboard';
import Firestore from '../api/firebase/Firestore';
import SessionHelper from '../helper/SessionHelper';
import Colors from '../constants/Colors';
import StudentSubscriptionDashboard from '../components/dashboard/StudentSubscriptionDashboard';

export default class HomePage extends Component {

    state = {
        dashboard: null,
        defaultDashboard: 'default',
        defaultDashboardCheckbox: false,
        idParam: null,
    }

    async componentDidMount() {

        await this.getParams();
        await this.getDefaultDashboard();
    }

    async getParams() {

        let query = await Firestore.customQuery('settings').where('id_company', '==', SessionHelper.getData().id_company).get();
        let defaultDashboard = 'default';
        let idParam = null;

        if (query.size === 1 && query.docs[0]) {
            defaultDashboard = query.docs[0].data().default_dashboard || 'default';
            idParam = query.docs[0].id;
        }

        this.setState({ defaultDashboard: defaultDashboard, dashboard: defaultDashboard, idParam: idParam });
    }

    async getDefaultDashboard() {

        if (this.state.dashboard === this.state.defaultDashboard) {

            this.setState({ defaultDashboardCheckbox: true });

        } else {

            this.setState({ defaultDashboardCheckbox: false });
        }
    }

    async setDefaultDashboard() {

        if (this.state.idParam) {

            let dashboard = this.state.dashboard;
            await Firestore.update({ default_dashboard: dashboard }, 'settings', this.state.idParam);

            await this.setState({ defaultDashboard: dashboard });
            await this.getDefaultDashboard();
        }
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (

            <div style={{ padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Card style={{ paddingLeft: 25, paddingTop: 25, paddingBottom: 25, paddingRight: 25, width: '100%', height: 80, marginBottom: 8, display: 'flex', flexDirection: 'row', boxShadow: Colors.boxShadow }} className={'header-actions-buttons'}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', paddingLeft: 10, paddingRight: 10, alignItems: 'center', alignContent: 'center'}}>
                        <Select
                            style={{ width: '100%', height: '100%', marginRight: 10 }}
                            labelId="tipo"
                            value={this.state.dashboard}
                            onChange={async (v) => { await this.setState({ dashboard: v.target.value }); this.getDefaultDashboard(); }}>
                            <MenuItem value={'default'}>{'DASHBOARD PADRÃO'}</MenuItem>
                            <MenuItem value={'sales'}>{'DASHBOARD VENDAS'}</MenuItem>
                            <MenuItem value={'student_subscription'}>{'DASHBOARD MATRÍCULAS'}</MenuItem>
                            {/* <MenuItem value={'tournament'}>{'DASHBOARD TORNEIOS'}</MenuItem> */}
                        </Select>
                        <IosSwitch onChange={(v) => { this.setDefaultDashboard() }} disabled={this.state.defaultDashboardCheckbox} label={window.screen.width < 1366 ? `` : `Padrão`} labelPlacement={'start'} checked={this.state.defaultDashboardCheckbox} />
                    </div>
                </Card>
                {this.state.dashboard === 'default' && <DefaultDashboard />}
                {this.state.dashboard === 'sales' && <SalesDashboard />}
                {this.state.dashboard === 'student_subscription' && <StudentSubscriptionDashboard />}
                {this.state.dashboard === 'tournament' && <TournamentDashboard />}
            </div>
        );
    }
}
