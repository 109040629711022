import React, { Suspense } from "react";
import FirebaseConfig from './constants/Firebase';
import FirebaseConfigTest from './constants/FirebaseTest';
import firebase from 'firebase/app';
import 'firebase/auth'
import LoginNavigator from './navigation/LoginNavigator';
import RootNavigator from './navigation/RootNavigator';
import ErrorContainer from './components/ErrorContainer';
import SessionHelper from './helper/SessionHelper';
import Firestore from './api/firebase/Firestore';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import AdminPage from "./page/AdminPage";
import CustomSuspense from "./components/CustomSuspense";
import moment from "moment-timezone";

let config = isDevelopmentMode() || isTestUser() ? FirebaseConfigTest : FirebaseConfig;

if (!isDevelopmentMode()) {
    Sentry.init({
        dsn: "https://64490b1f3249446e9f33819a87d59d1b@o913330.ingest.sentry.io/5851140",
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}




if (!firebase.apps.length) { 
    setConstants();
    firebase.initializeApp(config) 
}

function isDevelopmentMode() {
    return '_self' in React.createElement('div');
}

function isTestUser() {
    let loginEmail = sessionStorage.getItem('loginEmail');

    return (loginEmail && loginEmail == 'test_company2@aceapp.com.br') == true;
}

function setConstants() {
    SessionHelper.setConstants({
        isDevelopment: isDevelopmentMode(),
        isTestUser: isTestUser()
    });
}

function authUser() {
    return new Promise(function (resolve, reject) {
        firebase.auth().onAuthStateChanged(async (user) => {

            if (user) {
                SessionHelper.setFirebaseAuth(user);

                let userDoc = await Firestore.getDoc('user', user.uid);
                let data;

                if (userDoc.exists) {

                    data = userDoc.data();

                    if (userDoc.data().id_company) {

                        let company = await Firestore.getDoc('company', userDoc.data().id_company);

                        if (company.exists) {

                            data.company = company.data();
                        }
                    }

                    if (userDoc.data().id_permission_group) {

                        let permission = await Firestore.getDoc('permission_group', userDoc.data().id_permission_group);

                        if (permission.exists) {

                            data.permission = permission.data();
                        }
                    }

                    if (data.company && data.company.id_plan) {

                        let plan = await Firestore.getDoc('plan', data.company.id_plan);

                        if (plan.exists) {

                            data.plan = { ...plan.data(), id: plan.id };
                        }
                    }

                    SessionHelper.setData(data);

                    if (SessionHelper.getData().company) {

                        moment.tz.setDefault(SessionHelper.getData().company.timezone || 'America/Sao_Paulo');
                    }
                }

            } else {

                SessionHelper.destroy();
            }

            resolve(user);
        });
    });
}

export default class App extends React.Component {

    state = {
        isAuthenticating: true,
        user: null,
    }

    componentDidMount() {        
        authUser().then((user) => {
            this.setState({ isAuthenticating: false, user: user });
        }, (error) => {
            this.setState({ isAuthenticating: false });
            alert(error);
        });
    }

    renderPlatform() {
        let type = SessionHelper.getData().type;
        let company = SessionHelper.getData().id_company;

        if ((type == 'super_admin' || type == 'sales') && !company) {

            return <AdminPage app={this} />

        } else {
            let establishmentType = SessionHelper.getData().company.establishment_type;

            return <RootNavigator establishmentType={establishmentType} />
        }
    }

    render() {
        if (this.state.isAuthenticating) return <CustomSuspense />;

        return (
            <ErrorContainer>
                <Suspense fallback={<CustomSuspense />}>
                    {this.state.user ? this.renderPlatform() : <LoginNavigator />}
                </Suspense>
            </ErrorContainer>
        );
    }
}