import React, { Component } from 'react';
import Colors from '../constants/Colors';
import moment from "moment-timezone";
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import TournamentHelper from '../helper/TournamentHelper';
import SkipNext from '@material-ui/icons/SkipNext';

export default class TournamentMatch extends Component {

    state = {
        tournament: this.props.tournament,
        match: this.props.match,
        users: this.props.users,
        happeningNow: false
    }

    componentDidMount() {
        let happeningNow = this.state.match.start && this.state.match.end ?
            moment().isBetween(moment(this.state.match.start.toDate ? this.state.match.start.toDate() : this.state.match.start), moment(this.state.match.end.toDate ? this.state.match.end.toDate() : this.state.match.end))
            : false;

        this.setState({ happeningNow })
    }

    isBye(pos) {
        let otherPos = pos === 'p1' ? 'p2' : 'p1';
        return this.state.match[pos].length && !this.state.match[otherPos]
    }

    renderPlayers() {
        let date = this.state.match.start ?
            moment(this.state.match.start.toDate ? this.state.match.start.toDate() : this.state.match.start).diff(moment(), 'days') >= 7 ?
                moment(this.state.match.start.toDate ? this.state.match.start.toDate() : this.state.match.start).format(`DD/MM/YYYY HH:mm`)
                : moment(this.state.match.start.toDate ? this.state.match.start.toDate() : this.state.match.start).calendar()
            : 'Sem data';

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row', position: 'relative' }}>
                    {
                        this.state.match.p1 && this.state.match.p1.length ? this.state.match.p1.map((player, key) => {

                            let user = this.state.users.find(item => item.id === player);

                            return (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: 200 }}>
                                    {!this.props.print ? <img style={{ width: 30, height: 30, borderRadius: 30 / 2, boxShadow: Colors.boxShadow, border: '1px solid lightgrey', marginRight: 12 }} src={user && user.photo ? user.photo : process.env.PUBLIC_URL + '/empty_avatar.png'} /> : null}
                                    {user ? user.name.length > 15 ? user.name.slice(0, 15) + '...' : user.name : '?'}
                                    {this.state.match.winner === 'p1' ? <EmojiEventsIcon style={{ color: 'orange', fontSize: 18, position: 'absolute', right: 0 }} /> : null}
                                    {this.isBye('p1') ? <SkipNext style={{ color: Colors.disabled, fontSize: 18, position: 'absolute', right: 0 }} /> : null}
                                </div>
                            )

                        }) : this.state.match.p1 == null ? (

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: 200 }}>
                                {'Bye'}
                            </div>

                        ) : (
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: 200 }}>
                                {!this.props.print ? <img style={{ width: 30, height: 30, borderRadius: 30 / 2, boxShadow: Colors.boxShadow, border: '1px solid lightgrey', marginRight: 12 }} src={process.env.PUBLIC_URL + '/empty_avatar.png'} /> : null}
                                {'?'}
                            </div>
                        )
                    }
                </div>
                <div style={{ paddingTop: 5, borderBottom: '1px solid lightgrey', marginBottom: 8 }} />
                <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 8, position: 'relative' }}>
                    {
                        this.state.match.p2 && this.state.match.p2.length ? this.state.match.p2.map((player, key) => {

                            let user = this.state.users.find(item => item.id === player);

                            return (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: 200 }}>
                                    {!this.props.print ? <img style={{ width: 30, height: 30, borderRadius: 30 / 2, boxShadow: Colors.boxShadow, border: '1px solid lightgrey', marginRight: 12 }} src={user && user.photo ? user.photo : process.env.PUBLIC_URL + '/empty_avatar.png'} /> : null}
                                    {user ? user.name.length > 15 ? user.name.slice(0, 15) + '...' : user.name : '?'}
                                    {this.state.match.winner === 'p2' ? <EmojiEventsIcon style={{ color: 'orange', fontSize: 18, position: 'absolute', right: 0 }} /> : null}
                                    {this.isBye('p2') ? <SkipNext style={{ color: Colors.disabled, fontSize: 18, position: 'absolute', right: 0 }} /> : null}
                                </div>
                            )

                        }) : this.state.match.p2 == null ? (

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: 200 }}>
                                {'Bye'}
                            </div>

                        ) : (
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: 200 }}>
                                {!this.props.print ? <img style={{ width: 30, height: 30, borderRadius: 30 / 2, boxShadow: Colors.boxShadow, border: '1px solid lightgrey', marginRight: 12 }} src={process.env.PUBLIC_URL + '/empty_avatar.png'} /> : null}
                                {'?'}
                            </div>
                        )
                    }
                </div>
                <div style={{ position: 'absolute', bottom: 5, color: 'grey', fontSize: 10, right: 5 }}>
                    {`${date} • ${this.state.match.court ? this.state.match.court.length > 20 ? `${this.state.match.court.slice(0, 17)}...` : this.state.match.court : 'Sem quadra'}`}
                </div>
                <div style={{ position: 'absolute', top: 2, color: 'grey', fontSize: 10, right: 5, fontWeight: 'bold' }}>
                    {this.state.match && this.state.match.indexes ? TournamentHelper.getMatchAlias(this.state.match.indexes.gameAlias) : null}
                </div>
            </div>
        )
    }

    renderBrackets() {

        return (
            <div style={{ position: 'absolute', top: '50%', left: -50, width: 50, borderTop: '2px solid grey' }} />
        )
    }

    renderConnection() {

        let round = this.props.round;
        let height = 150;
        let top = -25;

        for (let i = 1; i < round; i++) {
            height *= 2;
            top = (top * 2) - 50;

            if (round === 2) top = -100;
            if (round === 3) top = -250;
        }

        return (
            <div style={{ position: 'absolute', top: top, left: -95, width: 45, height: height, borderTop: '2px solid grey', borderBottom: '2px solid grey', borderRight: '2px solid grey', alignSelf: 'center' }} />
        )
    }

    render() {
        return (
            <div
                onClick={() => { this.props.openMatch(this.state.match) }}
                style={{
                    position: 'relative', padding: 15,
                    cursor: 'pointer',
                    backgroundColor: 'white',
                    borderRadius: 5,
                    marginBottom: this.props.view === 'column' ? 5 : 0,
                    boxShadow: Colors.boxShadow,
                    display: 'flex',
                    flexDirection: 'column',
                    borderLeft: `4px solid ${this.state.match.p2 === null || this.state.match.p1 === null ? Colors.disabled : this.state.match.winner ? Colors.success : this.state.happeningNow ? Colors.primary : Colors.danger}`,
                    borderTop: '2px solid lightgrey',
                    borderRight: '2px solid lightgrey',
                    borderBottom: '2px solid lightgrey',
                    position: 'relative'
                }}
            >
                {this.renderPlayers()}
                {this.props.round > 0 && this.props.view === 'key' ? this.renderBrackets() : null}
                {this.props.round > 0 && this.props.view === 'key' ? this.renderConnection() : null}
            </div>
        )
    }
}