import React, { Component } from 'react';
import Colors from '../constants/Colors';
import moment from "moment-timezone";
import Firestore from '../api/firebase/Firestore';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import DefaultLoader from './DefaultLoader';
import CloseIcon from '@material-ui/icons/Close';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ClearIcon from '@material-ui/icons/Clear';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import RoomIcon from '@material-ui/icons/Room';
import EditIcon from '@material-ui/icons/Edit';
import { Tooltip, Button, Table, TableHead, TableBody, TableRow, TableCell, InputLabel } from '@material-ui/core';
import InfoRounded from '@material-ui/icons/InfoRounded';
import { toast } from 'react-toastify';
import TournamentHelper from '../helper/TournamentHelper';
import FileCopy from '@material-ui/icons/FileCopy';
import LocalHospital from '@material-ui/icons/LocalHospital';

export default class RankingMatchPanel extends Component {

    state = {
        ranking: null,
        rounds: [],
        match: { ...this.props.match },
        users: this.props.users,
        loading: true,
    }

    async componentDidMount() {

        await this.setState({ loading: true });

        await this.getTournament();

        await this.setState({ loading: false });
    }

    async getTournament() {

        let query = await Firestore.getDoc('ranking', this.props.ranking);

        if (query.exists) {

            let data = { ...query.data(), id: query.id };
            let categories = data.category;

            let rounds = categories[this.state.match.indexes.category].classes[this.state.match.indexes.class].rounds;

            this.setState({ ranking: data, rounds: rounds });
        }
    }

    renderTitle() {

        let title = (this.state.match.indexes.round + 1) === this.state.rounds.length ? 'Final' : `Etapa ${this.state.match.indexes.round + 1} - Partida ${this.state.match.indexes.match + 1}`;

        return <h1>{title}</h1>
    }

    isBye() { return this.state.match.p1.length && !this.state.match.p2 }

    renderWinner() {

        //Bye
        if (this.isBye()) {

            let players = this.state.match.p1.map((user, key) => {

                let userDoc = this.state.users.find(item => item.id === user);

                let text = userDoc ? userDoc.name : '';
                text += this.state.match.p1[key + 1] ? ', ' : '';

                return text;
            });

            return <div>{players}</div>

        } else {

            let winner = this.state.match.winner;

            if (winner) {

                let players = this.state.match[winner].map((user, key) => {

                    let userDoc = this.state.users.find(item => item.id === user);

                    let text = userDoc ? userDoc.name : '';
                    text += this.state.match.p1[key + 1] ? ', ' : '';

                    return text;
                });

                return <div>{players}</div>

            } else {

                return <div>{'Resultado não informado'}</div>
            }
        }
    }

    renderMatchInfo() {

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <EmojiEventsIcon style={{ color: Colors.primary, marginRight: 5 }} />
                    {this.renderWinner()}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 8 }}>
                    <ScheduleIcon style={{ color: Colors.primary, marginRight: 5 }} />
                    <div>{this.state.match.start ? `${moment(this.state.match.start.toDate ? this.state.match.start.toDate() : this.state.match.start).calendar()} (${moment(this.state.match.start.toDate ? this.state.match.start.toDate() : this.state.match.start).format(`DD/MM/YYYY HH:mm`)})` : 'Sem data'}</div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 8, marginBottom: 10 }}>
                    <RoomIcon style={{ color: Colors.primary, marginRight: 5 }} />
                    <div>{this.state.match.court || 'Sem quadra'}</div>
                </div>
                <div style={{ marginTop: 5, borderTop: `1px solid lightgrey` }}>
                    <p style={{ flexDirection: 'row' }}><b>Categoria: </b>{`${this.state.match.indexes && this.state.match.indexes.category >= 0 ? this.state.ranking.category[this.state.match.indexes.category].title : `Desconhecida`}`}</p>
                    <p style={{ flexDirection: 'row' }}><b>Classe: </b>{`${this.state.match.indexes && this.state.match.indexes.class >= 0 ? this.state.ranking.category[this.state.match.indexes.category].classes[this.state.match.indexes.class].title : `Desconhecida`}`}</p>
                    <p style={{ flexDirection: 'row' }}><b>Rodada: </b>{`${this.state.match.indexes && this.state.match.indexes.round >= 0 ? parseInt(this.state.match.indexes.round) + 1 : `Desconhecida`}`}</p>

                    {this.state.match.indexes && this.state.match.indexes.category >= 0 &&
                        this.state.match.indexes.class >= 0 && this.state.match.indexes.match >= 0
                        && this.state.match.indexes.round >= 0 ?
                        <p onClick={(e) => { navigator.clipboard.writeText(TournamentHelper.getMatchAlias(this.state.match.indexes.category, this.state.match.indexes.class, this.state.match.indexes.match, this.state.match.indexes.round, this.state.match.indexes.group, this.state.match.indexes.game)); toast.info('ID copiado!'); e.stopPropagation(); }} style={{ cursor: 'pointer' }}><b>Identificador: </b>{`${TournamentHelper.getMatchAlias(this.state.match.indexes.gameAlias)}`}  <FileCopy style={{ marginLeft: 4, marginTop: 4, fontSize: 16 }} /></p>
                        : null}
                </div>
            </div>
        )
    }

    renderPlayers() {

        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', overflowX: 'scroll', marginTop: 30 }}>

                {
                    this.state.match.p1 ? this.state.match.p1.map((user, key) => {

                        let userDoc = this.state.users.find(item => item.id === user);

                        return (
                            <div style={{ marginRight: this.state.match.p1[key + 1] ? 10 : 0 }}>
                                <img src={userDoc && userDoc.photo || process.env.PUBLIC_URL + '/empty_avatar.png'} style={{ height: 125, width: 125, borderRadius: 125 / 2, border: '1px solid lightgrey', boxShadow: Colors.boxShadow }} />
                                <Tooltip title={userDoc && userDoc.name || ''}>
                                    <div style={{ marginTop: 8, fontWeight: 'bold', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                        {userDoc ? userDoc.name.length > 15 ? userDoc.name.slice(0, 15) + '...' : userDoc.name : '?'}
                                    </div>
                                </Tooltip>
                            </div>
                        )
                    })

                        : 'Bye'
                }

                <ClearIcon style={{ fontSize: 30, margin: 15 }} />

                {
                    !this.isBye() ? this.state.match.p2.map((user, key) => {

                        let userDoc = this.state.users.find(item => item.id === user);

                        return (
                            <div style={{ marginRight: this.state.match.p2[key + 1] ? 10 : 0 }}>
                                <img src={userDoc && userDoc.photo || process.env.PUBLIC_URL + '/empty_avatar.png'} style={{ height: 125, width: 125, borderRadius: 125 / 2, border: '1px solid lightgrey', boxShadow: Colors.boxShadow }} />
                                <Tooltip title={userDoc && userDoc.name || ''}>
                                    <div style={{ marginTop: 8, fontWeight: 'bold', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                        {userDoc ? userDoc.name.length > 15 ? userDoc.name.slice(0, 15) + '...' : userDoc.name : '?'}
                                    </div>
                                </Tooltip>
                            </div>
                        )
                    })

                        : 'Bye'
                }

            </div>
        )
    }

    renderResult() {

        let p1 = this.state.match.p1.map((player, key) => {

            let user = this.state.users.find(item => item.id === player);

            return user ? user.name : '';
        });

        let p2 = this.state.match.p2.map((player, key) => {

            let user = this.state.users.find(item => item.id === player);

            return user ? user.name : '';
        });

        p1 = p1.join(', ');
        p2 = p2.join(', ');

        let wo =
            <TableRow>
                <TableCell align="left">Desclassificação</TableCell>
                <TableCell style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} align="left">
                    {this.state.match.winner && this.state.match.winner == 'p1' ? 'W.O.' : '--'}
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="right">{'X'}</TableCell>
                <TableCell style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }} align="left">
                    {this.state.match.winner && this.state.match.winner == 'p2' ? 'W.O.' : '--'}
                </TableCell>
            </TableRow>;


        return (
            <div>
                <p style={{ color: 'grey' }}>{`Postado ${moment(this.state.match.result.date.toDate ? this.state.match.result.date.toDate() : this.state.match.result.date).calendar().toLowerCase()}`}</p>
                <Table style={{ marginTop: 20, marginBottom: 20 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold', width: 50 }} align="left">{'Set'}</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="center">{this.state.match.winner && this.state.match.winner == 'p2' && this.state.match.result && this.state.match.result.gaveUp ? <LocalHospital style={{ color: 'red', marginRight: 6, fontSize: 18 }} /> : null}{p1}</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="right">{''}</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="right">{this.state.match.winner && this.state.match.winner == 'p1' && this.state.match.result && this.state.match.result.gaveUp ? <LocalHospital style={{ color: 'red', marginRight: 6, fontSize: 18 }} /> : null}{p2}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            !this.state.match.result.isWo ?
                                this.state.match.result.sets.map((set, key) => {

                                    return (
                                        <TableRow>
                                            <TableCell align="left">{key + 1}</TableCell>
                                            <TableCell style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} align="left">
                                                {set.p1}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }} align="right">{'X'}</TableCell>
                                            <TableCell style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }} align="left">
                                                {set.p2}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                : wo
                        }

                        {

                            !this.state.match.result.isWo && Object.keys(this.state.match.result.tiebreak).length ?

                                <TableRow>
                                    <TableCell align="left">{'Tiebreak'}</TableCell>
                                    <TableCell style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} align="left">
                                        {this.state.match.result.tiebreak.p1}
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} align="right">{'X'}</TableCell>
                                    <TableCell style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }} align="left">
                                        {this.state.match.result.tiebreak.p2}
                                    </TableCell>
                                </TableRow>

                                : null
                        }

                    </TableBody>
                </Table>
            </div>
        )
    }

    renderEmptyResult() {
        return <p style={{ color: 'grey' }}>{this.isBye() ? 'Bye' : 'O resultado da partida ainda não foi informado.'}</p>
    }

    editable() {
        let end = this.state.match.end ? moment(this.state.match.end.toDate ? this.state.match.end.toDate() : this.state.match.end) : null;
        let isAfter = end ? moment().isAfter(end) : false;

        return isAfter ? true : this.isBye() ? true : Object.keys(this.state.match.result).length ? true : false;
    }

    canPostResult() {
        return this.isBye() ? true : this.state.match.winner !== null ? true : (!this.state.match.p1[0] || !this.state.match.p2[0]) ? true : false;
    }

    canEditResult() {
        let end = this.state.ranking.end ? moment(this.state.ranking.end.toDate ? this.state.ranking.end.toDate() : this.state.ranking.end) : null;
        let reasonableToEdit = end ? moment().subtract(1, 'day').isBefore(end) : false;

        return this.state.match.winner && this.state.match.prevMatch && reasonableToEdit && !this.isBye() ? true : false;
    }

    renderMatchActions() {
        let canEdit = !this.editable() || this.isBye();
        let canPostResult = !this.canPostResult();
        let canEditResult = this.canEditResult();

        return (

            <div style={{ alignItems: 'center' }}>
                {this.isBye() ?
                    <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 25, marginBottom: 25, fontWeight: 'bold' }}>
                        <InfoRounded style={{ fontSize: 25, marginRight: 8 }} />
                        <div>
                            {`Atenção: tenha cuidado ao editar um BYE, as partidas das rodadas subsequentes não serão atualizadas automaticamente.`}
                        </div>
                    </div>
                    : null
                }
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Tooltip title={'Editar Partida'}>
                        <Button disabled={!canEdit} style={{ color: 'white', backgroundColor: canEdit ? this.isBye() ? `orange` : Colors.primary : 'lightgrey' }} variant={'contained'} onClick={() => { this.props.openEditModal(this.state.match) }}>
                            <EditIcon style={{ marginLeft: 3 }} />
                        </Button>
                    </Tooltip>
                    <Tooltip title={'Informar Resultado'}>
                        <Button disabled={!canPostResult} style={{ color: 'white', backgroundColor: canPostResult ? Colors.primary : 'lightgrey', marginLeft: 8 }} variant={'contained'} onClick={() => { this.state.ranking.visible_brackets ? this.props.openResultModal(this.state.match) : toast.info('Primeiro deixe as chaves visíveis para poder informar resultados') }}>
                            <EmojiEventsIcon style={{ marginLeft: 3 }} />
                        </Button>
                    </Tooltip>
                    {canEditResult ?
                        <Tooltip title={'Editar Resultado'}>
                            <Button disabled={!canEditResult} style={{ color: 'white', backgroundColor: Colors.danger, marginLeft: 8 }} variant={'contained'} onClick={() => { this.state.ranking.visible_brackets ? this.props.openResultModal(this.state.match) : toast.info('Primeiro deixe as chaves visíveis para poder editar resultados') }}>
                                <EmojiEventsIcon style={{ marginLeft: 3 }} />
                                <EditIcon style={{ marginLeft: 3 }} />
                            </Button>
                        </Tooltip>
                        : null
                    }
                </div>
            </div>
        )
    }

    renderContent() {

        return (

            <div style={{ paddingBottom: 50 }}>
                {this.renderTitle()}

                {this.renderMatchActions()}

                <div style={{ marginTop: 50 }}>
                    <h2>Informações</h2>
                    {this.renderMatchInfo()}
                </div>

                <div style={{ marginTop: 50 }}>
                    <h2>Jogadores</h2>
                    {this.renderPlayers()}
                </div>

                <div style={{ marginTop: 50 }}>
                    <h2>Resultado</h2>
                    {Object.keys(this.state.match.result).length ? this.renderResult() : this.renderEmptyResult()}
                </div>
            </div>
        )
    }

    render() {

        return (

            <SlidingPane
                className={'right-panel'}
                isOpen={this.props.panelOpen}
                hideHeader={true}
                closeIcon={<CloseIcon style={{ width: 30, marginTop: 5 }} />}
                width={window.screen.width < 1080 ? "80%" : "40%"}
                onRequestClose={() => this.props.close()}>

                {this.state.loading ? <DefaultLoader /> : this.renderContent()}

            </SlidingPane>
        )
    }
}