import { Component } from "react";
import DefaultModal from './DefaultModal';
import DefaultButton from './DefaultButton';
import Colors from '../constants/Colors';
import CheckIcon from "@material-ui/icons/CheckCircle";
import Firestore from "../api/firebase/Firestore";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import SearchBar from './SearchBar';
import { IconButton, Tooltip, RadioGroup, Radio, FormControlLabel, Menu, MenuItem } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import CurrencyHelper from '../helper/CurrencyHelper';
import moment from "moment";
import SalesHelper from "../helper/SalesHelper";
import DefaultSelect from "./DefaultSelect";
import CurrencyInput from "./CurrencyInput";
import { toast } from "react-toastify";
import SessionHelper from "../helper/SessionHelper";
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

export default class settleTickets extends Component {
    
    state = {
        loadingModal: false,
        paymentOptions: SalesHelper.getPaymentOptions(),
        settleDocs: [],
        usersDocs: this.props.usersDocs,
        start: moment().startOf('day').startOf('month').toDate(),
        end: moment().startOf('day').endOf('month').toDate(),
        type: 'due_date',
        selected: 0,
        applyPaymentMethodMenuAnchor: null,
    }

    componentDidMount() {

        this.getDocs();
    }

    async getDocs() {

        let filterStart = moment(this.state.start);
        let filterEnd = moment(this.state.end);

        if (filterStart.isValid() && filterEnd.isValid()) {

            this.setState({ loadingModal: true });

            await this.getOpenTickets();

            this.setState({ loadingModal: false });
        }
    }

    async getOpenTickets() {

        let query = await Firestore.customQuery('order').where('status', '==', 'waiting_payment').where('type', '==', 'ticket').orderBy('end', 'asc').get();
        let type = this.state.type;
        let docs = [];
        let actualDocs = this.state.settleDocs;

        query.forEach((doc, key) => {

            let data = { ...doc.data(), checked: false, id: doc.id };
            data.user = this.state.usersDocs.find(item => item.id === data.id_user);

            let filterStart = moment(this.state.start);
            let filterEnd = moment(this.state.end);
            let alreadyExists = actualDocs.find(item => item.id === data.id);
            let canPush = true;

            if (data.canceled) {
                
                canPush = false;
            }

            if (type === 'due_date') {

                let end = moment(data.end.toDate());

                if (end.isSameOrAfter(filterStart) && end.isSameOrBefore(filterEnd)) {
                    
                    if (alreadyExists) {

                        if (canPush) { docs.push(alreadyExists) }

                    } else {

                        if (canPush) {docs.push(data) }
                    }
                }

            } else {

                let date = moment(data.date.toDate());

                if (date.isSameOrAfter(filterStart) && date.isSameOrBefore(filterEnd)) {
                    
                    if (alreadyExists) {

                        if (canPush) { docs.push(alreadyExists) }

                    } else {

                        if (canPush) { docs.push(data) }
                    }
                }
            }
        });

        let selected = 0;

        docs.forEach((doc, key) => {

            if (doc.checked) { selected ++ };
        });

        this.setState({ settleDocs: docs, selected });
    }

    async settleTickets() {

        try {

            this.setState({ loadingModal: true });

            let isValid = true;
            let docs = this.state.settleDocs;
            let settleDocs = [];

            docs.forEach((doc, key) => {

                if (doc.checked) {

                    let paidValue = 0;
                    if (doc && doc.history) doc.history.map(history => { paidValue += history.amount });

                    settleDocs.push(doc);

                    if (!doc.payment_method || !doc.paymentValue > 0) {

                        isValid = false;
                        toast.warn(`${doc.title} com dados inválidos!`, { autoClose: false });
                    
                    } else if (doc.paymentValue > 0 && doc.paymentValue > (doc.amount - paidValue)) {

                        isValid = false;
                        toast.warn(`${doc.title} com valor informado maior que o restante para ser pago!`, { autoClose: false });
                    }
                }
            });

            if (isValid && settleDocs.length) {

                for (let doc of settleDocs) {
                    
                    let paidValue = 0;
                    if (doc && doc.history) doc.history.map(history => { paidValue += history.amount });

                    let paymentValue = parseInt(Math.ceil(doc.paymentValue));

                    if (!doc.history) doc.history = [];
                    doc.history.push({ amount: paymentValue, payment_method: doc.payment_method, id_user: SessionHelper.getFirebaseAuth().uid, date: new Date() });
        
                    if (parseInt(paidValue + paymentValue) === parseInt(doc.amount)) doc.status = 'paid';
        
                    await Firestore.update({ history: doc.history, status: doc.status }, 'order', doc.id);
                }

                toast.success('Comandas baixadas com sucesso');
                this.props.onClose();

            } else if (!settleDocs.length) {

                toast.warn('Selecione uma ou mais comandas para baixar');       
            }

            this.setState({ loadingModal: false });

        } catch (error) {

            toast.error('Houve um problema ao baixar as comandas');
            this.setState({ loadingModal: false });
        }
    }

    search(array, text) {

        let result = [];

        result = array.filter((doc) => {

            const name = doc.user ? doc.user.name.toUpperCase() : ''.toUpperCase();
            const title = doc.title ? doc.title.toUpperCase() : ''.toUpperCase();
            const search = text.toString().toUpperCase();

            if (name.indexOf(search) > -1) {

                return true;
            }

            if (title.indexOf(search) > -1) {

                return true;
            }
        });

        return result;
    }

    selectAllTickets(flag) {

        let tickets = this.state.settleDocs;
        let selected = 0;

        tickets.forEach((ticket, key) => {

            let paidValue = 0;

            if (ticket.history) {

                ticket.history.map((history, key) => {
                    paidValue += history.amount;
                });
            }

            tickets[key].payment_method = 'pix';
            tickets[key].paymentValue = tickets[key].amount - paidValue;
            tickets[key].checked = flag;

            if (flag) {

                selected = tickets.length;

            } else {

                selected = 0;
            }
        });

        this.setState({ settleDocs: tickets, selected });
    }

    applyPaymentMethod(paymentMethod) {

        let tickets = this.state.settleDocs;

        tickets.forEach((ticket, key) => {

            if (ticket.checked) {

                tickets[key].payment_method = paymentMethod;
            }
        });

        this.setState({ settleDocs: tickets, applyPaymentMethodMenuAnchor: null });
    }

    content() {

        let totalAmount = 0;

        this.state.settleDocs.map((ticket, key) => {

            if (ticket.checked) { totalAmount += ticket.paymentValue };
        });

        return (

            <div>
                <div style={{ display: 'flex', flexDirection: 'column', fontWeight: 'bold', marginBottom: 10, paddingTop: 10, paddingBottom: 10, paddingLeft: 20, paddingRight: 20, backgroundColor: '#FFF', boxShadow: Colors.boxShadow, border: '1px solid lightgrey', borderRadius: 10 }}>
                    <div>
                        <RadioGroup style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }} name="type" value={this.state.type} onChange={ async (evt) => { await this.setState({ type: evt.target.value }); await this.getDocs() }}>
                            <FormControlLabel value={'emission'} control={<Radio style={{ color: Colors.primary }} />} label={'Emissão'} />
                            <FormControlLabel value={'due_date'} control={<Radio style={{ color: Colors.primary }} />} label={'Vencimento'} />
                        </RadioGroup>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                style={{ width: '100%', paddingLeft: 5, marginBottom: 15 }}
                                invalidDateMessage={false}
                                format={'dd/MM/yyyy'}
                                autoOk={true}
                                label="De"
                                cancelLabel={'Cancelar'}
                                okLabel={'Confirmar'}
                                onChange={ async (v) => { await this.setState({ start: v }); this.getDocs() }}
                                value={this.state.start}
                            />
                        </MuiPickersUtilsProvider>
                        <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                style={{ width: '100%', paddingLeft: 5, marginBottom: 15 }}
                                invalidDateMessage={false}
                                format={'dd/MM/yyyy'}
                                autoOk={true}
                                label="Até"
                                cancelLabel={'Cancelar'}
                                okLabel={'Confirmar'}
                                onChange={ async (v) => { await this.setState({ end: v }); this.getDocs() }}
                                value={this.state.end}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
                <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', fontWeight: 'bold', marginBottom: 10, padding: 10, alignItems: 'center', backgroundColor: '#FFF', boxShadow: Colors.boxShadow, border: '1px solid lightgrey', borderRadius: 10 }}>
                    <InfoRoundedIcon style={{ fontSize: 40, marginRight: 8 }} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div>{`Total de ${this.state.settleDocs.length} comandas em aberto.`}</div>
                        <div>{`Selecione abaixo as que você deseja liquidar.`}</div>
                    </div>
                </div>
                <div style={{ color: Colors.success, display: 'flex', flexDirection: 'row', fontWeight: 'bold', marginBottom: 10, padding: 10, alignItems: 'center', backgroundColor: '#FFF', boxShadow: Colors.boxShadow, border: '1px solid lightgrey', borderRadius: 10 }}>
                    <AttachMoneyIcon style={{ fontSize: 40, marginRight: 8 }} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div>{`${this.state.selected} comandas selecionadas.`}</div>
                        <div>{`${CurrencyHelper.centsToMoney(totalAmount)} para receber.`}</div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: '49%' }}>
                        <h2>Comandas em Aberto</h2>
                        <div style={{ marginBottom: 20 }}>
                            <DefaultButton title={'Mover Todas'} rightIcon={<DoubleArrowIcon style={{ marginLeft: 8 }}/>} onClick={() => { this.selectAllTickets(true) }}/>
                        </div>
                        <div style={{ height: 500, overflowY: 'scroll' }}>
                            <SearchBar customSearch={(docs, text) => { return this.search(docs, text) }} placeholder={'Busque por comanda ou cliente...'} style={{ marginBottom: 10 }} docs={this.state.settleDocs} search={'name'} onEmpty={(docs) => { this.setState({ settleDocs: docs }) }} onSearch={(result) => { this.setState({ settleDocs: result }) }} />
                            { !this.state.settleDocs.length ? <p style={{ color: 'grey', textAlign: 'center', marginTop: 50 }}>{'Nenhuma comanda encontrada.'}</p> : null }
                            {
                                this.state.settleDocs.map((doc, key) => {

                                    if (!doc.checked) {

                                        let paidValue = 0;

                                        if (doc.history) {
    
                                            doc.history.map((history, key) => {
                                                paidValue += history.amount;
                                            });
                                        }
    
                                        let productsCounter = 0,
                                        servicesCounter = 0,
                                        products = '',
                                        services = '';
                            
                                        doc.items.forEach(item => {
                                            if (item.tangible) {
                                                productsCounter += item.quantity;
                                                products += `${item.title} (${item.quantity}) (${CurrencyHelper.centsToMoney(item.unit_price)}), `;
                                            } else {
                                                servicesCounter += 1;
                                                services += `${item.title} (${item.quantity}) (${CurrencyHelper.centsToMoney(item.unit_price)}), `;
                                            }
                                        })
    
                                        return (
                                            <div style={{ padding: 10, marginBottom: 10, backgroundColor: '#FFF', boxShadow: Colors.boxShadow, border: '1px solid lightgrey', borderRadius: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <Tooltip title={'Selecionar'}>
                                                    <IconButton style={{ marginRight: 20, boxShadow: Colors.boxShadow, border: '1px solid lightgrey', height: 50, width: 50, borderRadius: 50 / 2 }} onClick={() => {
    
                                                        let selected = this.state.selected;
                                                        selected ++;

                                                        let docs = this.state.settleDocs;
                                                        docs[key].checked = true;
                                                        docs[key].payment_method = 'pix';
                                                        docs[key].paymentValue = doc.amount - paidValue;
    
                                                        docs.sort((a, b) => {
    
                                                            let dateOne = new Date(a.end.toDate ? a.end.toDate() : a.end);
                                                            let dateTwo = new Date(b.end.toDate ? b.end.toDate() : b.end);
    
                                                            return dateOne - dateTwo;
                                                        });
    
                                                        this.setState({ docs, selected });
                                                    }}>
                                                        <AddIcon style={{ color: Colors.primary }}/>
                                                    </IconButton>
                                                </Tooltip>
                                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                                        <div style={{ fontWeight: 'bold' }}>{`${doc.title} • ${doc.user ? doc.user.name : 'Cliente Desconhecido'}`}</div>
                                                        <div/>
                                                        <div style={{ color: Colors.success, fontWeight: 'bold' }}>{`${CurrencyHelper.centsToMoney(doc.amount)} (${CurrencyHelper.centsToMoney(paidValue)} pagos)`}</div>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <Tooltip title={products}><div style={{ marginTop: 8, paddingLeft: 5, paddingRight: 5, paddingBottom: 2, paddingTop: 2, color: '#fff', borderRadius: 5, textAlign: 'center', width: 110, fontWeight: 'bold', fontSize: 13, backgroundColor: Colors.primary }}>{productsCounter} PRODUTOS</div></Tooltip>
                                                            <Tooltip title={services}><div style={{ marginTop: 8, paddingLeft: 5, paddingRight: 5, paddingBottom: 2, paddingTop: 2, color: '#fff', borderRadius: 5, textAlign: 'center', width: 110, fontWeight: 'bold', fontSize: 13, backgroundColor: Colors.contrast, marginLeft: 5 }}>{servicesCounter} SERVIÇOS</div></Tooltip>
                                                            {doc.discount > 0 ? <Tooltip title={`O valor original era ${CurrencyHelper.centsToMoney(doc.brute_amount)}`}><div style={{ marginTop: 8, paddingLeft: 5, paddingRight: 5, paddingBottom: 2, paddingTop: 2, color: '#fff', borderRadius: 5, textAlign: 'center', fontWeight: 'bold', fontSize: 13, backgroundColor: Colors.danger, marginLeft: 5 }}>{CurrencyHelper.centsToMoney(doc.discount)} DESCONTO</div></Tooltip> : null}
                                                        </div>
                                                        <div/>
                                                        <div style={{ color: 'grey', fontSize: 12, alignSelf: 'flex-end' }}>{`Vencimento ${moment(doc.end.toDate()).format('DD/MM/YYYY HH:mm')}`}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                    <div style={{ width: '2%' }}/>
                    <div style={{ width: '49%' }}>
                        <h2>Selecionadas para Baixar</h2>
                        <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row' }}>
                            <DefaultButton title={'Mover Todas'} leftIcon={<DoubleArrowIcon style={{ marginRight: 8, transform: 'rotate(180deg)' }}/>} onClick={() => { this.selectAllTickets(false) }}/>
                            <div style={{ marginLeft: 10 }}/>
                            <DefaultButton color={Colors.primary} leftIcon={<ArrowDropDownIcon style={{ marginRight: 10 }} />} onClick={(evt) => { this.setState({ applyPaymentMethodMenuAnchor: evt.currentTarget }) }} title={'Aplicar Método de Pagam.'} />
                            <Menu
                                anchorEl={this.state.applyPaymentMethodMenuAnchor}
                                style={{ marginTop: 50 }}
                                keepMounted
                                open={Boolean(this.state.applyPaymentMethodMenuAnchor)}
                                onClose={() => { this.setState({ applyPaymentMethodMenuAnchor: null }) }}>

                                {
                                    this.state.paymentOptions.map((paymentOption, key) => {
                                        
                                        if (paymentOption.key !== 'ticket') {

                                            return <MenuItem onClick={() => { this.applyPaymentMethod(paymentOption.key) }}>{paymentOption.label}</MenuItem>
                                        }
                                    })
                                }

                            </Menu>
                        </div>
                        <div style={{ height: 500, overflowY: 'scroll' }}>
                            {
                                this.state.settleDocs.map((doc, key) => {

                                    if (doc.checked) {

                                        let paidValue = 0;

                                        if (doc.history) {

                                            doc.history.map((history, key) => {
                                                paidValue += history.amount;
                                            });
                                        }

                                        let productsCounter = 0,
                                        servicesCounter = 0,
                                        products = '',
                                        services = '';
                            
                                        doc.items.forEach(item => {
                                            if (item.tangible) {
                                                productsCounter += item.quantity;
                                                products += `${item.title} (${item.quantity}) (${CurrencyHelper.centsToMoney(item.unit_price)}), `;
                                            } else {
                                                servicesCounter += 1;
                                                services += `${item.title} (${item.quantity}) (${CurrencyHelper.centsToMoney(item.unit_price)}), `;
                                            }
                                        });

                                        const options = [...this.state.paymentOptions];
                                        options.forEach((opt, key) => { if (opt.key === 'ticket') options.splice(key, 1) })

                                        return (
                                            <div style={{ padding: 10, marginBottom: 10, backgroundColor: '#FFF', boxShadow: Colors.boxShadow, border: '1px solid lightgrey', borderRadius: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <Tooltip title={'Selecionar'}>
                                                    <IconButton style={{ marginRight: 20, boxShadow: Colors.boxShadow, border: '1px solid lightgrey', height: 50, width: 50, borderRadius: 50 / 2 }} onClick={() => {

                                                        let selected = this.state.selected;
                                                        selected --;

                                                        let docs = this.state.settleDocs;
                                                        docs[key].checked = false;

                                                        delete docs[key].payment_method;
                                                        delete docs[key].paymentValue;

                                                        docs.sort((a, b) => {

                                                            let dateOne = new Date(a.end.toDate ? a.end.toDate() : a.end);
                                                            let dateTwo = new Date(b.end.toDate ? b.end.toDate() : b.end);

                                                            return dateOne - dateTwo;
                                                        });

                                                        this.setState({ docs, selected });
                                                    }}>
                                                        <DeleteIcon style={{ color: Colors.error }}/>
                                                    </IconButton>
                                                </Tooltip>
                                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                                        <div style={{ fontWeight: 'bold' }}>{`${doc.title} • ${doc.user ? doc.user.name : 'Cliente Desconhecido'}`}</div>
                                                        <div/>
                                                        <div style={{ color: Colors.success, fontWeight: 'bold' }}>{`${CurrencyHelper.centsToMoney(doc.amount)} (${CurrencyHelper.centsToMoney(paidValue)} pagos)`}</div>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <Tooltip title={products}><div style={{ marginTop: 8, paddingLeft: 5, paddingRight: 5, paddingBottom: 2, paddingTop: 2, color: '#fff', borderRadius: 5, textAlign: 'center', width: 110, fontWeight: 'bold', fontSize: 13, backgroundColor: Colors.primary }}>{productsCounter} PRODUTOS</div></Tooltip>
                                                            <Tooltip title={services}><div style={{ marginTop: 8, paddingLeft: 5, paddingRight: 5, paddingBottom: 2, paddingTop: 2, color: '#fff', borderRadius: 5, textAlign: 'center', width: 110, fontWeight: 'bold', fontSize: 13, backgroundColor: Colors.contrast, marginLeft: 5 }}>{servicesCounter} SERVIÇOS</div></Tooltip>
                                                            {doc.discount > 0 ? <Tooltip title={`O valor original era ${CurrencyHelper.centsToMoney(doc.brute_amount)}`}><div style={{ marginTop: 8, paddingLeft: 5, paddingRight: 5, paddingBottom: 2, paddingTop: 2, color: '#fff', borderRadius: 5, textAlign: 'center', fontWeight: 'bold', fontSize: 13, backgroundColor: Colors.danger, marginLeft: 5 }}>{CurrencyHelper.centsToMoney(doc.discount)} DESCONTO</div></Tooltip> : null}
                                                        </div>
                                                        <div/>
                                                        <div style={{ color: 'grey', fontSize: 12, alignSelf: 'flex-end' }}>{`Vencimento ${moment(doc.end.toDate()).format('DD/MM/YYYY HH:mm')}`}</div>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 25 }}>
                                                        <DefaultSelect
                                                            id={'payment_selection'}
                                                            valueField={'key'}
                                                            displayField={'label'}
                                                            value={doc.payment_method}
                                                            onChange={(v) => {

                                                                let docs = this.state.settleDocs;
                                                                docs[key].payment_method = v.target.value;

                                                                this.setState({ docs: docs });
                                                            }}
                                                            docs={options}
                                                            label={'Método de pagamento'}
                                                        />
                                                        <div style={{ marginRight: 10 }} />
                                                        <CurrencyInput required label={'Valor (R$)'} onChange={(values) => {
                                                            
                                                            const { formattedValue, value } = values;
                                                            let docs = this.state.settleDocs;
                                                            docs[key]['paymentValue'] = value * 100;

                                                            this.setState({ docs: docs });

                                                        }} value={doc.paymentValue > 0 ? doc.paymentValue / 100 : doc.paymentValue} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                    <DefaultButton leftIcon={<CheckIcon />} onClick={() => { this.settleTickets() }} color={Colors.primary} loading={this.state.loadingModal} width={'100%'} title={`Baixar Comandas Selecionadas`} />
                </div>
            </div>
        )
    }

    render() {

        return <DefaultModal heigth={'100%'} width={'80%'} loading={this.state.loadingModal} content={this.content()} title={'Baixar Comandas'} onClose={() => { this.props.onClose(); this.setState({ settleTickets: [] }) }} open={this.props.open} />
    }
}