import React, { Component } from 'react';
import IosSwitch from '../IosSwitch';
import DefaultButton from '../DefaultButton';
import DefaultLoader from '../DefaultLoader';
import ReportHelper from '../../helper/ReportHelper';
import moment from "moment-timezone";
import { FormLabel } from '@material-ui/core';
import Firestore from '../../api/firebase/Firestore';
import SessionHelper from '../../helper/SessionHelper';
import ReportDefaultModalOptions from './ReportDefaultModalOptions';

export default class CalendarEventReport extends Component {

    state = {
        loadingModal: true,
        progress: 0,
        studentDocs: [],
        teacherDocs: [],
        courtDocs: [],
        users: [],
        allUsers: true,
        teachers: [],
        allTeachers: true,
        courts: [],
        allCourts: true,
        showLesson: true,
        showCourtRent: true,
        showTournamentMatch: true,
        showExperimentalLesson: true,
        showPrivateLesson: true,
        showDayUse: true,
        start: moment().startOf('day').toDate(),
        end: moment().endOf('day').toDate(),
        output: 'download',
        format: 'pdf',
    }

    async componentDidMount() {

        if (this.props.getDocs && this.props.getDocs.length) {

            for (let index = 0; index < this.props.getDocs.length; index++) {

                const getDoc = this.props.getDocs[index];
                await getDoc(this, 'studentDocs', true);
            }
        }

        this.setState({ loadingModal: false, progress: 0 });
    }

    async getReportDocs() {
        let docs = [];
        let allEvents = [];

        let teachers = this.state.allTeachers ? this.state.teacherDocs : this.state.teachers;
        let teacherDocs = [];

        let users = this.state.allUsers ? this.state.studentDocs : this.state.users;
        let userDocs = [];

        let courts = this.state.allCourts ? this.state.courtDocs : this.state.courts;
        let courtDocs = [];

        teachers.forEach((teacher, key) => {

            let teacherDoc = teacher;

            if (!this.state.allTeachers) {

                teacherDoc = this.state.teacherDocs.find(item => item.id === teacher);
            }

            teacherDocs.push(teacherDoc);
        });

        users.forEach((user, key) => {

            let userDoc = user;

            if (!this.state.allUsers) {

                userDoc = this.state.studentDocs.find(item => item.id === user);
            }

            userDocs.push(userDoc);
        });

        courts.forEach((court, key) => {

            let courtDoc = court;

            if (!this.state.allCourts) {

                courtDoc = this.state.courtDocs.find(item => item.id === court);
            }

            courtDocs.push(courtDoc);
        });

        if (this.state.showLesson) {

            let query = await Firestore.customQuery('lesson').where('id_company', '==', SessionHelper.getData().id_company).where('start', '>=', this.state.start).where('start', '<=', this.state.end).get();

            allEvents.push({
                type: 'Aula',
                events: query.docs,
            });
        }

        if (this.state.showCourtRent) {

            let query = await Firestore.customQuery('court_rent').where('id_company', '==', SessionHelper.getData().id_company).where('start', '>=', this.state.start).where('start', '<=', this.state.end).get();

            allEvents.push({
                type: 'Aluguel de Quadra',
                events: query.docs,
            });
        }

        if (this.state.showTournamentMatch) {

            let query = await Firestore.customQuery('tournament_event').where('id_company', '==', SessionHelper.getData().id_company).where('start', '>=', this.state.start).where('start', '<=', this.state.end).get();

            allEvents.push({
                type: 'Partida de Torneio',
                events: query.docs,
            });
        }

        if (this.state.showExperimentalLesson) {

            let query = await Firestore.customQuery('experimental_lesson').where('id_company', '==', SessionHelper.getData().id_company).where('start', '>=', this.state.start).where('start', '<=', this.state.end).get();

            allEvents.push({
                type: 'Aula Experimental',
                events: query.docs,
            });
        }

        if (this.state.showPrivateLesson) {

            let query = await Firestore.customQuery('private_lesson').where('id_company', '==', SessionHelper.getData().id_company).where('start', '>=', this.state.start).where('start', '<=', this.state.end).get();

            allEvents.push({
                type: 'Aula Avulsa',
                events: query.docs,
            });
        }

        if (this.state.showDayUse) {

            let query = await Firestore.customQuery('day_use').where('id_company', '==', SessionHelper.getData().id_company).where('start', '>=', this.state.start).where('start', '<=', this.state.end).get();

            allEvents.push({
                type: 'Day Use',
                events: query.docs,
            });
        }

        for (let eventType of allEvents) {
            let type = eventType.type;
            let events = eventType.events;

            let counter = 0;
            for (let doc of events) {
                let findTeacher = false;
                let findStudent = false;
                let findCourt = false;

                let event = { ...doc.data(), id: doc.id };

                let userNames = [];
                let idUsers = [];

                if (event.id_students) { idUsers = event.id_students };
                if (event.participants) { idUsers = event.participants };
                if (event.id_players) { idUsers = event.id_players };
                if (event.id_user) { idUsers = [event.id_user] };

                for (const user of idUsers) {

                    let doc = userDocs.find(item => item.id === user);

                    if (doc) {

                        userNames.push(ReportHelper.getShortName(doc.name));
                        findStudent = true;

                    } else {

                        let query = await Firestore.getDoc('user', user);
                        let userData = { ...query.data(), id: query.id };

                        userNames.push(ReportHelper.getShortName(userData.name));

                        if (this.state.allUsers) {

                            findStudent = true;
                        }
                    }
                }

                if (event.manual_info && event.manual_info.name) {

                    userNames.push(ReportHelper.getShortName(event.manual_info.name));

                    if (this.state.allUsers) {

                        findStudent = true;
                    }
                }

                if (event.manual_info && event.manual_info[0] && event.manual_info[0].name) {

                    if (this.state.allUsers) {

                        findStudent = true;
                    }

                    for (let user of event.manual_info) {
                        userNames.push(ReportHelper.getShortName(user.name))
                    }
                }

                let courtNames = [];
                let idCourts = [];

                if (event.id_courts) { idCourts = event.id_courts };
                if (event.id_court) { idCourts = [event.id_court] };

                idCourts.forEach((court, key) => {

                    let doc = courtDocs.find(item => item.id === court);

                    if (doc) {

                        courtNames.push(doc.name)
                        findCourt = true;

                    } else {

                        courtNames.push('');
                    }
                });

                if (!idCourts.length && event.court) {

                    findCourt = true;
                    courtNames.push(event.court);
                }

                let order = '';

                if (event.id_order) {

                    let query = await Firestore.getDoc('order', event.id_order);
                    if (query.exists) { order = query.data().title };
                }

                let teacher = '';

                if (event.id_teacher) {

                    let doc = teacherDocs.find(item => item.id === event.id_teacher);

                    if (doc) {

                        teacher = ReportHelper.getShortName(doc.name);
                        findTeacher = true;
                    }
                }

                if (event.id_tournament && idUsers.length < 1) {
                    findStudent = true;
                }

                if ((findCourt || this.state.allCourts) && (findTeacher || this.state.allTeachers) && findStudent) {

                    docs.push({
                        date: `${moment(event.start.toDate()).format('DD/MM/YYYY HH:mm')} ~ ${moment(event.end.toDate()).format('HH:mm')}`,
                        court: courtNames.join(', '),
                        students: userNames.join(', '),
                        type: type,
                        order: order,
                        teacher: teacher,
                    });
                }

                counter++;
                ReportHelper.updateProgress(this, events.length, counter, `Exportando ${type}`);
            }
        }

        docs.sort((a, b) => {

            return a.date > b.date;
        });

        return docs;
    }

    async print() {

        this.setState({ loadingModal: true });

        let docs = await this.getReportDocs();
        let name = `Agenda de Eventos - ${SessionHelper.getData().company.name}`;
        let subtitle = `De ${moment(this.state.start).format('DD/MM/YYYY HH:mm')} Até ${moment(this.state.end).format('DD/MM/YYYY HH:mm')}`;
        let report = await ReportHelper.createReport(name, 5, subtitle, this.state.format);

        report = await ReportHelper.createColumns(report, docs, [
            { name: 'Tipo', width: 13, getText: (doc) => doc.type },
            { name: 'Data', width: 48, getText: (doc) => doc.date },
            { name: 'Quadra', width: 92, getText: (doc) => this.state.format === 'pdf' ? ReportHelper.cutName(doc.court, 20) : doc.court },
            { name: 'Aluno/Cliente', width: 135, getText: (doc) => this.state.format === 'pdf' ? ReportHelper.cutName(doc.students, 34) : doc.students },
        ]);

        ReportHelper.print(report, this.state.output, this.state.format);

        this.setState({ loadingModal: false });
    }

    renderLoading() {
        return (
            <div style={{ height: 200 }}>
                <DefaultLoader progress={this.state.progress || 0} loadingText={this.state.loadingText || ``} />
            </div>
        )
    }

    renderModal() {

        return (

            <div>
                <IosSwitch fullWidth label={'Todos os Professores'} checked={this.state.allTeachers} onChange={(e) => { this.setState({ allTeachers: e, teachers: [] }) }} />
                {!this.state.allTeachers ? ReportHelper.renderTeacherSelection(this, this.state.teacherDocs, 'teachers') : null}

                <IosSwitch fullWidth label={'Todos os Alunos'} checked={this.state.allUsers} onChange={(e) => { this.setState({ allUsers: e, users: [] }) }} />
                {!this.state.allUsers ? ReportHelper.renderUserSelection(this, this.state.studentDocs, 'users') : null}

                <IosSwitch fullWidth label={'Todas as Quadras'} checked={this.state.allCourts} onChange={(e) => { this.setState({ allCourts: e, courts: [] }) }} />
                {!this.state.allCourts ? ReportHelper.renderCourtSelection(this, this.state.courtDocs, 'courts') : null}

                {ReportHelper.renderPeriodSelection(this, 'start', 'end', 'dd/MM/yyyy HH:mm')}

                <FormLabel style={{ marginTop: 18, fontWeight: '800', marginBottom: 10 }} component="legend">Exibir</FormLabel>
                <IosSwitch fullWidth label={'Aulas'} checked={this.state.showLesson} onChange={(e) => { this.setState({ showLesson: e }) }} />
                <IosSwitch fullWidth label={'Aluguel de Quadra'} checked={this.state.showCourtRent} onChange={(e) => { this.setState({ showCourtRent: e }) }} />
                <IosSwitch fullWidth label={'Partida de Torneio'} checked={this.state.showTournamentMatch} onChange={(e) => { this.setState({ showTournamentMatch: e }) }} />
                <IosSwitch fullWidth label={'Aula Experimental'} checked={this.state.showExperimentalLesson} onChange={(e) => { this.setState({ showExperimentalLesson: e }) }} />
                <IosSwitch fullWidth label={'Aula Avulsa'} checked={this.state.showPrivateLesson} onChange={(e) => { this.setState({ showPrivateLesson: e }) }} />
                <IosSwitch fullWidth label={'Day Use'} checked={this.state.showDayUse} onChange={(e) => { this.setState({ showDayUse: e }) }} />
                <ReportDefaultModalOptions format={this.state.format} output={this.state.output} onOutputChange={(value) => { this.setState({ output: value }) }} onFormatChange={(value) => { this.setState({ format: value }) }} />
                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                    <DefaultButton width={'100%'} title={'Imprimir'} onClick={() => { this.print() }} />
                </div>
            </div>
        )
    }

    render() {

        return (

            this.state.loadingModal ? this.renderLoading() : this.renderModal()
        )
    }
}