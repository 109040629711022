import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Card, Button, FormLabel, FormGroup, FormControlLabel, Checkbox, Select, MenuItem, Input } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import Functions from '../api/firebase/Functions'
import AddIcon from '@material-ui/icons/Add';
import HeaderActionsWrapper from '../components/HeaderActionsWrapper';

export default class UserPage extends Component {

    state = {
        docs: [],
        permissionDocs: [],
        name: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        sector: '',
        id_permission_group: null,
        loading: true,
        loadingModal: false,
        addModal: false,
        editId: null,
        inputError: false,
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('user').where('id_company', '==', SessionHelper.getData().id_company).where('type', '==', 'admin').orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    async getPermissions() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('permission_group').where('id_company', '==', SessionHelper.getData().id_company).get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ permissionDocs: docs, loading: false });
    }

    async componentDidMount() {

        await this.getDocs();
        await this.getPermissions();
    }

    async addUser() {

        if (this.state.name && this.state.password && this.state.email && this.state.sector && this.state.id_permission_group && SessionHelper.getData().id_company) {

            if (this.state.password === this.state.passwordConfirmation) {

                let data = {
                    name: this.state.name,
                    email: this.state.email,
                    sector: this.state.sector,
                    id_permission_group: this.state.id_permission_group,
                    type: 'admin',
                    id_company: SessionHelper.getData().id_company,
                    password: this.state.password,
                };
    
                try {
    
                    this.setState({ loadingModal: true });

                    let response = await Functions.request('POST', 'registerUser', data);
    
                    if (response.status == 200) {
                        toast.success("Cadastrado com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        await this.setState({ loadingModal: false, addModal: false, name: '', email: '', sector: '', id_permission_group: '', password: '', passwordConfirmation: ''});
                        await this.getDocs();

                    } else {
                        throw new Error(response.message);
                    }
    
                } catch (error) {
                    toast.error("Houve um problema ao cadastrar", {
                        position: toast.POSITION.TOP_RIGHT
                    });
    
                    this.setState({ loadingModal: false });
                }
            } else {
                toast.warn("As senhas são diferentes", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }

        } else {

            toast.warn("Preencha todos os campos", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    getPermissionLookup() {
        let permissions = this.state.permissionDocs;
        let lookup = {};

        permissions.forEach((permission, key) => {
            lookup[permission.id] = permission.name;
        });

        return lookup;
    }

    addModal() {
        return (
            <div>
                <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Nome'} onBlur={(v) => { this.setState({ name: v }) }}/>
                <DefaultInput required email onError={(error, msg) => { this.setState({ inputError: error }) }} label={'E-mail'} onBlur={(v) => { this.setState({ email: v }) }}/>
                <DefaultInput label={'Setor'} onBlur={(v) => { this.setState({ sector: v }) }}/>
                <div style={{paddingTop: 18}}>
                    <FormLabel component="legend">Grupo de Permissão</FormLabel>
                    <FormGroup>
                        <Select
                        onChange={(evt) => { this.setState({ id_permission_group: evt.target.value }) }}
                        input={<Input />}>

                            {this.state.permissionDocs.map((permission, key) => (
                                <MenuItem key={permission.id} value={permission.id}>
                                {permission.name}
                                </MenuItem>
                            ))}

                        </Select>
                    </FormGroup>
                </div>

                <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Senha'} type={'password'} onBlur={(v) => { this.setState({ password: v }) }}/>
                <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Confirme a Senha'} type={'password'} onBlur={(v) => { this.setState({ passwordConfirmation: v }) }}/>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.addUser() }} style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ addModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <HeaderActionsWrapper style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon/>} onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'}/>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        
                    </div>
                </HeaderActionsWrapper> 

                <DefaultTable
                title={'Usuários'}
                marginTop={10}
                
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Nome', field: 'name' },
                    { title: 'E-mail', field: 'email', editable: false },
                    { title: 'Setor', field: 'sector' },
                    { title: 'Grupo de Permissão', field: 'id_permission_group', lookup: this.getPermissionLookup() },
                    { title: 'Tipo', field: 'type', editable: false, render: rowData => <div>{rowData.type === 'admin' ? 'Administrador' : 'Atendente'}</div> },
                ]}
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                    let prev = this.state.docs;
                    prev[prev.indexOf(oldData)] = newData;

                    this.setState({ docs: prev });

                    if (oldData.id) {
                        
                        let data = {
                            name: newData.name,
                            email: newData.email,
                            sector: newData.sector,
                            id_permission_group: newData.id_permission_group,
                        };

                        await Firestore.update(data, 'user', oldData.id);
                    }

                    toast.success("Editado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                onRowDelete={ async (oldData) => {

                    let prev = this.state.docs;
                    prev.splice(prev.indexOf(oldData), 1);
                    
                    this.setState({ docs: prev });

                    if (oldData.id) {

                        let response = await Functions.request('POST', 'deleteUser', { uid: oldData.id });

                        if (response.status == 200) {
                            await Firestore.delete('user', oldData.id);

                            toast.success("Removido com sucesso", {
                                position: toast.POSITION.TOP_RIGHT
                            });

                            return prev;

                        } else {

                            toast.error("Erro ao remover", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }
                    }
                }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Usuário'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
