import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import RedeemIcon from '@material-ui/icons/Redeem';
import CropFreeIcon from '@material-ui/icons/CropFree';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import MoneyIcon from '@material-ui/icons/Money';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Colors from '../constants/Colors';
import Firestore from '../api/firebase/Firestore';
import SessionHelper from './SessionHelper';
import CurrencyHelper from './CurrencyHelper';
import TermHelper from './TermHelper';
import moment from "moment-timezone";
import { FormLabel, Tooltip, IconButton, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import CurrencyInput from '../components/CurrencyInput';
import DefaultSelect from '../components/DefaultSelect';
import EventIcon from '@material-ui/icons/Event';
import PeopleIcon from '@material-ui/icons/People';
import HistoryIcon from '@material-ui/icons/History';
import DefaultInput from '../components/DefaultInput';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import SaleLogRow from '../components/SaleLogRow';
import TicketCard from '../components/TicketCard';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export default class SalesHelper {

    static getPaymentOptions() {

        let style = {
            color: Colors.primary,
            fontSize: 64,
            marginBottom: 10
        };

        return [
            { label: 'Pix', icon: <CropFreeIcon style={style} />, key: 'pix' },
            { label: 'Dinheiro', icon: <AttachMoneyIcon style={style} />, key: 'money' },
            { label: 'Cartão de Crédito', icon: <CreditCardIcon style={style} />, key: 'credit-card' },
            { label: 'Cartão de Débito', icon: <MoneyIcon style={style} />, key: 'debit-card' },
            { label: 'Permuta', icon: <SwapHorizIcon style={style} />, key: 'permuta' },
            { label: 'Bonificação', icon: <RedeemIcon style={style} />, key: 'bonificacao' },
            { label: 'Comanda (Em Débito)', icon: <ReceiptIcon style={style} />, key: 'ticket' },
        ];
    }

    static getStatuses() {

        return [
            { type: `paid`, color: Colors.success, title: `Pago` },
            { type: `waiting_payment`, color: Colors.danger, title: `Pendente` },
            { type: `late`, color: Colors.error, title: `Atrasado` },
        ];
    }

    static getDateFilterTypes() {
        return [
            { type: `date`, color: Colors.contrast, title: `Emissão` },
            { type: `end`, color: Colors.danger, title: `Vencimento` }
        ];
    }

    static getTimeUnitFilterTypes() {
        return [
            { type: `month`, format: `MMMM/YY`, color: Colors.contrast, title: `Mês` },
            { type: `week`, format: `DD/MM`, color: Colors.contrast, title: `Semana` },
            { type: `day`, format: `DD/MM`, color: Colors.contrast, title: `Dia` }
        ];
    }

    static getDateTypeTypes() {
        return [
            { type: `basic`, color: Colors.primary, title: `Simples` },
            { type: `advanced`, color: Colors.primary, title: `Personalizado` },
        ];
    }

    static async getProducts(instance) {

        let style = {
            color: `white`,
            fontSize: 12,
            paddingLeft: 5,
            paddingRight: 5,
            borderRadius: 5,
            marginLeft: 5,
            maxWidth: 100
        };

        let query = await Firestore.customQuery('product').where('id_company', '==', SessionHelper.getData().id_company).where('active', '==', true).orderBy('name', 'asc').get();

        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();

            if (data.type == 'product') {
                let stockRule = Colors.danger;

                if (data.quantity_available) {
                    if (data.low_inventory_reminder) {
                        if (data.quantity_available > data.low_inventory_reminder) {
                            stockRule = Colors.danger;
                        }

                        if (data.quantity_available > (data.low_inventory_reminder * 2)) {
                            stockRule = Colors.success;
                        }
                    } else {
                        if (data.quantity_available > 5) {
                            stockRule = Colors.danger;
                        }

                        if (data.quantity_available > 10) {
                            stockRule = Colors.success;
                        }
                    }
                }

                data.type_label = <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ backgroundColor: Colors.primary, ...style }}>Produto</div>
                    <div style={{ backgroundColor: stockRule, ...style }}>{parseInt(data.quantity_available)} em estoque</div>
                    <div style={{ backgroundColor: Colors.success, fontWeight: 'bold', ...style }}>{CurrencyHelper.centsToMoney(data.price)}</div>
                </div>;
            } else {
                data.type_label = <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ backgroundColor: Colors.contrast, ...style }}>Serviço</div>
                    <div style={{ backgroundColor: Colors.success, fontWeight: 'bold', ...style }}>{CurrencyHelper.centsToMoney(data.price)}</div>
                </div>;
            }

            docs.push({ ...data, id: doc.id });
        });

        instance.setState({ productsDocs: docs });
    }

    static async getUserOpenTickets(id_user) {
        let queryOpen = await Firestore.customQuery('order').where('id_company', '==', SessionHelper.getData().id_company).where('status', '==', 'waiting_payment').where('id_user', '==', id_user).where('end', '>=', new Date()).orderBy('end', 'desc').get();
        let queryLate = await Firestore.customQuery('order').where('id_company', '==', SessionHelper.getData().id_company).where('status', '==', 'waiting_payment').where('id_user', '==', id_user).where('end', '<=', new Date()).orderBy('end', 'desc').get();

        let docs = [];
        queryOpen.forEach(doc => { docs.push({ ...doc.data(), id: doc.id }) });
        queryLate.forEach(doc => { docs.push({ ...doc.data(), id: doc.id }) });

        return {
            open: queryOpen.docs.length,
            late: queryLate.docs.length,
            docs
        }
    }

    static async getUsers(instance) {

        let style = {
            color: `white`,
            fontSize: 12,
            paddingLeft: 5,
            paddingRight: 5,
            borderRadius: 5,
            marginLeft: 5,
            maxWidth: 100
        };

        let query = await Firestore.customQuery('user').where('id_companies', 'array-contains', SessionHelper.getData().id_company).where('type', 'in', ['student', 'sales_user']).orderBy('name', 'asc').get();

        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();

            if (data.type == 'student') {
                data.type_label = <div style={{ backgroundColor: Colors.success, ...style }}>{TermHelper.getestablishmentTypeLabel(SessionHelper.getData().company.establishment_type).singular}</div>;
            } else {
                data.type_label = <div style={{ backgroundColor: Colors.primary, ...style }}>Não {TermHelper.getestablishmentTypeLabel(SessionHelper.getData().company.establishment_type).singular}</div>;
            }

            docs.push({ ...data, id: doc.id });
        });

        instance.setState({ usersDocs: docs });
    }

    static async getSystemUsers(instance) {

        let query = await Firestore.customQuery('user').where('id_company', '==', SessionHelper.getData().id_company).where('type', '==', 'admin').orderBy('name', 'asc').get();
        let query2 = await Firestore.customQuery('user').where('type', '==', 'super_admin').orderBy('name', 'asc').get();

        let docs = [];

        query.forEach((doc, key) => {
            docs.push({ ...doc.data(), id: doc.id });
        });

        query2.forEach((doc, key) => {
            docs.push({ ...doc.data(), id: doc.id });
        });

        instance.setState({ adminUsers: docs });
    }

    static async getTicketsDocs(instance) {
        try {

            let query = await Firestore.customQuery('order').where('id_company', '==', SessionHelper.getData().id_company).where('type', '==', 'ticket').where(instance.state.selectedDateFilter, '>=', instance.state.paginationStart.toDate()).where(instance.state.selectedDateFilter, '<=', instance.state.paginationEnd.toDate()).orderBy(instance.state.selectedDateFilter, 'asc').get();
            let docs = [];

            query.forEach((document, key) => {
                let doc = { ...document.data(), id: document.id };

                let canPush = true;

                if (instance.state.search.length > 0 && canPush) {
                    let users = instance.searchArray(instance.state.usersDocs, `name`, instance.state.search);

                    if (doc.id_user) {
                        canPush = users.some(user => doc.id_user === user.id);
                    } else {
                        canPush = false;
                    }
                }

                if (instance.state.selectedStatuses.length > 0 && canPush) {
                    if (doc.end.toDate && moment(doc.end.toDate()).isValid()) {
                        canPush = instance.state.selectedStatuses.includes(doc.status);

                        if (doc.status !== `paid`) {
                            if (instance.state.selectedStatuses.includes('late') && !instance.state.selectedStatuses.includes('waiting_payment')) {
                                if (doc.end.toDate && moment(doc.end.toDate()).isValid()) canPush = moment(doc.end.toDate()).isSameOrBefore(moment());
                            }

                            if (instance.state.selectedStatuses.includes('waiting_payment') && !instance.state.selectedStatuses.includes('late')) {
                                if (doc.end.toDate && moment(doc.end.toDate()).isValid()) canPush = moment(doc.end.toDate()).isSameOrAfter(moment());
                            }
                        }
                    }
                }

                if (doc.canceled) canPush = false;

                if (canPush) docs.push(doc);
            });

            instance.setState({ ticketsDocs: docs });
        } catch (e) {
            console.log(e.message)
        }
    }

    static async updatePrice(instance) {
        let value = 0;

        instance.state.addSaleData.items.forEach(item => {
            value += (item.unit_price * item.quantity);

            if (item.divisions && item.divisions.length) {

                item.divisions.forEach(division => {
                    value -= division.unit_price;
                });
            }
        });

        instance.setState({ addSaleData: { ...instance.state.addSaleData, value } });
    }

    static async handleQuantityChange(increment, key, instance) {

        let items = instance.state.addSaleData.items;
        let quantity = items[key].quantity;

        let doc = instance.state.productsDocs.find(doc => doc.id === items[key].id);

        let canDoOperation = true;

        if (doc) {
            if (doc.type !== 'service' && increment && (doc.quantity_available < 1 || doc.quantity_available < (quantity + 1))) {
                // canDoOperation = false;
            }
        } else {
            canDoOperation = false;
        }

        if (!increment && (quantity - 1) < 1) canDoOperation = false;

        if (canDoOperation) {
            if (increment) {
                quantity++;
            } else {
                quantity--;
            }

            if (items[key].log && items[key].log.length > 0) {
                items[key].log.push(SalesHelper.getElementLogModel(quantity));
            } else {
                items[key].log = [SalesHelper.getElementLogModel(quantity)];
            }
        }

        items[key].quantity = quantity;

        if (items[key].divisions && items[key].divisions.length && !items[key].division_inserted) {

            items[key].divisions.forEach((division, divisionKey) => {

                items[key].divisions[divisionKey].unit_price = (items[key].unit_price * quantity) / (items[key].divisions.length + 1);
            });
        }

        await instance.setState({ addSaleData: { ...instance.state.addSaleData, items } });

        this.updatePrice(instance);
    }

    static addProduct(instance, event = null) {

        let items = instance.state.addSaleData.items;

        if (event) {

            let title = `${event.title} - De ${moment(event.start).format('DD/MM/YYYY HH:mm')} Até ${moment(event.end).format('DD/MM/YYYY HH:mm')}`;
            items.push({ id: null, quantity: 1, tangible: false, title: '', unit_price: 0, type: 'event', id_event: event.id });

        } else {

            items.push({ id: null, quantity: 1, tangible: true, title: ``, unit_price: 0, type: 'product', id_event: null });
        }

        instance.setState({ addSaleData: { ...instance.state.addSaleData, items } });
    }

    static handleDivisionQuantityChange(instance, key, value) {

        let items = instance.state.addSaleData.items;
        let basePrice = items[key].unit_price * items[key].quantity;

        if (value) {

            items[key].divided_in++;
            items[key].divisions.push({
                payment_method: null,
                unit_price: 0,
                date: new Date(),
                id_admin: SessionHelper.getFirebaseAuth().uid,
                id_user: ``,
            });

            let dividedPrice = basePrice / (items[key].divisions.length + 1);

            if (items[key].divisions && items[key].divisions.length) {

                for (let divisionKey = 0; divisionKey < items[key].divisions.length; divisionKey++) {

                    items[key].divisions[divisionKey].unit_price = dividedPrice;
                }
            }

        } else {

            if (items[key].divided_in > 1) {

                items[key].divided_in--;
                items[key].divisions.splice(items[key].divisions.length - 1, 1);

                let dividedPrice = basePrice / (items[key].divisions.length + 1);

                if (items[key].divisions && items[key].divisions.length) {

                    for (let divisionKey = 0; divisionKey < items[key].divisions.length; divisionKey++) {

                        items[key].divisions[divisionKey].unit_price = dividedPrice;
                    }
                }
            }
        }

        instance.setState({ addSaleData: { ...instance.state.addSaleData, items } });
    }

    static renderElementLog(item, key) {
        return (
            <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                <FormLabel>Histórico do Produto/Serviço</FormLabel>
                <div style={{ marginBottom: 10 }} />
                {
                    item && item.log ?
                        item.log.map((log, logKey) => (<SaleLogRow data={log} />))
                        : <FormLabel>Não há histórico.</FormLabel>
                }
                <div style={{ marginBottom: 10 }} />
            </div>
        )
    }

    static renderDivisionTicketSelection(instance, itemKey, divisionKey) {

        let items = instance.state.addSaleData.items;
        
        return items[itemKey].divisions[divisionKey].open_tickets.map(doc => {
            
            return (

                <div onClick={(e) => { items[itemKey].divisions[divisionKey].id_ticket = doc.id; instance.setState({ addSaleData: { ...instance.state.addSaleData, items } }) }} style={{ display: 'flex', flexDirection: 'column', border: `4px solid ${items[itemKey].divisions[divisionKey].id_ticket === doc.id ? Colors.success : 'lightgrey'}`, borderRadius: 5, padding: 10, marginRight: 10, alignItems: 'center' }}>
                    <TicketCard readOnly width={250} fromSalesPage={false} productsDocs={instance.state.productsDocs} doc={doc} usersDocs={instance.state.usersDocs} adminUsers={instance.state.adminUsers} />
                    { items[itemKey].divisions[divisionKey].id_ticket === doc.id ? <CheckCircleIcon style={{ color: Colors.success }} /> : null }
                </div>
            )
        });
    }

    static renderProductDivision(instance, item, key) {

        return (

            <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <FormLabel>Dividir Em</FormLabel>
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Tooltip title={'Reduzir'}>
                            <IconButton disabled={item.division_inserted} style={{ height: 32, width: 32 }} onClick={() => { this.handleDivisionQuantityChange(instance, key, false) }}>
                                <RemoveIcon />
                            </IconButton>
                        </Tooltip>
                        <div style={{ fontSize: 32, margin: 10 }}>{item.divided_in}</div>
                        <Tooltip title={'Adicionar'}>
                            <IconButton disabled={item.division_inserted} style={{ height: 32, width: 32 }} onClick={() => { this.handleDivisionQuantityChange(instance, key, true) }}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <div style={{ width: '90%', marginTop: 10 }}>
                    {
                        item.divisions.map((division, divisionKey) => {

                            return (

                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', width: '100%' }}>
                                        <DefaultSelect
                                            useCustomRender
                                            search={true}
                                            searchField={'name'}
                                            id={'user_selection'}
                                            valueField={'id'}
                                            displayField={'name'}
                                            secondaryComponent={'type_label'}
                                            value={division.id_user}
                                            disabled={item.division_inserted}
                                            onChange={ async (v) => {

                                                let items = instance.state.addSaleData.items;
                                                items[key].divisions[divisionKey].id_user = v.target.value;

                                                if (items[key].divisions[divisionKey].payment_method === 'ticket' && items[key].divisions[divisionKey] === 'existing') {

                                                    let query = await Firestore.customQuery('order').where('end', '>=', new Date()).where('type', '==', 'ticket').where('status', '==', 'waiting_payment').where('id_user', 'in', [items[key].divisions[divisionKey].id_user]).orderBy('end', 'desc').get();
                                                    let docs = [];
                                        
                                                    query.forEach((doc, key) => {
                                        
                                                        let data = { ...doc.data(), id: doc.id };
                                                        docs.push(data);
                                                    });
                                        
                                                    items[key].divisions[divisionKey].open_tickets = docs;
                                                }

                                                instance.setState({ ...instance.state.addSaleData, items: items });
                                            }}
                                            docs={instance.props.usersDocs}
                                            label={'Cliente'}
                                        />
                                        <div style={{ marginLeft: 25, marginRight: 5 }} />
                                        <DefaultSelect disabled={item.division_inserted} value={division.payment_method} valueField={'key'} displayField={'label'} onChange={(v) => {

                                            let items = instance.state.addSaleData.items;
                                            items[key].divisions[divisionKey].payment_method = v.target.value;

                                            if (items[key].divisions[divisionKey].payment_method === 'ticket') {

                                                items[key].divisions[divisionKey].ticket_mode = 'new';
                                                items[key].divisions[divisionKey].end = moment().endOf('day').toDate();
                                            }

                                            instance.setState({ ...instance.state.addSaleData, items: items });

                                        }} docs={this.getPaymentOptions()} label={'Método de Pagamento'} />
                                        <div style={{ marginLeft: 5, marginRight: 5 }} />
                                        <CurrencyInput paddingBottom={0} value={division.unit_price / 100} label={'Valor (R$)'} disabled={true} onChange={async (values) => { }} />
                                    </div>
                                    { !item.division_inserted && item.divisions[divisionKey].payment_method === 'ticket' && item.divisions[divisionKey].id_user ?
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: 20 }}>
                                            <FormLabel style={{ marginBottom: 10 }}>Inserir Em</FormLabel>
                                            <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} name="ticket_mode" value={item.divisions[divisionKey].ticket_mode} onChange={ async (evt) => {

                                                let items = instance.state.addSaleData.items;
                                                items[key].divisions[divisionKey].ticket_mode = evt.target.value;

                                                if (items[key].divisions[divisionKey].ticket_mode === 'existing' && items[key].divisions[divisionKey].id_user) {

                                                    let query = await Firestore.customQuery('order').where('end', '>=', new Date()).where('type', '==', 'ticket').where('status', '==', 'waiting_payment').where('id_user', 'in', [items[key].divisions[divisionKey].id_user]).orderBy('end', 'desc').get();
                                                    let docs = [];
                                        
                                                    query.forEach((doc, key) => {
                                        
                                                        let data = { ...doc.data(), id: doc.id };
                                                        docs.push(data);
                                                    });
                                        
                                                    items[key].divisions[divisionKey].open_tickets = docs;

                                                } else {

                                                    items[key].divisions[divisionKey].open_tickets = [];
                                                }

                                                instance.setState({ ...instance.state.addSaleData, items: items });
                                            }}>
                                                <FormControlLabel disabled={item.division_inserted} value="new" control={<Radio style={{ color: Colors.primary }} />} label="Nova Comanda" />
                                                <FormControlLabel disabled={item.division_inserted} value="existing" control={<Radio style={{ color: Colors.primary }} />} label="Comanda Existente" />
                                            </RadioGroup>
                                            
                                            { !item.division_inserted && item.divisions[divisionKey].ticket_mode === 'new' ?

                                                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                                    <KeyboardDateTimePicker
                                                        invalidDateMessage={false}
                                                        format={'dd/MM/yyyy HH:mm'}
                                                        autoOk={true}
                                                        label="Vencimento"
                                                        cancelLabel={'Cancelar'}
                                                        okLabel={'Confirmar'}
                                                        onChange={(v) => {

                                                            let items = instance.state.addSaleData.items;
                                                            items[key].divisions[divisionKey].end = v;

                                                            instance.setState({ ...instance.state.addSaleData, items: items });
                                                        }}
                                                        minDate={new Date()}
                                                        value={division.end}
                                                        disabled={item.division_inserted}
                                                    />
                                                </MuiPickersUtilsProvider>
                                                
                                            : null }

                                            { !item.division_inserted && item.divisions[divisionKey].ticket_mode === 'existing' &&  item.divisions[divisionKey].id_user ?
                                            
                                                <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'auto', paddingTop: 10 }}>
                                                    {item.divisions[divisionKey].open_tickets && item.divisions[divisionKey].open_tickets.length ? this.renderDivisionTicketSelection(instance, key, divisionKey) : null}
                                                    {!item.divisions[divisionKey].open_tickets.length ? <p style={{ color: 'grey' }}>Nenhuma comanda encontrada para esse cliente</p> : null}
                                                </div>
                                            
                                            : null }
                                        </div>
                                    : null}
                                </div>
                            )
                        })
                    }
                </div>
                <div style={{ color: Colors.danger, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold', marginBottom: 10, marginTop: 10, width: '100%', textAlign: 'center' }}>
                    <InfoRoundedIcon style={{ marginRight: 8, fontSize: 28 }} />
                    <div>{`${item.division_inserted ? 'Foi' : 'Será'} gerado uma venda avulsa para cada pagamento.`}</div>
                </div>
            </div>
        )
    }

    static getElementLogModel(quantity) {
        return {
            date: new Date(),
            id_admin: SessionHelper.getFirebaseAuth().uid,
            quantity: parseInt(quantity) || 1
        }
    }

    static renderProductSelection(instance) {

        return (

            <div>
                {!instance.state.addSaleData.items.length ? <p style={{ color: 'grey' }}>Nenhum produto ou serviço selecionado.</p> : null}

                {

                    instance.state.addSaleData.items.map((item, key) => {

                        let productDoc = instance.state.productsDocs.find(search => search.id === item.id);

                        return (
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <div style={{ paddingTop: 15, paddingBottom: 15, display: 'flex', flexDirection: 'row', width: '100%', borderBottom: '.5px solid lightgrey', justifyContent: 'space-evenly', alignItems: 'center' }}>

                                    <div style={{ paddingRight: 15 }}>
                                        <FormLabel>Quantidade</FormLabel>
                                        <div style={{ display: 'flex', flexDirection: 'row', width: 120, alignItems: 'center' }}>
                                            <Tooltip title={'Remover Unidade'}>
                                                <IconButton disabled={item.division_inserted} style={{ height: 32, width: 32 }} onClick={() => { this.handleQuantityChange(false, key, instance) }}>
                                                    <RemoveIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <div style={{ fontSize: 32, margin: 10 }}>{item.quantity}</div>
                                            <Tooltip title={'Adicionar Unidade'}>
                                                <IconButton disabled={item.division_inserted} style={{ height: 32, width: 32 }} onClick={() => { this.handleQuantityChange(true, key, instance) }}>
                                                    <AddIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div style={{ width: '60%', display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <DefaultSelect
                                                useCustomRender
                                                search={true}
                                                searchField={'name'}
                                                id={'product_selection'}
                                                valueField={'id'}
                                                displayField={'name'}
                                                secondaryComponent={'type_label'}
                                                value={item.id}
                                                onChange={async (v) => {

                                                    let itemsSale = instance.state.addSaleData.items;
                                                    itemsSale[key].id = v.target.value;

                                                    let doc = instance.state.productsDocs.find(doc => doc.id === v.target.value);

                                                    if (doc) {
                                                        itemsSale[key] = { id: v.target.value, quantity: 1, tangible: doc.type === 'product', title: doc.name, unit_price: doc.price, id_event: itemsSale[key].id_event || null, log: [SalesHelper.getElementLogModel(1)] };
                                                    }

                                                    this.resetDivisions(instance, key, item, false);

                                                    await instance.setState({ addSaleData: { ...instance.state.addSaleData, items: itemsSale } });

                                                    this.updatePrice(instance);
                                                }}
                                                docs={instance.state.productsDocs}
                                                label={'Selecione um Produto/Serviço'}
                                                disabled={(item.division_inserted) || ((item.title.length || item.unit_price) && !item.id)}
                                            />

                                            {
                                                item.id_event ?

                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                        <div style={{ fontSize: 20, color: Colors.disabled, marginLeft: 20, marginRight: 20 }}>ou</div>

                                                        <DefaultInput value={item.title} disabled={item.id ? true : false} onChange={async (v) => {

                                                            let itemsSale = instance.state.addSaleData.items;

                                                            if (!itemsSale[key].id) {

                                                                itemsSale[key] = { id: null, quantity: 1, tangible: false, title: v, unit_price: itemsSale[key].unit_price, id_event: itemsSale[key].id_event || null, log: [SalesHelper.getElementLogModel(1)] };

                                                                await instance.setState({ addSaleData: { ...instance.state.addSaleData, items: itemsSale } });

                                                                this.updatePrice(instance);
                                                            }

                                                        }} label={'Nome'} />

                                                        <div style={{ marginLeft: 5, marginRight: 5 }} />

                                                        <CurrencyInput value={item.unit_price / 100} disabled={item.id ? true : false} label={'Valor (R$)'} onChange={async (values) => {

                                                            const { formattedValue, value } = values;

                                                            let itemsSale = instance.state.addSaleData.items;

                                                            if (!itemsSale[key].id) {

                                                                itemsSale[key] = { id: null, quantity: 1, tangible: false, title: itemsSale[key].title, unit_price: value * 100, id_event: itemsSale[key].id_event || null };

                                                                await instance.setState({ addSaleData: { ...instance.state.addSaleData, items: itemsSale } });

                                                                this.updatePrice(instance);
                                                            }

                                                        }} />
                                                    </div>

                                                    : null
                                            }

                                        </div>
                                    </div>
                                    <div style={{ width: 10 }} />
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        {
                                            productDoc && productDoc.type === 'product' ?

                                                <Tooltip title={'Ratear Produto'}>
                                                    <IconButton disabled={item.division_inserted} onClick={() => { this.resetDivisions(instance, key, item) }} style={{ boxShadow: Colors.boxShadow, marginLeft: 15, border: '1px solid lightgrey', width: 48, height: 48, backgroundColor: item.divided ? Colors.primary : 'white' }}>
                                                        <PeopleIcon style={{ color: item.divided ? 'white' : Colors.primary }} />
                                                    </IconButton>
                                                </Tooltip>

                                                : null
                                        }

                                        <Tooltip title={'Histórico'}>
                                            <IconButton onClick={() => { instance.setState({ showElementLog: instance.state.showElementLog && instance.state.showElementLog === item.id ? null : item.id }) }} style={{ boxShadow: Colors.boxShadow, marginLeft: 15, border: '1px solid lightgrey', width: 48, height: 48, backgroundColor: instance.state.showElementLog && instance.state.showElementLog === item.id ? Colors.primary : 'white' }}>
                                                <HistoryIcon style={{ color: instance.state.showElementLog && instance.state.showElementLog === item.id ? 'white' : Colors.primary }} />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title={'Remover'}>
                                            <IconButton disabled={item.division_inserted} onClick={() => { let items = instance.state.addSaleData.items; items.splice(key, 1); instance.setState({ addSaleData: { ...instance.state.addSaleData, items } }); this.updatePrice(instance); }} style={{ boxShadow: Colors.boxShadow, marginLeft: 15, border: '1px solid lightgrey', width: 48, height: 48 }}>
                                                <DeleteIcon style={{ color: Colors.error }} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                    {
                                        item.divided ? this.renderProductDivision(instance, item, key) : null
                                    }
                                    {
                                        instance.state.showElementLog && instance.state.showElementLog === item.id ? this.renderElementLog(item, key) : null
                                    }
                                </div>
                            </div>
                        )
                    })

                }

                <Tooltip title={'Adicionar Produto/Serviço'}>
                    <IconButton onClick={() => { this.addProduct(instance) }} style={{ boxShadow: Colors.boxShadow, border: '1px solid lightgrey', marginTop: 10 }}>
                        <AddIcon style={{ color: Colors.primary }} />
                    </IconButton>
                </Tooltip>

                {instance.props.event ?

                    <Tooltip title={`Adicionar ${instance.props.event.collection === 'court_rent' ? 'esse Aluguel' : 'essa Aula'}`}>
                        <IconButton onClick={() => { this.addProduct(instance, instance.props.event) }} style={{ boxShadow: Colors.boxShadow, border: '1px solid lightgrey', marginTop: 10, marginLeft: 8 }}>
                            <EventIcon style={{ color: Colors.primary }} />
                        </IconButton>
                    </Tooltip>

                    : null}

                <div style={{ marginTop: 25 }} />
                <CurrencyInput required onError={(error, msg) => { instance.setState({ inputError: error }) }} label={'Desconto (R$)'} onChange={(values) => { const { formattedValue, value } = values; instance.setState({ addSaleData: { ...instance.state.addSaleData, discount: value * 100 } }); }} value={instance.state.addSaleData.discount > 0 ? instance.state.addSaleData.discount / 100 : instance.state.addSaleData.discount} />

                <h2 style={{ textAlign: 'left', marginTop: 25, color: Colors.success }}>Valor Bruto: +{this.getBruteAmount(instance)}</h2>
                <h2 style={{ textAlign: 'left', marginTop: 10, color: Colors.error }}>Desconto: -{CurrencyHelper.centsToMoney(instance.state.addSaleData.discount)}</h2>
                <h2 style={{ textAlign: 'left', marginTop: 10, color: Colors.primary }}>Valor da Venda: {this.getLiquidAmount(instance)}</h2>
            </div>
        );
    }

    static getBruteAmount(instance) {

        let value = 0;

        if (instance.state.addSaleData) {

            instance.state.addSaleData.items.forEach(item => {

                value += (item.unit_price * item.quantity);

                if (item.division_inserted && item.divisions && item.divisions.length) {

                    item.divisions.forEach(division => {
                        value += division.unit_price;
                    });
                }
            });
        }

        return CurrencyHelper.centsToMoney(value);
    }

    static getLiquidAmount(instance) {

        let value = 0;

        if (instance.state.addSaleData) {

            instance.state.addSaleData.items.forEach(item => {

                value += (item.unit_price * item.quantity);

                if (item.division_inserted && item.divisions && item.divisions.length) {

                    item.divisions.forEach(division => {
                        value += division.unit_price;
                    });
                }
            });

            value -= instance.state.addSaleData.discount;
        }

        return CurrencyHelper.centsToMoney(value);
    }

    static resetDivisions(instance, key, item, flag = null) {

        let items = instance.state.addSaleData.items;

        items[key].divided = flag !== null ? flag : !items[key].divided;
        items[key].divided_in = 1;
        items[key].division_inserted = false;
        items[key].divisions = flag === false ? [] : [{
            payment_method: null,
            id_user: '',
            unit_price: (item.unit_price * item.quantity) / 2,
            date: new Date(),
            id_admin: SessionHelper.getFirebaseAuth().uid
        }];

        instance.setState({ addSaleData: { ...instance.state.addSaleData, items } });
    }

    static validateProductDivision(items) {

        let valid = true;

        for (let item of items) {

            if (item.divided && !item.division_inserted && item.divisions && item.divisions.length) {

                for (let division of item.divisions) {

                    if (!division.id_user || !division.payment_method || (division.payment_method === 'ticket' && division.ticket_mode === 'new' && !division.end) || (division.payment_method === 'ticket' && division.ticket_mode === 'existing' && !division.id_ticket)) {
                        
                        valid = false;
                    }
                }
            }
        }

        return valid;
    }

    static async createProductDivisions(instance, itemIndex) {

        let items = instance.state.addSaleData.items;

        if (items[itemIndex]) {

            for (let division of items[itemIndex].divisions) {

                // Subtrai os rateios no produto de origem;
                items[itemIndex].unit_price -= division.unit_price;

                let id_user = '';

                if (division.id_user) {

                    id_user = division.id_user;
                }

                let itemClone = { ...items[itemIndex] };

                delete itemClone.divisions;
                delete itemClone.division_inserted;
                delete itemClone.divided;
                delete itemClone.divided_in;
                
                let price = parseInt(division.unit_price);

                if (division.payment_method !== 'ticket' || (division.payment_method === 'ticket' && division.ticket_mode === 'new')) {
                 
                    let orderTitle = `${division.payment_method === 'ticket' ? `Comanda` : `Pedido`} #${moment().format('HHmmss')}`;
                    let order = {
                        items: [itemClone],
                        date: new Date(),
                        title: orderTitle,
                        amount: price,
                        discount: 0,
                        brute_amount: price,
                        tax: 0,
                        payment_method: division.payment_method,
                        id_user: id_user,
                        type: division.payment_method === 'ticket' ? 'ticket' : 'product',
                        finished: false,
                        refunded: false,
                        id_company: SessionHelper.getData().id_company,
                        date: new Date(),
                        id_transaction_pagarme: null,
                        created_by: SessionHelper.getFirebaseAuth().uid,
                        status: division.payment_method === 'ticket' ? 'waiting_payment' : 'paid',
                    };
    
                    if (division.payment_method === 'ticket') order.end = division.end;
                    await Firestore.insert(order, 'order');
                
                } else {

                    let query = await Firestore.getDoc('order', division.id_ticket);

                    if (query.exists) {

                        let ticket = { ...query.data(), id: query.id };
                        let items = ticket.items;

                        if (!items) { items = [] };

                        items.push(itemClone);

                        await Firestore.update({ items, amount: parseInt(ticket.amount + price) }, 'order', ticket.id);
                    }
                }
            }

            items[itemIndex].division_inserted = true;
        }

        await instance.setState({ addSaleData: { ...instance.state.addSaleData, items: items } });
    }
}