import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import React, { Component } from 'react';
import Colors from '../constants/Colors';
import WorkIcon from '@material-ui/icons/Work';
import PersonIcon from '@material-ui/icons/Person';
import DefaultButton from '../components/DefaultButton';

export default class HowToPermissionsPage extends Component {
    state = {

    }

    render() {
        return (
            <div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', textAlign: 'center', flex: 1, width: '100%' }}>
                    <div style={{ backgroundColor: '#fff', borderRadius: 10, margin: 20, display: 'flex', flexDirection: 'column' }}>
                        <p style={{ color: Colors.dark, marginBottom: 40, textAlign: 'center', fontSize: 24, fontWeight: 'bold', lineHeight: 2 }}><Lock style={{ fontSize: 26 }} /> Parece que você não tem permissão para acessar esse módulo</p>

                        <div style={window.screen.width < 1366 ? { display: 'flex', flexDirection: 'column', justifyContent: 'center' } : { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={{ backgroundColor: Colors.primary, padding: 20, borderRadius: 10, margin: 10, display: 'flex', flexDirection: 'column', color: Colors.background, alignContent: 'center', alignItems: 'center' }}>
                                <div style={{ display: 'flex', marginBottom: 20, flexDirection: 'row', alignContent: 'center', alignItems: 'center', fontSize: 16, fontWeight: 'bold', textAlign: 'left' }}><WorkIcon style={{ marginRight: 20, fontSize: 32 }} /> Caso você seja um administador, provavelmente este é um módulo novo e você pode habilitá-lo para você e/ou seus funcionários clicando abaixo!</div>
                                <DefaultButton onClick={() => {
                                    window.location.href = '/permission';
                                }} width={'100%'} color={Colors.background} title={'Ir para permissões'} textColor={Colors.dark} leftIcon={<LockOpen />} />
                            </div>
                            <div style={{ backgroundColor: Colors.danger, padding: 20, borderRadius: 10, margin: 10, display: 'flex', flexDirection: 'row', color: Colors.background, alignContent: 'center', alignItems: 'center', fontSize: 16, fontWeight: 'bold', textAlign: 'left' }}> <PersonIcon style={{ marginRight: 20, fontSize: 32 }} /> Caso você não seja um administador, esse módulo não está dentro do seu escopo de permissões, desculpe.</div>
                        </div>

                        <div style={{ marginTop: 20, padding: 10 }}>
                            <p style={{ color: Colors.dark, textAlign: 'left', fontSize: 14, color: Colors.disabled, textAlign: 'center' }}>Você também pode mudar as permissões em Configurações ↳ Perimssões, no menu lateral.</p>
                            <p style={{ color: Colors.dark, textAlign: 'left', fontSize: 14, color: Colors.disabled, textAlign: 'center' }}>Caso tenha dúvidas sobre esse processo, você pode chamar o suporte do ACE em <a style={{ color: Colors.primary }} href='mailto:suporte@aceapp.com.br'>suporte@aceapp.com.br</a> ou nos chamar no <a style={{ color: Colors.primary }} href='https://wa.me/5549991870204'>WhatsApp</a>.</p>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}