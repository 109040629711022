import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Card, CardActions, CardContent, Button, Tooltip  } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import Firestore from '../api/firebase/Firestore';
import moment from "moment-timezone";
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';

export default class RatingPage extends Component {

    state = {
        docs: [],
        averageRating: null,
        ratingTotal: null,
        loading: true,
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('rating').where('id_company', '==', SessionHelper.getData().id_company).orderBy('date', 'desc').get();
        let docs = [];

        let sum = 0;

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);

            sum += data.stars;
        });

        this.setState({ docs: docs, ratingTotal: query.size, averageRating: (sum / query.size).toFixed(2), loading: false });
    }

    componentDidMount() {

        this.getDocs();
    }

    renderStars(numberOfStars) {

        let stars = [];

        for (let index = 0; index < numberOfStars; index++) {
            
            let star = <StarIcon/>
            stars.push(star);
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'row', color: numberOfStars > 3 ? Colors.success : numberOfStars <= 3 && numberOfStars > 2 ? Colors.danger : Colors.error }}>
                { stars }
            </div>
        )
    }

    renderThumb() {

        return this.state.averageRating >= 2.5 ? <ThumbUpIcon style={{ fontSize: 45, color: this.state.averageRating >= 3.5 ? Colors.success : Colors.danger }}/> : <ThumbDownIcon style={{ fontSize: 40, color: Colors.error }}/>
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>

                <div style={{ display: 'flex', flexDirection: 'row' }} className={'mobile-dashboard'}>
                    <Card variant="outlined" style={{ width: '100%', height: 200, marginRight: 5, marginBottom: 12, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 10 }}>
                        <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div style={{ fontWeight: 'bold', fontSize: 18 }}>Média de Avaliações</div>
                            </div>
                            <div style={{ paddingTop: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    {this.state.averageRating >= 0 ? this.renderThumb() : <ThumbUpIcon style={{ fontSize: 45, color: Colors.success }}/>}
                                    <div style={{ marginLeft: 10, fontSize: 50, fontWeight: 'lighter' }}>{`${this.state.averageRating >= 0 ? this.state.averageRating : '-- '}/${(5).toFixed(2)}`}</div>
                                </div>
                                <div style={{ fontSize: 12, textAlign: 'center', color: 'grey' }}>{`Atualizado agora`}</div>
                            </div>
                        </CardContent>
                    </Card>
                    
                    <Card variant="outlined" style={{ width: '100%', height: 200, marginLeft: 5, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 10 }}>
                        <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div style={{ fontWeight: 'bold', fontSize: 18 }}>Total de Avaliações</div>
                            </div>
                            <div style={{ paddingTop: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ fontSize: 50, fontWeight: 'lighter' }}>{this.state.ratingTotal < 10 ? '0'+this.state.ratingTotal : this.state.ratingTotal}</div>
                                </div>
                                <div style={{ fontSize: 12, textAlign: 'center', color: 'grey' }}>{`Atualizado agora`}</div>
                            </div>
                        </CardContent>
                    </Card>
                </div>

                <DefaultTable
                marginTop={10}
                height={550}
                title={'Avaliações'}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Estrelas', field: 'stars', render: rowData => this.renderStars(rowData.stars)},
                    { title: 'Mensagem', field: 'message', render: rowData => <div>{rowData.message ? rowData.message : 'Sem Mensagem'}</div> },
                    { title: 'Data Publicação', field: 'date', render: rowData => moment(rowData.date.toDate()).format('DD/MM/YYYY HH:mm') },
                ]}
                data={this.state.docs}
                />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
