export default {
    orderTypes: [
        { value: 'all', label: 'Todos' },
        { value: 'tournament_ticket', label: 'Inscrição de Torneio' },
        { value: 'court_rent', label: 'Aluguel de Quadra' },
        { value: 'private_lesson', label: 'Aula Avulsa' },
        { value: 'experimental_lesson', label: 'Aula Experimental' },
        { value: 'private_lesson_pack', label: 'Pacote de Aulas Avulsas' },
        { value: 'court_rent_pack', label: 'Pacote de Aluguéis de Quadra' },
        { value: 'product', label: 'Produtos' },
        { value: 'ticket', label: 'Comanda' },
        { value: 'day_use', label: 'Day Use' },
    ],
    paymentMethodTypes: [
        { value: 'all', label: 'Todos' },
        { value: 'boleto', label: 'Boleto' },
        { value: 'credit_card', label: 'Cartão de Crédito' },
        { value: 'debit-card', label: 'Cartão de Débito' },
        { value: 'money', label: 'Pagamento no Local' },
        { value: 'pix', label: 'Pix' },
        { value: 'ticket', label: 'Comanda em Aberto' },
    ],
    statusTypes: [
        { value: 'all', label: 'Todos' },
        { value: 'waiting_payment', label: 'Aguardando Pagamento' },
        { value: 'canceled', label: 'Cancelado' },
        { value: 'paid', label: 'Pago' },
        { value: 'refunded', label: 'Estornado' },
    ],
}