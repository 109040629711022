import React, { Component } from 'react';
import IosSwitch from '../IosSwitch';
import DefaultButton from '../DefaultButton';
import DefaultLoader from '../DefaultLoader';
import SessionHelper from '../../helper/SessionHelper';
import ReportHelper from '../../helper/ReportHelper';
import CurrencyHelper from '../../helper/CurrencyHelper';
import { FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import Colors from '../../constants/Colors';
import ReportDefaultModalOptions from './ReportDefaultModalOptions';

export default class AvailableInventoryReport extends Component {

    state = {
        loadingModal: true,
        productDocs: [],
        products: [],
        allProducts: true,
        availableInventoryOrder: 'name',
        output: 'download',
        format: 'pdf',
    }

    async componentDidMount() {

        if (this.props.getDocs && this.props.getDocs) {

            for (let index = 0; index < this.props.getDocs.length; index++) {
                
                const getDoc = this.props.getDocs[index];
                await getDoc(this);
            }
        }

        this.setState({ loadingModal: false });
    }

    async getReportDocs() {

        let docs = this.state.allProducts ? [...this.state.productDocs] : [...this.state.products];

        if (!this.state.allProducts) {
            
            docs.forEach((product, key) => {

                docs[key] = this.state.productDocs.find(item => item.id === product);
            });
        }

        docs.sort((a, b) => {

            if (this.state.availableInventoryOrder === 'name') {

                return a.name > b.name;

            } else {

                return a.quantity_available < b.quantity_available;
            }
        });

        return docs;
    }

    getColor(report, doc) {

        if (doc.quantity_available <= 10 && doc.quantity_available > 0) {
    
            report.doc.setTextColor(240, 144, 0);

        } else if (doc.quantity_available <= 0) {

            report.doc.setTextColor(215, 77, 78);

        } else {

            report.doc.setTextColor(89, 166, 61);
        }
    }

    async print() {

        this.setState({ loadingModal: true });

        let name = `Estoque Disponível - ${SessionHelper.getData().company.name}`;
        let docs = await this.getReportDocs();
        let report = await ReportHelper.createReport(name, 5, '', this.state.format);

        report = await ReportHelper.createColumns(report, docs, [
            { name: 'Produto', width: 13, getText: (doc) => doc.name ? this.state.format === 'pdf' ? ReportHelper.cutName(doc.name, 50) : doc.name : '' },
            { name: 'Valor', width: 110, getText: (doc) => CurrencyHelper.centsToMoney(doc.price) },
            { name: 'Custo', width: 135, getText: (doc) => doc.cost ? CurrencyHelper.centsToMoney(doc.cost) : '' },
            { name: 'Quantidade Disponível', getColor: (report, doc) => this.getColor(report, doc), width: 158, getText: (doc) => doc.quantity_available.toString() },
        ]);

        ReportHelper.print(report, this.state.output, this.state.format);

        this.setState({ loadingModal: false });
    }

    renderLoading() {

        return (

            <div style={{ height: 200 }}>
                <DefaultLoader/>
            </div>
        )
    }

    renderModal() {

        return (

            <div>
                <IosSwitch fullWidth label={'Todos os Produtos'} checked={this.state.allProducts} onChange={(e) => { this.setState({ allProducts: e, products: [] }) }} />
                { !this.state.allProducts ? ReportHelper.renderProductSelection(this, this.state.productDocs, 'products') : null }
                <FormLabel style={{ marginTop: 18, fontWeight: '800' }} component="legend">Ordenação</FormLabel>
                <div style={{ flexDirection: 'row', paddingTop: 10 }}>
                    <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} value={this.state.availableInventoryOrder} onChange={(evt) => { this.setState({ availableInventoryOrder: evt.target.value }) }}>
                        <FormControlLabel value="name" control={<Radio style={{ color: Colors.primary }} />} label="Nome do Produto" />
                        <FormControlLabel value="quantity_available" control={<Radio style={{ color: Colors.primary }} />} label="Quantidade Disponível" />
                    </RadioGroup>
                </div>
                <ReportDefaultModalOptions format={this.state.format} output={this.state.output} onOutputChange={(value) => { this.setState({ output: value }) }} onFormatChange={(value) => { this.setState({ format: value }) }}/>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <DefaultButton width={'100%'} title={'Imprimir'} onClick={() => { this.print() }} />
                </div>
            </div>
        )
    }

    render() {

        return (

            this.state.loadingModal ? this.renderLoading() : this.renderModal()
        )
    }
}