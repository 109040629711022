import React from 'react';
import Firestore from '../api/firebase/Firestore';
import SessionHelper from '../helper/SessionHelper';
import ClipLoader from "react-spinners/ClipLoader";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import Computer from '@material-ui/icons/Computer';
import Colors from '../constants/Colors';
import moment from "moment-timezone";
import DefaultLoader from './DefaultLoader';

export default class SaleLogRow extends React.Component {

    state = {
        loading: true,
        data: this.props.data,
        user: null
    }

    async componentDidMount() {
        await this.getUser();

        this.setState({ loading: false });
    }

    async getUser() {
        let query = await Firestore.getDoc('user', this.state.data.id_admin);

        if (query.exists) {
            this.setState({ user: query.data() });
        }
    }

    render() {

        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', width: '100%', padding: 10, borderBottom: `.5px solid ${Colors.disabled}`, marginTop: 2 }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>Quantidade alterada para → <div style={{ fontWeight: '600', marginLeft: 5 }}>{this.state.data.quantity}</div></div>
                <div style={{ color: 'grey' }}>{moment(this.state.data.date.toDate ? this.state.data.date.toDate() : this.state.data.date).format('DD/MM/YYYY HH:mm:ss')}</div>
                <div style={{ display: 'flex', flexDirection: 'row', color: 'grey' }}>Operador: {this.state.user ? this.state.user.name : <DefaultLoader css={{ marginLeft: 10 }} size={10} />}</div>
            </div>
        );
    }
}