import React, { Component } from 'react';
import CurrencyHelper from '../helper/CurrencyHelper';
import PagarmeConstants from '../constants/PagarMe';
import { Tooltip } from '@material-ui/core';
import Colors from '../constants/Colors';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

export default class LiquidProfit extends Component {

    getValue(type) {
        
        let value = 0;

        if (type == 'credit_card') {

            value = [];
            value.push(<div>{`${CurrencyHelper.centsToMoney((((100 - PagarmeConstants.getKeys().credit_card_tax) / 100) * this.props.price) - PagarmeConstants.getKeys().easydata_transaction_amount)} à vista`}</div>);

            if (this.props.installments) {

                if (this.props.installments >= 2) {

                    value.push(<div>{`${CurrencyHelper.centsToMoney((((100 - PagarmeConstants.getKeys().credit_card_tax_2_6) / 100) * this.props.price) - PagarmeConstants.getKeys().easydata_transaction_amount)} - 2 a 6 parcelas`}</div>);
                }

                if (this.props.installments >= 7) {

                    value.push(<div>{`${CurrencyHelper.centsToMoney((((100 - PagarmeConstants.getKeys().credit_card_tax_7) / 100) * this.props.price) - PagarmeConstants.getKeys().easydata_transaction_amount)} - 7 parcelas ou mais`}</div>);
                }
            }

        } else if (type == 'boleto') {

            value = CurrencyHelper.centsToMoney((this.props.price - PagarmeConstants.getKeys().boleto_tax) - PagarmeConstants.getKeys().easydata_transaction_amount_boleto);

        } else if (type == 'pix') {

            value = <div>{`${CurrencyHelper.centsToMoney((((100 - PagarmeConstants.getKeys().pix_tax) / 100) * this.props.price) - PagarmeConstants.getKeys().easydata_transaction_amount_pix)}`}</div>;
        }

        if (this.props.price > 0) {

            return value

        } else {

            return CurrencyHelper.centsToMoney(0, true);
        }
    }

    renderType() {

        let text = '(Cartão de Crédito)';

        if (this.props.type) {

            if (this.props.type == 'boleto') {

                text = '(Boleto)';
    
            } else if (this.props.type == 'credit_card') {
    
                text = '(Cartão de Crédito)';
    
            } else if (this.props.type == 'pix') {
    
                text = '(Pix)';
            }
        }

        return text;
    }

    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', color: 'orange' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>Vendendo pelo App você recebe {this.renderType()}: <strong>{this.getValue(this.props.type || 'credit_card')}</strong></div>
                <Tooltip title={'Consulte nossas taxas acessando Configurações > Minha Empresa > Taxas e Serviços'}>
                    <InfoOutlinedIcon style={{ fontSize: 17, marginLeft: 5, marginTop: 1, cursor: 'pointer' }} />
                </Tooltip>
            </div>
        )
    }
}
