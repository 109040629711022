import React, { Component } from 'react';
import Functions from '../api/firebase/Functions';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckIcon from '@material-ui/icons/Check';
import HelpIcon from '@material-ui/icons/Help';
import moment from "moment-timezone";
import SessionHelper from '../helper/SessionHelper';
import TournamentHelper from '../helper/TournamentHelper';
import Colors from '../constants/Colors';

export default class MaximumPlayersInTournament extends Component {

    state = {
        expanded: false,
        validVariables: false,
        maximumMatches: 0,
        generatedMatches: 0,
        numberOfParticipants: 0,
        category: {}
    }

    async componentDidUpdate(prev) {
        let bracketsHaveChanged = false;

        this.props.category.forEach((category, key) => {
            if (category.checked) {
                category.classes.forEach((categoryClass, classKey) => {
                    if (prev.category && prev.category[key]) {
                        if (categoryClass.bracket_type !== prev.category[key].classes[classKey].bracket_type
                            || categoryClass.limit !== prev.category[key].classes[classKey].limit) bracketsHaveChanged = true;
                    }
                });
            }
        });

        if (this.props.numberOfParticipants != prev.numberOfParticipants ||
            this.props.numberOfCourts != prev.numberOfCourts ||
            this.props.matchAverageDuration != prev.matchAverageDuration ||
            bracketsHaveChanged
        ) {
            let maximumMatches = await TournamentHelper.getMaximumMatchesInPeriod(this.props.matchStart, this.props.matchEnd, this.props.matchAverageDuration, this.props.tournamentStart, this.props.tournamentEnd, this.props.numberOfCourts);
            let generatedMatches = await TournamentHelper.getGeneratedMatchesInPeriod(this.props.category);

            if (maximumMatches != false && maximumMatches > 0) {
                this.setState({ validVariables: true, maximumMatches: parseInt(maximumMatches), numberOfParticipants: this.props.numberOfParticipants });
            }

            if (maximumMatches != false && maximumMatches > 0 && generatedMatches != false && generatedMatches > 0) {
                this.setState({ generatedMatches: parseInt(generatedMatches) });
            }
        }
    }

    render() {
        let intoTheLimit = true;

        if (this.state.validVariables == true && this.state.generatedMatches > 0 && this.state.maximumMatches > 0 && this.state.numberOfParticipants > 0) {
            if (this.state.generatedMatches > this.state.maximumMatches) intoTheLimit = false;
        } else {
            intoTheLimit = true;
        }

        return (
            <Accordion onChange={(event, value) => { this.setState({ expanded: value }) }} expanded={this.state.expanded} style={{ marginTop: 10, marginBottom: 10, color: this.state.validVariables ? intoTheLimit ? Colors.success : Colors.danger : Colors.primary, border: `3px solid ${this.state.validVariables ? intoTheLimit ? Colors.success : Colors.danger : Colors.primary}` }}>
                <AccordionSummary expandIcon={!this.state.expanded ? <ChevronRightIcon style={{ color: this.state.validVariables ? intoTheLimit ? Colors.success : Colors.danger : Colors.primary }} /> : <ExpandLessIcon style={{ color: this.state.validVariables ? intoTheLimit ? Colors.success : Colors.danger : Colors.primary }} />}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontWeight: 'bold' }}>
                        {this.state.validVariables ? intoTheLimit ? <CheckIcon /> : <InfoOutlinedIcon style={{ marginRight: 5 }} /> : <HelpIcon style={{ marginRight: 5 }} />}
                        {this.state.validVariables ? intoTheLimit ? <div>{`Dentro do limite sugerido! Limite de partidas sugerido: ${this.state.maximumMatches}; Partidas geradas: ${this.state.generatedMatches}`}</div> : <div>{`Fora do limite sugerido! Limite sugerido: ${this.state.maximumMatches}; Partidas geradas: ${this.state.generatedMatches}`}</div> : <div>{`Ainda não é possível calcular o limite sugerido`}</div>}
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div>{this.state.maximumMatches ? `Sugerimos o limite teórico de quantas partidas é possível jogar no espaço de tempo do seu torneio ~${this.state.maximumMatches} (~${(this.state.maximumMatches / this.props.numberOfCourts)} em cada quadra) partidas de em média ${this.props.matchAverageDuration} minutos, totalizando, leve em consideração que para cada tipo de chave há uma relação ao número de partidas que a mesma gera, chaves de grupo gerarão pelo menos (tamanho do grupo - 1) partidas para cada jogador ou dupla.` : ``}</div>
                </AccordionDetails>
            </Accordion>
        )
    }
}
