import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import Colors from '../constants/Colors';
import { toast } from 'react-toastify';
import DefaultLoader from './DefaultLoader';
export default class components extends Component {

	getBackgroundColor() {

		return this.props.disabled == true ? 'lightgrey' : (this.props.color ? this.props.color : Colors.primary);
	}

	getColor() {
		return this.props.disabled == true ? 'rgba(0, 0, 0, 0.26)' : this.props.textColor || '#fff';
	}

	render() {
		return (
			<Button
				disabled={this.props.disabled == true}
				onClick={(evt) => {
					if (!this.props.loading) {
						this.props.onClick(evt);
					} else {
						toast.info('Aguarde. Realizando Ação...');
					}
				}}
				style={{ width: this.props.width ? this.props.width : 'auto', fontWeight: 'bold', backgroundColor: this.getBackgroundColor(), color: this.getColor(), display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: window.screen.width < 1360 ? 12 : 0, height: window.screen.width < 1080 ? 64 : 36 }}
				variant="contained" >
				{this.props.leftIcon && !this.props.loading ? this.props.leftIcon : null}
				{this.props.loading ? <DefaultLoader css={{ display: 'flex', marginRight: 8 }} size={20} color={this.getColor()} /> : null}
				<div style={{ marginLeft: this.props.leftIcon && this.props.title ? 8 : 0, color: this.getColor() }}>
					{this.props.title}
				</div>
				{this.props.rightIcon && this.props.rightIcon}
			</Button>
		)
	}
}
