import React, { Component } from 'react';
import Functions from '../api/firebase/Functions';
import { Accordion, AccordionSummary, AccordionDetails, Tooltip, FormLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckIcon from '@material-ui/icons/Check';
import moment from "moment-timezone";
import SessionHelper from '../helper/SessionHelper';
import DefaultLoader from './DefaultLoader';
import Colors from '../constants/Colors';
import EventTypeHelper from '../helper/EventTypeHelper';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        maxWidth: '100%',
        fontSize: 13
    }
}))(Tooltip);

export default class AgendaConflict extends Component {

    state = {
        hover: false,
        loading: false,
        hasConflict: false,
        expanded: false,
        courts: [],
        teachers: [],
        conflicts: [],
        allConflicts: [],
        start: null,
        end: null,
        court: null,
        teacher: null
    }

    async componentDidMount() {
        if (this.props.courts) await this.setState({ courts: this.props.courts });
        if (this.props.teachers) await this.setState({ teachers: this.props.teachers });
        await this.setData();
        await this.checkConflicts();
    }

    async componentDidUpdate(prev) {
        if (this.props.start !== prev.start || this.props.end !== prev.end || this.props.court !== prev.court || this.props.teacher !== prev.teacher) {
            await this.setData();
            await this.checkConflicts();
        }
    }

    async setData() {
        await this.setState({ start: this.props.start, end: this.props.end, court: this.props.court, teacher: this.props.teacher, editid: this.props.editId, edittype: this.props.editType });
    }

    async checkConflicts() {
        let start = this.state.start;
        let end = this.state.end;
        let court = this.state.court || '';
        let teacher = this.state.teacher || '';
        let editid = this.state.editid || '';
        let edittype = this.state.edittype || '';

        if (start && end) {
            let startDate = moment(start);
            let endDate = moment(end);

            if (startDate.isValid() && endDate.isValid()) {
                this.setState({ loading: true, hasConflict: false, conflicts: [], allConflicts: [] });

                let response = await Functions.request('GET', `checkConflicts?id_company=${SessionHelper.getData().id_company}&start=${startDate.format('DDMMYYYYHHmm')}&end=${endDate.format('DDMMYYYYHHmm')}&court=${court}&teacher=${teacher}&editid=${editid}&edittype=${edittype}`);

                if (response && response.status === 200) {
                    this.setState({ hasConflict: response.data.hasConflict, conflicts: response.data.conflictedWith || [], allConflicts: response.data.allConflicts });
                }

                this.setState({ loading: false });
            }
        }

        this.props.conflictCallback(this.state.hasConflict);
    }

    renderConflicts() {

        return this.state.conflicts.map((conflict, key) => {

            let label = conflict.type === 'lesson' ? 'Aula'
                : conflict.type === 'court_rent' ? 'Aluguel de Quadra'
                    : conflict.type === 'tournament' ? 'Partida de Torneio'
                        : conflict.type === 'experimental_lesson' ? 'Aula Experimental'
                            : conflict.type === 'day_use' ? 'Day Use'
                                : 'Aula Avulsa';

            return (

                <div>{`${label} - de ${conflict.start} até ${conflict.end}`}</div>
            )
        });
    }


    tooltip = (unavaluable, event, element) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 8, paddingBottom: 8 }}>
                <div><b>{'Status: '}</b>{unavaluable.includes(element.id) ? `Ocupado(a)` : `Disponível`}</div>
                {event && event.length ? event.map(e => (
                    <div style={{ borderTop: `1px solid white`, marginTop: 5, paddingTop: 4, paddingBottom: 4 }}>
                        <div><b>{EventTypeHelper.getEventNameByType(e.type)}</b>: {e.start} ~ {e.end}</div>
                    </div>
                )) : null}
            </div>
        )
    }

    renderTeachers() {
        let unavaluable = [];

        let eventsMap = {};

        this.state.allConflicts.forEach(event => {
            if (event.id_teacher && !unavaluable.includes(event.id_teacher)) {
                unavaluable.push(event.id_teacher);

                if (!eventsMap[event.id_teacher]) eventsMap[event.id_teacher] = [];
                eventsMap[event.id_teacher].push(event);
            }
        });

        return this.state.teachers.map(teacher => (
            <div style={{ height: '100%' }} onMouseOut={() => { this.setState({ hover: false }) }} onMouseOver={() => { this.setState({ hover: teacher.id }); }}>
                <HtmlTooltip open={this.state.hover === teacher.id} placement={'top-start'} title={this.tooltip(unavaluable, eventsMap[teacher.id] || [], teacher)}>
                    <div style={{
                        alignItems: 'center',
                        alignContent: 'center',
                        alignSelf: 'center',
                        textAlign: 'flex-start',
                        margin: 2,
                        borderRadius: 5,
                        padding: 4,
                        height: 36,
                        width: 108,
                        color: this.state.loading ? Colors.primary : unavaluable.includes(teacher.id) ? Colors.error : Colors.success,
                        border: `3px solid ${this.state.loading ? Colors.primary : unavaluable.includes(teacher.id) ? Colors.error : Colors.success}`
                    }}>
                        {teacher.name.length < 13 ? teacher.name : teacher.name.slice(0, 10) + '...'}
                    </div>
                </HtmlTooltip>
            </div>

        ));
    }

    renderCourts() {
        let unavaluable = [];

        let eventsMap = {};

        this.state.allConflicts.forEach(event => {
            if (event.id_court && !unavaluable.includes(event.id_court)) {
                unavaluable.push(event.id_court);

                if (!eventsMap[event.id_court]) eventsMap[event.id_court] = [];
                eventsMap[event.id_court].push(event);
            }

            if (event.id_courts) {
                event.id_courts.forEach(court => {
                    if (!unavaluable.includes(court)) {
                        unavaluable.push(court);

                        if (!eventsMap[court]) eventsMap[court] = [];
                        eventsMap[court].push(event);
                    }
                });
            }
        });

        return this.state.courts.map(court => (
            <div style={{ height: '100%' }} onMouseOut={() => { this.setState({ hover: false }) }} onMouseOver={() => { this.setState({ hover: court.id }); }}>
                <HtmlTooltip open={this.state.hover === court.id} placement={'top-start'} title={this.tooltip(unavaluable, eventsMap[court.id] || [], court)}>
                    <div style={{
                        alignItems: 'center',
                        alignContent: 'center',
                        alignSelf: 'center',
                        textAlign: 'flex-start',
                        margin: 2,
                        borderRadius: 5,
                        padding: 4,
                        height: 36,
                        width: 108,
                        color: this.state.loading ? Colors.primary : unavaluable.includes(court.id) ? Colors.error : Colors.success,
                        border: `3px solid ${this.state.loading ? Colors.primary : unavaluable.includes(court.id) ? Colors.error : Colors.success}`
                    }}>
                        {court.name.length < 13 ? court.name : court.name.slice(0, 10) + '...'}
                    </div>
                </HtmlTooltip>
            </div>

        ));
    }

    render() {
        return (
            <div style={{ width: '100%' }}>
                <Accordion onChange={(event, value) => { this.state.conflicts.length ? this.setState({ expanded: value }) : this.setState({ expanded: false }) }} expanded={this.state.expanded} style={{ borderRadius: 5, marginTop: 30, marginBottom: 30, color: this.state.loading ? Colors.primary : this.state.hasConflict ? Colors.error : Colors.success, border: `3px solid ${this.state.loading ? Colors.primary : this.state.hasConflict ? Colors.error : Colors.success}` }}>
                    <AccordionSummary expandIcon={this.state.loading ? null : !this.state.conflicts.length ? null : !this.state.expanded ? <ChevronRightIcon style={{ color: Colors.error }} /> : <ExpandLessIcon style={{ color: Colors.error }} />}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontWeight: 'bold' }}>
                            {this.state.loading ? <DefaultLoader size={20} css={{ marginRight: 5, marginTop: 5 }} /> : this.state.hasConflict ? <InfoOutlinedIcon style={{ marginRight: 5 }} /> : <CheckIcon />}
                            {this.state.loading ? <div>{'Procurando por conflitos...'}</div> : this.state.hasConflict ? <div>{'Esse horário possuí conflitos'}</div> : <div>{'Horário disponível'}</div>}
                        </div>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                        {this.state.conflicts.length ? this.renderConflicts() : null}
                    </AccordionDetails>
                </Accordion>

                <FormLabel>Quadras nesse horário</FormLabel>
                <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', marginBottom: 10, marginTop: 5 }}>
                    {this.state.courts && this.state.courts.length > 0 ? this.renderCourts() : null}

                </div>

                <FormLabel>Professores nesse horário</FormLabel>
                <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', marginTop: 5 }}>
                    {this.state.teachers && this.state.teachers.length > 0 ? this.renderTeachers() : null}
                </div>
            </div>

        )
    }
}
