import React, { Component } from 'react';
import Colors from '../constants/Colors';
import moment from "moment-timezone";
import 'moment/locale/pt-br';
import { FormLabel, Tooltip } from '@material-ui/core';
import DefaultInput from './DefaultInput';
import DefaultSlider from './DefaultSlider';
import DefaultLoader from './DefaultLoader';
import SearchIcon from '@material-ui/icons/Search';
import KanbanCard from './KanbanCard';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import Brightness4 from '@material-ui/icons/Brightness4';

export default class Kanban extends Component {
    state = {
        start: moment().startOf('day').add(-1, 'days'),
        end: moment().endOf('day').add(2, 'days'),
        sliderValue: [-1, 2],
        teacherDocs: this.props.parent.state.teacherDocs,
        courtDocs: this.props.parent.state.courtDocs,
        types: [
            { type: `lesson`, color: `rgb(54, 162, 235)`, title: `Aula` },
            { type: `court_rent`, color: `green`, title: `Aluguel de Quadra` },
            { type: `experimental_lesson`, color: `purple`, title: `Aula Experimental` },
            { type: `private_lesson`, color: `red`, title: `Aula Avulsa` },
            { type: `tournament`, color: `orange`, title: `Partida de torneio` },
            { type: `day_use`, color: Colors.order.day_use, title: `Day Use` }
        ],
        studentsDocs: {},
        search: ``,
        selectedTypes: [],
        selectedTeachers: [],
        selectedCourts: [],
        toDo: [],
        next: [],
        doing: [],
        done: []
    };

    componentDidMount = async () => {
        await this.props.parent.setState({ start: moment(this.state.start).toDate(), end: moment(this.state.end).toDate(), kanbanCallback: this.filterEvents });
        await this.getEvents();

        this.interval = setInterval(() => { if (!this.props.parent.state.eventOpen) this.getEvents() }, 120000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    renderIcon(type) {
        return type === 'tournament' ? <EmojiEventsIcon style={styles.titleIcon} /> : 
                type === 'court_rent' ? <AttachMoneyIcon style={styles.titleIcon} /> : 
                type === 'experimental_lesson' ? <img src={process.env.PUBLIC_URL + '/icons/flask-grey.png'} style={{ ...styles.titleIcon, height: 16, width: 16, filter: `${this.state.selectedTypes.includes(type) ? `invert(0%)` : `invert(100%)`} ` }} /> :
                type === 'private_lesson' ? <SportsTennisIcon style={styles.titleIcon} /> : 
                type === 'day_use' ? <Brightness4 style={styles.titleIcon} /> : 
                <SportsTennisIcon style={styles.titleIcon} />
    }

    getEvents = async () => {
        await this.props.parent.filterEvents();
    }

    searchArray(array, searchField, keyword) {
        let search = [];

        for (var j = 0; j < array.length; j++) {
            var regex = new RegExp(keyword, "gm");

            if (array[j][searchField].match(regex)) search.push(array[j]);
        }

        return search;
    }

    filterEvents = (events) => {
        let filteredEvents = [];

        if (events) {
            events.forEach(event => {
                if (!event.type) {
                    event.type = `lesson`;
                    event.color = `rgb(54, 162, 235)`;
                }

                let canPush = true;

                if (this.state.selectedCourts.length > 0 && canPush) {
                    canPush = event.id_court && this.state.selectedCourts.includes(event.id_court);
                }

                if (this.state.selectedTeachers.length > 0 && canPush) {
                    canPush = event.id_teacher && this.state.selectedTeachers.includes(event.id_teacher);
                }

                if (this.state.selectedTypes.length > 0 && canPush) {
                    canPush = event.type && this.state.selectedTypes.includes(event.type);
                }

                if (this.state.search.length > 0 && canPush) {
                    let students = this.searchArray(Object.keys(this.state.studentsDocs).map(id => (this.state.studentsDocs[id])), `name`, this.state.search);

                    if (event.id_student && event.id_student.length > 0) {
                        canPush = students.some(student => event.id_student.includes(student.id));
                    } else {
                        canPush = false;
                    }
                }

                if (canPush) filteredEvents.push(event);
            });
        }

        let toDo = [], next = [], doing = [], done = [];

        filteredEvents.forEach((event) => {
            if (moment().isBetween(event.start, event.end)) {
                doing.push(event);
            } else if (moment().isAfter(event.end)) {
                done.push(event);
            } else if (moment().isBefore(event.start)) {
                if (moment().add(4, 'hour').isSameOrAfter(event.start)) {
                    next.push(event);
                } else {
                    toDo.push(event);
                }
            }
        });

        this.setState({ toDo: [], next: [], doing: [], done: [] });
        this.setState({ toDo, next, doing, done });
    }

    handleEventSelect = (event) => {
        let evtCopy = { ...event };
        if (evtCopy.type == `lesson`) evtCopy.type = null;

        this.props.parent.handleEventSelect(evtCopy);
    }

    setStudents(student) {
        if (!Object.keys(this.state.studentsDocs).includes(student.id)) {
            let studentsDocs = this.state.studentsDocs;
            studentsDocs[student.id] = student;

            this.setState({ studentsDocs });
        }
    }

    handleSliderChange = async (evt, value) => {
        let start = moment().startOf('day').add(value[0] < value[1] ? value[0] : value[1], 'days');
        let end = moment().endOf('day').add(value[1] > value[0] ? value[1] : value[0], 'days');

        this.setState({ sliderValue: value, start, end });
    }

    handleSliderChangeCommited = async () => {
        this.props.parent.setState({ start: moment(this.state.start).toDate(), end: moment(this.state.end).toDate() });
        await this.getEvents();
    }

    renderKanbanLoading = () => {
        return <DefaultLoader size={13} css={{ marginLeft: 6 }} color={`rgba(0, 0, 0, 0.54)`} />;
    }

    renderToDo = () => {
        return (
            <div>
                <div style={styles.kanbanTitleContainer}>
                    <FormLabel style={styles.kanbanTitle}>À FAZER · {this.props.parent.state.loading ? this.renderKanbanLoading() : this.state.toDo.length}</FormLabel>
                </div>
                <div style={styles.kanbanCardsContainer}>
                    {this.state.toDo.map(event => <KanbanCard event={event} parent={this} />)}
                </div>
            </div>

        );
    }

    renderNext = () => {
        return (
            <div>
                <div style={styles.kanbanTitleContainer}>
                    <FormLabel style={styles.kanbanTitle}>NAS PRÓXIMAS 4 HORAS · {this.props.parent.state.loading ? this.renderKanbanLoading() : this.state.next.length}</FormLabel>
                </div>
                <div style={styles.kanbanCardsContainer}>
                    {this.state.next.map(event => <KanbanCard event={event} parent={this} />)}
                </div>
            </div>

        );
    }

    renderDoing = () => {
        return (
            <div>
                <div style={styles.kanbanTitleContainer}>
                    <FormLabel style={styles.kanbanTitle}>ACONTECENDO AGORA · {this.props.parent.state.loading ? this.renderKanbanLoading() : this.state.doing.length}</FormLabel>
                </div>
                <div style={styles.kanbanCardsContainer}>
                    {this.state.doing.map(event => <KanbanCard event={event} parent={this} />)}
                </div>
            </div>

        );
    }

    renderDone = () => {
        return (
            <div>
                <div style={styles.kanbanTitleContainer}>
                    <FormLabel style={styles.kanbanTitle}>FINALIZADO · {this.props.parent.state.loading ? this.renderKanbanLoading() : this.state.done.length}</FormLabel>
                </div>
                <div style={styles.kanbanCardsContainer}>
                    {this.state.done.map(event => <KanbanCard event={event} parent={this} />)}
                </div>
            </div>
        );
    }

    renderTeachers = () => {
        return this.state.teacherDocs.map((teacher, index) => (
            <Tooltip title={teacher.name}>
                <img style={{ ...styles.teachersSelector, zIndex: !this.props.parent.state.eventOpen ? this.state.teacherDocs.length - index : 0, border: this.state.selectedTeachers.includes(teacher.id) ? `3px solid ${Colors.primary}` : `` }} src={teacher.photo || process.env.PUBLIC_URL + '/empty_avatar.png'} onClick={() => { this.selectElement(teacher.id, `selectedTeachers`) }} />
            </Tooltip>
        ));

    }

    renderCourts = () => {
        return this.state.courtDocs.map(court => (
            <div style={{ ...styles.courtsSelector, color: this.state.selectedCourts.includes(court.id) ? `#fff` : `#222222`, backgroundColor: this.state.selectedCourts.includes(court.id) ? Colors.primary : `#fafafa` }} onClick={() => { this.selectElement(court.id, `selectedCourts`) }}>
                {court.name}
            </div>
        ));
    }

    renderTypes = () => {
        return this.state.types.map(type => (
            <Tooltip title={type.title}>
                <div style={{ ...styles.typeSelector, border: `2px solid ${type.color}`, color: this.state.selectedTypes.includes(type.type) ? `#fff` : `#222222`, backgroundColor: this.state.selectedTypes.includes(type.type) ? type.color : `#fafafa` }} onClick={() => { this.selectElement(type.type, `selectedTypes`) }}>
                    {this.renderIcon(type.type)}
                </div>
            </Tooltip>

        ));
    }

    selectElement = (element, stateField) => {
        let state = this.state;

        if (state[stateField].includes(element)) {
            const index = state[stateField].indexOf(element);
            if (index > -1) state[stateField].splice(index, 1);
        } else {
            state[stateField].push(element);
        }

        this.setState(state);

        this.filterEvents(this.props.parent.state.events);
    }

    dynamicSearch = async (text) => {
        this.setState({ search: text });

        const term = text;
        const minChars = 3;

        if (term == ``) {
            this.setState({ search: term });
            this.getEvents();
        }

        if (term.length < minChars) {
            this.setState({ isTyping: false });
            return;
        }

        this.setState({ isTyping: true });

        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        const that = this;

        this.setState({
            typingTimeout: setTimeout(async () => {
                that.setState({ isTyping: false });
                that.search();
            }, 1000)
        });
    }

    search = () => {
        this.filterEvents(this.props.parent.state.events);
    }

    render() {
        return (
            <div style={styles.wrapper}>
                <div style={styles.header}>
                    <div style={styles.sliderContainer}>
                        <FormLabel style={{ marginBottom: 5, fontSize: 18 }}>Eventos de {moment(this.state.start).format('DD/MM/YY')} até {moment(this.state.end).format('DD/MM/YY')}</FormLabel>
                        <DefaultSlider onChange={this.handleSliderChange} onChangeCommitted={this.handleSliderChangeCommited} defaultValue={[...this.state.sliderValue]} value={this.state.sliderValue} max={30} min={-30} />
                    </div>
                    <div style={styles.searchContainer}>
                        {this.state.loadingSearch ? <DefaultLoader size={28} color={'#0c0c0c'} css={{ display: 'flex', marginTop: 5, marginRight: 10, justifyContent: 'center', alignItems: 'center' }} /> : <div style={{ display: 'flex', height: '100%', justifyContent: 'center', marginRight: 5 }}><SearchIcon style={{ marginTop: 25, fontSize: 28, color: '#0c0c0c' }} /></div>}
                        <DefaultInput label={'Busque por usuários...'} type={'text'} value={this.state.search} onChange={(text) => { this.dynamicSearch(text) }} />
                    </div>

                </div>
                <div style={styles.header}>
                    <div style={styles.typesContainer}>
                        {this.renderTypes()}
                    </div>
                    <div style={styles.courtsContainer}>
                        {this.renderCourts()}
                    </div>
                    <div style={styles.teachersContainer}>
                        {this.renderTeachers()}
                    </div>
                </div>

                <div style={styles.body}>
                    <div style={styles.kanbanColumn}>
                        {this.renderToDo()}
                    </div>
                    <div style={styles.kanbanColumn}>
                        {this.renderNext()}
                    </div>
                    <div style={styles.kanbanColumn}>
                        {this.renderDoing()}
                    </div>
                    <div style={styles.kanbanColumn}>
                        {this.renderDone()}
                    </div>
                </div>
            </div>
        );
    }
}

const styles = {
    wrapper: {
        maxHeight: window.screen.width < 1360 ? window.screen.height: window.innerHeight,
        marginTop: -10,
        heigth: `100%`,
        display: `flex`,
        flexDirection: `column`
    },
    header: {
        maxHeight: 120,
        width: `100%`,
        display: `flex`,
        flexDirection: `row`,
        paddingBottom: 10,
        justifyContent: 'space-evenly',
    },
    body: {
        display: `flex`,
        heigth: `100%`,
        marginTop: 10,
        justifyContent: `flex-start`,
        overflowX: `auto`,
        flexDirection: `row`
    },
    kanbanColumn: {
        minWidth: 350,
        width: 350,
        minHeight: window.screen.width < 1360 ? window.screen.height - (690) : window.innerHeight - (390),
        maxHeight: window.screen.width < 1360 ? window.screen.height - (690) : window.innerHeight - (390),
        marginRight: 20,
        borderRadius: 10,
        marginBottom: 10,
        flexDirection: `column`,
        backgroundColor: `rgb(244, 245, 247)`
    },
    kanbanTitleContainer: {
        display: `flex`,
        flexDirection: `row`,
        height: 32,
        padding: 13,
        width: `100%`
    },
    kanbanTitle: {
        display: `flex`,
        flexDirection: `row`,
        textAlign: `left`,
        fontWeight: `bold`,
        fontSize: 13
    },
    searchContainer: {
        display: `flex`,
        justifyContent: 'flex-end',
        width: `20%`,
        marginTop: 5,
        marginBottom: 5,
        flexDirection: 'row'
    },
    teachersSelector: {
        cursor: `pointer`,
        marginRight: -8,
        marginBottom: 6,
        width: 48,
        height: 48,
        borderRadius: 24
    },
    teachersContainer: {
        display: `flex`,
        alignSelf: `center`,
        alignContent: `center`,
        textAlign: 'center',
        flexDirection: `row`,
        paddingLeft: 10,
        paddingTop: 3,
        paddingBottom: 3,
        maxWidth: `30%`,
        minWidth: `20%`,
        overflowX: `auto`
    },
    courtsContainer: {
        display: `flex`,
        justifyContent: 'center',
        textAlign: 'center',
        flexDirection: `row`,
        flexWrap: `wrap`,
        maxWidth: `53%`,
        maxHeight: 110,
        overflowY: 'auto'
    },
    typesContainer: {
        display: `flex`,
        justifyContent: 'center',
        textAlign: 'center',
        flexDirection: `row`,
        flexWrap: `wrap`,
        maxWidth: `17%`,
        minWidth: `17%`,
    },
    courtsSelector: {
        cursor: `pointer`,
        marginRight: 6,
        marginBottom: 6,
        minHeight: `auto`,
        border: `2px solid ${Colors.primary}`,
        maxHeight: 32,
        textAlign: `center`,
        justifyContent: `center`,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 3,
        paddingBottom: 3,
        borderRadius: 15
    },
    typeSelector: {
        cursor: `pointer`,
        marginRight: 6,
        marginBottom: 6,
        minHeight: `auto`,
        maxHeight: 32,
        textAlign: `center`,
        justifyContent: `center`,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 3,
        paddingBottom: 3,
        borderRadius: 15
    },
    sliderContainer: {
        display: `flex`,
        justifyContent: 'center',
        textAlign: 'center',
        flexDirection: `column`,
        width: `70%`
    },
    kanbanCardsContainer: {
        overflowY: `auto`,
        width: `100%`,
        maxHeight: window.screen.width < 1360 ? window.screen.height - (690 + 32) : window.innerHeight - (390 + 32),
        paddingLeft: 10,
        paddingBottom: 10,
        paddingRight: 10
    },
    titleIcon: {
        fontSize: 18,
        marginRight: 3
    },
}