import React, { Component, forwardRef } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Card, Button, FormControlLabel, InputLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DateRangeIcon from '@material-ui/icons/DateRange';
import FaceIcon from '@material-ui/icons/Face';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import Firestore from '../api/firebase/Firestore';
import moment from "moment-timezone";
import AddIcon from '@material-ui/icons/Add';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import Calendar from '../components/Calendar';
import Storage from '../api/firebase/Storage';
import IosSwitch from '../components/IosSwitch';
import Functions from '../api/firebase/Functions';
import InputMask from 'react-input-mask';
import HeaderActionsWrapper from '../components/HeaderActionsWrapper';
import DataHelper from '../helper/DataHelper';

export default class TeacherPage extends Component {

    state = {
        docs: [],
        name: '',
        email: '',
        phone: '',
        cpf: '',
        sex: 'F',
        productImage: '',
        active: true,
        blobImage: null,
        photoModalURI: null,
        photoModal: false,
        loading: true,
        loadingModal: false,
        addModal: false,
        agendaModal: false,
        loginModal: false,
        loginEmail: '',
        password: '',
        passwordConfirmation: '',
        inputError: false,
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('user').where('id_company', '==', SessionHelper.getData().id_company).where('type', '==', 'teacher').orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    componentDidMount() {

        this.getDocs();
    }

    async uploadPhoto() {

        if (this.state.editId && this.state.blobImage) {

            try {

                this.setState({ loadingModal: true });

                let ref = await Storage.uploadFile('teacher_images', this.state.blobImage);
                let downloadPath = await Storage.getFileFromRef(ref);
        
                if (!downloadPath) {
                    throw new Error('upload error');
                }

                await Firestore.update({ photo: downloadPath }, 'user', this.state.editId);
    
                toast.success("Imagem atualizada com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ photoModal: false, loadingModal: false });

                this.getDocs();
    
            } catch (error) {
                toast.error("Houve um problema ao salvar a imagem", {
                    position: toast.POSITION.TOP_RIGHT
                });


                this.setState({ loadingModal: false });
            }
        }
    }

    appLogin(data) {

        if (data.app_login) {

            toast.warn('Professor já possui login no app');

        } else {

            this.setState({ loginModal: true, loginEmail: data.email, editId: data.id });
        }
    }

    async addTeacher() {

        if (!(await this.checkEmailExists(this.state.email))) {

            if (!this.state.inputError) {

                try {
    
                    this.setState({ loadingModal: true });
    
                    let data = {
                        name: this.state.name,
                        email: this.state.email,
                        phone: this.state.phone.replace(/[^\d]/g, ''),
                        cpf: this.state.cpf.replace(/[^\d]/g, ''),
                        id_company: SessionHelper.getData().id_company,
                        date: new Date(),
                        type: 'teacher',
                        sex: this.state.sex,
                        app_login: false,
                        active: this.state.active,
                    };
    
                    if (this.state.blobImage) {
    
                        let ref = await Storage.uploadFile('teacher_images', this.state.blobImage);
                        let downloadPath = await Storage.getFileFromRef(ref);
            
                        if (!downloadPath) {
                            throw new Error('upload error');
                        }
    
                        data.photo = downloadPath;
                    }
    
                    await Firestore.insert(data, 'user');
    
                    toast.success("Cadastrado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });
    
                    await this.setState({ addModal: false, loadingModal: false, name: '', email: '', phone: '', cpf: '', blobImage: null, productImage: '' });
                    await this.getDocs();
    
                } catch (error) {
                    toast.error("Houve um problema ao cadastrar", {
                        position: toast.POSITION.TOP_RIGHT
                    });
    
                    this.setState({ loadingModal: false, blobImage: null, productImage: '' });
                }
            } else {
    
                toast.warn("Preencha todos os campos corretamente", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }

        } else {

            toast.warn('O e-mail informado já existe');
        }
    }

    photoModal() {

        if (this.state.photoModal) {
            return (
                <div style={{}}>
                    
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        { !this.state.photoModalURI ? <p style={{ textAlign: 'center', color: 'grey', paddingTop: 100 }}>Nenhuma imagem adicionada</p> : null }
                        <img style={{ paddingTop: 15, paddingBottom: 15, height: 350 }} src={this.state.photoModalURI}/>
                    </div>

                    <input
                    accept="image/*"
                    style={{display: 'none'}}
                    id="contained-button-file"
                    type="file"
                    onChange={(v) => { this.setState({ blobImage: v.target.files[0], photoModalURI: URL.createObjectURL(v.target.files[0]) }) }}
                    />
                    <label htmlFor="contained-button-file">
                        <Button startIcon={<CloudUploadIcon/>} component="span" style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '100%'}} variant={'contained'}>{'UPLOAD'}</Button>
                    </label>
    
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.uploadPhoto() }} style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ photoModal: false, blobImage: null, photoModalURI: '' }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    loginModal() {
        return (
            <div>
                <DefaultInput required email onError={(error, msg) => { this.setState({ inputError: error }) }} value={this.state.loginEmail} disabled={true} label={'E-mail'}/>
                <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} type={'password'} onBlur={(v) => { this.setState({ password: v }) }} label={'Senha'}/>
                <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} type={'password'} onBlur={(v) => { this.setState({ passwordConfirmation: v }) }} label={'Confirme a senha'}/>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.activateLogin() }} style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ loginModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    addModal() {

        if (this.state.addModal) {

            return (
                <div>
                    <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ name: v }) }} label={'Nome'}/>
                    <DefaultInput required email onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ email: v }) }} label={'E-mail'}/>
                    
                    <InputMask onBlur={(v) => { this.setState({ phone: DataHelper.treatPhone(v) }) }} mask="(99) 9 9999-99999999" maskPlaceholder={''}>
                        <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={11} label={'Telefone'} />
                    </InputMask>
                    
                    <InputMask onBlur={(v) => { this.setState({ cpf: v }) }} mask="999.999.999-99" maskPlaceholder={''}>
                        <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={11} label={'Cpf'} />
                    </InputMask>

                    <div style={{paddingTop: 8}}>
                        <FormLabel component="legend">Sexo</FormLabel>
                        <RadioGroup style={{display: 'flex', flexDirection: 'row'}} aria-label="sex" name="sex"  value={this.state.sex} onChange={(evt) => { this.setState({ sex: evt.target.value }) }}>
                            <FormControlLabel value="F" control={<Radio style={{color: Colors.primary}}/>} label="Feminino" />
                            <FormControlLabel value="M" control={<Radio style={{color: Colors.primary}}/>} label="Masculino" />
                            <FormControlLabel value="O" control={<Radio style={{color: Colors.primary}}/>} label="Outro" />
                        </RadioGroup>
                    </div>

                    <InputLabel style={{paddingTop: 15}} htmlFor="upload-button">Imagem</InputLabel>
                    { this.state.productImage ? <img style={{ paddingTop: 15, paddingBottom: 15, height: 250 }} src={this.state.productImage}/> : <p style={{paddingTop: 30, paddingBottom: 30, textAlign: 'center', color: 'grey'}}>{'Nenhuma imagem selecionada.'}</p> }

                    <input
                    accept="image/*"
                    style={{display: 'none'}}
                    id="contained-button-file"
                    type="file"
                    onChange={(v) => { this.setState({ blobImage: v.target.files[0], productImage: URL.createObjectURL(v.target.files[0]) }) }}
                    />
                    <label htmlFor="contained-button-file">
                        <Button startIcon={<CloudUploadIcon/>} component="span" style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '100%'}} variant={'contained'}>{'UPLOAD'}</Button>
                    </label>

                    <div style={{ marginTop: 20 }}/>
                    <IosSwitch label={'Ativo'} checked={this.state.active} onChange={(v) => { this.setState({ active: v }) }}/>

                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.addTeacher() }} style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ addModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    agendaModal() {

        if (this.state.agendaModal) {

            return (
                <div>
                    <Calendar noFilter={true} hiddenButton={true} filterTeacher={this.state.editId}/>
                </div>
            )
        }
    }

    getSexLookup() {
        
        return {
            'M': 'Masculino',
            'F': 'Feminino',
            'O': 'Outro',
        }
    }

    async checkEmailExists(email) {
        
        const query = await Firestore.customQuery('user').where('email', '==', email).get();

        if (query.docs.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    async activateLogin()  {

        if (this.state.password && this.state.passwordConfirmation && this.state.loginEmail) {

            if (this.state.password === this.state.passwordConfirmation) {

                if (this.state.password.toString().length >= 6) {

                    this.setState({ loadingModal: true });

                    let data = {
                        email: this.state.loginEmail,
                        password: this.state.password,
                        id: this.state.editId,
                    };

                    try {

                        await Functions.request('POST', 'registerStudent', data);

                    } catch (error) {

                        console.log(error);
                    }

                    toast.success('Login ativado');

                    this.setState({ loginModal: false, loginEmail: '', password: '', passwordConfirmation: '', editId: null, loadingModal: false });
                    this.getDocs();


                } else {

                    toast.warn('A senha deve conter ao menos 6 caracteres');
                }

            } else {

                toast.warn('As senhas são diferentes');
            }

        } else {

            toast.warn('Preencha todos os campos');
        }
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <HeaderActionsWrapper style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon/>} onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'}/>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        
                    </div>
                </HeaderActionsWrapper>

                <DefaultTable
                title={'Professores'}
                marginTop={10}
                
                actions={[
                    {
                        icon: DateRangeIcon,
                        tooltip: 'Ver Agenda',
                        onClick: (event, rowData) => { this.setState({ agendaModal: true, editId: rowData.id  }) } 
                    },
                    {
                        icon: FaceIcon,
                        tooltip: 'Foto',
                        onClick: (event, rowData) => { this.setState({ photoModal: true, photoModalURI: rowData.photo, editId: rowData.id }) } 
                    },
                    {
                        icon: PhoneIphoneIcon,
                        tooltip: 'Criar Login no App',
                        onClick: (event, rowData) => { this.appLogin(rowData) } 
                    },
                ]}
                filtering={true}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Nome', field: 'name' },
                    { title: 'E-mail', field: 'email', editable: (event, rowData) => (!rowData.app_login) },
                    { title: 'Telefone', field: 'phone' },
                    { title: 'Cpf', field: 'cpf' },
                    { title: 'Sexo', field: 'sex', lookup: this.getSexLookup() },
                    { title: 'Ativo', field: 'active', type: 'boolean', render: rowData => <IosSwitch disabled={true} checked={rowData.active}/>, editComponent: props => (<IosSwitch checked={props.value} onChange={(e) => { props.onChange(e) }} /> ) },
                    { title: 'Login no App', field: 'app_login', editable: false, render: rowData => <IosSwitch disabled={true} checked={rowData.app_login}/> },
                    { title: 'Data Cadastro', field: 'date', editable: false, render: rowData => <div>{moment(rowData.date.toDate()).format('DD/MM/YYYY HH:mm')}</div> },
                ]}
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                    newData.date = oldData.date;

                    let prev = this.state.docs;
                    prev[prev.indexOf(oldData)] = newData;

                    this.setState({ docs: prev });

                    if (oldData.id) {
                        
                        let update = {
                            name: newData.name,
                            email: newData.email,
                            phone: newData.phone,
                            cpf: newData.cpf,
                            sex: newData.sex,
                            active: newData.active,
                        };

                        await Firestore.update(update, 'user', oldData.id);
                    }

                    toast.success("Editado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.loginModal()} title={'Criar Login'} onClose={() => { this.setState({ loginModal: false }) }} open={this.state.loginModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.agendaModal()} title={'Agenda'} onClose={() => { this.setState({ agendaModal: false }) }} open={this.state.agendaModal} width={'90%'}/>
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Professor'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.photoModal()} title={'Foto'} onClose={() => { this.setState({ photoModal: false, blobImage: null, photoModalURI: '' }) }} open={this.state.photoModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
