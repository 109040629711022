import React, { Component } from 'react';
import IosSwitch from '../IosSwitch';
import DefaultButton from '../DefaultButton';
import DefaultLoader from '../DefaultLoader';
import ReportHelper from '../../helper/ReportHelper';
import moment from "moment-timezone";
import Firestore from '../../api/firebase/Firestore';
import PaymentMethodHelper from '../../helper/PaymentMethodHelper';
import { toast } from 'react-toastify';
import { FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import ReportDefaultModalOptions from './ReportDefaultModalOptions';
import Colors from '../../constants/Colors';
import CurrencyHelper from '../../helper/CurrencyHelper';

export default class TournamentEnrollReport extends Component {

    state = {
        loadingModal: true,
        tournamentDocs: [],
        tournamentDoc: null,
        tournament: null,
        paymentMethods: [],
        start: moment().startOf('month').startOf('day').toDate(),
        end: moment().endOf('day').toDate(),
        output: 'download',
        format: 'pdf',
        order: 'date',
    }

    async componentDidMount() {

        if (this.props.getDocs && this.props.getDocs) {

            for (let index = 0; index < this.props.getDocs.length; index++) {
                
                const getDoc = this.props.getDocs[index];
                await getDoc(this);
            }
        }

        this.setState({ loadingModal: false });
    }

    async getReportDocs() {

        let docs = [];
        let count = 0;
        let totalAmount = 0;
        let tournament = this.state.tournamentDoc;

        for (let category of tournament.category) {
            
            if (category.print) {

                for (let categoryClass of category.classes) {
                
                    if (categoryClass.print) {

                        for (let subscription of categoryClass.participants) {

                            let date = moment(subscription.date.toDate()).startOf('day');

                            if (!this.state.start || moment(date).isSameOrAfter(moment(this.state.start).startOf('day'))) {

                                if (!this.state.end || moment(date).isSameOrBefore(moment(this.state.end).startOf('day'))) {

                                    let players = await Firestore.customQuery('user').where(Firestore.idField(), 'in', subscription.players).get();

                                    let orderQuery = await Firestore.getDoc('order', subscription.id_order);
                                    let order = orderQuery.data();

                                    if (!this.state.paymentMethods.length || (order && this.state.paymentMethods.includes(order.payment_method))) {
                                    
                                        let playersDisplay = [];
                                        players.forEach((doc, key) => { playersDisplay.push(doc.data().name) });
                                        playersDisplay = playersDisplay.join(', ');

                                        let categoryLabel = category.name === 'M' ? 'Masc.' : category.name === 'F' ? 'Fem.' : 'Mis.';

                                        docs.push({
                                            name: playersDisplay,
                                            category: `${categoryLabel} - ${categoryClass.title}`,
                                            payment_method: `${order ? PaymentMethodHelper.getPaymentMethodLabel(order.payment_method).replace(' de Crédito', '') : ''}`,
                                            order: `${order ? order.title.replace('Pedido ', '') : ''}`,
                                            amount: `${order ? CurrencyHelper.centsToMoney(order.amount) : ''}`,
                                            date: `${date.format('DD/MM/YYYY HH:mm')}`,
                                        });

                                        count ++;
                                        totalAmount += order ? order.amount : 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        if (this.state.order === 'date') {

            docs.sort((a, b) => {

                return moment(b.date, 'DD/MM/YYYY HH:mm').toDate() - moment(a.date, 'DD/MM/YYYY HH:mm').toDate();
            });
        
        } else {

            docs.sort((a, b) => {

                if(a.name < b.name) { return -1; }
                if(a.name > b.name) { return 1; }
                return 0;
            });
        }

        return { docs: docs, totalizer: [{ count: count, total_amount: totalAmount }] };
    }

    async print() {

        if (this.state.tournamentDoc) {

            this.setState({ loadingModal: true });

            let name = `Inscrições - ${this.state.tournamentDoc.name}`;
            let subtitle = `De ${moment(this.state.start).format('DD/MM/YYYY')} até ${moment(this.state.end).format('DD/MM/YYYY')}`;
            let docs = await this.getReportDocs();
            let report = await ReportHelper.createReport(name, 6, subtitle, this.state.format);

            report = await ReportHelper.createColumns(report, docs.docs, [
                { name: 'Nome', width: 13, getText: (doc) => doc.name ? this.state.format === 'pdf' ? ReportHelper.cutName(ReportHelper.getShortName(doc.name), 18) : doc.name : '' },
                { name: 'Categoria', width: 50, getText: (doc) => this.state.format === 'pdf' ? ReportHelper.cutName(doc.category, 29) : doc.category },
                { name: 'Pagamento', width: 110, getText: (doc) => doc.payment_method },
                { name: 'Valor', width: 132, getText: (doc) => doc.amount },
                { name: 'Pedido', width: 152, getText: (doc) => doc.order },
                { name: 'Data', width: 169, getText: (doc) => doc.date },
            ]);

            report = await ReportHelper.createColumns(report, docs.totalizer, [
                { name: 'Inscrições', width: 169, getText: (doc) => doc.count.toString() },
                { name: 'Valor Total', width: 132, getText: (doc) => CurrencyHelper.centsToMoney(doc.total_amount)},
                { name: '', width: 0, getText: (doc) => '' },
                { name: '', width: 0, getText: (doc) => '' },
                { name: '', width: 0, getText: (doc) => '' },
            ], null, false, 6);

            ReportHelper.print(report, this.state.output, this.state.format);

            this.setState({ loadingModal: false });

        } else {

            toast.warn('Selecione um torneio para imprimir');
        }
    }

    renderLoading() {

        return (

            <div style={{ height: 200 }}>
                <DefaultLoader/>
            </div>
        )
    }

    handleTournamentChange(value) {

        let doc = this.state.tournamentDocs.find(item => item.id === value);

        for (let categoryIndex = 0; categoryIndex < doc.category.length; categoryIndex++) {
            
            doc.category[categoryIndex].print = true;

            for (let classIndex = 0; classIndex < doc.category[categoryIndex].classes.length; classIndex++) {

                doc.category[categoryIndex].classes[classIndex].print = true;
            }
        }

        this.setState({ tournamentDoc: doc, start: doc.ticket_start.toDate(), end: doc.ticket_limit.toDate() });
    }

    renderModal() {

        return (
            <div>
                { ReportHelper.renderTournamentSelection(this, this.state.tournamentDocs, 'tournament', (value) => { this.handleTournamentChange(value) }) }

                { this.state.tournamentDoc ? <FormLabel style={{ paddingBottom: 18 }} component="legend">Selecione as Classes</FormLabel> : null }
                {
                    this.state.tournamentDoc ?

                        this.state.tournamentDoc.category.map((category, key) => {

                            if (category.checked) {

                                return (

                                    <div>
                                        <IosSwitch fullWidth label={category.title} checked={category.print} onChange={(e) => { let tournament = this.state.tournamentDoc; tournament.category[key].print = e; this.setState({ tournamentDoc: tournament }) }} />
                                        
                                        {
                                            this.state.tournamentDoc.category[key].print ? 

                                                this.state.tournamentDoc.category[key].classes.map((categoryClass, classKey) => {
                                                    
                                                    if (categoryClass.checked) {

                                                        return (
                                                            <div style={{ marginLeft: 20 }}>
                                                                <IosSwitch fullWidth label={categoryClass.title} checked={categoryClass.print} onChange={(e) => { let tournament = this.state.tournamentDoc; tournament.category[key].classes[classKey].print = e; this.setState({ tournamentDoc: tournament }) }} />
                                                            </div>
                                                        )
                                                    }
                                                })

                                            : null
                                        }
                                        
                                    </div>
                                )
                            }
                        })

                    : null
                }

                { ReportHelper.renderPaymentMethodSelection(this, 'paymentMethods', 'tournament') }
                { ReportHelper.renderPeriodSelection(this, 'start', 'end') }

                <FormLabel style={{ marginTop: 18, fontWeight: '800' }} component="legend">Ordenação</FormLabel>
                <div style={{ flexDirection: 'row', paddingTop: 10 }}>
                    <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} value={this.state.order} onChange={(evt) => { this.setState({ order: evt.target.value }) }}>
                        <FormControlLabel value="date" control={<Radio style={{ color: Colors.primary }} />} label="Data" />
                        <FormControlLabel value="user" control={<Radio style={{ color: Colors.primary }} />} label="Nome" />
                    </RadioGroup>
                </div>

                <ReportDefaultModalOptions format={this.state.format} output={this.state.output} onOutputChange={(value) => { this.setState({ output: value }) }} onFormatChange={(value) => { this.setState({ format: value }) }}/>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <DefaultButton width={'100%'} title={'Imprimir'} onClick={() => { this.print() }} />
                </div>
            </div>
        )
    }

    render() {

        return (

            this.state.loadingModal ? this.renderLoading() : this.renderModal()
        )
    }
}