import React, { Component } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AppsIcon from '@material-ui/icons/Apps';
import { FormLabel } from '@material-ui/core';
import Colors from '../constants/Colors';

export default class HeaderActionsWrapper extends Component {
    state = {
        open: false
    };

    renderMobile() {
        return (
            <Accordion style={{ borderRadius: 5 }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    style={{ height: 66 }}
                >
                    <div style={{ fontSize: 20, textAlign: 'left', alignItems: 'center', justifyContent: 'center', alignSelf: 'center', alignContent: 'center', display: 'flex', flexDirection: 'row' }}>
                        <AppsIcon style={{ fontSize: 26, color: Colors.formLabel, marginRight: 5 }} />
                        <FormLabel style={{ fontSize: 20, color: Colors.formLabel }}>Opções da Tela</FormLabel>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ display: 'flex', flexDirection: 'row', ...this.props.style, backgroundColor: 'transparent' }} className={'header-actions-buttons'}>
                        {this.props.children}
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    }

    renderDesktop() {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', ...this.props.style }} className={'header-actions-buttons'}>
                {this.props.children}
            </div>
        );
    }

    render() {
        return window.screen.width < 1366 ? this.renderMobile() : this.renderDesktop();
    }
}