import React, { Component } from 'react';
import Colors from '../constants/Colors';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import DefaultLoader from './DefaultLoader';

export default class SearchBar extends Component {

    state = {
        search: '',
        docs: this.props.docs || [],
    }

    componentWillUnmount() {

        this.clearSearch();
    }

    clearSearch() {

        this.setState({ search: '' });

        if (this.props.onEmpty) {

            this.props.onEmpty(this.state.docs);
        }
    }

    render() {
        return (
            <div>
                <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', borderRadius: 10, height: 50, backgroundColor: '#FFF', boxShadow: Colors.boxShadow, border: '1px solid lightgrey', ...this.props.style }}>
                    <input disabled={this.props.loading || false} value={this.state.search} onChange={(evt) => { this.handleSearch(evt.target.value) }} style={{ height: '100%', width: '95%', paddingTop: 5, paddingBottom: 5, marginRight: 5, paddingLeft: 15, paddingRight: 15, fontSize: 16, border: 'none', borderRadius: 10 }} placeholder={this.props.loading ? `Carregando dados para busca...` : this.props.placeholder} />
                    {this.props.loading ? <DefaultLoader size={28} css={{ marginRight: 5, marginTop: 5 }} /> : this.state.search.length ? <ClearIcon onClick={() => { this.clearSearch() }} style={{ fontSize: 34, cursor: 'pointer', marginRight: 5, ...this.props.iconStyle }} /> : <SearchIcon style={{ fontSize: 34, cursor: 'pointer', marginRight: 5, ...this.props.iconStyle }} />}
                </div>
            </div>
        )
    }

    async handleSearch(text) {

        await this.setState({ search: text });

        if (this.state.search.toString().length > 2) {

            let result = [];

            if (this.state.docs.length) {

                let array = this.state.docs;

                if (this.props.customSearch) {

                    result = this.props.customSearch(array, text);

                } else {

                    result = array.filter((doc) => {

                        const name = doc[this.props.search] ? doc[this.props.search].toUpperCase() : ''.toUpperCase();
                        const search = this.state.search.toString().toUpperCase();

                        if (name.indexOf(search) > -1) {

                            return true;
                        }
                    });
                }
            }

            if (this.props.onSearch) {

                this.props.onSearch(result);
            }

        } else if (this.state.search.toString().length === 0) {

            this.clearSearch();
        }
    }
}
