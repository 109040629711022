import React, { Component } from 'react';
import DefaultLoader from '../DefaultLoader';
import { Card, IconButton, Tooltip, Grid } from '@material-ui/core';
import Firestore from '../../api/firebase/Firestore';
import moment from "moment-timezone";
import { Doughnut, Bar } from 'react-chartjs-2';
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import CalendarEvent from '../CalendarEvent';
import SessionHelper from '../../helper/SessionHelper';
import Colors from '../../constants/Colors';

moment.locale('pt-br');
const localizer = momentLocalizer(moment);

const gridSize = window.screen.width < 1080 ? 12 : 6;

export default class HomePage extends Component {

    state = {
        loading: true,
        courtRentDocs: [],
        studentDocs: [],
        lessonDocs: [],
        lessonChart: [],
        teacherDocs: [],
        courtDocs: [],
        lessonChart_start: moment().startOf('day').toDate(),
        lessonChart_end: moment().endOf('day').toDate(),
        teacherLessonChart: {
            labels: [],
            datasets: [
                {
                    label: 'Aulas',
                    backgroundColor: '#36A2EB',
                    borderWidth: 1,
                    data: []
                }
            ]
        },
        teacherLessonChart_start: moment().startOf('day').toDate(),
        teacherLessonChart_end: moment().endOf('day').toDate(),
        newStudentsChart: {
            labels: ['Hoje', 'Essa semana', 'Esse mês', 'Ano todo'],
            datasets: [
                {
                    label: 'Número de matrículas',
                    backgroundColor: '#FF6384',
                    borderWidth: 1,
                    data: [],
                }
            ]
        },
        events: [],
    }

    async getStudents() {

        let query = await Firestore.customQuery('user').where('type', '==', 'student').where('id_companies', 'array-contains', SessionHelper.getData().id_company).get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ studentDocs: docs });
    }

    async getLessons() {

        let query = await Firestore.customQuery('lesson').where('id_company', '==', SessionHelper.getData().id_company).get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ lessonDocs: docs });
    }

    async getTeachers() {

        let query = await Firestore.customQuery('user').where('type', '==', 'teacher').where('id_company', '==', SessionHelper.getData().id_company).get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ teacherDocs: docs });
    }

    async getCourts() {

        let query = await Firestore.customQuery('court').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ courtDocs: docs });
    }

    async componentDidMount() {

        this.setState({ loading: true });

        await this.getStudents();
        await this.getLessons();
        await this.getTeachers();
        await this.getCourts();

        await this.lessonChart();
        await this.teacherLessonChart();
        await this.newStudentsChart();
        await this.getCalendarEvents();

        this.setState({ loading: false });
    }

    async lessonChart() {

        let data = {
            now: 0,
            toDo: 0,
            finished: 0,
        };

        this.state.lessonDocs.forEach((lesson, key) => {

            let start = moment(lesson.start && lesson.start.toDate ? lesson.start.toDate() : lesson.start);
            let end = moment(lesson.end && lesson.end.toDate ? lesson.end.toDate() : lesson.end);

            if (((moment(this.state.lessonChart_start).isValid() && (start.isSameOrAfter(moment(this.state.lessonChart_start)))) && moment(this.state.lessonChart_end).isValid() && (start.isSameOrBefore(moment(this.state.lessonChart_end)))) && ((moment(this.state.lessonChart_start).isValid() && end) && (end.isSameOrAfter(moment(this.state.lessonChart_start)))) && moment(this.state.lessonChart_end).isValid() && (end.isSameOrBefore(moment(this.state.lessonChart_end)))) {

                if (moment().isBetween(start, end)) {
                    data.now++;

                } else if (moment().isAfter(end)) {
                    data.finished++;

                } else if (moment().isBefore(start) || moment().isAfter(end)) {
                    data.toDo++;
                }
            }
        });

        this.setState({ lessonChart: [data.now, data.toDo, data.finished] });
    }

    async teacherLessonChart() {

        let data = {
            labels: [],
            datasets: [
                {
                    label: 'Aulas',
                    backgroundColor: '#36A2EB',
                    borderWidth: 1,
                    data: []
                }
            ]
        };

        let teacherLessonChart = {};

        this.state.lessonDocs.forEach((lesson, key) => {

            let start = moment(lesson.start && lesson.start.toDate ? lesson.start.toDate() : lesson.start);
            let end = moment(lesson.end && lesson.end.toDate ? lesson.end.toDate() : lesson.end);

            let teacherDoc = this.state.teacherDocs[this.state.teacherDocs.findIndex(item => item.id === lesson.id_teacher)];

            if (teacherLessonChart[lesson.id_teacher] === undefined) {
                teacherLessonChart[lesson.id_teacher] = 0;
                if(teacherDoc && teacherDoc.name) data.labels.push(teacherDoc.name);
            }

            if ((moment(this.state.teacherLessonChart_start).isValid() && (start.isSameOrAfter(moment(this.state.teacherLessonChart_start)))) && moment(this.state.teacherLessonChart_end).isValid() && (end.isSameOrBefore(moment(this.state.teacherLessonChart_end)))) {

                if (lesson.id_teacher === teacherDoc.id) {
                    teacherLessonChart[lesson.id_teacher]++;
                }
            }
        });

        Object.keys(teacherLessonChart).forEach((value, key) => {
            data.datasets[0].data.push(teacherLessonChart[value]);
        });

        this.setState({ teacherLessonChart: data });
    }

    async newStudentsChart() {

        let counts = {
            today: 0,
            week: 0,
            month: 0,
            year: 0,
        };

        this.state.studentDocs.forEach((student, key) => {

            let date = moment(student.date && student.date.toDate ? student.date.toDate() : student.date);
            let actualDate = moment();

            if (actualDate.isSame(date, 'day')) {
                counts.today++;
            }

            if (actualDate.isSame(date, 'week')) {
                counts.week++;
            }

            if (actualDate.isSame(date, 'month')) {
                counts.month++;
            }

            if (actualDate.isSame(date, 'year')) {
                counts.year++;
            }
        });


        let data = {
            labels: ['Hoje', 'Essa semana', 'Esse mês', 'Ano todo'],
            datasets: [
                {
                    label: 'Alunos',
                    backgroundColor: '#FF6384',
                    borderWidth: 1,
                    data: [counts.today, counts.week, counts.month, counts.year],
                }
            ]
        };

        this.setState({ newStudentsChart: data });
    }

    getCalendarEvents() {

        let events = [];

        this.state.lessonDocs.forEach((lesson, key) => {

            let teacher = this.state.teacherDocs[this.state.teacherDocs.findIndex(doc => doc.id === lesson.id_teacher)];
            let court = this.state.courtDocs[this.state.courtDocs.findIndex(doc => doc.id === lesson.id_court)];

            let title = 'Aula - ';
            let students = '';

            lesson.id_students.forEach((student, key) => {

                var doc = this.state.studentDocs.find(doc => doc.id === student);

                if (!doc) {
                    doc = { name: 'Aluno Desvinculado', id: student };
                }

                title += doc.name.split(' ')[0];
                students += doc.name + ` (${lesson.accepted && lesson.accepted.includes(doc.id) ? 'Aceito' : lesson.refused && lesson.refused.includes(doc.id) ? 'Recusado' : 'Pendente'})`;

                if (lesson.id_students[key + 1]) {
                    title += ', ';
                    students += ', ';
                }
            });

            let event = {
                title: title.length < 22 ? title : title.slice(0, 22) + '...',
                start: lesson.start && lesson.start.toDate ? lesson.start.toDate() : lesson.start,
                end: lesson.end && lesson.end.toDate ? lesson.end.toDate() : lesson.end,
                allDay: false,
                id: lesson.id,
                teacher: teacher.name,
                students: students,
                court: court && court.name ? court.name : 'Quadra Desconhecida',
                period: `${moment(lesson.start && lesson.start.toDate ? lesson.start.toDate() : lesson.start).format('DD/MM/YYYY HH:mm')} até ${moment(lesson.end && lesson.end.toDate ? lesson.end.toDate() : lesson.end).format('DD/MM/YYYY HH:mm')}`,
                id_teacher: teacher.id,
                id_student: lesson.id_students,
                id_court: court.id,
                dashboard: true,
            };

            events.push(event);
        });

        this.setState({ events: events });
    }

    render() {

        return this.state.loading ? <DefaultLoader /> : (

            <div style={{ width: '100%' }}>
                <Grid container spacing={1}>
                    <Grid item xs={gridSize}>
                        <Card style={{ padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'auto', boxShadow: Colors.boxShadow }} className={'mobile-dashboard'}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', marginBottom: 20, marginTop: 10 }}>
                                <div style={{ fontSize: 20 }}>{'Aulas Agendadas'}</div>
                                <Tooltip onClick={async () => { await this.getLessons(); await this.lessonChart(); }} title="Atualizar"><IconButton><RefreshIcon style={{}} /></IconButton></Tooltip>
                            </div>
                            <Doughnut
                                height={window.screen.width < 1080 ? 250 : 80}
                                options={{ maintainAspectRatio: true }}
                                data={{
                                    labels: [
                                        'Acontecendo Agora',
                                        'Não Realizadas',
                                        'Finalizadas'
                                    ],
                                    datasets: [{
                                        data: this.state.lessonChart,
                                        backgroundColor: [
                                            '#FF6384',
                                            '#36A2EB',
                                            '#FFCE56'
                                        ],

                                    }]
                                }}
                            />

                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', alignSelf: 'flex-end', marginTop: 20 }}>
                                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        style={{ width: '100%', padding: 15 }}
                                        invalidDateMessage={false}
                                        format={'dd/MM/yyyy'}
                                        autoOk={true}
                                        cancelLabel={'Cancelar'}
                                        okLabel={'Confirmar'}
                                        onChange={async (v) => { await this.setState({ lessonChart_start: v }); this.lessonChart(); }}
                                        value={this.state.lessonChart_start}
                                    />
                                </MuiPickersUtilsProvider>
                                <div style={{ color: 'grey' }}>{'até'}</div>
                                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        style={{ width: '100%', padding: 15 }}
                                        invalidDateMessage={false}
                                        format={'dd/MM/yyyy'}
                                        autoOk={true}
                                        cancelLabel={'Cancelar'}
                                        okLabel={'Confirmar'}
                                        onChange={async (v) => { await this.setState({ lessonChart_end: v }); this.lessonChart(); }}
                                        value={this.state.lessonChart_end}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={gridSize}>
                        <Card style={{ padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'auto', boxShadow: Colors.boxShadow }} className={'mobile-dashboard'}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', marginBottom: 20, marginTop: 10 }}>
                                <div style={{ fontSize: 20 }}>{'Aulas por Professor'}</div>
                                <Tooltip onClick={async () => { await this.getLessons(); await this.getTeachers(); await this.teacherLessonChart(); }} title="Atualizar"><IconButton><RefreshIcon style={{}} /></IconButton></Tooltip>
                            </div>

                            <Bar
                                height={window.screen.width < 1080 ? 250 : 80}
                                options={{ maintainAspectRatio: true, scales: { yAxes: [{ ticks: { beginAtZero: true, precision: 0 } }] } }}
                                data={this.state.teacherLessonChart} />

                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', alignSelf: 'flex-end', marginTop: 20 }}>
                                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        style={{ width: '100%', padding: 15 }}
                                        invalidDateMessage={false}
                                        format={'dd/MM/yyyy'}
                                        autoOk={true}
                                        cancelLabel={'Cancelar'}
                                        okLabel={'Confirmar'}
                                        onChange={async (v) => { await this.setState({ teacherLessonChart_start: v }); this.teacherLessonChart(); }}
                                        value={this.state.teacherLessonChart_start}
                                    />
                                </MuiPickersUtilsProvider>
                                <div style={{ color: 'grey' }}>{'até'}</div>
                                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        style={{ width: '100%', padding: 15 }}
                                        invalidDateMessage={false}
                                        format={'dd/MM/yyyy'}
                                        autoOk={true}
                                        cancelLabel={'Cancelar'}
                                        okLabel={'Confirmar'}
                                        onChange={async (v) => { await this.setState({ teacherLessonChart_end: v }); this.teacherLessonChart(); }}
                                        value={this.state.teacherLessonChart_end}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={gridSize}>
                        <Card style={{ padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', height: 375, boxShadow: Colors.boxShadow }} className={'mobile-dashboard'}>

                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ fontSize: 20 }}>{'Novos Alunos'}</div>
                                <Tooltip onClick={async () => { await this.getStudents(); await this.newStudentsChart(); }} title="Atualizar"><IconButton><RefreshIcon style={{}} /></IconButton></Tooltip>
                            </div>

                            <Bar
                                height={window.screen.width < 1080 ? 250 : 125}
                                width={500}
                                options={{ maintainAspectRatio: true, scales: { yAxes: [{ ticks: { beginAtZero: true, precision: 0 } }] } }}
                                data={this.state.newStudentsChart} />

                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', alignSelf: 'flex-end', marginTop: 20 }}>

                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={gridSize}>
                        <Card style={{ padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'auto', boxShadow: Colors.boxShadow }} className={'mobile-dashboard'}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ fontSize: 20 }}>{'Agenda de Aulas'}</div>
                                <Tooltip onClick={async () => { await this.getLessons(); await this.getCourtRent(); await this.getCalendarEvents(); }} title="Atualizar"><IconButton><RefreshIcon style={{}} /></IconButton></Tooltip>
                            </div>

                            <Calendar
                                messages={{
                                    agenda: 'Agenda',
                                    today: 'Hoje',
                                    month: 'Mês',
                                    week: 'Semana',
                                    day: 'Dia',
                                    next: 'Próximo',
                                    previous: 'Voltar',
                                    noEventsInRange: 'Nenhum evento encontrado para essas datas',
                                    allDay: 'Dia Todo',
                                    date: 'Data',
                                    event: 'Evento',
                                    time: 'Hora',
                                    showMore: (count) => `+${count} eventos`,
                                }}
                                localizer={localizer}
                                events={this.state.events}
                                startAccessor="start"
                                endAccessor="end"
                                eventPropGetter={(event) => {
                                    var color = event.color;
                                    if (!color) {
                                        color = '#36A2EB';
                                    }
                                    var style = {
                                        backgroundColor: color,
                                        borderRadius: '3px',
                                        opacity: 0.8,
                                        color: 'white',
                                        border: '0px',
                                        display: 'block'
                                    };
                                    return {
                                        style: style
                                    };
                                }}
                                defaultView={'agenda'}
                                views={['agenda']}
                                style={{ height: 280, width: '100%' }}
                                onSelectEvent={(evt) => { this.redirectToSAT(evt) }}
                                components={{
                                    event: CalendarEvent
                                }}
                                tooltipAccessor={(e) => { return '' }}
                            />
                        </Card>
                    </Grid>
                </Grid>
            </div>
        )
    }
}