import Firestore from '../api/firebase/Firestore';

export default class NotificationHelper {

    static async send(title, body, data, token, id_user) {
        let badgeCounter = await this.getUserBadgeCounter(id_user);

        const message = {
            to: token,
            title: title,
            body: body,
            data: data,
            badge: badgeCounter,
            sound: 'default',
            priority: 'normal',
        };

        let color = '#000000';
        let icon = 'bell';
        let navigate = 'Highlights';

        if (data && !data.navigate) data.navigate = 'Highlights';

        let notificationData = {
            title: message.title ? message.title : 'Sem título',
            body: message.body ? message.body : 'Sem corpo',
            data: message.data ? message.data : { icon, color, navigate },
            date: new Date(),
            platform: false,
            viewed: false,
            id_user: id_user,
        }

        await Firestore.insert(notificationData, 'notification');

        await fetch('https://exp.host/--/api/v2/push/send', {
            method: 'POST',
            mode: 'no-cors',
            headers: {
                'Accept': 'application/json',
                'Accept-encoding': 'gzip, deflate',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(message),
        });
    }

    static async getUserBadgeCounter(id_user) {
        if (id_user) {
            const query = await Firestore.customQuery('notification').where('id_user', '==', id_user).where('viewed', '==', false).get();
            const counter = query.docs.length || 0;

            return counter;
        } else {
            return 0;
        }
    }
}