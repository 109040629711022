import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import IosSwitch from '../components/IosSwitch';
import DefaultModal from '../components/DefaultModal';
import Colors from '../constants/Colors';
import { List, ListItem, Tooltip } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import moment from "moment-timezone";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SessionHelper from '../helper/SessionHelper';
import firebase from 'firebase/app';
import { toast, ToastContainer } from 'react-toastify';
import CurrencyHelper from '../helper/CurrencyHelper';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Pagarme from '../api/pagarme/Pagarme';
import DefaultButton from '../components/DefaultButton';
import AttachMoney from '@material-ui/icons/AttachMoney';
import Gavel from '@material-ui/icons/Gavel';
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet';
import LiveHelp from '@material-ui/icons/LiveHelp';
import TimerOff from '@material-ui/icons/TimerOff';
import Cancel from '@material-ui/icons/Cancel';
import Room from '@material-ui/icons/Room';
import Email from '@material-ui/icons/Email';
import Storage from '@material-ui/icons/Storage';
import Phone from '@material-ui/icons/Phone';
import Chat from '@material-ui/icons/Chat';
import WhatsApp from '@material-ui/icons/WhatsApp';
import Sync from '@material-ui/icons/Sync';
import Map from '@material-ui/icons/Map';
import SpeakerNotesOff from '@material-ui/icons/SpeakerNotesOff';
import MenuBook from '@material-ui/icons/MenuBook';
import GeographicHelper from '../helper/GeographicHelper';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';
import DeleteIcon from '@material-ui/icons/Delete';
import PhoneEnabled from '@material-ui/icons/PhoneEnabled';
import PhoneDisabled from '@material-ui/icons/PhoneDisabled';
import Functions from '../api/firebase/Functions';
import CustomSuspense from '../components/CustomSuspense';
import DefaultInput from '../components/DefaultInput';
import LocationPicker from '../components/LocationPicker';
import InputMask from "react-input-mask";
import Add from '@material-ui/icons/Add';
import Person from '@material-ui/icons/Person';
import DataHelper from '../helper/DataHelper';

moment.locale('pt-br');

export default class AdminPage extends Component {

    state = {
        loading: false,
        injectedCompany: false,
        filters: [
            { key: 'active', color: Colors.success, label: 'Contratado', icon: <AccountBalanceWallet style={{ padding: 10 }} /> },
            { key: 'trial', color: Colors.danger, label: 'Em Trial', icon: <LiveHelp style={{ padding: 10 }} /> },
            { key: 'ended', color: Colors.disabled, label: 'Trial Finalizado', icon: <TimerOff style={{ padding: 10 }} /> },
            { key: 'canceled', color: Colors.error, label: 'Cancelado', icon: <Cancel style={{ padding: 10 }} /> },
        ],
        paymentChecks: {},
        selectedFilter: `active`,
        active: [],
        trial: [],
        ended: [],
        canceled: [],
        docs: [],
        planDocs: [],
        search: '',
        editId: null,
        selectedView: SessionHelper.getData().type !== 'super_admin' ? `leads` : `clients`,
        views: [
            { key: 'clients', color: Colors.success, label: 'Clientes', icon: <AccountBalanceWallet style={{ padding: 10 }} /> },
            { key: 'leads', color: Colors.primary, label: 'Leads', icon: <LiveHelp style={{ padding: 10 }} /> },
        ],
        showLeads: false,
        syncHistory: [],
        leadDocs: [],
        to_do: [],
        contacted: [],
        showed: [],
        sold: [],
        selectedLeadFilter: `to_do`,
        leadsFilters: [
            { key: 'to_do', color: Colors.danger, label: 'Não Contatado', icon: <SpeakerNotesOff style={{ padding: 10 }} /> },
            { key: 'contacted', color: Colors.primary, label: 'Contatado', icon: <Chat style={{ padding: 10 }} /> },
            { key: 'showed', color: Colors.contrast, label: 'Apresentado', icon: <AssignmentTurnedIn style={{ padding: 10 }} /> },
            { key: 'sold', color: Colors.success, label: 'Vendido', icon: <AccountBalanceWallet style={{ padding: 10 }} /> },
        ],
        phoneLeadFilter: [
            { key: 'has_whatsapp', color: Colors.success, label: 'WhatsApp', icon: <WhatsApp style={{ padding: 10 }} /> },
            { key: 'unavaluable_whatsapp', color: Colors.danger, label: 'Ligação', icon: <PhoneEnabled style={{ padding: 10 }} /> },
            { key: 'no_phone', color: Colors.error, label: 'Sem Telefone', icon: <PhoneDisabled style={{ padding: 10 }} /> },
        ],
        selectedPhoneFilter: 'has_whatsapp',
        syncTerm: ``,
        loadingDoc: ``,
        syncingTerm: false,
        loadingModal: false,
        companyLog: [],
        adminUsers: {},
        companyHistory: false,
        syncModal: false,
        quote: { quote: ``, author: `` },
        addLeadModal: false,
        name: ``,
        coordinates: GeographicHelper.setFirebaseGeopoint(-27, -52),
        phone: ``
    }

    async componentDidMount() {
        this.setQuote();
        await this.getDocs();

        this.adminInterval = setInterval(() => {
            if (!this.state.syncModal && !this.state.companyHistory && !this.state.addLeadModal) {
                if (this.state.selectedView === `leads`) {
                    this.getSyncHistory();
                    this.getLeads();
                }
            }
        }, 60000);
    }

    componentWillUnmount() {
        clearInterval(this.adminInterval);
    }


    async getPlans() {

        let query = await Firestore.customQuery('plan').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ planDocs: docs });
    }

    async getClients() {
        let query = await Firestore.customQuery('company').orderBy('name', 'asc').get();
        let docs = [], active = [], trial = [], ended = [], canceled = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            if (data.id_plan) {
                active.push(data);
            } else if (data.trial) {
                if (this.isInTrial(data.trial_expiration_date)) {
                    trial.push(data);
                } else {
                    ended.push(data);
                }

            } else {

                canceled.push(data);
            }

            docs.push(data);
        });

        this.setState({ active, trial, ended, canceled });
    }

    async getLeads() {
        let query = await Firestore.customQuery('leads').orderBy('name', 'asc').get();
        let leadDocs = [], to_do = [], contacted = [], showed = [], sold = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            if (data.status === `contacted`) {
                contacted.push(data);
            } else if (data.status === `showed`) {
                showed.push(data);
            } else if (data.status === `sold`) {
                sold.push(data);
            } else if (!data.status) {
                let canPush = false;

                if (this.state.selectedPhoneFilter == 'unavaluable_whatsapp' && data[this.state.selectedPhoneFilter] && data.phone) canPush = true;
                if (this.state.selectedPhoneFilter == 'has_whatsapp' && !data.unavaluable_whatsapp && data.phone) canPush = true;
                if (this.state.selectedPhoneFilter == 'no_phone' && !data.phone) canPush = true;

                if (canPush) to_do.push(data);
            }

            leadDocs.push(data);
        });

        this.setState({ leadDocs, to_do, contacted, showed, sold });
    }

    async getSyncHistory() {
        let query = await Firestore.customQuery('leads_cities').orderBy('date', 'desc').get();
        let syncHistory = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            syncHistory.push(data);
        });

        this.setState({ syncHistory });
    }

    async syncAdminUsers() {
        let query = await Firestore.customQuery('user').where('type', '==', 'super_admin').get();

        let adminUsers = {};

        query.forEach((doc, key) => {
            if (!adminUsers[doc.id]) adminUsers[doc.id] = doc.data();
        });

        this.setState({ adminUsers });
    }

    async getDocs() {
        await this.setState({ loading: true });

        if (this.state.selectedView === `clients`) {
            await this.getPlans();
            await this.getClients();

            await this.setState({ loading: false });

            await this.setPaymentStatuses();
        } else if (this.state.selectedView === `leads`) {
            await this.syncAdminUsers();
            await this.getSyncHistory();
            await this.getLeads();

            await this.setState({ loading: false });
        }
    }

    async setPaymentStatuses() {
        if (this.state.active.length > 0) {
            for (let doc of this.state.active) {
                await this.paymentCheck(doc.id, doc.id_subscription_pagarme);
            }
        }
    }

    async handleSwitch(idCompany, value) {
        let confirm = window.confirm(`Tem certeza que deseja ${value ? `ativar` : `desativar`} esta empresa?`);

        if (confirm) {
            if (idCompany) { await Firestore.update({ active: value }, 'company', idCompany) }
            await this.getDocs();
        }
    }

    async handleSearch(text) {

        await this.setState({ search: text });

        if (this.state.search.toString().length > 2) {

            if (this.state.selectedView === `clients`) {
                let result = [];

                if (this.state[this.state.selectedFilter].length) {

                    let array = this.state[this.state.selectedFilter];

                    result = array.filter((company) => {

                        const name = company.name ? company.name.toUpperCase() : ''.toUpperCase();
                        const cnpj = company.cnpj ? company.cnpj.toString().replace(/\D+/g, '').toUpperCase() : company.cpf ? company.cpf.toString().replace(/\D+/g, '').toUpperCase() : ''.toUpperCase();

                        const search = this.state.search.toString().toUpperCase();

                        if (name.indexOf(search) > -1) {

                            return true;
                        }

                        if (cnpj.indexOf(search) > -1) {

                            return true;
                        }
                    });
                }

                let newState = this.state;
                newState[this.state.selectedFilter] = result;

                this.setState(newState);
            }

            if (this.state.selectedView === `leads`) {
                let result = [];

                if (this.state[this.state.selectedLeadFilter].length) {

                    let array = this.state[this.state.selectedLeadFilter];

                    result = array.filter((lead) => {

                        const name = lead.name ? lead.name.toUpperCase() : ''.toUpperCase();
                        const city = lead.city ? lead.city.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase() : ''.toUpperCase();

                        const search = this.state.search.toString().toUpperCase();

                        if (name.indexOf(search) > -1) {

                            return true;
                        }

                        if (city.indexOf(search) > -1) {

                            return true;
                        }
                    });
                }

                let newState = this.state;
                newState[this.state.selectedLeadFilter] = result;

                this.setState(newState);
            }


        } else if (this.state.search.toString().length === 0) {
            this.setState({ search: '' });

            if (this.state.selectedView === `clients`) {
                this.getClients();
            }

            if (this.state.selectedView === `leads`) {
                this.getLeads();
            }

        }
    }

    enterCompany(doc) {

        if (doc) {

            let session = SessionHelper.getData();
            session.id_company = doc.id;
            session.company = doc;

            SessionHelper.setData(session);
            moment.tz.setDefault(SessionHelper.getData().company.timezone || 'America/Sao_Paulo');

            this.props.app.forceUpdate();
        }
    }

    logout() { firebase.auth().signOut(); window.location.href = '/' }

    renderPlan(idPlan) {

        let plan = this.state.planDocs.find(item => item.id === idPlan);

        return `Plano ${plan.name} • ${CurrencyHelper.centsToMoney(plan.price)}`
    }

    renderTrial(date) {

        date = date.toDate ? date.toDate() : date;

        let trialDate = moment(date).startOf('day');
        let actualDate = moment().startOf('day');

        let diff = trialDate.diff(actualDate, 'days');
        let term = '';

        if (diff <= 0) {

            term = `finalizado`;

        } else {

            term = `finaliza`;
        }

        return `Trial ${term} em ${trialDate.format('DD/MM/YYYY')}`
    }

    getTrialColor(date) {

        date = date.toDate ? date.toDate() : date;

        let trialDate = moment(date).startOf('day');
        let actualDate = moment().startOf('day');

        let diff = trialDate.diff(actualDate, 'days');
        let color = Colors.danger;

        if (diff <= 0) {
            color = Colors.error;
        }

        return color;
    }

    isInTrial(date) {

        date = date.toDate ? date.toDate() : date;

        let trialDate = moment(date).startOf('day');
        let actualDate = moment().startOf('day');

        let diff = trialDate.diff(actualDate, 'days');
        let isInTrial = true;

        if (diff <= 0) {
            isInTrial = false;
        }

        return isInTrial;
    }

    renderCompaniesMobile() {
        return this.state[this.state.selectedFilter].map((doc, key) => {

            return (
                <ListItem style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', borderBottom: '1px solid lightgrey', paddingBottom: 15, paddingTop: 15 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div>
                            <img style={{ marginRight: 20, height: 64 }} src={doc.logo ? doc.logo : 'http://placehold.it/900x300'} />
                        </div>

                        <div style={{ padding: 10, maxWidth: `80%`, textAlign: 'center' }}>
                            <div style={{ color: Colors.dark, fontWeight: 'bold', fontSize: 18, marginBottom: 10 }}>{`${doc.name} • ${doc.cnpj ? doc.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") : doc.cpf ? doc.cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : ''}`}</div>
                            <div style={{ color: Colors.formLabel, alignSelf: 'center', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginBottom: 5, fontSize: 16 }}><Email style={{ marginRight: 8, width: 28 }} /> {`${doc.email}`}</div>
                            <div style={{ color: Colors.formLabel, alignSelf: 'center', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginBottom: 5, fontSize: 16 }}><Phone style={{ marginRight: 8, width: 28 }} /> {`${doc.phone && doc.ddd ? `(${doc.ddd}) ${doc.phone}` : 'Sem Telefone'}`}</div>
                            <div style={{ color: Colors.formLabel, alignSelf: 'center', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginBottom: 5, fontSize: 16 }}><Storage style={{ marginRight: 8, width: 28 }} /> {doc.id}</div>
                            <div style={{ color: Colors.formLabel, alignSelf: 'center', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginBottom: 5, fontSize: 16 }}><Room style={{ marginRight: 8, width: 28 }} /> {`${doc.city} - ${doc.state}, ${doc.zipcode}`}</div>
                            <div style={{ marginTop: 20, marginBottom: 15 }}>
                                {doc.id_plan ? <b style={{ backgroundColor: Colors.success, color: 'white', paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8, borderRadius: 5 }}>{this.renderPlan(doc.id_plan)}</b> : null}
                                {doc.trial ? <b style={{ backgroundColor: this.getTrialColor(doc.trial_expiration_date), color: 'white', paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8, borderRadius: 5 }}>{this.renderTrial(doc.trial_expiration_date)}</b> : null}
                            </div>
                            {doc.id_plan && this.state.paymentChecks[doc.id] ? <b style={{ marginLeft: 10, backgroundColor: this.state.paymentChecks[doc.id].color, color: 'white', paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8, borderRadius: 5 }}>{this.state.paymentChecks[doc.id].message}</b> : doc.id_plan ? <DefaultLoader size={20} css={{ marginTop: 20, marginLeft: 0 }} /> : null}
                        </div>
                    </div>
                    <div style={{ padding: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <IosSwitch onChange={(v) => { this.handleSwitch(doc.id, v) }} labelPlacement={'end'} label={'Ativa'} checked={doc.active} />
                        </div>

                        <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                            <Tooltip title={'Acessar Empresa'}>
                                <div>
                                    <DefaultButton width={64} height={64} leftIcon={<ExitToAppIcon style={{ padding: 10 }} />} onClick={() => { this.enterCompany(doc) }} title={''} />
                                </div>
                            </Tooltip>
                            <Tooltip title={'Visualizar Financeiro'}>
                                <div style={{ marginLeft: 8 }}>
                                    <DefaultButton color={Colors.success} disabled={this.state.selectedFilter !== 'active'} width={64} height={64} leftIcon={<AttachMoney style={{ padding: 10 }} />} onClick={() => { this.viewSubscription(doc.id_subscription_pagarme) }} title={''} />
                                </div>
                            </Tooltip>
                            <Tooltip title={'Baixar Parcela Atual'}>
                                <div style={{ marginLeft: 8 }}>
                                    <DefaultButton color={Colors.danger} disabled={this.state.selectedFilter !== 'active'} width={64} height={64} leftIcon={<Gavel style={{ padding: 10 }} />} onClick={() => { this.skipInstallment(doc.id_subscription_pagarme, doc.id) }} title={''} />
                                </div>
                            </Tooltip>
                            <Tooltip title={'Endereço (Google Maps)'}>
                                <div style={{ marginLeft: 8 }}>
                                    <DefaultButton color={Colors.contrast} disabled={this.state.selectedFilter !== 'active'} width={64} height={64} leftIcon={<Room style={{ padding: 10 }} />} onClick={() => { window.open(`http://maps.google.com/?q=${GeographicHelper.getLatLngFromGeopoint(doc.g.geopoint).lat},${GeographicHelper.getLatLngFromGeopoint(doc.g.geopoint).lng}`, '_blank') }} title={''} />
                                </div>
                            </Tooltip>
                            {this.state.selectedFilter == `trial` || this.state.selectedFilter == `ended` ?
                                <Tooltip title={'Estender teste em mais 7 dias à partir de hoje'}>
                                    <div style={{ marginLeft: 8 }}>
                                        <DefaultButton color={Colors.danger} width={64} height={64} onClick={() => { this.extendTrial(doc.id) }} title={<b style={{ padding: 15, fontSize: 20 }}>+7</b>} />
                                    </div>
                                </Tooltip>
                                : null}
                        </div>
                    </div>
                </ListItem>
            )
        });
    }

    renderCompanies() {

        return this.state[this.state.selectedFilter].map((doc, key) => {

            return (
                <ListItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: '1px solid lightgrey', padding: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '60%', alignSelf: 'center', alignItems: 'center' }}>
                        <div style={{ minWidth: 250 }}>
                            <img style={{ marginRight: 20, width: 250, height: 80 }} src={doc.logo ? doc.logo : 'http://placehold.it/250x80'} />
                        </div>
                        <div style={{}}>
                            <div style={{ color: Colors.dark, fontWeight: 'bold', fontSize: 18, marginBottom: 10 }}>{`${doc.name} • ${doc.cnpj ? doc.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") : doc.cpf ? doc.cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : ''}`}</div>
                            <div style={{ color: Colors.formLabel, alignSelf: 'center', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginBottom: 5, fontSize: 16 }}><Email style={{ marginRight: 8, width: 28 }} /> {`${doc.email}`}</div>
                            <div style={{ color: Colors.formLabel, alignSelf: 'center', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginBottom: 5, fontSize: 16 }}><Phone style={{ marginRight: 8, width: 28 }} /> {`${doc.phone && doc.ddd ? `(${doc.ddd}) ${doc.phone}` : 'Sem Telefone'}`}</div>
                            <div style={{ color: Colors.formLabel, alignSelf: 'center', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginBottom: 5, fontSize: 16 }}><Storage style={{ marginRight: 8, width: 28 }} /> {doc.id}</div>
                            <div style={{ color: Colors.formLabel, alignSelf: 'center', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginBottom: 5, fontSize: 16 }}><Room style={{ marginRight: 8, width: 28 }} /> {`${doc.street}, ${doc.number}${doc.complemento}, ${doc.neighborhood}. ${doc.city} - ${doc.state}, ${doc.zipcode}.`}</div>
                            <div style={{ marginTop: 20 }}>
                                {doc.id_plan ? <b style={{ backgroundColor: Colors.success, color: 'white', paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8, borderRadius: 5 }}>{this.renderPlan(doc.id_plan)}</b> : null}
                                {doc.trial ? <b style={{ backgroundColor: this.getTrialColor(doc.trial_expiration_date), color: 'white', paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8, borderRadius: 5 }}>{this.renderTrial(doc.trial_expiration_date)}</b> : null}
                                {doc.id_plan && this.state.paymentChecks[doc.id] ? <b style={{ marginLeft: 10, backgroundColor: this.state.paymentChecks[doc.id].color, color: 'white', paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8, borderRadius: 5 }}>{this.state.paymentChecks[doc.id].message}</b> : doc.id_plan ? <DefaultLoader size={20} css={{ marginTop: -20, marginLeft: 200 }} /> : null}
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Tooltip title={'Acessar Empresa'}>
                            <div style={{ marginRight: 5 }}>
                                <DefaultButton width={64} height={64} leftIcon={<ExitToAppIcon style={{ padding: 15 }} />} onClick={() => { this.enterCompany(doc) }} title={''} />
                            </div>
                        </Tooltip>
                        <Tooltip title={'Visualizar Financeiro'}>
                            <div style={{ marginRight: 5 }}>
                                <DefaultButton color={Colors.success} disabled={this.state.selectedFilter !== 'active'} width={64} height={64} leftIcon={<AttachMoney style={{ padding: 15 }} />} onClick={() => { this.viewSubscription(doc.id_subscription_pagarme) }} title={''} />
                            </div>
                        </Tooltip>
                        <Tooltip title={'Baixar Parcela Atual'}>
                            <div style={{ marginRight: 5 }}>
                                <DefaultButton color={Colors.danger} disabled={this.state.selectedFilter !== 'active'} width={64} height={64} leftIcon={<Gavel style={{ padding: 15 }} />} onClick={() => { this.skipInstallment(doc.id_subscription_pagarme, doc.id) }} title={''} />
                            </div>
                        </Tooltip>
                        <Tooltip title={'Endereço'}>
                            <div style={{ marginRight: 5 }}>
                                <DefaultButton color={Colors.contrast} disabled={this.state.selectedFilter !== 'active'} width={64} height={64} leftIcon={<Room style={{ padding: 15 }} />} onClick={() => { window.open(`http://maps.google.com/?q=${GeographicHelper.getLatLngFromGeopoint(doc.g.geopoint).lat},${GeographicHelper.getLatLngFromGeopoint(doc.g.geopoint).lng}`, '_blank') }} title={''} />
                            </div>
                        </Tooltip>
                        {this.state.selectedFilter == `trial` || this.state.selectedFilter == `ended` ?
                            <Tooltip title={'Estender teste em mais 7 dias à partir de hoje'}>
                                <div style={{ marginRight: 5 }}>
                                    <DefaultButton color={Colors.danger} width={64} height={64} onClick={() => { this.extendTrial(doc.id) }} title={<b style={{ padding: 15, fontSize: 20 }}>+7</b>} />
                                </div>
                            </Tooltip>
                            : null}
                    </div>
                    <div style={{ marginRight: 20 }}>
                        <IosSwitch onChange={(v) => { this.handleSwitch(doc.id, v) }} labelPlacement={'start'} label={'Ativa'} checked={doc.active} />
                    </div>
                </ListItem>
            )
        });
    }

    async viewSubscription(id_subscription_pagarme) {

        try {

            let response = await Pagarme.get(`/subscriptions/${id_subscription_pagarme}`, {});

            if (response && response.manage_url) {

                window.open(response.manage_url, '_blank');
            }

        } catch (error) {

        }
    }

    async skipInstallment(id_subscription_pagarme, id_company) {

        let confirm = window.confirm('Tem certeza que deseja baixar o pagamento dessa empresa manualmente? Isso vai marcar a dívida como paga.');

        if (confirm) {

            try {

                this.setState({ loading: true });

                let data = {
                    charges: 1,
                }

                let response = await Pagarme.post(`/subscriptions/${id_subscription_pagarme}/settle_charge`, data);

                if (response.errors && response.errors.length) {

                    throw new Error('Pagarme Error');
                }

                await Firestore.update({ active: true, status: 'paid' }, 'company', id_company);

                this.setState({ loading: false });

                toast.success('Baixa manual realizada com sucesso, atualize a página.');

            } catch (error) {

                this.setState({ loading: false });
                toast.error('Houve um problema ao realizar a baixa manual');
            }
        }
    }

    async extendTrial(id_company) {
        let confirm = window.confirm(`Tem certeza que extender o trial desta empresa em mais 7 dias? Caso sim, o trial vai até ${moment().add(7, 'days').endOf('day').format('DD/MM/YYYY HH:mm')}`);

        if (confirm) {
            try {

                this.setState({ loading: true });

                await Firestore.update({ trial_expiration_date: moment().add(7, 'days').endOf('day').toDate(), active: true }, 'company', id_company);

                await this.getDocs();

                this.setState({ loading: false });

                toast.success(`Trial estendido até ${moment().add(7, 'days').endOf('day').format('DD/MM/YYYY HH:mm')}`);

            } catch (error) {

                this.setState({ loading: false });
                toast.error('Houve um problema ao estender o trial');
            }
        }
    }

    renderEmpty() {

        return (
            <p style={{ textAlign: 'center', color: 'grey' }}>{'Nenhuma empresa encontrada.'}</p>
        )
    }

    renderSearch() {

        return (
            <ListItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 20, borderBottom: '1px solid lightgrey' }}>
                <input value={this.state.search} onChange={(evt) => { this.handleSearch(evt.target.value) }} style={{ width: '100%', border: '1px solid lightgrey', padding: 20, borderRadius: 10, backgroundColor: 'white', boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', fontSize: 16 }} placeholder={this.state.selectedView === `clients` ? 'Busque por nome e CNPJ...' : 'Busque por nome da empresa ou cidade...'} />
                {this.state.search ? <ClearIcon onClick={() => {
                    this.setState({ search: '' });

                    if (this.state.selectedView === `clients`) {
                        this.getClients();
                    }

                    if (this.state.selectedView === `leads`) {
                        this.getLeads();
                    }

                }} style={{ fontSize: 34, cursor: 'pointer', position: 'absolute', right: 0, marginRight: 40 }} /> : <SearchIcon style={{ fontSize: 34, cursor: 'pointer', position: 'absolute', right: 0, marginRight: 40 }} />}
            </ListItem>
        )
    }

    paymentCheck = async (id_company, id_subscription_pagarme) => {
        let checkObj = {
            paid: false,
            message: 'Dados Indisponíveis',
            color: Colors.disabled
        }

        try {
            if (id_subscription_pagarme) {

                let response = await Pagarme.get(`/subscriptions/${id_subscription_pagarme}`, {});

                if (response.errors && response.errors.length) {
                    checkObj = { paid: false, message: 'Dados Indisponíveis', color: Colors.disabled };
                }

                if (response.status === 'canceled') {
                    checkObj = { paid: false, message: 'Cancelado', color: Colors.error };
                }

                if (response.current_transaction.payment_method === 'boleto' && response.current_transaction.status !== 'paid') {

                    if (moment(response.current_transaction.boleto_expiration_date).isBefore(moment())) {

                        let expirationDays = moment().diff(moment(response.current_transaction.boleto_expiration_date).set('hours', 0), 'days');

                        checkObj = { paid: false, message: `Atrasado ${expirationDays} dias`, color: expirationDays > 15 ? Colors.error : Colors.danger }
                    }
                }

                if (response.current_transaction.payment_method === 'credit_card' && response.current_transaction.status !== 'paid') {

                    let expirationDays = moment().diff(moment(response.current_period_end).set('hours', 0), 'days');

                    checkObj = { paid: false, message: `Atrasado ${expirationDays} dias`, color: expirationDays > 15 ? Colors.error : Colors.danger }
                }

                if (response.status === 'paid') {
                    checkObj = { paid: true, message: 'Pagamento em dia', color: Colors.success };
                }

            }

        } catch (error) {

            checkObj = { paid: false, message: 'Dados Indisponíveis', color: Colors.disabled }
        }

        let paymentChecks = this.state.paymentChecks;
        if (!paymentChecks[id_company]) paymentChecks[id_company] = checkObj;
        this.setState({ paymentChecks });
    }

    renderFilters() {
        return (
            <div style={{ marginTop: 20, marginBottom: 20, display: 'flex', justifyContent: 'space-evenly', flexDirection: 'row', textAlign: 'center', alignItems: 'center' }}>
                {this.state.filters.map(filter =>
                    <div
                        onClick={() => { this.setState({ selectedFilter: filter.key }) }}
                        style={{
                            cursor: 'pointer',
                            backgroundColor: filter.color,
                            justifyContent: 'center',
                            flexDirection: 'row',
                            paddingTop: this.state.selectedFilter == filter.key ? 8 : 5,
                            paddingBottom: this.state.selectedFilter == filter.key ? 8 : 5,
                            paddingLeft: this.state.selectedFilter == filter.key ? 10 : 8,
                            paddingRight: this.state.selectedFilter == filter.key ? 10 : 8,
                            borderRadius: 5,
                        }}
                    >
                        <div
                            style={{ color: 'white', fontSize: 20, alignSelf: 'center', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                            {this.state.selectedFilter == filter.key ? <CheckCircleIcon style={{ fontSize: 17, color: 'white', marginRight: 5 }} /> : null}
                            {filter.icon}
                            {window.screen.width < 1360 || <b style={{ marginBottom: 10, marginTop: 10, marginRight: 10 }}>{filter.label}</b>}
                        </div>
                    </div>)}
            </div>
        )
    }

    moveLead = async (move_to, id) => {
        try {
            this.setState({ loadingDoc: id });

            let doc = await Firestore.getDoc('leads', id);

            if (doc.exists) {
                let data = doc.data();

                if (!data.log) data.log = [];
                data.log.push({ date: new Date(), id_user: SessionHelper.getFirebaseAuth().uid, action: 'move', value: move_to });

                await Firestore.update({ log: data.log, status: move_to }, 'leads', id);
                toast.success(`Movido com sucesso!`);

                await this.getLeads();
            }
        } catch (e) {
            console.log(e);
            toast.error(`Não foi possível mover`);
        }

        this.setState({ loadingDoc: `` });
    }

    needsCall = async (id) => {
        try {
            this.setState({ loadingDoc: id });

            let doc = await Firestore.getDoc('leads', id);

            if (doc.exists) {
                await Firestore.update({ unavaluable_whatsapp: true }, 'leads', id);
                toast.success(`Apontado!`);

                await this.getLeads();
            }
        } catch (e) {
            console.log(e);
            toast.error(`Não foi possível apontar`);
        }

        this.setState({ loadingDoc: `` });
    }

    renderLeads() {
        return this.state[this.state.selectedLeadFilter].map((doc, key) => {
            let message = this.state.selectedLeadFilter === `to_do` ? encodeURI(`Olá pessoal da ${doc.name}. Me chamo ${SessionHelper.getData().name.replace(/\s+.+/g, ``)}, consigo falar com um gestor ou responsável por aqui? Faço parte do time do ACE!\n\nO ACE é um *aplicativo* e *sistema de gerenciamento* criado com um objetivo claro em mente, *facilitar* tarefas e *otimizar* o tempo de *gestores, professores e alunos*. Automatizando tarefas e fazendo diferença no seu dia-a-dia!\n\n*Contamos com módulos completos de:*\n🏆 Torneios		    📊 Vendas		📆 Alugueis de Quadra\n📈 Matrículas		    📖 Aulas		🚀 E mais...\n\nVocê já usa algum sistema de gerenciamento no seu espaço? Vem bater um papo conosco e ver como o ACE pode ajudar a fazer a melhor jogada para o seu negócio!\n\n🖥 https://bit.ly/3g8H3ZD\n\nDesde já, agradecemos sua atenção!`)
                : encodeURI(`Olá pessoal da ${doc.name}, sou o ${SessionHelper.getData().name.replace(/\s+.+/g, ``)} do ACE!`);

            return this.state.loadingDoc === doc.id ? <ListItem style={{ display: 'flex', flexDirection: window.screen.width < 1360 ? 'column' : 'row', justifyContent: 'center', alignSelf: 'center', textAlign: 'center', alignItems: 'center', borderBottom: '1px solid lightgrey', padding: 20 }}><DefaultLoader css={{ marginTop: 10 }} /></ListItem> : (
                <ListItem style={{ display: 'flex', flexDirection: window.screen.width < 1360 ? 'column' : 'row', justifyContent: 'space-between', borderBottom: '1px solid lightgrey', padding: 20 }}>
                    <div style={window.screen.width < 1360 ? { display: 'flex', flexDirection: 'row', alignItems: 'center', width: '90%', alignSelf: 'center', alignItems: 'center' } : { display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={window.screen.width < 1360 ? { maxWidth: `100%`, textAlign: 'center' } : {}}>
                            <div style={{ color: Colors.dark, fontWeight: 'bold', fontSize: 18, marginBottom: 10 }}>{`${doc.name}`}</div>
                            <div style={{ color: Colors.formLabel, alignSelf: 'center', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginBottom: 5, fontSize: 16 }}><Phone style={{ marginRight: 8, width: 28 }} /> {doc.phone ? <a style={{ color: Colors.formLabel }} href={`tel:${doc.phone.replace(`+`, ``).replace(`-`, ``).replace(/\s/gm, ``)}`}>{doc.phone}</a> : 'Sem Telefone'}</div>
                            <div style={{ color: Colors.formLabel, alignSelf: 'center', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginBottom: 5, fontSize: 16 }}><Storage style={{ marginRight: 8, width: 28 }} /> {doc.id}</div>
                            <div style={{ color: Colors.formLabel, alignSelf: 'center', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginBottom: 5, fontSize: 16 }}><Map style={{ marginRight: 8, width: 28 }} /> {`${doc.city} - ${doc.state}`}</div>
                            {window.screen.width < 1360 ? null : <div style={{ color: Colors.formLabel, alignSelf: 'center', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginBottom: 5, fontSize: 16 }}><Room style={{ marginRight: 8, width: 28 }} /> {`${doc.address}`}</div>}
                            {doc.manual ? <div style={{ color: Colors.formLabel, alignSelf: 'center', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginBottom: 5, fontSize: 16 }}><Person style={{ marginRight: 8, width: 28 }} /> {`Cadastro manual em ${moment(doc.created_at.toDate()).calendar()}`}</div> : <div style={{ color: Colors.formLabel, alignSelf: 'center', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginBottom: 5, fontSize: 16 }}><Sync style={{ marginRight: 8, width: 28 }} /> {`Sincronizado por último ${moment(doc.created_at.toDate()).calendar()}`}</div>}
                            <div style={{ marginTop: 20 }}>
                                {doc.unavaluable_whatsapp ? <b style={{ backgroundColor: Colors.danger, color: 'white', paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8, borderRadius: 5 }}>Ligação Necessária (Sem WhatsApp)</b> : null}
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: window.screen.width < 1360 ? 10 : 0 }}>
                        {!doc.status ?
                            <Tooltip title={'Mover para contatado'}>
                                <div style={{ marginRight: 5 }}>
                                    <DefaultButton color={Colors.primary} width={64} height={64} leftIcon={<Chat style={{ padding: 15 }} />} onClick={() => { this.moveLead(`contacted`, doc.id) }} title={''} />
                                </div>
                            </Tooltip>
                            : null}

                        {doc.status == `contacted` ?
                            <Tooltip title={'Mover para apresentado'}>
                                <div style={{ marginRight: 5 }}>
                                    <DefaultButton color={Colors.contrast} width={64} height={64} leftIcon={<AssignmentTurnedIn style={{ padding: 15 }} />} onClick={() => { this.moveLead(`showed`, doc.id) }} title={''} />
                                </div>
                            </Tooltip>
                            : null}

                        {doc.status == `showed` ?
                            <Tooltip title={'Mover para vendido'}>
                                <div style={{ marginRight: 5 }}>
                                    <DefaultButton color={Colors.success} width={64} height={64} leftIcon={<AccountBalanceWallet style={{ padding: 15 }} />} onClick={() => { this.moveLead(`sold`, doc.id) }} title={''} />
                                </div>
                            </Tooltip>
                            : null}

                        {doc.log ?
                            <Tooltip title={'Histórico'}>
                                <div style={{ marginRight: 5 }}>
                                    <DefaultButton color={`#5300EB`} width={64} height={64} leftIcon={<MenuBook style={{ padding: 15 }} />} onClick={() => { this.setState({ companyLog: doc.log, companyHistory: true }) }} title={''} />
                                </div>
                            </Tooltip>
                            : null}

                        {doc.phone && !doc.unavaluable_whatsapp ?
                            <Tooltip title={'Chamar no WhatsApp'}>
                                <div style={{ marginRight: 5 }}>
                                    <DefaultButton color={Colors.success} width={64} height={64} leftIcon={<WhatsApp style={{ padding: 15 }} />} onClick={() => { window.open(`https://api.whatsapp.com/send?phone=${doc.phone.replace(`+`, ``).replace(`-`, ``).replace(/\s/gm, ``)}&text=${message}`, '_blank') }} title={''} />
                                </div>
                            </Tooltip>
                            : null}

                        {doc.phone && !doc.unavaluable_whatsapp && !doc.status ?
                            <Tooltip title={'Marcar WhatsApp Indisponível'}>
                                <div style={{ marginRight: 5 }}>
                                    <DefaultButton color={Colors.danger} width={64} height={64} leftIcon={<PhoneEnabled style={{ padding: 15 }} />} onClick={() => { this.needsCall(doc.id) }} title={''} />
                                </div>
                            </Tooltip>
                            : null}

                        <Tooltip title={'Endereço'}>
                            <div style={{ marginRight: 5 }}>
                                <DefaultButton color={Colors.contrast} width={64} height={64} leftIcon={<Room style={{ padding: 15 }} />} onClick={() => { window.open(doc.maps_url, '_blank') }} title={''} />
                            </div>
                        </Tooltip>

                        {doc.status !== `sold` && doc.status !== `deleted` ?
                            <Tooltip title={'Remover da Lista'}>
                                <div style={{ marginRight: 5 }}>
                                    <DefaultButton color={Colors.error} width={64} height={64} leftIcon={<DeleteIcon style={{ padding: 15 }} />} onClick={() => {
                                        let confirm = window.confirm(`Tem certeza que deseja remover ${doc.name} desta lista?`);

                                        if (confirm) {
                                            this.moveLead(`deleted`, doc.id);
                                        }
                                    }} title={''} />
                                </div>
                            </Tooltip>
                            : null}
                    </div>
                    {/* <div style={{ marginRight: 20 }}>
                        <IosSwitch onChange={(v) => { this.handleSwitch(doc.id, v) }} labelPlacement={'start'} label={'Ativa'} checked={doc.active} />
                    </div> */}
                </ListItem>
            )
        });
    }

    renderLeadsFilters() {
        return (
            <div>
                <div style={{ marginTop: 20, marginBottom: 20, paddingBottom: 20, display: 'flex', justifyContent: 'space-evenly', flexDirection: 'row', textAlign: 'center', alignItems: 'center', borderBottom: `1px solid lightgrey` }}>
                    {this.state.leadsFilters.map(filter =>
                        <div
                            onClick={() => { this.setState({ selectedLeadFilter: filter.key }) }}
                            style={{
                                cursor: 'pointer',
                                backgroundColor: filter.color,
                                justifyContent: 'center',
                                flexDirection: 'row',
                                paddingTop: this.state.selectedLeadFilter == filter.key ? 8 : 5,
                                paddingBottom: this.state.selectedLeadFilter == filter.key ? 8 : 5,
                                paddingLeft: this.state.selectedLeadFilter == filter.key ? 10 : 8,
                                paddingRight: this.state.selectedLeadFilter == filter.key ? 10 : 8,
                                borderRadius: 5,
                            }}
                        >
                            <div
                                style={{ color: 'white', fontSize: 20, alignSelf: 'center', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                {this.state.selectedLeadFilter == filter.key ? <CheckCircleIcon style={{ fontSize: 17, color: 'white', marginRight: 5 }} /> : null}
                                {filter.icon}
                                {window.screen.width < 1360 || <b style={{ marginBottom: 10, marginTop: 10, marginRight: 10 }}>{filter.label}</b>}
                            </div>
                        </div>)}
                </div>
                {this.state.selectedLeadFilter === `to_do` ? this.renderPhoneFilter() : null}


            </div>

        )
    }

    renderPhoneFilter() {
        return (
            <div style={{ marginTop: 20, marginBottom: 20, display: 'flex', justifyContent: 'space-evenly', flexDirection: 'row', textAlign: 'center', alignItems: 'center' }}>
                {this.state.phoneLeadFilter.map(filter =>
                    <div
                        onClick={() => { this.setState({ selectedPhoneFilter: filter.key }); this.getLeads(); }}
                        style={{
                            cursor: 'pointer',
                            backgroundColor: filter.color,
                            justifyContent: 'center',
                            flexDirection: 'row',
                            paddingTop: this.state.selectedLeadFilter == filter.key ? 8 : 5,
                            paddingBottom: this.state.selectedLeadFilter == filter.key ? 8 : 5,
                            paddingLeft: this.state.selectedLeadFilter == filter.key ? 10 : 8,
                            paddingRight: this.state.selectedLeadFilter == filter.key ? 10 : 8,
                            borderRadius: 5,
                        }}
                    >
                        <div
                            style={{ color: 'white', fontSize: 20, alignSelf: 'center', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                            {this.state.selectedPhoneFilter == filter.key ? <CheckCircleIcon style={{ fontSize: 17, color: 'white', marginRight: 5 }} /> : null}
                            {filter.icon}
                            {window.screen.width < 1360 || <b style={{ marginBottom: 10, marginTop: 10, marginRight: 10 }}>{filter.label}</b>}
                        </div>
                    </div>)}
            </div>
        );
    }

    setQuote() {
        let quotes = [
            { quote: 'Daqui pra frente só pra trás', author: 'Cavasotto, Weiler' },
            { quote: 'Mas daí daqui a pouco', author: 'Piva, Ivornei' },
            { quote: 'UNLIMITED POWER', author: 'Palpatine, Imperador' },
            { quote: 'Não entende patavina de pinóia', author: 'Marangoni, Edimilson' },
            { quote: 'Quem resolve essa babylônia?', author: 'Cavasotto, Weiler' },
            { quote: 'Esse é o tal do mula', author: 'Fahur, Sargento' },
            { quote: 'Isso aí na App Store não dá', author: 'Marangoni, Eduardo' },
            { quote: 'Novidades em breve', author: 'Marangoni, Edimilson' },
            { quote: 'Bebendo água de coco, num coco', author: 'Cavasotto, Weiler' },
            { quote: 'Ihhuuuuuuuulllll, agora sim, agora sim, agora vai!', author: 'Marangoni, Edimilson' },
        ];

        let min = Math.ceil(0);
        let max = Math.floor(quotes.length - 1);
        let pos = Math.floor(Math.random() * (max - min + 1)) + min;

        this.setState({ quote: { quote: quotes[pos].quote, author: quotes[pos].author } });
    }

    renderQuote() {
        return <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 50, alignItems: 'center' }}>
            <a style={{ fontWeight: 'bold', color: Colors.primary, fontSize: 20, textAlign: 'start', width: 350 }}>"{this.state.quote.quote}"</a>
            <a style={{ fontWeight: 'bold', color: Colors.primary, fontSize: 10, textAlign: 'end', width: 350 }}>-{this.state.quote.author}</a>
        </div>
    }

    renderCompaniesList() {
        return (
            <List style={{ width: '100%', backgroundColor: '#FFFFFF', borderRadius: 5 }}>
                {this.renderSearch()}
                {this.renderFilters()}
                {window.screen.width < 1360 ? this.renderCompaniesMobile() : this.renderCompanies()}
                {!this.state[this.state.selectedFilter].length && this.renderEmpty()}
            </List>
        );
    }

    renderViewSelection() {
        return (
            <div style={{ marginTop: 20, marginBottom: 20, display: 'flex', justifyContent: 'space-evenly', flexDirection: 'row', textAlign: 'center', alignItems: 'center', width: '100%' }}>
                {this.state.views.map(view =>
                    <div
                        onClick={() => { this.setState({ selectedView: view.key, search: `` }); this.getDocs(); }}
                        style={{
                            cursor: 'pointer',
                            backgroundColor: view.color,
                            justifyContent: 'center',
                            flexDirection: 'row',
                            paddingTop: this.state.selectedView == view.key ? 8 : 5,
                            paddingBottom: this.state.selectedView == view.key ? 8 : 5,
                            paddingLeft: this.state.selectedView == view.key ? 10 : 8,
                            paddingRight: this.state.selectedView == view.key ? 10 : 8,
                            borderRadius: 5,
                        }}
                    >
                        <div
                            style={{ color: 'white', fontSize: 20, alignSelf: 'center', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                            {this.state.selectedView == view.key ? <CheckCircleIcon style={{ fontSize: 17, color: 'white', marginRight: 5 }} /> : null}
                            {view.icon}
                            {window.screen.width < 1360 || <b style={{ marginBottom: 10, marginTop: 10, marginRight: 10 }}>{view.label}</b>}
                        </div>
                    </div>)}
            </div>
        );
    }

    renderSyncCity() {
        return (
            <ListItem style={{ display: 'flex', flexDirection: window.screen.width < 1360 ? 'column' : 'row', justifyContent: 'space-between', padding: 20, borderBottom: '1px solid lightgrey' }}>
                <div style={{ marginRight: window.screen.width < 1360 ? 0 : 10, marginBottom: window.screen.width < 1360 ? 10 : 0, minWidth: 150 }}><DefaultButton loading={this.state.loadingModal} title={'Add Lead'} color={Colors.primary} leftIcon={<Add style={{ fontSize: 34 }} />} onClick={() => { this.setState({ addLeadModal: true }) }} /></div>
                <div style={{ marginRight: window.screen.width < 1360 ? 0 : 10, marginBottom: window.screen.width < 1360 ? 10 : 0 }}><DefaultButton loading={this.state.syncingTerm} title={'Sincronizar'} color={Colors.success} leftIcon={<Sync style={{ fontSize: 34 }} />} onClick={this.syncCity} /></div>
                <input value={this.state.syncTerm} onChange={(evt) => { this.setState({ syncTerm: evt.target.value }) }} style={{ width: '100%', border: '1px solid lightgrey', padding: 10, borderRadius: 10, backgroundColor: 'white', boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', fontSize: 16 }} placeholder={'Nome da cidade para sincronizar'} />
                <div style={{ marginLeft: window.screen.width < 1360 ? 0 : 10, marginTop: window.screen.width < 1360 ? 20 : 0 }}><DefaultButton title={'Histórico'} color={`#5300EB`} leftIcon={<MenuBook style={{ fontSize: 34 }} />} onClick={() => { this.setState({ syncModal: true }) }} /></div>
            </ListItem>
        )
    }

    syncCity = async () => {
        this.setState({ syncingTerm: true });

        try {
            if (this.state.syncTerm.length > 2) {
                let req = await Functions.request('POST', 'admin/syncLeadsByCity', { id_user: SessionHelper.getFirebaseAuth().uid, city: this.state.syncTerm });

                toast.success(`Sincronização completa`);
            }
        } catch (e) {
            console.log(e);
            toast.error(`Ocorreu um erro ao sincronizar, pare, não clique 20 vezes e chame alguém que sabe concertar`);
        }

        await this.getDocs();
        this.setState({ syncingTerm: false, syncTerm: `` });
    }

    renderLeadsView() {
        return (
            <List style={{ width: '100%', backgroundColor: '#FFFFFF', borderRadius: 5 }}>
                {this.renderSyncCity()}
                {this.renderSearch()}
                {this.renderLeadsFilters()}
                {this.renderLeads()}
                {!this.state[this.state.selectedLeadFilter].length && this.renderEmpty()}
            </List>
        );
    }

    companyHistory() {
        if (this.state.companyHistory) {
            return (
                <div>
                    <List style={{ maxWidth: '100%', backgroundColor: '#FFFFFF', maxHeight: 420, overflowY: 'auto' }}>
                        {this.state.companyLog.map(log => {
                            let actions = {
                                move: `Moveu para`
                            }

                            let types = {};

                            this.state.leadsFilters.forEach(filter => {
                                types[filter.key] = filter.label;
                            })

                            return (
                                <ListItem style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: window.screen.width < 1360 ? 'space-evenly' : 'space-between', borderBottom: '1px solid lightgrey' }}>
                                    <div style={{ color: Colors.dark, fontWeight: 'bold', fontSize: 18, marginBottom: 10 }}>{actions[log.action]} {types[log.value]}</div>
                                    <div style={{ color: Colors.formLabel, alignSelf: 'center', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginBottom: 5, fontSize: 16 }}>Realizado por {this.state.adminUsers[log.id_user] ? this.state.adminUsers[log.id_user].name : log.id_user}</div>
                                    <div style={{ color: Colors.formLabel, alignSelf: 'center', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginBottom: 5, fontSize: 16 }}>{`Ação realizada em ${moment(log.date.toDate()).calendar()}`}</div>
                                </ListItem>
                            );

                        })}
                    </List>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.setState({ companyHistory: false }) }} color={Colors.primary} loading={this.state.loadingModal} width={'100%'} title={'Fechar'} />
                    </div>
                </div>
            );
        }

    }

    syncModal() {
        if (this.state.syncModal) {
            return (
                <div>
                    <List style={{ maxWidth: '100%', backgroundColor: '#FFFFFF', maxHeight: 420, overflowY: 'auto' }}>
                        {this.state.syncHistory.map(log => {
                            return (
                                <ListItem style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: window.screen.width < 1360 ? 'space-evenly' : 'space-between', borderBottom: '1px solid lightgrey' }}>
                                    <div style={{ color: Colors.formLabel, marginBottom: 5, fontSize: 16 }}>Query: "{log.city}"</div>
                                    <div style={{ color: Colors.formLabel, marginBottom: 5, fontSize: 16 }}>Realizado por {this.state.adminUsers[log.id_user] ? this.state.adminUsers[log.id_user].name : log.id_user}</div>
                                    <div style={{ color: Colors.formLabel, marginBottom: 5, fontSize: 16 }}>{`Query realizada em ${moment(log.date.toDate()).calendar()}`}</div>
                                </ListItem>
                            );

                        })}
                    </List>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.setState({ syncModal: false }) }} color={Colors.primary} loading={this.state.loadingModal} width={'100%'} title={'Fechar'} />
                    </div>
                </div>
            );
        }
    }

    handleMapChange = (geodata) => {
        let state = this.state;

        if (geodata.address) {
            Object.keys(geodata.address).forEach(key => {
                if (key == 'number') {
                    state[key] = geodata.address[key].replace(/\D*/, '');
                    state['complemento'] = geodata.address[key].replace(/\d*/, '');
                } else {
                    state[key] = geodata.address[key];
                }
            });
        }
        if (geodata.coords) {
            state.coordinates = [geodata.coords.lat, geodata.coords.lng];
        }

        this.setState(state);
    }

    addLead = async () => {
        try {
            if (this.state.name && this.state.phone) {
                this.setState({ loadingModal: true });

                let place = {
                    name: this.state.name,
                    phone: this.state.phone ? `+${this.state.phone.replace(/[^\d]/g, '')}` : '',
                    address: `${this.state.street || ``}, ${this.state.number || ``}, ${this.state.city || ``} - ${this.state.state || ``}, ${this.state.zipcode || ``}, ${this.state.country || ``}`,
                    coordinates: this.state.coordinates,
                    city: this.state.city || ``,
                    state: this.state.state || ``,
                    country: this.state.country || ``,
                    maps_url: ``,
                    manual: true,
                    active: true,
                    created_at: new Date()
                }

                await Firestore.insert(place, 'leads');

                await this.getLeads();
                this.setState({ loadingModal: false, addLeadModal: false });

                toast.success('Lead inserido com sucesso');
            } else {
                toast.warn('Preencha todos os dados');
            }
        } catch (e) {
            toast.error('Ocorreu um erro ao inserir o lead manualmente');
        }
    }

    addLeadModal() {
        if (this.state.addLeadModal) {
            return (
                <div>
                    <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ name: v }) }} label={'Nome'} />

                    <InputMask onBlur={(v) => { this.setState({ phone: DataHelper.treatPhone(v) }) }} mask="+55 (99) 9 9999-99999999" maskPlaceholder={''}>
                        <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} minLength={11} label={'Telefone'} />
                    </InputMask>

                    <LocationPicker callback={this.handleMapChange} initialCenter={GeographicHelper.getLatLngFromGeopoint(this.state.coordinates)} />

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.addLead() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                        <DefaultButton onClick={() => { this.setState({ addLeadModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    render() {
        return this.state.loading ? <CustomSuspense /> : (
            <div style={{ backgroundColor: Colors.primary, padding: window.screen.width < 1360 ? 10 : 30, height: '100%', minHeight: '100vh' }}>
                <List style={{ maxWidth: '100%', backgroundColor: '#FFFFFF', borderRadius: 5, marginBottom: 15, overflowY: 'auto' }}>
                    <ListItem style={{ display: 'flex', flexDirection: 'row', justifyContent: window.screen.width < 1360 ? 'space-evenly' : 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', minWidth: '80%' }} className={'header-actions-buttons'}>
                            <img style={{ height: 42 }} src={process.env.PUBLIC_URL + '/logo.png'} />
                            {window.screen.width < 1360 || this.renderQuote()}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }} onClick={() => { this.logout() }}>
                            <ExitToAppIcon style={{ fontWeight: 'bold', cursor: 'pointer', color: Colors.primary, fontSize: 22, marginRight: 5 }} />
                            <a style={{ fontWeight: 'bold', cursor: 'pointer', color: Colors.primary, fontSize: 20 }}>SAIR</a>
                        </div>
                    </ListItem>
                    {SessionHelper.getData().type === 'super_admin' ?
                        <ListItem style={{ minWidth: '100%', backgroundColor: '#FFFFFF', borderRadius: 5, marginBottom: 5, justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                            {this.renderViewSelection()}
                        </ListItem>
                        : null}
                </List>
                {this.state.selectedView == `clients` ? this.renderCompaniesList() : this.renderLeadsView()}
                <ToastContainer />
                <DefaultModal loading={this.state.loadingModal} content={this.companyHistory()} title={'Histórico de interações'} onClose={() => { this.setState({ companyHistory: false }) }} open={this.state.companyHistory} />
                <DefaultModal loading={this.state.loadingModal} content={this.syncModal()} title={'Histórico de sincronizações'} onClose={() => { this.setState({ syncModal: false }) }} open={this.state.syncModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.addLeadModal()} title={'Adicionar lead manualmente'} onClose={() => { this.setState({ addLeadModal: false }) }} open={this.state.addLeadModal} />
            </div>
        );
    }
}
