import React, { Component } from 'react';
import { InputLabel, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Colors from '../constants/Colors';
import { toast } from 'react-toastify';
import Storage from '../api/firebase/Storage';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import SessionHelper from '../helper/SessionHelper';
import ReportHelper from '../helper/ReportHelper';
import moment from "moment-timezone";

export default class FilePicker extends Component {

    state = {
        files: this.props.files || [],
        hover: null,
        limit: this.props.limit ? this.props.limit : 0,
        sizeLimit: this.props.sizeLimit ? this.props.sizeLimit : 10,
    }

    async upload(files) {

        try {

            if (this.props.onUploadStart) {

                this.props.onUploadStart();
            }

            let filesArray = Array.from(files);
            let links = this.state.files;

            for (let index = 0; index < filesArray.length; index++) {
                
                let file = filesArray[index];
                let filesize = file.size;

                if (((file.size/1024)/1024).toFixed(4) <= this.state.sizeLimit) {

                    let ref = await Storage.uploadFile(this.props.folder || '', file);
                    let downloadPath = await Storage.getFileFromRef(ref);
            
                    if (!downloadPath) {
                        throw new Error('upload error');
                    }
    
                    links.push({
                        name: file.name,
                        size: filesize,
                        link: downloadPath,
                        date: new Date(),
                        id_user: SessionHelper.getFirebaseAuth().uid,
                    });
        
                    toast.success("Imagem adicionada com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });   

                } else {

                    toast.warn(`O tamanho da imagem selecionada é maior que ${this.state.sizeLimit}Mb`, {
                        position: toast.POSITION.TOP_RIGHT
                    });  
                }
            }

            if (this.props.onUpload) {

                this.props.onUpload(links);
            }

            await this.setState({ files: links });

            if (this.props.onUploadEnd) {

                this.props.onUploadEnd();
            }

        } catch (error) {

            toast.error("Houve um problema ao salvar a imagem", {
                position: toast.POSITION.TOP_RIGHT
            });

            if (this.props.onUploadEnd) {

                this.props.onUploadEnd();
            }
        }
    }

    async delete(file, key) {

        if (this.state.files[key]) {

            try {

                if (this.props.onUploadStart) {
                    this.props.onUploadStart();
                }

                await Storage.removeFile(file.link);

                let files = this.state.files;
                files.splice(key, 1);

                if (this.props.onUpload) {
                    this.props.onUpload(files);
                }
    
                await this.setState({ files: files });
    
                if (this.props.onUploadEnd) {
                    this.props.onUploadEnd();
                }

                toast.success("Imagem removida com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });  

            } catch (error) {

                toast.error("Houve um problema ao remover a imagem", {
                    position: toast.POSITION.TOP_RIGHT
                });
    
                if (this.props.onUploadEnd) {
                    this.props.onUploadEnd();
                }
            }
        }
    }

    toggleHover(file) {

        this.setState({ hover: file });
    }

    renderFiles() {

        return this.state.files.map((file, key) => {

            return (

                <div key={`image_picker_image_${key}`} style={{ position: 'relative' }} onMouseOver={() => { this.toggleHover(file) }} onMouseLeave={() => { this.toggleHover(null) }}>
                    { this.state.hover === file && <Tooltip title={'Remover imagem'}><DeleteIcon onClick={() => { this.delete(file, key) }} style={{ color: Colors.primary, cursor: 'pointer', position: 'absolute', top: 0, left: 0, margin: 5, marginTop: 15, width: 50, height: 50, padding: 10, borderRadius: '100%', backgroundColor: 'white', boxShadow: Colors.boxShadow }} /></Tooltip> }
                    <div style={{ height: 150, width: 150, backgroundColor: Colors.primary, borderRadius: 5, marginRight: 10, marginTop: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <InsertDriveFileIcon style={{ fontSize: 60, color: 'white' }}/>
                        <Tooltip title={file.name}><div style={{ color: 'white', fontWeight: 'bold', marginTop: 10 }}>{ReportHelper.cutName(file.name, 15)}</div></Tooltip>
                        <div style={{ color: 'white', fontSize: 12, marginTop: 10 }}>{moment(file.date.toDate ? file.date.toDate() : file.date).calendar()}</div>
                    </div>
                </div>
            )
        });
    }

    render() {

        return (
            <div>

                { this.props.label && <InputLabel>{this.props.label}</InputLabel> }

                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20, flexWrap: 'wrap' }}>

                    { this.renderFiles() }

                    <input
                        multiple={this.props.multiple}
                        style={{display: 'none'}}
                        id={this.props.id || "contained-button-file"}
                        type="file"
                        onChange={(v) => { this.upload(v.target.files) }}
                    />

                        { this.state.files.length < this.state.limit || this.state.limit === 0 ?
                        
                            <label htmlFor={this.props.id || "contained-button-file"}>
                                <div style={{ height: 150, width: 150, backgroundColor: '#ededed', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', marginTop: 10 }}>
                                    <AddIcon style={{ fontSize: 40, color: 'grey' }}/>
                                </div>
                            </label>

                        : null }

                </div>
            </div>
        )
    }
}