import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import { Card } from '@material-ui/core';
import moment from "moment-timezone";
import Colors from '../constants/Colors';
import SessionHelper from '../helper/SessionHelper';
import Functions from '../constants/Functions';
import ClipLoader from "react-spinners/ClipLoader";

export default class PaymentMissingPage extends Component {

    state = {
        loading: true,
        success: null,
    }

    async componentDidMount() {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const hash = urlParams.get('hash');

        if (hash) {

            const request = await fetch(`${Functions.getEndPoint()}/${'auth/linkUser'}`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify({ hash: hash })
            });

            let response = await request.json();
            response.status = request.status;

            let success = false;

            if (response.status === 200) {

                success = true;
            }

            this.setState({ loading: false, success: success })
        }
    }

    renderStatus() {

        if (this.state.success) {

            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <img style={{height: 300}} src={process.env.PUBLIC_URL + '/ilustrations/confirm_link_page.png'}/>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 25 }}>
                        <p style={{fontSize: 25, fontWeight: 'bold'}}>{`Sucesso!`}</p>
                        <div style={{color: 'grey', fontSize: 16, textAlign: 'center'}}>{'O seu vínculo foi confirmado, encerre e abra novamente o aplicativo para atualizar os seus dados.'}</div>
                    </div>
                </div>
            )

        } else {

            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <img style={{height: 300}} src={process.env.PUBLIC_URL + '/ilustrations/error_link_page.png'}/>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 25 }}>
                        <p style={{fontSize: 25, fontWeight: 'bold'}}>{`Ops!`}</p>
                        <div style={{color: 'grey', fontSize: 16, textAlign: 'center'}}>{'Houve um problema ao realizar o seu vínculo, solicite o envio do e-mail novamente.'}</div>
                    </div>
                </div>
            )
        }
    }

    renderLoading() {

        return (
            <div style={{ minWidth: 300, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <ClipLoader
                    size={this.props.size ? this.props.size : 50}
                    color={this.props.color ? this.props.color : Colors.primary}
                    loading={true}
                />
            </div>
        )
    }
    
    render() {
        return (
            <div style={styles.container}>
                <Card style={{padding: 50, margin: 20, height: '70vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    { this.state.loading ? this.renderLoading() : this.renderStatus() }
                </Card>
            </div>
        );
    }
}

const styles = {
    container: {
        backgroundColor: Colors.primary,
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}