import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button, InputLabel, Select, MenuItem, Tooltip } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import IosSwitch from '../components/IosSwitch';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import DefaultSelect from '../components/DefaultSelect';
import SessionHelper from '../helper/SessionHelper';
import CurrencyInput from '../components/CurrencyInput';
import moment from "moment-timezone";
import LiquidProfit from '../components/LiquidProfit';
import AddIcon from '@material-ui/icons/Add';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import HeaderActionsWrapper from '../components/HeaderActionsWrapper';

export default class CourtRentPacksPage extends Component {

    state = {
        docs: [],
        courtDocs: [],
        name: '',
        rents: 0,
        max_parcel: 12,
        price: null,
        rawPrice: null,
        studentPrice: null,
        rawStudentPrice: null,
        courts: [],
        loading: true,
        loadingModal: false,
        addModal: false,
        active: true,
        inputError: false,
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('court_rent_packs').where('id_company', '==', SessionHelper.getData().id_company).get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;
            data.price = data.price / 100;
            data.student_price = data.student_price / 100;

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    async getCourts() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('court').where('forRent', '==', true).where('id_company', '==', SessionHelper.getData().id_company).get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ courtDocs: docs, loading: false });
    }

    componentDidMount() {

        this.getDocs();
        this.getCourts();
    }

    async addPack() {

        if (this.state.name && this.state.rawPrice && this.state.rents > 0) {

            if (this.state.rents % 2 === 0) {

                this.setState({ loadingModal: true });

                let data = {
                    name: this.state.name,
                    price: this.state.rawPrice * 100,
                    student_price: this.state.rawStudentPrice * 100,
                    rents: this.state.rents,
                    max_parcel: this.state.max_parcel,
                    active: this.state.active,
                    id_company: SessionHelper.getData().id_company,
                    courts: this.state.courts,
                    date: new Date(),
                };

                try {

                    await Firestore.insert(data, 'court_rent_packs');

                    toast.success("Cadastrado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    await this.setState({ loadingModal: false, addModal: false, name: '', price: null, rawPrice: null, rents: 0, max_parcel: 12 });
                    await this.getDocs();

                } catch (error) {

                    toast.error("Houve um problema ao cadastrar", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    this.setState({ loadingModal: false });
                }

            } else {

                toast.warn('Informe um número par nos créditos do pacote');
            }

        } else {

            toast.warn("Preencha os campos obrigatórios", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    renderCourtSelection() {

        return (
            <div style={{ marginTop: 10, marginBottom: 10 }}>
                <DefaultSelect search={true} searchField={'name'} multiple={true} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ courts: v.target.value }) }} value={this.state.courts} docs={this.state.courtDocs} label={'Selecionar Quadras'} />
            </div>
        )
    }

    addModal() {
        return (
            <div>
                <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ name: v }) }} label={'Nome'}/>
                <DefaultInput required number onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ rents: v }) }} label={'Número de Aluguéis'}/>
                
                <CurrencyInput required onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Valor'} onChange={(values) => { const {formattedValue, value} = values; this.setState({price: formattedValue, rawPrice: value}) }} value={this.state.price}/>
                <LiquidProfit installments={this.state.max_parcel} price={this.state.rawPrice * 100}/>
                <LiquidProfit price={this.state.rawPrice * 100} type={'pix'}/>

                <CurrencyInput required onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Valor para Alunos'} onChange={(values) => { const { formattedValue, value } = values; this.setState({ studentPrice: formattedValue, rawStudentPrice: value }) }} value={this.state.studentPrice} />
                <LiquidProfit installments={this.state.max_parcel} price={this.state.rawStudentPrice * 100} />
                <LiquidProfit price={this.state.rawStudentPrice * 100} type={'pix'}/>

                <div style={{ marginTop: 10 }}><DefaultInput number required value={this.state.max_parcel} onChange={(v) => { this.setState({ max_parcel: v }) }} label={'Número Máximo de Parcelas (Crédito)'}/></div>
                
                <div style={{ marginTop: 10 }}/>

                { this.renderCourtSelection() }

                <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', fontWeight: 'bold', marginBottom: 10 }}>
                    <InfoRoundedIcon style={{ fontSize: 20, marginRight: 8 }}/>
                    <div>{'Deixando vazio você habilita o pacote para todas as quadras'}</div>
                </div>

                <IosSwitch fullWidth label="Ativo" value="active" checked={this.state.active} onChange={(v) => { this.setState({ active: v }) }}/>

                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.addPack() }} style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ addModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    renderCourts(courts) {

        let text = '';

        if (courts && courts.length) {

            courts.forEach((court, key) => {

                let doc = this.state.courtDocs.find(item => item.id === court);
                
                if (doc) {
    
                    text += doc.name;
                }
    
                if (courts[key + 1]) {
                    text += ', ';
                }
            });

        } else {

            text = 'Todas as Quadras';
        }

        return text;
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <HeaderActionsWrapper style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon/>} onClick={() => { this.setState({ addModal: true, courts: [] }) }} title={'Adicionar'}/>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        
                    </div>
                </HeaderActionsWrapper>  

                <DefaultTable
                title={'Pacotes de Aluguéis de Quadra'}
                marginTop={10}
                
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Nome', field: 'name' },
                    { title: 'Valor', field: 'price', type: 'currency', currencySetting: {locale: 'brl', currencyCode: 'brl'}, editComponent: props => ( <CurrencyInput value={props.value} onChange={(e) => { props.onChange(e.floatValue) }} /> ) },
                    { title: 'Valor para Alunos', field: 'student_price', type: 'currency', currencySetting: { locale: 'brl', currencyCode: 'brl' }, editComponent: props => (<CurrencyInput value={props.value} onChange={(e) => { props.onChange(e.floatValue) }} />) },
                    { title: 'Número Máximo de Parcelas', field: 'max_parcel', type: 'numeric' },
                    { title: 'Número de Aluguéis', field: 'rents', type: 'numeric' },
                    { title: 'Quadras', field: 'courts', editComponent: props => <DefaultSelect search={true} multiple={true} searchField={'name'} valueField={'id'} displayField={'name'} onChange={(v) => { props.onChange(v.target.value) }} value={props.value} docs={this.state.courtDocs}/>, render: rowData => this.renderCourts(rowData.courts),},
                    { title: 'Ativo', field: 'active', type: 'boolean', render: rowData => <IosSwitch disabled={true} checked={rowData.active}/>, editComponent: props => (<IosSwitch checked={props.value} onChange={(e) => { props.onChange(e) }} /> ) },
                    { title: 'Data Criação', field: 'date', render: rowData => <div>{moment(rowData.date.toDate()).format('DD/MM/YYYY HH:mm')}</div>, editable: false },
                ]}
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                    newData.date = oldData.date;

                    let prev = this.state.docs;
                    prev[prev.indexOf(oldData)] = newData;

                    this.setState({ docs: prev });

                    if (oldData.id) {
                        
                        let update = {
                            name: newData.name,
                            price: newData.price * 100,
                            student_price: newData.student_price * 100,
                            max_parcel: newData.max_parcel,
                            rents: newData.rents,
                            courts: newData.courts,
                            active: newData.active,
                        };

                        await Firestore.update(update, 'court_rent_packs', oldData.id);
                    }

                    toast.success("Editado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Pacote'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
