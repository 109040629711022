import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import Colors from '../constants/Colors';
import { Button, FormLabel, Select, MenuItem, TextareaAutosize } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircle';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import TimerIcon from '@material-ui/icons/Timer';
import DescriptionIcon from '@material-ui/icons/Description';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import CancelOutlinedIcon from '@material-ui/icons/Cancel';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import LayersIcon from '@material-ui/icons/Layers';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import IosSwitch from '../components/IosSwitch';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import DefaultInput from '../components/DefaultInput';
import DefaultSelect from '../components/DefaultSelect';
import SessionHelper from '../helper/SessionHelper';
import moment from "moment-timezone";
import Pagarme from '../api/pagarme/Pagarme';
import CurrencyHelper from '../helper/CurrencyHelper';
import BankList from 'bancos-brasileiros/bancos.json';
import NotificationHelper from '../helper/NotificationHelper';
import { MTableToolbar } from 'material-table';
import InputMask from "react-input-mask";
import OrderConstants from '../constants/Order';
import CalendarEvent from '../components/CalendarEvent';
import InventoryHelper from '../helper/InventoryHelper';
import RelationField from '../components/grid/RelationField';
import PaymentMethodHelper from '../helper/PaymentMethodHelper';
import CropFreeIcon from '@material-ui/icons/CropFree';
import QRCode from 'qrcode.react';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DefaultButton from '../components/DefaultButton';
import Brightness4 from '@material-ui/icons/Brightness4';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import TodayIcon from '@material-ui/icons/Today';
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

export default class OrderPage extends Component {

    state = {
        docs: [],
        loading: true,
        loadingModal: false,
        orderItensModal: false,
        orderItens: [],
        orderUser: null,
        orderEvents: null,
        orderEventType: null,
        finishModal: false,
        cancelModal: false,
        editId: null,
        refundPayment: false,
        orderData: null,
        cancelReason: '',
        refundBankCode: '',
        refundAccount: '',
        refundAccountDv: '',
        refundAgencia: '',
        refundAgenciaDv: '',
        refundAccountType: '',
        refundAccountName: '',
        refundDocument: '',
        inputError: false,
        filterType: 'all',
        filterStatus: 'all',
        filterPaymentMethod: 'all',
        refundInventory: false,
        orderTypes: OrderConstants.orderTypes,
        paymentMethodTypes: OrderConstants.paymentMethodTypes,
        statusTypes: OrderConstants.statusTypes,
        pixModal: false,
        pixQrCode: null,
        markAsPaid: false,
        renewExpirationDate: null,
        renewExpirationDateModal: false,
    }

    async getDocs(load = false) {

        if (load) { this.setState({ loading: true }) };

        let query = await Firestore.customQuery('order').where('id_company', '==', SessionHelper.getData().id_company).orderBy('date', 'desc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;
            data.amount = data.amount / 100;
            data.discount = data.discount / 100;

            if (data.payment_method === 'credit-card') { data.payment_method = 'credit_card' };

            if (this.state.filterPaymentMethod === 'all' || this.state.filterPaymentMethod === data.payment_method) {

                if (this.state.filterType === 'all' || this.state.filterType === data.type) {

                    let status = '';

                    if (data.payment_method === 'money') {

                        if (data.finished && !data.refunded && !data.canceled) {

                            status = 'paid'

                        } else if (data.finished && data.refunded) {

                            status = 'refunded';

                        } else if (data.canceled) {

                            status = 'canceled';

                        } else {

                            status = 'waiting_payment';
                        }

                    } else {

                        if (data.status === 'paid' || !data.status) {

                            if (data.refunded) {

                                status = 'refunded';

                            } else if (data.canceled) {

                                status = 'canceled';

                            } else {

                                status = 'paid';
                            }

                        } else if (data.status === 'waiting_payment' && !data.canceled && !data.refunded) {

                            status = 'waiting_payment';

                        } else if (data.canceled && !data.refunded) {

                            status = 'canceled';

                        } else {

                            status = 'refunded';
                        }
                    }

                    if (this.state.filterStatus === status || this.state.filterStatus === 'all') {

                        docs.push(data);
                    }
                }
            }
        });

        this.setState({ docs: docs });

        if (load) { this.setState({ loading: false }) };
    }

    async componentDidMount() {

        await this.getDocs();

        this.setState({ loading: false });
    }

    renderType(type) {

        let label = '';
        let color = '';
        let icon = '';

        if (type === 'product') {
            label = 'Produtos';
            color = Colors.order.product;
            icon = <ShoppingCartIcon />;

        } else if (type === 'tournament_ticket') {
            label = 'Inscrição de Torneio';
            color = Colors.order.tournament_ticket;
            icon = <EmojiEventsIcon />;

        } else if (type === 'ranking_ticket') {
            label = 'Inscrição de Ranking';
            color = Colors.order.ranking_ticket;
            icon = <ImportExportIcon />;

        } else if (type === 'court_rent') {
            label = 'Aluguel de Quadra';
            color = Colors.order.court_rent;
            icon = <TimerIcon />;

        } else if (type === 'private_lesson') {
            label = 'Aula Avulsa';
            color = Colors.order.private_lesson;
            icon = <SportsTennisIcon />;

        } else if (type === 'experimental_lesson') {
            label = 'Aula Experimental';
            color = Colors.order.experimental_lesson;
            icon = <SportsTennisIcon />;
        } else if (type === 'private_lesson_pack') {
            label = 'Pacote de Aulas Avulsas';
            color = Colors.order.private_lesson_pack;
            icon = <LayersIcon />;

        } else if (type === 'court_rent_pack') {
            label = 'Pacote de Aluguéis de Quadra';
            color = Colors.order.court_rent_pack;
            icon = <LayersIcon />;

        } else if (type === 'ticket') {
            label = 'Comanda';
            color = Colors.order.ticket;
            icon = <ReceiptIcon />;
        } else if (type === 'day_use') {
            label = 'Day Use (Rotativo)';
            color = Colors.order.day_use;
            icon = <Brightness4 />;
        }

        return (
            <div style={{ color: color, fontWeight: 'bold', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {icon}
                <div style={{ marginLeft: 5 }}>{label}</div>
            </div>
        );
    }

    async finishOrder() {

        if (this.state.editId) {

            try {

                this.setState({ loadingModal: true });

                await Firestore.update({ finished: true, finish_date: new Date(), finished_by: SessionHelper.getFirebaseAuth().uid }, 'order', this.state.editId);

                if (this.state.markAsPaid && this.state.orderData.status && this.state.orderData.status === 'waiting_payment') {

                    await Firestore.update({ status: 'paid' }, 'order', this.state.editId);
                }

                let idUser = this.state.orderData.id_user;

                let query = await Firestore.getDoc('user', idUser);
                let userDoc = { id: query.id, ...query.data() };

                if (userDoc && userDoc.notification_token && userDoc.notification_token.data) {

                    let token = userDoc.notification_token.data;
                    let notification = {
                        icon: 'shopping-bag',
                        color: 'green',
                        navigate: 'DetailedOrder',
                        id_order: this.state.editId,
                        title: this.state.orderData.title
                    };

                    NotificationHelper.send('Pedido Finalizado', `O seu ${this.state.orderData.title} foi finalizado, abra o app para mais informações.`, notification, token, idUser);
                }

                await this.getDocs();

                toast.success('Pedido finalizado com sucesso');
                this.setState({ loadingModal: false, finishModal: false });

            } catch (error) {

                toast.error('Houve um problema ao encerrar o pedido');
                this.setState({ loadingModal: false });
            }
        }
    }

    async getOrderEvents(collection, ids) {
        let query = await Firestore.customQuery(collection).where(Firestore.idField(), 'in', ids).get();

        if (query.size > 0) {
            let events = [];

            for (let doc of query.docs) {
                let data = { ...doc.data(), id: doc.id };

                if (data.friends) {
                    let friendsQuery = await Firestore.customQuery('user').where(Firestore.idField(), 'in', data.friends).get();

                    if (friendsQuery.size > 0) {

                        data.friends = [];

                        friendsQuery.forEach((friend, key) => {

                            data.friends.push(friend.data().name);
                        });
                    }
                }

                events.push(data);
            }

            this.setState({ orderEvents: events, orderEventType: collection });
        }
    }

    async getOrderUser(id) {

        let query = await Firestore.getDoc('user', id);

        if (query.exists) {

            let data = { ...query.data(), id: query.id };

            this.setState({ orderUser: data });
        }
    }

    async getOrderItens(id_transaction_pagarme, items, type, id_court_rent, ids_court_rent, id_private_lesson, id_experimental_lesson, id_user) {

        try {

            await this.setState({ orderItensModal: true, loadingModal: true });

            let orderItems = [];

            if (id_transaction_pagarme) {

                let response = await Pagarme.get(`/transactions/${id_transaction_pagarme}`);

                if (response.items && response.items.length) {

                    orderItems = [...response.items];
                }
            }

            if (items) {

                orderItems = [...items];
            }

            this.setState({ orderItens: orderItems });

            if (type === 'court_rent' || type === 'private_lesson' || type === 'experimental_lesson') {

                let ids = type === 'court_rent' ? ids_court_rent ? ids_court_rent : [id_court_rent]
                    : type === 'private_lesson' ? [id_private_lesson] : [id_experimental_lesson];

                await this.getOrderEvents(type, ids);
            }

            await this.getOrderUser(id_user)

            this.setState({ loadingModal: false });

        } catch (error) {

            toast.error('Houve um problema ao buscar os itens do pedido');
            this.setState({ loadingModal: false, orderItens: [] });
        }
    }

    async getOrderBoleto(id_transaction_pagarme) {

        try {

            this.setState({ loading: true });

            let response = await Pagarme.get(`/transactions/${id_transaction_pagarme}`);

            if (response.payment_method === 'boleto') {

                let boletoUrl = response.boleto_url;

                if (boletoUrl) {

                    window.open(boletoUrl, '_blank');
                }
            }

            this.setState({ loading: false });

        } catch (error) {

            toast.error('Houve um problema ao buscar os itens do pedido');
            this.setState({ loading: false });
        }
    }

    async cancelEvent() {

        if (this.state.orderData.id_experimental_lesson) {

            await Firestore.delete('experimental_lesson', this.state.orderData.id_experimental_lesson);

        } else if (this.state.orderData.id_private_lesson) {

            await Firestore.delete('private_lesson', this.state.orderData.id_private_lesson);

        } else if (this.state.orderData.id_tournament) {

            let query = await Firestore.getDoc('tournament', this.state.orderData.id_tournament);

            if (query.exists) {

                let tournament = { ...query.data(), id: query.id };

                let category = tournament.category;
                let participants = tournament.participants;

                let participantsIndex = participants.findIndex(item => item === this.state.orderData.id_user);
                let classIndex = category[this.state.orderData.tournament_category].classes[this.state.orderData.tournament_class].participants.findIndex(item => item.id_user === this.state.orderData.id_user);

                if (participantsIndex >= 0) participants.splice(participantsIndex, 1);
                if (classIndex >= 0) category[this.state.orderData.tournament_category].classes[this.state.orderData.tournament_class].participants.splice(classIndex, 1);

                if (this.state.orderData.id_friend) {

                    let participantsIndex = participants.findIndex(item => item === this.state.orderData.id_friend);

                    if (participantsIndex >= 0) {

                        participants.splice(participantsIndex, 1);
                    }
                }

                await Firestore.update({ participants: participants, category: category }, 'tournament', this.state.orderData.id_tournament);
            }

        } else if (this.state.orderData.id_ranking) {
            let query = await Firestore.getDoc('ranking', this.state.orderData.id_ranking);

            if (query.exists) {
                let ranking = { ...query.data(), id: query.id };

                let category = ranking.category;
                let participants = ranking.participants;

                let participantsIndex = participants.findIndex(item => item === this.state.orderData.id_user);
                let classIndex = category[this.state.orderData.ranking_category].classes[this.state.orderData.ranking_class].participants.findIndex(item => item.id_user === this.state.orderData.id_user);

                if (participantsIndex >= 0) participants.splice(participantsIndex, 1);
                if (classIndex >= 0) category[this.state.orderData.ranking_category].classes[this.state.orderData.ranking_class].participants.splice(classIndex, 1);

                if (this.state.orderData.id_friend) {

                    let participantsIndex = participants.findIndex(item => item === this.state.orderData.id_friend);

                    if (participantsIndex >= 0) {

                        participants.splice(participantsIndex, 1);
                    }
                }

                await Firestore.update({ participants: participants, category: category }, 'ranking', this.state.orderData.id_ranking);
            }

        } else if (this.state.orderData.id_court_rent) {

            await Firestore.delete('court_rent', this.state.orderData.id_court_rent);

        } else if (this.state.orderData.ids_court_rent) {

            for (let id of this.state.orderData.ids_court_rent) {
                await Firestore.delete('court_rent', id);
            }

        } else if (this.state.orderData.id_day_use) {

            let query = await Firestore.getDoc('day_use', this.state.orderData.id_day_use);

            if (query.exists) {

                let day_use = { ...query.data(), id: query.id };

                let participantsIndex = day_use.participants.findIndex(item => item === this.state.orderData.id_user);

                if (participantsIndex >= 0) {
                    day_use.participants.splice(participantsIndex, 1);

                    if (day_use.participant_options && day_use.participant_options[this.state.orderData.id_user]) {
                        delete day_use.participant_options[this.state.orderData.id_user];
                    }
                }

                await Firestore.update({ participants: day_use.participants, participant_options: day_use.participant_options }, 'day_use', this.state.orderData.id_day_use);
            }

        }
    }

    async refundInventory() {

        let order = this.state.orderData;
        let items = order.items;

        if (items.length) {

            for (const item of items) {

                if (item.checked && item.id && item.quantity) {

                    await InventoryHelper.addProductMovement(item.id, item.quantity, 'entry', `Devolução do ${order.title}`, true);
                }
            }
        }
    }

    async cancelOrder() {

        if (this.state.editId) {

            if (this.state.cancelReason.length) {

                if ((this.state.refundPayment && ((this.state.orderData.payment_method === 'boleto' && (this.state.refundBankCode && this.state.refundAgencia && this.state.refundAccount && this.state.refundAccountDv && this.state.refundAccountType && this.state.refundDocument && this.state.refundAccountName)) || this.state.orderData.payment_method === 'credit_card' || this.state.orderData.payment_method === 'credit-card' || this.state.orderData.payment_method === 'money' || this.state.orderData.payment_method === 'pix' || this.state.orderData.payment_method === 'debit-card' || this.state.orderData.payment_method === 'permuta' || this.state.orderData.payment_method === 'bonificacao')) || !this.state.refundPayment) {

                    try {

                        this.setState({ loadingModal: true });

                        if (this.state.refundPayment && this.state.orderData.id_transaction_pagarme && this.state.orderData.payment_method !== 'money') {

                            let idTransaction = this.state.orderData.id_transaction_pagarme;

                            let body = {
                                async: false,
                                metadata: {
                                    id_order: this.state.editId,
                                },
                            };

                            if (this.state.orderData.payment_method === 'boleto') {

                                let bankCode = this.state.refundBankCode;

                                if (bankCode.length === 2) {

                                    bankCode = `0${bankCode}`;
                                }

                                body.bank_code = bankCode;
                                body.agencia = this.state.refundAgencia.replace(/[^\d]/g, '');
                                body.agencia_dv = this.state.refundAgenciaDv.replace(/[^\d]/g, '');
                                body.conta = this.state.refundAccount.replace(/[^\d]/g, '');
                                body.conta_dv = this.state.refundAccountDv.replace(/[^\d]/g, '');
                                body.type = this.state.refundAccountType;
                                body.document_number = this.state.refundDocument.replace(/[^\d]/g, '');
                                body.legal_name = this.state.refundAccountName;

                                if (this.state.refundAgenciaDv) {
                                    body.agencia_dv = this.state.refundAgenciaDv.replace(/[^\d]/g, '');
                                }
                            }

                            let response = await Pagarme.post(`/transactions/${idTransaction}/refund`, body);

                            if (response.errors && response.errors.length) {
                                throw new Error('Payment Error');
                            }
                        }

                        if (this.state.refundInventory && (this.state.orderData.type === 'ticket' || this.state.orderData.type === 'product')) {

                            await this.refundInventory();
                        }

                        await this.cancelEvent();
                        await Firestore.update({ finished: true, cancel_date: new Date(), refunded: this.state.refundPayment, canceled: true, finished_by: SessionHelper.getFirebaseAuth().uid, cancel_reason: this.state.cancelReason }, 'order', this.state.editId);

                        let idUser = this.state.orderData.id_user;

                        if (idUser) {

                            let query = await Firestore.getDoc('user', idUser);
                            let userDoc = { id: query.id, ...query.data() };

                            if (userDoc && userDoc.notification_token && userDoc.notification_token.data) {

                                let token = userDoc.notification_token.data;
                                let notification = {
                                    icon: 'shopping-bag',
                                    color: 'red',
                                    navigate: 'DetailedOrder',
                                    id_order: this.state.editId,
                                    title: this.state.orderData.title
                                };

                                NotificationHelper.send('Pedido Cancelado', `O seu ${this.state.orderData.title} foi cancelado, abra o app para mais informações.`, notification, token, idUser);
                            }
                        }

                        await this.getDocs();
                        await this.setState({ loadingModal: false, cancelModal: false, editId: null, orderData: null });

                        toast.success('Pedido cancelado com sucesso');

                    } catch (error) {

                        console.log(error);

                        toast.error('Houve um problema ao processar o cancelamento');
                        this.setState({ loadingModal: false });
                    }

                } else {

                    toast.warn('Preencha as informações para estornar a compra');
                }

            } else {

                toast.warn('Informe o motivo de cancelamento');
            }
        }
    }

    async renewExpirationDate() {

        try {

            this.setState({ loadingModal: true });

            let newDate = moment(this.state.renewExpirationDate, 'dd/MM/yyyy');
            let idTransaction = this.state.orderData.id_transaction_pagarme;

            if (idTransaction) {

                let response = await Pagarme.get(`/transactions/${idTransaction}`);

                if (response.errors && response.errors.length) {

                    throw new Error('Transaction not find');
                }

                let newTransaction = {
                    amount: response.amount,
                    payment_method: response.payment_method,
                    async: false,
                    soft_descriptor: 'Ace',
                    metadata: response.metadata,
                }

                let items = [];
                let splitRules = [];

                response.items.forEach((item, key) => {

                    items.push({
                        id: item.id,
                        title: item.title,
                        unit_price: item.unit_price,
                        quantity: item.quantity,
                        tangible: item.tangible,
                    });
                });

                response.split_rules.forEach((rule, key) => {

                    splitRules.push({
                        recipient_id: rule.recipient_id,
                        liable: rule.liable,
                        charge_processing_fee: rule.charge_processing_fee,
                        amount: rule.amount,
                        charge_remainder: rule.charge_remainder,
                    });
                });

                newTransaction.items = items;
                newTransaction.split_rules = splitRules;

                if (response.billing) {

                    newTransaction.billing = {
                        name: response.billing.name,
                        address: {
                            street: response.billing.address.street,
                            street_number: response.billing.address.street_number,
                            zipcode: response.billing.address.zipcode,
                            country: response.billing.address.country,
                            state: response.billing.address.state,
                            city: response.billing.address.city,
                        }
                    };
                }

                if (response.customer) {

                    newTransaction.customer = {
                        external_id: response.customer.external_id,
                        name: response.customer.name,
                        email: response.customer.email,
                        country: response.customer.country,
                        type: response.customer.type,
                        documents: [
                            {
                                type: response.customer.documents[0].type,
                                number: response.customer.documents[0].number
                            }
                        ],
                        phone_numbers: [
                            response.customer.phone_numbers[0],
                        ],
                    };
                }

                if (response.postback_url) {

                    newTransaction.postback_url = response.postback_url;
                }

                if (response.payment_method === 'boleto') {

                    newTransaction.boleto_expiration_date = newDate.format('YYYY-MM-DD');

                } else {

                    newTransaction.pix_expiration_date = newDate.format('YYYY-MM-DD');
                }

                let newTransactionResponse = await Pagarme.post('/transactions', newTransaction);

                if (newTransactionResponse.errors && newTransactionResponse.errors.length) {
                    throw new Error('Payment Error');
                }

                let update = {
                    id_transaction_pagarme: newTransactionResponse.id,
                    status: newTransactionResponse.status,
                }

                if (newTransactionResponse.payment_method === 'boleto') {

                    update.boleto_expiration_date = moment(newTransactionResponse.boleto_expiration_date).toDate();

                } else {

                    update.boleto_expiration_date = moment(newTransactionResponse.pix_expiration_date).toDate();
                    update.pix_qr_code = newTransactionResponse.pix_qr_code;
                }

                await Firestore.update(update, 'order', this.state.editId);
            }

            await this.getDocs();

            this.setState({ loadingModal: false, renewExpirationDateModal: false });
            toast.success('Vencimento alterado com sucesso');

        } catch (error) {
            console.log(error)
            toast.error('Houve um problema ao alterar o vencimento');
            this.setState({ loadingModal: false });
        }
    }

    finishModal() {

        if (this.state.finishModal) {
            return (
                <div>
                    <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', fontWeight: 'bold', alignItems: 'center' }}>
                        <InfoRoundedIcon style={{ fontSize: 40, marginRight: 8 }} />
                        <div>
                            Após finalizar esse pedido, não será mais possível reembolsá-lo.
                        </div>
                    </div>

                    {this.state.markAsPaid ?

                        <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', fontWeight: 'bold', alignItems: 'center', marginBottom: 20, marginTop: 20 }}>
                            <InfoRoundedIcon style={{ fontSize: 40, marginRight: 8 }} />
                            <div>
                                Use essa opção somente se tiver certeza que você recebeu o valor do pedido.
                            </div>
                        </div>

                        : null}

                    <div style={{ marginBottom: 20 }} />
                    {this.state.orderData.status === 'waiting_payment' ? <IosSwitch fullWidth label="Marcar como Pago" value="markAsPaid" checked={this.state.markAsPaid} onChange={(v) => { this.setState({ markAsPaid: v }) }} /> : null}

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { this.finishOrder() }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ finishModal: false }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    pixModal() {

        if (this.state.pixModal) {
            return (
                <div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <div onClick={() => { navigator.clipboard.writeText(this.state.pixQrCode); toast.success('Código copiado para a àrea de transferência') }} style={{ marginTop: 8, cursor: 'pointer', boxShadow: Colors.boxShadow, backgroundColor: Colors.primary, display: 'flex', paddingTop: 8, paddingBottom: 8, paddingLeft: 15, paddingRight: 15, borderRadius: 60, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                <FileCopyIcon style={{ color: 'white', fontSize: 18 }} />
                                <div style={{ fontWeight: 'bold', marginLeft: 8, color: 'white' }}>Copiar Código "Copia e Cola"</div>
                            </div>
                        </div>

                        <div style={{ padding: 15, marginTop: 40, marginBottom: 40, backgroundColor: 'white', border: `8px solid ${Colors.primary}`, boxShadow: Colors.boxShadow, borderRadius: 20 }}>
                            <QRCode size={200} value={this.state.pixQrCode} />
                        </div>
                    </div>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.setState({ pixModal: false, pixQrCode: null }) }} color={Colors.primary} textColor={`white`} loading={this.state.loadingModal} width={'100%'} title={'Fechar'} />
                    </div>
                </div>
            )
        }
    }

    renewExpirationDateModal() {

        if (this.state.renewExpirationDateModal) {
            return (
                <div>
                    <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            style={{ width: '100%' }}
                            invalidDateMessage={false}
                            format={'dd/MM/yyyy'}
                            autoOk={true}
                            label="Novo Vencimento"
                            cancelLabel={'Cancelar'}
                            minDate={moment().add(1, 'days').endOf('day').toDate()}
                            okLabel={'Confirmar'}
                            onChange={(v) => { this.setState({ renewExpirationDate: v }) }}
                            value={this.state.renewExpirationDate}
                        />
                    </MuiPickersUtilsProvider>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { this.renewExpirationDate() }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ renewExpirationDateModal: false }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    renderRefundPayment() {

        return (

            <div>
                {this.state.orderData.payment_method === 'boleto' ?

                    <div>
                        <FormLabel style={{ paddingTop: 28, paddingBottom: 18 }} component="legend">Conta Destino</FormLabel>

                        <div style={{ marginTop: 10 }}>
                            <DefaultSelect search={true} searchField={'LongName'} id={'bank_selection'} valueField={'COMPE'} displayField={'LongName'} secondaryDisplay={'COMPE'} onChange={(v) => { this.setState({ refundBankCode: v.target.value }) }} value={this.state.refundBankCode} docs={BankList} label={'Banco'} />
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                            <div style={{ width: '70%', paddingRight: 10 }}>
                                <InputMask onBlur={(v) => { this.setState({ refundAccount: v }) }} mask="9999999999999" maskPlaceholder={''} alwaysShowMask={false}>
                                    <DefaultInput required number onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Conta'} />
                                </InputMask>
                            </div>
                            <div style={{ width: '30%' }}>
                                <InputMask onBlur={(v) => { this.setState({ refundAccountDv: v }) }} mask="99" maskPlaceholder={''} alwaysShowMask={false}>
                                    <DefaultInput required number onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Digito Verificador'} />
                                </InputMask>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                            <div style={{ width: '50%', paddingRight: 10 }}>
                                <InputMask onBlur={(v) => { this.setState({ refundAgencia: v }) }} mask="9999" maskPlaceholder={''} alwaysShowMask={false}>
                                    <DefaultInput required number onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Agência'} />
                                </InputMask>
                            </div>
                            <div style={{ width: '50%' }}>
                                <InputMask onBlur={(v) => { this.setState({ refundAgenciaDv: v }) }} mask="*" maskPlaceholder={''} alwaysShowMask={false}>
                                    <DefaultInput placeholder={'Deixe vazio caso não tenha'} onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Digito Verificador da Agência'} />
                                </InputMask>
                            </div>
                        </div>

                        <FormLabel style={{ paddingTop: 10 }} component="legend">Tipo da Conta</FormLabel>
                        <Select
                            style={{ width: '100%', marginBottom: 10, marginTop: 10 }}
                            labelId="tipo"
                            value={this.state.refundAccountType}
                            onChange={(v) => { this.setState({ refundAccountType: v.target.value }) }}>
                            <MenuItem value={'conta_corrente'}>{'Conta Corrente'}</MenuItem>
                            <MenuItem value={'conta_poupanca'}>{'Conta Poupança'}</MenuItem>
                            <MenuItem value={'conta_corrente_conjunta'}>{'Conta Corrente Conjunta'}</MenuItem>
                            <MenuItem value={'conta_poupanca_conjunta'}>{'Conta Poupança Conjunta'}</MenuItem>
                        </Select>

                        <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Nome Completo'} onBlur={(v) => { this.setState({ refundAccountName: v }) }} />

                        <DefaultInput label={'Cnpj ou Cpf'} onError={(error, msg) => { this.setState({ inputError: error }) }} required onBlur={(v) => { this.setState({ refundDocument: v }) }} />
                    </div>

                    : null}

                <div style={{ color: 'orange', fontWeight: 'bold', marginTop: 15 }}>
                    {`Você irá estornar o valor de ${CurrencyHelper.centsToMoney(this.state.orderData.amount * 100)} ao cliente.`}
                </div>
            </div>
        )
    }

    handleRefundItem(value, key) {

        let orderData = this.state.orderData;
        orderData.items[key].checked = value;

        this.setState({ orderData });
    }

    renderRefundInventory() {

        return (
            <div>
                {
                    this.state.orderData.items.map((item, key) => {

                        if (item.id && item.tangible) {

                            return (
                                <div style={{ marginLeft: 20 }}>
                                    <IosSwitch fullWidth label={item.title} checked={item.checked} onChange={(v) => { this.handleRefundItem(v, key) }} />
                                </div>
                            )
                        }
                    })
                }
            </div>
        )
    }

    handleRefund(value, canRefund) {

        if (!canRefund) {

            this.setState({ refundPayment: value });

        } else {

            toast.warn('Só é possível estornar um pagamento com status Pago')
        }
    }

    cancelModal() {

        if (this.state.cancelModal) {

            let canRefund = !(this.state.orderData.status === 'paid' || !this.state.orderData.status);

            return (
                <div>
                    <TextareaAutosize style={{ width: '100%', borderRadius: 5, borderColor: 'lightgrey', padding: 15, fontSize: '1rem', marginBottom: 10 }} rowsMax={8} rowsMin={8} onBlur={(v) => { this.setState({ cancelReason: v.target.value }) }} placeholder="Informe o motivo do cancelamento" />

                    <IosSwitch fullWidth label="Estornar Pagamento" value="refundPayment" checked={this.state.refundPayment} onChange={(v) => { this.handleRefund(v, canRefund) }} />
                    {this.state.refundPayment ? this.renderRefundPayment() : null}

                    {this.state.orderData && this.state.orderData.items && this.state.orderData.items.length && (this.state.orderData.type === 'ticket' || this.state.orderData.type === 'product') ? <IosSwitch fullWidth label="Devolver Produtos ao Estoque" value="refundInventory" checked={this.state.refundInventory} onChange={(v) => { this.setState({ refundInventory: v }) }} /> : null}
                    {this.state.refundInventory ? this.renderRefundInventory() : null}

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { this.cancelOrder() }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ cancelModal: false }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    orderItensModal() {

        if (this.state.orderItensModal) {
            return (
                <div>
                    {
                        this.state.orderItens.map((item, key) => {
                            return (
                                <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 10, paddingBottom: 10, marginLeft: 5 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div style={{ fontWeight: 'bold', paddingRight: 3 }}>Nome: </div>{item.title} </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div style={{ fontWeight: 'bold', paddingRight: 3 }}>Quantidade: </div>{item.quantity} </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div style={{ fontWeight: 'bold', paddingRight: 3 }}>Valor Unitário: </div>{CurrencyHelper.centsToMoney(item.unit_price, true)} </div>
                                </div>
                            )
                        })
                    }

                    {this.state.orderEvents ?

                        <div>
                            <h2>Dados do Evento</h2>
                            {this.state.orderEvents.map(event => (
                                <div style={{ marginBottom: 10 }}>
                                    <CalendarEvent
                                        readOnly={true}
                                        showData={true}
                                        order={true}
                                        style={{ borderRadius: '3px', opacity: 0.8, color: 'white', border: '1px', padding: 8, display: 'block', backgroundColor: this.state.orderEventType === 'private_lesson' ? '#f04141' : this.state.orderEventType === 'experimental_lesson' ? 'purple' : '#3b9c3e' }}
                                        event={{
                                            id: event.id,
                                            start: event.start.toDate(),
                                            end: event.end.toDate(),
                                            allDay: false,
                                            type: this.state.orderEventType,
                                            id_student: [event.id_user],
                                            id_teacher: event.id_teacher || null,
                                            id_court: event.id_court
                                        }}
                                    />
                                </div>

                            ))}

                        </div>

                        : null}

                    {this.state.orderUser ?

                        <div>
                            <h2>Dados do Cliente</h2>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 10, paddingBottom: 10, marginLeft: 5 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div style={{ fontWeight: 'bold', paddingRight: 3 }}>Nome: </div>{this.state.orderUser.name} </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div style={{ fontWeight: 'bold', paddingRight: 3 }}>E-mail: </div>{this.state.orderUser.email ? this.state.orderUser.email : 'E-mail Desconhecido'} </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div style={{ fontWeight: 'bold', paddingRight: 3 }}>Telefone: </div>{this.state.orderUser.phone ? this.state.orderUser.phone : 'Telefone Desconhecido'} </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div style={{ fontWeight: 'bold', paddingRight: 3 }}>Cidade: </div>{`${this.state.orderUser.city ? this.state.orderUser.city : 'Cidade Desconhecida'} - ${this.state.orderUser.state ? this.state.orderUser.state : 'Estado Desconhecido'}, ${this.state.orderUser.zipcode ? this.state.orderUser.zipcode : 'CEP Desconhecido'}`} </div>
                            </div>
                        </div>

                        : null}

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { this.setState({ orderItensModal: false }) }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '100%' }} variant={'contained'}>{'FECHAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    renderStatus(status, refunded, finished, method, canceled, type) {

        let text = '';
        let color = '';

        if (method === 'money') {

            if (finished && !refunded && !canceled || (status === 'paid' && type === 'product' || type === 'ticket')) {

                text = 'Pago';
                color = Colors.success;

            } else if (finished && refunded) {

                text = 'Estornado';
                color = 'purple';

            } else if (canceled) {

                text = 'Cancelado';
                color = 'purple';

            } else {

                text = 'Aguardando Pagamento';
                color = 'orange';
            }

        } else {

            if (status === 'paid' || !status) {

                if (refunded) {

                    text = 'Estornado';
                    color = 'purple';

                } else if (canceled) {

                    text = 'Cancelado';
                    color = 'purple';

                } else {

                    text = 'Pago';
                    color = Colors.success;
                }

            } else if (status === 'waiting_payment' && !canceled && !refunded) {

                text = 'Aguardando Pagamento';
                color = 'orange';

            } else if (canceled && !refunded) {

                text = 'Cancelado';
                color = 'purple';

            } else {

                text = 'Estornado';
                color = 'purple';
            }
        }

        return <div style={{ fontWeight: 'bold', color: color }}>{text}</div>
    }

    dateSearch(term, date) {

        if (date) {

            let parsedDate = moment(date.toDate ? date.toDate() : date).format('DD/MM/YYYY HH:mm');
            return parsedDate.toUpperCase().indexOf(term) != -1;
        }

        return false;
    }

    currencySearch(term, amount) {

        return CurrencyHelper.centsToMoney(amount).indexOf(term) != -1;
    }

    renderToolbar(props) {
        return (
            <div>
                <MTableToolbar {...props} />
                <div style={{ paddingLeft: 25, paddingRight: 25, paddingBottom: 25, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ width: 300, marginRight: 15 }}><DefaultSelect valueField={'value'} displayField={'label'} onChange={async (v) => { await this.setState({ filterType: v.target.value }); this.getDocs(true); }} docs={this.state.orderTypes} label={window.screen.width < 1366 ? 'Tipo' : 'Filtrar Tipo'} value={this.state.filterType} /></div>
                    <div style={{ width: 300, marginRight: 15 }}><DefaultSelect valueField={'value'} displayField={'label'} onChange={async (v) => { await this.setState({ filterPaymentMethod: v.target.value }); this.getDocs(true); }} docs={this.state.paymentMethodTypes} label={window.screen.width < 1366 ? 'Mét. Pagamento' : 'Filtrar Método de Pagamento'} value={this.state.filterPaymentMethod} /></div>
                    <div style={{ width: 300, marginRight: 15 }}><DefaultSelect valueField={'value'} displayField={'label'} onChange={async (v) => { await this.setState({ filterStatus: v.target.value }); this.getDocs(true); }} docs={this.state.statusTypes} label={window.screen.width < 1366 ? 'Status' : 'Filtrar Status'} value={this.state.filterStatus} /></div>
                </div>
            </div>
        )
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <DefaultTable
                    title={'Pedidos'}
                    marginTop={10}

                    actions={[
                        {
                            icon: CancelOutlinedIcon,
                            tooltip: 'Cancelar Pedido',
                            onClick: (event, rowData) => { rowData.finished && SessionHelper.getData().type !== 'super_admin' ? toast.warn('Pedido já finalizado') : this.setState({ cancelModal: true, editId: rowData.id, orderData: rowData, refundPayment: false }) }
                        },
                        {
                            icon: CheckIcon,
                            tooltip: 'Finalizar Venda',
                            onClick: (event, rowData) => { rowData.finished ? toast.warn('Pedido já finalizado') : this.setState({ finishModal: true, editId: rowData.id, orderData: rowData, markAsPaid: false }) }
                        },
                        {
                            icon: LocalMallIcon,
                            tooltip: 'Itens do Pedido',
                            onClick: (event, rowData) => { this.getOrderItens(rowData.id_transaction_pagarme, rowData.items, rowData.type, rowData.id_court_rent, rowData.ids_court_rent, rowData.id_private_lesson, rowData.id_experimental_lesson, rowData.id_user) }
                        },
                        (rowData) => rowData.id_transaction_pagarme && rowData.payment_method === 'boleto' ? {
                            icon: DescriptionIcon,
                            tooltip: 'Imprimir Boleto',
                            onClick: (event, rowData) => { this.getOrderBoleto(rowData.id_transaction_pagarme) }
                        } : rowData.id_transaction_pagarme && rowData.pix_qr_code && rowData.payment_method === 'pix' ? {
                            icon: CropFreeIcon,
                            tooltip: 'Imprimir QrCode',
                            onClick: (event, rowData) => { this.setState({ pixModal: true, pixQrCode: rowData.pix_qr_code }) }
                        } : null,
                        (rowData) => rowData.id_transaction_pagarme && rowData.status && rowData.status === 'waiting_payment' && rowData.boleto_expiration_date && moment(rowData.boleto_expiration_date.toDate ? rowData.boleto_expiration_date.toDate() : rowData.boleto_expiration_date).isBefore(new Date()) ? {
                            icon: TodayIcon,
                            tooltip: 'Renovar Vencimento',
                            onClick: (event, rowData) => { this.setState({ renewExpirationDateModal: true, renewExpirationDate: moment().endOf('day').add(1, 'days').toDate(), editId: rowData.id, orderData: rowData }) }
                        } : null,
                    ]}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Pedido', field: 'title', editable: false },
                        { title: 'Tipo', field: 'type', render: rowData => this.renderType(rowData.type), editable: false },
                        { title: 'Valor', field: 'amount', editable: false, render: rowData => <div>{`${CurrencyHelper.centsToMoney(rowData.amount * 100)} ${rowData.installments > 1 ? `(${rowData.installments}x de ${CurrencyHelper.centsToMoney((rowData.amount * 100) / rowData.installments)})` : ''}`}</div>, customFilterAndSearch: (term, rowData) => this.currencySearch(term, rowData.amount * 100) },
                        { title: 'Desconto', field: 'discount', editable: false, render: rowData => <div>{`${rowData.discount ? CurrencyHelper.centsToMoney(rowData.discount * 100) : '--'}`}</div>, customFilterAndSearch: (term, rowData) => this.currencySearch(term, rowData.discount * 100) },
                        { title: 'Método de Pagamento', field: 'payment_method', render: rowData => <div>{PaymentMethodHelper.getPaymentMethodLabel(rowData.payment_method)}</div>, editable: false },
                        { title: 'Status', field: 'status', render: rowData => this.renderStatus(rowData.status, rowData.refunded, rowData.finished, rowData.payment_method, rowData.canceled, rowData.type), editable: false },
                        { title: 'Data de Vencimento', field: 'boleto_expiration_date', render: rowData => <div>{rowData.boleto_expiration_date ? moment(rowData.boleto_expiration_date.toDate()).format('DD/MM/YYYY') : '--'}</div>, editable: false, customFilterAndSearch: (term, rowData) => this.dateSearch(term, rowData.boleto_expiration_date) },
                        { title: 'Cliente', field: 'id_user', editable: false, render: rowData => <RelationField collection={'user'} field={'name'} id={rowData.id_user} /> },
                        { title: 'Finalizado', field: 'finished', type: 'boolean', editable: false, render: rowData => <IosSwitch disabled={true} checked={rowData.finished} /> },
                        { title: 'Finalizado Por', field: 'finished_by', editable: false, render: rowData => rowData.finished_by ? <RelationField collection={'user'} field={'name'} id={rowData.finished_by} /> : '' },
                        { title: 'Data Criação', field: 'date', render: rowData => <div>{moment(rowData.date.toDate()).format('DD/MM/YYYY HH:mm')}</div>, editable: false, customFilterAndSearch: (term, rowData) => this.dateSearch(term, rowData.date) },
                    ]}
                    components={{
                        Toolbar: props => (this.renderToolbar(props)),
                    }}
                    data={this.state.docs}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.cancelModal()} title={'Cancelar Pedido'} onClose={() => { this.setState({ cancelModal: false }) }} open={this.state.cancelModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.finishModal()} title={'Finalizar Pedido'} onClose={() => { this.setState({ finishModal: false, markAsPaid: false }) }} open={this.state.finishModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.orderItensModal()} title={'Itens do Pedido'} onClose={() => { this.setState({ orderItensModal: false, orderEvents: null, orderEventType: null, orderItens: [] }) }} open={this.state.orderItensModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.pixModal()} title={'Qr Code'} onClose={() => { this.setState({ pixModal: false, pixQrCode: null }) }} open={this.state.pixModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.renewExpirationDateModal()} title={'Renovar Vencimento'} onClose={() => { this.setState({ renewExpirationDate: null, renewExpirationDateModal: false }) }} open={this.state.renewExpirationDateModal} />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
