import { FormLabel, IconButton, Tooltip } from '@material-ui/core';
import React, { Component } from 'react';
import TournamentGroupMatch from './TournamentGroupMatch';
import TableChartIcon from '@material-ui/icons/TableChart';
import Colors from '../constants/Colors';
import EmojiEvents from '@material-ui/icons/EmojiEvents';
import { toast } from 'react-toastify';
import DefaultLoader from './DefaultLoader';
import Firestore from '../api/firebase/Firestore';

export default class TournamentGroups extends Component {
    state = {
        groups: this.props.groups,
        loadingGroups: {}
    }

    async componentDidMount() {
        for (let i = 0; i < this.props.groups.length; i++) {
            this.loadGroups(i);
        }
    }

    async loadGroups(groupIndex) {
        let groups = this.state.groups;
        let loadingGroups = this.state.loadingGroups;

        loadingGroups[groupIndex] = true;

        this.setState({ loadingGroups });

        let indexes = this.props.indexes;
        let id_tournament = this.props.tournament.id;

        let query = await Firestore.customQuery('tournament_event').where('id_tournament', '==', id_tournament)
            .where('indexes.category', '==', indexes.category).where('indexes.class', '==', indexes.class)
            .where('indexes.group', '==', groupIndex).get();

        let matches = [];

        query.forEach(match => { matches.push({ ...match.data(), id: match.id }) });
        matches.sort((a, b) => a.indexes.match - b.indexes.match);

        groups[groupIndex].matches = matches;
        loadingGroups[groupIndex] = false;

        this.setState({ groups, loadingGroups });
    }

    renderGroup(key) {
        let group = this.state.groups[key];
        let minWidth = this.props.tournament.type === 'pair' ? this.props.groups.length > 1 ? 900 : window.screen.width > 1080 ? '100%' : 900 : this.props.groups.length > 1 ? 480 : window.screen.width > 1080 ? '100%' : 480;
        let tallestGroup = 0;

        if (this.state.groups[key].matches && !this.state.loadingGroups[key]) {
            let nOfMatches = 0;

            group.matches.forEach((match) => {
                if (match.p1 !== null && match.p2 !== null) nOfMatches++;
            });

            let finalistPlayers = [];

            this.props.finalists.forEach(finalist => {
                if (parseInt(finalist.group) === parseInt(key)) {
                    let names = [];

                    finalist.players.forEach(player => {
                        let user = this.props.users.find(item => item.id === player);

                        return names.push(user ? user.name : '');
                    })

                    if (names.length > 0) finalistPlayers.push(names.join(`, `));
                }
            });

            if (nOfMatches * 70 > tallestGroup) tallestGroup = nOfMatches * 70;

            group.matches.sort((a, b) => {
                if (a.winner && !b.winner) return 1;
                if (b.winner && !a.winner) return -1;
                return 0;
            });

            return (
                <div style={{ minWidth, borderRight: this.props.groups.length > 1 ? '1px solid lightgrey' : 'none', marginRight: this.props.groups.length > 1 ? 20 : 0 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', top: 0, minWidth, maxWidth: minWidth }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', borderRight: finalistPlayers.length > 0 ? '1px solid lightgrey' : 'none', paddingRight: 20 }}>
                            <Tooltip title={'Tabela de resultados e avançar jogadores'}>
                                <IconButton onClick={() => { this.props.tournament.sorted_players && this.props.tournament.sort_date && this.props.tournament.visible_brackets ? this.props.openResultsTable(key) : toast.info(`O torneio deve ter sido sorteado e estar visível você poder visualizar a tabela de resultados.`) }}>
                                    <TableChartIcon />
                                </IconButton>
                            </Tooltip>
                            <h4 style={{ marginLeft: 2, textAlign: 'center' }}>{`Grupo ${key + 1}`}</h4>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {finalistPlayers.length > 0 ? <FormLabel style={{ fontSize: 10, fontWeight: 'bold', marginLeft: 8 }}>VENCEDORES</FormLabel> : null}
                            {finalistPlayers.map(playersNames => (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 20, marginBottom: -12, marginTop: -5 }}>
                                    <EmojiEvents style={{ color: 'orange', fontSize: 18, marginRight: 2 }} />
                                    <p style={{ textAlign: 'center', fontSize: 12, color: Colors.dark }}>{playersNames}</p>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', height: (nOfMatches * 75) + 20, marginTop: 80, marginRight: this.props.groups.length > 1 ? 20 : 0 }}>
                        {
                            group.matches.map((match, matchKey) => {
                                if (match.p1 !== null && match.p2 !== null) {
                                    return <TournamentGroupMatch view={'key'} {...this.props} round={key} match={match} index={matchKey} />
                                }
                            })
                        }
                    </div>
                </div>
            );
        } else {
            return (
                <div style={{ minWidth, borderRight: this.props.groups.length > 1 ? '1px solid lightgrey' : 'none', marginRight: this.props.groups.length > 1 ? 20 : 0 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', top: 0, minWidth, maxWidth: minWidth }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', borderRight: 'none', paddingRight: 20 }}>
                            <h4 style={{ marginLeft: 2, textAlign: 'center' }}>{`Grupo ${key + 1}`}</h4>
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', height: 200, marginTop: 80, marginRight: this.props.groups.length > 1 ? 20 : 0 }}>
                        <p style={{ textAlign: 'center', color: 'grey' }}><DefaultLoader size={28} color={'grey'} /></p>
                    </div>
                </div>
            );
        }
    }
    renderGroups() {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', position: 'relative' }}>
                {
                    this.state.groups.map((group, key) => (this.renderGroup(key)))
                }
            </div>
        )
    }

    render() {
        return (
            <div style={{ padding: 25 }}>
                {this.props.groups && this.props.groups.length ? this.renderGroups() : null}
            </div>
        )
    }
}