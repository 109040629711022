import i18next from '../i18n';
export default class TermHelper {
    static getestablishmentTypeLabel(type) {
        let text = i18next.t('termHelper.student');
        if (type === 'club') text = i18next.t('termHelper.member');

        return {
            plural: text,
            singular: text.substring(0, text.length - 1),
        };
    }
}