import React, { Component } from 'react';
import { IconButton, Badge, Menu, MenuItem, Tooltip, Divider, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import Colors from '../constants/Colors';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EditIcon from '@material-ui/icons/Edit';
import PeopleIcon from '@material-ui/icons/People';
import AddIcon from '@material-ui/icons/Add';
import BusinessIcon from '@material-ui/icons/Business';
import PublicIcon from '@material-ui/icons/Public';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import Firestore from '../api/firebase/Firestore';
import Storage from '../api/firebase/Storage';
import SessionHelper from '../helper/SessionHelper';
import DefaultLoader from './DefaultLoader';
import { toast } from 'react-toastify';
import firebase from 'firebase/app'
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

export default class ProfileCard extends Component {

    state = {
        menuOpen: false,
        loading: false,
        mouseX: 0,
        mouseY: 0,
        userId: this.props.userId ? this.props.userId : SessionHelper.getFirebaseAuth().uid,
        user: {},
        company: SessionHelper.getData().company,
        photoHover: false
    }

    async componentDidMount() {
        await this.getUser();
    }

    async getUser() {
        this.setState({ loading: true });

        let user = await Firestore.getDoc('user', this.state.userId)

        this.setState({ user: user.data(), loading: false });
    }

    async toggleMenu() {
        let menu = !this.state.menuOpen;

        if (menu) {
            await this.getUser()
        }

        this.setState({ menuOpen: menu });
    }

    renderPhoto() {
        return (
            <div style={{ paddingBottom: 10, display: 'flex' }}>
                <input
                    multiple={false}
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="profile-picture"
                    type="file"
                    onChange={(v) => { this.upload(v.target.files[0]) }}
                />
                <label htmlFor="profile-picture">

                    {!this.state.user.photo ?

                        <div style={{ height: 64, width: 64, backgroundColor: '#ededed', borderRadius: 32, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', marginTop: 10 }}>
                            <AddIcon style={{ fontSize: 40, color: 'grey' }} />
                        </div>

                        :

                        <div onMouseOver={() => { this.setState({ photoHover: true }) }} onMouseLeave={() => { this.setState({ photoHover: false }) }} style={{ position: 'relative', height: 64, width: 64, borderRadius: 72 / 2, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', marginTop: 10 }}>
                            {this.state.photoHover && <EditIcon style={{ color: '#fff', cursor: 'pointer', position: 'absolute', marginBottom: 'auto', marginLeft: 'auto', margin: 0, marginTop: 0, fontSize: 30 }} />}
                            <img src={this.state.user.photo} style={{ height: 64, width: 64, borderRadius: 32, }} />
                        </div>

                    }

                </label>
            </div>
        )
    }

    renderHeader() {
        return (
            <MenuItem style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid lightgrey', fontSize: 12 }}>
                <div style={{ fontWeight: 'bold', color: 'black', fontSize: 13, marginRight: 15 }}>{SessionHelper.getFirebaseAuth().uid == this.state.userId ? `Meu Perfil` : `Perfil de ${this.state.user.name}`}</div>
            </MenuItem>
        )
    }

    getProfilePicture(style) {
        return this.state.user.photo ? <img style={style} src={this.state.user.photo} /> : <AccountCircleIcon style={style} />;
    }

    logout() { firebase.auth().signOut(); window.location.href = '/' }

    renderProfile() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', fontSize: 12, padding: 10, minWidth: 300, width: 'auto' }}>
                <div style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid lightgrey', marginBottom: -10 }}>
                    {SessionHelper.getFirebaseAuth().uid == this.state.userId ? this.renderPhoto() : this.getProfilePicture({ color: Colors.primary, height: 64, width: 64, color: Colors.primary, borderRadius: 32 })}
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15, marginTop: SessionHelper.getFirebaseAuth().uid == this.state.userId ? 8 : -2 }}>
                        <p style={{ fontWeight: '400', fontSize: 14 }}>{this.state.user.name}</p>
                        <p style={{ fontWeight: '400', fontSize: 11, marginTop: -10, fontWeight: '200' }}>{this.state.user.email}</p>
                    </div>
                </div>
                <List component="nav" style={{ maxHeight: '100%', overflowY: 'auto' }}>
                    <ListItem>
                        <ListItemIcon>
                            <BusinessIcon style={{ color: Colors.primary }} />
                        </ListItemIcon>
                        <ListItemText primary={this.state.company.name} secondary={`Empresa`} />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemIcon>
                            <PeopleIcon style={{ color: Colors.primary }} />
                        </ListItemIcon>
                        <ListItemText primary={this.state.user.sector ? this.state.user.sector : 'Sem Setor'} secondary={`Setor`} />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemIcon>
                            <PublicIcon style={{ color: Colors.primary }} />
                        </ListItemIcon>
                        <ListItemText primary={'Fuso Horário'} secondary={SessionHelper.getData().company.timezone || 'America/Sao_Paulo'} />
                    </ListItem>
                    <Divider />
                    {this.props.showLogout ?
                        <ListItem button onClick={this.logout}>
                            <ListItemIcon>
                                <ExitToAppIcon style={{ color: Colors.primary }} />
                            </ListItemIcon>
                            <ListItemText primary={'Sair'} />
                        </ListItem>
                        : null}

                </List>
            </div>
        )
    }

    async upload(file) {
        if (file) {

            try {

                this.setState({ loading: true });

                if (this.state.user.photo) await Storage.removeFile(this.state.user.photo);

                let ref = await Storage.uploadFile('user_images', file);
                let downloadPath = await Storage.getFileFromRef(ref);

                if (!downloadPath) {
                    throw new Error('upload error');
                }

                await Firestore.update({ photo: downloadPath }, 'user', this.state.userId);

                await this.getUser();

                this.setState({ logo: downloadPath, loading: false });


                toast.success("Imagem adicionada com sucesso", {
                    position: toast.POSITION.TOP_CENTER
                });

            } catch (error) {
                console.log(error)

                toast.error("Houve um problema ao salvar a imagem", {
                    position: toast.POSITION.TOP_CENTER
                });

                this.setState({ loading: false });
            }
        }
    }

    renderLoading() {
        return (
            <div style={{ minWidth: 300, minHeight: 150 }}>
                <DefaultLoader size={25}
                    color={'lightgrey'} />
            </div>
        )
    }

    render() {

        return (
            <div style={{ display: 'flex' }}>
                <IconButton color="inherit" aria-controls={'primary-search-account-menu'} aria-haspopup="true" onClick={(event) => { this.setState({ mouseX: event.clientX, mouseY: event.clientY }); this.toggleMenu(); }}>
                    {this.getProfilePicture({ color: Colors.primary, height: 40, width: 40, cursor: 'pointer', color: this.props.iconColor || '#fff', borderRadius: 32 })}
                </IconButton>

                <Menu
                    getContentAnchorEl={null}
                    anchorOrigin={this.props.useMouseAsAnchor ? { vertical: this.state.mouseY, horizontal: this.state.mouseX } : { vertical: 'top', horizontal: 'right' }}
                    id={'primary-search-account-menu'}
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={this.state.menuOpen}
                    style={{ marginTop: 40, minHeight: 490, maxHeight: 520, height: 'auto' }}
                    onClose={() => { this.toggleMenu() }} >
                    {this.renderHeader()}
                    {this.state.loading ? this.renderLoading() : this.renderProfile()}
                </Menu>

            </div>
        )

    }
}
