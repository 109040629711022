import React, { Component } from 'react';
import Colors from '../constants/Colors';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from 'moment';
import 'moment/locale/pt-br';
import Firestore from '../api/firebase/Firestore';
import { Button, MenuItem, InputLabel, Select, Badge, FormLabel, Tooltip, IconButton, Menu, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import DefaultSelect from '../components/DefaultSelect';
import DefaultButton from '../components/DefaultButton';
import DefaultModal from '../components/DefaultModal';
import IosSwitch from '../components/IosSwitch';
import DefaultInput from '../components/DefaultInput';
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CalendarEvent from '../components/CalendarEvent';
import SessionHelper from '../helper/SessionHelper';
import { toast } from 'react-toastify';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import CloseIcon from '@material-ui/icons/Close';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import CheckIcon from '@material-ui/icons/Check';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import TodayIcon from '@material-ui/icons/Today';
import RepeatIcon from '@material-ui/icons/Repeat';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import TuneIcon from '@material-ui/icons/Tune';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import PrintIcon from '@material-ui/icons/Print';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import LoopIcon from '@material-ui/icons/Loop';
import TableChartIcon from '@material-ui/icons/TableChart';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import NotificationHelper from '../helper/NotificationHelper';
import SearchBar from '../components/SearchBar';
import CalendarToolbar from '../components/CalendarToolbar';
import AgendaConflict from './AgendaConflict';
import Functions from '../api/firebase/Functions';
import EventPanel from '../components/EventPanel';
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import DefaultLoader from './DefaultLoader';
import Kanban from './Kanban';
import StudentReplacementHistory from './StudentReplacementHistory';
import ReplacementLessonLogHelper from '../helper/ReplacementLessonLogHelper';
import HeaderActionsWrapper from './HeaderActionsWrapper';
import DayUseUserCard from './DayUseUserCard';
import { ChromePicker, CirclePicker } from 'react-color';
import Cancel from '@material-ui/icons/Cancel';
import Palette from '@material-ui/icons/Palette';
import TermHelper from '../helper/TermHelper';
import InputMask from 'react-input-mask';
import CalendarEventReport from './report/CalendarEventReport';
import UserHelper from '../helper/UserHelper';
import Edit from '@material-ui/icons/Edit';
import Clear from '@material-ui/icons/Clear';
import PersonAdd from '@material-ui/icons/PersonAdd';
import ActiveLessonReplacements from './ActiveLessonReplacements';
import DataHelper from '../helper/DataHelper';

moment.locale('pt-br');
const localizer = momentLocalizer(moment);

export default class CalendarComponent extends Component {

    state = {
        loading: true,
        loadingModal: false,
        scheduleModal: false,
        replacementModal: false,
        renewalModal: false,
        renewRepetitionModal: false,
        hasConflict: false,
        repetitionEventToRenew: {},
        events: [],
        start: moment().startOf('month').subtract(5, 'days').toDate(),
        end: moment().endOf('month').add(5, 'days').toDate(),
        courtDocs: [],
        teacherDocs: [],
        companyStudents: [],
        courtRentDocs: [],
        replacementLessonsDocs: [],
        activeRenewalsDocs: [],
        studentSubscriptionDocs: [],
        paramDoc: {},
        replacementLessonsTotal: 0,
        selected_student: 'all',
        selected_category: 'all',
        selected_tag: 'all',
        selected_teacher: 'all',
        selected_teacher: 'all',
        selected_court: 'all',
        selected_subscription_status: 'all',
        selected_replacement_lesson: false,
        exportModal: false,
        scheduleTeacher: null,
        scheduleCourt: null,
        scheduleStudents: [],
        eventColor: false,
        sendPushTeacher: true,
        sendPushStudent: true,
        scheduleStart: null,
        scheduleEnd: null,
        scheduleCategories: [],
        scheduleTags: [],
        panelOpen: false,
        eventOpen: false,
        selectedEvent: null,
        repeat: false,
        repeat_date: null,
        id_parent_event: null,
        updateOnFutureLessons: false,
        showLessons: true,
        showCourtRents: true,
        showDayUses: true,
        showCourtColors: true,
        showCustomColors: true,
        showTournamentMatches: true,
        showExperimentalLessons: true,
        showPrivateLesson: true,
        replacementStudents: [],
        replacementTeacher: '',
        replacementCourt: '',
        openReplacement: false,
        openReplacementNumber: '',
        replacementCategory: [],
        replacementType: 'group',
        courtRentModal: false,
        view: 'day',
        firstLoad: false,
        statusTypes: [
            { value: 'paid', label: 'Pago' },
            { value: 'canceled', label: 'Cancelado' },
            { value: 'ended', label: 'Encerrado' },
            { value: 'pending_payment', label: 'Aguardando Pagamento' },
            { value: 'unpaid', label: 'Inadimplente' },
            { value: 'none', label: 'Sem Matrícula' },
        ],
        showKanban: false,
        kanbanCallback: false,
        dayUseModal: false,
        selectedDayUse: {},
        userDocsDayUse: [],
        loadingSearchBar: true,
        searchingDayUseUsers: false,
        addEventModal: false,
        internalSchedule: 'internal',
        name: '',
        phone: '',
        inputError: null,
        externalUsers: [],
        addExternalUsers: false,
        editIndex: -1
    }

    async componentDidMount() {

        this.getActiveRenewals();
        await this.getParams();
        await this.getTeachers();
        await this.getCourts();
        await this.getStudentSubscriptions();
        await this.getStudents();
        await this.setInitialFilters();
        await this.setInitialView();
        await this.filterEvents();

        this.setState({ firstLoad: true });

        this.agendaInterval = setInterval(() => { if (!this.state.showKanban && !this.state.eventOpen) this.filterEvents() }, 600000);
    }

    componentWillUnmount() {
        clearInterval(this.agendaInterval);
    }

    async setInitialView() {

        if (this.state.paramDoc) {

            let companyDefaultView = this.state.paramDoc.calendar_default_view || 'day';
            await this.setState({ view: companyDefaultView });
        }
    }

    async setInitialFilters() {

        if (this.props.filterStudent) {

            await this.setState({ selected_student: this.props.filterStudent });

        } else if (this.props.filterTeacher) {

            await this.setState({ selected_teacher: this.props.filterTeacher });
        }
    }

    async filterEvents(instance = this) {
        instance.setState({ eventOpen: false, selectedEvent: null, events: [], loading: true });

        let events = [];
        let query = await Firestore.customQuery('lesson').where('id_company', '==', SessionHelper.getData().id_company).where('start', '>=', instance.state.start).where('start', '<=', instance.state.end).get();

        if (instance.state.showLessons) {

            query.forEach((doc, key) => {

                let data = { ...doc.data(), id: doc.id };
                let students = data.id_students;
                let court = instance.state.courtDocs.find(item => item.id === data.id_court);

                let hasStudent = false;
                let unpaidSubscriptions = [];

                students.forEach((id_student, key) => {

                    let user = instance.state.companyStudents.find(item => item.id === id_student);

                    if ((!instance.state.selected_student || instance.state.selected_student === 'all') || instance.state.selected_student && data.id_students.includes(instance.state.selected_student)) {

                        if ((!instance.state.selected_teacher || instance.state.selected_teacher === 'all') || instance.state.selected_teacher && data.id_teacher === instance.state.selected_teacher) {

                            if ((!instance.state.selected_court || instance.state.selected_court === 'all') || instance.state.selected_court && data.id_court === instance.state.selected_court) {

                                if ((!instance.state.selected_category || instance.state.selected_category === 'all') || instance.state.selected_category && data.categories && data.categories.findIndex(item => item == instance.state.selected_category) > -1) {

                                    if ((!instance.state.selected_tag || instance.state.selected_tag === 'all') || instance.state.selected_tag && data.tags && data.tags.findIndex(item => item == instance.state.selected_tag) > -1) {

                                        if ((!instance.state.selected_subscription_status || instance.state.selected_subscription_status === 'all') || (instance.state.selected_subscription_status && user && (user.subscription.status === instance.state.selected_subscription_status || (instance.state.selected_subscription_status === 'none' && !user.subscription.status)))) {

                                            if ((!instance.state.selected_replacement_lesson || instance.state.selected_subscription_status === false) || (data.replacement_lesson_students && data.replacement_lesson_students.includes(id_student))) {

                                                hasStudent = true;

                                                if (user && user.subscription && user.subscription.status === 'unpaid') {

                                                    unpaidSubscriptions.push(user.subscription.id);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                });

                if (hasStudent) {

                    let categories = data.categories;
                    let categoriesLabel = [];

                    if (categories) {

                        categories.forEach((eventCategory, key) => {

                            let category = instance.state.paramDoc.categories.find(item => item.value == eventCategory);

                            if (category) {
                                categoriesLabel.push(category.label);
                            }
                        });
                    }

                    let tags = data.tags;
                    let parsedTags = [];

                    if (tags) {

                        tags.forEach((eventTag, key) => {

                            let tag = instance.state.paramDoc.tags.find(item => item.value == eventTag);
                            if (tag) { parsedTags.push(tag) }
                        });
                    }

                    let teacher = instance.state.teacherDocs[instance.state.teacherDocs.findIndex(item => item.id === doc.data().id_teacher)];
                    let studentNames = [];

                    data.id_students.forEach((student, key) => {

                        let doc = instance.state.companyStudents.find(item => item.id === student);

                        if (doc) {

                            studentNames.push(UserHelper.treatName(doc.name));

                        } else {

                            studentNames.push('Aluno não encontrado');
                        }
                    });

                    let event = {
                        start: doc.data().start.toDate(),
                        end: doc.data().end.toDate(),
                        allDay: false,
                        id: doc.id,
                        teacher: teacher ? UserHelper.treatName(teacher.name) : 'Professor não encontrado',
                        court: court.name,
                        id_parent_event: data.id_parent_event || null,
                        id_teacher: data.id_teacher,
                        id_student: data.id_students,
                        id_court: data.id_court,
                        id_order: data.id_order,
                        categories: categories,
                        category_label: categoriesLabel.join(', '),
                        id_tags: tags,
                        tags: parsedTags,
                        unpaid_subscriptions: unpaidSubscriptions,
                        replacement_students: data.replacement_lesson_students,
                        repeat_until: doc.data().repeat_until ? doc.data().repeat_until : false,
                        renewed_until: doc.data().renewed_until ? doc.data().renewed_until : false,
                        eventColor: data.color || false,
                        id_replacement_lesson: doc.data().id_replacement_lesson,
                        student_names: studentNames.join(', '),
                    };

                    events.push(event);
                }
            });
        }

        if (instance.props.showCourtRents && instance.state.showCourtRents) {

            let rents = await Firestore.customQuery('court_rent').where('id_company', '==', SessionHelper.getData().id_company).where('start', '>=', instance.state.start).where('start', '<=', instance.state.end).get();

            rents.forEach((rent, key) => {

                let data = { ...rent.data(), id: rent.id };
                let court = instance.state.courtDocs.find(item => item.id === data.id_court);

                if ((!instance.state.selected_student || instance.state.selected_student === 'all') || instance.state.selected_student && data.id_user === instance.state.selected_student) {

                    if (!instance.state.selected_teacher || instance.state.selected_teacher === 'all') {

                        if ((!instance.state.selected_court || instance.state.selected_court === 'all') || instance.state.selected_court && data.id_court === instance.state.selected_court) {

                            let event = {
                                start: data.start.toDate(),
                                end: data.end.toDate(),
                                allDay: false,
                                id: data.id,
                                type: 'court_rent',
                                color: '#3b9c3e',
                                id_student: data.id_students ? data.id_students : data.id_user ? [data.id_user] : [],
                                id_court: data.id_court,
                                id_order: data.id_order,
                                id_package: data.id_package,
                                deletable: !data.id_order ? true : false,
                                court: court.name,
                                id_parent_event: data.id_parent_event || null,
                                repeat_until: data.repeat_until ? data.repeat_until : false,
                                renewed_until: data.renewed_until ? data.renewed_until : false,
                                id_admin: data.id_admin,
                                eventColor: data.color || false,
                                manual_info: data.manual_info ? data.manual_info : null,
                                racquetCount: data.racquetCount && data.racquetCount > 0 ? data.racquetCount : 0
                            };

                            events.push(event);
                        }
                    }
                }
            });
        }

        if (instance.props.showDayUses && instance.state.showDayUses) {

            let day_uses = await Firestore.customQuery('day_use').where('id_company', '==', SessionHelper.getData().id_company).where('start', '>=', instance.state.start).where('start', '<=', instance.state.end).get();

            day_uses.forEach((day_use, key) => {

                let data = { ...day_use.data(), id: day_use.id };

                if ((!instance.state.selected_student || instance.state.selected_student === 'all') || instance.state.selected_student && data.participants.includes(instance.state.selected_student)) {
                    if (!instance.state.selected_teacher || instance.state.selected_teacher === 'all') {
                        if ((!instance.state.selected_court || instance.state.selected_court === 'all') || instance.state.selected_court && data.id_courts.includes(instance.state.selected_court)) {
                            let event = {
                                start: data.start.toDate(),
                                end: data.end.toDate(),
                                deletable: data.participants && data.participants.length < 1 && data.active,
                                allDay: true,
                                id: data.id,
                                type: 'day_use',
                                color: data.active || data.finished ? Colors.order.day_use : Colors.disabled,
                                participants: data.participants ? data.participants : [],
                                id_courts: data.id_courts,
                                active: data.active,
                                finished: data.finished,
                                court: data.finished ? `Finalizado` : data.active && (SessionHelper.getData().day_use && SessionHelper.getData().day_use.limit > data.participants.length ? false : true) ? `${data.id_courts.length} quadras` : data.participants.length > 0 ? `Esgotado` : `Cancelado`
                            };


                            events.push(event);
                        }
                    }
                }
            });

        }

        if (instance.props.showTournamentMatches && instance.state.showTournamentMatches) {

            let tournamentMatches = await Firestore.customQuery('tournament_event').where('id_company', '==', SessionHelper.getData().id_company).where('start', '>=', instance.state.start).where('start', '<=', instance.state.end).get();

            tournamentMatches.forEach((tournamentMatch, key) => {

                tournamentMatch = { ...tournamentMatch.data(), id: tournamentMatch.id };

                // if (tournamentMatch.id_players && tournamentMatch.id_players.length) {

                if ((!instance.state.selected_student || instance.state.selected_student === 'all') || instance.state.selected_student && tournamentMatch.id_players.includes(instance.state.selected_student)) {

                    if (!instance.state.selected_teacher || instance.state.selected_teacher === 'all') {

                        if ((!instance.state.selected_court || instance.state.selected_court === 'all')) {

                            let court = tournamentMatch.court;

                            let event = {
                                start: tournamentMatch.start ? tournamentMatch.start.toDate() : '',
                                end: tournamentMatch.end ? tournamentMatch.end.toDate() : '',
                                allDay: false,
                                id: tournamentMatch.id,
                                id_tournament: tournamentMatch.id_tournament,
                                id_court: tournamentMatch.id_court || null,
                                indexes: tournamentMatch.indexes || null,
                                court: court ? court : 'Sem Localização',
                                type: 'tournament',
                                color: 'orange',
                                id_student: tournamentMatch.id_players,
                            };

                            events.push(event);
                        }
                    }
                }
                // }
            });
        }

        if (instance.props.showExperimentalLessons && instance.state.showExperimentalLessons) {

            let experimentalLessons = await Firestore.customQuery('experimental_lesson').where('id_company', '==', SessionHelper.getData().id_company).where('start', '>=', instance.state.start).where('start', '<=', instance.state.end).get();

            experimentalLessons.forEach((experimentalLesson, key) => {

                experimentalLesson = { ...experimentalLesson.data(), id: experimentalLesson.id };
                let court = instance.state.courtDocs.find(item => item.id === experimentalLesson.id_court);

                if ((!instance.state.selected_student || instance.state.selected_student === 'all')) {

                    if ((!instance.state.selected_teacher || instance.state.selected_teacher === 'all') || instance.state.selected_teacher && experimentalLesson.id_teacher === instance.state.selected_teacher) {

                        if ((!instance.state.selected_court || instance.state.selected_court === 'all') || instance.state.selected_court && experimentalLesson.id_court === instance.state.selected_court) {

                            let event = {
                                start: experimentalLesson.start.toDate(),
                                end: experimentalLesson.end.toDate(),
                                allDay: false,
                                id: experimentalLesson.id,
                                type: 'experimental_lesson',
                                color: 'purple',
                                id_student: [experimentalLesson.id_user],
                                id_teacher: experimentalLesson.id_teacher,
                                id_order: experimentalLesson.id_order,
                                id_court: experimentalLesson.id_court,
                                deletable: !experimentalLesson.id_order ? true : false,
                                court: court.name,
                            };

                            events.push(event);
                        }
                    }
                }
            });
        }

        if (instance.props.showPrivateLesson && instance.state.showPrivateLesson) {

            let privateLessons = await Firestore.customQuery('private_lesson').where('id_company', '==', SessionHelper.getData().id_company).where('start', '>=', instance.state.start).where('start', '<=', instance.state.end).get();

            privateLessons.forEach((privateLesson, key) => {

                privateLesson = { ...privateLesson.data(), id: privateLesson.id };
                let court = instance.state.courtDocs.find(item => item.id === privateLesson.id_court);

                if ((!instance.state.selected_student || instance.state.selected_student === 'all')) {

                    if ((!instance.state.selected_teacher || instance.state.selected_teacher === 'all') || instance.state.selected_teacher && privateLesson.id_teacher === instance.state.selected_teacher) {

                        if ((!instance.state.selected_court || instance.state.selected_court === 'all') || instance.state.selected_court && privateLesson.id_court === instance.state.selected_court) {

                            let event = {
                                start: privateLesson.start.toDate(),
                                end: privateLesson.end.toDate(),
                                allDay: false,
                                id: privateLesson.id,
                                type: 'private_lesson',
                                color: '#f04141',
                                id_student: [privateLesson.id_user],
                                id_teacher: privateLesson.id_teacher,
                                id_order: privateLesson.id_order,
                                id_court: privateLesson.id_court,
                                court: court.name,
                                deletable: privateLesson.id_package ? true : false,
                                id_package: privateLesson.id_package,
                            };

                            events.push(event);
                        }
                    }
                }
            });
        }

        let timeHash = {};

        events.forEach((event, index) => {
            if (event && event.start) {
                const flatStart = (moment(event.start).set({ 'second': 0 }).unix()).toString();
                if (!timeHash[flatStart]) timeHash[flatStart] = [];
                timeHash[flatStart].push(index);

                event.rankedStart = moment(event.start).set({ 'second': 0 }).toDate();
            }
        });

        if (Object.keys(timeHash).length > 0) {
            Object.keys(timeHash).forEach(hash => {
                timeHash[hash].sort((indexA, indexB) => {
                    let eventA = events[indexA];
                    let eventB = events[indexB];

                    if (eventA.court && eventB.court) {
                        const compare = eventA.court.localeCompare(eventB.court);
                        return compare;
                    }
                });
            });

            Object.keys(timeHash).forEach(hash => {
                timeHash[hash].forEach((elemIndex, index) => {
                    let event = events[elemIndex];

                    events[elemIndex].rankedStart = moment(event.rankedStart).set({ 'millisecond': 0 }).add(999 - index, 'millisecond').toDate();
                });
            });
        }

        instance.setState({ events: events, loading: false });

        if (instance.state.kanbanCallback && instance.state.showKanban) instance.state.kanbanCallback(events);
    }

    async notifyCanceledLesson(instance = this, doc, description) {

        let query = await Firestore.getDoc('lesson', doc);

        if (query.exists) {

            let data = query.data();

            let students = data.id_students;
            let teacher = data.id_teacher;

            let users = await Firestore.customQuery('user').where(Firestore.idField(), 'in', students).get();

            users.forEach((user, key) => {

                let userDoc = { ...user.data(), id: user.id };

                if (userDoc && userDoc.notification_token && userDoc.notification_token.data) {

                    let token = userDoc.notification_token.data;
                    let notification = {
                        icon: 'calendar-times',
                        color: 'red',
                        navigate: 'Notification'
                    };

                    NotificationHelper.send('Aula Cancelada', `A sua aula de ${moment(data.start.toDate()).format('DD/MM/YYYY HH:mm')} foi cancelada pela academia. ${description ? `Motivo: ${description}` : ``}`, notification, token, userDoc.id);
                }
            });

            let teacherDoc = instance.state.teacherDocs.find(item => item.id === teacher);

            if (teacherDoc && teacherDoc.notification_token && teacherDoc.notification_token.data) {

                let token = teacherDoc.notification_token.data;
                let notification = {
                    icon: 'calendar-times',
                    color: 'red',
                    navigate: 'Notification'
                };

                NotificationHelper.send('Aula Cancelada', `A sua aula de ${moment(data.start.toDate()).format('DD/MM/YYYY HH:mm')} foi cancelada, abra o app e confira.`, notification, token, teacher);
            }
        }
    }

    async notifyCanceledCourtRent(instance = this, doc, description) {
        let query = await Firestore.getDoc('court_rent', doc);

        if (query.exists) {

            let data = query.data();

            if (data.id_user) {
                let id_user = data.id_user;

                let userQuery = await Firestore.getDoc('user', id_user);
                let userDoc = { ...userQuery.data(), id: userQuery.id };

                if (userDoc && userDoc.notification_token && userDoc.notification_token.data) {

                    let token = userDoc.notification_token.data;

                    let notification = {
                        icon: 'calendar-times',
                        color: 'red',
                        navigate: 'Notification'
                    };

                    NotificationHelper.send('Aluguel Cancelado', `O seu aluguel de quadra de ${moment(data.start.toDate()).format('DD/MM/YYYY HH:mm')} foi cancelado pela academia. ${description ? `Motivo: ${description}` : ``}`, notification, token, id_user);

                    if (data.id_package) {
                        let notification = {
                            icon: 'plus-circle',
                            color: 'green',
                            navigate: 'Notification'
                        };

                        NotificationHelper.send('Seus créditos foram reembolsados', `${data.playerCount} créditos foram reembolsados ao seu pacote por conta do cancelamento do aluguel.`, notification, token, id_user);
                    }
                }
            }
        }
    }

    async notifyCanceledPrivateLesson(instance = this, doc, description) {
        let query = await Firestore.getDoc('private_lesson', doc);

        if (query.exists) {

            let data = query.data();

            if (data.id_user) {
                let id_user = data.id_user;

                let userQuery = await Firestore.getDoc('user', id_user);
                let userDoc = { ...userQuery.data(), id: userQuery.id };

                if (userDoc && userDoc.notification_token && userDoc.notification_token.data) {

                    let token = userDoc.notification_token.data;

                    let notification = {
                        icon: 'calendar-times',
                        color: 'red',
                        navigate: 'Notification'
                    };

                    NotificationHelper.send('Aula Avulsa Cancelada', `A sua aula avulsa de ${moment(data.start.toDate()).format('DD/MM/YYYY HH:mm')} foi cancelada pela academia. ${description ? `Motivo: ${description}` : ``}`, notification, token, id_user);

                    if (data.id_package) {
                        let notification = {
                            icon: 'plus-circle',
                            color: 'green',
                            navigate: 'Notification'
                        };

                        NotificationHelper.send('Seus créditos foram reembolsados', `1 crédito foi reembolsado ao seu pacote por conta do cancelamento da aula.`, notification, token, id_user);
                    }
                }
            }
        }
    }

    async notifyCanceledExperimentalLesson(instance = this, doc, description) {
        let query = await Firestore.getDoc('experimental_lesson', doc);

        if (query.exists) {

            let data = query.data();

            if (data.id_user) {
                let id_user = data.id_user;

                let userQuery = await Firestore.getDoc('user', id_user);
                let userDoc = { ...userQuery.data(), id: userQuery.id };

                if (userDoc && userDoc.notification_token && userDoc.notification_token.data) {

                    let token = userDoc.notification_token.data;

                    let notification = {
                        icon: 'calendar-times',
                        color: 'red',
                        navigate: 'Notification'
                    };

                    NotificationHelper.send('Aula Experimental', `A sua aula experimental de ${moment(data.start.toDate()).format('DD/MM/YYYY HH:mm')} foi cancelada pela academia. ${description ? `Motivo: ${description}` : ``}`, notification, token, id_user);
                }
            }
        }
    }

    async getStudents(instance = this, state = 'companyStudents', fromReport = false) {

        let query = await Firestore.customQuery('user').where('type', '==', 'student').where('id_companies', 'array-contains', SessionHelper.getData().id_company).orderBy('name', 'asc').get();
        let companyDocs = [];
        let replacementLessons = 0;

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            let companyStudent = { ...data, subscription: {} };

            if (!fromReport) {

                let companyData = companyStudent.companies[SessionHelper.getData().id_company];

                if (companyData && companyData.category !== null && companyData.category !== undefined && companyData.category.toString().length > 0 && instance.state.paramDoc) {
                    let category = instance.state.paramDoc.categories.find(item => item.value == companyData.category);

                    if (category) {
                        companyStudent.category_label = category.label;
                        companyStudent.category_color = category.color || Colors.primary;
                    }
                }

                if (companyData && companyData.lesson_type !== null && companyData.lesson_type !== undefined && companyData.lesson_type.toString().length > 0) {
                    let lesson_type_label = UserHelper.getLessonTypeLookup();

                    if (lesson_type_label[companyData.lesson_type]) {
                        companyStudent.lesson_type_label = lesson_type_label[companyData.lesson_type];
                    }
                }

                if (companyStudent.lesson_type_label || companyStudent.category_label) {
                    companyStudent.labelComponent = <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 2 }}>
                        {companyStudent.category_label ? <div style={{ ...styles.selectComponentBox, backgroundColor: companyStudent.category_color }}>{companyStudent.category_label}</div> : null}
                        {companyStudent.lesson_type_label ? <div style={{ ...styles.selectComponentBox, backgroundColor: UserHelper.getLessonType()[companyData.lesson_type].color }}>{companyStudent.lesson_type_label}</div> : null}
                    </div>;
                }

                let subscription = instance.state.studentSubscriptionDocs.find(item => item.id_user === data.id);

                if (subscription) {
                    companyStudent.subscription = subscription;
                }

                companyDocs.push(companyStudent);

                if (companyData && companyData.replacement_lesson) {
                    replacementLessons += companyData.replacement_lesson;
                }

            } else {

                companyDocs.push(companyStudent);
            }
        });

        let stateData = { replacementLessonsTotal: replacementLessons };
        stateData[state] = companyDocs;

        instance.setState(stateData);
    }

    async getCourtRents() {

        let query = await Firestore.customQuery('court_rent').where('id_company', '==', SessionHelper.getData().id_company).get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ courtRentDocs: docs });
    }

    async getTournaments() {

        let query = await Firestore.customQuery('tournament').where('id_company', '==', SessionHelper.getData().id_company).get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ tournamentDocs: docs });
    }

    async getTeachers(instance = this) {

        let query = await Firestore.customQuery('user').where('id_company', '==', SessionHelper.getData().id_company).where('type', '==', 'teacher').orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        instance.setState({ teacherDocs: docs });
    }

    async getStudentSubscriptions() {

        let query = await Firestore.customQuery('student_subscription').where('id_company', '==', SessionHelper.getData().id_company).orderBy('created_at', 'desc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = { ...doc.data(), id: doc.id };
            docs.push(data);
        });

        this.setState({ studentSubscriptionDocs: docs });
    }

    async getCourts(instance = this, state = '', fromReport = false) {

        let query = await Firestore.customQuery('court').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            if (!fromReport) {

                data.sport_label = this.getSportLookup()[data.sport || `tennis`];
            }

            docs.push(data);
        });

        instance.setState({ courtDocs: docs });
    }

    getSportLookup() {
        return {
            'tennis': 'Tennis',
            'padel': 'Padel',
            'beach_tennis': 'Beach Tennis',
            'squash': 'Squash',
            'badminton': 'Badminton',
            'beach_volley': 'Vôlei de Praia',
            'foot_volley': 'Futevôlei'
        }
    }

    async getReplacementLessons() {
        let query = await Firestore.customQuery('replacement_lesson').where('id_company', '==', SessionHelper.getData().id_company).orderBy('start', 'desc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ replacementLessonsDocs: docs });
    }

    async getActiveRenewals() {
        let query = await Firestore.customQuery('active_renewals').where('id_company', '==', SessionHelper.getData().id_company).where('event_date', '>=', moment().subtract(1, 'week').startOf('day').toDate()).orderBy('event_date', 'desc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ activeRenewalsDocs: docs });
    }

    async getParams() {

        let query = await Firestore.customQuery('settings').where('id_company', '==', SessionHelper.getData().id_company).get();
        let data = this.state.config;

        if (query.size === 1 && query.docs[0]) {

            data = query.docs[0].data();
            data.id = query.docs[0].id;

            if (!data.tags) {
                data.tags = [];
            }
        }

        this.setState({ paramDoc: data });
    }

    async notifyUpdatedLesson(oldLesson, newLesson, doc) {

        let isSameDate = moment(oldLesson.start.toDate()).isSame(moment(newLesson.start));
        let students = this.state.scheduleStudents;
        let removedStudents = students.filter(x => !students.includes(x));

        if (moment(newLesson.start).isAfter(moment())) {

            if (this.state.sendPushStudent) {

                if (removedStudents.length) {

                    let users = await Firestore.customQuery('user').where(Firestore.idField(), 'in', removedStudents).get();

                    users.forEach((student, key) => {

                        let userDoc = { ...student.data(), id: student.id };

                        if (userDoc && userDoc.notification_token && userDoc.notification_token.data) {

                            let token = userDoc.notification_token.data;
                            let notification = {
                                icon: 'calendar-times',
                                color: 'red',
                                navigate: 'Notification'
                            };

                            NotificationHelper.send('Aula Cancelada', `Você foi removido(a) da aula de ${moment(oldLesson.start.toDate()).format('DD/MM/YYYY HH:mm')}. Caso não reconheça a mudança contate a academia.`, notification, token, userDoc.id);
                        }
                    });
                }

                let users = await Firestore.customQuery('user').where(Firestore.idField(), 'in', students).get();

                users.forEach((student, key) => {

                    let userDoc = { ...student.data(), id: student.id };

                    if (userDoc && userDoc.notification_token && userDoc.notification_token.data) {

                        let token = userDoc.notification_token.data;
                        let notification = {
                            icon: 'calendar-plus',
                            color: 'green',
                            navigate: 'Lesson',
                            lesson_id: doc,
                            start: newLesson.start,
                        };

                        if (!isSameDate && oldLesson.id_students.includes(student)) {
                            notification.icon = 'calendar-day';
                            notification.color = 'orange';

                            NotificationHelper.send('Aula Agendada', `A sua aula de ${moment(oldLesson.start.toDate()).format('DD/MM/YYYY HH:mm')} foi alterada para ${moment(newLesson.start).format('DD/MM/YYYY HH:mm')}, abra o app e confira.`, notification, token, student);

                        } else if (!oldLesson.id_students.includes(student)) {

                            NotificationHelper.send('Aula Agendada', `Nova aula agendada para ${moment(newLesson.start).format('DD/MM/YYYY HH:mm')}, abra o app e confira.`, notification, token, userDoc.id);
                        }
                    }
                });
            }

            if (this.state.sendPushTeacher) {

                if (oldLesson.id_teacher === newLesson.id_teacher && !isSameDate) {

                    let userDoc = this.state.teacherDocs.find(item => item.id === newLesson.id_teacher);

                    if (userDoc && userDoc.notification_token && userDoc.notification_token.data) {

                        let token = userDoc.notification_token.data;
                        let notification = {
                            icon: 'calendar-day',
                            color: 'orange',
                            navigate: 'TeacherLesson',
                            lesson_id: doc,
                            start: newLesson.start,
                        };

                        NotificationHelper.send('Aula Agendada', `A sua aula de ${moment(oldLesson.start.toDate()).format('DD/MM/YYYY HH:mm')} foi alterada para ${moment(newLesson.start).format('DD/MM/YYYY HH:mm')}, abra o app e confira.`, notification, token, newLesson.id_teacher);
                    }

                } else if (oldLesson.id_teacher !== newLesson.id_teacher) {

                    let oldTeacherDoc = this.state.teacherDocs.find(item => item.id === oldLesson.id_teacher);
                    let newTeacherDoc = this.state.teacherDocs.find(item => item.id === newLesson.id_teacher);

                    if (oldTeacherDoc && oldTeacherDoc.notification_token && oldTeacherDoc.notification_token.data) {

                        let token = oldTeacherDoc.notification_token.data;
                        let notification = {
                            icon: 'calendar-times',
                            color: 'red',
                            navigate: 'Notification',
                        };

                        NotificationHelper.send('Aula Agendada', `A sua aula de ${moment(oldLesson.start.toDate()).format('DD/MM/YYYY HH:mm')} foi cancelada, abra o app e confira.`, notification, token, oldLesson.id_teacher);
                    }

                    if (newTeacherDoc && newTeacherDoc.notification_token && newTeacherDoc.notification_token.data) {

                        let token = newTeacherDoc.notification_token.data;
                        let notification = {
                            icon: 'calendar-plus',
                            color: 'green',
                            navigate: 'TeacherLesson',
                            lesson_id: doc,
                            start: newLesson.start,
                        };

                        NotificationHelper.send('Aula Agendada', `Nova aula agendada para ${moment(newLesson.start).format('DD/MM/YYYY HH:mm')}, abra o app e confira.`, notification, token, newLesson.id_teacher);
                    }
                }
            }
        }
    }

    async notifyUpdatedCourtRent(oldCourtRentQuery, courtRent, doc) {
        if (this.state.scheduleStudents && this.state.internalSchedule === 'internal') {
            if (moment(courtRent.start).isAfter(moment())) {
                for (let student in this.state.scheduleStudents) {
                    let userQuery = await Firestore.getDoc('user', student);
                    let userDoc = { ...userQuery.data(), id: userQuery.id };

                    if (userDoc && userDoc.notification_token && userDoc.notification_token.data) {
                        let token = userDoc.notification_token.data;
                        let notification = {
                            icon: 'clock',
                            navigate: 'CourtRent',
                            start: courtRent.start,
                            court_rent_id: doc,
                        };

                        NotificationHelper.send('Aluguel Agendado', `O seu aluguél de ${moment(oldCourtRentQuery.start.toDate()).format('DD/MM/YYYY HH:mm')} foi alterada para ${moment(courtRent.start).format('DD/MM/YYYY HH:mm')}, abra o app e confira.`, notification, token, student);
                    }
                }
            }
        }


    }

    async scheduleLesson() {
        if (this.state.loadingModal == true) {
            toast.warn('Aguarde, realizando ação...');
            return;
        }

        // if (this.state.hasConflict == true) {
        //     toast.warn('Este horário já tem um agendamento, verifique os horários disponíveis.');
        //     return;
        // }

        if (this.state.scheduleStart && this.state.scheduleEnd && this.state.scheduleStudents.length && this.state.scheduleTeacher && this.state.scheduleCourt) {

            try {

                this.setState({ loadingModal: true });

                let data = {
                    start: this.state.scheduleStart,
                    end: this.state.scheduleEnd,
                    id_students: this.state.scheduleStudents,
                    id_teacher: this.state.scheduleTeacher,
                    id_court: this.state.scheduleCourt,
                    id_company: SessionHelper.getData().id_company,
                    categories: this.state.scheduleCategories,
                    tags: this.state.scheduleTags,
                    created_at: new Date(),
                    color: this.state.eventColor
                };

                let doc = null;

                if (this.state.editId) {

                    doc = this.state.editId;

                    let oldLessonQuery = await Firestore.getDoc('lesson', doc);
                    let oldLesson = oldLessonQuery.data();

                    await Firestore.update(data, 'lesson', doc);

                    this.notifyUpdatedLesson(oldLesson, data, doc);

                    if (oldLesson && oldLesson.id_replacement_lesson) {

                        let replacementLessonQuery = await Firestore.customQuery('replacement_lesson').where('id_lesson', '==', doc).get();

                        if (replacementLessonQuery.size > 0) {

                            for (let replacementLessonDoc of replacementLessonQuery.docs) {

                                await Firestore.update({ start: data.start, end: data.end }, 'replacement_lesson', replacementLessonDoc.id);
                            }
                        }
                    }

                } else {

                    doc = await Firestore.getId('lesson');

                    if (this.state.repeat) {
                        data.id_parent_event = doc;
                    }

                    await Firestore.insert(data, 'lesson', doc);

                    if (this.state.sendPushStudent) {

                        this.state.scheduleStudents.forEach((student, key) => {

                            let userDoc = this.state.companyStudents.find(item => item.id === student);

                            if (userDoc && userDoc.notification_token && userDoc.notification_token.data) {

                                let token = userDoc.notification_token.data;
                                let notification = {
                                    icon: 'calendar-plus',
                                    color: 'green',
                                    navigate: 'Lesson',
                                    lesson_id: doc,
                                    start: data.start,
                                };

                                NotificationHelper.send('Aula Agendada', `Nova aula agendada para ${moment(data.start).format('DD/MM/YYYY HH:mm')}, abra o app e confira.`, notification, token, student);
                            }
                        });
                    }

                    if (this.state.sendPushTeacher) {

                        let teacherDoc = this.state.teacherDocs.find(item => item.id === this.state.scheduleTeacher);

                        if (teacherDoc && teacherDoc.notification_token && teacherDoc.notification_token.data) {

                            let token = teacherDoc.notification_token.data;
                            let notification = {
                                icon: 'calendar-plus',
                                color: 'green',
                                navigate: 'TeacherLesson',
                                lesson_id: doc,
                                start: data.start,
                            };

                            NotificationHelper.send('Aula Agendada', `Nova aula agendada para ${moment(data.start).format('DD/MM/YYYY HH:mm')}, abra o app e confira.`, notification, token, teacherDoc.id);
                        }
                    }
                }

                if (this.state.repeat) {

                    let repeatDate = moment(this.state.repeat_date).endOf('day');;

                    if (repeatDate.isValid()) {

                        let start = moment(data.start).startOf('day');
                        let repeat_number = parseInt(repeatDate.diff(start, 'weeks', false));

                        let repeat_until = moment(data.start).add(repeat_number, 'weeks');

                        if (Number.isInteger(parseInt(repeat_number)) && repeat_number > 0) {

                            let referenceStart = data.start;
                            let referenceEnd = data.end;

                            for (let index = 0; index < repeat_number; index++) {

                                referenceStart = moment(referenceStart).add(1, 'week').toDate();
                                referenceEnd = moment(referenceEnd).add(1, 'week').toDate();

                                let lesson = {
                                    start: referenceStart,
                                    end: referenceEnd,
                                    id_students: this.state.scheduleStudents,
                                    id_teacher: this.state.scheduleTeacher,
                                    id_court: this.state.scheduleCourt,
                                    id_parent_event: doc,
                                    repeat_until: repeat_until.toDate(),
                                    id_company: SessionHelper.getData().id_company,
                                    tags: this.state.scheduleTags,
                                    created_at: new Date(),
                                    color: this.state.eventColor
                                }

                                await Firestore.insert(lesson, 'lesson');
                            }

                            if (this.state.sendPushStudent) {

                                this.state.scheduleStudents.forEach((student, key) => {

                                    let userDoc = this.state.companyStudents.find(item => item.id === student);

                                    if (userDoc && userDoc.notification_token && userDoc.notification_token.data) {

                                        let token = userDoc.notification_token.data;
                                        let notification = {
                                            icon: 'calendar-plus',
                                            color: 'blue',
                                            navigate: 'Home'
                                        };

                                        NotificationHelper.send('Aula Agendada', `Você tem ${repeat_number} novas aulas agendadas, abra o app e confira.`, notification, token, student);
                                    }
                                });
                            }

                            if (this.state.sendPushTeacher) {

                                let teacherDoc = this.state.teacherDocs.find(item => item.id === this.state.scheduleTeacher);

                                if (teacherDoc && teacherDoc.notification_token && teacherDoc.notification_token.data) {

                                    let token = teacherDoc.notification_token.data;
                                    let notification = {
                                        icon: 'calendar-plus',
                                        color: 'blue',
                                        navigate: 'TeacherHome'
                                    };

                                    NotificationHelper.send('Aula Agendada', `Você tem ${repeat_number} novas aulas agendadas, abra o app e confira.`, notification, token, this.state.scheduleTeacher);
                                }
                            }

                        } else {

                            toast.warn('A data de repetição informada é inválida', { autoClose: false });
                        }

                    } else {

                        toast.warn('A data de repetição informada é inválida', { autoClose: false });
                    }
                }

                if (this.state.id_parent_event && this.state.updateOnFutureLessons) {
                    let referenceStart = data.start;
                    let referenceEnd = data.end;

                    let query = await Firestore.customQuery('lesson').where('id_parent_event', '==', this.state.id_parent_event).where('start', '>=', moment(referenceStart).toDate()).orderBy('start', 'desc').get();

                    if (query.docs.length > 0) {
                        for (let i = 0; i < query.docs.length; i++) {
                            const editId = query.docs[i].id;

                            if (i > 0) {
                                referenceStart = moment(referenceStart).add(1, 'week').toDate();
                                referenceEnd = moment(referenceEnd).add(1, 'week').toDate();
                            }

                            let lesson = {
                                start: referenceStart,
                                end: referenceEnd,
                                id_students: this.state.scheduleStudents,
                                id_teacher: this.state.scheduleTeacher,
                                id_court: this.state.scheduleCourt,
                                tags: this.state.scheduleTags,
                                color: this.state.eventColor
                            }

                            await Firestore.update(lesson, 'lesson', editId);
                        }

                        if (this.state.sendPushStudent) {

                            this.state.scheduleStudents.forEach((student, key) => {

                                let userDoc = this.state.companyStudents.find(item => item.id === student);

                                if (userDoc && userDoc.notification_token && userDoc.notification_token.data) {

                                    let token = userDoc.notification_token.data;
                                    let notification = {
                                        icon: 'calendar-plus',
                                        color: 'blue',
                                        navigate: 'Home'
                                    };

                                    NotificationHelper.send('Aula Agendada', `Você tem ${query.docs.length} novas aulas agendadas, abra o app e confira.`, notification, token, student);
                                }
                            });
                        }

                        if (this.state.sendPushTeacher) {

                            let teacherDoc = this.state.teacherDocs.find(item => item.id === this.state.scheduleTeacher);

                            if (teacherDoc && teacherDoc.notification_token && teacherDoc.notification_token.data) {

                                let token = teacherDoc.notification_token.data;
                                let notification = {
                                    icon: 'calendar-plus',
                                    color: 'blue',
                                    navigate: 'TeacherHome'
                                };

                                NotificationHelper.send('Aula Agendada', `Você tem ${query.docs.length} novas aulas agendadas, abra o app e confira.`, notification, token, this.state.scheduleTeacher);
                            }
                        }
                    }
                }

                if (this.state.openReplacement) {

                    if (Number.isInteger(parseInt(this.state.openReplacementNumber)) && this.state.openReplacementNumber > 0) {

                        let replacementLessonId = await Firestore.getId('replacement_lesson');

                        let replacementLessonData = {
                            start: data.start,
                            end: data.end,
                            category: this.state.replacementCategory,
                            lesson_type: this.state.replacementType,
                            limit: data.id_students.length + parseInt(this.state.openReplacementNumber),
                            id_students: data.id_students,
                            id_lesson: doc,
                            full: false,
                            id_court: data.id_court,
                            id_teacher: data.id_teacher,
                            id_company: SessionHelper.getData().id_company,
                        };

                        await Firestore.insert(replacementLessonData, 'replacement_lesson', replacementLessonId);
                        await Firestore.update({ id_replacement_lesson: replacementLessonId }, 'lesson', doc);

                        Functions.request('POST', 'replacementLessonNotification', { id_company: replacementLessonData.id_company, category: replacementLessonData.category, start: replacementLessonData.start, lesson_type: replacementLessonData.lesson_type });

                    } else {

                        throw new Error('integer error');
                    }
                }

                toast.success('Aula agendada com sucesso');
                this.setState({ loadingModal: false, scheduleStart: null, scheduleEnd: null, editId: null, scheduleTeacher: null, sendEmailStudent: false, sendEmailTeacher: false, scheduleModal: false, editId: null, replacementCategory: [], openReplacement: false, openReplacementNumber: '', repeat: false, repeat_date: null, updateOnFutureLessons: false, id_parent_event: false, eventColor: false });

                this.filterEvents();

            } catch (error) {
                this.setState({ loadingModal: false });
                toast.error('Erro ao agendar aula');
            }

        } else {

            toast.warn('Preencha todos os campos');
        }
    }

    async scheduleCourtRent() {
        if (this.state.loadingModal == true) {
            toast.warn('Aguarde, realizando ação...');
            return;
        }

        if (this.state.hasConflict == true) {
            toast.warn('Este horário já tem um agendamento, verifique os horários disponíveis.');
            return;
        }

        if (this.state.scheduleStart && this.state.scheduleEnd && this.state.scheduleCourt
            &&
            ((this.state.internalSchedule === 'external' && this.state.externalUsers && this.state.externalUsers.length > 0)
                || (this.state.internalSchedule === 'internal' && this.state.scheduleStudents && this.state.scheduleStudents.length > 0))) {

            try {

                this.setState({ loadingModal: true });

                let courtRent = {
                    amount_of_hours: 1,
                    amount_paid: 0,
                    created_at: new Date(),
                    start: this.state.scheduleStart,
                    end: this.state.scheduleEnd,
                    id_company: SessionHelper.getData().id_company,
                    id_court: this.state.scheduleCourt,
                    id_user: null,
                    id_order: null,
                    id_package: null,
                    id_admin: SessionHelper.getFirebaseAuth().uid,
                    playerCount: parseInt(this.state.internalSchedule === 'internal' ? this.state.scheduleStudents.length : this.state.externalUsers.length),
                    color: this.state.eventColor,
                    rated: true,
                    id_students: this.state.internalSchedule === 'internal' ? this.state.scheduleStudents && this.state.scheduleStudents.length > 0 ? this.state.scheduleStudents : null : null,
                    manual_info: this.state.externalUsers
                }

                let doc = null;

                if (this.state.editId) {

                    doc = this.state.editId;

                    let oldCourtRentQuery = await Firestore.getDoc('court_rent', doc);
                    let oldCourtRent = oldCourtRentQuery.data();

                    await Firestore.update(courtRent, 'court_rent', doc);

                    this.notifyUpdatedCourtRent(oldCourtRent, courtRent, doc);

                } else {

                    doc = await Firestore.getId('court_rent');

                    if (this.state.repeat) {
                        courtRent.id_parent_event = doc;
                    }

                    await Firestore.insert(courtRent, 'court_rent', doc);

                    if (this.state.repeat) {

                        let repeatDate = moment(this.state.repeat_date).endOf('day');

                        if (repeatDate.isValid()) {

                            let start = moment(courtRent.start).startOf('day');
                            let repeat_number = parseInt(repeatDate.diff(start, 'weeks', false));

                            let repeat_until = moment(courtRent.start).add(repeat_number, 'weeks');

                            if (Number.isInteger(parseInt(repeat_number)) && repeat_number > 0) {

                                let referenceStart = courtRent.start;
                                let referenceEnd = courtRent.end;

                                for (let index = 0; index < repeat_number; index++) {

                                    referenceStart = moment(referenceStart).add(1, 'week').toDate();
                                    referenceEnd = moment(referenceEnd).add(1, 'week').toDate();

                                    let courtRent = {
                                        amount_of_hours: 1,
                                        amount_paid: 0,
                                        created_at: new Date(),
                                        start: referenceStart,
                                        end: referenceEnd,
                                        id_company: SessionHelper.getData().id_company,
                                        id_court: this.state.scheduleCourt,
                                        id_user: null,
                                        id_order: null,
                                        id_package: null,
                                        id_admin: SessionHelper.getFirebaseAuth().uid,
                                        playerCount: parseInt(this.state.internalSchedule === 'internal' ? this.state.scheduleStudents.length : this.state.externalUsers.length),
                                        rated: false,
                                        id_parent_event: doc,
                                        color: this.state.eventColor,
                                        repeat_until: repeat_until.toDate(),
                                        id_students: this.state.internalSchedule === 'internal' ? this.state.scheduleStudents && this.state.scheduleStudents.length > 0 ? this.state.scheduleStudents : null : null,
                                        manual_info: this.state.externalUsers
                                    }

                                    await Firestore.insert(courtRent, 'court_rent');
                                }

                                if (this.state.scheduleStudents && this.state.internalSchedule === 'internal') {
                                    this.state.scheduleStudents.forEach(id_user => {
                                        let userDoc = this.state.companyStudents.find(item => item.id === id_user);

                                        if (userDoc && userDoc.notification_token && userDoc.notification_token.data) {

                                            let token = userDoc.notification_token.data;
                                            let notification = {
                                                icon: 'calendar-plus',
                                                color: 'blue',
                                                navigate: 'Home'
                                            };

                                            NotificationHelper.send('Aluguel Agendado', `Você tem ${repeat_number} novos aluguéis agendados, abra o app e confira.`, notification, token, id_user);
                                        }
                                    });
                                }

                            } else {

                                toast.warn('A data de repetição informada é inválida', { autoClose: false });
                            }

                        } else {

                            toast.warn('A data de repetição informada é inválida', { autoClose: false });
                        }
                    }
                }

                if (this.state.id_parent_event && this.state.updateOnFutureLessons) {

                    let referenceStart = courtRent.start;
                    let referenceEnd = courtRent.end;

                    let query = await Firestore.customQuery('court_rent').where('id_parent_event', '==', this.state.id_parent_event).where('start', '>=', moment(referenceStart).toDate()).orderBy('start', 'desc').get();

                    if (query.docs.length > 0) {
                        for (let i = 0; i < query.docs.length; i++) {
                            const editId = query.docs[i].id;

                            if (i > 0) {
                                referenceStart = moment(referenceStart).add(1, 'week').toDate();
                                referenceEnd = moment(referenceEnd).add(1, 'week').toDate();
                            }

                            let courtRent = {
                                start: referenceStart,
                                end: referenceEnd,
                                id_students: this.state.scheduleStudents,
                                id_teacher: this.state.scheduleTeacher,
                                id_court: this.state.scheduleCourt,
                                color: this.state.eventColor,
                                tags: this.state.scheduleTags,
                            }

                            await Firestore.update(courtRent, 'court_rent', editId);
                        }

                        let userDoc = this.state.companyStudents.find(item => item.id === courtRent.id_user);

                        if (userDoc && userDoc.notification_token && userDoc.notification_token.data) {

                            let token = userDoc.notification_token.data;
                            let notification = {
                                icon: 'calendar-plus',
                                color: 'blue',
                                navigate: 'Home'
                            };

                            NotificationHelper.send('Aluguél Agendado', `Você tem ${query.docs.length} novos aluguéis agendados, abra o app e confira.`, notification, token, userDoc.id);
                        }
                    }
                }

                toast.success('Aluguel agendadado com sucesso');
                this.setState({ loadingModal: false, courtRentModal: false, scheduleCourt: false, scheduleStudents: [], externalUsers: [], editIndex: -1, name: '', phone: '', scheduleStart: null, scheduleEnd: null, updateOnFutureLessons: false, eventColor: false });

                this.filterEvents();
            } catch (error) {
                this.setState({ loadingModal: false });
                toast.error('Erro ao agendar aluguel');
            }

        } else {
            toast.warn('Preencha todos os campos');
        }
    }

    async addManualReplacementLesson() {
        if (this.state.loadingModal == true) {
            toast.warn('Aguarde, realizando ação...');
            return;
        }

        if (this.state.replacementStart && this.state.replacementEnd && this.state.replacementCourt && this.state.replacementTeacher && this.state.replacementStudents.length > 0) {

            this.setState({ loadingModal: true });
            let data = {
                start: this.state.replacementStart,
                end: this.state.replacementEnd,
                id_students: this.state.replacementStudents,
                replacement_lesson_students: this.state.replacementStudents,
                accepted: this.state.replacementStudents,
                id_teacher: this.state.replacementTeacher,
                id_court: this.state.replacementCourt,
                id_company: SessionHelper.getData().id_company,
                categories: this.state.replacementCategory,
                tags: this.state.scheduleTags,
                created_at: new Date(),
                color: this.state.eventColor
            };

            let lesson = await Firestore.getId('lesson');

            await Firestore.insert(data, 'lesson', lesson);

            for (let id_user of this.state.replacementStudents) {
                let user = this.state.companyStudents.find(user => user.id === id_user);

                if (user) {
                    let companyData = user.companies[SessionHelper.getData().id_company];
                    await this.removeUserReplacementLesson(id_user, companyData.replacement_lesson, true, lesson);

                    if (user.notification_token && user.notification_token.data) {
                        let token = user.notification_token.data;
                        let notification = {
                            icon: 'sync',
                            color: 'green',
                            navigate: 'Lesson',
                            lesson_id: lesson,
                            start: data.start,
                        };

                        NotificationHelper.send('Aula de Reposição Agendada', `Uma aula de reposição foi agendada para você para ${moment(data.start).format('DD/MM/YYYY HH:mm')} e um crédito de reposição foi removido, abra o app e confira.`, notification, token, id_user);
                    }
                }
            }

            this.setState({
                loadingModal: false,
                addManualReplacement: false,
                replacementStart: null,
                replacementEnd: null,
                replacementCourt: '',
                replacementTeacher: '',
                replacementLimit: '',
                replacementStudents: []
            });

            this.filterEvents();
            toast.success('Aula agendada com sucesso, evento já se encontra na agenda.');
        } else {
            toast.warn('Preencha todos os campos');
        }
    }

    async addReplacementLesson() {
        if (this.state.loadingModal == true) {
            toast.warn('Aguarde, realizando ação...');
            return;
        }

        if (this.state.replacementStart && this.state.replacementEnd && this.state.replacementCourt && parseInt(this.state.replacementLimit) > 0) {

            try {

                this.setState({ loadingModal: true });

                let data = {
                    start: this.state.replacementStart,
                    end: this.state.replacementEnd,
                    category: this.state.replacementCategory,
                    lesson_type: this.state.replacementType,
                    limit: parseInt(this.state.replacementLimit),
                    id_students: [],
                    id_lesson: null,
                    full: false,
                    id_court: this.state.replacementCourt,
                    id_teacher: this.state.replacementTeacher,
                    id_company: SessionHelper.getData().id_company,
                };

                await Firestore.insert(data, 'replacement_lesson');
                await this.getReplacementLessons();

                Functions.request('POST', 'replacementLessonNotification', { id_company: data.id_company, category: data.category, start: data.start, lesson_type: data.lesson_type });

                this.setState({
                    loadingModal: false,
                    addReplacement: false,
                    replacementStart: null,
                    replacementEnd: null,
                    replacementCourt: '',
                    replacementTeacher: '',
                    replacementLimit: '',
                    replacementStudents: []
                });
                toast.success('Horário aberto com sucesso');

            } catch (error) {

                this.setState({ loadingModal: false });
                toast.error('Houve um erro ao abrir a vaga');
            }

        } else {

            toast.warn('Preencha todos os campos');
        }
    }

    removeReplacementLesson = async (id) => {
        try {
            this.setState({ loadingModal: true });

            await Firestore.delete('replacement_lesson', id);
            await this.getReplacementLessons();

            this.setState({ loadingModal: false });
            toast.success('Horário removido com sucesso');

        } catch (error) {

            this.setState({ loadingModal: false });
            toast.error('Houve um erro ao remover a vaga');
        }
    }

    async deleteRenewalEvent(id) {
        try {
            this.setState({ loadingModal: true });

            await Firestore.delete('active_renewals', id);
            await this.getActiveRenewals();

            this.setState({ loadingModal: false });
            toast.success('Aviso ignorado com sucesso');
        } catch (error) {
            this.setState({ loadingModal: false });
            toast.error('Houve um erro ao ignorar o aviso');
        }
    }

    getStudentCategory(studentCategory) {

        let result = 'Sem Categoria';
        let category = this.state.paramDoc.categories.find(item => item.value == studentCategory);

        if (category) {

            result = category.label;
        }

        return result;
    }

    addUserReplacementLesson = async (idUser, replacementLessons, fromLesson) => {

        try {

            if (this.state.loadingModal == true) {
                toast.warn('Aguarde, realizando ação...');
                return;
            }

            this.setState({ loadingModal: true });

            let userQuery = await Firestore.getDoc('user', idUser);
            let userData = { ...userQuery.data(), id: userQuery.id };

            replacementLessons++;

            if (userData.companies[SessionHelper.getData().id_company]) {

                userData.companies[SessionHelper.getData().id_company].replacement_lesson = replacementLessons;
            }

            Firestore.update({ companies: userData.companies }, 'user', idUser);

            if (userData.notification_token && userData.notification_token.data) {

                let token = userData.notification_token.data;
                let notification = {
                    icon: 'plus',
                    color: 'green',
                    navigate: 'ReplacementLesson'
                };

                NotificationHelper.send('Aula de Reposição', `Você ganhou uma aula de reposição em ${SessionHelper.getData().company.name}`, notification, token, idUser);
            }

            if (fromLesson) {
                await ReplacementLessonLogHelper.insert(true, idUser, SessionHelper.getFirebaseAuth().uid, true);
            } else {
                await ReplacementLessonLogHelper.insert(true, idUser, SessionHelper.getFirebaseAuth().uid, false);
            }

            await this.getStudents();
            this.setState({ loadingModal: false });

            if (!fromLesson) toast.success('Aula de reposição adicionada com sucesso');

        } catch (error) {

            this.setState({ loadingModal: false });
            toast.error('Houve um problema ao adicionar a aula');
        }
    }

    conflictCallback = (hasConflict) => {
        this.setState({ hasConflict });
    }
    async removeUserReplacementLesson(idUser, replacementLessons, fromManualLesson, id_lesson) {
        if (this.state.loadingModal == true && !fromManualLesson) {
            toast.warn('Aguarde, realizando ação...');
            return;
        }

        if (replacementLessons > 0) {

            try {

                this.setState({ loadingModal: true });

                let userQuery = await Firestore.getDoc('user', idUser);
                let userData = { ...userQuery.data(), id: userQuery.id };

                replacementLessons--;

                if (userData.companies[SessionHelper.getData().id_company]) {

                    userData.companies[SessionHelper.getData().id_company].replacement_lesson = replacementLessons;
                }

                await Firestore.update({ companies: userData.companies }, 'user', idUser);
                await ReplacementLessonLogHelper.insert(false, idUser, SessionHelper.getFirebaseAuth().uid, false, fromManualLesson, id_lesson);

                await this.getStudents();
                this.setState({ loadingModal: false });

                if (!fromManualLesson) toast.success('Aula de reposição removida com sucesso');

            } catch (error) {

                this.setState({ loadingModal: false });
                toast.error('Houve um problema ao remover a aula');
            }

        } else {

            toast.warn('Esse aluno já possuí zero aulas');
        }
    }

    handleReplacementHistory(key, value) {
        let users = this.state.companyStudents;

        let index = users.findIndex(item => item.showHistory === true);
        if (index > -1) { users[index].showHistory = false };

        users[key].showHistory = value;

        this.setState({ companyStudent: users })
    }

    async renewEvent() {
        this.setState({ loadingModal: true });

        try {
            let event = this.state.repetitionEventToRenew;

            if (!this.state.repeat_date) return toast.warn(`Preencha a data final para as novas repetições`);

            if (event && event.id) {
                let collection = !event.type ? `lesson` : event.type;
                let query = await Firestore.getDoc(collection, event.id);

                if (query.exists) {
                    let data = query.data();

                    let repeatDate = moment(this.state.repeat_date).endOf('day');

                    if (repeatDate.isValid()) {

                        let start = moment(data.start.toDate()).startOf('day');
                        let repeat_number = parseInt(repeatDate.diff(start, 'weeks', false));

                        let repeat_until = moment(data.start.toDate()).add(repeat_number, 'weeks');

                        if (Number.isInteger(parseInt(repeat_number)) && repeat_number > 0) {

                            let referenceStart = data.start.toDate();
                            let referenceEnd = data.end.toDate();

                            for (let index = 0; index < repeat_number; index++) {

                                referenceStart = moment(referenceStart).add(1, 'week').toDate();
                                referenceEnd = moment(referenceEnd).add(1, 'week').toDate();

                                let newRepetition = {};
                                Object.assign(newRepetition, data);

                                newRepetition.start = referenceStart;
                                newRepetition.end = referenceEnd;
                                newRepetition.repeat_until = repeat_until.toDate();
                                newRepetition.created_at = new Date();
                                newRepetition.renewed_until = null;

                                if (event.type === 'court_rent' && newRepetition.id_user !== null) {
                                    newRepetition.id_students = [newRepetition.id_user];
                                    newRepetition.id_user = null;
                                }

                                if (newRepetition.accepted) newRepetition.accepted = [];
                                if (newRepetition.refused) newRepetition.refused = [];
                                if (newRepetition.replacement_lesson_students) {
                                    if (newRepetition.replacement_lesson_students && newRepetition.replacement_lesson_students.length > 0) {
                                        if (newRepetition.id_students && newRepetition.id_students.length > 0) {
                                            newRepetition.replacement_lesson_students.forEach(replacement_id => {
                                                if (newRepetition.id_students.includes(replacement_id)) {
                                                    let replacement_index = newRepetition.id_students.indexOf(replacement_id);
                                                    if (replacement_index > -1) newRepetition.id_students.splice(replacement_index, 1);
                                                }
                                            });
                                        }
                                    }

                                    newRepetition.replacement_lesson_students = [];
                                }

                                await Firestore.insert(newRepetition, collection);
                            }
                        }

                        await Firestore.update({ renewed_until: repeat_until.toDate() }, collection, query.id);
                    }

                    let notification = await Firestore.customQuery('active_renewals').where('id_event', '==', query.id).get();

                    if (notification.size) {
                        for (let doc of notification.docs) {
                            await Firestore.delete('active_renewals', doc.id);
                        }
                    }

                    toast.success(`Evento renovado com sucesso!`);
                }
            } else {
                throw new Error();
            }
        } catch (e) {
            console.log(e)
            toast.error(`Houve um erro ao renovar o evento`);
        }

        await this.getActiveRenewals();
        await this.filterEvents(this);
        this.setState({ renewRepetitionModal: false, repetitionEventToRenew: {}, repeat_date: null, loadingModal: false });
    }

    renewRepetitionModal() {
        if (this.state.renewRepetitionModal && this.state.repetitionEventToRenew && this.state.repetitionEventToRenew.start) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <div style={{ color: Colors.contrast, display: 'flex', flexDirection: 'row', fontWeight: 'bold', marginBottom: 20 }}>
                        <InfoRoundedIcon style={{ fontSize: 28, marginRight: 8 }} />
                        <div>
                            Esse evento foi originalmente repetido até: {moment(this.state.repetitionEventToRenew.start).format('DD/MM/YYYY')}
                        </div>
                    </div>

                    <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            style={{ width: '100%', paddingLeft: 5, marginBottom: 15 }}
                            invalidDateMessage={false}
                            format={'dd/MM/yyyy'}
                            autoOk={true}
                            label="Repetir Até"
                            cancelLabel={'Cancelar'}
                            minDate={moment().isSameOrAfter(moment(this.state.repetitionEventToRenew.start)) ? moment() : moment(this.state.repetitionEventToRenew.start)}
                            okLabel={'Confirmar'}
                            onChange={(v) => { this.setState({ repeat_date: v }) }}
                            value={this.state.repeat_date}
                        />
                    </MuiPickersUtilsProvider>

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50, width: `100%` }}>
                        <DefaultButton onClick={() => { this.setState({ renewRepetitionModal: false, repetitionEventToRenew: {}, repeat_date: null }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                        <DefaultButton onClick={() => { this.renewEvent() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                    </div>
                </div>
            )
        }
    }

    renewalModal() {
        if (this.state.renewalModal) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <h2>{'Repetições que precisam de atenção'}</h2>
                    <div>
                        {!this.state.activeRenewalsDocs.length ? <p style={{ color: 'grey' }}>{'Nenhuma repetição precisa de renovação essa semana até agora.'}</p> : null}
                        <div style={{ marginBottom: 25, maxHeight: 400, overflowY: 'scroll' }}>
                            {
                                this.state.activeRenewalsDocs.map((event, key) => {

                                    if (event.id_event) {
                                        return (
                                            <div style={{ borderBottom: '1px solid lightgrey', paddingTop: 20, paddingBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: `space-between` }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 25 }}>
                                                    <div style={{ marginLeft: 15 }}>
                                                        <b>{`Repetição de ${event.type == `lesson` ? `Aula` : `Aluguel`} de toda ${moment(event.event_date.toDate()).format('dddd [às] HH:mm')}`}</b>
                                                        <p style={{ color: Colors.disabled, marginTop: 0 }}>Repetição expirou em {moment(event.date.toDate()).format('DD/MM/YYYY HH:mm')}</p>
                                                    </div>
                                                </div>
                                                <div style={{ width: 250, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: `center` }}>
                                                    <Tooltip title={'Ignorar'} style={{ alignSelf: 'flex-start', marginRight: 15 }}>
                                                        <IconButton onClick={() => { this.deleteRenewalEvent(event.id) }}>
                                                            <DeleteIcon style={{ color: 'red' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={'Agendar próximas repetições'} style={{ alignSelf: 'flex-start' }}>
                                                        <IconButton onClick={() => {
                                                            let repetitionEventToRenew = this.state.events.find(calendarEvt => calendarEvt.id === event.id_event);

                                                            if (repetitionEventToRenew && repetitionEventToRenew.id) {
                                                                this.setState({ repetitionEventToRenew, renewRepetitionModal: true })
                                                            }
                                                        }}>
                                                            <RepeatIcon style={{ color: Colors.success }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                                <CalendarEvent
                                                    readOnly={true}
                                                    showData={true}
                                                    order={false}
                                                    style={{ borderRadius: '3px', opacity: 0.8, color: 'white', border: '1px', padding: 8, display: 'block', backgroundColor: event.type === 'lesson' ? '#36A2EB' : '#3b9c3e', width: 200, marginRight: 25 }}
                                                    event={{
                                                        eventDoc: event.event,
                                                        id: event.id_event,
                                                        start: event.event.start.toDate(),
                                                        end: event.event.end.toDate(),
                                                        allDay: false,
                                                        type: event.type === 'lesson' ? '' : event.type,
                                                        id_student: event.event.id_students ? event.event.id_students : event.event.id_user ? [event.event.id_user] : null,
                                                        id_teacher: event.event.id_teacher ? event.event.id_teacher : null,
                                                        id_court: event.event.id_court
                                                    }}
                                                />
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            );
        }
    }

    getReplacementStudentsDocs() {
        let docs = [];

        this.state.companyStudents.sort((a, b) => a.name - b.name).map((student, key) => {
            let companyData = student.companies[SessionHelper.getData().id_company];

            if (companyData && companyData.replacement_lesson > 0) {
                student.replacement_lesson = `${companyData.replacement_lesson} Reposições`;
                docs.push(student);
            }
        });

        return docs;
    }

    replacementModal() {
        if (this.state.replacementModal) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%', marginRight: 25 }}>
                            <h2>{'Aulas de Reposição'}</h2>
                            <SearchBar docs={this.state.companyStudents} search={'name'} onEmpty={(docs) => { this.setState({ companyStudents: docs }) }} onSearch={(result) => { this.setState({ companyStudents: result }) }} placeholder={'Busque por alunos...'} />
                            <div style={{ height: 500, overflowY: 'scroll' }}>
                                {this.state.replacementLessonsTotal === 0 ? <p style={{ color: 'grey' }}>{'Nenhuma aula de reposição encontrada'}</p> : null}
                                {
                                    this.state.companyStudents.sort((a, b) => a.name - b.name).map((student, key) => {

                                        let companyData = student.companies[SessionHelper.getData().id_company];

                                        if (companyData && companyData.replacement_lesson > 0) {
                                            let category = companyData.category ? this.state.paramDoc.categories.find(item => item.value == companyData.category) : null;

                                            return (
                                                <div style={{ display: 'flex', flexDirection: 'column', borderBottom: '1px solid lightgrey', paddingBottom: 20 }}>
                                                    <div style={{ paddingTop: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                        <img style={{ height: 60, width: 60, borderRadius: 60 / 2, boxShadow: 'rgba(50, 50, 50, 0.47) 1px 1px 10px 0px' }} src={student.photo ? student.photo : process.env.PUBLIC_URL + '/empty_avatar.png'} />
                                                        <div style={{ marginLeft: 15 }}>
                                                            <b>{student.name}</b>
                                                            {companyData.category ? <div style={{ backgroundColor: category.color || Colors.primary, minWidth: 125, borderRadius: 5, textAlign: 'center', color: 'white', fontWeight: 'bold', marginTop: 5 }}>{category.label}</div> : null}
                                                            {companyData.lesson_type ? <div style={{ backgroundColor: UserHelper.getLessonType()[companyData.lesson_type].color, minWidth: 125, borderRadius: 5, textAlign: 'center', color: 'white', fontWeight: 'bold', marginTop: 5 }}>{UserHelper.getLessonType()[companyData.lesson_type].label}</div> : null}
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 'auto', marginRight: 25 }}>
                                                            <Tooltip onClick={() => { this.removeUserReplacementLesson(student.id, companyData.replacement_lesson) }} title={'Remover Reposição'}>
                                                                <IconButton style={{ boxShadow: Colors.boxShadow, border: '0.3px solid lightgrey', color: Colors.primary }}>
                                                                    <RemoveIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <div style={{ fontSize: 20, marginLeft: 10, marginRight: 10 }}>{companyData.replacement_lesson}</div>
                                                            <Tooltip title={'Adicionar Reposição'}>
                                                                <IconButton onClick={() => { this.addUserReplacementLesson(student.id, companyData.replacement_lesson) }} style={{ boxShadow: Colors.boxShadow, border: '0.3px solid lightgrey', color: Colors.primary }}>
                                                                    <AddIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title={'Visualizar Histórico'}>
                                                                <IconButton onClick={() => { this.handleReplacementHistory(key, student.showHistory ? false : true) }} style={{ boxShadow: Colors.boxShadow, border: '0.3px solid lightgrey', color: student.showHistory ? 'white' : Colors.primary, backgroundColor: student.showHistory ? Colors.primary : 'white', marginLeft: 40 }}>
                                                                    <LoopIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    {student.showHistory ? <h2>Histórico</h2> : null}
                                                    {student.showHistory ? <StudentReplacementHistory ref={ref => this.studentReplacementHistory = ref} id={student.id} /> : null}
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%', marginLeft: 25 }}>
                            <h2>{'Vagas de Horário'}</h2>
                            <div>
                                {!this.state.addReplacement && !this.state.addManualReplacement && !this.state.replacementLessonsDocs.length ? <p style={{ color: 'grey' }}>{'Nenhum horário vago aberto'}</p> : null}
                                {!this.state.addReplacement && !this.state.addManualReplacement && <div style={{ marginBottom: 25, maxHeight: 450, overflowY: 'scroll' }}>
                                    {
                                        this.state.replacementLessonsDocs.map((replacementLesson, key) => {
                                            if (!replacementLesson.lesson_type) replacementLesson.lesson_type = 'group';

                                            if (!replacementLesson.full) {
                                                return (
                                                    <div style={{ borderBottom: '1px solid lightgrey', paddingTop: 20, paddingBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                        <TodayIcon style={{ border: `3px solid ${Colors.primary}`, height: 60, width: 60, padding: 5, borderRadius: 60 / 2, color: Colors.primary, boxShadow: 'rgba(50, 50, 50, 0.47) 1px 1px 10px 0px' }} />
                                                        <div style={{ marginLeft: 15 }}>
                                                            <b>{`De ${moment(replacementLesson.start.toDate()).format('DD/MM/YYYY HH:mm')} até ${moment(replacementLesson.end.toDate()).format('DD/MM/YYYY HH:mm')}`}</b>

                                                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', maxWidth: 260 }}>{replacementLesson.category.map((cat, key) => {
                                                                let category = cat ? this.state.paramDoc.categories.find(item => item.value == cat) : null;
                                                                return <div style={{ backgroundColor: category.color || Colors.primary, minWidth: 125, borderRadius: 5, textAlign: 'center', color: 'white', fontWeight: 'bold', marginTop: 5, marginLeft: key > 0 ? 5 : 0 }}>{category.label}</div>
                                                            })}</div>

                                                            <div style={{ backgroundColor: UserHelper.getLessonType()[replacementLesson.lesson_type].color, maxWidth: 125, borderRadius: 5, textAlign: 'center', color: 'white', fontWeight: 'bold', marginTop: 5 }}>{UserHelper.getLessonType()[replacementLesson.lesson_type].label}</div>
                                                            <div style={{ color: 'green', marginTop: 8 }}>Vagas Restantes: <strong>{replacementLesson.limit - replacementLesson.id_students.length}</strong> {replacementLesson.limit - replacementLesson.id_students.length === 1 ? 'vaga' : 'vagas'} de <strong>{replacementLesson.limit}</strong> no total</div>
                                                            {replacementLesson.automated ? <div style={{ marginTop: 5, backgroundColor: '#ffae42', borderRadius: 5, color: '#fff', padding: 3, textAlign: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                                <FlashOnIcon style={{ fontSize: 20 }} />
                                                                <strong>Gerada Automáticamente</strong>
                                                            </div> : null}
                                                        </div>

                                                        <Tooltip title={'Remover Horário'} style={{ alignSelf: 'flex-start', marginLeft: 'auto' }}>
                                                            <IconButton onClick={() => { this.removeReplacementLesson(replacementLesson.id) }}>
                                                                <DeleteIcon style={{ color: 'red' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                                }


                                {!this.state.addReplacement && !this.state.addManualReplacement &&
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                        <DefaultButton leftIcon={<AddIcon style={{ marginRight: 10 }} />} title={'ABRIR NOVO HORÁRIO'} onClick={() => { this.setState({ addReplacement: true }) }} />
                                        <DefaultButton leftIcon={<PersonAdd style={{ marginRight: 10 }} />} title={'REPOSIÇÃO MANUAL'} onClick={() => { this.setState({ addManualReplacement: true }) }} />
                                    </div>
                                }

                                {this.state.addManualReplacement &&
                                    <div style={{ height: 550, overflowY: 'scroll' }}>
                                        <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10 }}>
                                            <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                                <KeyboardDateTimePicker
                                                    style={{ width: '100%', marginTop: 15 }}
                                                    invalidDateMessage={false}
                                                    format={'dd/MM/yyyy HH:mm'}
                                                    autoOk={true}
                                                    label="Início"
                                                    cancelLabel={'Cancelar'}
                                                    okLabel={'Confirmar'}
                                                    onChange={(v) => { this.setState({ replacementStart: v, replacementEnd: moment(v).add(1, 'hour').toDate() }) }}
                                                    minDate={new Date()}
                                                    value={this.state.replacementStart || null}
                                                />
                                                <div style={{ marginRight: 5, marginLeft: 5 }} />
                                                <KeyboardDateTimePicker
                                                    style={{ width: '100%', marginTop: 15 }}
                                                    invalidDateMessage={false}
                                                    format={'dd/MM/yyyy HH:mm'}
                                                    autoOk={true}
                                                    label="Fim"
                                                    cancelLabel={'Cancelar'}
                                                    okLabel={'Confirmar'}
                                                    onChange={(v) => { this.setState({ replacementEnd: v }) }}
                                                    minDate={new Date()}
                                                    value={this.state.replacementEnd || null}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>

                                        <div style={{ marginBottom: 15, marginTop: 25 }}>
                                            <InputLabel id="type">Categoria</InputLabel>
                                            <Select
                                                style={{ width: '100%' }}
                                                labelId="tipo"
                                                multiple={true}
                                                value={this.state.replacementCategory}
                                                onChange={(v) => { this.setState({ replacementCategory: v.target.value }) }}>
                                                {

                                                    this.state.paramDoc && this.state.paramDoc.categories ? this.state.paramDoc.categories.map((category, key) => {
                                                        return <MenuItem value={category.value}>{category.label}</MenuItem>
                                                    }) : null

                                                }

                                            </Select>
                                        </div>

                                        <div style={{ marginBottom: 15, marginTop: 25 }}>
                                            <InputLabel id="type">Tipo de Reposição</InputLabel>
                                            <Select
                                                style={{ width: '100%' }}
                                                labelId="tipo"
                                                value={this.state.replacementType}
                                                onChange={(v) => { this.setState({ replacementType: v.target.value }) }}>
                                                {
                                                    Object.keys(UserHelper.getLessonTypeLookup()).map((key) => {
                                                        return <MenuItem value={key}>{UserHelper.getLessonTypeLookup()[key]}</MenuItem>
                                                    })
                                                }

                                            </Select>
                                        </div>
                                        <div style={{ paddingTop: 25 }}>
                                            <DefaultSelect search={true} searchField={'name'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ replacementTeacher: v.target.value }) }} value={this.state.replacementTeacher} docs={this.state.teacherDocs} label={'Selecionar Professor'} />
                                        </div>
                                        <div style={{ paddingTop: 25 }}>
                                            <DefaultSelect search={true} searchField={'name'} valueField={'id'} secondaryDisplay={'sport_label'} displayField={'name'} onChange={(v) => { this.setState({ replacementCourt: v.target.value }) }} docs={this.state.courtDocs} label={'Quadra'} value={this.state.replacementCourt} />
                                        </div>
                                        <div style={{ paddingTop: 25 }}>
                                            <DefaultSelect useCustomRender search={true} multiple={true} secondaryDisplay={'replacement_lesson'} secondaryComponent={'labelComponent'} searchField={'name'} id={'replacement_student_selection'} valueField={'id'} displayField={'name'} value={this.state.replacementStudents} onChange={(v) => { this.setState({ replacementStudents: v.target.value }) }} docs={this.getReplacementStudentsDocs()} label={'Alunos'} />
                                        </div>

                                        <AgendaConflict start={this.state.replacementStart} end={this.state.replacementEnd} court={this.state.replacementCourt} teacher={this.state.replacementTeacher} teachers={this.state.teacherDocs} courts={this.state.courtDocs} editId={this.state.editId || ''} editType={'lesson'} conflictCallback={this.conflictCallback} />

                                        <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingTop: 10, width: '100%' }}>
                                            <DefaultButton leftIcon={<CheckIcon style={{ marginRight: 10 }} />} title={'Agendar aula'} onClick={() => { this.addManualReplacementLesson() }} />
                                            <div style={{ margin: 5 }} />
                                            <DefaultButton leftIcon={<DeleteIcon style={{ marginRight: 10 }} />} color={Colors.error} title={'Cancelar'} onClick={() => { this.setState({ addManualReplacement: false, scheduleStudents: [] }) }} />
                                        </div>
                                    </div>
                                }

                                {this.state.addReplacement &&
                                    <div style={{ height: 550, overflowY: 'scroll' }}>
                                        <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10 }}>
                                            <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                                <KeyboardDateTimePicker
                                                    style={{ width: '100%', marginTop: 15 }}
                                                    invalidDateMessage={false}
                                                    format={'dd/MM/yyyy HH:mm'}
                                                    autoOk={true}
                                                    label="Início"
                                                    cancelLabel={'Cancelar'}
                                                    okLabel={'Confirmar'}
                                                    onChange={(v) => { this.setState({ replacementStart: v, replacementEnd: moment(v).add(1, 'hour').toDate() }) }}
                                                    minDate={new Date()}
                                                    value={this.state.replacementStart || null}
                                                />
                                                <div style={{ marginRight: 5, marginLeft: 5 }} />
                                                <KeyboardDateTimePicker
                                                    style={{ width: '100%', marginTop: 15 }}
                                                    invalidDateMessage={false}
                                                    format={'dd/MM/yyyy HH:mm'}
                                                    autoOk={true}
                                                    label="Fim"
                                                    cancelLabel={'Cancelar'}
                                                    okLabel={'Confirmar'}
                                                    onChange={(v) => { this.setState({ replacementEnd: v }) }}
                                                    minDate={new Date()}
                                                    value={this.state.replacementEnd || null}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>

                                        <div style={{ marginBottom: 15, marginTop: 25 }}>
                                            <InputLabel id="type">Categoria</InputLabel>
                                            <Select
                                                style={{ width: '100%' }}
                                                labelId="tipo"
                                                multiple={true}
                                                value={this.state.replacementCategory}
                                                onChange={(v) => { this.setState({ replacementCategory: v.target.value }) }}>
                                                {

                                                    this.state.paramDoc && this.state.paramDoc.categories ? this.state.paramDoc.categories.map((category, key) => {
                                                        return <MenuItem value={category.value}>{category.label}</MenuItem>
                                                    }) : null

                                                }

                                            </Select>
                                        </div>

                                        <div style={{ marginBottom: 15, marginTop: 25 }}>
                                            <InputLabel id="type">Tipo de Reposição</InputLabel>
                                            <Select
                                                style={{ width: '100%' }}
                                                labelId="tipo"
                                                value={this.state.replacementType}
                                                onChange={(v) => { this.setState({ replacementType: v.target.value }) }}>
                                                {

                                                    Object.keys(UserHelper.getLessonTypeLookup()).map((key) => {
                                                        return <MenuItem value={key}>{UserHelper.getLessonTypeLookup()[key]}</MenuItem>
                                                    })

                                                }

                                            </Select>
                                        </div>
                                        <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} required number onBlur={(v) => { this.setState({ replacementLimit: v }) }} label={'Limite de Alunos'} />
                                        <div style={{ paddingTop: 25 }}>
                                            <DefaultSelect search={true} searchField={'name'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ replacementTeacher: v.target.value }) }} value={this.state.replacementTeacher} docs={this.state.teacherDocs} label={'Selecionar Professor'} />
                                        </div>
                                        <div style={{ paddingTop: 25 }}>
                                            <DefaultSelect search={true} searchField={'name'} valueField={'id'} secondaryDisplay={'sport_label'} displayField={'name'} onChange={(v) => { this.setState({ replacementCourt: v.target.value }) }} docs={this.state.courtDocs} label={'Quadra'} value={this.state.replacementCourt} />
                                        </div>

                                        <AgendaConflict start={this.state.replacementStart} end={this.state.replacementEnd} court={this.state.replacementCourt} teacher={this.state.replacementTeacher} teachers={this.state.teacherDocs} courts={this.state.courtDocs} editId={this.state.editId || ''} editType={'lesson'} conflictCallback={this.conflictCallback} />

                                        <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingTop: 10, width: '100%' }}>
                                            <DefaultButton leftIcon={<CheckIcon style={{ marginRight: 10 }} />} title={'Salvar'} onClick={() => { this.addReplacementLesson() }} />
                                            <div style={{ margin: 5 }} />
                                            <DefaultButton leftIcon={<DeleteIcon style={{ marginRight: 10 }} />} color={Colors.error} title={'Cancelar'} onClick={() => { this.setState({ addReplacement: false }) }} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50, width: '100%' }}>
                        <Button onClick={() => { this.setState({ replacementModal: false }) }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '100%' }} variant={'contained'}>{'FECHAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    scheduleModal() {

        if (this.state.scheduleModal) {

            return (
                <div>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10 }}>
                        <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                            <KeyboardDateTimePicker
                                style={{ width: '100%', paddingRight: 5, marginBottom: 15 }}
                                invalidDateMessage={false}
                                format={'dd/MM/yyyy HH:mm'}
                                autoOk={true}
                                label="Início"
                                cancelLabel={'Cancelar'}
                                okLabel={'Confirmar'}
                                onChange={(v) => { this.setState({ scheduleStart: v, scheduleEnd: moment(v).add(1, 'hour').toDate() }) }}
                                value={this.state.scheduleStart}
                            />
                            <KeyboardDateTimePicker
                                style={{ width: '100%', paddingLeft: 5, marginBottom: 15 }}
                                invalidDateMessage={false}
                                format={'dd/MM/yyyy HH:mm'}
                                autoOk={true}
                                label="Fim"
                                cancelLabel={'Cancelar'}
                                minDate={this.state.scheduleStart}
                                okLabel={'Confirmar'}
                                onChange={(v) => { this.setState({ scheduleEnd: v }) }}
                                value={this.state.scheduleEnd}
                            />
                        </MuiPickersUtilsProvider>
                    </div>

                    <DefaultSelect useCustomRender search={true} multiple={true} secondaryComponent={'labelComponent'} searchField={'name'} id={'student_selection'} valueField={'id'} displayField={'name'} value={this.state.scheduleStudents} onChange={(v) => { this.setState({ scheduleStudents: v.target.value }) }} docs={this.state.companyStudents} label={'Alunos'} />

                    <div style={{ marginTop: 15 }} />
                    <DefaultSelect search={true} searchField={'name'} id={'teacher_selection'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ scheduleTeacher: v.target.value }) }} docs={this.state.teacherDocs} label={'Professor'} value={this.state.scheduleTeacher} />

                    <div style={{ marginTop: 15 }} />
                    <DefaultSelect search={true} searchField={'name'} id={'court_selection'} secondaryDisplay={'sport_label'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ scheduleCourt: v.target.value }) }} docs={this.state.courtDocs} label={'Quadra'} value={this.state.scheduleCourt} />

                    <div style={{ marginTop: 15 }} />
                    <DefaultSelect search={true} searchField={'label'} id={'category_selection'} valueField={'value'} multiple={true} displayField={'label'} onChange={(v) => { this.setState({ scheduleCategories: v.target.value }) }} docs={this.state.paramDoc.categories} label={'Categoria da Aula'} value={this.state.scheduleCategories} />

                    <div style={{ marginTop: 15 }} />
                    <DefaultSelect search={true} searchField={'label'} id={'tag_selection'} valueField={'value'} multiple={true} displayField={'label'} onChange={(v) => { this.setState({ scheduleTags: v.target.value }) }} docs={this.state.paramDoc.tags} label={'Tags'} value={this.state.scheduleTags} />

                    <AgendaConflict start={this.state.scheduleStart} end={this.state.scheduleEnd} court={this.state.scheduleCourt} teacher={this.state.scheduleTeacher} teachers={this.state.teacherDocs} courts={this.state.courtDocs} editId={this.state.editId || ''} editType={'lesson'} conflictCallback={this.conflictCallback} />

                    {this.renderColorPicker()}

                    <div style={{ marginTop: 15 }} />
                    <IosSwitch fullWidth label="Notificar Professor" value="sendPushTeacher" checked={this.state.sendPushTeacher} onChange={(v) => { this.setState({ sendPushTeacher: v }) }} />
                    <IosSwitch fullWidth label="Notificar Aluno" value="sendPushStudent" checked={this.state.sendPushStudent} onChange={(v) => { this.setState({ sendPushStudent: v }) }} />

                    {!this.state.editId ? <IosSwitch fullWidth label="Repetir Aula" value="repeat" checked={this.state.repeat} onChange={(v) => { this.setState({ repeat: v }) }} /> : null}
                    {this.state.repeat ?

                        <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                style={{ width: '100%', paddingLeft: 5, marginBottom: 15 }}
                                invalidDateMessage={false}
                                format={'dd/MM/yyyy'}
                                autoOk={true}
                                label="Repetir Até"
                                cancelLabel={'Cancelar'}
                                minDate={this.state.scheduleEnd ? this.state.scheduleEnd : new Date()}
                                okLabel={'Confirmar'}
                                onChange={(v) => { this.setState({ repeat_date: v }) }}
                                value={this.state.repeat_date}
                            />
                        </MuiPickersUtilsProvider>

                        : null}

                    {this.state.editId && this.state.id_parent_event != null ? <IosSwitch fullWidth label={`Aplicar mudanças nas aulas futuras de ${moment(this.state.scheduleStart).format('dddd HH:mm')}`} checked={this.state.updateOnFutureLessons} onChange={(v) => { this.setState({ updateOnFutureLessons: v }) }} /> : null}

                    {!this.state.hasReplacementLesson ? <IosSwitch fullWidth label="Abrir Vaga de Reposição" value="openReplacement" checked={this.state.openReplacement} onChange={(v) => { this.setState({ openReplacement: v }) }} /> : null}
                    {this.state.openReplacement && <DefaultInput required number onBlur={(v) => { this.setState({ openReplacementNumber: v }) }} label={'Número de Vagas'} />}
                    {this.state.openReplacement &&

                        <div style={{ marginBottom: 15, marginTop: 15 }}>
                            <InputLabel id="type">Categoria</InputLabel>
                            <Select
                                style={{ width: '100%' }}
                                labelId="tipo"
                                multiple={true}
                                value={this.state.replacementCategory}
                                onChange={(v) => { this.setState({ replacementCategory: v.target.value }) }}>

                                {

                                    this.state.paramDoc && this.state.paramDoc.categories ? this.state.paramDoc.categories.map((category, key) => {
                                        return <MenuItem value={category.value}>{category.label}</MenuItem>
                                    }) : null

                                }

                            </Select>
                        </div>

                    }

                    {this.state.openReplacement &&
                        <div style={{ marginBottom: 15, marginTop: 25 }}>
                            <InputLabel id="type">Tipo de Reposição</InputLabel>
                            <Select
                                style={{ width: '100%' }}
                                labelId="tipo"
                                value={this.state.replacementType}
                                onChange={(v) => { this.setState({ replacementType: v.target.value }) }}>
                                {

                                    Object.keys(UserHelper.getLessonTypeLookup()).map((key) => {
                                        return <MenuItem value={key}>{UserHelper.getLessonTypeLookup()[key]}</MenuItem>
                                    })

                                }

                            </Select>
                        </div>
                    }

                    <ActiveLessonReplacements id_lesson={this.state.editId} removeReplacementLesson={this.removeReplacementLesson} paramDoc={this.state.paramDoc} setLessonHasReplacementLesson={this.setLessonHasReplacementLesson} />

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.scheduleLesson() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                        <DefaultButton onClick={() => { this.setState({ scheduleModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    setLessonHasReplacementLesson = (bool) => {
        this.setState({ hasReplacementLesson: bool });
    }

    renderColorPicker() {
        return (
            <div style={{ width: '100%', marginBottom: 20 }}>
                <FormLabel style={{ fontWeight: '800', marginTop: 3, marginTop: 18, marginBottom: 15 }} component="legend">Cor personalizada do evento</FormLabel>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                    <CirclePicker width='100%' color={this.state.eventColor} onChangeComplete={(color) => { this.setState({ eventColor: color.hex }); }} colors={Colors.altPickerColors} />

                    <Tooltip title={`Outras cores`}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', cursor: 'pointer', marginTop: -3 }}>
                            <Palette style={{ width: 32, height: 32, color: Colors.text, marginRight: 10, color: this.state.eventColor ? this.state.eventColor : Colors.dark, border: this.state.eventColor ? `5px solid ${this.state.eventColor}` : ``, borderRadius: 16 }} onClick={() => { this.setState({ showPicker: !this.state.showPicker == true }) }} />
                        </div>
                    </Tooltip>

                    <Tooltip title={`Limpar cor e usar a padrão`}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', cursor: 'pointer', marginTop: -3 }}>
                            <Cancel style={{ width: 32, height: 32, color: Colors.error }} onClick={() => { this.setState({ eventColor: false }) }} />
                        </div>
                    </Tooltip>
                </div>
                {this.state.showPicker ?
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        <ChromePicker width={`50%`} disableAlpha={true} color={this.state.eventColor} onChangeComplete={(color) => { if (color.hex !== this.state.eventColor) this.setState({ eventColor: color.hex }) }} />
                        <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column', width: `40%` }}>
                            <CalendarEvent
                                readOnly={true}
                                showData={false}
                                order={false}
                                hideTooltip={true}
                                style={{ borderRadius: '3px', opacity: 0.8, color: 'white', border: '1px', padding: 8, display: 'block', backgroundColor: this.state.eventColor || Colors.primary, width: `100%`, marginRight: 25 }}
                                event={{
                                    court: `Quadra exemplo 1`,
                                    eventDoc: { data: false, start: moment(), end: moment().add(1, 'hour') },
                                    id: `no_id`,
                                    start: moment(),
                                    end: moment().add(1, 'hour'),
                                    allDay: false,
                                    view: 'day',
                                    type: 'lesson',
                                    showData: false
                                }}
                            />
                            <CalendarEvent
                                readOnly={true}
                                order={false}
                                showData={false}
                                hideTooltip={true}
                                style={{ borderRadius: '3px', opacity: 0.8, color: 'white', border: '1px', padding: 8, display: 'block', backgroundColor: this.state.eventColor || Colors.primary, width: `100%`, marginRight: 25 }}
                                event={{
                                    court: `Quadra exemplo 2`,
                                    eventDoc: { data: false, start: moment(), end: moment().add(1, 'hour') },
                                    id: `no_id`,
                                    start: moment(),
                                    end: moment().add(1, 'hour'),
                                    allDay: false,
                                    view: 'day',
                                    type: 'court_rent',
                                    showData: false
                                }}
                            />
                        </div>

                    </div>
                    : null}
            </div>
        );
    }

    courtRentModal() {

        if (this.state.courtRentModal) {

            return (
                <div>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10 }}>
                        <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                            <KeyboardDateTimePicker
                                style={{ width: '100%', paddingRight: 5, marginBottom: 15 }}
                                invalidDateMessage={false}
                                format={'dd/MM/yyyy HH:mm'}
                                autoOk={true}
                                label="Início"
                                cancelLabel={'Cancelar'}
                                okLabel={'Confirmar'}
                                onChange={(v) => { this.setState({ scheduleStart: v, scheduleEnd: moment(v).add(1, 'hour').toDate() }) }}
                                value={this.state.scheduleStart}
                            />
                            <KeyboardDateTimePicker
                                style={{ width: '100%', paddingLeft: 5, marginBottom: 15 }}
                                invalidDateMessage={false}
                                format={'dd/MM/yyyy HH:mm'}
                                autoOk={true}
                                label="Fim"
                                cancelLabel={'Cancelar'}
                                minDate={this.state.scheduleStart}
                                okLabel={'Confirmar'}
                                onChange={(v) => { this.setState({ scheduleEnd: v }) }}
                                value={this.state.scheduleEnd}
                            />
                        </MuiPickersUtilsProvider>
                    </div>

                    <RadioGroup style={{ display: 'flex', flexDirection: 'row', marginBottom: 25, marginTop: 15 }} aria-label="internalSchedule" name="internalSchedule" value={this.state.internalSchedule} onChange={(evt) => { this.setState({ internalSchedule: evt.target.value, scheduleStudents: [], name: '', phone: '', externalUsers: [], editIndex: -1 }) }}>
                        <FormControlLabel value={'internal'} control={<Radio style={{ color: Colors.primary }} />} label={`${TermHelper.getestablishmentTypeLabel(SessionHelper.getData().company.establishment_type).plural} Internos`} />
                        <FormControlLabel value={'external'} control={<Radio style={{ color: Colors.primary }} />} label={`Usuários Externos`} />
                    </RadioGroup>

                    {this.state.internalSchedule === 'internal' ?
                        <DefaultSelect search={true} multiple={true} searchField={'name'} id={'student_selection'} valueField={'id'} displayField={'name'} value={this.state.scheduleStudents} onChange={(v) => { this.setState({ scheduleStudents: v.target.value }) }} docs={this.state.companyStudents} label={`${TermHelper.getestablishmentTypeLabel(SessionHelper.getData().company.establishment_type).plural} / Mensalistas`} />
                        :
                        <div>
                            {
                                this.state.externalUsers.map((data, key) => {

                                    return (
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10, padding: 15, color: Colors.primary, border: `3px solid ${Colors.primary}`, borderRadius: 8, minWidth: '100%' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div style={{ fontSize: 14, fontWeight: 'bold' }}>{data.name}</div>
                                                <div style={{ fontSize: 12, fontWeight: 'normal' }}>{data.phone || `Sem Telefone`}</div>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <IconButton onClick={() => { this.setState({ editIndex: key, name: data.name || ``, phone: data.phone || ``, addExternalUsers: true }) }} style={{ marginRight: 8 }}>
                                                    <Edit style={{ fontSize: 25, cursor: 'pointer', color: Colors.primary }} />
                                                </IconButton>
                                                <IconButton onClick={(evt) => { this.removeExternalUser(key) }} >
                                                    <DeleteIcon style={{ fontSize: 25, cursor: 'pointer', color: Colors.error }} />
                                                </IconButton>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                !this.state.addExternalUsers ?
                                    <Tooltip title={'Clique em "+" para adicionar nome e telefone de um ou mais participantes, o limite será definido pelo "Número de jogadores"'}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <IconButton onClick={(evt) => { this.setState({ addExternalUsers: true }) }} style={{ backgroundColor: 'white', boxShadow: Colors.boxShadow, border: '1px solid lightgrey' }}>
                                                <AddIcon style={{ color: Colors.primary }} />
                                            </IconButton>
                                            <FormLabel style={{ marginLeft: 10 }}>Informar os dados de um ou vários participantes</FormLabel>
                                        </div>
                                    </Tooltip>
                                    :
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', minWidth: '70%' }}>
                                            <DefaultInput defaultValue={this.state.name} required onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ name: v }) }} label={'Nome'} />
                                            <InputMask defaultValue={this.state.phone} onBlur={(v) => { this.setState({ phone: DataHelper.treatPhone(v) }) }} mask="(99) 9 9999-9999" maskPlaceholder={''}>
                                                <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} minLength={11} label={'Telefone'} />
                                            </InputMask>
                                        </div>

                                        <IconButton onClick={() => { this.addExternalUser(this.state.editIndex) }} style={{ backgroundColor: 'white', boxShadow: Colors.boxShadow, border: '1px solid lightgrey', width: 50, height: 50, marginLeft: 5 }}>
                                            <CheckIcon style={{ color: Colors.success }} />
                                        </IconButton>
                                        <IconButton onClick={() => { this.setState({ addExternalUsers: false, name: '', phone: '', editIndex: -1 }) }} style={{ backgroundColor: 'white', boxShadow: Colors.boxShadow, border: '1px solid lightgrey', width: 50, height: 50, marginLeft: 5 }}>
                                            <Clear style={{ color: Colors.error }} />
                                        </IconButton>
                                    </div>
                            }

                        </div>
                    }

                    <div style={{ marginTop: 15 }} />
                    <DefaultSelect search={true} searchField={'name'} id={'court_selection'} secondaryDisplay={'sport_label'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ scheduleCourt: v.target.value }) }} docs={this.state.courtDocs} label={'Quadra'} value={this.state.scheduleCourt} />

                    <AgendaConflict start={this.state.scheduleStart} end={this.state.scheduleEnd} court={this.state.scheduleCourt} teacher={null} teachers={this.state.teacherDocs} courts={this.state.courtDocs} editId={this.state.editId || ''} editType={'court_rent'} conflictCallback={this.conflictCallback} />

                    {this.renderColorPicker()}

                    {!this.state.editId ? <IosSwitch fullWidth label="Repetir Aluguel" value="repeat" checked={this.state.repeat} onChange={(v) => { this.setState({ repeat: v }) }} /> : null}
                    {this.state.repeat ?

                        <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                style={{ width: '100%', paddingLeft: 5, marginBottom: 15 }}
                                invalidDateMessage={false}
                                format={'dd/MM/yyyy'}
                                autoOk={true}
                                label="Repetir Até"
                                cancelLabel={'Cancelar'}
                                minDate={this.state.scheduleEnd ? this.state.scheduleEnd : new Date()}
                                okLabel={'Confirmar'}
                                onChange={(v) => { this.setState({ repeat_date: v }) }}
                                value={this.state.repeat_date}
                            />
                        </MuiPickersUtilsProvider>

                        : null}

                    {this.state.editId && this.state.id_parent_event != null ? <IosSwitch fullWidth label={`Aplicar mudanças nos aluguéis futuros de ${moment(this.state.scheduleStart).format('dddd HH:mm')}`} checked={this.state.updateOnFutureLessons} onChange={(v) => { this.setState({ updateOnFutureLessons: v }) }} /> : null}

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.scheduleCourtRent() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                        <DefaultButton onClick={() => { this.setState({ courtRentModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    async addExternalUser(editIndex) {
        if (this.state.name.length) {
            this.setState({ loadingModal: true });

            let externalUsers = this.state.externalUsers;

            if (editIndex >= 0) {
                externalUsers[editIndex] = { name: this.state.name, phone: this.state.phone ? this.state.phone.replace(/[^\d]/g, '') : `` };
            } else {
                externalUsers.push({ name: this.state.name, phone: this.state.phone ? this.state.phone.replace(/[^\d]/g, '') : `` });
            }

            this.setState({ externalUsers, name: '', phone: '', editIndex: -1, addExternalUsers: false, loadingModal: false });
        } else {
            toast.warn('Preencha o nome');
        }
    }

    async removeExternalUser(key) {
        this.setState({ loadingModal: true });

        let externalUsers = this.state.externalUsers;
        externalUsers.splice(key, 1);

        this.setState({ externalUsers, loadingModal: false });
    }

    async handleReplacementModal() {

        this.setState({
            loadingModal: true,
            replacementModal: true,
            replacementStart: null,
            replacementEnd: null,
            replacementCourt: '',
            replacementTeacher: '',
            replacementStudents: []
        });

        await this.getStudents();
        await this.getReplacementLessons();

        this.setState({ loadingModal: false });
    }

    async handleRenewalModal() {

        this.setState({ loadingModal: true, renewalModal: true });

        await this.getActiveRenewals();

        this.setState({ loadingModal: false });
    }

    renderButtons() {

        return (
            <HeaderActionsWrapper style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                    <DefaultButton disabled={this.state.loading} color={Colors.primary} width={240} leftIcon={<ArrowDropDownIcon style={{ marginRight: 10 }} />} onClick={(evt) => { this.setState({ scheduleMenuAnchor: evt.currentTarget }) }} title={'Agendar Evento'} />
                    <Menu
                        anchorEl={this.state.scheduleMenuAnchor}
                        style={{ marginTop: 50 }}
                        keepMounted
                        open={Boolean(this.state.scheduleMenuAnchor)}
                        onClose={() => { this.setState({ scheduleMenuAnchor: null }) }}>

                        <MenuItem style={{ color: '#36A2EB', fontWeight: 'bold' }} onClick={() => { this.setState({ scheduleMenuAnchor: null, scheduleModal: true, scheduleStart: null, scheduleEnd: null, scheduleStudents: [], scheduleTeacher: null, scheduleCourt: null, sendPushTeacher: true, sendPushStudent: true, repeat: false, repeat_date: null, openReplacement: false, openReplacementNumber: '', replacementCategory: [], editId: null }) }}>
                            <SportsTennisIcon style={{ marginRight: 10 }} />
                            Agendar Aula
                        </MenuItem>
                        <MenuItem style={{ color: '#3b9c3e', fontWeight: 'bold' }} onClick={() => { this.setState({ scheduleMenuAnchor: null, courtRentModal: true, scheduleStart: null, scheduleEnd: null, scheduleStudents: [], scheduleTeacher: null, scheduleCourt: null, sendPushTeacher: true, sendPushStudent: true, repeat: false, repeat_date: null, openReplacement: false, openReplacementNumber: '', replacementCategory: [], editId: null }) }}>
                            <AttachMoneyIcon style={{ marginRight: 10 }} />
                            Agendar Aluguel
                        </MenuItem>

                    </Menu>
                    <div style={{ marginRight: 12 }} />
                    <Badge style={{ zIndex: 0, marginRight: 15 }} badgeContent={this.state.replacementLessonsTotal} color="secondary"><DefaultButton disabled={this.state.loading} leftIcon={<LoopIcon style={{ marginRight: 10 }} />} onClick={() => { this.handleReplacementModal() }} title={'Reposição'} color={'orange'} /></Badge>
                    <Badge style={{ zIndex: 0, marginRight: 15 }} badgeContent={this.state.activeRenewalsDocs.length || 0} color="secondary"><DefaultButton disabled={this.state.loading} leftIcon={<RepeatIcon style={{ marginRight: 10 }} />} onClick={() => { this.handleRenewalModal() }} title={'Repetições'} color={'#ed6111'} /></Badge>
                    {!this.state.showKanban ?
                        <DefaultButton disabled={this.state.loading} leftIcon={<TableChartIcon style={{ marginRight: 10 }} />} onClick={this.toggleKanban} title={'Kanban'} color={'#008B02'} />
                        :
                        <DefaultButton disabled={this.state.loading} leftIcon={<CalendarTodayIcon style={{ marginRight: 10 }} />} onClick={this.toggleKanban} title={'Voltar à Agenda'} color={'#008B02'} />
                    }
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                    {!this.props.noFilter && <DefaultButton disabled={this.state.loading || this.state.showKanban} width={50} leftIcon={<TuneIcon style={{ marginLeft: 8 }} />} onClick={() => { this.setState({ panelOpen: !this.state.panelOpen }) }} title={''} />}
                    <div style={{ marginLeft: 12 }} />
                    {this.props.export && <DefaultButton disabled={this.state.loading} width={50} leftIcon={<PrintIcon style={{ marginLeft: 8 }} />} onClick={() => { this.setState({ exportModal: true }) }} title={''} />}
                </div>
            </HeaderActionsWrapper>
        )
    }

    toggleKanban = async () => {
        if (!this.state.showKanban) {
            const previousStart = this.state.start;
            const previousEnd = this.state.end;

            await this.setState({ previousStart, previousEnd });
        }

        await this.setState({
            showLessons: true,
            showCourtRents: true,
            showDayUses: true,
            showCourtColors: true,
            showCustomColors: true,
            showTournamentMatches: true,
            showExperimentalLessons: true,
            showPrivateLesson: true,
            selected_replacement_lesson: false,
            selected_student: 'all',
            selected_category: 'all',
            selected_tag: 'all',
            selected_teacher: 'all',
            selected_court: 'all',
            selected_subscription_status: 'all',
            kanbanCallback: false,
            showKanban: !this.state.showKanban
        });

        if (!this.state.showKanban) {
            await this.setState({ start: this.state.previousStart, end: this.state.previousEnd });
            await this.filterEvents();
        }
    }

    renderLoading() {
        return (
            <ClipLoader
                css={css`position:absolute;top:50%;left:50%;z-index: 999;`}
                size={50}
                color={Colors.primary}
                loading={true}
            />
        );
    }

    handleEventSelect(event) {
        if (!this.props.readOnly) {
            this.setState({ eventOpen: true, selectedEvent: event });
        }
    }

    handleSlotSelection = (event) => {
        if (this.state.view !== 'month') {
            this.setState({ scheduleStudents: [], scheduleTeacher: null, scheduleCourt: null, sendPushTeacher: true, sendPushStudent: true, repeat: false, repeat_date: null, openReplacement: false, openReplacementNumber: '', replacementCategory: [], editId: null, scheduleCategories: [], scheduleTags: [] })
            this.setState({ addEventModal: true, scheduleStart: event.start, scheduleEnd: event.end });
        }
    }

    addEventModal() {
        if (this.state.addEventModal && this.state.scheduleStart && this.state.scheduleEnd) {
            return (
                <div>
                    <p style={{ marginBottom: 20 }}>Você está agendando um evento de {moment(this.state.scheduleStart).format('DD/MM/YYYY HH:mm')} até {moment(this.state.scheduleEnd).format('HH:mm')}</p>

                    <div style={{ marginBottom: 15 }}>
                        <DefaultButton color={`#36A2EB`} onClick={() => { this.setState({ scheduleModal: true, addEventModal: false }) }} loading={this.state.loadingModal} leftIcon={<SportsTennisIcon style={{ marginRight: 10 }} />} width={'100%'} title={'Aula'} />
                    </div>
                    <div style={{ marginBottom: 15 }}>
                        <DefaultButton color={`#3b9c3e`} onClick={() => { this.setState({ courtRentModal: true, addEventModal: false }) }} loading={this.state.loadingModal} leftIcon={<AttachMoneyIcon style={{ marginRight: 10 }} />} width={'100%'} title={'Aluguel'} />
                    </div>

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 30 }}>
                        <DefaultButton onClick={() => { this.setState({ addEventModal: false, scheduleStart: null, scheduleEnd: null }) }} color={Colors.error} leftIcon={<KeyboardBackspaceIcon style={{ marginRight: 10 }} />} loading={this.state.loadingModal} width={'100%'} title={'CANCELAR'} />
                    </div>
                </div>
            );

        }
    }

    renderCalendar() {
        return (
            <div style={{ position: 'relative' }}>

                <div style={{ filter: this.state.loading && this.state.firstLoad ? 'blur(5px)' : '' }}>
                    {!this.state.firstLoad ? <DefaultLoader /> : <Calendar
                        className={this.props.className ? this.props.className : ''}
                        messages={{
                            agenda: 'Agenda',
                            today: 'Hoje',
                            month: 'Mês',
                            week: 'Semana',
                            day: 'Dia',
                            next: 'Próximo',
                            previous: 'Voltar',
                            noEventsInRange: 'Nenhum evento encontrado para essas datas',
                            allDay: 'Dia Todo',
                            date: 'Data',
                            event: 'Evento',
                            time: 'Hora',
                            showMore: (count) => `+${count} eventos`,
                        }}
                        localizer={localizer}
                        events={this.state.events}
                        startAccessor="rankedStart"
                        endAccessor="end"
                        eventPropGetter={(event) => {
                            var color = event.color;
                            if (!color) {
                                color = '#36A2EB';
                            }

                            if (this.state.showCourtColors && this.state.courtDocs && event.id_court) {
                                let court = this.state.courtDocs.find(item => item.id === event.id_court);
                                color = court && court.color ? court.color : color;
                            }

                            if (this.state.showCustomColors && event.eventColor) {
                                color = event.eventColor;
                            }

                            var style = {
                                backgroundColor: color,
                                borderRadius: '3px',
                                opacity: 0.8,
                                color: 'white',
                                border: '1px',
                                display: 'block'
                            };
                            return {
                                style: style
                            };
                        }}
                        style={{ height: this.props.height ? this.props.height : 650, minHeight: 500 }}
                        onSelectEvent={(evt) => { this.handleEventSelect(evt) }}
                        components={{
                            toolbar: (props) => <CalendarToolbar {...props} />,
                            event: (props) => <CalendarEvent {...props} view={this.state.view} />
                        }}
                        tooltipAccessor={(e) => { return '' }}
                        selectable={true}
                        onSelectSlot={this.handleSlotSelection}
                        onRangeChange={async (range, view) => {

                            if (view === undefined) {

                                if (Array.isArray(range)) {

                                    if (range.length === 1) {

                                        view = 'day';

                                    } else if (range.length === 7) {

                                        view = 'week';
                                    }

                                } else {

                                    view = 'month';
                                }
                            }

                            if (view === 'month' || view === 'agenda') {

                                await this.setState({ start: range.start, end: range.end, view: view });


                            } else if (view === 'week') {
                                let start = moment(range[0]).startOf('day').toDate();
                                let end = moment(range[range.length - 1]).endOf('day').toDate();

                                await this.setState({ start, end, view: view });

                            } else if (view === 'day') {

                                let start = moment(range[0]).startOf('day').toDate();
                                let end = moment(range[0]).endOf('day').toDate();

                                await this.setState({ start, end, view: view });
                            }

                            await this.filterEvents();
                        }}
                        scrollToTime={moment().startOf('hour').toDate()}
                        defaultView={this.state.view}
                        dayLayoutAlgorithm={"no-overlap"}
                        weekLayoutAlgorithm={"no-overlap"}
                        step={15}
                        timeslots={4}
                    />}
                </div>

                {this.state.loading && this.state.firstLoad ? this.renderLoading() : null}

            </div>
        );
    }

    renderKanban = () => {
        return (
            <Kanban parent={this} />
        );
    }

    searchCallback = (user) => {
        let exists = this.state.userDocsDayUse.find(eUser => (eUser.id === user.id));

        if (!exists) {
            let userDocsDayUse = this.state.userDocsDayUse;
            userDocsDayUse.push(user);

            this.setState({ userDocsDayUse, loadingSearchBar: this.state.selectedDayUse.participants && userDocsDayUse.length === this.state.selectedDayUse.participants.length ? false : true });
        }
    }

    dayUseModal() {
        if (this.state.dayUseModal && this.state.selectedDayUse.id) {
            return (
                <div>
                    <p style={{ fontSize: 12, marginTop: -10 }}>Cancele o pedido para cancelar uma inscrição</p>
                    <SearchBar docs={this.state.userDocsDayUse} search={'name'} loading={this.state.loadingSearchBar} onEmpty={async (docs) => { await this.setState({ userDocsDayUse: docs, searchingDayUseUsers: false }) }} onSearch={async (result) => { await this.setState({ userDocsDayUse: result, searchingDayUseUsers: true }) }} placeholder={'Busque por inscritos...'} />

                    {this.state.searchingDayUseUsers ?
                        <div style={{ overflowY: 'auto', width: '100%', maxHeight: 560 }}>
                            <p style={{ fontSize: 12 }}>{this.state.userDocsDayUse.length} resultados para a busca:</p>
                            {this.state.userDocsDayUse.map(user => <DayUseUserCard dayUse={this.state.selectedDayUse} userId={user.id} />)}
                        </div>
                        :
                        <div style={{ overflowY: 'auto', width: '100%', maxHeight: 560 }}>
                            {this.state.selectedDayUse.participants.map(id_user => <DayUseUserCard dayUse={this.state.selectedDayUse} userId={id_user} searchCallback={this.searchCallback} />)}
                        </div>
                    }


                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.setState({ dayUseModal: false, selectedDayUse: {}, userDocsDayUse: [] }) }} color={Colors.primary} loading={this.state.loadingModal} width={'100%'} title={'VOLTAR'} />
                    </div>
                </div>
            );
        }
    }

    render() {
        return (
            <div style={{ height: `100%` }}>
                <div style={{ marginBottom: 15 }}>

                    {!this.props.hiddenButton && this.renderButtons()}

                </div>

                {this.state.showKanban ? this.renderKanban() : this.renderCalendar()}

                <SlidingPane
                    className={'right-panel'}
                    isOpen={this.state.panelOpen}
                    title="Filtros"
                    closeIcon={<CloseIcon style={{ width: 30, marginTop: 5 }} />}
                    width={window.screen.width < 1080 ? "80%" : "40%"}
                    onRequestClose={() => this.setState({ panelOpen: false })}>
                    <FormLabel style={{ paddingBottom: 18, paddingTop: 18 }} component="legend">Filtrar</FormLabel>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 10 }} className={'header-actions-buttons'}>
                        <DefaultSelect search={true} allItems={<MenuItem value={'all'}>{'TODOS OS ALUNOS'}</MenuItem>} searchField={'name'} id={'student_selection'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ selected_student: v.target.value }); this.filterEvents() }} value={this.state.selected_student || 'all'} docs={this.state.companyStudents} label={'Aluno'} />
                        <div style={{ marginLeft: 5, marginRight: 5, marginBottom: 12 }} />
                        <DefaultSelect search={true} allItems={<MenuItem value={'all'}>{'TODOS OS PROFESSORES'}</MenuItem>} searchField={'name'} id={'teacher_selection'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ selected_teacher: v.target.value }); this.filterEvents() }} value={this.state.selected_teacher || 'all'} docs={this.state.teacherDocs} label={'Professor'} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 15, paddingLeft: 10 }}>
                        <DefaultSelect search={true} allItems={<MenuItem value={'all'}>{'TODOS AS QUADRAS'}</MenuItem>} searchField={'name'} id={'court_selection'} secondaryDisplay={'sport_label'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ selected_court: v.target.value }); this.filterEvents() }} value={this.state.selected_court || 'all'} docs={this.state.courtDocs} label={'Quadra'} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 15, paddingLeft: 10 }}>
                        <DefaultSelect search={true} allItems={<MenuItem value={'all'}>{'TODAS AS CATEGORIAS'}</MenuItem>} searchField={'label'} id={'category_selection'} valueField={'value'} displayField={'label'} onChange={(v) => { this.setState({ selected_category: v.target.value.toString() }); this.filterEvents() }} value={this.state.selected_category || 'all'} docs={this.state.paramDoc.categories} label={'Categoria da Aula'} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 15, paddingLeft: 10 }}>
                        <DefaultSelect search={true} allItems={<MenuItem value={'all'}>{'TODAS AS TAGS'}</MenuItem>} searchField={'label'} id={'tag_selection'} valueField={'value'} displayField={'label'} onChange={(v) => { this.setState({ selected_tag: v.target.value.toString() }); this.filterEvents() }} value={this.state.selected_tag || 'all'} docs={this.state.paramDoc.tags} label={'Tag'} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 15, paddingLeft: 10 }}>
                        <DefaultSelect search={true} allItems={<MenuItem value={'all'}>{'TODOS OS STATUS'}</MenuItem>} searchField={'label'} id={'status_selection'} valueField={'value'} displayField={'label'} onChange={(v) => { this.setState({ selected_subscription_status: v.target.value }); this.filterEvents() }} value={this.state.selected_subscription_status || 'all'} docs={this.state.statusTypes} label={'Status da Matrícula'} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 15, paddingLeft: 10 }}>
                        <DefaultSelect id={'replacement_lesson_selection'} valueField={'value'} displayField={'label'} onChange={(v) => { this.setState({ selected_replacement_lesson: v.target.value }); this.filterEvents() }} value={this.state.selected_replacement_lesson || false} docs={[{ label: 'Não', value: false }, { label: 'Sim', value: true }]} label={'Apenas Reposições'} />
                    </div>
                    <FormLabel style={{ paddingBottom: 18, paddingTop: 18 }} component="legend">Exibir</FormLabel>
                    <div style={{ width: '100%' }}>
                        <IosSwitch label="Aulas" value="showLessons" checked={this.state.showLessons} onChange={(v) => { this.setState({ showLessons: v }); this.filterEvents() }} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <IosSwitch label="Aluguel de Quadra" value="showCourtRents" checked={this.state.showCourtRents} onChange={(v) => { this.setState({ showCourtRents: v }); this.filterEvents() }} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <IosSwitch label="Day Use" value="showDayUses" checked={this.state.showDayUses} onChange={(v) => { this.setState({ showDayUses: v }); this.filterEvents() }} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <IosSwitch label="Partida de Torneio" value="showTournamentMatches" checked={this.state.showTournamentMatches} onChange={(v) => { this.setState({ showTournamentMatches: v }); this.filterEvents() }} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <IosSwitch label="Aula Experimental" value="showExperimentalLessons" checked={this.state.showExperimentalLessons} onChange={(v) => { this.setState({ showExperimentalLessons: v }); this.filterEvents() }} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <IosSwitch label="Aula Avulsa" value="showPrivateLesson" checked={this.state.showPrivateLesson} onChange={(v) => { this.setState({ showPrivateLesson: v }); this.filterEvents() }} />
                    </div>
                    <FormLabel style={{ paddingBottom: 18, paddingTop: 18 }} component="legend">Visualização</FormLabel>
                    <div style={{ width: '100%' }}>
                        <IosSwitch label="Separar por Cor da Quadra" value="showCourtColors" checked={this.state.showCourtColors} onChange={(v) => { this.setState({ showCourtColors: v }); this.filterEvents() }} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <IosSwitch label="Mostrar cores Personalizadas" value="showCustomColors" checked={this.state.showCustomColors} onChange={(v) => { this.setState({ showCustomColors: v }); this.filterEvents() }} />
                    </div>
                    <div style={{ heigth: 100 }} />
                </SlidingPane>

                {this.state.eventOpen ?

                    <EventPanel
                        panelOpen={this.state.eventOpen}
                        event={this.state.selectedEvent}
                        close={() => { this.setState({ eventOpen: false, selectedEvent: null }) }}
                        repeatEvent={(event) => { this.setState({ repetitionEventToRenew: event, renewRepetitionModal: true }) }}
                        editLesson={(event) => { this.setState({ scheduleModal: true, editId: event.id, scheduleStart: event.start, scheduleEnd: event.end, scheduleStudents: event.id_student, scheduleTeacher: event.id_teacher, scheduleCourt: event.id_court, id_parent_event: event.id_parent_event || null, repeat: false, repeat_date: null, openReplacement: false, openReplacementNumber: '', eventOpen: false, selectedEvent: null, scheduleCategories: event.categories || [], scheduleTags: event.id_tags || [], eventColor: event.eventColor || false }) }}
                        editCourtRent={(event) => {
                            if (event.manual_info && event.manual_info.name) {
                                this.setState({ externalUsers: [{ name: event.manual_info.name, phone: event.manual_info.phone }], internalSchedule: 'external' });
                            } else if (event.manual_info && event.manual_info[0] && event.manual_info[0].name) {
                                this.setState({ externalUsers: event.manual_info, internalSchedule: 'external' });
                            } else {
                                this.setState({ scheduleStudents: event.id_student, internalSchedule: 'internal' });
                            }

                            this.setState({
                                courtRentModal: true,
                                editId: event.id,
                                scheduleStart: event.start,
                                scheduleEnd: event.end,
                                scheduleCourt: event.id_court,
                                id_parent_event: event.id_parent_event || null,
                                repeat: false,
                                repeat_date: null,
                                eventOpen: false,
                                selectedEvent: null,
                                eventColor: event.eventColor || false
                            });
                        }}
                        editDayUse={async (event) => {
                            let query = await Firestore.getDoc('day_use', event.id);

                            if (query.exists) {
                                this.setState({ dayUseModal: true, selectedDayUse: { ...query.data(), id: query.id } })
                            } else {
                                toast.error(`Não foi possível editar evento`);
                            }
                        }}
                        notifyCanceledLesson={this.notifyCanceledLesson}
                        notifyCanceledCourtRent={this.notifyCanceledCourtRent}
                        notifyCanceledPrivateLesson={this.notifyCanceledPrivateLesson}
                        notifyCanceledExperimentalLesson={this.notifyCanceledExperimentalLesson}
                        paramDoc={this.state.paramDoc}
                        setLessonHasReplacementLesson={this.setLessonHasReplacementLesson}
                        removeReplacementLesson={this.removeReplacementLesson}
                        filterEvents={this.filterEvents}
                        addUserReplacementLesson={this.addUserReplacementLesson}
                        instance={this}
                    />
                    : null}

                <DefaultModal loading={this.state.loadingModal} content={this.renewRepetitionModal()} title={'Renovar repetição'} onClose={() => { this.setState({ renewRepetitionModal: false, repetitionEventToRenew: {}, repeat_date: null }) }} open={this.state.renewRepetitionModal} width={window.screen.width < 1366 ? (window.screen.width - (window.screen.width * 0.15)) : 850} />
                <DefaultModal loading={this.state.loadingModal} content={this.renewalModal()} title={''} onClose={() => { this.setState({ renewalModal: false }) }} open={this.state.renewalModal} width={window.screen.width < 1366 ? (window.screen.width - (window.screen.width * 0.15)) : 850} />
                <DefaultModal loading={this.state.loadingModal} content={this.replacementModal()} title={''} onClose={() => { this.setState({ replacementModal: false }) }} open={this.state.replacementModal} width={window.screen.width - (window.screen.width * 0.15)} />
                <DefaultModal loading={this.state.loadingModal} content={this.scheduleModal()} title={'Agendar Aula'} onClose={() => { this.setState({ scheduleModal: false }) }} open={this.state.scheduleModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.courtRentModal()} title={'Agendar Aluguel'} onClose={() => { this.setState({ courtRentModal: false }) }} open={this.state.courtRentModal} />
                <DefaultModal loading={this.state.loadingModal} content={<CalendarEventReport getDocs={[this.getStudents, this.getTeachers, this.getCourts]} />} title={'Exportar Agenda'} onClose={() => { this.setState({ exportModal: false }) }} open={this.state.exportModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.dayUseModal()} title={'Inscrições do Day Use'} onClose={() => { this.setState({ dayUseModal: false, selectedDayUse: {}, userDocsDayUse: [] }) }} open={this.state.dayUseModal} width={window.screen.width < 1366 ? (window.screen.width - (window.screen.width * 0.15)) : 850} />
                <DefaultModal loading={this.state.loadingModal} content={this.addEventModal()} title={'Agendar um Evento'} onClose={() => { this.setState({ addEventModal: false }) }} open={this.state.addEventModal} width={window.screen.width < 1366 ? (window.screen.width - (window.screen.width * 0.15)) : 850} />
            </div>
        )
    }
}

const styles = {
    selectComponentBox: {
        color: `white`,
        backgroundColor: Colors.primary,
        fontSize: 12,
        paddingLeft: 5,
        paddingRight: 5,
        borderRadius: 5,
        marginLeft: 5
    }
}