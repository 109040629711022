import React, { Component } from 'react';
import { RadioGroup, FormControlLabel, Radio, FormLabel } from '@material-ui/core';
import Colors from '../../constants/Colors';

export default class ReportDefaultModalOptions extends Component {

    handleOutputChange(value) {

        this.setState({ output: value });

        if (this.props.onOutputChange) {

            this.props.onOutputChange(value);
        }
    }

    handleFormatChange(value) {

        this.setState({ format: value });

        if (this.props.onFormatChange) {

            this.props.onFormatChange(value);
        }
    }

    render() {

        return (

            <div>
                <FormLabel style={{ marginTop: 18, fontWeight: '800' }} component="legend">Formato</FormLabel>
                <div style={{ flexDirection: 'row', paddingTop: 10 }}>
                    <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} value={this.props.format} onChange={(evt) => { this.handleFormatChange(evt.target.value) }}>
                        <FormControlLabel value="pdf" control={<Radio style={{ color: Colors.primary }} />} label="Pdf" />
                        <FormControlLabel value="xlxs" control={<Radio style={{ color: Colors.primary }} />} label="XLSX (Excel)" />
                    </RadioGroup>
                </div>

                {
                    this.props.format === 'pdf' ?

                        <div>
                            <FormLabel style={{ marginTop: 18, fontWeight: '800' }} component="legend">Exportar Como</FormLabel>
                            <div style={{ flexDirection: 'row', paddingTop: 10 }}>
                                <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} value={this.props.output} onChange={(evt) => { this.handleOutputChange(evt.target.value) }}>
                                    <FormControlLabel value="download" control={<Radio style={{ color: Colors.primary }} />} label="Baixar Arquivo" />
                                    <FormControlLabel value="new_tab" control={<Radio style={{ color: Colors.primary }} />} label="Nova Aba" />
                                </RadioGroup>
                            </div>
                        </div>

                : null }
            </div>
        )
    }
}