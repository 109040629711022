import React, { Component } from 'react';
import TournamentMatch from './TournamentMatch';
import Colors from '../constants/Colors';
import DefaultLoader from './DefaultLoader';
import Firestore from '../api/firebase/Firestore';

export default class TournamentKeys extends Component {
    state = {
        rounds: this.props.rounds,
        loadingRounds: {}
    }

    async componentDidMount() {
        for (let i = 0; i < this.props.rounds.length; i++) {
            this.loadRound(i);
        }
    }

    async loadRound(roundIndex) {
        let rounds = this.state.rounds;
        let loadingRounds = this.state.loadingRounds;

        loadingRounds[roundIndex] = true;

        this.setState({ loadingRounds });

        let indexes = this.props.indexes;
        let id_tournament = this.props.tournament.id;

        let query = await Firestore.customQuery('tournament_event').where('id_tournament', '==', id_tournament)
            .where('indexes.category', '==', indexes.category).where('indexes.class', '==', indexes.class)
            .where('indexes.round', '==', roundIndex).get();

        let matches = [];

        query.forEach(match => { matches.push({ ...match.data(), id: match.id }) });
        matches.sort((a, b) => a.indexes.match - b.indexes.match);
        
        rounds[roundIndex].matches = matches;
        loadingRounds[roundIndex] = false;

        this.setState({ rounds, loadingRounds });
    }

    renderRound(key) {
        let round = this.state.rounds[key];
        
        if (this.state.rounds[key].matches && !this.state.loadingRounds[key]) {
            return (
                <div style={{ marginRight: 40 }}>
                    <h4 style={{ position: 'absolute', top: 0, minWidth: 220, paddingLeft: 8 }}>{key === this.props.rounds.length - 1 ? 'Final' : `Rodada ${key + 1}`}</h4>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: this.props.rounds[0].matches.length * 150, marginTop: 80, marginRight: 50 }}>
                        {
                            round.matches.map((match, matchKey) => {

                                return <TournamentMatch view={'key'} {...this.props} round={key} match={match} index={matchKey}/>
                                
                            })
                        }
                    </div>
                </div>
            );
        } else {
            return (
                <div style={{ marginRight: 25, minWidth: 220 }}>
                    <h4 style={{ position: 'absolute', top: 0, minWidth: 100, paddingLeft: 8 }}>{key === this.props.rounds.length - 1 ? 'Final' : `Rodada ${key + 1}`}</h4>
                    <div style={{ display: 'flex', padding: 8, boxShadow: Colors.boxShadow, borderRadius: 5, flexDirection: 'column', backgroundColor: 'white', marginTop: 0, marginRight: 0 }}>
                        <p style={{ textAlign: 'center', color: 'grey' }}><DefaultLoader size={28} color={'grey'} /></p>
                    </div>
                </div>
            );
        }
    }

    renderRounds() {

        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative' }}>

                { 
                    this.props.rounds.map((round, key) => (this.renderRound(key)))
                }

            </div>
        )
    }

    render() {
        return (
            <div style={{ padding: 25 }}>
                { this.props.rounds && this.props.rounds.length ? this.renderRounds() : null }
            </div>
        )
    }
}