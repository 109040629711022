import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import { Card, Button, StepLabel, Stepper, Step, FormLabel, RadioGroup, FormControlLabel, Radio, Checkbox, InputLabel } from '@material-ui/core';
import DefaultInput from '../components/DefaultInput';
import Colors from '../constants/Colors';
import InputMask from "react-input-mask";
import { withRouter } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import CheckIcon from '@material-ui/icons/Check';
import { toast, ToastContainer } from 'react-toastify';
import Functions from '../api/firebase/Functions';
import 'react-credit-cards/es/styles-compiled.css';
import moment from "moment-timezone";
import LocationPicker from '../components/LocationPicker';
import GeographicHelper from '../helper/GeographicHelper';
import Geohash from '../helper/Geohash';
import DefaultLoader from '../components/DefaultLoader';
import DocumentHelper from '../helper/DocumentHelper';
import DataHelper from '../helper/DataHelper';

class TrialPage extends Component {

    state = {
        activeStep: 0,
        setActiveStep: 0,
        error: false,
        loading: false,
        steps: ['Dados da Empresa', 'Finalização'],
        city: '',
        ddd: '',
        name: '',
        type: 'pj',
        establishment_type: 'academy',
        phone: '',
        cnpj: '',
        cpf: '',
        zipcode: '',
        state: '',
        street: '',
        number: '',
        complemento: '',
        neighborhood: '',
        userName: '',
        email: '',
        passwordConfirm: '',
        password: '', 
        inputError: false,
        coordinates: GeographicHelper.setFirebaseGeopoint(-27, -52),
        acceptedTerms: false,
    }

    async handleNext() {

        let isValid = true;
        let step = this.state.activeStep;

        this.setState({ loading: true });

        if (step === 0) {

            isValid = await this.validateCompanyData();

            if (isValid && !this.state.acceptedTerms) {

                isValid = false;
                toast.warn('Aceite os termos de uso para prosseguir');
            }

            if (isValid) {

                isValid = await this.finish();
            }
        }

        if (isValid) { step ++ }

        this.setState({ activeStep: step, loading: false });
    };
    
    handleBack() {

        let step = this.state.activeStep;
        step --;

        this.setState({ activeStep: step, inputError: false });
    };

    async validateCompanyData() {

        let flag = true;

        if (this.state.name && this.state.ddd && this.state.phone && (this.state.cnpj || this.state.cpf) && this.state.zipcode && this.state.state && this.state.city && this.state.street && this.state.number && this.state.neighborhood && this.state.email && this.state.password) {

            if ((this.state.type === 'pj' && DocumentHelper.cnpjValid(this.state.cnpj.replace(/[^\d]/g, ''))) || (this.state.type === 'pf' && DocumentHelper.cpfValid(this.state.cpf.replace(/[^\d]/g, '')))) {

                let emailExists;

                try {
                    
                    let request = await Functions.request('POST', 'emailExists', { email: this.state.email });
    
                    if (request.status === 200) {
    
                        emailExists = request.result;
    
                    } else {
    
                        emailExists = null;
                    }
    
                } catch (error) {
    
                    emailExists = null;
                }
    
                if (emailExists === true || emailExists === null) {
    
                    flag = false;
                    toast.warn('Já existe um login com o e-mail escolhido');
                }
    
                let companyExists;
    
                try {
    
                    let request = await Functions.request('POST', 'companyExists', { type: this.state.type, cpf: this.state.cpf, cnpj: this.state.cnpj });
    
                    if (request.status === 200) {
    
                        companyExists = request.result;
    
                    } else {
    
                        companyExists = null;
                    }
    
                } catch (error) {
    
                    companyExists = null;
                }
    
                if (companyExists === true || companyExists === null) {
    
                    flag = false;
                    toast.warn(`Já existe uma empresa com o ${this.state.type === 'pj' ? 'Cnpj' : 'Cpf'} escolhido`);
                }
    
                if (this.state.inputError) {
                    flag = false;
                    toast.warn('Preencha todos os campos corretamente');
    
                } else {
                    
                    if (this.state.password !== this.state.passwordConfirm) {
                        flag = false;
                        toast.warn('As senhas são diferentes');
                    }
    
                    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email) === false) {
                        flag = false;
                        toast.warn('O E-mail informado não é válido');
                    }
                }

            } else {

                flag = false;
                toast.warn(`O ${this.state.type === 'pj' ? 'cnpj' : 'cpf'} informado é inválido`);
            }

        } else {

            flag = false;
            toast.warn('Preencha todos os campos');
        }

        return flag;
    }

    async finish() {

        try {

            await this.setState({ loading: true });

            let coordinates = GeographicHelper.getLatLngFromGeopoint(this.state.coordinates);
            let companyData = {
                companyDoc: {
                    id_subscription_pagarme: '',
                    id_recipient_pagarme: '',
                    active: true,
                    city: this.state.city,
                    complemento: this.state.complemento,
                    country: 'Brasil',
                    language: 'br',
                    ddd: this.state.ddd.replace(/[^\d]/g, ''),
                    email: this.state.email,
                    name: this.state.name,
                    type: this.state.type,
                    establishment_type: this.state.establishment_type,
                    neighborhood: this.state.neighborhood,
                    number: this.state.number.replace(/[^\d]/g, ''),
                    phone: this.state.phone.replace(/[^\d]/g, ''),
                    state: this.state.state,
                    street: this.state.street,
                    visible: false,
                    zipcode: this.state.zipcode.replace(/[^\d]/g, ''),
                    g: {
                        geohash: Geohash.encode(coordinates.lat, coordinates.lng),
                    },
                    tasks: {
                        first_payment_account: { title: 'Conta de Pagamentos', body: 'Cadastre a sua conta de pagamentos para poder vender pelo app.', redirect: '/payment_account', completed: false, order: 0 },
                        app_visible: { title: 'Fique Visível no App', body: 'Acesse as configurações da sua empresa, e fique visível no app.', redirect: '/company', completed: false, order: 1 },
                        company_description: { title: 'Descrição da Empresa', body: 'Acesse as configurações da sua empresa, e escreva uma descrição.', redirect: '/company', completed: false, order: 2 },
                        company_logo: { title: 'Logo da Empresa', body: 'Acesse as configurações da sua empresa, e faça o upload de uma logomarca.', redirect: '/company', completed: false, order: 3 },
                        company_photo: { title: 'Fotos da Empresa', body: 'Acesse as configurações da sua empresa, e adicione imagens da sua empresa.', redirect: '/company', completed: false, order: 4 },
                        company_sport: { title: 'Esporte', body: 'Acesse as configurações da sua empresa, e selecione o tipo de esporte oferecido.', redirect: '/company', completed: false, order: 5 },
                        company_services: { title: 'Serviços', body: 'Acesse as configurações da sua empresa, e marque os serviços que você oferece.', redirect: '/company', completed: false, order: 6 },
                        first_court: { title: 'Primeira Quadra', body: 'Cadastre a sua primeira quadra.', redirect: '/court', completed: false, order: 7 },
                    },
                },
                companyCoordinates: this.state.coordinates,
            };

            if (this.state.type === 'pj') {

                companyData.companyDoc.cnpj = this.state.cnpj.replace(/[^\d]/g, '');

            } else {

                companyData.companyDoc.cpf = this.state.cpf.replace(/[^\d]/g, '');
            }

            let userData = {
                email: this.state.email,
                name: this.state.userName,
                sector: '',
                type: 'admin',
                password: this.state.password,
            };

            let data = {
                isTrial: true,
                company: {
                    companyDoc: companyData.companyDoc,
                    companyCoordinates: companyData.companyCoordinates,
                },
                user: userData,
            }

            let response = await Functions.request('POST', 'addCompany', data);
            if (response.status !== 200) { throw new Error('User Creation Error') }

            await this.setState({ loading: false });

            return true;

        } catch (error) {
console.log(error)
            this.setState({ loading: false });
            toast.error('Houve um problema ao cadastrar, entre em contato conosco');

            return false;
        }
    }

    renderTerms() {
        
        return (
            <FormControlLabel
                control={
                    <Checkbox
                    style={{ color: Colors.primary }}
                    checked={this.state.acceptedTerms}
                    onChange={(v) => { this.setState({ acceptedTerms: v.target.checked }) }}
                    name="acceptedTerms"
                    />
                }
                label={<p>Declaro que li e aceito os <a style={{ color: Colors.primary }} target={'_blank'} href={'https://aceapp.com.br/termos-de-uso.html'}>Termos de Uso</a> e a <a style={{ color: Colors.primary }} target={'_blank'} href={'https://aceapp.com.br/politica-de-privacidade.html'}>Política de Privacidade</a>.</p>}
            />
        )
    }

    renderDocument() {
        
        if (this.state.type === 'pj') {

            return (
                <InputMask onChange={(v) => { this.setState({ cnpj: v }) }} mask="99.999.999/9999-99" value={this.state.cnpj}>
                    <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={14} label={'Cnpj'} />
                </InputMask>
            )

        } else if (this.state.type === 'pf') {

            return (
                <InputMask onChange={(v) => { this.setState({ cpf: v }) }} mask="999.999.999-99" value={this.state.cpf}>
                    <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={11} label={'Cpf'} />
                </InputMask>
            )
        }
    }

    handleMapChange = (geodata) => {
        let state = this.state;

        if (geodata.address) {
            Object.keys(geodata.address).forEach(key => {
                if (key == 'number') {
                    state[key] = geodata.address[key].replace(/\D*/, '');
                    state['complemento'] = geodata.address[key].replace(/\d*/, '');
                } else {
                    state[key] = geodata.address[key];
                }
            });
        }
        if (geodata.coords) {
            state.coordinates = GeographicHelper.setFirebaseGeopoint(geodata.coords.lat, geodata.coords.lng);
        }

        this.setState(state);
    }

    renderCompanyData() {

        return (
            <div style={{ marginBottom: 50, width: '100%', height: 550, overflowY: 'scroll' }}>
                <FormLabel style={{ paddingBottom: 18, paddingTop: 18 }} component="legend">Dados</FormLabel>
                <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} value={this.state.name} onChange={(text) => { this.setState({ name: text }) }} label={'Nome da Empresa'} />
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <InputMask onChange={(v) => { this.setState({ ddd: v }) }} mask="99" value={this.state.ddd}>
                        <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={2} label={'DDD'} />
                    </InputMask>
                    <div style={{ marginLeft: 3, marginRight: 3 }} />
                    <InputMask onChange={(v) => { this.setState({ phone: DataHelper.treatPhone(v) }) }} mask="9 9999-99999999" maskPlaceholder={''} value={this.state.phone}>
                        <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={9} label={'Telefone'} />
                    </InputMask>
                </div>
                <FormLabel style={{ marginTop: 18, fontWeight: '800' }} component="legend">Tipo de Estabelecimento</FormLabel>
                <RadioGroup style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }} value={this.state.establishment_type} onChange={(evt) => { this.setState({ establishment_type: evt.target.value }) }}>
                    <FormControlLabel value="academy" control={<Radio style={{ color: Colors.primary }}/>} label="Academia" />
                    <FormControlLabel value="club" control={<Radio style={{ color: Colors.primary }}/>} label="Clube" />
                </RadioGroup>
                <FormLabel style={{ marginTop: 18, fontWeight: '800' }} component="legend">Tipo de Empresa</FormLabel>
                <RadioGroup style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }} value={this.state.type} onChange={(evt) => { this.setState({ type: evt.target.value, cnpj: '', cpf: '', inputError: false }) }}>
                    <FormControlLabel value="pf" control={<Radio style={{ color: Colors.primary }}/>} label="Pessoa Física" />
                    <FormControlLabel value="pj" control={<Radio style={{ color: Colors.primary }}/>} label="Pessoa Jurídica" />
                </RadioGroup>
                { this.renderDocument() }
                <FormLabel style={{ paddingBottom: 18, paddingTop: 18 }} component="legend">Endereço</FormLabel>

                <LocationPicker callback={this.handleMapChange} initialCenter={GeographicHelper.getLatLngFromGeopoint(this.state.coordinates)} />

                <InputMask disabled={true} onChange={(v) => { this.setState({ zipcode: v }) }} mask="99999-999" value={this.state.zipcode}>
                    <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={8} label={'Cep'} />
                </InputMask>

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <DefaultInput value={'Brasil'} disabled={true} label={'País'} />
                    <div style={{ paddingRight: 5, paddingLeft: 5 }} />
                    <DefaultInput disabled onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} value={this.state.state} onChange={(text) => { this.setState({ state: text }) }} label={'Estado'} />
                </div>

                <DefaultInput disabled onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} value={this.state.city} onChange={(text) => { this.setState({ city: text }) }} label={'Cidade'} />

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} value={this.state.street} onChange={(text) => { this.setState({ street: text }) }} label={'Rua'} />
                    <div style={{ paddingRight: 5, paddingLeft: 5 }} />
                    <div style={{ paddingRight: 5, paddingLeft: 5, width: '50%' }}>
                        <InputMask onChange={(v) => { this.setState({ number: v }) }} mask="999999999999" maskPlaceholder={''} alwaysShowMask={false} value={this.state.number}>
                            <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} label={'Número'} />
                        </InputMask>
                    </div>
                    <div style={{ paddingRight: 5, paddingLeft: 5, width: '50%' }}>
                        <DefaultInput value={this.state.complemento} onChange={(text) => { this.setState({ complemento: text }) }} label={'Complemento'} />
                    </div>
                </div>

                <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} value={this.state.neighborhood} onChange={(text) => { this.setState({ neighborhood: text }) }} label={'Bairro'} />

                <FormLabel style={{ paddingBottom: 18, paddingTop: 18 }} component="legend">Informações de Login</FormLabel>
                <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} value={this.state.userName} onChange={(text) => { this.setState({ userName: text }) }} label={'Seu Nome'} />
                <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} email={true} value={this.state.email} onChange={(text) => { this.setState({ email: text.toLowerCase() }) }} label={'E-mail'} />
                <InputLabel>Caso já tenha cadastro no aplicativo para celular, use um e-mail diferente do e-mail utilizado no aplicativo.</InputLabel>
                <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} value={this.state.password} type={'password'} tooltip={'Dica: combine sempre letras, números e símbolos para criar uma senha forte'} onChange={(text) => { this.setState({ password: text }) }} label={'Senha'} />
                <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} value={this.state.passwordConfirm} type={'password'} onChange={(text) => { this.setState({ passwordConfirm: text }) }} label={'Confirme a Senha'} />

                {this.renderTerms()}
            </div>
        )
    }

    renderFinish() {

        return (
            <div style={{ marginBottom: 50, width: '100%', height: 550, overflowY: 'scroll', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img style={{ height: 225 }} src={process.env.PUBLIC_URL + '/ilustrations/winners.png'}/>
                <h1 style={{ textAlign: 'center' }}>Sucesso!<br/>Seja bem vindo ao Ace</h1>
                <p style={{ textAlign: 'center' }}>Um e-mail de confirmação foi enviado a sua empresa com mais detalhes.</p>Agora você já pode efetuar login e aproveitar as funcionalidades da plataforma.
            </div>
        )
    }

    renderIcon(step) {

        return (
            <div style={{backgroundColor: this.state.activeStep < step ? 'lightgrey' : Colors.primary, width:20, height: 20, padding: 5, fontSize: 14, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{ color: '#FFFFFF' }}>{this.state.activeStep < step + 1 ? (step + 1) : <CheckIcon style={{ fontSize: 20 }}/>}</div>
            </div>
        )
    }

    render() {
        return (
            <Container style={{backgroundColor: Colors.primary, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundImage: `url(${process.env.PUBLIC_URL + '/background.jpg'})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} maxWidth={false}>
                <div style={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Card style={{ padding: 30, width: 1200, height: 800, filter: this.state.loading ? 'blur(5px)' : '', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <img style={{paddingBottom: 15, paddingTop: 15, height: 85,}} src={process.env.PUBLIC_URL + '/logo.png'} /> 
                        <span style={{ paddingBottom: 25, textAlign: 'center', color: Colors.formLabel, fontSize: 22, fontWeight: '600' }}>Experimente grátis por 7 dias</span>
                        <Stepper style={{ width: '100%' }} activeStep={this.state.activeStep} alternativeLabel>
                            {this.state.steps.map((label, key) => (
                                <Step key={label}>
                                    <StepLabel icon={this.renderIcon(key)}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>

                        { this.state.activeStep === 0 && this.renderCompanyData() }
                        { this.state.activeStep === 1 && this.renderFinish() }

                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <Button style={{ width: '50%', marginRight: 5, fontWeight: 'bold' }} variant="contained" disabled={this.state.activeStep === 0 || this.state.activeStep === this.state.steps.length - 1} onClick={() => { this.handleBack() }}>{'Voltar'}</Button>
                            <Button style={{ width: '50%', marginLeft: 5, backgroundColor: Colors.primary, fontWeight: 'bold' }} variant="contained" color="primary" onClick={() => { this.state.activeStep === this.state.steps.length - 1 ? window.location.href = '/' : this.handleNext() }}>{this.state.activeStep === this.state.steps.length - 1 ? 'Voltar para Tela de Login' : 'Avançar'}</Button>
                        </div>
                    </Card>
                    {this.state.loading ? <DefaultLoader css={{ position: 'absolute', top: '38vh', left: '49%'}}/> : null}
                </div>
                <ToastContainer style={{}}/>
            </Container>
        );
    }
}

export default withRouter(TrialPage);
 