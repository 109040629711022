import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import Colors from '../constants/Colors';
import Firestore from '../api/firebase/Firestore';
import DefaultLoader from './DefaultLoader';
import FileCopy from '@material-ui/icons/FileCopy';
import { toast } from 'react-toastify';
import moment from "moment-timezone";
import SessionHelper from '../helper/SessionHelper';
import Pagarme from '../api/pagarme/Pagarme';

export default class DayUseUserCard extends Component {
    state = {
        loading: true,
        loadingOrder: true,
        user: {},
        order: {}
    }

    async componentDidMount() {
        await this.getUser();
        this.getOrder();

        this.setState({ loading: false });
    }

    async getUser() {
        if (this.props.dayUse && this.props.userId) {
            let query = await Firestore.getDoc('user', this.props.userId);

            if (query.exists) {
                let user = { ...query.data(), id: query.id };
                user.isStudent = user.id_companies && user.id_companies.length > 0 && user.id_companies.includes(SessionHelper.getData().id_company);
                
                if(this.props.searchCallback) {
                    this.props.searchCallback(user);
                }
                
                this.setState({ user });
            }
        }
    }

    async getOrder() {
        if (this.props.dayUse && this.props.userId) {
            if (this.props.dayUse.participant_options[this.props.userId] && this.props.dayUse.participant_options[this.props.userId].id_order) {
                let query = await Firestore.getDoc('order', this.props.dayUse.participant_options[this.props.userId].id_order);

                if (query.exists) {
                    let order = { ...query.data(), id: query.id };

                    if (order.id_transaction_pagarme) {
                        let response = await Pagarme.get(`/transactions/${order.id_transaction_pagarme}`, {});

                        if (!response.errors) {
                            if (response.status && !order.status) order.status = response.status;
                        }
                    }

                    this.setState({ order });
                }
            }
        }

        this.setState({ loadingOrder: false });
    }

    getSportLookup() {
        return {
            'tennis': 'Tennis',
            'padel': 'Padel',
            'beach_tennis': 'Beach Tennis',
            'squash': 'Squash',
            'badminton': 'Badminton',
            'beach_volley': 'Vôlei de Praia',
            'foot_volley': 'Futevôlei',
            'others': 'Outros'
        }
    }

    render() {
        return this.state.loading ? <div style={style.card}><DefaultLoader size={48} css={{ marginTop: 20 }} /></div> : this.state.user.id ? (
            <div style={style.card}>
                <img style={{ height: 60, width: 60, borderRadius: 60 / 2, boxShadow: 'rgba(50, 50, 50, 0.47) 1px 1px 10px 0px' }} src={this.state.user.photo ? this.state.user.photo : process.env.PUBLIC_URL + '/empty_avatar.png'} />
                <div style={{ marginLeft: 20, display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                    <b style={{ fontSize: 16, marginTop: 8 }}>{this.state.user.name}</b>
                    {this.state.loadingOrder ? <p style={{ cursor: 'pointer', marginTop: 3 }}>Carregando...</p> : this.state.order && this.state.order.title ? <p onClick={(e) => { navigator.clipboard.writeText(this.state.order.title); toast.info('Nº do pedido copiado!'); e.stopPropagation(); }} style={{ cursor: 'pointer', marginTop: 3 }}>{`${this.state.order.title}`}  <FileCopy style={{ marginLeft: 4, fontSize: 16 }} /></p> : <p style={{ cursor: 'pointer', marginTop: 3 }}>Pedido não encontrado</p>}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 'auto', marginRight: 25, width: 216 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                        <p style={{ fontSize: 13 }}>Inscrito em {moment(this.props.dayUse.participant_options[this.props.userId].created_at.toDate()).format('DD/MM/YYYY HH:mm')}</p>
                        <p style={{ fontSize: 13, marginTop: -5 }}>Esporte selecionado: <b>{this.getSportLookup()[this.props.dayUse.participant_options[this.props.userId].sport]}</b></p>
                    </div>
                </div>
                <div style={{ display: 'column', flexDirection: 'row', alignItems: 'center', fontSize: 12, marginTop: 8, width: 150 }}>
                    {this.state.user.isStudent ? <div style={{ backgroundColor: Colors.success, ...style.customTextBox }}>Aluno</div> : <div style={{ backgroundColor: Colors.danger, ...style.customTextBox }}>Não Aluno</div>}
                    {this.state.loadingOrder ? <div style={{ backgroundColor: Colors.primary, ...style.customTextBox }}>Carregando...</div> : this.state.order && this.state.order.status === 'paid' ? <div style={{ backgroundColor: Colors.success, ...style.customTextBox }}>Pago</div> : <div style={{ backgroundColor: Colors.danger, ...style.customTextBox }}>Pagamento Pendente</div>}
                </div>
            </div>
        ) : null;
    }

}

let style = {
    card: {
        width: `100%`,
        height: 120,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        paddingLeft: 10,
        paddingRight: 10,
        borderBottom: '1px solid lightgrey'
    },
    customTextBox: {
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 10,
        paddingRight: 10,
        color: 'white',
        fontWeight: 'bold',
        marginLeft: 0,
        borderRadius: 50,
        marginBottom: 5,
        textAlign: 'center'
    }
}