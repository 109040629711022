import React, { Component } from 'react';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from "moment-timezone";
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import RepeatIcon from '@material-ui/icons/Repeat';
import LoopIcon from '@material-ui/icons/Loop';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import ClipLoader from "react-spinners/ClipLoader";
import Firestore from '../api/firebase/Firestore';
import Colors from '../constants/Colors';
import Brightness4 from '@material-ui/icons/Brightness4';
import TournamentHelper from '../helper/TournamentHelper';
import DefaultLoader from './DefaultLoader';
import UserHelper from '../helper/UserHelper';
import PhoneIphone from '@material-ui/icons/PhoneIphone';
import Computer from '@material-ui/icons/Computer';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        maxWidth: '100%',
        fontSize: 13
    }
}))(Tooltip);

export default class CalendarEvent extends Component {

    state = {
        hover: false,
        loading: true,
        loadingStudents: true,
        collection: null,
        doc: null,
        students: [],
        teacher: null,
        tournament: null,
        court: null,
        order: null,
    }

    componentDidMount() {
        if (this.props.view === 'day' && this.props.event.type === 'court_rent') { this.getStudents() }
        if (this.props.showData) { this.getEventData() }
    }

    async getEventData() {

        await this.getCollection();

        const collection = this.state.collection || 'lesson';

        let doc = null;
        let query = await Firestore.getDoc(collection, this.props.event.id);

        if (query.exists) {
            doc = { ...query.data(), id: query.id };
        } else if (this.props.event && this.props.event.eventDoc) {
            if (this.props.event.eventDoc) doc = this.props.event.eventDoc;
        }

        if (this.props.event.id_teacher) {

            await this.getTeacher();
        }

        if (this.props.event.id_order) {

            await this.getOrder();
        }

        if (this.props.event.id_tournament) {

            await this.getTournament();
        }

        if (this.props.event.id_court) {

            await this.getCourt();
        }

        await this.getStudents();

        this.setState({ loading: false, doc: doc });
    }

    async getCollection() {

        let collection = 'lesson';
        let type = this.props.event.type;

        if (type === 'court_rent') {

            collection = 'court_rent';

        } else if (type === 'tournament') {

            collection = 'tournament_event';

        } else if (type === 'experimental_lesson') {

            collection = 'experimental_lesson';

        } else if (type === 'private_lesson') {

            collection = 'private_lesson';

        } else if (type === 'day_use') {

            collection = 'day_use';
        }

        this.setState({ collection });
    }

    async getStudents() {
        let docs = [];

        if (this.props.event.id_student && this.props.event.id_student[0]) {
            let query = await Firestore.customQuery('user').where(Firestore.idField(), 'in', this.props.event.id_student).get();

            query.forEach((doc, key) => {

                let data = { ...doc.data(), id: doc.id };

                docs.push(data);
            });
        }

        this.setState({ students: docs, loadingStudents: false });
    }

    async getCourt() {
        if (this.props.event.id_court) {
            let query = await Firestore.getDoc('court', this.props.event.id_court);

            if (query.exists) {

                this.setState({ court: { ...query.data(), id: query.id } });
            }
        }
    }

    async getOrder() {

        let query = await Firestore.getDoc('order', this.props.event.id_order);

        if (query.exists) {

            this.setState({ order: { ...query.data(), id: query.id } });
        }
    }

    async getTeacher() {

        let query = await Firestore.getDoc('user', this.props.event.id_teacher);

        if (query.exists) {

            this.setState({ teacher: { ...query.data(), id: query.id } });
        }
    }

    async getTournament() {

        let query = await Firestore.getDoc('tournament', this.props.event.id_tournament);

        if (query.exists) {

            this.setState({ tournament: { ...query.data(), id: query.id } });
        }
    }

    async handleHover() {

        if (!this.props.readOnly) {

            await this.setState({ hover: true });
            await this.getEventData();
        }
    }

    tooltip() {

        return this.state.loading ? this.renderLoading() : (

            this.state.collection === 'lesson' ? this.renderLessonTooltip(true)

                : this.state.collection === 'court_rent' ? this.renderCourtRentTooltip(true)

                    : this.state.collection === 'tournament_event' ? this.renderTournamentMatchTooltip(true)

                        : this.state.collection === 'experimental_lesson' ? this.renderExperimentalLessonTooltip(true)

                            : this.state.collection === 'private_lesson' ? this.renderPrivateLessonTooltip(true)

                                : this.state.collection === 'day_use' ? this.renderDayUseTooltip(true)

                                    : null
        )
    }

    renderCourtRentTooltip(isTooltip) {
        if(!this.state.doc) return null;

        let students = this.state.students.map((student, key) => {

            let user = this.state.students.find((item => item.id === student.id));

            return user.name;

        }).join(', ');

        return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 8, paddingBottom: 8 }}>
                <div><b>{'Cliente: '}</b>{students ? students : this.props.event.manual_info && this.props.event.manual_info.name ? this.props.event.manual_info.name : this.props.event.manual_info && this.props.event.manual_info[0] && this.props.event.manual_info[0].name ? this.props.event.manual_info.map(user => (user.name)).join(`, `) : 'Não Informado'}</div>
                <div><b>{'Jogadores: '}</b>{this.state.doc.playerCount || `Não Encontrado`}</div>
                <div><b>{'Quadra: '}</b>{this.state.court.name}</div>
                {this.state.order ? <div><b>{'Pedido: '}</b>{this.state.order.title}</div> : null}
                {isTooltip || this.props.order ? <div><b>{'Início: '}</b>{moment(this.state.doc.start.toDate()).format('DD/MM/YYYY HH:mm')}</div> : null}
                {isTooltip || this.props.order ? <div><b>{'Fim: '}</b>{moment(this.state.doc.end.toDate()).format('DD/MM/YYYY HH:mm')}</div> : null}
            </div>
        )
    }

    renderCourtRentStudentsNames() {
        if (this.state.loadingStudents) {
            return <div style={{ display: 'flex', flexDirection: 'row' }}><DefaultLoader size={14} color={Colors.background} css={{ marginRight: 5 }} /> Jogadores...</div>;
        }

        let students = this.state.students.map((student, key) => {
            let user = this.state.students.find((item => item.id === student.id));
            return this.state.students.length > 1 ? UserHelper.treatName(user.name) : user.name;
        }).join(', ');

        return (
            <div>
                <div>{students ? students : this.props.event.manual_info && this.props.event.manual_info.name ? this.props.event.manual_info.name : this.props.event.manual_info && this.props.event.manual_info[0] && this.props.event.manual_info[0].name ? this.props.event.manual_info.map(user => (this.props.event.manual_info.length > 1 ? UserHelper.treatName(user.name) : user.name)).join(`, `) : 'Jogador não informado'}</div>
            </div>
        )
    }

    renderLessonTooltip(isTooltip) {
        let students = this.state.students.map((student, key) => {
            let user = this.state.students.find((item => item.id === student.id));
            return (this.state.students.length > 1 ? UserHelper.treatName(user.name) : user.name) + ` (${this.state.doc.accepted && this.state.doc.accepted.includes(student.id) ? '✓' : this.state.doc.refused && this.state.doc.refused.includes(student.id) ? 'X' : '?'})`;
        }).join(', ');

        return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 8, paddingBottom: 8 }}>
                <div><b>{'Alunos: '}</b>{students}</div>
                {this.props.event.category_label ? <div><b>{'Categorias: '}</b>{this.props.event.category_label}</div> : null}
                <div><b>{'Professor: '}</b>{this.state.teacher.name}</div>
                <div><b>{'Quadra: '}</b>{this.state.court.name}</div>
                {isTooltip || this.props.order ? <div><b>{'Início: '}</b>{moment(this.state.doc.start.toDate()).format('DD/MM/YYYY HH:mm')}</div> : null}
                {isTooltip || this.props.order ? <div><b>{'Fim: '}</b>{moment(this.state.doc.end.toDate()).format('DD/MM/YYYY HH:mm')}</div> : null}
                {this.props.event.tags && isTooltip ? this.renderTags() : null}
            </div>
        )
    }

    renderExperimentalLessonTooltip(isTooltip) {

        let students = this.state.students.map((student, key) => {

            let user = this.state.students.find((item => item.id === student.id));

            return user.name;

        }).join(', ');

        return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 8, paddingBottom: 8 }}>
                <div><b>{'Aluno: '}</b>{students || this.state.doc.name}</div>
                <div><b>{'Professor: '}</b>{this.state.teacher.name}</div>
                <div><b>{'Quadra: '}</b>{this.state.court.name}</div>
                {this.state.order ? <div><b>{'Pedido: '}</b>{this.state.order.title}</div> : null}
                {isTooltip || this.props.order ? <div><b>{'Início: '}</b>{moment(this.state.doc.start.toDate()).format('DD/MM/YYYY HH:mm')}</div> : null}
                {isTooltip || this.props.order ? <div><b>{'Fim: '}</b>{moment(this.state.doc.end.toDate()).format('DD/MM/YYYY HH:mm')}</div> : null}
            </div>
        )
    }

    renderPrivateLessonTooltip(isTooltip) {

        let students = this.state.students.map((student, key) => {

            let user = this.state.students.find((item => item.id === student.id));

            return user.name;

        }).join(', ');

        return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 8, paddingBottom: 8 }}>
                <div><b>{'Aluno: '}</b>{students || this.state.doc.name}</div>
                <div><b>{'Professor: '}</b>{this.state.teacher.name}</div>
                <div><b>{'Quadra: '}</b>{this.state.court.name}</div>
                {this.state.order ? <div><b>{'Pedido: '}</b>{this.state.order.title}</div> : null}
                {isTooltip || this.props.order ? <div><b>{'Início: '}</b>{moment(this.state.doc.start.toDate()).format('DD/MM/YYYY HH:mm')}</div> : null}
                {isTooltip || this.props.order ? <div><b>{'Fim: '}</b>{moment(this.state.doc.end.toDate()).format('DD/MM/YYYY HH:mm')}</div> : null}
            </div>
        )
    }

    renderDayUseTooltip(isTooltip) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 8, paddingBottom: 8 }}>
                <div><b>{'Participantes: '}</b>{this.state.doc.participants.length || 0} inscritos</div>
                <div><b>{'Quadras: '}</b>{this.state.doc.id_courts.length || 0} quadras</div>
                <div><b>{'Ativo: '}</b>{this.state.doc.active ? `Sim` : `Não (Inscrições travadas manualmente)`}</div>
                {isTooltip ? <div><b>{'Início: '}</b>{moment(this.state.doc.start.toDate()).format('DD/MM/YYYY HH:mm')}</div> : null}
                {isTooltip ? <div><b>{'Fim: '}</b>{moment(this.state.doc.end.toDate()).format('DD/MM/YYYY HH:mm')}</div> : null}
            </div>
        )
    }

    renderTournamentMatchTooltip(isTooltip) {

        let players = this.state.students.map((student, key) => {

            let user = this.state.students.find((item => item.id === student.id));

            return user.name;

        }).join(', ');

        return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 8, paddingBottom: 8 }}>
                <div><b>{'Jogadores: '}</b>{players || `Ainda não definido`}</div>
                {isTooltip || this.props.order ? <div><b>{'Início: '}</b>{moment(this.state.doc.start.toDate()).format('DD/MM/YYYY HH:mm')}</div> : null}
                {isTooltip || this.props.order ? <div><b>{'Fim: '}</b>{moment(this.state.doc.end.toDate()).format('DD/MM/YYYY HH:mm')}</div> : null}
                <div><b>{'Torneio: '}</b>{this.state.tournament ? this.state.tournament.name : ``}</div>
                <div><b>{'Quadra: '}</b>{this.state.court ? this.state.court.name : this.props.event.court}</div>
                <div><b>{'Categoria: '}</b>{this.state.doc.indexes && this.state.doc.indexes.category >= 0 ? this.state.tournament.category[this.state.doc.indexes.category].title : `Desconhecida`}</div>
                <div><b>{'Classe: '}</b>{this.state.doc.indexes && this.state.doc.indexes.class >= 0 ? this.state.tournament.category[this.state.doc.indexes.category].classes[this.state.doc.indexes.class].title : `Desconhecida`}</div>
                <div><b>{'Rodada: '}</b>{this.state.doc.round + 1}</div>

                {this.state.doc.indexes && this.state.doc.indexes.category >= 0 &&
                    this.state.doc.indexes.class >= 0 && this.state.doc.indexes.match >= 0
                    && this.state.doc.indexes.round >= 0 ?
                    <div><b>{'Identificador: '}</b>{TournamentHelper.getMatchAlias(this.state.doc.indexes.gameAlias)}</div>
                    : null}
            </div>
        )
    }

    renderTags() {

        return (
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 8 }}>
                {
                    this.props.event.tags.map((tag, key) => {

                        return <div style={{ marginRight: 5, backgroundColor: tag.color || Colors.primary, paddingTop: 3, paddingBottom: 3, paddingLeft: 8, paddingRight: 8, fontWeight: 'bold', borderRadius: 5, color: 'white' }}>{tag.label}</div>
                    })
                }
            </div>
        )
    }

    renderLoading() {

        return (
            <div style={{ display: 'flex', justifyContent: 'center', padding: 25 }}>
                <ClipLoader
                    size={15}
                    color={'white'}
                    loading={true}
                />
            </div>
        )
    }

    renderIcon() {
        return this.props.event.type === 'tournament' ? <EmojiEventsIcon style={{ fontSize: 20, marginRight: 5 }} /> :
            this.props.event.type === 'court_rent' ? <AttachMoneyIcon style={{ fontSize: 20, marginRight: 5 }} /> :
                this.props.event.type === 'experimental_lesson' ? <img src={process.env.PUBLIC_URL + '/icons/flask-grey.png'} style={{ height: 15, width: 18, marginRight: 8 }} /> :
                    this.props.event.type === 'private_lesson' ? <SportsTennisIcon style={{ fontSize: 20, marginRight: 5 }} /> :
                        this.props.event.type === 'day_use' ? <Brightness4 style={{ fontSize: 20, marginRight: 5 }} /> : <SportsTennisIcon style={{ fontSize: 20, marginRight: 5 }} />
    }

    renderTitle() {
        return this.props.event.type === 'tournament' ? 'Partida de Torneio' :
            this.props.event.type === 'court_rent' ? `Aluguel de Quadra` :
                this.props.event.type === 'experimental_lesson' ? 'Aula Experimental' :
                    this.props.event.type === 'private_lesson' ? 'Aula Avulsa' :
                        this.props.event.type === 'day_use' ? 'Day use' : `Aula ${this.props.view === 'day' && this.props.event.student_names ? `com ${this.props.event.student_names}` : this.props.event.teacher ? `com ${this.props.event.teacher}` : ``}`;
    }

    renderEventData() {

        return this.state.loading ? this.renderLoading() : (

            this.state.collection === 'lesson' ? this.renderLessonTooltip(false)

                : this.state.collection === 'court_rent' ? this.renderCourtRentTooltip(false)

                    : this.state.collection === 'tournament_event' ? this.renderTournamentMatchTooltip(false)

                        : this.state.collection === 'experimental_lesson' ? this.renderExperimentalLessonTooltip(false)

                            : this.state.collection === 'private_lesson' ? this.renderPrivateLessonTooltip(false)

                                : this.state.collection === 'day_use' ? this.renderDayUseTooltip(false)

                                    : null
        )
    }

    renderEventHasOrder() {

        return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>

                {
                    <div style={{ backgroundColor: this.props.event.id_order ? Colors.dark : Colors.contrast, width: 24, height: 24, borderRadius: 24 / 2, padding: 3, marginRight: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {this.props.event.id_order ? <PhoneIphone style={{ fontSize: 16 }} /> : <Computer style={{ fontSize: 16 }} />}
                    </div>
                }
            </div>
        )
    }

    renderEventHasRacquets() {

        if(this.props.event && this.props.event.racquetCount && this.props.event.racquetCount > 0) {
            return (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
    
                    {
                        <div style={{ backgroundColor: Colors.success, width: 42, height: 24, borderRadius: 24 / 2, padding: 3, marginRight: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {this.props.event.racquetCount && this.props.event.racquetCount > 0 ? <span style={{ fontSize: 16, marginRight: 3 }}>{this.props.event.racquetCount}</span> : null}
                            {this.props.event.racquetCount && this.props.event.racquetCount > 0 ? <SportsTennisIcon style={{ fontSize: 16 }} /> : null}
                        </div>
                    }
                </div>
            )
        } else {
            return null;
        }
        
    }

    renderUnpaidSubscriptions() {

        return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>

                {
                    this.props.event.unpaid_subscriptions.map(() => {

                        return (
                            <div style={{ backgroundColor: Colors.error, width: 24, height: 24, borderRadius: 24 / 2, padding: 3, marginRight: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <AttachMoneyIcon style={{ fontSize: 16 }} />
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    renderReplacementStudents() {
        return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>

                {
                    this.props.event.replacement_students.map(() => {

                        return (
                            <div style={{ backgroundColor: Colors.success, width: 24, height: 24, borderRadius: 24 / 2, padding: 3, marginRight: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <LoopIcon style={{ fontSize: 16 }} />
                            </div>
                        )
                    })
                }

            </div>
        )
    }

    isRepetitionEvent() {
        let isRepetitionEvent = false;

        if (this.props.event.repeat_until) {
            let startOfDay = moment(this.props.event.repeat_until.toDate()).startOf('day');
            let endOfDay = moment(this.props.event.repeat_until.toDate()).endOf('day');

            if (moment(this.props.event.start).isBetween(startOfDay, endOfDay, 'minutes', '[]') && !this.props.event.renewed_until) {
                isRepetitionEvent = true;
            }
        }

        return isRepetitionEvent;
    }

    renderRepetitionEvent() {

        return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {this.isRepetitionEvent() ?
                    <div style={{ backgroundColor: `rgb(237, 97, 17)`, width: 24, height: 24, borderRadius: 24 / 2, padding: 3, marginRight: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <RepeatIcon style={{ fontSize: 16 }} />
                    </div>
                    : null}
            </div>
        )
    }

    renderWarnings() {

        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'center', height: 35 }}>

                {this.props.view !== 'month' && this.props.event.unpaid_subscriptions && this.props.event.unpaid_subscriptions.length ? this.renderUnpaidSubscriptions() : null}
                {this.props.view !== 'month' && this.props.event.replacement_students && this.props.event.replacement_students.length ? this.renderReplacementStudents() : null}
                {this.props.view !== 'month' && this.props.event.type === 'court_rent' ? this.renderEventHasOrder() : null}
                {this.props.view !== 'month' && this.props.event.type === 'court_rent' ? this.renderEventHasRacquets() : null}
                {this.props.view !== 'month' && this.props.event.repeat_until ? this.renderRepetitionEvent() : null}

            </div>
        )
    }

    render() {
        return (
            <div style={{ height: '100%' }} onMouseOut={() => { this.setState({ hover: false }) }} onMouseOver={() => { this.handleHover() }}>
                <HtmlTooltip open={this.state.hover} title={this.props.hideTooltip ? null : this.tooltip()} placement={'bottom-start'}>
                    <div>
                        <div style={{ position: 'relative' }} {...this.props} title={''}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 0 }}>
                                {this.props.event.type === 'day_use' ? <div className={`rbc-event-label`}>{moment(this.props.event.start).format('HH:mm')} - {moment(this.props.event.end).format('HH:mm')}</div> : null}

                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 0, fontWeight: 'bold', flexWrap: 'wrap' }}>
                                    {this.renderIcon()}
                                    {this.renderTitle()}
                                </div>

                                {this.props.view !== 'month' ?

                                    <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 5 }}>
                                        {this.props.event.court ? <div style={{ paddingBottom: 10 }}>{this.props.event.court}</div> : null}
                                        {this.props.view === 'day' && this.props.event.teacher ? <div style={{ paddingBottom: 10 }}>{`Professor ${this.props.event.teacher}`}</div> : null}
                                        {this.props.view === 'day' && this.props.event.type === 'court_rent' ? this.renderCourtRentStudentsNames() : null}
                                        {this.props.event.type === 'day_use' ? <div style={{ marginTop: -12 }}>{this.props.event.participants.length} inscritos</div> : null}
                                        {this.props.event.type === 'tournament' && this.props.event.indexes ? <div>{TournamentHelper.getMatchAlias(this.props.event.indexes.gameAlias)}</div> : null}
                                        {this.props.view !== 'month' ? this.renderWarnings() : null}

                                    </div>

                                    : null}

                                {this.props.showData ? this.renderEventData() : null}

                            </div>
                        </div>
                    </div>
                </HtmlTooltip>
            </div>
        )
    }
}
