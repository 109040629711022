import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button, FormLabel, RadioGroup, Radio, FormControlLabel, Tooltip, IconButton } from '@material-ui/core';
import PhotoIcon from '@material-ui/icons/Photo';
import HistoryIcon from '@material-ui/icons/History';
import Firestore from '../api/firebase/Firestore';
import IosSwitch from '../components/IosSwitch';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import CurrencyInput from '../components/CurrencyInput';
import ImagePicker from '../components/ImagePicker';
import moment from "moment-timezone";
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import LabelIcon from '@material-ui/icons/Label';
import PlaceIcon from '@material-ui/icons/Place';
import InputMask from "react-input-mask";
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import InventoryHelper from '../helper/InventoryHelper';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import CallMadeIcon from '@material-ui/icons/CallMade';
import RelationField from '../components/grid/RelationField';
import DefaultSelect from '../components/DefaultSelect';
import CurrencyHelper from '../helper/CurrencyHelper';
import HeaderActionsWrapper from '../components/HeaderActionsWrapper';

export default class ProductPage extends Component {

    state = {
        docs: [],
        locationDocs: [],
        categoryDocs: [],
        name: '',
        code: '',
        quantity_available: 0,
        low_inventory_reminder: 0,
        type: 'product',
        price: null,
        rawPrice: null,
        cost: null,
        rawCost: null,
        images: [],
        location: '',
        category: '',
        loading: true,
        loadingModal: false,
        photoModal: false,
        addModal: false,
        locationModal: false,
        categoryModal: false,
        historyModal: false,
        history: [],
        active: true,
        inputError: false,
        addLocation: false,
        addCategory: false,
        addLocationName: '',
        addCategoryName: '',
    }

    async componentDidMount() {

        await this.getLocations();
        await this.getCategories();
        await this.getDocs();

        this.setState({ loading: false });
    }

    async getDocs() {

        let query = await Firestore.customQuery('product').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;
            data.price = data.price / 100;
            data.cost = data.cost / 100;

            docs.push(data);
        });

        this.setState({ docs: docs });
    }

    async getLocations() {

        let query = await Firestore.customQuery('product_location').where('id_company', '==', SessionHelper.getData().id_company).orderBy('date', 'desc').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = { ...doc.data(), id: doc.id };
            docs.push(data);
        });

        this.setState({ locationDocs: docs });
    }

    async getCategories() {

        let query = await Firestore.customQuery('product_category').where('id_company', '==', SessionHelper.getData().id_company).orderBy('date', 'desc').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = { ...doc.data(), id: doc.id };
            docs.push(data);
        });

        this.setState({ categoryDocs: docs });
    }

    async productExists(code) {

        let query = await Firestore.customQuery('product').where('id_company', '==', SessionHelper.getData().id_company).where('code', '==', code).limit(1).get();
        return query.size === 1 ? true : false;
    }

    async addProduct() {

        if (this.state.name && this.state.rawPrice) {

            if (!(await this.productExists(this.state.code))) {

                try {

                    this.setState({ loadingModal: true });
    
                    let id = await Firestore.getId('product');

                    let data = {
                        name: this.state.name,
                        code: this.state.code,
                        type: this.state.type,
                        price: this.state.rawPrice * 100,
                        cost: this.state.rawCost * 100,
                        quantity_available: 0,
                        low_inventory_reminder: parseInt(this.state.low_inventory_reminder) || 0,
                        images: this.state.images,
                        id_location: this.state.location,
                        id_category: this.state.category,
                        active: this.state.active,
                        id_company: SessionHelper.getData().id_company,
                        date: new Date(),
                    };
    
                    await Firestore.insert(data, 'product', id);

                    if (this.state.type === 'product' && parseInt(this.state.quantity_available) > 0) {

                        await InventoryHelper.addProductMovement(id, parseInt(this.state.quantity_available), 'entry', 'Entrada inicial do produto', true);
                    }
    
                    toast.success("Cadastrado com sucesso");
    
                    await this.setState({ loadingModal: false, addModal: false, name: '', code: '', price: null, rawPrice: null, cost: null, rawCost: null, quantity_available: 0, low_inventory_reminder: 0, images: [], id_location: '', id_category: '' });
                    await this.getDocs();
    
                } catch (error) {
                    toast.error("Houve um problema ao cadastrar");
                    this.setState({ loadingModal: false });
                }

            } else {

                toast.warn('Já existe um produto com o mesmo código cadastrado');
            }

        } else {

            toast.warn("Preencha ao menos o Nome e Valor Unitário", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    addModal() {
        return (
            <div>
                <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ name: v }) }} label={'Nome'}/>
                <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ code: v }) }} label={'Código'}/>
                <CurrencyInput required onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Valor Unitário'} onChange={(values) => { const {formattedValue, value} = values; this.setState({price: formattedValue, rawPrice: value}) }} value={this.state.price}/>
                <CurrencyInput onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Custo'} onChange={(values) => { const {formattedValue, value} = values; this.setState({cost: formattedValue, rawCost: value}) }} value={this.state.cost}/>
                <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                    <DefaultSelect value={this.state.location} id={'location_selection'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ location: v.target.value }) }} docs={this.state.locationDocs} label={'Local'} />
                </div>
                <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                    <DefaultSelect value={this.state.category} id={'location_selection'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ category: v.target.value }) }} docs={this.state.categoryDocs} label={'Categoria'} />
                </div>
                <FormLabel style={{ marginTop: 18, fontWeight: '800' }} component="legend">Tipo</FormLabel>
                <div style={{ flexDirection: 'row', paddingTop: 10 }}>
                    <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} value={this.state.type} onChange={(evt) => { this.setState({ type: evt.target.value }) }}>
                        <FormControlLabel value="product" control={<Radio style={{ color: Colors.primary }}/>} label="Produto" />
                        <FormControlLabel value="service" control={<Radio style={{ color: Colors.primary }}/>} label="Serviço" />
                    </RadioGroup>
                </div>
                
                { this.state.type === 'product' ? 
                
                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                        <InputMask onChange={(v) => { this.setState({ quantity_available: v }) }} mask="999999" maskPlaceholder={''} value={this.state.quantity_available}>
                            <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={1} label={'Quantidade Disponível'} />
                        </InputMask>
                        <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', fontWeight: 'bold', marginTop: 8, marginBottom: 20 }}>
                            <InfoRoundedIcon style={{ fontSize: 40, marginRight: 8 }} />
                            <div>{`Essa quantidade será usada para gerar uma movimentação inicial no estoque. Deixe "0" ou vazio caso não queira.`}</div>
                        </div>
                        <InputMask onChange={(v) => { this.setState({ low_inventory_reminder: v }) }} mask="999" maskPlaceholder={''} value={this.state.low_inventory_reminder}>
                            <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={1} label={'Alerta de Estoque Baixo'} />
                        </InputMask>
                        <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', fontWeight: 'bold', alignItems: 'center', marginTop: 8, marginBottom: 40 }}>
                            <InfoRoundedIcon style={{ fontSize: 40, marginRight: 8 }} />
                            <div>{`Quando o estoque atingir a quantidade informada, você receberá um aviso.`}</div>
                        </div>
                    </div>
                
                : null }
                
                <div style={{ marginTop: 20 }}/>
                <ImagePicker onUploadStart={() => { this.setState({ loadingModal: true }) }} onUploadEnd={() => { this.setState({ loadingModal: false }) }} onUpload={(files) => { this.setState({ images: files }); }} images={this.state.images} folder={'product_images'} multiple={true} label={'Imagens (Opcional)'}/>
                <div style={{ paddingTop: 15 }}>
                    <IosSwitch label="Ativo" value="active" checked={this.state.active} onChange={(v) => { this.setState({ active: v }) }}/>
                </div>
                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                    <DefaultButton onClick={() => { this.addProduct() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                    <DefaultButton onClick={() => { this.setState({ addModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                </div>
            </div>
        )
    }

    async updateImages(files) {

        this.setState({ images: files });
        Firestore.update({ images: files }, 'product', this.state.editId);
    }

    async handleHistoryModal(rowData) {

        await this.setState({ historyModal: true, loadingModal: true });

        let history = [];
        let query = await Firestore.customQuery('inventory_movement').where('id_product', '==', rowData.id).orderBy('date', 'desc').get();

        if (query.size > 0) {
            
            query.forEach((doc, key) => {
                history.push({ ...doc.data(), id: doc.id });
            });
        }

        await this.setState({ loadingModal: false, history: history });
    }

    async saveLocation() {

        if (!this.state.loadingModal) {

            if (this.state.addLocationName) {

                try {
    
                    this.setState({ loadingModal: true });
        
                    let data = {
        
                        name: this.state.addLocationName,
                        id_company: SessionHelper.getData().id_company,
                        id_user: SessionHelper.getFirebaseAuth().uid,
                        date: new Date(),
                    }
    
                    await Firestore.insert(data, 'product_location');
                    await this.getLocations();
        
                    this.setState({ loadingModal: false, addLocation: false, addLocationName: '' });
        
                } catch (error) {
        
                    toast.error('Houve um problema ao salvar');
                    this.setState({ loadingModal: false });
                }
            
            } else {
    
                toast.warn('Preencha todos os campos');
            }

        } else {

            toast.info('Aguarde. Realizando Ação...');
        }
    }

    async saveCategory() {

        if (!this.state.loadingModal) {

            if (this.state.addCategoryName) {

                try {
    
                    this.setState({ loadingModal: true });
        
                    let data = {
        
                        name: this.state.addCategoryName,
                        id_company: SessionHelper.getData().id_company,
                        id_user: SessionHelper.getFirebaseAuth().uid,
                        date: new Date(),
                    }
    
                    await Firestore.insert(data, 'product_category');
                    await this.getCategories();
        
                    this.setState({ loadingModal: false, addCategory: false, addCategoryName: '' });
        
                } catch (error) {
        
                    toast.error('Houve um problema ao salvar');
                    this.setState({ loadingModal: false });
                }
            
            } else {
    
                toast.warn('Preencha todos os campos');
            }

        } else {

            toast.info('Aguarde. Realizando Ação...');
        }
    }

    photoModal() {

        if (this.state.photoModal) {
            return (
                <div style={{}}>
                    
                    <ImagePicker onUploadStart={() => { this.setState({ loadingModal: true }) }} onUploadEnd={() => { this.setState({ loadingModal: false }) }} onUpload={(files) => { this.updateImages(files) }} images={this.state.images} folder={'product_images'} multiple={true}/>
    
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.setState({ photoModal: false, images: [] }) }} style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '100%'}} variant={'contained'}>{'FECHAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    historyModal() {

        if (this.state.historyModal) {

            return (
                <div>
                    { !this.state.loadingModal && !this.state.history.length ? <p style={{ color: 'grey', textAlign: 'center', marginTop: 50 }}>Nenhuma movimentação encontrada.</p> : null }
                    { 
                        this.state.history.map((history, key) => {

                            let label = history.type === 'entry' ? 'Entrada' : 'Saída';
                            let color = history.type === 'entry' ? Colors.success : Colors.error;
                            let icon = history.type === 'entry' ? <CallMadeIcon style={{ border: `3px solid ${color}`, color: color, borderRadius: '100%', padding: 5, fontSize: 45, marginRight: 10 }}/> : <CallReceivedIcon style={{ border: `3px solid ${color}`, color: color, borderRadius: '100%', padding: 5, fontSize: 45, marginRight: 10 }}/>;

                            return (
                                <div style={{ display: 'flex', flexDirection: 'row', padding: 0, paddingLeft: 12, paddingRight: 12, paddingTop: key === 0 ? 12 : 0, alignItems: 'flex-start', position: 'relative' }}>
                                    
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end' }}>
                                        {icon}
                                        <div style={{ borderLeft: this.state.history[key + 1] ? `3px dashed lightgrey` : '', height: 30, paddingRight: 10 }}/>
                                    </div>
                                    
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <div style={{ fontWeight: 'bold' }}>{label}</div>
                                        {history.system ? 'Sistema' : <RelationField loading={false} id={history.id_user} collection={'user'} field={'name'}/>}
                                        { history.obs ? <div style={{ fontSize: 12, color: 'grey'}}>{`${history.obs}`}</div> : null }
                                        <div style={{ fontSize: 12, position: 'absolute', right: 0, bottom: 30, color: 'grey' }}>{moment(history.date.toDate()).format('DD/MM/YYYY HH:mm')}</div>
                                    </div>

                                </div>
                            )
                        }) 
                    }
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <DefaultButton width={'100%'} title={'Fechar'} textColor={'white'} color={Colors.primary} onClick={() => { this.setState({ historyModal: false, history: [] }) }} />
                    </div>
                </div>
            )
        }
    }

    locationModal() {

        if (this.state.locationModal) {

            return (
                <div style={{ marginTop: 20 }}>
                    
                    { !this.state.loadingModal && !this.state.locationDocs.length ? <p style={{ color: 'grey', textAlign: 'center', marginTop: 50 }}>Nenhum local encontrado.</p> : null }

                    { 
                        this.state.locationDocs.map((doc, key) => {

                            return (
                                <div style={{ display: 'flex', flexDirection: 'row', padding: 0, alignItems: 'flex-start', position: 'relative', borderTop: key === 0 ? '1px solid lightgrey' : '', paddingTop: 20, borderBottom: '1px solid lightgrey', paddingBottom: 20 }}>
                                    
                                    <PlaceIcon style={{ border: `3px solid ${Colors.primary}`, color: Colors.primary, borderRadius: 50 / 2, height: 50, width: 50, padding: 5, fontSize: 45, marginRight: 10 }}/>
                                    
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <div style={{ fontWeight: 'bold' }}>{doc.name}</div>
                                        <RelationField render={(name) => `Cadastrado por ${name}`} collection={'user'} id={doc.id_user} field={'name'} loading={false}/>
                                        <div style={{ fontSize: 12, position: 'absolute', right: 0, bottom: 10, color: 'grey' }}>{moment(doc.date.toDate()).format('DD/MM/YYYY HH:mm')}</div>
                                    </div>

                                </div>
                            )
                        }) 
                    }

                    <div style={{ marginTop: 20 }}/>

                    { !this.state.addLocation ?

                        <Tooltip title={'Adicionar'}>
                            <IconButton style={{ boxShadow: Colors.boxShadow, color: Colors.primary, border: '1px solid lightgrey' }} onClick={() => { this.setState({ addLocation: true }) }}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>

                    :
                    
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <DefaultInput required onBlur={(v) => { this.setState({ addLocationName: v }) }} label={'Nome'}/>
                            <div style={{ marginLeft: 20 }}/>
                            <Tooltip title={'Salvar'}>
                                <IconButton style={{ boxShadow: Colors.boxShadow, color: Colors.success, border: '1px solid lightgrey' }} onClick={() => { this.saveLocation() }}>
                                    <CheckIcon />
                                </IconButton>
                            </Tooltip>
                            <div style={{ marginLeft: 8 }}/>
                            <Tooltip title={'Cancelar'}>
                                <IconButton style={{ boxShadow: Colors.boxShadow, color: Colors.error, border: '1px solid lightgrey' }} onClick={() => { this.setState({ addLocation: false, addLocationName: '' }) }}>
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    
                    }

                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <DefaultButton width={'100%'} title={'Fechar'} textColor={'white'} color={Colors.primary} onClick={() => { this.setState({ locationModal: false }) }} />
                    </div>
                </div>
            )
        }
    }

    categoryModal() {

        if (this.state.categoryModal) {

            return (
                <div style={{ marginTop: 20 }}>
                    
                    { !this.state.loadingModal && !this.state.categoryDocs.length ? <p style={{ color: 'grey', textAlign: 'center', marginTop: 50 }}>Nenhuma categoria encontrada.</p> : null }

                    { 
                        this.state.categoryDocs.map((doc, key) => {

                            return (
                                <div style={{ display: 'flex', flexDirection: 'row', padding: 0, alignItems: 'flex-start', position: 'relative', borderTop: key === 0 ? '1px solid lightgrey' : '', paddingTop: 20, borderBottom: '1px solid lightgrey', paddingBottom: 20 }}>
                                    
                                    <LabelIcon style={{ border: `3px solid ${Colors.primary}`, color: Colors.primary, borderRadius: 50 / 2, height: 50, width: 50, padding: 5, fontSize: 45, marginRight: 10 }}/>
                                    
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <div style={{ fontWeight: 'bold' }}>{doc.name}</div>
                                        <RelationField render={(name) => `Cadastrado por ${name}`} collection={'user'} id={doc.id_user} field={'name'} loading={false}/>
                                        <div style={{ fontSize: 12, position: 'absolute', right: 0, bottom: 10, color: 'grey' }}>{moment(doc.date.toDate()).format('DD/MM/YYYY HH:mm')}</div>
                                    </div>

                                </div>
                            )
                        }) 
                    }

                    <div style={{ marginTop: 20 }}/>

                    { !this.state.addCategory ?

                        <Tooltip title={'Adicionar'}>
                            <IconButton style={{ boxShadow: Colors.boxShadow, color: Colors.primary, border: '1px solid lightgrey' }} onClick={() => { this.setState({ addCategory: true }) }}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>

                    :
                    
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <DefaultInput required onBlur={(v) => { this.setState({ addCategoryName: v }) }} label={'Nome'}/>
                            <div style={{ marginLeft: 20 }}/>
                            <Tooltip title={'Salvar'}>
                                <IconButton style={{ boxShadow: Colors.boxShadow, color: Colors.success, border: '1px solid lightgrey' }} onClick={() => { this.saveCategory() }}>
                                    <CheckIcon />
                                </IconButton>
                            </Tooltip>
                            <div style={{ marginLeft: 8 }}/>
                            <Tooltip title={'Cancelar'}>
                                <IconButton style={{ boxShadow: Colors.boxShadow, color: Colors.error, border: '1px solid lightgrey' }} onClick={() => { this.setState({ addLocation: false, addCategoryName: '' }) }}>
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    
                    }

                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <DefaultButton width={'100%'} title={'Fechar'} textColor={'white'} color={Colors.primary} onClick={() => { this.setState({ categoryModal: false }) }} />
                    </div>
                </div>
            )
        }
    }

    renderAvaible(rowData) {

        let color = Colors.success;

        if (rowData.quantity_available <= 10 && rowData.quantity_available > 0) {

            color = Colors.danger;

        } else if (rowData.quantity_available <= 0) {
            
            color = Colors.error;
        }   

        return rowData.type === 'product' ? <div style={{ color: color, fontWeight: 'bold' }}>{rowData.type === 'product' ? rowData.quantity_available : '--'}</div> : <div>{'--'}</div>;
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <HeaderActionsWrapper style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon/>} onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'}/>
                        <div style={{ marginRight: 12 }} />
                        <DefaultButton leftIcon={<PlaceIcon/>} onClick={() => { this.setState({ locationModal: true }) }} color={'orange'} title={'Locais'}/>
                        <div style={{ marginRight: 12 }} />
                        <DefaultButton leftIcon={<LabelIcon/>} onClick={() => { this.setState({ categoryModal: true }) }} color={'#ed6111'} title={'Categorias'}/>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        
                    </div>
                </HeaderActionsWrapper>  

                <DefaultTable
                title={'Produtos'}
                marginTop={10}
                actions={[
                    {
                        icon: PhotoIcon,
                        tooltip: 'Imagens do Produto',
                        onClick: (event, rowData) => { this.setState({ photoModal: true, editId: rowData.id, images: rowData.images  }) } 
                    },
                    {
                        icon: HistoryIcon,
                        tooltip: 'Histórico de Movimentações',
                        onClick: (event, rowData) => { this.handleHistoryModal(rowData) } 
                    },
                ]}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Nome', field: 'name' },
                    { title: 'Tipo', field: 'type', render: rowData => <div>{rowData.type === 'product' ? 'Produto' : 'Serviço'}</div>, editable: false },
                    { title: 'Local', field: 'id_location', render: rowData => rowData.id_location ? <RelationField id={rowData.id_location} collection={'product_location'} field={'name'}/> : '', editComponent: props => ( <DefaultSelect displayField={'name'} search={true} searchField={'name'} valueField={'id'} onChange={(v) => { props.onChange(v.target.value) }} value={props.value} docs={this.state.locationDocs}/> ) },
                    { title: 'Categoria', field: 'id_category', render: rowData => rowData.id_category ? <RelationField id={rowData.id_category} collection={'product_category'} field={'name'}/> : '', editComponent: props => ( <DefaultSelect displayField={'name'} search={true} searchField={'name'} valueField={'id'} onChange={(v) => { props.onChange(v.target.value) }} value={props.value} docs={this.state.locationDocs}/> ) },
                    { title: 'Código', field: 'code' },
                    { title: 'Valor Unitário', field: 'price', type: 'currency', currencySetting: {locale: 'brl', currencyCode: 'brl'}, editComponent: props => ( <CurrencyInput value={props.value} onChange={(e) => { props.onChange(e.floatValue) }} /> ) },
                    { title: 'Custo', field: 'cost', type: 'currency', render: rowData => <div>{rowData.cost ? CurrencyHelper.centsToMoney(rowData.cost * 100) : ''}</div>, editComponent: props => ( <CurrencyInput value={props.value} onChange={(e) => { props.onChange(e.floatValue) }} /> ) },
                    { title: 'Quantidade Disponível', field: 'quantity_available', type: 'numeric', editable: false, render: rowData => this.renderAvaible(rowData) },
                    { title: 'Alerta de Estoque Baixo', field: 'low_inventory_reminder', type: 'numeric', render: rowData => <div>{rowData.type === 'product' ? rowData.low_inventory_reminder : '--'}</div> },
                    { title: 'Ativo', field: 'active', type: 'boolean', render: rowData => <IosSwitch disabled={true} checked={rowData.active}/>, editComponent: props => (<IosSwitch checked={props.value} onChange={(e) => { props.onChange(e) }} /> ) },
                    { title: 'Data Cadastro', field: 'date', render: rowData => <div>{moment(rowData.date.toDate()).format('DD/MM/YYYY HH:mm')}</div>, editable: false },
                ]}
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                    newData.date = oldData.date;

                    if (newData.name && newData.code && newData.price) {

                        if (newData.code === oldData.code || !(await this.productExists(newData.code))) {

                            let prev = this.state.docs;
                            prev[prev.indexOf(oldData)] = newData;
        
                            this.setState({ docs: prev });
        
                            if (oldData.id) {
                                
                                let update = {
                                    name: newData.name,
                                    code: newData.code,
                                    price: newData.price * 100,
                                    cost: newData.cost * 100,
                                    id_location: newData.id_location || '',
                                    id_category: newData.id_category || '',
                                    active: newData.active,
                                };
        
                                if (newData.type === 'product') {
                                    update.low_inventory_reminder = newData.low_inventory_reminder || 0;
                                }
        
                                await Firestore.update(update, 'product', oldData.id);
                            }
        
                            toast.success("Editado com sucesso", {
                                position: toast.POSITION.TOP_RIGHT
                            });
        
                            return prev;

                        } else {

                            toast.warn('Já existe um produto com esse código cadastrado');
                            return;
                        }

                    } else {

                        toast.warn('Preencha todos os campos');
                    }
                }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Produto'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.photoModal()} title={'Imagem do Produto'} onClose={() => { this.setState({ photoModal: false, images: [] }) }} open={this.state.photoModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.historyModal()} title={'Histórico de Movimentações'} onClose={() => { this.setState({ historyModal: false, history: [] }) }} open={this.state.historyModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.locationModal()} title={'Locais'} onClose={() => { this.setState({ locationModal: false }) }} open={this.state.locationModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.categoryModal()} title={'Categorias'} onClose={() => { this.setState({ categoryModal: false }) }} open={this.state.categoryModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
