import Firestore from '../api/firebase/Firestore';
import SessionHelper from './SessionHelper';

export default class CompanyTaskHelper {

    static async completeTask(key, instance) {

        let tasks = SessionHelper.getData().company.tasks;
        
        if (tasks && tasks[key]) {

            let task = tasks[key];

            if (!task.completed) {

                task.completed = true;

                let session = SessionHelper.getData();

                tasks[key] = task;
                session.company.tasks = tasks;

                SessionHelper.setData(session);

                await Firestore.update({ tasks: tasks }, 'company', SessionHelper.getData().id_company);

                if (instance) {

                    instance.getTasks();
                }
            }
        }
    }
}