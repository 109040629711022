import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import moment from "moment-timezone";
import IosSwitch from '../components/IosSwitch';
import DefaultSelect from '../components/DefaultSelect';
import LoopIcon from '@material-ui/icons/Loop';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import AddIcon from '@material-ui/icons/Add';
import Functions from '../api/firebase/Functions';
import HeaderActionsWrapper from '../components/HeaderActionsWrapper';

const notificationLimit = 5;

export default class StudentNotificationPage extends Component {

    state = {
        docs: [],
        userDocs: [],
        allUsersDocs: [],
        categoriesDocs: [],
        tagsDocs: [],
        categories: [],
        tags: [],
        title: '',
        body: '',
        allUsers: true,
        applyFilters: false,
        save: true,
        users: [],
        loading: true,
        loadingModal: false,
        addModal: false,
        notificationCount: null,
    }

    async getDocs() {

        let query = await Firestore.customQuery('student_notification').where('id_company', '==', SessionHelper.getData().id_company).orderBy('date', 'desc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs });
    }

    async getUsers() {
        let query = await Firestore.customQuery('user').where('id_companies', 'array-contains', SessionHelper.getData().id_company).where('type', '==', 'student').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            if (data.notification_token && data.notification_token.data) {
                docs.push(data);
            }
        });

        this.setState({ userDocs: docs, allUsersDocs: docs });
    }

    async filterUsers() {
        this.setState({ userDocs: [], users: [], loadingModal: true });

        let userDocs = [];

        this.state.allUsersDocs.forEach((data) => {
            if(this.state.applyFilters) {
                let canPush = true;
                let filterCategories = this.state.categories.length > 0;
                let filterTags = this.state.tags.length > 0;

                if(canPush && filterCategories) {
                    if(!this.state.categories.includes(data?.companies[SessionHelper.getData().id_company]?.category)) {
                        canPush = false;
                    }
                }

                if(canPush && filterTags) {
                    if(data.tags && data.tags.length) {
                        if(!data?.tags.some(r => this.state.tags.includes(r))) {
                            canPush = false;
                        }
                    } else {
                        canPush = false;
                    }
                }

                if(canPush) {
                    userDocs.push(data);
                }
            }
        });

        this.setState({ userDocs }, () => {this.setState({ loadingModal: false })});
    }

    async getSettings() {
        let query = await Firestore.customQuery('settings').where('id_company', '==', SessionHelper.getData().id_company).get();

        let categories = [];
        let tags = [];

        if(!query.empty) {
            let settings = query.docs[0].data();

            if(settings.categories) {
                categories = settings.categories;
            }

            if(settings.tags) {
                tags = settings.tags;
            }
        }

        this.setState({ categoriesDocs: categories, tagsDocs: tags });
    }

    async getCompanyNotification() {

        let id = SessionHelper.getData().id_company;
        let company = await Firestore.getDoc('company', id);

        if (company.exists) {

            let data = { ...company.data(), id: company.id };
            let notificationCount = !isNaN(parseInt(data.notification_count)) ? data.notification_count : notificationLimit;

            this.setState({ notificationCount });
        }
    }

    async getTitle() {

        let title = `Mensagem de ${SessionHelper.getData().company.name} 🔉`;

        this.setState({ title: title });
    }

    async componentDidMount() {
        this.setState({ loading: true });

        await this.getCompanyNotification();
        await this.getDocs();
        await this.getUsers();
        await this.getSettings();
        await this.getTitle();

        this.setState({ loading: false });
    }

    async canSendNotification() {

        await this.getCompanyNotification();

        if (this.state.notificationCount > 0) {

            return true;

        } else {

            return false;
        }
    }

    async sendNotifications(allUsers = false, users = [], body, load = false) {

        try {

            if (load) { this.setState({ loading: true }) };

            if (await (this.canSendNotification())) {

                let requestBody = {
                    title: this.state.title,
                    body: body ? body : this.state.body,
                    all_users: allUsers,
                    users: users,
                    id_company: SessionHelper.getData().id_company,
                };
        
                Functions.request('POST', 'sendStudentNotifications', requestBody);
    
                let notificationCount = this.state.notificationCount;
                notificationCount --;
    
                await Firestore.update({ notification_count: parseInt(notificationCount) }, 'company', SessionHelper.getData().id_company);
                await this.getCompanyNotification();
    
                toast.success('Notificações enviadas');
    
            } else {
                
                toast.warn('Você atingiu o limite de notificações diárias, aguarde o próximo dia para enviar', { autoClose: false });
            }

            if (load) { this.setState({ loading: false }) };

        } catch (error) {

            toast.error('Houve um problema ao enviar as notificações');
            if (load) { this.setState({ loading: false }) };
        }
    }

    async addNotification() {

        if (this.state.title && this.state.body) {

            try {

                this.setState({ loadingModal: true });

                let data = {
                    body: this.state.body,
                    all_users: this.state.allUsers && !this.state.applyFilters,
                    users: this.state.applyFilters && this.state.allUsers ? this.state.userDocs : this.state.users,
                    created_by: SessionHelper.getFirebaseAuth().uid,
                    date: new Date(),
                    id_company: SessionHelper.getData().id_company,
                };

                let doc = await Firestore.getId('student_notification');

                await this.sendNotifications(this.state.allUsers, data.users);

                if (this.state.save) {

                    await Firestore.insert(data, 'student_notification', doc);
                    toast.success('Notificação salva com sucesso');
                }

                await this.setState({ loadingModal: false, addModal: false, body: '', save: true, users: [], allUsers: true });
                await this.getDocs();

            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn("Preencha todos os campos", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    renderUserSelection() {
        return (
            <div style={{ marginTop: 8, marginBottom: 8 }}>
                <DefaultSelect search={true} multiple={true} searchField={'name'} id={'users'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ users: v.target.value }) }} value={this.state.users} docs={this.state.userDocs} label={'Selecionar Usuários'}/>
            </div>
        )
    }
    renderUserFilters() {
        return (
            <div style={{ marginTop: 8, marginBottom: 8 }}>
                <DefaultSelect search={true} multiple={true} searchField={'label'} id={'categories'} valueField={'value'} displayField={'label'} onChange={(v) => { this.setState({ categories: v.target.value }, this.filterUsers) }} value={this.state.categories} docs={this.state.categoriesDocs} label={'Selecionar Categorias'}/>
                <div style={{ marginTop: 20 }}/>
                <DefaultSelect search={true} multiple={true} searchField={'label'} id={'tags'} valueField={'value'} displayField={'label'} onChange={(v) => { this.setState({ tags: v.target.value }, this.filterUsers) }} value={this.state.tags} docs={this.state.tagsDocs} label={'Selecionar Tags'}/>
            </div>
        )
    }

    renderNotificationPreview() {

        return (

            <div style={{ width: '100%', boxShadow: Colors.boxShadow, padding: 10, marginTop: 10, display: 'flex', flexDirection: 'column', border: '1px solid lightgrey', borderRadius: 8, flexWrap: 'wrap' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 8, width: '100%' }}>
                    <img style={{ height: 25, borderRadius: 5 }} src={process.env.PUBLIC_URL + '/icon.png'} />
                    <div style={{ marginLeft: 5, color: 'grey', fontWeight: 400 }}>ACE</div>
                    <div style={{ flexGrow: 1 }}/>
                    <div style={{ color: 'grey', fontWeight: 400 }}>{'há 3m'}</div>
                </div>
                <div style={{ fontWeight: 'bold' }}>{this.state.title || 'Título'}</div>
                <div style={{ }}>{this.state.body || 'Mensagem'}</div>
            </div>
        )
    }

    addModal() {
        if (this.state.addModal) {
            return (
                <div>
                    <DefaultInput label={'Mensagem'} onChange={(v) => { this.setState({ body: v }) }}/>
                    <IosSwitch fullWidth label={'Aplicar filtros'} checked={this.state.applyFilters} onChange={(e) => { this.setState({ applyFilters: e, tags: [], categories: [] }) }} />
                    { this.state.applyFilters ? this.renderUserFilters() : null }
                    <IosSwitch fullWidth label={`Enviar para todos os usuários ${this.state.applyFilters ? `(Filtrados)` : ``}`} checked={this.state.allUsers} onChange={(e) => { this.setState({ allUsers: e }) }} />
                    { !this.state.allUsers ? this.renderUserSelection() : null }
                    <IosSwitch fullWidth label={'Salvar notificação'} checked={this.state.save} onChange={(e) => { this.setState({ save: e }) }} />
                    <p>{'Pré-visualização'}</p>
                    { this.renderNotificationPreview() }
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.addNotification() }} style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ addModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    renderNotificationCount() {

        return (
            <div style={{ fontWeight: 'bold', color: 'orange', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <InfoRoundedIcon style={{ fontSize: 24 }}/>
                <div style={{ marginLeft: 5 }}>{`Notificações diárias restantes: ${this.state.notificationCount}`}</div>
            </div>
        )
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <HeaderActionsWrapper style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <DefaultButton leftIcon={<AddIcon/>} onClick={() => { this.setState({ addModal: true, save: true }) }} title={'Adicionar'}/>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        { this.renderNotificationCount() }
                    </div>
                </HeaderActionsWrapper>

                <DefaultTable
                title={'Notificações Push'}
                marginTop={10}
                
                actions={[
                    {
                        icon: LoopIcon,
                        tooltip: 'Enviar Novamente',
                        onClick: (event, rowData) => { this.sendNotifications(rowData.all_users, rowData.users, rowData.body, true) } 
                    },
                ]}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Mensagem', field: 'body' },
                    { title: 'Enviar para todos os usuários', field: 'all_users', render: rowData => <IosSwitch disabled={true} checked={rowData.all_users}/>, editComponent: props => (<IosSwitch checked={props.value} onChange={(e) => { props.onChange(e) }} />), },
                    { title: 'Usuários', field: 'users', render: rowData => <div>{rowData.all_users ? 'Todos os usuários' : `${rowData.users.length} usuários selecionados`}</div>, editComponent: props => <DefaultSelect displayField={'name'} search={true} multiple={true} searchField={'name'} valueField={'id'} onChange={(v) => { props.onChange(v.target.value) }} value={props.value} docs={this.state.userDocs}/> },
                    { title: 'Data de Cadastro', editable: false, field: 'date', render: rowData => <div>{rowData.date ? moment(rowData.date.toDate ? rowData.date.toDate() : rowData.date).format('DD/MM/YYYY HH:mm') : ''}</div> },
                ]}
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                    newData.date = oldData.date;

                    let prev = this.state.docs;
                    prev[prev.indexOf(oldData)] = newData;

                    this.setState({ docs: prev });

                    if (oldData.id) {
                        
                        let data = {
                            body: newData.body,
                            sector: newData.sector || '',
                            all_users: newData.all_users,
                            users: newData.users,
                        };

                        await Firestore.update(data, 'student_notification', oldData.id);
                    }

                    toast.success("Editado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                onRowDelete={ async (oldData) => {

                    let prev = this.state.docs;
                    prev.splice(prev.indexOf(oldData), 1);
                    
                    this.setState({ docs: prev });

                    if (oldData.id) {

                        await Firestore.delete('student_notification', oldData.id);

                        toast.success("Removido com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Nova Notificação'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
