import React, { Component } from 'react';
import Colors from '../constants/Colors';
import moment from "moment-timezone";
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import TournamentHelper from '../helper/TournamentHelper';

export default class TournamentMatch extends Component {
    state = {
        tournament: this.props.tournament,
        match: this.props.match,
        users: this.props.users,
        happeningNow: false
    }

    componentDidMount() {
        let happeningNow = this.state.match.start && this.state.match.end ?
            moment().isBetween(moment(this.state.match.start.toDate ? this.state.match.start.toDate() : this.state.match.start), moment(this.state.match.end.toDate ? this.state.match.end.toDate() : this.state.match.end))
            : false;

        this.setState({ happeningNow })
    }

    isBye() { return this.state.match.p1.length && !this.state.match.p2 }

    renderPlayers() {
        let date = this.state.match.start ?
            moment(this.state.match.start.toDate ? this.state.match.start.toDate() : this.state.match.start).diff(moment(), 'days') >= 7 ?
                moment(this.state.match.start.toDate ? this.state.match.start.toDate() : this.state.match.start).format(`DD/MM/YYYY HH:mm`)
                : moment(this.state.match.start.toDate ? this.state.match.start.toDate() : this.state.match.start).calendar()
            : 'Sem data';

        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <div style={{ display: 'flex', flexDirection: 'row', position: 'relative' }}>
                    {
                        this.state.match.p1 && this.state.match.p1.length ? this.state.match.p1.map((player, key) => {

                            let user = this.state.users.find(item => item.id === player);

                            return (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: 200 }}>
                                    {!this.props.print ? <img style={{ width: 30, height: 30, borderRadius: 30 / 2, boxShadow: Colors.boxShadow, border: '1px solid lightgrey', marginRight: 12 }} src={user && user.photo ? user.photo : process.env.PUBLIC_URL + '/empty_avatar.png'} /> : null}
                                    {user ? user.name.length > 15 ? user.name.slice(0, 15) + '...' : user.name : '?'}
                                    {this.state.match.winner === 'p1' || this.isBye() ? <EmojiEventsIcon style={{ color: 'orange', fontSize: 18, position: 'absolute', right: 0 }} /> : null}
                                </div>
                            )

                        }) : this.state.match.p1 == null ? (

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: 200 }}>
                                {'Bye'}
                            </div>

                        ) : (
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: 200 }}>
                                {!this.props.print ? <img style={{ width: 30, height: 30, borderRadius: 30 / 2, boxShadow: Colors.boxShadow, border: '1px solid lightgrey', marginRight: 12 }} src={process.env.PUBLIC_URL + '/empty_avatar.png'} /> : null}
                                {'?'}
                            </div>
                        )
                    }
                </div>
                <div style={{ paddingTop: 5, borderRight: '1px solid lightgrey' }} />
                <div style={{ display: 'flex', flexDirection: 'row', position: 'relative' }}>
                    {
                        this.state.match.p2 && this.state.match.p2.length ? this.state.match.p2.map((player, key) => {

                            let user = this.state.users.find(item => item.id === player);

                            return (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: 200 }}>
                                    {!this.props.print ? <img style={{ width: 30, height: 30, borderRadius: 30 / 2, boxShadow: Colors.boxShadow, border: '1px solid lightgrey', marginRight: 12 }} src={user && user.photo ? user.photo : process.env.PUBLIC_URL + '/empty_avatar.png'} /> : null}
                                    {user ? user.name.length > 15 ? user.name.slice(0, 15) + '...' : user.name : '?'}
                                    {this.state.match.winner === 'p2' ? <EmojiEventsIcon style={{ color: 'orange', fontSize: 18, position: 'absolute', right: 0 }} /> : null}
                                </div>
                            )

                        }) : this.state.match.p2 == null ? (

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: 200 }}>
                                {'Bye'}
                            </div>

                        ) : (
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: 200 }}>
                                {!this.props.print ? <img style={{ width: 30, height: 30, borderRadius: 30 / 2, boxShadow: Colors.boxShadow, border: '1px solid lightgrey', marginRight: 12 }} src={process.env.PUBLIC_URL + '/empty_avatar.png'} /> : null}
                                {'?'}
                            </div>
                        )
                    }
                </div>
                <div style={{ position: 'absolute', bottom: 5, color: 'grey', fontSize: 10, left: 5 }}>
                    {`${date} • ${this.state.match.court ? this.state.match.court.length > 20 ? `${this.state.match.court.slice(0, 17)}...` : this.state.match.court : 'Sem quadra'}`}
                </div>
                <div style={{ position: 'absolute', bottom: 2, color: 'grey', fontSize: 10, right: 5, fontWeight: 'bold' }}>
                    {this.state.match && this.state.match.indexes ? TournamentHelper.getMatchAlias(this.state.match.indexes.gameAlias) : null}
                </div>
                <div style={{ position: 'absolute', top: 2, color: this.state.match.winner ? Colors.success : this.state.happeningNow ? Colors.primary : Colors.danger, fontSize: 10, right: 5, fontWeight: 'bold' }}>
                    {this.state.match.winner ? 'Finalizada' : this.state.happeningNow ? 'Acontecendo Agora' : 'Pendente'}
                </div>
            </div>
        )
    }

    render() {
        return (
            <div
                onClick={() => { this.props.openMatch(this.state.match) }}
                style={{
                    position: 'relative',
                    paddingTop: 13,
                    paddingBottom: 25,
                    paddingLeft: 8,
                    paddingRight: 8,
                    cursor: 'pointer',
                    backgroundColor: 'white',
                    borderRadius: 5,
                    width: '100%',
                    marginBottom: 5,
                    boxShadow: Colors.boxShadow,
                    display: 'flex',
                    flexDirection: 'column',
                    borderLeft: `4px solid ${this.state.match.winner ? Colors.success : this.state.happeningNow ? Colors.primary : Colors.danger}`,
                    borderTop: '2px solid lightgrey',
                    borderRight: '2px solid lightgrey',
                    borderBottom: '2px solid lightgrey',
                    position: 'relative'
                }}>
                {this.renderPlayers()}
            </div>
        )
    }
}