import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultTable from '../components/DefaultTable';
import DefaultInput from '../components/DefaultInput';
import Firestore from '../api/firebase/Firestore';
import { toast } from 'react-toastify';
import SessionHelper from '../helper/SessionHelper';
import DefaultSelect from '../components/DefaultSelect';
import DefaultButton from '../components/DefaultButton';
import { FormLabel } from '@material-ui/core';
import IosSwitch from '../components/IosSwitch';
import moment from "moment-timezone";
import 'moment/locale/pt-br';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import brLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from '@date-io/date-fns';
import CurrencyInput from '../components/CurrencyInput';
import CurrencyHelper from '../helper/CurrencyHelper';
import LiquidProfit from '../components/LiquidProfit';
import SaveIcon from '@material-ui/icons/Save';
import GroupIcon from '@material-ui/icons/Group';
import PrintIcon from '@material-ui/icons/Print';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Functions from '../api/firebase/Functions';
import Colors from '../constants/Colors';
import UnderConstruction from '../components/UnderConstruction';
import HeaderActionsWrapper from '../components/HeaderActionsWrapper';
import DayUseUserCard from '../components/DayUseUserCard';
import DefaultModal from '../components/DefaultModal';
import SearchBar from '../components/SearchBar';

export default class DayUsePage extends Component {
    state = {
        loadingModal: false,
        dayUseModal: false,
        selectedDayUse: {},
        userDocsDayUse: [],
        loadingSearchBar: true,
        searchingDayUseUsers: false,
        loading: false,
        loadingEvents: false,
        loadingText: ``,
        courtDocs: [],
        formattedPrice: ``,
        formattedStudentPrice: ``,
        day_use: SessionHelper.getData().company.day_use || {
            active: false,
            repetition_interval: 1,
            schedule_distance_limit: 2,
            price: 0,
            student_price: 0,
            limit: 100,
            sports: [],
            courts: [],
            days: {
                monday: {
                    checked: true,
                    label: 'Segunda-feira',
                    hours: ['09:00', '20:00']
                },
                tuesday: {
                    checked: true,
                    label: 'Terça-feira',
                    hours: ['09:00', '20:00']
                },
                wednesday: {
                    checked: true,
                    label: 'Quarta-feira',
                    hours: ['09:00', '20:00']
                },
                thursday: {
                    checked: true,
                    label: 'Quinta-feira',
                    hours: ['09:00', '20:00']
                },
                friday: {
                    checked: true,
                    label: 'Sexta-feira',
                    hours: ['09:00', '20:00']
                },
                saturday: {
                    checked: true,
                    label: 'Sábado',
                    hours: ['09:00', '20:00']
                },
                sunday: {
                    checked: true,
                    label: 'Domingo',
                    hours: ['09:00', '20:00']
                }
            }
        },
        sports: [
            { title: 'Tennis', name: 'tennis' },
            { title: 'Padel', name: 'padel' },
            { title: 'Beach Tennis', name: 'beach_tennis' },
            { title: 'Squash', name: 'squash' },
            { title: 'Badminton', name: 'badminton' },
            { title: 'Vôlei de Praia', name: 'beach_volley' },
            { title: 'Futevôlei', name: 'foot_volley' }
        ],
        docs: []
    }

    async componentDidMount() {
        await this.sync();

        this.syncInterval = setInterval(() => { if (!this.state.loading && !this.state.loadingEvents) this.sync() }, 600000);
    }

    componentWillUnmount() {
        clearInterval(this.syncInterval);
    }

    async sync() {
        this.setState({ loading: true });

        await this.getDayUseOptions();
        await this.getDocs();
        await this.getCourts();

        if (this.state.day_use) {
            this.setState({
                formattedStudentPrice: CurrencyHelper.centsToMoney(this.state.day_use.student_price, false),
                formattedPrice: CurrencyHelper.centsToMoney(this.state.day_use.price, false)
            });
        }

        this.setState({ loading: false });
    }

    async getDayUseOptions() {
        const query = await Firestore.getDoc('company', SessionHelper.getData().id_company);

        if (query.exists) {
            let data = query.data();

            if (data.day_use && data.day_use.days) {
                await this.setState({ day_use: data.day_use });
            }
        }
    }

    async getDocs() {
        this.setState({ loadingEvents: true });

        const query = await Firestore.customQuery('day_use').where('id_company', '==', SessionHelper.getData().id_company).where('finished', '==', false).orderBy('start').get();

        if (query.size > 0) {
            let docs = [];

            query.docs.forEach(doc => {
                docs.push({ ...doc.data(), id: doc.id });
            });

            this.setState({ docs });
        }

        this.setState({ loadingEvents: false });
    }

    async getCourts() {
        const query = await Firestore.customQuery('court').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name').get();

        if (query.size > 0) {
            let docs = [];

            query.forEach((doc, key) => {
                let data = doc.data();
                data.id = doc.id;
                data.sport_label = this.getSportLookup()[data.sport || `tennis`];
                docs.push(data);
            });

            this.setState({ courtDocs: docs });
        }
    }

    getSportLookup() {
        return {
            'tennis': 'Tennis',
            'padel': 'Padel',
            'beach_tennis': 'Beach Tennis',
            'squash': 'Squash',
            'badminton': 'Badminton',
            'beach_volley': 'Vôlei de Praia',
            'foot_volley': 'Futevôlei'
        }
    }

    async generateEvents() {
        this.setState({ loadingEvents: true });

        toast.warn('Gerando horários... Não saia dessa página até a geração ser concluída!', { autoClose: false });

        try {
            setTimeout(async () => {
                await Functions.request('PUT', `dayuse/generateDayUse/${SessionHelper.getData().id_company}`, {});

                setTimeout(async () => {
                    let query = await Firestore.customQuery('day_use').where('id_company', '==', SessionHelper.getData().id_company).where('start', '>=', moment().add(4, 'weeks').toDate()).limit(1).get();

                    if (query.docs.length < 1) {
                        setTimeout(async () => {
                            await Functions.request('PUT', `dayuse/generateDayUse/${SessionHelper.getData().id_company}`, {});
                            toast.success('Horários gerados com sucesso! Agora é seguro sair da página!', { autoClose: false });
                        }, 5000);
                    } else {
                        toast.success('Horários gerados com sucesso! Agora é seguro sair da página!', { autoClose: false });
                    }

                    await this.getDocs();
                    this.setState({ loadingEvents: false });
                }, 4000);

            }, 3000);

        } catch (e) {
            this.setState({ loadingEvents: false });
            toast.error('Ocorreu um erro ao gerar os horários. Contate o suporte do ACE para mais informações!', { autoClose: false });
            return;
        }

    }

    save = async () => {
        if (this.state.loading == true) {
            toast.warn('Aguarde, realizando ação...');
            return;
        }

        let canUpdate = this.state.day_use.repetition_interval && this.state.day_use.schedule_distance_limit && this.state.day_use.price && this.state.day_use.student_price && this.state.day_use.sports.length && this.state.day_use.courts.length && this.state.day_use.days;
        if (this.state.day_use.active == false) canUpdate = true;

        if (canUpdate) {
            try {
                this.setState({ loading: true, loadingText: `Salvando...` });

                toast.success("Atualizado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await Firestore.update({ day_use: this.state.day_use }, 'company', SessionHelper.getData().id_company);

                await this.generateEvents();

                this.setState({ loading: false, loadingText: `` });

            } catch (error) {
                console.log(error)
                toast.error("Houve um problema ao atualizar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loading: false, loadingText: `` });
            }


        } else {

            toast.warn("Preencha todos os campos", {
                position: toast.POSITION.TOP_RIGHT
            });
        }

    }

    getTreatedGridDate(date) {
        if (date.toDate) {
            return date.toDate();
        } else {
            return moment(date);
        }
    }

    renderActiveDayUses() {
        return (
            <div style={styles.activeDayUseWrapper}>
                <HeaderActionsWrapper style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<SaveIcon />} loading={this.state.loading} onClick={this.save} title={'Salvar Alterações'} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>

                    </div>
                </HeaderActionsWrapper>

                <DefaultTable
                    isLoading={this.state.loadingEvents}
                    title={'Day Uses Ativos'}
                    marginTop={10}
                    width={'100%'}
                    filtering={true}
                    actions={[
                        {
                            icon: GroupIcon,
                            tooltip: 'Inscrições',
                            onClick: (event, rowData) => { this.setState({ selectedDayUse: rowData, dayUseModal: true }) }
                        },
                    ]}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Permitir Inscrições', field: 'active', render: rowData => <IosSwitch disabled={true} checked={rowData.active} />, editComponent: props => (<IosSwitch checked={props.value} onChange={(e) => { props.onChange(e) }} />) },
                        { title: 'Nº de inscrições', field: 'start', render: rowData => <div style={{ color: rowData.participants.length < 1 ? Colors.disabled : rowData.participants.length / 2 > this.state.day_use.limit ? Colors.success : rowData.participants.length - 2 > this.state.day_use.limit ? Colors.error : Colors.primary, fontWeight: `bold` }}>{rowData.participants.length} inscritos</div>, editable: false },
                        { title: 'Inicio', field: 'start', render: rowData => <div>{moment(this.getTreatedGridDate(rowData.start)).format('DD/MM/YYYY HH:mm')} ({moment(this.getTreatedGridDate(rowData.start)).format('dddd')})</div>, editable: false },
                        { title: 'Fim', field: 'end', render: rowData => <div>{moment(this.getTreatedGridDate(rowData.end)).format('DD/MM/YYYY HH:mm')} ({moment(this.getTreatedGridDate(rowData.end)).format('dddd')})</div>, editable: false },
                    ]}
                    data={this.state.docs}
                    onRowUpdate={async (oldData, newData) => {
                        let prev = this.state.docs;
                        prev[prev.indexOf(oldData)] = newData;

                        if (oldData.id) {
                            let update = {
                                active: newData.active
                            };

                            await Firestore.update(update, 'day_use', oldData.id);
                        }

                        await this.getDocs();

                        toast.success("Editado com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        return prev;
                    }}
                />
            </div>
        );
    }


    renderDayUseOptions() {
        return (
            <div style={styles.dayUseOptionsWrapper}>
                <FormLabel style={{ marginTop: 10, marginBottom: 20, fontWeight: '800', fontSize: 20 }} component="legend">Configurações do Day Use</FormLabel>

                <IosSwitch label={`Disponibilizar Day Use`} onChange={(evt) => { this.setState({ day_use: { ...this.state.day_use, active: evt } }); }} checked={this.state.day_use.active} />

                {this.state.day_use.active ?
                    <div>
                        <div style={{ width: '100%' }}>
                            <FormLabel style={{ marginTop: 18, fontWeight: '800' }} component="legend">Valores por pessoa (R$)</FormLabel>
                            <FormLabel style={{ fontSize: 14, marginTop: 5 }} component="legend">Valores de inscrição para alunos e não alunos.</FormLabel>

                            <div style={{ display: 'flex', justifyContent: 'space-beetween', flexDirection: 'row', minWidth: '100%' }}>
                                <div style={{ width: '100%', paddingRight: 5 }}>
                                    <CurrencyInput required onError={(error, msg) => { this.setState({ inputError: error }); }} label={'Valor por pessoa (Aluno)'} onChange={(values) => { const { formattedValue, value } = values; this.setState({ day_use: { ...this.state.day_use, student_price: value * 100 }, formattedStudentPrice: formattedValue }) }} value={this.state.formattedStudentPrice} />
                                    <LiquidProfit price={this.state.day_use.student_price} />
                                    <LiquidProfit price={this.state.day_use.student_price} type={'pix'}/>
                                </div>
                                <div style={{ width: '100%', paddingLeft: 5 }}>
                                    <CurrencyInput required onError={(error, msg) => { this.setState({ inputError: error }); }} label={'Valor por pessoa (Não Aluno)'} onChange={(values) => { const { formattedValue, value } = values; this.setState({ day_use: { ...this.state.day_use, price: value * 100 }, formattedPrice: formattedValue }) }} value={this.state.formattedPrice} />
                                    <LiquidProfit price={this.state.day_use.price} />
                                    <LiquidProfit price={this.state.day_use.price} type={'pix'}/>
                                </div>
                            </div>
                        </div>

                        <FormLabel style={{ marginTop: 18, fontWeight: '800' }} component="legend">Limite de inscritos</FormLabel>
                        <FormLabel style={{ fontSize: 14, marginTop: 5 }} component="legend">Limite de vagas por Day Use</FormLabel>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', minWidth: '100%', marginBottom: 15, alignItems: 'center' }}>
                            <FormLabel>No máximo</FormLabel>
                            <div style={{ maxWidth: 60, marginLeft: 15, marginRight: 10 }}>
                                <DefaultInput number required onError={(error, msg) => { this.setState({ inputError: error }) }} value={this.state.day_use.limit} onChange={(v) => { this.setState({ day_use: { ...this.state.day_use, limit: parseInt(v) } }) }} />
                            </div>
                            <FormLabel>pessoas podem se inscrever por Day Use.</FormLabel>
                        </div>


                        <FormLabel style={{ marginTop: 18, fontWeight: '800' }} component="legend">Distância máxima de agendamentos</FormLabel>
                        <FormLabel style={{ fontSize: 14, marginTop: 5 }} component="legend">Quantas semanas no futuro, à partir de hoje, um usuário pode se inscrever em um Day Use.</FormLabel>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', minWidth: '100%', marginBottom: 15, alignItems: 'center' }}>
                            <FormLabel>Até</FormLabel>
                            <div style={{ maxWidth: 30, marginLeft: 15, marginRight: 10 }}>
                                <DefaultInput number required onError={(error, msg) => { this.setState({ inputError: error }) }} value={this.state.day_use.schedule_distance_limit} onChange={(v) => { this.setState({ day_use: { ...this.state.day_use, schedule_distance_limit: parseInt(v) } }) }} />
                            </div>
                            <FormLabel>semana(s) no futuro</FormLabel>
                        </div>

                        <FormLabel style={{ marginTop: 18, fontWeight: '800' }} component="legend">Frequência de repetição</FormLabel>
                        <FormLabel style={{ fontSize: 14, marginTop: 5 }} component="legend">Aqui você informa de a frequência de repetição em que o day use é repetido (Ex: a cada duas semanas, nos dias e horários selecionados)</FormLabel>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', minWidth: '100%', marginBottom: 15, alignItems: 'center' }}>
                            <FormLabel>Repete-se à cada</FormLabel>
                            <div style={{ maxWidth: 30, marginLeft: 15, marginRight: 10 }}>
                                <DefaultInput number required onError={(error, msg) => { this.setState({ inputError: error }) }} value={this.state.day_use.repetition_interval} onChange={(v) => { this.setState({ day_use: { ...this.state.day_use, repetition_interval: parseInt(v) } }) }} />
                            </div>
                            <FormLabel>semana(s)</FormLabel>
                        </div>

                        <FormLabel style={{ marginTop: 18, fontWeight: '800' }} component="legend">Quadras do Day Use</FormLabel>
                        <FormLabel style={{ fontSize: 14, marginTop: 5 }} component="legend">Quadras que ficarão reservadas ao Day Use nos dias marcados.</FormLabel>
                        <div style={{ marginTop: 15, marginBottom: 15 }}>
                            <DefaultSelect search={true} multiple={true} searchField={'name'} id={'court_selection'} valueField={'id'} displayField={'name'} secondaryDisplay={'sport_label'} value={this.state.day_use.courts} onChange={(v) => { this.setState({ day_use: { ...this.state.day_use, courts: v.target.value } }) }} docs={this.state.courtDocs} label={'Quadras'} />
                        </div>

                        <FormLabel style={{ marginTop: 18, fontWeight: '800' }} component="legend">Esportes do Day Use</FormLabel>
                        <FormLabel style={{ fontSize: 14, marginTop: 5 }} component="legend">Quadras que ficarão reservadas ao Day Use nos dias marcados.</FormLabel>
                        <div style={{ marginTop: 15, marginBottom: 15 }}>
                            <DefaultSelect search={true} multiple={true} searchField={'title'} id={'sport_selection'} valueField={'name'} displayField={'title'} value={this.state.day_use.sports} onChange={(v) => { this.setState({ day_use: { ...this.state.day_use, sports: v.target.value } }) }} docs={this.state.sports} label={'Esportes'} />
                        </div>

                        {this.renderDayUseHours()}
                    </div>
                    : null}


            </div>
        );
    }

    getDaysIndex() {
        return [
            { key: 'monday' },
            { key: 'tuesday' },
            { key: 'wednesday' },
            { key: 'thursday' },
            { key: 'friday' },
            { key: 'saturday' },
            { key: 'sunday' },
        ];
    }

    renderDayUseHours() {
        return (
            <div style={{ marginTop: 25 }}>
                <FormLabel style={{ marginTop: 18, fontWeight: '800' }} component="legend">Horários do Day Use</FormLabel>
                <FormLabel style={{ fontSize: 14, marginTop: 5 }} component="legend">Aqui você configura os dias e horários que o Day Use será disponibilizado. Isso ficará visível aos usuários do App.</FormLabel>
                <div style={{ paddingTop: 10 }}>
                    {this.getDaysIndex().map(dayIndex => {
                        let key = dayIndex.key;
                        let day = this.state.day_use.days[key];

                        if (day.label) {
                            return (
                                <div style={{ paddinTop: 10, flexDirection: 'row', display: 'flex', maxWidth: '100%', justifyContent: 'space-between' }}>
                                    <IosSwitch label={day.label} onChange={(evt) => { this.setState({ day_use: { ...this.state.day_use, days: { ...this.state.day_use.days, [key]: { ...this.state.day_use.days[key], checked: evt } } } }); }} checked={day.checked || false} />
                                    {day.checked ?
                                        <div style={{ paddingLeft: 25, paddingTop: 10, flexDirection: 'row', display: 'flex' }}>

                                            <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: 15 }}>
                                                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                                    <KeyboardTimePicker
                                                        style={{ width: '100%', marginBottom: 30, paddingRight: 5 }}
                                                        invalidDateMessage={false}
                                                        format={'HH:mm'}
                                                        autoOk={true}
                                                        label="Início"
                                                        cancelLabel={'Cancelar'}
                                                        okLabel={'Confirmar'}
                                                        onChange={(v) => {
                                                            let hours = day.hours;
                                                            hours[0] = moment(v).format('HH:mm');

                                                            this.setState({ day_use: { ...this.state.day_use, days: { ...this.state.day_use.days, [key]: { ...this.state.day_use.days[key], hours } } } });
                                                        }}
                                                        value={moment(day.hours[0], 'HH:mm')}
                                                    />
                                                    <KeyboardTimePicker
                                                        style={{ width: '100%', marginBottom: 30, paddingLeft: 5 }}
                                                        invalidDateMessage={false}
                                                        format={'HH:mm'}
                                                        autoOk={true}
                                                        label="Fim"
                                                        cancelLabel={'Cancelar'}
                                                        okLabel={'Confirmar'}
                                                        onChange={(v) => {
                                                            let hours = day.hours;
                                                            hours[1] = moment(v).format('HH:mm');

                                                            this.setState({ day_use: { ...this.state.day_use, days: { ...this.state.day_use.days, [key]: { ...this.state.day_use.days[key], hours } } } });
                                                        }}
                                                        value={moment(day.hours[1], 'HH:mm')}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                            );
                        }
                    })}
                </div>
            </div >
        );
    }

    isDevelopmentMode() {
        return '_self' in React.createElement('div');
    }

    searchCallback = (user) => {
        let exists = this.state.userDocsDayUse.find(eUser => (eUser.id === user.id));

        if (!exists) {
            let userDocsDayUse = this.state.userDocsDayUse;
            userDocsDayUse.push(user);

            this.setState({ userDocsDayUse, loadingSearchBar: this.state.selectedDayUse.participants && userDocsDayUse.length === this.state.selectedDayUse.participants.length ? false : true });
        }
    }

    dayUseModal() {
        if (this.state.dayUseModal && this.state.selectedDayUse.id) {
            return (
                <div>
                    <p style={{ fontSize: 12, marginTop: -10 }}>Cancele o pedido para cancelar uma inscrição</p>
                    <SearchBar docs={this.state.userDocsDayUse} search={'name'} loading={this.state.loadingSearchBar} onEmpty={async (docs) => { await this.setState({ userDocsDayUse: docs, searchingDayUseUsers: false }) }} onSearch={async (result) => { await this.setState({ userDocsDayUse: result, searchingDayUseUsers: true }) }} placeholder={'Busque por inscritos...'} />

                    {this.state.searchingDayUseUsers ?
                        <div style={{ overflowY: 'auto', width: '100%', maxHeight: 560 }}>
                            <p style={{ fontSize: 12 }}>{this.state.userDocsDayUse.length} resultados para a busca:</p>
                            {this.state.userDocsDayUse.map(user => <DayUseUserCard dayUse={this.state.selectedDayUse} userId={user.id} />)}
                        </div>
                        :
                        <div style={{ overflowY: 'auto', width: '100%', maxHeight: 560 }}>
                            {this.state.selectedDayUse.participants.map(id_user => <DayUseUserCard dayUse={this.state.selectedDayUse} userId={id_user} searchCallback={this.searchCallback} />)}
                        </div>
                    }


                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.setState({ dayUseModal: false, selectedDayUse: {}, userDocsDayUse: [] }) }} color={Colors.primary} loading={this.state.loadingModal} width={'100%'} title={'VOLTAR'} />
                    </div>
                </div>
            );
        }
    }

    render() {
        return this.state.loading ? <DefaultLoader loadingText={this.state.loadingText} /> : (
            <div style={styles.container}>
                <div style={styles.wrapper}>
                    {this.renderActiveDayUses()}
                    {this.renderDayUseOptions()}
                </div>
                <DefaultModal loading={this.state.loadingModal} content={this.dayUseModal()} title={'Inscrições do Day Use'} onClose={() => { this.setState({ dayUseModal: false, selectedDayUse: {}, userDocsDayUse: [] }) }} open={this.state.dayUseModal} width={window.screen.width < 1366 ? (window.screen.width - (window.screen.width * 0.15)) : 850} />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    },
    wrapper: {
        width: `100%`,
        height: `100%`,
        display: `flex`,
        flexDirection: window.screen.width < 1360 ? `column` : `row`,
        justify: `space-evenly`
    },
    dayUseOptionsWrapper: {
        maxHeight: window.screen.width < 1360 ? window.screen.height - (220) : window.screen.height - (220),
        overflowY: `auto`,
        width: window.screen.width < 1360 ? `100%` : `50%`,
        height: `100%`,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: window.screen.width < 1360 ? 10 : 25,
        paddingRight: 10
    },
    activeDayUseWrapper: {
        width: window.screen.width < 1360 ? `100%` : `50%`,
        height: `100%`,
        padding: 10
    }
}
