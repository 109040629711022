import React, { Component } from 'react';
import Colors from '../constants/Colors';
import TournamentMatch from './TournamentMatch';
import SearchBar from './SearchBar';
import moment from "moment-timezone";
import DefaultLoader from './DefaultLoader';
import Firestore from '../api/firebase/Firestore';
import AddAlarmIcon from '@material-ui/icons/AddAlarm';
import { IconButton, Tooltip } from '@material-ui/core';

export default class RankingColumnView extends Component {

    state = {
        rounds: this.props.rounds,
        loadingRounds: {}
    }

    async componentDidMount() {
        for (let i = 0; i < this.props.rounds.length; i++) {
            this.loadRound(i);
        }
    }

    async loadRound(roundIndex) {
        let rounds = this.state.rounds;
        let loadingRounds = this.state.loadingRounds;

        loadingRounds[roundIndex] = true;

        this.setState({ loadingRounds });

        let indexes = this.props.indexes;
        let id_ranking = this.props.ranking.id;

        let query = await Firestore.customQuery('ranking_event').where('id_ranking', '==', id_ranking)
            .where('indexes.category', '==', indexes.category).where('indexes.class', '==', indexes.class)
            .where('indexes.round', '==', roundIndex).get();

        let matches = [];

        query.forEach(match => { matches.push({ ...match.data(), id: match.id }) });
        matches.sort((a, b) => a.indexes.match - b.indexes.match);

        rounds[roundIndex].matches = matches;
        loadingRounds[roundIndex] = false;

        this.setState({ rounds, loadingRounds });
    }

    searchMatch(docs, text) {
        let result = [];

        result = docs.filter((doc) => {

            const court = doc['court'] ? doc['court'].toUpperCase() : ''.toUpperCase();

            let p1UserOne = doc['p1'] && doc['p1'][0] ? this.props.users.find(item => item.id === doc['p1'][0]) : '';
            let p1UserTwo = doc['p1'] && doc['p1'][1] ? this.props.users.find(item => item.id === doc['p1'][1]) : '';

            let p2UserOne = doc['p2'] && doc['p2'][0] ? this.props.users.find(item => item.id === doc['p2'][0]) : '';
            let p2UserTwo = doc['p2'] && doc['p2'][1] ? this.props.users.find(item => item.id === doc['p2'][1]) : '';

            const p1One = p1UserOne ? p1UserOne.name.toUpperCase() : '';
            const p1Two = p1UserTwo ? p1UserTwo.name.toUpperCase() : '';

            const p2One = p2UserOne ? p2UserOne.name.toUpperCase() : '';
            const p2Two = p2UserTwo ? p2UserTwo.name.toUpperCase() : '';

            const search = text.toString().toUpperCase();

            if (court.indexOf(search) > -1) {

                return true;
            }

            if (p1One.indexOf(search) > -1) {

                return true;
            }

            if (p1Two.indexOf(search) > -1) {

                return true;
            }

            if (p2One.indexOf(search) > -1) {

                return true;
            }

            if (p2Two.indexOf(search) > -1) {

                return true;
            }

            return false;
        });

        return result;
    }

    async handleMatchSearch(round, result) {

        let rounds = this.state.rounds;

        if (rounds[round]) {

            rounds[round].matches = [];

            await this.setState({ rounds: rounds });

            rounds[round].matches = result;

            await this.setState({ rounds: rounds });
        }
    }

    renderRound(key) {
        let round = this.state.rounds[key];

        let start = moment(round.start.toDate ? round.start.toDate() : round.start);
        let end = moment(round.end.toDate ? round.end.toDate() : round.end);

        if (this.state.rounds[key].matches && !this.state.loadingRounds[key]) {
            return (
                <div style={{ marginRight: 25, minWidth: 220 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', minWidth: 200, paddingLeft: 8, justifyContent: 'flex-start', alignContent: 'center', alignItems: 'center' }}>
                        <Tooltip title={'Adiar ou adiantar rodada'}>
                            <IconButton style={{ marginRight: 5 }} onClick={() => { this.props.openReescheduleModal(round, key) }}>
                                <AddAlarmIcon />
                            </IconButton>
                        </Tooltip>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ fontWeight: moment().isBetween(start, end) ? 'bold' : 'normal', fontSize: 14 }}>{`Rodada ${key + 1}`}</div>
                            <div style={{ fontWeight: moment().isBetween(start, end) ? 'bold' : 'normal', fontSize: 12 }}>{`${moment(start).format('DD/MM')} até ${moment(end).format('DD/MM')}`}</div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', padding: 8, boxShadow: Colors.boxShadow, borderRadius: 5, flexDirection: 'column', backgroundColor: 'white', marginTop: 20, marginRight: 0 }}>
                        <SearchBar customSearch={(docs, text) => { return this.searchMatch(docs, text) }} placeholder={'Busque uma partida...'} iconStyle={{ fontSize: 22 }} style={{ height: 35, marginBottom: 8 }} docs={round.matches} search={'court'} onEmpty={(docs) => { this.handleMatchSearch(key, docs) }} onSearch={(result) => { this.handleMatchSearch(key, result) }} />
                        {
                            round.matches.map((match, matchKey) => {
                                return <TournamentMatch view={'column'} {...this.props} round={key} match={match} index={matchKey} />
                            })
                        }

                        {!round.matches.length ? <p style={{ textAlign: 'center', color: 'grey' }}>{'Nenhum resultado encontrado.'}</p> : null}
                    </div>
                </div>
            );
        } else {
            return (
                <div style={{ marginRight: 25, minWidth: 220 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', minWidth: 200, paddingLeft: 8, justifyContent: 'flex-start', alignContent: 'center', alignItems: 'center' }}>
                        <Tooltip title={'Adiar ou adiantar rodada'}>
                            <DefaultLoader size={20} css={{ margin: 10 }} color={'grey'} />
                        </Tooltip>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ fontWeight: moment().isBetween(start, end) ? 'bold' : 'normal', fontSize: 14 }}>{`Rodada ${key + 1}`}</div>
                            <div style={{ fontWeight: moment().isBetween(start, end) ? 'bold' : 'normal', fontSize: 12 }}>{`${moment(start).format('DD/MM')} até ${moment(end).format('DD/MM')}`}</div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', padding: 8, boxShadow: Colors.boxShadow, borderRadius: 5, flexDirection: 'column', backgroundColor: 'white', marginTop: 0, marginRight: 0 }}>
                        <p style={{ textAlign: 'center', color: 'grey' }}><DefaultLoader size={28} color={'grey'} /></p>
                    </div>
                </div>
            );
        }
    }

    renderRounds() {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', position: 'relative' }}>
                {
                    this.state.rounds.map((round, key) => {
                        return this.renderRound(key);
                    })
                }

            </div>
        )
    }

    render() {
        return (
            <div style={{ padding: 25 }}>
                {this.state.rounds && this.state.rounds.length ? this.renderRounds() : null}
            </div>
        )
    }
}