import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Card, Button, InputLabel, Select, MenuItem, FormControlLabel, Switch, TextareaAutosize, FormLabel, RadioGroup, Radio } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import IosSwitch from '../components/IosSwitch';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import CurrencyInput from '../components/CurrencyInput';
import moment from "moment-timezone";
import DefaultSelect from '../components/DefaultSelect';
import Pagarme from '../api/pagarme/Pagarme';
import LiquidProfit from '../components/LiquidProfit';
import AddIcon from '@material-ui/icons/Add';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import HeaderActionsWrapper from '../components/HeaderActionsWrapper';
import { MTableToolbar } from 'material-table';

export default class RegistrationPlanPage extends Component {

    state = {
        docs: [],
        name: '',
        description: '',
        paymentType: ['boleto', 'credit_card'],
        price: null,
        rawPrice: null,
        days: '',
        charges: null,
        installments: '',
        invoiceReminder: '',
        active: true,
        inifinite: true,
        lessons: '',
        loading: true,
        loadingModal: false,
        addModal: false,
        signUp: SessionHelper.getData().company.services ? SessionHelper.getData().company.services.find(item => item.name === 'sign_up') : { label: 'Matrícula', name: 'sign_up', checked: false },
        inputError: false,
        filterStatus: true,
        statusTypes: [
            { value: true, label: 'Ativo' },
            { value: false, label: 'Inativo / Personalizado não salvo' }
        ],
    }

    async getDocs() {
        let query = await Firestore.customQuery('student_plan').where('id_company', '==', SessionHelper.getData().id_company).where('active', '==', this.state.filterStatus).orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;
            data.price = data.price / 100;

            docs.push(data);
        });

        this.setState({ docs: docs });
    }

    async componentDidMount() {

        this.setState({ loading: true });

        await this.getDocs();

        this.setState({ loading: false });
    }

    async addPlan() {

        if (this.state.name && this.state.rawPrice && this.state.paymentType.length) {

            try {

                this.setState({ loadingModal: true });

                let data = {
                    name: this.state.name,
                    description: this.state.description,
                    price: this.state.rawPrice * 100,
                    payment_type: this.state.paymentType,
                    lessons: this.state.lessons,
                    days: this.state.days,
                    charges: this.state.charges,
                    inifinite: this.state.inifinite,
                    installments: 1,
                    invoice_reminder: this.state.invoiceReminder,
                    active: this.state.active,
                    id_company: SessionHelper.getData().id_company,
                    date: new Date(),
                };

                let body = {
                    amount: data.price,
                    days: this.state.days,
                    charges: this.state.charges,
                    installments: 1,
                    name: data.name,
                    payment_methods: data.payment_type,
                };

                if (data.invoice_reminder > 0) {
                    body.invoice_reminder = data.invoice_reminder;
                }

                let response = await Pagarme.post('/plans', body);

                if (response.errors && response.errors.length) {
                    throw new Error('Pagarme Error');
                }

                data.id_plan_pagarme = response.id;

                await Firestore.insert(data, 'student_plan');

                toast.success("Cadastrado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.setState({ loadingModal: false, addModal: false, name: '', description: '', price: null, rawPrice: null, paymentType: ['boleto'] });
                await this.getDocs();

            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn("Preencha ao menos o Nome, Preço, e formas de pagamento", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    addModal() {
        return (
            <div>
                <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ name: v }) }} label={'Nome'} />
                <TextareaAutosize style={{ width: '100%', borderRadius: 5, borderColor: 'lightgrey', padding: 15, marginTop: 15, fontSize: '1rem' }} rowsMax={8} rowsMin={8} value={this.state.description} onChange={(v) => { this.setState({ description: v.target.value }) }} placeholder="Descrição" />
                <DefaultInput number required onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ lessons: v }) }} label={'Aulas por Semana'} />

                <CurrencyInput required onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Valor'} onChange={(values) => { const { formattedValue, value } = values; this.setState({ price: formattedValue, rawPrice: value }) }} value={this.state.price} />

                <div style={{ marginBottom: 15, marginTop: 15 }}>
                    <InputLabel id="type">Formas de Pagamento</InputLabel>
                    <Select
                        style={{ width: '100%' }}
                        labelId="tipo"
                        value={this.state.paymentType}
                        multiple={true}
                        onChange={(v) => { this.setState({ paymentType: v.target.value }) }}>
                        <MenuItem value={'boleto'}>{'Boleto'}</MenuItem>
                        <MenuItem value={'credit_card'}>{'Cartão de Crédito'}</MenuItem>
                    </Select>
                </div>

                {this.state.paymentType.includes('boleto') && <DefaultInput onBlur={(v) => { this.setState({ invoiceReminder: v }) }} label={'Lembrete de Pagamento (dias antes do vencimento)'} />}

                <DefaultInput required number onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ days: v }) }} label={'Intervalo de Cobrança (Ex: 30 = uma cobrança a cada 30 dias)'} />

                <div style={{ marginTop: 15 }}>
                    <IosSwitch fullWidth label="Cobrar até o cancelamento do plano" checked={this.state.inifinite} onChange={(v) => { this.setState({ inifinite: v, charges: null }) }} />
                </div>

                {!this.state.inifinite ? <DefaultInput required number onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ charges: v }) }} label={'Número de Cobranças'} /> : null}

                <IosSwitch fullWidth label="Ativo" checked={this.state.active} onChange={(v) => { this.setState({ active: v }) }} />

                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                    <Button onClick={() => { this.addPlan() }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ addModal: false }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    renderPaymentMethods(data) {

        let text = '';

        data.forEach((method, key) => {

            let label = '';

            if (method === 'boleto') {
                label = 'Boleto';

            } else if (method === 'credit_card') {
                label = 'Cartão de Crédito';

            } else if (method === 'money') {
                label = 'Dinheiro';

            } else {
                label = 'Maquininha';
            }

            text += label;

            if (data[key + 1]) {
                text += ', ';
            }
        });

        return text;
    }

    async handleSwitch(v) {

        let signUp = this.state.signUp;
        signUp.checked = v;

        this.setState({ signUp: signUp });

        let services = SessionHelper.getData().company.services || [
            { label: 'Matrícula', name: 'sign_up', checked: true },
            { label: 'Aula Avulsa', name: 'private_lesson', checked: false },
            { label: 'Aula Experimental', name: 'test_lesson', checked: false },
        ];

        let index = services.findIndex(item => item.name === 'sign_up');
        services[index] = signUp;

        await Firestore.update({ services: services }, 'company', SessionHelper.getData().id_company);
    }

    renderToolbar(props) {
        return (
            <div>
                <MTableToolbar {...props} />
                <div style={{ paddingLeft: 25, paddingRight: 25, paddingBottom: 25, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ width: 300, marginRight: 15 }}><DefaultSelect valueField={'value'} displayField={'label'} onChange={async (v) => { await this.setState({ filterStatus: v.target.value }); this.getDocs(); }} docs={this.state.statusTypes} label={'Filtrar Status do Plano'} value={this.state.filterStatus} /></div>
                </div>
            </div>
        )
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <HeaderActionsWrapper style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon />} onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <IosSwitch onChange={(v) => { this.handleSwitch(v) }} label={this.state.signUp.label} checked={this.state.signUp.checked} />
                    </div>
                </HeaderActionsWrapper>
                <DefaultTable
                    title={'Planos de Matrícula'}
                    marginTop={10}

                    actions={[]}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Ativo', field: 'active', type: 'boolean', render: rowData => <IosSwitch disabled={true} checked={rowData.active} />, editComponent: props => (<IosSwitch checked={props.value} onChange={(e) => { props.onChange(e) }} />) },
                        { title: 'Nome', field: 'name' },
                        { title: 'Descrição', field: 'description' },
                        { title: 'Aulas por Semana', field: 'lessons', type: 'numeric' },
                        { title: 'Valor', field: 'price', type: 'currency', currencySetting: { locale: 'brl', currencyCode: 'brl' }, editable: false },
                        { title: 'Métodos de Pagamento', field: 'payment_type', render: rowData => <div>{this.renderPaymentMethods(rowData.payment_type)}</div>, editable: false },
                        { title: 'Lembrete de Pagamento', field: 'invoice_reminder', editable: false, render: rowData => <div>{rowData.invoice_reminder ? rowData.invoice_reminder + ' dias' : ''}</div> },
                        { title: 'Intervalo de Cobrança', field: 'days', editable: false, render: rowData => <div>{rowData.days ? rowData.days + ' dias' : ''}</div> },
                        { title: 'Número de Cobranças', field: 'charges', editable: false, render: rowData => <div>{rowData.charges ? rowData.charges : 'Indefinidamente'}</div> },
                        { title: 'Cobrar até o cancelamento', field: 'inifinite', editable: false, type: 'boolean', render: rowData => <IosSwitch disabled={true} checked={rowData.inifinite} />, editComponent: props => (<IosSwitch checked={props.value} onChange={(e) => { props.onChange(e) }} />) },
                        { title: 'Data Criação', field: 'date', render: rowData => <div>{moment(rowData.date.toDate()).format('DD/MM/YYYY HH:mm')}</div>, editable: false },
                    ]}
                    data={this.state.docs}
                    onRowUpdate={async (oldData, newData) => {

                        newData.date = oldData.date;

                        let prev = this.state.docs;
                        prev[prev.indexOf(oldData)] = newData;

                        this.setState({ docs: prev });

                        if (oldData.id) {

                            let update = {
                                active: newData.active,
                                name: newData.name,
                                description: newData.description,
                                lessons: newData.lessons,
                            };

                            await Firestore.update(update, 'student_plan', oldData.id);
                        }

                        await this.getDocs();

                        toast.success("Editado com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        return prev;
                    }}
                    components={{
                        Toolbar: props => (this.renderToolbar(props)),
                    }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Plano'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal} />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
