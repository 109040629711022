import SessionHelper from "./SessionHelper";

export default class UserHelper {

    static treatName(string, id, withSeparator = true) {
        if (id == SessionHelper.getFirebaseAuth().uid) return 'Você';

        string = string.toLowerCase();
        string = string.charAt(0).toUpperCase() + string.slice(1);
        string = string.split(/\s/);

        return `${string[0]}${(string.length - 1) > 0 && string[string.length - 1][0] ? ` ${string[string.length - 1][0].toUpperCase()}${withSeparator ? '.' : ''}` : ''}`;
    }

    static getLessonTypeLookup() {
        let lookup = {
            single: 'Individual',
            couple: 'Dupla',
            group: 'Grupo'
        };

        return lookup;
    }

    static getLessonType() {
        let types = {
            single: { label: 'Individual', color: '#8a0fe8' },
            couple: { label: 'Dupla', color: '#1cc2fc' },
            group: { label: 'Grupo', color: '#fc1cfc' }
        };

        return types;
    }
}