import React from 'react';
import Firestore from '../api/firebase/Firestore';
import SessionHelper from '../helper/SessionHelper';
import ClipLoader from "react-spinners/ClipLoader";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import Computer from '@material-ui/icons/Computer';
import Colors from '../constants/Colors';
import moment from "moment-timezone";

export default class StudentReplacementHistory extends React.Component {

    state = {
        loading: true,
        id: this.props.id,
        docs: [],
    }

    async componentDidMount() {

        await this.getReplacementLogs();

        this.setState({ loading: false });
    }

    async update() {

        await this.setState({ loading: true });
        await this.getReplacementLogs();

        this.setState({ loading: false });
    }

    async getReplacementLogs() {

        let query = await Firestore.customQuery('replacement_lesson_log').where('id_user', '==', this.state.id).where('id_company', '==', SessionHelper.getData().id_company).orderBy('date', 'desc').get();
        let docs = [];

        if (query.size > 0) {

            for (let doc of query.docs) {

                let data = { ...doc.data(), id: doc.id };

                if (data.id_admin) {

                    let admin = await Firestore.getDoc('user', data.id_admin);

                    if (admin.exists) {

                        data.admin = admin.data().name;
                    }
                }

                if (data.id_lesson) {

                    let lesson = await Firestore.getDoc('lesson', data.id_lesson);

                    if (lesson.exists) {

                        data.lesson = lesson.data();
                    }
                }

                docs.push(data);
            }
        }

        this.setState({ docs });
    }

    renderLoading() {

        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ClipLoader
                size={25}
                color={Colors.primary}
                loading={true}
                />
            </div>
        )
    }

    renderHistory() {

        return this.state.docs.map((history, key) => {

            let label = history.app ? 'Aula Reagendada' : history.from_manual_lesson ? 'Aula Reagendada Manualmente' : history.add ? 'Reposição Ganha' : 'Reposição Removida';
            let color = history.app || history.from_manual_lesson ? Colors.primary : history.add ? Colors.success : Colors.error;
            let icon = history.app ? <PhoneIphoneIcon style={{ border: `3px solid ${color}`, color: color, borderRadius: '100%', padding: 5, fontSize: 45, marginRight: 10 }}/> : history.from_manual_lesson ? <Computer style={{ border: `3px solid ${color}`, color: color, borderRadius: '100%', padding: 5, fontSize: 45, marginRight: 10 }}/> : history.add ? <AddIcon style={{ border: `3px solid ${color}`, color: color, borderRadius: '100%', padding: 5, fontSize: 45, marginRight: 10 }}/> : <RemoveIcon style={{ border: `3px solid ${color}`, color: color, borderRadius: '100%', padding: 5, fontSize: 45, marginRight: 10 }}/>;

            return (
                <div style={{ display: 'flex', flexDirection: 'row', padding: 0, paddingLeft: 12, paddingRight: 12, paddingTop: key === 0 ? 12 : 0, alignItems: 'flex-start', position: 'relative' }}>
                    
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end' }}>
                        {icon}
                        <div style={{ borderLeft: this.state.docs[key + 1] ? `3px dashed lightgrey` : '', height: 30, paddingRight: 10 }}/>
                    </div>
                    
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <div style={{ fontWeight: 'bold' }}>{label}</div>
                        { history.admin ? <div>{`por ${history.admin}`}</div> : null }
                        { history.from_canceled_lesson ? <div style={{ fontSize: 12, color: 'grey'}}>{`(Aula Cancelada no Sistema)`}</div> : null }
                        { history.lesson ? <div>{`${moment(history.lesson.start.toDate()).format('DD/MM/YYYY HH:mm')} até ${moment(history.lesson.end.toDate()).format('HH:mm')}`}</div> : null }
                        <div style={{ fontSize: 12, position: 'absolute', right: 0, bottom: history.from_canceled_lesson ? 15 : 30, color: 'grey' }}>{moment(history.date.toDate()).format('DD/MM/YYYY HH:mm')}</div>
                    </div>

                </div>
            )
        })
    }

    renderEmpty() {

        return <p style={{ color: 'grey' }}>Nenhum registro de histórico encontrado.</p>
    }

    render() {

        return this.state.loading ? this.renderLoading() : (

            <div {...this.props}>
                { this.state.docs.length ? this.renderHistory() : this.renderEmpty() }
            </div>
        );
    }
}