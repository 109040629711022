import React, { Component } from 'react';
import DefaultLoader from '../DefaultLoader';
import { Card, IconButton, Tooltip } from '@material-ui/core';
import Firestore from '../../api/firebase/Firestore';
import moment from "moment-timezone";
import { Doughnut, Bar } from 'react-chartjs-2';
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import RefreshIcon from '@material-ui/icons/Refresh';
import SessionHelper from '../../helper/SessionHelper';
import CurrencyHelper from '../../helper/CurrencyHelper';
import PagarmeConstants from '../../constants/PagarMe';

export default class HomePage extends Component {

    state = {
        loading: true,
        tournamentDocs: [],
        userDocs: [],
        orderDocs: [],
        tournamentTicketSalesChart_start: moment().startOf('month').toDate(),
        tournamentTicketSalesChart_end: moment().endOf('month').toDate(),
        tournamentTicketSalesChart: {
            labels: [],
            datasets: [
                {
                    label: 'Lucro',
                    backgroundColor: '#36A2EB',
                    borderWidth: 1,
                    data: []
                },
            ]
        },
        tournamentParticipantsChart_start: moment().startOf('month').toDate(),
        tournamentParticipantsChart_end: moment().endOf('month').toDate(),
        tournamentParticipantsChart: {
            labels: [],
            datasets: [
                {
                    label: 'Limite',
                    backgroundColor: '#36A2EB',
                    borderWidth: 1,
                    data: []
                },
                {
                    label: 'Inscritos',
                    backgroundColor: '#36A2EB',
                    borderWidth: 1,
                    data: []
                }
            ]
        },
        categoryChart: [],
        categoryChart_start: moment().startOf('month').toDate(),
        categoryChart_end: moment().endOf('month').toDate(),
    }

    async getTournaments() {

        let query = await Firestore.customQuery('tournament').where('id_company', '==', SessionHelper.getData().id_company).get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ tournamentDocs: docs });
    }

    async getUsers() {

        let query = await Firestore.customQuery('user').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ userDocs: docs });
    }

    async getOrders() {

        let query = await Firestore.customQuery('order').where('id_company', '==', SessionHelper.getData().id_company).where('type', '==', 'tournament_ticket').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ orderDocs: docs });
    }

    async componentDidMount() {

        this.setState({ loading: true });

        await this.getTournaments();
        await this.getUsers();
        await this.getOrders();

        await this.tournamentParticipantsChart();
        await this.tournamentTicketSalesChart();
        await this.categoryChart();

        this.setState({ loading: false });
    }

    async tournamentParticipantsChart() {

        let data = {
            labels: [],
            datasets: [
                {
                    label: 'Limite',
                    backgroundColor: '#FFCE56',
                    borderWidth: 1,
                    data: []
                },
                {
                    label: 'Inscritos',
                    backgroundColor: '#36A2EB',
                    borderWidth: 1,
                    data: []
                }
            ]
        };

        let tournamentParticipantsChart = {};
        let tournamentParticipantsLimitChart = {};

        this.state.tournamentDocs.forEach((tournament, key) => {

            let date = moment(tournament.created_at.toDate());
            let participants = tournament.participants.length;

            if ((moment(this.state.tournamentParticipantsChart_start).isValid() && (date.isSameOrAfter(moment(this.state.tournamentParticipantsChart_start)))) && moment(this.state.tournamentParticipantsChart_end).isValid() && (date.isSameOrBefore(moment(this.state.tournamentParticipantsChart_end)))) {

                if (tournamentParticipantsChart[tournament.name] === undefined && tournamentParticipantsLimitChart[tournament.name] === undefined) {

                    tournamentParticipantsChart[tournament.name] = participants;
                    tournamentParticipantsLimitChart[tournament.name] = tournament.participants_limit;
                    
                    data.labels.push(tournament.name);
                }
            }
        });

        Object.keys(tournamentParticipantsChart).forEach((value, key) => {
            data.datasets[1].data.push(tournamentParticipantsChart[value]);
        });

        Object.keys(tournamentParticipantsLimitChart).forEach((value, key) => {
            data.datasets[0].data.push(tournamentParticipantsLimitChart[value]);
        });

        this.setState({ tournamentParticipantsChart: data });
    }

    async tournamentTicketSalesChart() {

        let data = {
            labels: [],
            datasets: [
                {
                    label: 'Lucro',
                    backgroundColor: '#36A2EB',
                    borderWidth: 1,
                    data: []
                },
            ]
        };

        let tournamentTicketSales = {};

        this.state.tournamentDocs.forEach((tournament, key) => {

            let isEmpty = false;

            this.state.orderDocs.forEach((order, key) => {

                if (order.id_tournament === tournament.id) {

                    let date = moment(order.date.toDate());
        
                    if ((moment(this.state.tournamentTicketSalesChart_start).isValid() && (date.isSameOrAfter(moment(this.state.tournamentTicketSalesChart_start)))) && moment(this.state.tournamentTicketSalesChart_end).isValid() && (date.isSameOrBefore(moment(this.state.tournamentTicketSalesChart_end)))) {
        
                        if (tournamentTicketSales[tournament.name] === undefined) {
        
                            tournamentTicketSales[tournament.name] = 0;
                            data.labels.push(tournament.name);

                            isEmpty = true;
                        }

                        tournamentTicketSales[tournament.name] += order.amount;
                    }
                }
            });

            if (!isEmpty) {
                tournamentTicketSales[tournament.name] = 0;
                data.labels.push(tournament.name);
            }
        });

        Object.keys(tournamentTicketSales).forEach((value, key) => {
            data.datasets[0].data.push(tournamentTicketSales[value]);
        });

        this.setState({ tournamentTicketSalesChart: data });
    }

    async categoryChart() {

        let data = {
            labels: [],
            datasets: [
                {
                    backgroundColor: [],
                    data: []
                },
            ]
        };

        let categoryChart = {};

        this.state.tournamentDocs.forEach((tournament, key) => {

            let date = moment(tournament.created_at.toDate());
        
            if ((moment(this.state.categoryChart_start).isValid() && (date.isSameOrAfter(moment(this.state.categoryChart_start)))) && moment(this.state.categoryChart_end).isValid() && (date.isSameOrBefore(moment(this.state.categoryChart_end)))) {

                let categories = tournament.category;

                categories.forEach((category, key) => {

                    if (categoryChart[category] === undefined) {

                        categoryChart[category] = 0;
                        data.labels.push(category);
                    }
    
                    categoryChart[category] += 1;
                });
            }
        });

        Object.keys(categoryChart).forEach((value, key) => {
            data.datasets[0].data.push(categoryChart[value]);
            data.datasets[0].backgroundColor.push(this.dynamicColors());
        });

        this.setState({ categoryChart: data });
    }

    dynamicColors() {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        
        return "rgb(" + r + "," + g + "," + b + ")";
    }

    render() {

        return this.state.loading ? <DefaultLoader /> : (

            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
                <Card style={{ padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '49%', height: 'auto', minHeight: 370, margin: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', marginBottom: 20, marginTop: 10 }}>
                        <div style={{ fontSize: 20 }}>{'Participantes por Torneio'}</div>
                        <Tooltip onClick={async () => { await this.getTournaments(); await this.tournamentParticipantsChart(); }} title="Atualizar"><IconButton><RefreshIcon style={{}} /></IconButton></Tooltip>
                    </div>
                    <Bar
                        height={60}
                        options={{ maintainAspectRatio: true, scales: { yAxes: [{ ticks: { beginAtZero: true, precision: 0 } }] } }}
                        data={this.state.tournamentParticipantsChart} />

                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', alignSelf: 'flex-end', marginTop: 20 }}>
                        <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                style={{ width: '100%', padding: 15 }}
                                invalidDateMessage={false}
                                format={'dd/MM/yyyy'}
                                autoOk={true}
                                cancelLabel={'Cancelar'}
                                okLabel={'Confirmar'}
                                onChange={async (v) => { await this.setState({ tournamentParticipantsChart_start: v }); this.tournamentParticipantsChart(); }}
                                value={this.state.tournamentParticipantsChart_start}
                            />
                        </MuiPickersUtilsProvider>
                        <div style={{ color: 'grey' }}>{'até'}</div>
                        <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                style={{ width: '100%', padding: 15 }}
                                invalidDateMessage={false}
                                format={'dd/MM/yyyy'}
                                autoOk={true}
                                cancelLabel={'Cancelar'}
                                okLabel={'Confirmar'}
                                onChange={async (v) => { await this.setState({ tournamentParticipantsChart_end: v }); this.tournamentParticipantsChart(); }}
                                value={this.state.tournamentParticipantsChart_end}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </Card>
                <Card style={{ padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '49%', height: 'auto', minHeight: 370, margin: 5 }}>

                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', marginBottom: 20, marginTop: 10 }}>
                        <div style={{ fontSize: 20 }}>{'Lucro por Torneio'}</div>
                        <Tooltip onClick={async () => { await this.getOrders(); await this.getTournaments(); await this.tournamentTicketSalesChart(); }} title="Atualizar"><IconButton><RefreshIcon style={{}} /></IconButton></Tooltip>
                    </div>

                    <Bar
                        height={60}
                        options={{ maintainAspectRatio: true, tooltips: { callbacks: { label: (t, d) => { return CurrencyHelper.centsToMoney(t.yLabel, true) } } }, scales: { yAxes: [{ ticks: { beginAtZero: true, precision: 0, callback: (value, index, values) => CurrencyHelper.centsToMoney(value, true) } }] } }}
                        data={this.state.tournamentTicketSalesChart} />

                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', alignSelf: 'flex-end', marginTop: 20 }}>
                        <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                style={{ width: '100%', padding: 15 }}
                                invalidDateMessage={false}
                                format={'dd/MM/yyyy'}
                                autoOk={true}
                                cancelLabel={'Cancelar'}
                                okLabel={'Confirmar'}
                                onChange={async (v) => { await this.setState({ tournamentTicketSalesChart_start: v }); this.tournamentTicketSalesChart(); }}
                                value={this.state.tournamentTicketSalesChart_start}
                            />
                        </MuiPickersUtilsProvider>
                        <div style={{ color: 'grey' }}>{'até'}</div>
                        <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                style={{ width: '100%', padding: 15 }}
                                invalidDateMessage={false}
                                format={'dd/MM/yyyy'}
                                autoOk={true}
                                cancelLabel={'Cancelar'}
                                okLabel={'Confirmar'}
                                onChange={async (v) => { await this.setState({ tournamentTicketSalesChart_end: v }); this.tournamentTicketSalesChart(); }}
                                value={this.state.tournamentTicketSalesChart_end}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </Card>
                <Card style={{ padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '99%', height: 'auto', minHeight: 370, margin: 5 }}>

                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', marginBottom: 20, marginTop: 10 }}>
                        <div style={{ fontSize: 20 }}>{'Torneios por Categoria'}</div>
                        <Tooltip onClick={async () => { await this.getTournaments(); await this.categoryChart(); }} title="Atualizar"><IconButton><RefreshIcon style={{}} /></IconButton></Tooltip>
                    </div>

                    <Doughnut
                        height={40}
                        options={{ maintainAspectRatio: true }}
                        data={this.state.categoryChart}
                    />

                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', alignSelf: 'flex-end', marginTop: 20 }}>
                        <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    style={{ width: '100%', padding: 15 }}
                                    invalidDateMessage={false}
                                    format={'dd/MM/yyyy'}
                                    autoOk={true}
                                    cancelLabel={'Cancelar'}
                                    okLabel={'Confirmar'}
                                    onChange={async (v) => { await this.setState({ categoryChart_start: v }); this.categoryChart(); }}
                                    value={this.state.categoryChart_start}
                                />
                            </MuiPickersUtilsProvider>
                            <div style={{ color: 'grey' }}>{'até'}</div>
                            <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    style={{ width: '100%', padding: 15 }}
                                    invalidDateMessage={false}
                                    format={'dd/MM/yyyy'}
                                    autoOk={true}
                                    cancelLabel={'Cancelar'}
                                    okLabel={'Confirmar'}
                                    onChange={async (v) => { await this.setState({ categoryChart_end: v }); this.categoryChart(); }}
                                    value={this.state.categoryChart_end}
                                />
                            </MuiPickersUtilsProvider>
                    </div>
                </Card>
            </div>
        )
    }
}