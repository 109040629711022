import React from "react";
import moment from "moment-timezone";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Platform from '../constants/Platform';
import { Divider, Drawer, Hidden, Collapse, Button, TextareaAutosize, IconButton, SvgIcon, Tooltip } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ChevronRight from "@material-ui/icons/ChevronRight";
import PieChartIcon from "@material-ui/icons/PieChart";
import transitions from "@material-ui/core/styles/transitions";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import WidgetsIcon from "@material-ui/icons/Widgets";
import MobileScreenShareIcon from "@material-ui/icons/MobileScreenShare";
import SettingsIcon from "@material-ui/icons/Settings";
import InfoIcon from "@material-ui/icons/Info";
import StoreIcon from "@material-ui/icons/Store";
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import SendIcon from '@material-ui/icons/Send';
import LockIcon from "@material-ui/icons/Lock";
import RedditIcon from "@material-ui/icons/Reddit";
import PeopleIcon from "@material-ui/icons/People";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import PersonIcon from "@material-ui/icons/Person";
import MenuIcon from '@material-ui/icons/Menu';
import Brightness4Icon from "@material-ui/icons/Brightness4";
import PrintIcon from "@material-ui/icons/Print";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AssignmentIcon from "@material-ui/icons/Assignment";
import WorkIcon from "@material-ui/icons/Work";
import ContactsIcon from '@material-ui/icons/Contacts';
import LayersIcon from "@material-ui/icons/Layers";
import TuneIcon from "@material-ui/icons/Tune";
import FavoriteIcon from "@material-ui/icons/Favorite";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import SchoolICon from "@material-ui/icons/School";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import EventIcon from "@material-ui/icons/Event";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ReceiptIcon from "@material-ui/icons/Receipt";
import SportsTennisIcon from "@material-ui/icons/SportsTennis";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import ImportExportIcon from '@material-ui/icons/ImportExport';
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Switch, Route, Link, BrowserRouter, Redirect } from "react-router-dom";
import firebase from 'firebase/app'
import Colors from '../constants/Colors';
import HomePage from '../page/HomePage';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import SessionHelper from '../helper/SessionHelper';
import CourtPage from '../page/CourtPage';
import StudentPage from "../page/StudentPage";
import TeacherPage from "../page/TeacherPage";
import AgendaPage from "../page/AgendaPage";
import TournamentPage from "../page/TournamentPage";
import ProductPage from "../page/ProductPage";
import CompanyPage from "../page/CompanyPage";
import OrderPage from "../page/OrderPage";
import WalletPage from "../page/WalletPage";
import ReportPage from "../page/ReportPage";
import SubscriptionPage from "../page/SubscriptionPage";
import UserPage from "../page/UserPage";
import PermissionPage from "../page/PermissionPage";
import FeedPage from "../page/FeedPage";
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import RatingPage from "../page/RatingPage";
import PaymentAccountPage from "../page/PaymentAccountPage";
import PaymentMissingPage from "../page/PaymentMissingPage";
import ParamPage from "../page/ParamPage";
import NotificationCenter from "../components/NotificationCenter";
import CompanyTask from "../components/CompanyTask";
import StudentPlanPage from "../page/StudentPlanPage";
import MembershipTermsPage from "../page/MembershipTermsPage";
import Pagarme from '../api/pagarme/Pagarme';
import NotFoundPage from "../page/NotFoundPage";
import ExperimentalLessonPage from "../page/ExperimentalLessonPage";
import PrivateLessonPage from "../page/PrivateLessonPage";
import PrivateLessonPacksPage from "../page/PrivateLessonPacksPage";
import Functions from "../api/firebase/Functions";
import CompanyBadge from "../components/CompanyBadge";
import Firestore from "../api/firebase/Firestore";
import CourtRentPacksPage from "../page/CourtRentPacksPage";
import StudentNotificationPage from "../page/StudentNotificationPage";
import LocalNotificationHelper from "../helper/LocalNotificationHelper";
import EmojiEvents from "@material-ui/icons/EmojiEvents";
import ProfileCard from "../components/ProfileCard";
import StudentSubscriptionRequest from "../page/StudentSubscriptionRequestPage";
import ActivePacksPage from "../page/ActivePacksPage";
import TermHelper from "../helper/TermHelper";
import DefaultButton from "../components/DefaultButton";
import SalesPage from "../page/SalesPage";
import InventoryMovementPage from "../page/InventoryMovementPage";
import DayUsePage from "../page/DayUsePage";
import { withTranslation } from "react-i18next";
import LanguageSelector from "../components/LanguageSelector";
import ProviderPage from "../page/ProviderPage";
import ExpensePage from "../page/ExpensePage";
import RankingPage from "../page/RankingPage";
import HowToPermissionsPage from "../page/HowToPermissionsPage";
import ClientPage from "../page/ClientPage";
import SignContractPage from "../page/SignContractPage";

const drawerWidth = 240;
const closedDrawerWidth = window.screen.width < 1080 ? 0 : 60;

class navigation extends React.Component {

    async logout() { await firebase.auth().signOut(); window.location.href = '/' }

    state = {
        permRoute: null,
        actualRoute: null,
        settingsMenuOpen: false,
        financialMenuOpen: false,
        storeMenuOpen: false,
        socialMenuOpen: false,
        packMenuOpen: false,
        aboutModal: false,
        helpModal: false,
        welcomeModal: false,
        subject: '',
        message: '',
        plan: SessionHelper.getData().plan || null,
        paymentCheck: true,
        expirationDays: null,
        openDrawer: true,
        buttonHeight: 59,
        studentMenuLabel: TermHelper.getestablishmentTypeLabel(this.props.establishmentType),
    }

    async componentDidMount() {
        await this.paymentCheck();
        await this.setDrawerBasedOnDevice();

        LocalNotificationHelper.askPermission();

        let welcomeModal = !SessionHelper.getData().company.welcome_modal ? true : false;

        this.setState({ actualRoute: window.location.pathname, welcomeModal: welcomeModal });
    }

    handleRouteChange(permRoute) {
        setTimeout(() => {
            this.setState({ permRoute: permRoute || null, actualRoute: window.location.pathname, openDrawer: window.screen.width < 1080 && this.state.openDrawer == true ? false : this.state.openDrawer });
        }, 10)
    }

    handleClick(menu) {
        let state = this.state;
        state[menu] = !state[menu];

        this.setState(state);
    }

    handleDrawer = () => {
        this.setState({ openDrawer: !this.state.openDrawer })
    }

    async setDrawerBasedOnDevice() {
        if (window.screen.width < 1360) this.setState({ openDrawer: false, buttonHeight: 80 })
    }

    async paymentCheck() {

        try {

            let id_subscription_pagarme = SessionHelper.getData().company.id_subscription_pagarme;

            if (id_subscription_pagarme) {

                let response = await Pagarme.get(`/subscriptions/${id_subscription_pagarme}`, {});

                if (response.errors && response.errors.length) {
                    throw new Error('Payment Error');
                }

                if (response.status === 'canceled') {
                    throw new Error('Payment Error');
                }

                if (response.current_transaction && response.current_transaction.payment_method === 'boleto' && response.current_transaction.status !== 'paid') {

                    if (moment(response.current_transaction.boleto_expiration_date).isBefore(moment())) {

                        let expirationDays = moment().diff(moment(response.current_transaction.boleto_expiration_date).set('hours', 0), 'days');

                        this.setState({ expirationDays: expirationDays });

                        if (expirationDays > 5) {
                            throw new Error('Payment Error');
                        }
                    }
                }

                if (response.payment_method === 'credit_card' && response.status !== 'paid') {

                    let expirationDays = moment().diff(moment(response.current_period_end).set('hours', 0), 'days');

                    this.setState({ expirationDays: expirationDays });

                    if (expirationDays > 5) {
                        throw new Error('Payment Error');
                    }
                }

                this.setState({ paymentCheck: true });

            } else if (SessionHelper.getData().company.trial) {

                let trialDate = SessionHelper.getData().company.trial_expiration_date;
                let trialExpirationDate = moment(trialDate.toDate ? trialDate.toDate() : trialDate);

                if (trialExpirationDate.isBefore(moment())) {

                    throw new Error('Payment Error');
                }

                this.setState({ paymentCheck: true });

            } else {

                throw new Error('Payment Error');
            }

        } catch (error) {
console.log(error);
            this.setState({ paymentCheck: false });
        }
    }

    handleWelcomeModal() {

        this.setState({ welcomeModal: false });
        Firestore.update({ welcome_modal: true }, 'company', SessionHelper.getData().id_company);
    }

    aboutModal() {
        if (this.state.plan && this.state.aboutModal) {
            return (
                <div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <img style={{ height: 65, marginLeft: 'auto', marginRight: 'auto', marginBottom: 8 }} src={process.env.PUBLIC_URL + '/logo.png'} />
                        <div style={{ textAlign: 'center', fontSize: 11, color: 'grey', marginTop: 10 }}>
                            {`Licensiado para `}
                            <strong>{SessionHelper.getData().company.name}</strong>
                            <div style={{ paddingBottom: 15 }}>Plano <strong>{SessionHelper.getData().plan.name}</strong></div>
                            <div style={{ borderBottom: '1px solid lightgrey', marginLeft: 'auto', marginRight: 'auto', paddingBottom: 15, width: '70%' }}></div>
                        </div>
                        <div style={{ textAlign: 'center', fontSize: 11, color: 'grey', marginTop: 25 }}>Plataforma Ace | Versão {Platform.version}</div>
                        <div style={{ textAlign: 'center', fontSize: 11, color: 'grey' }}>Copyright © 2021 Todos os direitos reservados</div>

                        <div style={{ fontSize: 11, color: 'grey', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 25 }}>Desenvolvido com <FavoriteIcon style={{ marginLeft: 5, marginRight: 5, color: 'red' }} /> por <a target={'_blank'} href={'https://easydata.net.br/'} style={{ paddingLeft: 3, color: 'blue' }}>EasyData Softwares LTDA</a></div>
                        <div style={{ textAlign: 'center', fontSize: 11, color: 'grey' }}>CNPJ 35.698.234/0001-39</div>
                    </div>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { this.setState({ aboutModal: false }) }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '100%' }} variant={'contained'}>{'FECHAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    welcomeModal() {
        if (this.state.welcomeModal) {
            return (
                <div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <img style={{ height: 250, marginLeft: 'auto', marginRight: 'auto', marginBottom: 8 }} src={process.env.PUBLIC_URL + '/ilustrations/celebration.png'} />
                        <div style={{ textAlign: 'center', fontSize: 14, color: 'grey', marginTop: 25 }}>{`Ficamos felizes com o seu primeiro acesso `}<strong>{SessionHelper.getData().company.name}</strong>.</div>
                        <div style={{ textAlign: 'center', fontSize: 14, color: 'grey' }}>Agora é só configurar e começar a aproveitar.</div>

                        {/* <div style={{ textAlign: 'center', fontSize: 12, color: 'grey', marginTop: 25 }}>Caso precise de ajuda, acesse esse <a href="http://bg-tennis.web.app" target="_blank">link</a>.</div> */}
                    </div>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { this.handleWelcomeModal() }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '100%' }} variant={'contained'}>{'FECHAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    async sendMessage() {

        this.setState({ loadingModal: true });

        await Functions.request('POST', 'createIssue', { summary: this.state.subject, text: `Descrição do Cliente: ${this.state.message}`, company: `Empresa: ${SessionHelper.getData().company.name}` });

        this.setState({ helpModal: false, loadingModal: false });

        toast.success('Recebemos a sua mensagem, em breve entraremos em contato', { autoClose: false });
    }

    getRoutePermission(hasPermition, route) {
        let granted = null;

        let permConditions = SessionHelper.getData().type === 'super_admin' || (SessionHelper.getData().permission && SessionHelper.getData().permission.routes[route]);

        if (!hasPermition || permConditions) {
            granted = true
        }

        return granted;
    }

    getDrawerListItemPermission(hasPermition, route, iconComponent, label, level, image) {
        if (!level) level = 1;

        let component = null;

        let permConditions = SessionHelper.getData().type === 'super_admin' || (SessionHelper.getData().permission && SessionHelper.getData().permission.routes[route]);

        if (!hasPermition || permConditions) {
            component = (
                <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === `/${route}` && true} style={this.state.actualRoute === `/${route}` ? { backgroundColor: Colors.primary, paddingLeft: this.state.openDrawer ? 16 * level : 16, height: this.state.buttonHeight } : { paddingLeft: this.state.openDrawer ? 16 * level : 16, height: this.state.buttonHeight }} component={Link} button to={`/${route}`}>
                    <ListItemIcon>{!image && iconComponent ? <SvgIcon component={iconComponent} style={this.state.actualRoute === `/${route}` ? { color: Colors.selected } : { color: Colors.notSelected }} /> : <img src={process.env.PUBLIC_URL + image} style={this.state.actualRoute === `/${route}` ? { filter: `brightness(0) invert(1)`, height: 24 } : { height: 24 }}></img>}</ListItemIcon>
                    <ListItemText style={this.state.actualRoute === `/${route}` ? { color: Colors.selected } : { color: Colors.notSelected }} primary={this.state.openDrawer ? label : ''} />
                </ListItem>
            );
        } else {
            component = (
                <ListItem onClick={() => { this.handleRouteChange(route, iconComponent, label, image); }} selected={this.state.permRoute === route && true} style={this.state.permRoute === route ? { backgroundColor: Colors.error, paddingLeft: this.state.openDrawer ? 16 * level : 16, height: this.state.buttonHeight } : { paddingLeft: this.state.openDrawer ? 16 * level : 16, height: this.state.buttonHeight }} component={Link} button to={`/how_to_permissions`}>
                    <ListItemIcon>
                        <Tooltip title={'Você não tem permissão para acessar essa página, clique para saber mais.'}>
                            <div style={{
                                backgroundColor: Colors.error,
                                borderRadius: 10,
                                paddingLeft: this.state.permRoute === route ? 2 : 9,
                                paddingRight: 9,
                                paddingTop: 3,
                                paddingBottom: 3,
                                alignItems: 'center',
                                alignContent: 'center',
                                display: 'flex',
                                justifyContent: 'center'
                            }}><LockIcon style={{ color: Colors.dark, fontSize: this.state.permRoute === route ? 24 : 14 }} /></div>
                        </Tooltip>
                    </ListItemIcon>
                    <ListItemText style={this.state.permRoute === route ? { color: Colors.dark } : { color: Colors.notSelected }} primary={this.state.openDrawer ? label : ''} />
                </ListItem>
            );
        }

        return component;
    }

    getLinkListItem(onClick, iconComponent, label, level, image) {
        if (!level) level = 1;

        let component = (
            <ListItem onClick={onClick} style={{ paddingLeft: this.state.openDrawer ? 16 * level : 16, height: this.state.buttonHeight, color: Colors.notSelected }} component={Link} button>
                <ListItemIcon>{!image && iconComponent ? <SvgIcon component={iconComponent} style={{ color: Colors.notSelected }} /> : <img src={process.env.PUBLIC_URL + image} style={{ height: 24 }}></img>}</ListItemIcon>
                <ListItemText primary={this.state.openDrawer ? label : ''} />
            </ListItem>
        );

        return component;
    }

    getCollapseListPermission(hasPermition, routes, iconComponent, stateControl, label, level) {
        if (!level) level = 1;

        let component = null;

        let baseConditions = SessionHelper.getData().type === 'super_admin' || SessionHelper.getData().permission == true;
        let hasPermInAtLeastOneRoute = false;

        if (hasPermition) {
            routes.forEach(route => {
                if (SessionHelper.getData().permission.routes[route]) hasPermInAtLeastOneRoute = true;
            });
        }

        if (!hasPermition || (baseConditions && hasPermInAtLeastOneRoute)) {
            component = (
                <ListItem style={{ paddingLeft: this.state.openDrawer ? 16 * level : 16, height: this.state.buttonHeight, backgroundColor: this.state[stateControl] ? 'rgba(15, 119, 232, 0.20)' : Colors.dark, color: this.state[stateControl] ? Colors.selected : Colors.notSelected }} selected={this.state[stateControl] ? true : false} button onClick={() => { this.handleClick(stateControl) }}>
                    <ListItemIcon>
                        <SvgIcon component={iconComponent} style={{ color: this.state[stateControl] ? Colors.selected : Colors.notSelected }} />
                    </ListItemIcon>
                    <ListItemText primary={this.state.openDrawer ? label : ``} />
                    {this.state[stateControl] ? <ExpandMore /> : <ChevronRight />}
                </ListItem>
            );
        }

        return component;
    }

    helpModal() {
        if (this.state.helpModal) {
            return (
                <div>
                    <div style={{ paddingBottom: 20 }}>{'Tem algum problema ou sugestão para o ACE? Envie a sua mensagem e entraremos em contato para solucionar o seu problema o mais rápido possível.'}</div>
                    <DefaultInput onBlur={(text) => { this.setState({ subject: text }) }} label={'Assunto'} />
                    <TextareaAutosize style={{ width: '100%', borderRadius: 5, borderColor: 'lightgrey', padding: 15, fontSize: '1rem', marginTop: 20 }} rowsMax={8} rowsMin={8} onBlur={(v) => { this.setState({ message: v.target.value }) }} placeholder="Descreva seu problema ou sugestão..." />
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.sendMessage() }} color={Colors.primary} loading={this.state.loadingModal} width={'100%'} title={'ENVIAR MENSAGEM'} />
                    </div>
                </div>
            )
        }
    }

    render() {
        const { t } = this.props;

        return (
            <div style={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" style={
                    this.state.openDrawer == false ?
                        styles.appBarOpen :
                        styles.appBarClosed
                }>
                    <Toolbar style={{ width: '100%', justifyContent: 'space-between', height: 64 }}>
                        <IconButton
                            aria-label="Gaveta Aberta"
                            onClick={this.handleDrawer}
                            edge="start"
                            style={styles.iconButtonAppBar}
                        >
                            {this.state.openDrawer ? <ChevronLeft /> : <MenuIcon />}
                        </IconButton>

                        {SessionHelper.getData().company.logo && <img style={{ height: 42 }} src={process.env.PUBLIC_URL + '/bg-wide-white.png'} />}

                        <div style={{ display: "flex", flexDirection: 'row', marginRight: -15 }}>
                            {SessionHelper.getData().company.visible === false ? <CompanyBadge href={'/company'} tooltip={'Quando a sua empresa não está visível, os usuários não verão: a sua empresa listada, aluguéis de quadra, aulas experimentais, aulas avulsas e torneios'} title={'Sua empresa não está visível no App'} /> : null}
                            {this.state.expirationDays ? <CompanyBadge tooltip={'Após 5 dias a sua plataforma será bloqueada até a confirmação do pagamento'} href={'/subscription'} title={`Pagamento vencido à ${this.state.expirationDays} ${this.state.expirationDays > 1 ? 'dias' : 'dia'}`} /> : null}
                            <LanguageSelector />
                            <CompanyTask ref={(ref) => { this.tasks = ref; }} />
                            <NotificationCenter />
                            <ProfileCard showLogout={true} />
                            {SessionHelper.getData().type && SessionHelper.getData().type === 'super_admin' ? <IconButton onClick={() => { window.location.reload() }}><SwapHorizIcon style={{ color: '#fff' }} /></IconButton> : null}
                        </div>
                    </Toolbar>
                </AppBar>
                <BrowserRouter forceRefresh={false}>
                    <nav className={this.state.openDrawer ? 'menu-open' : 'menu-closed'} style={{ width: this.state.openDrawer ? drawerWidth : closedDrawerWidth, flexShrink: 0, flexShrink: 0, }} aria-label="mailbox folders">
                        <Drawer
                            PaperProps={{ style: this.state.openDrawer ? styles.drawerOpen : styles.drawerClose }}
                            variant="permanent"
                            open>
                            <div>
                                <div />

                                <List>
                                    <ListItem style={{ paddingTop: 0, paddingBottom: 0 }} component={Link} button to={'/'} onClick={() => { this.setState({ actualRoute: '/' }) }}>
                                        {!SessionHelper.getData().company.logo ? <img style={{ height: 32, marginBottom: 15, marginTop: 8 }} src={process.env.PUBLIC_URL + '/bg-wide-white.png'} /> : <img style={{ height: 38, marginBottom: 15 }} src={SessionHelper.getData().company.logo} />}
                                    </ListItem>

                                    {this.getDrawerListItemPermission(false, ``, PieChartIcon, t('sideMenu.dashboard'))}

                                    {this.getCollapseListPermission(false, [], NoteAddIcon, 'recordsMenu', t('sideMenu.records'))}
                                    <Collapse in={this.state.recordsMenu} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {this.getDrawerListItemPermission(true, `student`, PeopleIcon, this.state.studentMenuLabel.plural, 2)}
                                            {this.getDrawerListItemPermission(true, `client`, ContactsIcon, t('sideMenu.client'), 2)}
                                            {this.getDrawerListItemPermission(true, `court`, false, t('sideMenu.court'), 2, '/icons/tennis-court-grey.png')}
                                            {this.getDrawerListItemPermission(true, `teacher`, RecordVoiceOverIcon, t('sideMenu.teacher'), 2)}
                                            {this.getDrawerListItemPermission(true, `provider`, LocalShippingIcon, t('sideMenu.provider'), 2)}
                                            {this.getDrawerListItemPermission(true, `product`, LocalOfferIcon, t('sideMenu.product'), 2)}
                                            {this.getDrawerListItemPermission(true, `inventory_movement`, WidgetsIcon, t('sideMenu.inventory_movement'), 2)}
                                            {this.getCollapseListPermission(false, [`private_lesson_packs`, `court_rent_packs`], LayersIcon, 'packMenuOpen', t('sideMenu.packs'), 2)}
                                            <Collapse in={this.state.packMenuOpen} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    {this.getDrawerListItemPermission(true, `private_lesson_packs`, SportsTennisIcon, t('sideMenu.private_lesson_packs'), 3)}
                                                    {this.getDrawerListItemPermission(true, `court_rent_packs`, false, t('sideMenu.court_rent_packs'), 3, '/icons/tennis-court-grey.png')}
                                                </List>
                                            </Collapse>
                                            {this.getDrawerListItemPermission(true, `student_plan`, SchoolICon, t('sideMenu.student_plan'), 2)}
                                            {this.getDrawerListItemPermission(true, `membership_terms`, AssignmentTurnedInIcon, t('sideMenu.membership_terms'), 2)}
                                        </List>
                                    </Collapse>

                                    {this.getDrawerListItemPermission(true, `sales`, ShoppingCartIcon, t('sideMenu.sales'))}

                                    {this.getCollapseListPermission(false, [], EventIcon, 'agendaMenuOpen', t('sideMenu.agenda'))}
                                    <Collapse in={this.state.agendaMenuOpen} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {this.getDrawerListItemPermission(true, `agenda`, EventIcon, t('sideMenu.agenda'), 2)}
                                            {this.getDrawerListItemPermission(true, `experimental_lesson`, false, t('sideMenu.experimental_lesson'), 2, '/icons/flask-white.png')}
                                            {this.getDrawerListItemPermission(true, `private_lesson`, SportsTennisIcon, t('sideMenu.private_lesson'), 2)}
                                        </List>
                                    </Collapse>

                                    {this.getCollapseListPermission(false, [], EmojiEvents, 'eventsMenuOpen', t('sideMenu.events'))}
                                    <Collapse in={this.state.eventsMenuOpen} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                        {this.getDrawerListItemPermission(true, `tournament`, EmojiEvents, t('sideMenu.tournament'), 2)}
                                        {this.getDrawerListItemPermission(true, `ranking`, ImportExportIcon, t('sideMenu.ranking'), 2)}
                                        {this.getDrawerListItemPermission(true, `day_use`, Brightness4Icon, t('sideMenu.day_use'), 2)}
                                        </List>
                                    </Collapse>

                                    {this.getCollapseListPermission(false, [`wallet`], AttachMoneyIcon, 'financialMenuOpen', t('sideMenu.financial'))}
                                    <Collapse in={this.state.financialMenuOpen} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {this.getDrawerListItemPermission(true, `wallet`, AccountBalanceWalletIcon, t('sideMenu.wallet'), 2)}
                                            {this.getDrawerListItemPermission(true, `expense`, TrendingDownIcon, t('sideMenu.expense'), 2)}
                                            {this.getDrawerListItemPermission(true, `active_packs`, VisibilityIcon, t('sideMenu.active_packs'), 2)}
                                            {this.getDrawerListItemPermission(true, `report`, PrintIcon, t('sideMenu.report'), 2)}
                                            {this.getDrawerListItemPermission(true, `order`, ReceiptIcon, t('sideMenu.order'), 2)}
                                            {this.getDrawerListItemPermission(true, `subscription`, WorkIcon, t('sideMenu.subscription'), 2)}
                                        </List>
                                    </Collapse>

                                    {this.getCollapseListPermission(false, [`subscription`, `user`, `payment_account`, `permission`, `params`], SettingsIcon, 'settingsMenuOpen', t('sideMenu.configs'))}
                                    <Collapse in={this.state.settingsMenuOpen} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {this.getDrawerListItemPermission(true, `company`, AssignmentIcon, t('sideMenu.company'), 2)}
                                            {this.getDrawerListItemPermission(true, `user`, PersonIcon, t('sideMenu.user'), 2)}
                                            {this.getDrawerListItemPermission(true, `payment_account`, CreditCardIcon, t('sideMenu.payment_account'), 2)}
                                            {this.getDrawerListItemPermission(true, `permission`, LockIcon, t('sideMenu.permission'), 2)}
                                            {this.getDrawerListItemPermission(true, `params`, TuneIcon, t('sideMenu.params'), 2)}
                                            {this.getLinkListItem(() => { this.setState({ aboutModal: true }) }, InfoIcon, t('sideMenu.about'), 2)}
                                        </List>

                                    </Collapse>

                                    {this.getCollapseListPermission(false, [`ratings`], RedditIcon, 'socialMenuOpen', t('sideMenu.social'))}
                                    <Collapse in={this.state.socialMenuOpen} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {this.getDrawerListItemPermission(true, `student_subscription_request`, MobileScreenShareIcon, t('sideMenu.student_subscription_request'), 2)}
                                            {this.getDrawerListItemPermission(true, `ratings`, ThumbUpIcon, t('sideMenu.ratings'), 2)}
                                            {this.getDrawerListItemPermission(true, `student_notification`, SendIcon, t('sideMenu.student_notification'), 2)}
                                        </List>
                                    </Collapse>
                                    

                                    {this.getLinkListItem(() => { this.setState({ helpModal: true }) }, HeadsetMicIcon, t('sideMenu.support'))} 
                                </List>
                            </div>
                        </Drawer>
                    </nav>

                    <main style={{ flexGrow: 1, paddingTop: 60 }}>
                        <Switch>
                            <Route exact path="/" render={() => this.state.paymentCheck ? <HomePage /> : <PaymentMissingPage />} />
                            {this.getRoutePermission(true, 'court') ? <Route path="/court" render={() => this.state.paymentCheck ? <CourtPage tasks={this.tasks} menuOpen={this.state.openDrawer} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'student') ? <Route path="/student" render={() => this.state.paymentCheck ? <StudentPage menuOpen={this.state.openDrawer} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'teacher') ? <Route path="/teacher" render={() => this.state.paymentCheck ? <TeacherPage menuOpen={this.state.openDrawer} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'agenda') ? <Route path="/agenda" render={() => this.state.paymentCheck ? <AgendaPage /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'tournament') ? <Route path="/tournament" render={() => this.state.paymentCheck ? <TournamentPage menuOpen={this.state.openDrawer} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'day_use') ? <Route path="/day_use" render={() => this.state.paymentCheck ? <DayUsePage menuOpen={this.state.openDrawer} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'sales') ? <Route path="/sales" render={() => this.state.paymentCheck ? <SalesPage menuOpen={this.state.openDrawer} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'product') ? <Route path="/product" render={() => this.state.paymentCheck ? <ProductPage menuOpen={this.state.openDrawer} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'inventory_movement') ? <Route path="/inventory_movement" render={() => this.state.paymentCheck ? <InventoryMovementPage menuOpen={this.state.openDrawer} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'company') ? <Route path="/company" render={() => this.state.paymentCheck ? <CompanyPage navigation={this} tasks={this.tasks} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'order') ? <Route path="/order" render={() => this.state.paymentCheck ? <OrderPage menuOpen={this.state.openDrawer} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'wallet') ? <Route path="/wallet" render={() => this.state.paymentCheck ? <WalletPage menuOpen={this.state.openDrawer} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'expense') ? <Route path="/expense" render={() => this.state.paymentCheck ? <ExpensePage menuOpen={this.state.openDrawer} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'provider') ? <Route path="/provider" render={() => this.state.paymentCheck ? <ProviderPage menuOpen={this.state.openDrawer} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'subscription') ? <Route path="/subscription" render={() => <SubscriptionPage menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'user') ? <Route path="/user" render={() => this.state.paymentCheck ? <UserPage menuOpen={this.state.openDrawer} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'permission') ? <Route path="/permission" render={() => this.state.paymentCheck ? <PermissionPage menuOpen={this.state.openDrawer} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'params') ? <Route path="/params" render={() => this.state.paymentCheck ? <ParamPage /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'payment_account') ? <Route path="/payment_account" render={() => this.state.paymentCheck ? <PaymentAccountPage tasks={this.tasks} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'feed') ? <Route path="/feed" render={() => this.state.paymentCheck ? <FeedPage menuOpen={this.state.openDrawer} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'ratings') ? <Route path="/ratings" render={() => this.state.paymentCheck ? <RatingPage menuOpen={this.state.openDrawer} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'student_plan') ? <Route path="/student_plan" render={() => this.state.paymentCheck ? <StudentPlanPage menuOpen={this.state.openDrawer} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'membership_terms') ? <Route path="/membership_terms" render={() => this.state.paymentCheck ? <MembershipTermsPage menuOpen={this.state.openDrawer} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'experimental_lesson') ? <Route path="/experimental_lesson" render={() => this.state.paymentCheck ? <ExperimentalLessonPage menuOpen={this.state.openDrawer} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'private_lesson') ? <Route path="/private_lesson" render={() => this.state.paymentCheck ? <PrivateLessonPage menuOpen={this.state.openDrawer} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'private_lesson_packs') ? <Route path="/private_lesson_packs" render={() => this.state.paymentCheck ? <PrivateLessonPacksPage menuOpen={this.state.openDrawer} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'court_rent_packs') ? <Route path="/court_rent_packs" render={() => this.state.paymentCheck ? <CourtRentPacksPage menuOpen={this.state.openDrawer} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'student_notification') ? <Route path="/student_notification" render={() => this.state.paymentCheck ? <StudentNotificationPage menuOpen={this.state.openDrawer} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'student_subscription_request') ? <Route path="/student_subscription_request" render={() => this.state.paymentCheck ? <StudentSubscriptionRequest menuOpen={this.state.openDrawer} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'active_packs') ? <Route path="/active_packs" render={() => this.state.paymentCheck ? <ActivePacksPage menuOpen={this.state.openDrawer} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'report') ? <Route path="/report" render={() => this.state.paymentCheck ? <ReportPage menuOpen={this.state.openDrawer} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'ranking') ? <Route path="/ranking" render={() => this.state.paymentCheck ? <RankingPage menuOpen={this.state.openDrawer} /> : <PaymentMissingPage />} /> : null}
                            {this.getRoutePermission(true, 'client') ? <Route path="/client" render={() => this.state.paymentCheck ? <ClientPage menuOpen={this.state.openDrawer} /> : <PaymentMissingPage />} /> : null}
                            <Route exact path="/how_to_permissions" render={() => this.state.paymentCheck ? <HowToPermissionsPage /> : <PaymentMissingPage />} />
                            <Route path="/sign_contract" render={() => <SignContractPage/> } />
                            <Route path="/404" render={() => <NotFoundPage height={'calc(100vh - 60px)'} />} />
                            <Redirect to="/404" />
                        </Switch>
                    </main>
                </BrowserRouter>
                <ToastContainer style={{ paddingTop: 100 }} />
                <DefaultModal loading={this.state.loadingModal} content={this.aboutModal()} title={'Sobre'} onClose={() => { this.setState({ aboutModal: false }) }} open={this.state.aboutModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.welcomeModal()} title={'Seja Bem Vindo!'} onClose={() => { this.handleWelcomeModal(); }} open={this.state.welcomeModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.helpModal()} title={'Suporte e Sugestões'} onClose={() => { this.setState({ helpModal: false }) }} open={this.state.helpModal} />
            </div>
        )
    }
}

const styles = {
    appBarOpen: {
        zIndex: 100,
        justifyContent: 'space-between',
        width: `100%`,
        marginLeft: 0,
        backgroundColor: Colors.primary,
        transition: transitions.create(['width', 'margin'], {
            easing: transitions.easing.sharp,
            duration: transitions.duration.leavingScreen,
        }),
    },
    appBarClosed: {
        zIndex: 100,
        justifyContent: 'space-between',
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        backgroundColor: Colors.primary,
        transition: transitions.create(['width', 'margin'], {
            easing: transitions.easing.sharp,
            duration: transitions.duration.enteringScreen,
        })
    },
    iconButtonAppBar: {
        color: `#fff`,
    },
    drawerOpen: {
        zIndex: 99,
        width: drawerWidth,
        backgroundColor: Colors.dark,
        transition: transitions.create('width', {
            easing: transitions.easing.sharp,
            duration: transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        zIndex: 99,
        backgroundColor: Colors.dark,
        transition: transitions.create('width', {
            easing: transitions.easing.sharp,
            duration: transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: closedDrawerWidth
    }
}

export default withTranslation()(navigation);