import React from 'react';
import Colors from '../constants/Colors';
import ClipLoader from "react-spinners/ClipLoader";
import SalesHelper from '../helper/SalesHelper';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import TicketCard from './TicketCard';
import Firestore from '../api/firebase/Firestore';
import SaleCard from './SaleCard';
import SessionHelper from '../helper/SessionHelper';
import { FormLabel } from '@material-ui/core';
import DefaultLoader from './DefaultLoader';

export default class TicketsDetailPanel extends React.Component {
    state = {
        loading: true,
        status: null,
        openTickets: [],
        historyTickets: [],
        adminUsers: [],
        historySales: []
    }

    async componentDidMount() {
        await this.getDocs();

        this.setState({ loading: false });
    }

    async componentDidUpdate(prev) {

        if (this.props.user !== prev.user) {

            await this.setState({ loading: true });
            await this.getDocs();

            this.setState({ loading: false });
        }
    }

    async getDocs() {
        await SalesHelper.getSystemUsers(this);
        await this.getOpenTickets();
        await this.getTicketHistory();
        await this.getSalesHistory();
    }

    async getOpenTickets() {
        let openTickets = await SalesHelper.getUserOpenTickets(this.props.user);
        this.setState({ openTickets: openTickets.docs });
    }

    async getTicketHistory() {
        let query = await Firestore.customQuery('order').where('id_company', '==', SessionHelper.getData().id_company).where('id_user', '==', this.props.user).where('type', '==', 'ticket').orderBy('end', 'desc').get();

        let docs = [];
        query.forEach(doc => { docs.push({ ...doc.data(), id: doc.id }) });

        this.setState({ historyTickets: docs });
    }

    async getSalesHistory() {
        let query = await Firestore.customQuery('order').where('id_company', '==', SessionHelper.getData().id_company).where('id_user', '==', this.props.user).where('type', '==', 'product').orderBy('date', 'desc').get();

        let docs = [];
        query.forEach(doc => { docs.push({ ...doc.data(), id: doc.id }) });

        this.setState({ historySales: docs });
    }

    renderLoading() {

        return (
            <div style={{ display: 'flex', justifyContent: 'center', width: '50%', padding: 25 }}>
                <ClipLoader
                    size={25}
                    color={Colors.primary}
                    loading={true}
                />
            </div>
        )
    }

    render() {
        return this.state.loading ? this.renderLoading() : (
            <div style={{ display: 'flex', flexDirection: 'column', padding: 20 }}>
                <FormLabel style={{marginTop: 10}}>Comandas Ativas</FormLabel>
                <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'scroll', paddingTop: 10 }}>
                    {this.state.openTickets.map(doc => <TicketCard width={250} onIsEditing={(flag) => { this.getOpenTickets(this.props.user); }} fromSalesPage={false} doc={doc} usersDocs={this.props.usersDocs} />)}
                </div>
                <FormLabel style={{marginTop: 10}}>Histórico de Comandas</FormLabel>
                <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'scroll', paddingTop: 10 }}>
                    {this.state.historyTickets.map(doc => <TicketCard width={250} onIsEditing={(flag) => { this.getOpenTickets(this.props.user); }} fromSalesPage={false} doc={doc} usersDocs={this.props.usersDocs} />)}
                </div>
                <FormLabel style={{marginTop: 10}}>Histórico de Vendas</FormLabel>
                <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'scroll', paddingTop: 10 }}>
                    {this.state.historySales.map(doc => <SaleCard horizontal={true} doc={doc} usersDocs={this.props.usersDocs} adminUsers={this.state.adminUsers}/>)}
                </div>
            </div>
        );
    }
}