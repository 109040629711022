import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import { Card } from '@material-ui/core';
import moment from "moment-timezone";
import Colors from '../constants/Colors';
import SessionHelper from '../helper/SessionHelper';

moment.updateLocale('pt-br', {
    months : [
        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho",
        "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ]
});
moment.locale('pt-br');

export default class PaymentMissingPage extends Component {

    state = {
        
    }

    renderTrialOver() {

        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <p style={{fontSize: 25, fontWeight: 'bold'}}>{`Ops! O seu período de testes acabou.`}</p>
                <div style={{color: 'grey', fontSize: 16}}>{'Acesse a '}<a href={'/subscription'}>página de faturamento</a> e contrate um plano, {'ou entre em contato conosco.'}</div>
            </div>
        )
    }

    renderPaymentMissing() {

        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <p style={{fontSize: 25, fontWeight: 'bold'}}>{`Ops! Houve um problema com o seu pagamento de ${moment().format('MMMM')} :(`}</p>
                <div style={{color: 'grey', fontSize: 16}}>{'Acesse a '}<a href={'/subscription'}>página de faturamento</a> {'ou entre em contato conosco.'}</div>
            </div>
        )
    }
    
    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <div style={{paddingTop: 40}}>
                    <Card style={{paddingLeft: 20, paddingRight: 20, marginBottom: 15, paddingBottom: 40, height: '80vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <img style={{height: 350}} src={process.env.PUBLIC_URL + '/payment_missing.png'}/>
                        { SessionHelper.getData().company.trial ? this.renderTrialOver() : this.renderPaymentMissing() }
                    </Card>
                </div>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}