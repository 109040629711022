import React, { Component } from 'react';
import IosSwitch from '../IosSwitch';
import DefaultButton from '../DefaultButton';
import DefaultLoader from '../DefaultLoader';
import SessionHelper from '../../helper/SessionHelper';
import ReportHelper from '../../helper/ReportHelper';
import moment from "moment-timezone";
import CurrencyHelper from '../../helper/CurrencyHelper';
import Firestore from '../../api/firebase/Firestore';
import { toast } from 'react-toastify';
import ReportDefaultModalOptions from './ReportDefaultModalOptions';

export default class TeacherLessonReport extends Component {

    state = {
        name: `Aulas por Professor - ${SessionHelper.getData().company.name}`,
        loadingModal: true,
        teacherDocs: [],
        studentPlanDocs: [],
        studentDocs: [],
        teachers: [],
        allTeachers: true,
        start: moment().startOf('month').startOf('day').toDate(),
        output: 'download',
        format: 'pdf',
    }

    async componentDidMount() {

        if (this.props.getDocs && this.props.getDocs) {

            for (let index = 0; index < this.props.getDocs.length; index++) {
                
                const getDoc = this.props.getDocs[index];
                await getDoc(this);
            }
        }

        this.setState({ loadingModal: false });
    }

    async getReportDocs() {

        let teachers = this.state.allTeachers ? this.state.teacherDocs : this.state.teachers;
        let docs = [];

        for (let teacher of teachers) {
         
            let teacherDoc = teacher;

            if (!this.state.allTeachers) {

                teacherDoc = this.state.teacherDocs.find(item => item.id === teacher);
            }

            let start = moment(this.state.start).startOf('month').startOf('day').toDate();
            let end = moment(this.state.start).endOf('month').endOf('day').toDate();
            let lessons = await Firestore.customQuery('lesson').where('start', '>=', start).where('start', '<=', end).where('id_teacher', '==', teacherDoc.id).get();

            let userDocs = [];
            let users = {};
            let count = 0;

            for (const doc of lessons.docs) {
                
                let lesson = { ...doc.data(), id: doc.id };

                if (lesson.id_students.length) {

                    count += lesson.id_students.length;

                    for (const student of lesson.id_students) {
                        
                        if (!users[student]) { users[student] = 1 } else { users[student] += 1 };
                    }
                }
            }

            let keys = Object.keys(users);

            for (const user of keys) {

                let userDoc = this.state.studentDocs.find(item => item.id === user);

                if (!userDoc) {

                    let query = await Firestore.getDoc('user', user);
                    userDoc = { ...query.data(), id: query.id };
                }

                let query = await Firestore.customQuery('student_subscription').where('id_user', '==', userDoc.id).where('id_company', '==', SessionHelper.getData().id_company).orderBy('created_at', 'desc').limit(1).get();
                let studentSubscription = null;
                let studentPlan = '';

                if (query.size === 1) {

                    studentSubscription = query.docs[0].data();
                    studentPlan = this.state.studentPlanDocs.find(item => item.id === studentSubscription.id_plan);
                };

                userDoc.plan = studentPlan ? `${studentPlan.name} - ${CurrencyHelper.centsToMoney(studentPlan.price)}` : 'Plano não encontrado';
                userDoc.total_lessons = users[user];

                userDocs.push(userDoc);
            }

            teacherDoc.total_lessons = count;
            teacherDoc.users = userDocs;

            docs.push(teacherDoc);
        }

        docs.sort((a, b) => {

            return a.name.toLowerCase() > b.name.toLowerCase();
        });

        return docs;
    }

    addThree(report, column) {

        report.doc.setLineWidth(0.6);
        report.doc.line(column.width - 0.3, report.offset + 4, 18, report.offset + 4);
        report.doc.line(column.width, report.offset + 4, column.width, report.offset + 2);
        report.doc.setLineWidth(0.2);
    }

    async print() {

        if (this.state.allTeachers || (!this.state.allTeachers && this.state.teachers.length)) {

            if (this.state.start) {

                try {

                    this.setState({ loadingModal: true });

                    let docs = await this.getReportDocs();
                    let report = await ReportHelper.createReport(this.state.name, 5, moment(this.state.start).format('MMMM/YYYY'), this.state.format);

                    let createLessonsColumn =  async (report, actualDoc) => {

                        if (actualDoc.users && actualDoc.users.length) {

                            report = await ReportHelper.createColumns(report, actualDoc.users, [
                                { name: '', width: 14, getText: (doc) => '', customColumnHeader: (report, column) => this.addThree(report, column) },
                                { name: 'Alunos', width: 20, getText: (doc) => doc.name ? this.state.format === 'pdf' ? ReportHelper.cutName(ReportHelper.getShortName(doc.name), 25) : doc.name : '' },
                                { name: 'Plano', width: 70, getText: (doc) => this.state.format === 'pdf' ? ReportHelper.cutName(doc.plan, 45) : doc.plan },
                                { name: 'Aulas Lecionadas', width: 167, getText: (doc) => doc.total_lessons.toString() },
                            ], null, false, 6, [212, 212, 212]);
    
                            return report;
                        }
                    }

                    report = await ReportHelper.createColumns(report, docs, [
                        { name: 'Professor', width: 13, getText: (doc) => doc.name ? this.state.format === 'pdf' ? ReportHelper.cutName(ReportHelper.getShortName(doc.name), 18) : doc.name : '' },
                        { name: 'Telefone', width: 50, getText: (doc) => doc.phone || '' },
                        { name: 'Cpf', width: 85, getText: (doc) => doc.cpf ? doc.cpf.toString().replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : '' },
                        { name: 'Total de Aulas', width: 167, getText: (doc) => doc.total_lessons.toString() },
                    ], (report, actualDoc) => createLessonsColumn(report, actualDoc));
            
                    ReportHelper.print(report, this.state.output, this.state.format);

                    this.setState({ loadingModal: false });    

                } catch (error) {

                    this.setState({ loadingModal: false });
                    toast.error('Houve um problema ao imprimir o relatório')
                }

            } else {

                toast.warn('Selecione o mês para imprimir');
            }

        } else {

            toast.warn('Selecione ao menos um professor para imprimir');
        }
    }

    renderLoading() {

        return (

            <div style={{ height: 200 }}>
                <DefaultLoader/>
            </div>
        )
    }

    renderModal() {

        return (
            <div>
                <IosSwitch fullWidth label={'Todos os Professores'} checked={this.state.allTeachers} onChange={(e) => { this.setState({ allTeachers: e, teachers: [] }) }} />
                { !this.state.allTeachers ? ReportHelper.renderTeacherSelection(this, this.state.teacherDocs, 'teachers') : null }
                
                { ReportHelper.renderMonthSelection(this, 'start') }
                
                <ReportDefaultModalOptions format={this.state.format} output={this.state.output} onOutputChange={(value) => { this.setState({ output: value }) }} onFormatChange={(value) => { this.setState({ format: value }) }}/>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <DefaultButton width={'100%'} title={'Imprimir'} onClick={() => { this.print() }} />
                </div>
            </div>
        )
    }

    render() {

        return (

            this.state.loadingModal ? this.renderLoading() : this.renderModal()
        )
    }
}