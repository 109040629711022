import React from 'react';
import Firestore from '../api/firebase/Firestore';
import Colors from '../constants/Colors';
import moment from "moment-timezone";
import DefaultLoader from './DefaultLoader';
import { FormLabel, IconButton, Tooltip } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import FlashOn from '@material-ui/icons/FlashOn';
import Today from '@material-ui/icons/Today';
import UserHelper from '../helper/UserHelper';

export default class ActiveLessonReplacements extends React.Component {
    state = {
        loading: true,
        id_lesson: this.props.id_lesson || null,
        docs: [],
    }

    async componentDidMount() {
        if (this.state.id_lesson) {
            await this.getReplacements();
        } else {
            this.props.setLessonHasReplacementLesson(false);
        }
    }

    async getReplacements() {
        this.setState({ loading: true });

        let query = await Firestore.customQuery('replacement_lesson').where('id_lesson', '==', this.state.id_lesson).get();
        let docs = [];

        if (query.size > 0) {
            for (let doc of query.docs) {
                let data = { ...doc.data(), id: doc.id };
                if (!data.lesson_type) data.lesson_type = 'group';
                docs.push(data);
            }

            this.props.setLessonHasReplacementLesson(true);
        } else {
            this.props.setLessonHasReplacementLesson(false);
        }

        this.setState({ docs, loading: false });
    }

    renderRepositions() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
                <FormLabel style={{ marginBottom: 8 }}>Reposições ativas desta aula:</FormLabel>
                <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'auto', width: '100%' }}>
                    {this.state.docs.map((replacementLesson, key) => (
                        <div style={{ border: '1px solid lightgrey', padding: 15, display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: key > 0 ? 15 : 0, minWidth: 350, marginBottom: 10, borderRadius: 5 }}>
                            <Today style={{ border: `3px solid ${Colors.primary}`, height: 60, width: 60, padding: 5, borderRadius: 60 / 2, color: Colors.primary, boxShadow: 'rgba(50, 50, 50, 0.47) 1px 1px 10px 0px' }} />
                            <div style={{ marginLeft: 15 }}>
                                <b>{`De ${moment(replacementLesson.start.toDate()).format('DD/MM/YYYY HH:mm')} até ${moment(replacementLesson.end.toDate()).format('DD/MM/YYYY HH:mm')}`}</b>

                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', maxWidth: 260 }}>{replacementLesson.category.map((cat, key) => {
                                    let category = cat ? this.props.paramDoc.categories.find(item => item.value == cat) : null;
                                    return <div style={{ backgroundColor: category.color || Colors.primary, minWidth: 125, borderRadius: 5, textAlign: 'center', color: 'white', fontWeight: 'bold', marginTop: 5, marginLeft: key > 0 ? 5 : 0 }}>{category.label}</div>
                                })}</div>

                                <div style={{ backgroundColor: UserHelper.getLessonType()[replacementLesson.lesson_type].color, maxWidth: 125, borderRadius: 5, textAlign: 'center', color: 'white', fontWeight: 'bold', marginTop: 5 }}>{UserHelper.getLessonType()[replacementLesson.lesson_type].label}</div>
                                <div style={{ color: 'green', marginTop: 8 }}>Vagas Restantes: <strong>{replacementLesson.limit - replacementLesson.id_students.length}</strong> {replacementLesson.limit - replacementLesson.id_students.length === 1 ? 'vaga' : 'vagas'} de <strong>{replacementLesson.limit}</strong> no total</div>
                                {replacementLesson.automated ? <div style={{ marginTop: 5, backgroundColor: '#ffae42', borderRadius: 5, color: '#fff', padding: 3, textAlign: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                    <FlashOn style={{ fontSize: 20 }} />
                                    <strong>Gerada Automáticamente</strong>
                                </div> : null}
                            </div>

                            <Tooltip title={'Remover Horário'} style={{ alignSelf: 'flex-start', marginLeft: 'auto', marginTop: 25 }}>
                                <IconButton onClick={() => { this.props.removeReplacementLesson(replacementLesson.id); this.getReplacements(); }}>
                                    <Delete style={{ color: 'red' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    ))}
                </div>
            </div>

        )


    }

    render() {
        return this.state.id_lesson ? this.state.loading ? <DefaultLoader size={32} color={Colors.primary} css={{ margin: 20 }} /> : (

            <div {...this.props}>
                {this.state.docs.length ? this.renderRepositions() : null}
            </div>
        ) : null;
    }
}