import React, { Component } from 'react';
import IosSwitch from '../IosSwitch';
import DefaultButton from '../DefaultButton';
import DefaultLoader from '../DefaultLoader';
import ReportHelper from '../../helper/ReportHelper';
import moment from "moment-timezone";
import { FormLabel } from '@material-ui/core';
import Firestore from '../../api/firebase/Firestore';
import SessionHelper from '../../helper/SessionHelper';
import ReportDefaultModalOptions from './ReportDefaultModalOptions';

export default class CourtRentReport extends Component {

    state = {
        loadingModal: true,
        studentDocs: [],
        users: [],
        courtDocs: [],
        courts: [],
        allCourts: true,
        allUsers: true,
        start: moment().startOf('day').toDate(),
        end: moment().endOf('day').toDate(),
        output: 'download',
        format: 'pdf',
    }

    async componentDidMount() {

        if (this.props.getDocs && this.props.getDocs.length) {

            for (let index = 0; index < this.props.getDocs.length; index++) {
                
                const getDoc = this.props.getDocs[index];
                await getDoc(this);
            }
        }

        this.setState({ loadingModal: false });
    }

    async getReportDocs() {

        let docs = [];

        let users = this.state.allUsers ? this.state.studentDocs : this.state.users;
        let userDocs = [];

        let courts = this.state.allCourts ? this.state.courtDocs : this.state.courts;
        let courtDocs = [];

        users.forEach((user, key) => {

            let userDoc = user;

            if (!this.state.allUsers) {

                userDoc = this.state.studentDocs.find(item => item.id === user);
            }

            userDocs.push(userDoc);
        });

        courts.forEach((court, key) => {

            let courtDoc = court;

            if (!this.state.allCourts) {

                courtDoc = this.state.courtDocs.find(item => item.id === court);
            }

            courtDocs.push(courtDoc);
        });

        let query = await Firestore.customQuery('court_rent').where('id_company', '==', SessionHelper.getData().id_company).where('start', '>=', this.state.start).where('start', '<=', this.state.end).get();

        let counter = 0;
        for (let doc of query.docs) {

            let findStudent = false;
            let findCourt = false;

            let event = { ...doc.data(), id: doc.id };
        
            let userNames = [];
            let idUsers = [];

            if (event.id_user) { idUsers = [ event.id_user ] };
            if (event.id_students) { idUsers = event.id_students };

            for (const user of idUsers) {
                
                let doc = userDocs.find(item => item.id === user);
    
                if (doc) {
                    
                    userNames.push(ReportHelper.getShortName(doc.name));
                    findStudent = true;

                } else {

                    let query = await Firestore.getDoc('user', user);
                    let userData = { ...query.data(), id: query.id };

                    userNames.push(ReportHelper.getShortName(userData.name));

                    if (this.state.allUsers) {

                        findStudent = true;
                    }
                }
            }

            if (event.manual_info && event.manual_info.name) {

                userNames.push(ReportHelper.getShortName(event.manual_info.name));

                if (this.state.allUsers) {

                    findStudent = true;
                }
            }
            
            if (event.manual_info && event.manual_info[0] && event.manual_info[0].name) { 

                if (this.state.allUsers) {

                    findStudent = true;
                }

                for(let user of event.manual_info) {
                    userNames.push(ReportHelper.getShortName(user.name))
                }
            }

            let court = courtDocs.find(item => item.id === event.id_court);
            if (court) { findCourt = true };

            let order = 'Sem Pedido';

            if (event.id_order) {

                let query = await Firestore.getDoc('order', event.id_order);
                if (query.exists) { order = query.data().title };
            }

            if (findCourt && findStudent) {

                docs.push({
                    date: `${moment(event.start.toDate()).format('DD/MM/YY HH:mm')} ~ ${moment(event.end.toDate()).format('HH:mm')}`,
                    court: court.name,
                    students: userNames.join(', '),
                    type: 'Aluguel de Quadra',
                    order: order,
                });
            }

            counter++;
            ReportHelper.updateProgress(this, query.docs.length, counter, null);
        }

        docs.sort((a, b) => {

            return a.date > b.date;
        });

        return docs;
    }

    async print() {

        this.setState({ loadingModal: true });

        let docs = await this.getReportDocs();
        let name = `Aluguéis de Quadra - ${SessionHelper.getData().company.name}`;
        let subtitle = `De ${moment(this.state.start).format('DD/MM/YYYY HH:mm')} Até ${moment(this.state.end).format('DD/MM/YYYY HH:mm')}`;
        let report = await ReportHelper.createReport(name, 5, subtitle, this.state.format);

        report = await ReportHelper.createColumns(report, docs, [
            { name: 'Data', width: 13, getText: (doc) => doc.date },
            { name: 'Quadra', width: 55, getText: (doc) => this.state.format === 'pdf' ? ReportHelper.cutName(doc.court, 21) : doc.court },
            { name: 'Pedido', width: 100, getText: (doc) => doc.order },
            { name: 'Alunos', width: 130, getText: (doc) => this.state.format === 'pdf' ? ReportHelper.cutName(doc.students, 33) : doc.students },
        ]);

        ReportHelper.print(report, this.state.output, this.state.format);

        this.setState({ loadingModal: false });
    }

    renderLoading() {
        return (
            <div style={{ height: 200 }}>
                <DefaultLoader progress={this.state.progress || 0} loadingText={this.state.loadingText || ``} />
            </div>
        )
    }

    renderModal() {

        return (

            <div>
                <IosSwitch fullWidth label={'Todos os Alunos'} checked={this.state.allUsers} onChange={(e) => { this.setState({ allUsers: e, users: [] }) }} />
                { !this.state.allUsers ? ReportHelper.renderUserSelection(this, this.state.studentDocs, 'users') : null }

                <IosSwitch fullWidth label={'Todas as Quadras'} checked={this.state.allCourts} onChange={(e) => { this.setState({ allCourts: e, courts: [] }) }} />
                { !this.state.allCourts ? ReportHelper.renderCourtSelection(this, this.state.courtDocs, 'courts') : null }

                { ReportHelper.renderPeriodSelection(this, 'start', 'end', 'dd/MM/yyyy HH:mm') }
                
                <ReportDefaultModalOptions format={this.state.format} output={this.state.output} onOutputChange={(value) => { this.setState({ output: value }) }} onFormatChange={(value) => { this.setState({ format: value }) }}/>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <DefaultButton width={'100%'} title={'Imprimir'} onClick={() => { this.print() }} />
                </div>
            </div>
        )
    }

    render() {

        return (

            this.state.loadingModal ? this.renderLoading() : this.renderModal()
        )
    }
}